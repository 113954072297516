import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { bem } from "@react-md/utils";
import cn from "classnames";

import stringHash from "@mapmycustomers/shared/util/hash/stringHash";

const block = bem("mmc-list-with-overflow-item");

interface ListWithOverflowItemProps {
  children: Array<ReactElement>;
  className?: string;
  overflowRender: (hiddenItemsCount: number) => ReactNode;
}

const ListWithOverflowItem: FC<ListWithOverflowItemProps> = ({
  children,
  className,
  overflowRender,
}) => {
  const listElement = useRef<HTMLDivElement>(null);
  const [hiddenItemsMap, setHiddenItemsMap] = useState<Record<string, boolean>>({});

  const handleIntersection = useCallback((entries: IntersectionObserverEntry[]) => {
    setHiddenItemsMap((previous) =>
      entries.reduce(
        (result, entry) => ({
          ...result,
          // we know that key is defined since we set it ourselves in this component
          [(entry.target as HTMLElement).dataset.key!]: !entry.isIntersecting,
        }),
        previous
      )
    );
  }, []);

  const key = stringHash(
    React.Children.map(children, (child: ReactElement, index) => child.key ?? index)?.join("_") ??
      ""
  );

  useEffect(() => {
    if (!listElement.current) {
      return;
    }

    const observer = new IntersectionObserver(handleIntersection, {
      root: listElement.current,
      threshold: 1,
    });

    Array.from(listElement.current.children).forEach((item) => {
      if ((item as HTMLElement).dataset.key !== undefined) {
        observer.observe(item);
      }
    });
    return () => {
      observer.disconnect();
      setHiddenItemsMap({});
    };
  }, [handleIntersection, key]);

  const hiddenItemsCount = useMemo(
    () => Object.values(hiddenItemsMap).filter((value) => value).length,
    [hiddenItemsMap]
  );

  return (
    <div className={cn(block(), className)}>
      <div className={block("list")} ref={listElement}>
        {React.Children.map(children, (child: ReactElement, index) => {
          return React.cloneElement(child, {
            className: cn(
              child.props.className,
              block("item", {
                invisible: hiddenItemsMap[child.key ?? index],
                visible: !hiddenItemsMap[child.key ?? index],
              })
            ),
            "data-key": child.key ?? index,
          });
        })}
      </div>

      {hiddenItemsCount ? overflowRender(hiddenItemsCount) : null}
    </div>
  );
};

export default ListWithOverflowItem;
