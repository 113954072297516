import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Route } from "react-router-dom";

import { isSignedIn } from "@app/store/auth";
import { loggedIn } from "@app/store/auth/actions";
import authService from "@app/store/auth/AuthService";
import { RootState } from "@app/store/rootReducer";

type Props = React.ComponentProps<typeof Route> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const AuthRoute = ({ isSignedIn, loggedIn, ...routeProps }: Props) => {
  const hasToken = authService.hasToken();

  useEffect(() => {
    if (hasToken && isSignedIn === false) {
      // has token in AuthService, but not in the store, perform loggedIn procedure
      loggedIn({ token: authService.getToken() });
    }
  }, [hasToken, isSignedIn, loggedIn]);

  return <Route {...routeProps} />;
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: isSignedIn(state),
});

const mapDispatchToProps = {
  loggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
