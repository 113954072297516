import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns/esm";

import CalendarViewMode from "@app/enum/CalendarViewMode";
import { getWeekEnd, getWeekStart } from "@app/util/dates";

const getActivityDateRange = (startAt: Date, viewMode: CalendarViewMode): [Date, Date] => {
  switch (viewMode) {
    case CalendarViewMode.DAY:
      return [startOfDay(startAt), endOfDay(startAt)];
    case CalendarViewMode.MONTH:
      return [startOfMonth(startAt), endOfMonth(startAt)];
    case CalendarViewMode.WEEK:
      return [getWeekStart(startAt), getWeekEnd(startAt)];
  }
};

export default getActivityDateRange;
