import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useIntl } from "react-intl";

import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { FormItem, SelectField } from "@mapmycustomers/ui";

import ButtonLink from "@app/component/ButtonLink";
import { ConditionalFormFieldRenderProps } from "@app/component/ConditionalFormField";
import { getFunnelStages } from "@app/store/deal";
import { fetchDealLossReasons } from "@app/store/dealLossReasons/actions";
import { getDealLossReasons, isDealLossReasonsLoading } from "@app/store/dealLossReasons/selectors";
import { isCurrentUserOwner } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

import CreateReasonModal from "./components/CreateReasonModal";
import DeleteLossReasonButton from "./components/DeleteLossReasonButton";
import styles from "./DealLossReasonField.module.scss";

interface OwnProps extends ConditionalFormFieldRenderProps {}

interface Props extends OwnProps {
  funnelStages: Record<Funnel["id"], Stage[]>;
  isOwner?: boolean;
  loading?: boolean;
  lossReasons: DealLossReason[];
  onFetchDealLossReasons: typeof fetchDealLossReasons.request;
}

const DealLossReasonField: React.FC<Props> = ({
  disabled,
  funnelStages,
  isOwner,
  label,
  loading,
  lossReasons,
  onFetchDealLossReasons,
  required,
  variant,
}) => {
  const intl = useIntl();
  const form = useFormInstance();

  const funnelId = useWatch("funnelId");
  const stageId = useWatch("stageId");
  const isLostStage = useMemo(
    () => funnelStages[funnelId]?.find(({ id }) => id === stageId)?.type === DealStageType.LOST,
    [funnelStages, funnelId, stageId]
  );

  useEffect(() => {
    // resetting deal loss reason since it depends on funnel
    //     By seeing an empty stageId we understand that the funnelId was changed and hence
    // we need to reset lossReason and load reasons for the newly selected funnel.
    // Just having funnelId in deps is not sufficient, since this effect is also called on an initial
    // form appearance, when we clearly don't need to reset the loss reason.
    if (!stageId) {
      form.setFieldsValue({ dealLossReasonId: undefined });
      onFetchDealLossReasons(funnelId);
    }
  }, [form, funnelId, onFetchDealLossReasons, stageId]);

  const [newReasonModalVisibility, showNewReasonModal, hideNewReasonModal] = useBoolean();
  const handleHideNewReasonModal = useCallback(
    (lossReason?: DealLossReason) => {
      if (lossReason) {
        form.setFieldsValue({ dealLossReasonId: lossReason.id });
      }
      hideNewReasonModal();
    },
    [form, hideNewReasonModal]
  );

  const selectedReasonId = useWatch("dealLossReasonId");

  const options = useMemo(
    () =>
      lossReasons.map((reason) => ({
        key: reason.id,
        label: (
          <div className={styles.reasonOption}>
            <span className={styles.reasonName}>{reason.name}</span>
            {selectedReasonId !== reason.id && (
              // TODO: fixme 2023-11-07 make this button a common component
              <DeleteLossReasonButton lossReason={reason} />
            )}
          </div>
        ),
        value: reason.id,
      })),
    [lossReasons, selectedReasonId]
  );

  // hide this field for non-lost stages, but only if we're not using a variant (i.e. for parent layout)
  const hideForNonLostStages = !variant;
  // if we hide field for non-lost stages and this is a non-lost stage, return null
  if (hideForNonLostStages && !isLostStage) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <FormItem label={label} name="dealLossReasonId" required={required} rules={[{ required }]}>
          <SelectField<DealLossReason["id"]>
            allowClear
            className={styles.select}
            disabled={disabled || !lossReasons.length}
            label={label}
            loading={loading}
            optionLabelProp="label"
            options={options}
            placeholder={
              lossReasons.length > 0
                ? intl.formatMessage({
                    id: "dealLostModal.reasons.placeholder",
                    defaultMessage: "Select a reason",
                    description: "Loss reasons selectbox placeholder in Deal Lost modal",
                  })
                : intl.formatMessage({
                    id: "dealLostModal.reasons.placeholder.noReasons",
                    defaultMessage: "No reasons created",
                    description: "Loss reasons selectbox placeholder when there's no reasons",
                  })
            }
            required={required}
          />
        </FormItem>
        {isOwner && (
          <div>
            <ButtonLink onClick={showNewReasonModal}>
              {intl.formatMessage({
                // TODO: fixme 2023-11-07 change message id
                id: "dealLostModal.addNewLostReason",
                defaultMessage: "+ Add New Reason",
                description: "Add new company button on Company association modal",
              })}
            </ButtonLink>
          </div>
        )}
      </div>
      {newReasonModalVisibility && <CreateReasonModal onHide={handleHideNewReasonModal} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnelStages: getFunnelStages(state),
  isOwner: isCurrentUserOwner(state),
  loading: isDealLossReasonsLoading(state),
  lossReasons: getDealLossReasons(state),
});

const mapDispatchToProps = {
  onFetchDealLossReasons: fetchDealLossReasons.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealLossReasonField);
