import { useCallback } from "react";

import { FormInstance, FormProps } from "antd/es/form";

import RecurringIntervalType from "../enum/RecurringIntervalType";
import FormValues from "../types/FormValues";

import { parseActivityInterval } from "./activityInterval";

export const useRecurIntervalTypeValueChangeHandler = (form: FormInstance<FormValues>) =>
  useCallback<NonNullable<FormProps<FormValues>["onValuesChange"]>>(
    (field, allFields) => {
      if ("recurIntervalType" in field && field.recurIntervalType !== RecurringIntervalType.NEVER) {
        const recurInterval = parseActivityInterval(
          field.recurIntervalType === RecurringIntervalType.CUSTOM
            ? RecurringIntervalType.MONTHLY
            : field.recurIntervalType
        );
        form.setFieldsValue({
          recurInterval,
        });
        allFields.recurInterval = recurInterval;
      }
    },
    [form]
  );
