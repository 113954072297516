import User from "@mapmycustomers/shared/types/User";

const getDefaultSelectedUsersState = (): undefined | User["id"][] => {
  const urlParams = new URLSearchParams(window.location.search);
  const users = urlParams.get("users");
  const userIds = (users ?? "")
    .split(",")
    .map((userId) => parseInt(userId, 10))
    .filter((userId) => userId > 0);
  return userIds.length ? userIds : undefined;
};

export default getDefaultSelectedUsersState;
