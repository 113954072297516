import React from "react";

import { bem } from "@react-md/utils";
import AntDAvatar, { AvatarProps } from "antd/es/avatar";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import { UserRef } from "@mapmycustomers/shared/types/User";
import getInitials from "@mapmycustomers/shared/util/ui/getInitials";

import { useConfigProvider } from "../ConfigProvider";

import colorStyles from "./AvatarColor.module.scss";
import { getUserDisplayNameWithInfo } from "./util/getUserDisplayNameWithInfo";

const block = bem("mmc-avatar");

interface Props extends Omit<AvatarProps, "className"> {
  actionOnHover?: boolean;
  className?: string;
  tooltip?: boolean;
  user: UserRef;
}

const Avatar: React.FC<Props> = ({
  actionOnHover,
  className,
  tooltip = true,
  user,
  ...avatarProps
}) => {
  const configProvider = useConfigProvider();
  const profilePhotoUrl = configProvider.complementFileUrl(user.profilePhoto?.publicURI);

  const nameInitials = getInitials(user);

  let colorClassName;
  if (!profilePhotoUrl) {
    colorClassName = colorStyles[configProvider.getUserColorClassName(user) as string];
  }

  return (
    <Tooltip
      title={
        tooltip
          ? actionOnHover && !user.deletedAt && !user.deactivated
            ? configProvider.formatMessage("ui.avatar.tooltip.open", {
                name: getUserDisplayNameWithInfo(configProvider, user),
              })
            : getUserDisplayNameWithInfo(configProvider, user)
          : undefined
      }
    >
      <AntDAvatar
        className={cn(
          block("avatarIcon", { avatarHover: actionOnHover }),
          colorClassName,
          className
        )}
        src={profilePhotoUrl}
        {...avatarProps}
      >
        {profilePhotoUrl ? " " : nameInitials}
      </AntDAvatar>
    </Tooltip>
  );
};

// Empty object preventing passing dispatch prop to the component
export default Avatar;
