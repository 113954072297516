import { ActionType, createAsyncAction } from "typesafe-actions";

import { EntityTypesSupportingDownloading } from "@mapmycustomers/shared/types/entity";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

export const exportEntities = createAsyncAction(
  "@exportEntities/download/request",
  "@exportEntities/download/success",
  "@exportEntities/download/failure"
)<
  {
    entityType: EntityTypesSupportingDownloading;
    exportCreationSuccessMessage?: string;
    name?: string;
    platformRequest?: Partial<PlatformListRequest>;
    total?: number;
    viewState: Partial<ViewState>;
  },
  void,
  unknown
>();

export type Actions = ActionType<typeof exportEntities>;
