import { useContext } from "react";

import { AnyEntity } from "@mapmycustomers/shared/types/entity";

import IViewContext from "../types/IViewContext";

import ViewContext from "./ViewContext";

const useView = <T = AnyEntity>(): IViewContext<T> => {
  return useContext(ViewContext) as unknown as IViewContext<T>;
};

export default useView;
