import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Alert } from "@mapmycustomers/ui";

import Path from "@app/enum/Path";
import { isLoading } from "@app/store/auth";
import { requestAccountSso, resetErrors, ssoLogin } from "@app/store/auth/actions";
import { RootState } from "@app/store/rootReducer";
import analyticsService, { useExtendedAnalytics } from "@app/util/analytic/AnalyticsService";

import commonStyles from "../AuthCommon.module.scss";

import SsoErrorCodes, { getSsoErrorMessage, getSsoErrorMessageTitle } from "./getSsoErrorMessage";
import messages from "./messages";
import styles from "./SsoLoginForm.module.scss";

interface Props {
  errorMessage?: string;
  loading?: boolean;
  mmcMessage: null | SsoErrorCodes;
  mmcSessionId: null | string;
  onRequestAccountSso: typeof requestAccountSso.request;
  onResetErrors: typeof resetErrors;
  onSsoLogin: typeof ssoLogin.request;
}

const SsoLoginForm: React.FC<Props> = ({
  errorMessage,
  loading = false,
  mmcMessage,
  mmcSessionId,
  onRequestAccountSso,
  onResetErrors,
  onSsoLogin,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const analytics = useExtendedAnalytics("SSO", analyticsService);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const handleFormFieldsChange = useCallback(
    () => setCanSubmit(!form.getFieldsError().some((field) => field.errors.length > 0)),
    [form, setCanSubmit]
  );

  const handleSubmit = useCallback(
    (values: { email: string }) => {
      onSsoLogin({ username: values.email });
      analytics.clicked(["Single Sign On"]);
    },
    [analytics, onSsoLogin]
  );

  const handleRequestAccount = useCallback(() => {
    if (mmcSessionId) {
      onRequestAccountSso({
        callback: () => {
          setRequestSent(true);
        },
        sessionId: mmcSessionId,
      });
    }
    analytics.clicked(["Request Account"]);
  }, [analytics, mmcSessionId, onRequestAccountSso]);

  const handleGoBack = useCallback(() => {
    onResetErrors();
    analytics.clicked(["Go back"]);
  }, [analytics, onResetErrors]);

  const [ssoMessage, ssoMessageTitle] = useMemo(() => {
    if (mmcMessage && !errorMessage) {
      const message = getSsoErrorMessage(intl, mmcMessage);
      const title = getSsoErrorMessageTitle(intl, mmcMessage);
      return [message, title];
    }
    return [undefined, undefined];
  }, [errorMessage, intl, mmcMessage]);

  const errorType = useMemo(() => {
    if (
      mmcMessage &&
      [
        SsoErrorCodes.SSO_APPROVAL_PENDING,
        SsoErrorCodes.USER_LIMIT_EXCEEDED,
        SsoErrorCodes.USER_NOT_FOUND_IN_SAML_RESPONSE,
      ].includes(mmcMessage)
    ) {
      return "warning";
    }
    return "error";
  }, [mmcMessage]);

  const requestAction = useMemo(() => {
    if (mmcMessage === SsoErrorCodes.USER_NOT_FOUND_IN_MMC) {
      return requestSent ? (
        <div className={styles.requestSent}>
          <FontAwesomeIcon icon={faCheck} />
          {intl.formatMessage(messages.requestSent)}
        </div>
      ) : (
        <Button
          className={styles.requestButton}
          htmlType="submit"
          onClick={handleRequestAccount}
          type="primary"
        >
          {intl.formatMessage(messages.request)}
        </Button>
      );
    }
    return null;
  }, [intl, handleRequestAccount, mmcMessage, requestSent]);

  return (
    <div className={commonStyles.container}>
      {errorMessage && <Alert className={styles.alert} message={errorMessage} type="error" />}
      {ssoMessage && (
        <Alert
          action={requestAction}
          className={styles.alert}
          description={ssoMessage}
          message={ssoMessageTitle}
          showIcon={!requestAction}
          type={errorType}
        />
      )}
      <Form
        form={form}
        initialValues={{ remember: true }}
        layout="vertical"
        name="ssoLogin"
        onFieldsChange={handleFormFieldsChange}
        onFinish={handleSubmit}
      >
        <h1 className={commonStyles.title}>{intl.formatMessage(messages.title)}</h1>
        <p className={commonStyles.description}>{intl.formatMessage(messages.description)}</p>
        <Form.Item
          colon={false}
          name="email"
          requiredMark="optional"
          rules={[
            { message: intl.formatMessage(messages.required), required: true },
            { type: "email" },
          ]}
        >
          <Input
            addonBefore={<FontAwesomeIcon icon={faEnvelope} />}
            autoComplete="username"
            className={commonStyles.input}
            name="username"
            size="large"
            type="email"
          />
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.submitButton}
            disabled={!canSubmit || loading}
            htmlType="submit"
            loading={loading}
            type="primary"
          >
            {loading ? intl.formatMessage(messages.loading) : intl.formatMessage(messages.submit)}
          </Button>
        </Form.Item>
        <div className={styles.backToLoginLink}>
          <Link onClick={handleGoBack} to={Path.LOGIN}>
            {intl.formatMessage(messages.goBack)}
          </Link>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: isLoading(state),
});

const mapDispatchToProps = {
  onRequestAccountSso: requestAccountSso.request,
  onResetErrors: resetErrors,
  onSsoLogin: ssoLogin.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoLoginForm);
