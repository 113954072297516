import { defineMessages, IntlShape } from "react-intl";

import SandboxStatus from "@mapmycustomers/shared/enum/SandboxStatus";

const messages = defineMessages<SandboxStatus>({
  [SandboxStatus.ACTIVE]: {
    id: "sandboxStatus.active",
    defaultMessage: "Active",
    description: "SandboxStatus - Active",
  },
  [SandboxStatus.CLONING]: {
    id: "sandboxStatus.Cloning",
    defaultMessage: "Cloning",
    description: "SandboxStatus - Cloning",
  },
  [SandboxStatus.COMPLETED]: {
    id: "sandboxStatus.completed",
    defaultMessage: "Completed",
    description: "SandboxStatus - Completed",
  },
  [SandboxStatus.ERRORED]: {
    id: "sandboxStatus.errored",
    defaultMessage: "Error",
    description: "SandboxStatus - Error",
  },
});

const getSandboxStatusDisplayName = (intl: IntlShape, status: SandboxStatus): string =>
  intl.formatMessage(messages[status]);

export default getSandboxStatusDisplayName;
