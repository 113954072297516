import { put, select, takeEvery } from "redux-saga/effects";

import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import Integration from "@app/types/integrations/Integration";

import {
  createSandboxConfig,
  deleteSandboxConfig,
  fetchSandbox,
  initSandboxPage,
  updateSandboxConfig,
} from "./actions";

export function* onInitSandboxPage() {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const response: Organization = yield callApi("getOrganization", organizationId);
    const integrationResponse: ListResponse<Integration> = yield callApi(
      "fetchIntegrations",
      organizationId
    );
    yield put(
      initSandboxPage.success({
        hasIntegration: integrationResponse.data.length > 0,
        sandbox: response.metaData?.connectedOrg ?? undefined,
      })
    );
  } catch (error) {
    yield put(initSandboxPage.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchSandbox() {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const response: Organization = yield callApi("getOrganization", organizationId);
    yield put(
      fetchSandbox.success({
        sandbox: response.metaData?.connectedOrg ?? undefined,
      })
    );
  } catch (error) {
    yield put(fetchSandbox.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateSandboxConfig({
  payload: { callback, name },
}: ReturnType<typeof createSandboxConfig.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("createSandbox", organizationId, name);
    callback?.();
    yield put(createSandboxConfig.success());
    yield put(initSandboxPage.request());
  } catch (error) {
    yield put(createSandboxConfig.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateSandboxConfig({
  payload,
}: ReturnType<typeof updateSandboxConfig.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("updateSandbox", organizationId, payload);
    yield put(updateSandboxConfig.success());
    yield put(initSandboxPage.request());
  } catch (error) {
    yield put(updateSandboxConfig.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDeleteSandboxConfig() {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deleteSandbox", organizationId);
    yield put(deleteSandboxConfig.success());
  } catch (error) {
    yield put(deleteSandboxConfig.failure(error));
    yield put(handleError({ error }));
  }
}

export function* sandboxSaga() {
  yield takeEvery(initSandboxPage.request, onInitSandboxPage);
  yield takeEvery(fetchSandbox.request, onFetchSandbox);
  yield takeEvery(createSandboxConfig.request, onCreateSandboxConfig);
  yield takeEvery(updateSandboxConfig.request, onUpdateSandboxConfig);
  yield takeEvery(deleteSandboxConfig.request, onDeleteSandboxConfig);
}
