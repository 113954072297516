import { createReducer } from "typesafe-actions";

import { Company, Deal, EntityWithLocation, Person } from "@mapmycustomers/shared/types/entity";

import {
  Actions,
  filterCompanies,
  filterDeals,
  filterEntitiesWithLocation,
  filterPeople,
} from "./actions";

export interface EntityAssociationsState {
  companies: Company[];
  deals: Deal[];
  filteredCompanies: number;
  filteredDeals: number;
  filteredPeople: number;
  loading: boolean;
  located: EntityWithLocation[];
  people: Person[];
  totalCompanies: number;
  totalDeals: number;
  totalPeople: number;
}

const initialState: EntityAssociationsState = {
  companies: [],
  deals: [],
  filteredCompanies: 0,
  filteredDeals: 0,
  filteredPeople: 0,
  loading: false,
  located: [],
  people: [],
  totalCompanies: 0,
  totalDeals: 0,
  totalPeople: 0,
};

const entityAssociations = createReducer<EntityAssociationsState, Actions>(initialState)
  .handleAction(filterCompanies.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(filterCompanies.success, (state, { payload }) => ({
    ...state,
    companies: payload.companies,
    filteredCompanies: payload.filtered,
    loading: false,
    totalCompanies: payload.total,
  }))
  .handleAction(filterCompanies.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(filterDeals.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(filterDeals.success, (state, { payload }) => ({
    ...state,
    deals: payload.deals,
    filteredDeals: payload.filtered,
    loading: false,
    totalDeals: payload.total,
  }))
  .handleAction(filterDeals.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(filterPeople.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(filterPeople.success, (state, { payload }) => ({
    ...state,
    filteredPeople: payload.filtered,
    loading: false,
    people: payload.people,
    totalPeople: payload.total,
  }))
  .handleAction(filterPeople.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(filterEntitiesWithLocation.success, (state, { payload }) => ({
    ...state,
    loading: false,
    located: payload,
  }))
  .handleAction(filterEntitiesWithLocation.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./actions";
export * from "./selectors";

export type EntityAssociationsActions = Actions;
export default entityAssociations;
