import { format } from "date-fns/esm";
import { createReducer } from "typesafe-actions";

import ImportErrorType from "@mapmycustomers/shared/enum/imports/ImportErrorType";
import { EntityType, EntityTypesSupportedByImport } from "@mapmycustomers/shared/types/entity";
import Import from "@mapmycustomers/shared/types/imports/Import";
import ImportConfig from "@mapmycustomers/shared/types/imports/ImportConfig";
import ImportMapping from "@mapmycustomers/shared/types/imports/ImportMapping";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";

import ImportType from "@app/scene/settings/component/Imports/enum/ImportType";
import StepsEnum from "@app/scene/settings/component/Imports/enum/Steps";
import ImportHistoryRow from "@app/scene/settings/component/Imports/type/ImportHistoryRow";
import getMissedFileColumns from "@app/scene/settings/component/Imports/util/getMissedFileColumns";
import prePopulateMapping from "@app/scene/settings/component/Imports/util/prepopulateOptions";
import FilePreview from "@app/types/FilePreview";
import TimeZone from "@app/types/TimeZone";
import getLayoutModelByEntityType from "@app/util/layout/impl";

import ImportMappingOptions from "../type/ImportMappingOption";

import {
  Actions,
  clearFilePreview,
  createConfig,
  deleteConfig,
  fetchImportConfigs,
  initializeAddPage,
  initializeMainPage,
  initMatchFieldsStep,
  selectConfig,
  selectFile,
  selectLayoutId,
  setEntityType,
  setMappingField,
  setStep,
  setTimezone,
  startImport,
  updateConfig,
  updateCurrentConfigMatching,
  updateStartedImport,
} from "./actions";

const DEFAULT_ENTITY_TYPE = EntityType.COMPANY;

export type NewImportState = {
  entityType: EntityTypesSupportedByImport;
  errorCode?: ImportErrorType;
  filePreview?: FilePreview;
  importType: ImportType;
  layoutId?: FormLayout["id"];
  mappedColumns: ImportMapping;
  mappingOptions: ImportMappingOptions[];
  timezone: TimeZone;
  uid?: string;
};

export const defaultNewImportState: Readonly<NewImportState> = {
  entityType: DEFAULT_ENTITY_TYPE,
  importType: ImportType.NO_UID,
  mappedColumns: {},
  mappingOptions: [],
  timezone: format(new Date(), "xxxxx") as TimeZone,
};

export type ImportState = {
  configCreationLoading: boolean;
  configDeletionLoading: boolean;
  configModified: boolean;
  configs: ImportConfig[];
  configsLoading: boolean;
  configUpdateLoading: boolean;
  filePreviewLoading: boolean;
  importHistory: ImportHistoryRow[];
  initializeAddPageLoading: boolean;
  loading: boolean;
  missedFileColumns: string[];
  newImport: NewImportState;
  selectedConfig?: ImportConfig;
  startedImport: Import | undefined;
  startInProgress: boolean;
  startIsErrored: boolean;
  step: StepsEnum;
};

const initialState: ImportState = {
  configCreationLoading: false,
  configDeletionLoading: false,
  configModified: false,
  configs: [],
  configsLoading: false,
  configUpdateLoading: false,
  filePreviewLoading: false,
  importHistory: [],
  initializeAddPageLoading: false,
  loading: false,
  missedFileColumns: [],
  newImport: defaultNewImportState,
  startedImport: undefined,
  startInProgress: false,
  startIsErrored: false,
  step: StepsEnum.UPLOAD,
};

const imports = createReducer<ImportState, Actions>(initialState)
  .handleAction(setEntityType, (state, { payload }) => ({
    ...state,
    newImport: {
      ...state.newImport,
      entityType: payload.entityType,
      layoutId: getLayoutModelByEntityType(payload.entityType).defaultFormLayout?.id,
      mappingOptions: payload.mappingOptions,
    },
  }))
  .handleAction(clearFilePreview, (state) => ({
    ...state,
    newImport: { ...state.newImport, errorCode: undefined, filePreview: undefined },
  }))
  .handleAction(selectFile.request, (state) => ({
    ...state,
    filePreviewLoading: true,
  }))
  .handleAction(selectFile.success, (state, action) => ({
    ...state,
    filePreviewLoading: false,
    newImport: {
      ...state.newImport,
      filePreview: action.payload,
    },
  }))
  .handleAction(selectLayoutId, (state, { payload }) => ({
    ...state,
    filePreviewLoading: false,
    newImport: {
      ...state.newImport,
      layoutId: payload,
    },
  }))
  .handleAction(setTimezone, (state, action) => ({
    ...state,
    filePreviewLoading: false,
    newImport: {
      ...state.newImport,
      timezone: action.payload,
    },
  }))
  .handleAction(selectFile.failure, (state, { payload }) => ({
    ...state,
    filePreviewLoading: false,
    newImport: {
      ...state.newImport,
      errorCode: payload.errors[0],
    },
  }))
  .handleAction(startImport.request, (state) => ({
    ...state,
    startInProgress: true,
    startIsErrored: false,
  }))
  .handleAction(startImport.success, (state, action) => ({
    ...state,
    startedImport: action.payload,
    startInProgress: false,
    startIsErrored: false,
  }))
  .handleAction(startImport.failure, (state) => ({
    ...state,
    startInProgress: false,
    startIsErrored: true,
  }))
  .handleAction(initializeMainPage.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(initializeMainPage.success, (state, action) => ({
    ...state,
    importHistory: action.payload.history,
    loading: false,
  }))
  .handleAction(initializeMainPage.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateStartedImport.success, (state, action) => ({
    ...state,
    startedImport: action.payload,
  }))
  .handleAction(setMappingField, (state, action) => {
    const mappedColumns = { ...state.newImport.mappedColumns };
    Object.keys(mappedColumns).forEach((k) => {
      if (mappedColumns[k] === action.payload.fileField) {
        delete mappedColumns[k];
      }
    });
    mappedColumns[action.payload.mmcField] = action.payload.fileField;
    return {
      ...state,
      configModified: true,
      newImport: { ...state.newImport, mappedColumns },
    };
  })
  .handleAction(initializeAddPage.request, (state) => ({
    ...state,
    configModified: false,
    configsLoading: false,
    initializeAddPageLoading: true,
    selectedConfig: undefined,
  }))
  .handleAction(initializeAddPage.failure, (state) => ({
    ...state,
    initializeAddPageLoading: false,
  }))
  .handleAction(initializeAddPage.success, (state, action) => ({
    ...state,
    initializeAddPageLoading: false,
    newImport: action.payload.newImportState,
  }))
  .handleAction(createConfig.request, (state) => ({
    ...state,
    configCreationLoading: true,
  }))
  .handleAction(createConfig.failure, (state) => ({
    ...state,
    configCreationLoading: false,
  }))
  .handleAction(createConfig.success, (state, action) => ({
    ...state,
    configCreationLoading: false,
    configModified: false,
    selectedConfig: action.payload,
  }))
  .handleAction(updateConfig.request, (state) => ({
    ...state,
    configUpdateLoading: true,
  }))
  .handleAction(updateConfig.failure, (state) => ({
    ...state,
    configUpdateLoading: false,
  }))
  .handleAction(updateConfig.success, (state, action) => ({
    ...state,
    configModified: false,
    configUpdateLoading: false,
    selectedConfig:
      state.selectedConfig?.id === action.payload.id ? action.payload : state.selectedConfig,
  }))
  .handleAction(updateCurrentConfigMatching.request, (state) => ({
    ...state,
    configUpdateLoading: true,
  }))
  .handleAction(updateCurrentConfigMatching.failure, (state) => ({
    ...state,
    configUpdateLoading: false,
  }))
  .handleAction(updateCurrentConfigMatching.success, (state) => ({
    ...state,
    configModified: false,
    configUpdateLoading: false,
  }))
  .handleAction(deleteConfig.request, (state) => ({
    ...state,
    configDeletionLoading: true,
  }))
  .handleAction([deleteConfig.failure, deleteConfig.success], (state) => ({
    ...state,
    configDeletionLoading: false,
  }))
  .handleAction(fetchImportConfigs.request, (state) => ({
    ...state,
    configsLoading: true,
  }))
  .handleAction(fetchImportConfigs.failure, (state) => ({
    ...state,
    configsLoading: false,
  }))
  .handleAction(fetchImportConfigs.success, (state, action) => ({
    ...state,
    configs: action.payload,
    configsLoading: false,
  }))
  .handleAction(selectConfig, (state, action) => ({
    ...state,
    configModified: false,
    missedFileColumns: getMissedFileColumns(
      action.payload.mapping.properties,
      state.newImport!.filePreview!.headers
    ),
    newImport: {
      ...state.newImport,
      mappedColumns: action.payload.mapping.properties,
    },
    selectedConfig: action.payload,
  }))
  .handleAction(initMatchFieldsStep, (state) => ({
    ...state,
    configModified: false,
    missedFileColumns: [],
    newImport: {
      ...state.newImport,
      mappedColumns: prePopulateMapping(
        state.newImport.mappingOptions,
        state.newImport.filePreview
      ),
    },
    selectedConfig: undefined,
  }))
  .handleAction(setStep, (state, action) => ({
    ...state,
    step: action.payload,
  }));

export type ImportActions = Actions;
export default imports;
