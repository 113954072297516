import { Action } from "redux";
import { put, select, takeLatest } from "redux-saga/effects";
import { isActionOf } from "typesafe-actions";

import BaseLayerData from "@app/scene/map/store/layers/BaseLayerData";
import { getTrafficLayerData } from "@app/scene/map/store/selectors";
import { updateMetadata } from "@app/store/iam/actions";

import { toggleLayerLegendExpandedState, toggleLayerVisibility } from "../actions";

export function* onPersistTrafficSettings() {
  const layerData: BaseLayerData = yield select(getTrafficLayerData);

  yield put(updateMetadata.request({ trafficSettings: layerData }));
}

const isToggleVisibilityOrLegendAction = isActionOf([
  toggleLayerVisibility,
  toggleLayerLegendExpandedState,
]);

export function* trafficLayerSagas() {
  yield takeLatest(
    (action: Action) =>
      isToggleVisibilityOrLegendAction(action) && action.payload.name === "traffic",
    onPersistTrafficSettings
  );
}
