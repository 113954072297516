import { createSelector } from "reselect";

import { getUsers } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";

import { getDefaultField } from "../util/getDefaultFieldName";

export const getDataViewState = (state: RootState) => state.component.dataView;

export const getEntityType = createSelector(getDataViewState, ({ entityType }) => entityType);

export const getParams = createSelector(getDataViewState, ({ params }) => params);

export const getFilter = createSelector(getDataViewState, ({ filter }) => filter);

export const getField = createSelector(getDataViewState, ({ entityType, params }) => {
  const fieldModel = getFieldModelByEntityType(entityType);
  // if by some reason field model doesn't have field with saved name
  // we need to return default field
  return fieldModel.getByName(params.field[entityType]) ?? getDefaultField(entityType);
});

export const getFunnelId = createSelector(getDataViewState, ({ params }) => params.funnelId);

export const getDataMode = createSelector(getDataViewState, ({ params }) => params.dataMode);

export const getDataDisplayType = createSelector(
  getDataViewState,
  ({ params }) => params.dataDisplayType
);

export const getSelectedUserIds = createSelector(
  getDataViewState,
  ({ params: { selectedUserIds } }) => selectedUserIds
);

export const getSelectedUsers = createSelector(
  getSelectedUserIds,
  getUsers,
  (selectedUserIds, allUsers) =>
    selectedUserIds ? allUsers.filter(({ id }) => selectedUserIds.includes(id)) : allUsers
);

export const isDataLoading = createSelector(getDataViewState, ({ loading }) => loading);

export const getUserData = createSelector(getDataViewState, ({ userData }) => userData);

export const getGroupData = createSelector(getDataViewState, ({ groupData }) => groupData);

export const getFunnelData = createSelector(getDataViewState, ({ funnelData }) => funnelData);

export const getTotalValue = createSelector(getDataViewState, ({ totalValue }) => totalValue);

export const isDownloading = createSelector(getDataViewState, ({ downloading }) => downloading);
