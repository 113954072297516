import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";

import configService from "@app/config/ConfigService";
import Path from "@app/enum/Path";
import { isSignedIn } from "@app/store/auth";
import { RootState } from "@app/store/rootReducer";

import signupAnalytics from "../../signupAnalytics";
import AuthLayout from "../AuthLayout";

import RegisterMember from "./components/RegisterMember";
import RegisterOwner from "./components/RegisterOwner";

interface Props {
  isSignedIn: boolean | undefined;
}

export const RegisterPage: React.FC<Props> = ({ isSignedIn }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const invitationCode: null | string = urlParams.get("invitationCode");
  const email: null | string = urlParams.get("username");
  const RECAPTCHA_SITE_KEY = configService.getRecaptchaSiteKey();

  // go to home to proceed when already signed in
  useEffect(() => signupAnalytics.initiated(), []);

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    scriptTag.async = true;
    scriptTag.defer = true;
    document.body.append(scriptTag);

    return () => {
      window.location.reload();
    };
  }, [RECAPTCHA_SITE_KEY]);

  if (isSignedIn) {
    return <Redirect to={Path.DASHBOARD} />;
  }

  return (
    <AuthLayout>
      {invitationCode && email ? (
        <RegisterMember code={invitationCode} email={email} />
      ) : (
        <RegisterOwner email={email ?? undefined} />
      )}
    </AuthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: isSignedIn(state),
});

export default connect(mapStateToProps)(RegisterPage);
