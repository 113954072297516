import { Stripe } from "@stripe/stripe-js";
import { createReducer } from "typesafe-actions";

import SubscriptionInterval from "@mapmycustomers/shared/enum/SubscriptionInterval";
import CurrentPlan from "@mapmycustomers/shared/types/plans/CurrentPlan";
import Tier from "@mapmycustomers/shared/types/plans/Tier";
import ApiError from "@mapmycustomers/shared/util/api/ApiError";

import { calculatePrices, PriceInfo } from "../util/price";
import { findTierByIntervalAndLicenses } from "../util/tier";

import {
  Actions,
  hideCheckoutModal,
  initialize,
  resetSuccessState,
  setAnnual,
  setLicenses,
  showCheckoutModal,
  upgrade,
} from "./actions";

export interface BillingState {
  annual: boolean;
  checkoutModalVisible: boolean;
  currentPlan: CurrentPlan | undefined;
  error: ApiError | undefined;
  licenses: number;
  loading: boolean;
  priceInfo: PriceInfo;
  selectedTier: Tier | undefined;
  showSuccessState: boolean;
  stripe: Stripe | undefined;
  tiers: Tier[];
  upgradeError: ApiError | undefined;
  upgradeLoading: boolean;
}

const initialState: BillingState = {
  annual: true,
  checkoutModalVisible: false,
  currentPlan: undefined,
  error: undefined,
  licenses: 0,
  loading: false,
  priceInfo: {
    adjustedPrice: { currency: "USD", value: 0 },
    dueTodayPrice: { currency: "USD", value: 0 },
    proratedPrice: { currency: "USD", value: 0 },
    subscriptionPrice: { currency: "USD", value: 0 },
    switchingToAnnual: false,
  },
  selectedTier: undefined,
  showSuccessState: false,
  stripe: undefined,
  tiers: [],
  upgradeError: undefined,
  upgradeLoading: false,
};

const billing = createReducer<BillingState, Actions>(initialState)
  .handleAction(initialize.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(
    initialize.success,
    (state, { payload: { currentPlan, licenses, stripe, tiers } }) => {
      const annual = currentPlan.tier.interval === SubscriptionInterval.ANNUAL;
      const selectedTier =
        findTierByIntervalAndLicenses(
          tiers,
          annual ? SubscriptionInterval.ANNUAL : SubscriptionInterval.MONTHLY,
          licenses
        ) || currentPlan.tier;
      return {
        ...state,
        annual: selectedTier.interval === SubscriptionInterval.ANNUAL,
        currentPlan,
        licenses,
        loading: false,
        priceInfo: calculatePrices(currentPlan, selectedTier, licenses),
        selectedTier,
        stripe,
        tiers,
      };
    }
  )
  .handleAction(initialize.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(upgrade.request, (state) => ({
    ...state,
    upgradeError: undefined,
    upgradeLoading: true,
  }))
  .handleAction(upgrade.success, (state) => ({
    ...state,
    showSuccessState: true,
    upgradeLoading: false,
  }))
  .handleAction(upgrade.failure, (state, action) => ({
    ...state,
    upgradeError: action.payload,
    upgradeLoading: false,
  }))
  .handleAction(resetSuccessState, (state) => ({
    ...state,
    showSuccessState: false,
  }))
  .handleAction(setAnnual, (state, action) => {
    const selectedTier =
      findTierByIntervalAndLicenses(
        state.tiers,
        action.payload ? SubscriptionInterval.ANNUAL : SubscriptionInterval.MONTHLY,
        state.licenses
      ) || state.selectedTier;
    return {
      ...state,
      annual: action.payload,
      priceInfo: calculatePrices(state.currentPlan!, selectedTier!, state.licenses),
      selectedTier,
    };
  })
  .handleAction(setLicenses, (state, action) => {
    const selectedTier =
      findTierByIntervalAndLicenses(
        state.tiers,
        state.annual ? SubscriptionInterval.ANNUAL : SubscriptionInterval.MONTHLY,
        action.payload
      ) ?? state.selectedTier;
    return {
      ...state,
      annual: selectedTier?.interval === SubscriptionInterval.ANNUAL,
      licenses: action.payload,
      priceInfo: calculatePrices(state.currentPlan!, selectedTier!, action.payload),
      selectedTier,
    };
  })
  .handleAction(showCheckoutModal, (state) => ({
    ...state,
    checkoutModalVisible: true,
  }))
  .handleAction(hideCheckoutModal, (state) => ({
    ...state,
    checkoutModalVisible: false,
  }));

export * from "./selectors";
export type BillingActions = Actions;
export default billing;
