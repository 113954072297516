// time is a string in HH:MM[:ss][.000][Z] format. Usually returned by platform as a value
// for the time-typed custom fields. Since time pickers still work with the date
// objects, we need to convert that time into a Date. Where date-part can be
// anything, but usually "today" is used for that.
import { formatDate } from "../formatters";

const parseTime = (time: string): Date => {
  // remove timezone and seconds fraction from a string
  const timeStringValue = time.replace(/\..*$/, "");
  const hasSeconds = timeStringValue.split(":").length > 2;
  const timeStringValueWithSeconds = hasSeconds ? timeStringValue : `${timeStringValue}:00`;
  const localDateTimeString = `${formatDate(
    Date.now(),
    "yyyy-MM-dd"
  )}T${timeStringValueWithSeconds}`;
  return new Date(localDateTimeString);
};

export const parseTime24hr = (time: string | undefined): Date => {
  const hour = Number(time?.substring(0, 2) ?? 0);
  const minute = Number(time?.substring(2) ?? 0);

  const date = new Date();
  date.setHours(hour, minute);

  return date;
};

export default parseTime;
