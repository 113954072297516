import { createReducer } from "typesafe-actions";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import ActivitiesOverdueCardConfiguration from "@app/types/dashboard/cards/activitiesOverdue/ActivitiesOverdueCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";
import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  ActivitiesOverdueCardActions,
  applyActivitiesOverdueDrillDownListViewSettings,
  fetchActivitiesOverdueCardDrillDownData,
  hideActivitiesOverdueDrillDown,
  showActivitiesOverdueDrillDown,
} from "./actions";

const defaultColumns: string[] = [
  ActivityFieldName.PRIMARY_ASSOCIATION,
  ActivityFieldName.ACTIVITY_TYPE,
  ActivityFieldName.ASSIGNEE,
  ActivityFieldName.START_AT,
  ActivityFieldName.NOTE,
];

const defaultSortColumn = ActivityFieldName.START_AT;

export interface ActivitiesOverdueCardState {
  configuration?: ActivitiesOverdueCardConfiguration; // also works as a "visible" trigger, visible if set
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const activitiesOverdueInitialState: ActivitiesOverdueCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: getDefaultDrillDownColumns(defaultColumns, activityFieldModel),
    filter: {},
    range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
    sort: [
      {
        field: activityFieldModel.getByName(defaultSortColumn)!,
        order: SortOrder.DESC,
      },
    ],
    viewAs: undefined,
  },
};

const activitiesOverdueState = createReducer<
  ActivitiesOverdueCardState,
  ActivitiesOverdueCardActions
>(activitiesOverdueInitialState)
  .handleAction(showActivitiesOverdueDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
  }))
  .handleAction(showActivitiesOverdueDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideActivitiesOverdueDrillDown, (state) => ({ ...state, configuration: undefined }))
  .handleAction(fetchActivitiesOverdueCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchActivitiesOverdueCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchActivitiesOverdueCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyActivitiesOverdueDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      columns: payload.columns ?? state.viewState.columns,
      filter: payload.filter ?? state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default activitiesOverdueState;
