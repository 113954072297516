import { createSelector } from "reselect";

import { EntityType } from "@mapmycustomers/shared/enum";

import { RootState } from "@app/store/rootReducer";

import getValidStops from "../util/getValidStops";

export const routeBuilderState = (state: RootState) => state.scene.activityRouteBuilder;

export const getEntityType = createSelector(routeBuilderState, ({ entityType }) => entityType);
export const getRange = createSelector(routeBuilderState, ({ dateRange }) => dateRange);
export const getRangeType = createSelector(routeBuilderState, ({ dateRangeType }) => dateRangeType);
export const getSelectedActivityTypes = createSelector(
  routeBuilderState,
  ({ selectedActivityTypes }) => selectedActivityTypes
);
export const getRemovedStops = createSelector(
  routeBuilderState,
  ({ removedStops }) => removedStops
);

export const getActivities = createSelector(routeBuilderState, ({ activities }) => activities);
export const getFilteredActivities = createSelector(
  getActivities,
  getRemovedStops,
  (activities, removedStops) => {
    const removedActivityIds = new Set(removedStops);
    return activities.filter(({ id }) => !removedActivityIds.has(id));
  }
);

export const areActivitiesLoading = createSelector(
  routeBuilderState,
  ({ activitiesLoading }) => activitiesLoading
);

export const getStops = createSelector(
  getFilteredActivities,
  getEntityType,
  (activities, entityType) => getValidStops(activities, entityType)
);

export const getInvalidStops = createSelector(
  getFilteredActivities,
  getEntityType,
  (activities, entityType) =>
    activities.filter(({ account, contact }) =>
      entityType === EntityType.COMPANY ? !account?.geoPoint : !contact?.geoPoint
    )
);

export const isCreatingRoute = createSelector(
  routeBuilderState,
  ({ createLoading }) => createLoading
);
