import EntityType from "@mapmycustomers/shared/enum/EntityType";

import noCompaniesImage from "@app/assets/list/preview/no-companies.svg";
import noDealsImage from "@app/assets/list/preview/no-deals.svg";
import noPeopleImage from "@app/assets/list/preview/no-people.svg";

const noRecordsImageMap = {
  [EntityType.COMPANY]: noCompaniesImage,
  [EntityType.DEAL]: noDealsImage,
  [EntityType.PERSON]: noPeopleImage,
} as const;

export default noRecordsImageMap;
