import { useCallback } from "react";

import { FormInstance } from "antd/es/form";
import { differenceInHours } from "date-fns/esm";
import omit from "lodash-es/omit";
import { IntlShape } from "react-intl";

import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import localSettings from "@app/config/LocalSettings";
import { ActivityPayload } from "@app/store/api/ApiService";
import HideAction from "@app/types/HideCallback";
import { ACTIVITY_TIME_DIFFERENCE_ALERT } from "@app/util/activity/const";
import showActivityTimeUpdateAlert from "@app/util/activity/showActivityTimeUpdateAlert";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";
import { parseApiDate } from "@app/util/parsers";

import { updateActivity } from "../store/actions";
import FormValues from "../types/FormValues";

import { stringifyActivityInterval } from "./activityInterval";

const useUpdateCallback = (
  activity: Activity | undefined,
  activityTypes: ActivityType[],
  form: FormInstance<FormValues>,
  formLayout: FormLayout | undefined,
  activityMarkedDone: boolean,
  onHide: HideAction<Activity>,
  updateActivityRequest: typeof updateActivity.request,
  intl: IntlShape,
  analyticIssuer?: AnalyticsService
) =>
  useCallback(() => {
    if (!activity || !formLayout) {
      return;
    }

    const values = form.getFieldsValue(true);
    const activityType = activityTypes.find(({ id }) => id === values.activityTypeId);

    const activityPayload: ActivityPayload = {
      account: values.companyId ? { id: values.companyId } : null,
      allDay: values.allDay ?? null,
      assigneeId: values.assignee?.id,
      completed: values.completed ?? null,
      contact: values.personId ? { id: values.personId } : null,
      crmActivityType: { id: values.activityTypeId! },
      deal: values.dealId ? { id: values.dealId } : null,
      endAt: values.allDay ? null : values.endAt?.toISOString() ?? null,
      name: values.name || activityType?.name || "",
      note: values.note ?? null,
      recurInterval: values.recurInterval ? stringifyActivityInterval(values.recurInterval) : null,
      reminders: values.reminders,
      startAt: values.startAt?.toISOString() ?? null,
      teamId: values.teamIds,
      visibility: values.visibility ?? null,
    };

    const customFieldsValues: CustomFieldValue[] = values.customFields
      ? Object.values<CustomFieldValue>(values.customFields).filter(isDefined)
      : [];

    localSettings.setActivityCreationNotifyPreferences(values.reminders);

    const startAt = activity?.startAt ? parseApiDate(activity.startAt) : undefined;
    const endAt = activity?.endAt ? parseApiDate(activity.endAt) : undefined;
    const isTimeFieldsTouched = form.isFieldsTouched(["startAt", "endAt"]);

    const updateActivity = (updatedActivity?: Activity) => {
      updateActivityRequest({
        activity: {
          ...omit(activity, "isPublic", "remindVia", "remindBeforeMinutes"), // Need to remove these keys from payload for backward compatibility, Can be removed once fully depreciated by platform
          ...activityPayload,
          endAt: updatedActivity?.endAt ?? activityPayload?.endAt,
          startAt: updatedActivity?.startAt ?? activityPayload?.startAt,
        },
        callback: (updated?: boolean, updatedActivity?: Activity) => {
          onHide(updated, updatedActivity);
          analyticIssuer?.feature(["Activity Updated"], { activity: activityPayload });
        },
        customFieldsValues,
        layoutId: formLayout.id,
        updateType: values.updateType,
      });
    };

    if (
      activityMarkedDone &&
      startAt &&
      !isTimeFieldsTouched &&
      Math.abs(differenceInHours(Date.now(), endAt ?? startAt)) >= ACTIVITY_TIME_DIFFERENCE_ALERT
    ) {
      showActivityTimeUpdateAlert(intl, activity, updateActivity);
    } else {
      updateActivity();
    }
  }, [
    activity,
    activityMarkedDone,
    activityTypes,
    analyticIssuer,
    form,
    formLayout,
    intl,
    onHide,
    updateActivityRequest,
  ]);

export default useUpdateCallback;
