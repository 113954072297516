import { EntityType, EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";

export const MAP_ENTITY_TYPES: EntityTypesSupportedByMapsPage[] = [
  EntityType.COMPANY,
  EntityType.PERSON,
  EntityType.DEAL,
];

export const OTHER_CLUSTER_ZINDEX = 1;
export const OTHER_PIN_ZINDEX = OTHER_CLUSTER_ZINDEX + 1;
export const BASE_CLUSTER_ZINDEX = OTHER_PIN_ZINDEX + 1;
export const PIN_ZINDEX = BASE_CLUSTER_ZINDEX + 100000; // should be higher than any cluster
export const BASE_LEAD_ICON_ZINDEX = PIN_ZINDEX + 1; // higher than regular pin
export const TERRITORY_LABEL_ZINDEX = BASE_LEAD_ICON_ZINDEX + 1; // higher than all pins
export const DEMOGRAPHY_LABEL_ZINDEX = BASE_LEAD_ICON_ZINDEX + 1; // higher than all pins
export const USER_LOCATION_ZINDEX = TERRITORY_LABEL_ZINDEX + 1;
export const USER_LOCATION_HISTORY_PATH_ZINDEX = USER_LOCATION_ZINDEX + 1;
export const USER_LOCATION_HISTORY_ZINDEX = USER_LOCATION_HISTORY_PATH_ZINDEX + 1;
export const SELECTED_USER_LOCATION_HISTORY_ZINDEX = USER_LOCATION_HISTORY_ZINDEX + 1;
export const DEMOGRAPHY_FEATURE_ZINDEX = SELECTED_USER_LOCATION_HISTORY_ZINDEX + 1;
export const TERRITORY_FEATURE_ZINDEX = DEMOGRAPHY_FEATURE_ZINDEX + 3;
export const LASSO_ZINDEX = TERRITORY_FEATURE_ZINDEX + 1; // higher than all other map features and demography feature
export const AREA_REGION_ZINDEX = LASSO_ZINDEX + 1;
export const AREA_REGION_UNITED_REGION = AREA_REGION_ZINDEX + 1;
export const FOCUSED_ENTITY_ZINDEX = AREA_REGION_UNITED_REGION + 1;
export const BASE_TOOLTIP_ZINDEX = FOCUSED_ENTITY_ZINDEX + 1; // higher than all pins and labels
