import React, { useCallback } from "react";

import cn from "classnames";
import { useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { NumberField, NumberFieldComponent } from "@mapmycustomers/ui";

import styles from "./RottingDaysOutFilter.module.scss";

export const ROTTING_DAYS_OUT_FILTER_OPERATORS = [
  FilterOperator.IS_OVERDUE,
  FilterOperator.IS_UPCOMING,
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_THAN_OR_EQUAL,
  FilterOperator.LESS_THAN,
  FilterOperator.LESS_THAN_OR_EQUAL,
];

interface RottingDaysOutFilterProps extends IFilterComponentProps {}

const RottingDaysOutFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return typeof value === "number" && ROTTING_DAYS_OUT_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<RottingDaysOutFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const intl = useIntl();

      const setRef = useCallback(
        (ref: null | NumberFieldComponent) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      if ([FilterOperator.IS_OVERDUE, FilterOperator.IS_UPCOMING].includes(value.operator)) {
        return null;
      }

      return (
        <div className={cn(styles.container)}>
          <NumberField
            inputClassName={className}
            onChange={(number) => onChange?.({ ...value, value: number })}
            ref={setRef}
            size="middle"
            value={value?.value ?? ""}
          />
          <span className={styles.suffix}>
            {intl.formatMessage({
              id: "filters.rottingDaysOut.suffix",
              defaultMessage: "days",
              description: "Days suffix in rotting days out filter",
            })}
          </span>
        </div>
      );
    },
};

export default RottingDaysOutFilter;
