import localSettings from "@app/config/LocalSettings";
import { getLanguageRegion } from "@app/util/i18n/findLibraryLocale";
import loggingService from "@app/util/logging";

import configService from "../config/ConfigService";

const MAPS_SCRIPT_ID = "mmc-google-maps-script";
export const CALLBACK_NAME = "__mmc_maps_callback__";

let mapsLoadingPromise: Promise<void> | undefined = undefined;

// TODO: move this into GeoService
export const ensureGoogleMapsApiIsLoaded = async () => {
  // do not load again if already loading or loaded
  if (document.getElementById(MAPS_SCRIPT_ID) || mapsLoadingPromise) {
    return mapsLoadingPromise;
  }

  // generate correct script url depending on whether googleClient param is specified or googleKey
  let url: string;
  const result = getLanguageRegion(localSettings.getLocale());
  const localeParams = `&language=${result?.language ?? "en"}${
    result?.region ? `&region=${result.region}` : ""
  }`;
  if (configService.getGoogleClient()) {
    url = `https://maps.googleapis.com/maps/api/js?libraries=visualization,geometry,drawing,places&client=${configService.getGoogleClient()}&callback=${CALLBACK_NAME}${localeParams}`;
  } else if (configService.getGoogleKey()) {
    url = `https://maps.googleapis.com/maps/api/js?libraries=visualization,geometry,drawing,places&key=${configService.getGoogleKey()}&callback=${CALLBACK_NAME}${localeParams}`;
  } else {
    loggingService.error("Configuration issue, can't load google maps");
    return Promise.reject();
  }

  mapsLoadingPromise = new Promise((resolve) => {
    window[CALLBACK_NAME] = resolve;

    const script = document.createElement("script");
    script.id = MAPS_SCRIPT_ID;
    script.src = url;
    script.defer = true;
    document.head.appendChild(script);
  });

  return mapsLoadingPromise;
};
