import React from "react";
import { connect } from "react-redux";

import { isFrequencyModalVisible } from "@app/store/frequency";
import { RootState } from "@app/store/rootReducer";

import FrequencyModal from "../../frequency/FrequencyModal";

interface Props {
  modalVisible: boolean;
}

const FrequencyModalWrapper: React.FC<Props> = ({ modalVisible }) => {
  if (!modalVisible) {
    return null;
  }

  return <FrequencyModal />;
};

const mapStateToProps = (state: RootState) => ({
  modalVisible: isFrequencyModalVisible(state),
});

export default connect(mapStateToProps)(FrequencyModalWrapper);
