import { defineMessages, IntlShape } from "react-intl";

import FieldVisibility, {
  FieldTeamVisibility,
  FieldVisibilityFilter,
  FieldVisibilityValues,
} from "@mapmycustomers/shared/enum/FieldVisibility";

export const visibilityNames = defineMessages<FieldVisibility>({
  [FieldVisibility.FULL_ACCESS]: {
    id: "fieldVisibility.fullAccess",
    defaultMessage: "All users can view and edit",
    description: "Field Visibility title for full access visibility",
  },
  [FieldVisibility.PRIVATE]: {
    id: "fieldVisibility.private",
    defaultMessage: "Private to Team Owners",
    description: "Field Visibility title for private visibility",
  },
  [FieldVisibility.PUBLIC]: {
    id: "fieldVisibility.public",
    defaultMessage: "All users can view only",
    description: "Field Visibility title for public visibility",
  },
  [FieldVisibility.TEAMS]: {
    id: "fieldVisibility.teams",
    defaultMessage: "Specific roles and/or teams",
    description: "Field Visibility title for teams visibility",
  },
});

export const visibilityFilterNames = defineMessages<FieldVisibility | FieldVisibilityFilter>({
  [FieldVisibility.FULL_ACCESS]: {
    id: "fieldVisibilityFilter.fullAccess",
    defaultMessage: "Users can view and edit",
    description: "Field Visibility title for full access visibility",
  },
  [FieldVisibility.PRIVATE]: {
    id: "fieldVisibilityFilter.private",
    defaultMessage: "Private to Team Owners",
    description: "Field Visibility title for private visibility",
  },
  [FieldVisibility.PUBLIC]: {
    id: "fieldVisibilityFilter.public",
    defaultMessage: "Users can view only",
    description: "Field Visibility title for public visibility",
  },
  [FieldVisibility.TEAMS]: {
    id: "fieldVisibilityFilter.teams",
    defaultMessage: "Specific roles and/or teams",
    description: "Field Visibility title for teams visibility",
  },
  [FieldVisibilityFilter.ALL]: {
    id: "fieldVisibilityFilter.all",
    defaultMessage: "Any Access",
    description: "Field Visibility filter - all",
  },
});

export const teamVisibilityNames = defineMessages<FieldTeamVisibility>({
  [FieldVisibility.FULL_ACCESS]: {
    id: "fieldTeamVisibility.fullAccess",
    defaultMessage: "View and edit",
    description: "View and edit permission",
  },
  [FieldVisibility.PRIVATE]: {
    id: "fieldTeamVisibility.private",
    defaultMessage: "No access",
    description: "No access permission",
  },
  [FieldVisibility.PUBLIC]: {
    id: "fieldTeamVisibility.public",
    defaultMessage: "View only",
    description: "View only permission",
  },
});

export const teamPhiVisibilityNames = defineMessages<FieldTeamVisibility>({
  [FieldVisibility.FULL_ACCESS]: {
    id: "fieldTeamVisibility.fullAccess.phi",
    defaultMessage: "View and edit",
    description: "View and edit permission",
  },
  [FieldVisibility.PRIVATE]: {
    id: "fieldTeamVisibility.private.phi",
    defaultMessage: "No access (PHI)",
    description: "No access permission",
  },
  [FieldVisibility.PUBLIC]: {
    id: "fieldTeamVisibility.public.phi",
    defaultMessage: "View only",
    description: "View only permission",
  },
});

export const getFieldVisibilityName = (intl: IntlShape, fieldVisibility: FieldVisibility) =>
  intl.formatMessage(visibilityNames[fieldVisibility]);

export const getFieldVisibilityFilterName = (
  intl: IntlShape,
  visibilityFilter: FieldVisibilityValues
) => intl.formatMessage(visibilityFilterNames[visibilityFilter]);

export const getFieldTeamVisibilityName = (
  intl: IntlShape,
  visibility: FieldTeamVisibility,
  phiEnabled?: boolean
) => intl.formatMessage((phiEnabled ? teamPhiVisibilityNames : teamVisibilityNames)[visibility]);
