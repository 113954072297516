import { isAfter } from "date-fns/esm";

import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

import FrequencyCriteria from "../../types/frequncy/FrequencyCriteria";

const doesActivityMeetFrequencyCriteria = (
  startAt: Date,
  activityTypeId?: ActivityType["id"],
  criteria?: FrequencyCriteria
): boolean => {
  return !!(
    !criteria ||
    (criteria &&
      !isAfter(startAt, criteria?.dueDate) &&
      (!criteria.activityTypesIds ||
        !criteria.activityTypesIds.length ||
        (activityTypeId && criteria.activityTypesIds.includes(activityTypeId))))
  );
};

export default doesActivityMeetFrequencyCriteria;
