import { ColDef } from "@ag-grid-community/core";
import memoize from "fast-memoize";
import { createSelector } from "reselect";

import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import { RootState } from "@app/store/rootReducer";

const peopleState = (state: RootState) => state.scene.people;

export const getGridColumnDefs = createSelector(peopleState, ({ columns }) =>
  memoize((forClientSideGrid?: boolean) => {
    // get field's gridProperties && also add width/pinned/hide props from the column model
    return columns
      .filter(({ field }) => field.isReadable || field.isSystemRequired)
      .map<ColDef>(({ field, pinned, visible, width }) => ({
        ...(field.gridProperties(forClientSideGrid) ?? {}),
        hide: !visible,
        pinned,
        width,
      }));
  })
);

export const getListViewState = createSelector(
  peopleState,
  ({ columns, filter, range, selectedSavedFilterId, sort, viewAs }): ViewState => ({
    columns,
    filter,
    range,
    selectedSavedFilterId,
    sort,
    viewAs,
  })
);

export const isLoading = createSelector(peopleState, ({ loading }) => loading);

export const getTotalFilteredRecordsCount = createSelector(
  peopleState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getTotalRecordsCount = createSelector(peopleState, ({ totalRecords }) => totalRecords);

export const getOfferedFilters = createSelector(peopleState, ({ offerFilters }) => offerFilters);
