import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import loggingService from "@app/util/logging";

const isLessThanCondition = (condition: Condition) =>
  Object.keys(condition).length === 2 &&
  PlatformFilterOperator.GREATER_THAN_OR_EQUAL in condition &&
  (PlatformFilterOperator.LESS_THAN_OR_EQUAL in condition ||
    PlatformFilterOperator.LESS_THAN in condition) &&
  (condition[PlatformFilterOperator.GREATER_THAN_OR_EQUAL] as number) === 0;

const parseRottingDaysOutCondition = (
  platformCondition: Condition
): FilterCondition | undefined => {
  if (isLessThanCondition(platformCondition)) {
    return PlatformFilterOperator.LESS_THAN_OR_EQUAL in platformCondition
      ? {
          operator: FilterOperator.LESS_THAN_OR_EQUAL,
          value: platformCondition[PlatformFilterOperator.LESS_THAN_OR_EQUAL] as number,
        }
      : {
          operator: FilterOperator.LESS_THAN,
          value: platformCondition[PlatformFilterOperator.LESS_THAN] as number,
        };
  }

  if (Object.keys(platformCondition).length !== 1) {
    loggingService.warning(
      "Invalid condition for rottingDaysOut field: not range condition but has number of operators greater than 1"
    );
    return undefined;
  }

  const filterOperator = Object.keys(platformCondition)[0] as PlatformFilterOperator;
  const filterValue = platformCondition[filterOperator] as number;

  if (filterOperator === PlatformFilterOperator.LESS_THAN) {
    if (filterValue === 0) {
      return { operator: FilterOperator.IS_UPCOMING, value: 0 };
    }
  } else if (filterOperator === PlatformFilterOperator.GREATER_THAN_OR_EQUAL) {
    return filterValue === 0
      ? { operator: FilterOperator.IS_OVERDUE, value: 0 }
      : { operator: FilterOperator.GREATER_THAN_OR_EQUAL, value: filterValue };
  } else if (filterOperator === PlatformFilterOperator.GREATER_THAN) {
    return { operator: FilterOperator.GREATER_THAN, value: filterValue };
  }

  loggingService.warning("Invalid condition for rottingDaysOut field: unexpected operator");
  return undefined;
};

export default parseRottingDaysOutCondition;
