import { defineMessages, IntlShape } from "react-intl";

enum ViewType {
  DASHBOARD = "dashboard",
  TIMELINE = "timeline",
}

const messages = defineMessages<ViewType>({
  [ViewType.DASHBOARD]: {
    id: "dashboard.viewType.dashboard",
    defaultMessage: "Dashboard",
    description: "Dashboard View title on the Dashboard page",
  },
  [ViewType.TIMELINE]: {
    id: "dashboard.viewType.timeline",
    defaultMessage: "Timeline",
    description: "Timeline View title on the Dashboard page",
  },
});

export const getViewTypeDisplayName = (intl: IntlShape, viewType: ViewType) =>
  intl.formatMessage(messages[viewType]);

export default ViewType;
