import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

import { DEFAULT_EMPTINESS_OPERATOR } from "@app/util/filters/defaultFilters";
import EmptyFilter from "@app/util/filters/EmptyFilter";
import LocationFilter, { LOCATION_FILTER_OPERATORS } from "@app/util/filters/Location";

import Field, { FieldProperties } from "./Field";
import { locationFormatter } from "./fieldUtil";

class BaseLocationField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "location",
        filterInstances: {
          empty: EmptyFilter,
          location: LocationFilter,
        },
        operators: [...LOCATION_FILTER_OPERATORS, ...DEFAULT_EMPTINESS_OPERATOR],
      },
      customGridProperties: {
        cellRenderer: "locationCellRenderer",
        // disable tooltips for this field, since we use custom renderer with a popover
        tooltipValueGetter: undefined,
      },
      type: FieldType.OBJECT,
      valueFormatter: locationFormatter,
      ...data,
    });
  }
}

export default BaseLocationField;
