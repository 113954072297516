import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import Select, { RefSelectProps } from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import Color from "@mapmycustomers/shared/enum/Color";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import { Route } from "@mapmycustomers/shared/types/entity";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";

import EntityBadge from "@app/component/EntityBadge";
import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import { fetchCompanyRoutes, fetchPeopleRoutes } from "@app/store/routes/actions";

import styles from "./RoutesFilter.module.scss";

interface Props {
  className?: string;
  focus?: boolean;
  onChange?: (value: SimpleCondition) => void;
  onFetchCompanyRoutes?: (payload: { query?: string }) => void;
  onFetchPeopleRoutes?: (payload: { query?: string }) => void;
  options: Partial<Record<FilterOption, unknown>> | undefined;
  value: SimpleCondition;
}

const RoutesFilter: React.FC<Props> = ({
  className,
  focus,
  onChange,
  onFetchCompanyRoutes,
  onFetchPeopleRoutes,
  options,
  value,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onFetchPeopleRoutes?.({});
    onFetchCompanyRoutes?.({});
  }, [onFetchPeopleRoutes, onFetchCompanyRoutes]);

  const setRef = useCallback(
    (ref: null | RefSelectProps) => {
      if (focus && ref) {
        ref.focus();
      }
    },
    [focus]
  );

  const handleSearch = useDebouncedCallback(
    [
      (value: string) => {
        (options?.entityType === EntityType.COMPANY ? onFetchCompanyRoutes : onFetchPeopleRoutes)?.(
          { query: value }
        );
      },
      500,
    ],
    [onFetchCompanyRoutes, onFetchPeopleRoutes, options?.entityType]
  );

  const handleBlur = useCallback(() => {
    (options?.entityType === EntityType.COMPANY ? onFetchCompanyRoutes : onFetchPeopleRoutes)?.({});
  }, [onFetchCompanyRoutes, onFetchPeopleRoutes, options?.entityType]);

  return (
    <Select<Array<Route["id"]>>
      className={cn(styles.container, className)}
      filterOption={defaultFilterOption}
      mode="multiple"
      onBlur={handleBlur}
      onChange={(routeIds: Route["id"][]) => onChange?.({ ...value, value: routeIds })}
      onSearch={handleSearch}
      placeholder={intl.formatMessage({
        id: "filters.route.select.placeholder",
        defaultMessage: "Click or type to select routes",
        description: "Placeholder displayed in a RouteFilter's select field",
      })}
      ref={setRef}
      value={Array.isArray(value.value) ? value.value : []}
    >
      {((options?.availableRoutes as Route[]) ?? []).map((route) => (
        <Select.Option key={route.id} label={route.name} value={route.id}>
          <EntityBadge
            entity={{
              color: Color.GREY,
              name: route.name,
            }}
          />
        </Select.Option>
      ))}
    </Select>
  );
};

const mapDispatchToProps = {
  onFetchCompanyRoutes: fetchCompanyRoutes.request,
  onFetchPeopleRoutes: fetchPeopleRoutes.request,
};

export default connect(null, mapDispatchToProps)(RoutesFilter);
