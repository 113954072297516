import { useEffect, useRef } from "react";

import { FeatureType } from "@app/component/map/FeaturedMap/types";
import {
  FEATURE_DATA_PROPERTY,
  FEATURE_TYPE_PROPERTY,
} from "@app/component/map/FeaturedMap/utils/consts";
import useMap from "@app/component/map/FeaturedMap/utils/useMap";
import useMapDataEventHandler from "@app/util/hook/map/useMapDataEventHandler";
import shallowComparison from "@app/util/shallowComparison";

interface Props<T> {
  geometryGetter?: (
    item: T
  ) => Exclude<google.maps.Data.FeatureOptions["geometry"], undefined> | undefined;
  idGetter: (item: T, type?: string) => number | string;
  isDifferent?: (a: T, b: T) => boolean;
  items: Iterable<T>;
  onClick?: (item: T, event: google.maps.MapMouseEvent) => void;
  onDoubleClick?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseDown?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseLeave?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseMove?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseOver?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseUp?: (item: T, event: google.maps.MapMouseEvent) => void;
  onRightClick?: (item: T, event: google.maps.MapMouseEvent) => void;
  styleGetter?: (item: T) => google.maps.Data.StyleOptions;
  type: FeatureType;
}

const Feature = <T,>({
  geometryGetter,
  idGetter,
  isDifferent = shallowComparison,
  items,
  onClick,
  onDoubleClick,
  onMouseDown,
  onMouseLeave,
  onMouseMove,
  onMouseOver,
  onMouseUp,
  onRightClick,
  styleGetter,
  type,
}: Props<T>) => {
  const { map, registerStylingFunction, unregisterStylingFunction } = useMap();

  // useEffect to update style function for the feature of this type
  useEffect(() => {
    // loggingService.debug(`useEffect for styling is called for Feature with type "${type}"`);
    if (!styleGetter) {
      return;
    }
    registerStylingFunction(type, (feature: google.maps.Data.Feature) => {
      const data = feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      return data ? styleGetter(data) : {};
    });
  }, [registerStylingFunction, styleGetter, type]);

  // only call unregister when component is unmounted or type changed
  useEffect(
    () => () => {
      unregisterStylingFunction(type);
    },
    [map, type, unregisterStylingFunction]
  );

  const previousIdGetter = useRef(idGetter);
  const previousGeometryGetter = useRef(geometryGetter);

  // useEffect to update features for the given items
  useEffect(() => {
    // loggingService.debug(`useEffect for data is called for Feature with type "${type}"`);
    const typedIdToItem = new Map<number | string, T>();
    for (const item of items) {
      typedIdToItem.set(`${type}_${idGetter(item, type)}`, item);
    }

    // iterate over feature and try to update features
    // for older items
    map.data.forEach((feature) => {
      const featureType = feature.getProperty(FEATURE_TYPE_PROPERTY);
      if (featureType !== type) {
        return; // skip not ours features
      }
      if (previousIdGetter.current !== idGetter) {
        // typedId getter has changed, we can not rely on matching previous
        // items ids' and current items ids'. Hence, we delete all
        // old items and add them again.
        map.data.remove(feature);
        return;
      }

      const typedId = feature.getId();
      if (typedId !== undefined && typedIdToItem.has(typedId)) {
        const data = typedIdToItem.get(typedId)!;
        const oldData: T = feature.getProperty(FEATURE_DATA_PROPERTY) as T;
        if (isDifferent(oldData, data)) {
          // force re-draw
          feature.setProperty(FEATURE_DATA_PROPERTY, data);
          // update geometry too, since data changed
          if (geometryGetter) {
            const geometry = geometryGetter(data);
            if (geometry) {
              feature.setGeometry(geometry);
            }
          }
        } else if (previousGeometryGetter.current !== geometryGetter && geometryGetter) {
          // or at least update geometry if geometry getter has changed
          const geometry = geometryGetter(data);
          if (geometry) {
            feature.setGeometry(geometry);
          }
        }
        typedIdToItem.delete(typedId); // delete from the map to indicate that this item is already added in maps data
      } else {
        // if map feature no longer in the items array, delete it
        map.data.remove(feature);
      }
    });

    previousIdGetter.current = idGetter;
    previousGeometryGetter.current = geometryGetter;

    // now let's add new items (all existing items were deleted in a for-loop above)
    typedIdToItem.forEach((item, typedId) => {
      try {
        map.data.add({
          id: typedId,
          geometry: geometryGetter?.(item),
          properties: {
            [FEATURE_DATA_PROPERTY]: item,
            [FEATURE_TYPE_PROPERTY]: type,
          },
        });
      } catch (e) {
        console.error("!!! feature fail", type, item, e);
      }
    });
  }, [geometryGetter, idGetter, isDifferent, items, map, type]);

  // remove all our features when component is unmounted
  useEffect(
    () => () =>
      map.data.forEach((feature) => {
        if (feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
          map.data.remove(feature);
        }
      }),
    [map, type]
  );

  useMapDataEventHandler(map, "click", (event) => {
    if (onClick && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onClick(data, event);
      }
    }
  });

  useMapDataEventHandler(map, "dblclick", (event) => {
    if (onDoubleClick && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onDoubleClick(data, event);
      }
    }
  });

  useMapDataEventHandler(map, "mousemove", (event) => {
    if (onMouseMove && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onMouseMove(data, event);
      }
    }
  });

  useMapDataEventHandler(map, "mouseover", (event) => {
    if (onMouseOver && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onMouseOver(data, event);
      }
    }
  });

  useMapDataEventHandler(map, "mouseout", (event) => {
    if (onMouseLeave && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onMouseLeave(data, event);
      }
    }
  });

  useMapDataEventHandler(map, "mousedown", (event) => {
    if (onMouseDown && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onMouseDown(data, event);
      }
    }
  });

  useMapDataEventHandler(map, "mouseup", (event) => {
    if (onMouseUp && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onMouseUp(data, event);
      }
    }
  });

  useMapDataEventHandler(map, "contextmenu", (event) => {
    if (onRightClick && event.feature.getProperty(FEATURE_TYPE_PROPERTY) === type) {
      const data = event.feature.getProperty(FEATURE_DATA_PROPERTY) as T;
      if (data) {
        onRightClick(data, event);
      }
    }
  });

  return null;
};

export default Feature;
