import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { faLocationArrow, faLock } from "@fortawesome/pro-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { isEqual } from "lodash-es";

import Address from "@mapmycustomers/shared/types/Address";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import Region from "@mapmycustomers/shared/types/Region";
import { isEmptyString } from "@mapmycustomers/shared/util/stringUtils";

import { useConfigProvider } from "../../../../ConfigProvider";
import LoadingSpinner from "../../../../LoadingSpinner";
import layout from "../../../../styles/layout";
import InlineButtons from "../../../inline/InlineButtons";
import InlineSelectField from "../../../inline/SelectField";
import InlineTextField from "../../../inline/SingleLineTextField";
import Labeled, { LabeledFieldProps } from "../../../Labeled";
import useCountryListOptions from "../../utils/useCountryListOptions";

import RegionField from "./RegionField";

const block = bem("mmc-inline-manual-address");

interface InlineManualAddressProps extends Omit<LabeledFieldProps, "children"> {
  disabled?: boolean;
  extra?: ReactNode;
  onChange?: (address?: Address) => void;
  onReverseGeocodeAddress: (payload: {
    callback: (result: GeocodeResult) => void;
    coordinates: LongLat;
    failureCallback?: () => void;
  }) => void;
  onToggleManualAddress: () => void;
  value?: Address;
}

const ManualAddress: React.FC<InlineManualAddressProps> = ({
  disabled,
  extra,
  label,
  labelClassName,
  labelPosition = "side",
  onChange,
  onReverseGeocodeAddress,
  onToggleManualAddress,
  required,
  value,
}) => {
  const { formatMessage, useFindMyLocation } = useConfigProvider();
  const [address, setAddress] = useState(value);
  const isAddressChanged = !isEqual(address, value);

  const handleAddressChange = useCallback(
    (address: string) =>
      setAddress((currentAddress) => ({
        ...currentAddress,
        address: address.length > 0 ? address : undefined,
      })),
    []
  );

  const handleCountryCodeChange = useCallback((countryCode = null, row) => {
    setAddress((currentAddress) => ({
      ...currentAddress,
      country: row ? row.text : null,
      countryCode,
      region: undefined,
      regionCode: undefined,
    }));
  }, []);

  const handleCityChange = useCallback(
    (city: string) =>
      setAddress((currentAddress) => ({
        ...currentAddress,
        city: city.length > 0 ? city : undefined,
      })),
    []
  );

  const handleRegionChange = useCallback(
    (region: Region) => setAddress((currentAddress) => ({ ...currentAddress, ...region })),
    []
  );

  const handlePostalCodeChange = useCallback(
    (postalCode: string) =>
      setAddress((currentAddress) => ({
        ...currentAddress,
        postalCode: postalCode.length > 0 ? postalCode : undefined,
      })),
    []
  );

  const handleSave = useCallback(() => {
    onChange?.(address);
  }, [address, onChange]);

  const handleCancel = useCallback(() => {
    setAddress(value);
  }, [value]);

  // Find Me functionality:
  const [, , findMeLoading, , handleFindMyLocation, geocodingResult, resetError] =
    useFindMyLocation(onReverseGeocodeAddress);
  const lastGeocodingResult = useRef<GeocodeResult>();

  const canClearCountry = useMemo(
    () =>
      !isEmptyString(value?.countryCode) &&
      isEmptyString(value?.address) &&
      isEmptyString(value?.city) &&
      isEmptyString(value?.region) &&
      isEmptyString(value?.postalCode),
    [value]
  );

  useEffect(() => {
    resetError();
  }, [value, resetError]);

  useEffect(() => {
    if (lastGeocodingResult.current !== geocodingResult) {
      lastGeocodingResult.current = geocodingResult;
      onChange?.(geocodingResult?.address);
      setAddress(geocodingResult?.address);
    }
  }, [geocodingResult, onChange]);

  const countryListOptions = useCountryListOptions();

  return (
    <Labeled
      extra={disabled ? <FontAwesomeIcon className={block("lock")} icon={faLock} /> : undefined}
      label={label}
      labelClassName={cn(block("label"), labelClassName)}
      labelPosition={labelPosition}
      required={required}
    >
      <Row className={block()}>
        {extra && <Col span={24}>{extra}</Col>}
        <Col span={24}>
          <InlineTextField
            disabled={disabled}
            onChange={handleAddressChange}
            placeholder={formatMessage("ui.manualAddress.street")}
            showFooterButtons={false}
            suffix={
              findMeLoading ? (
                <LoadingSpinner mini />
              ) : !disabled ? (
                <Tooltip title={formatMessage("ui.address.findMe.tooltip")}>
                  <FontAwesomeIcon
                    className={block("find-me-icon")}
                    icon={faLocationArrow}
                    onClick={handleFindMyLocation}
                    size="lg"
                  />
                </Tooltip>
              ) : undefined
            }
            value={address?.address}
          />
        </Col>
        <Col span={24}>
          <InlineSelectField
            allowClear={canClearCountry}
            className={block("country-dropdown")}
            clearIcon={<FontAwesomeIcon icon={faTimesCircle} />}
            disabled={disabled}
            dropdownMatchSelectWidth={false}
            onChange={handleCountryCodeChange}
            optionFilterProp="text"
            options={countryListOptions}
            placeholder={formatMessage("ui.manualAddress.country")}
            showFooterButtons={false}
            showSearch
            value={address?.countryCode}
          />
        </Col>
        <Col span={24}>
          <Row gutter={layout.spacerS}>
            <Col span={12}>
              <InlineTextField
                disabled={disabled}
                onChange={handleCityChange}
                placeholder={formatMessage("ui.manualAddress.city")}
                showFooterButtons={false}
                value={address?.city}
              />
            </Col>
            <Col span={12}>
              <RegionField
                countryCode={address?.countryCode}
                disabled={disabled}
                onChange={handleRegionChange}
                value={address}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <InlineTextField
            disabled={disabled}
            onChange={handlePostalCodeChange}
            placeholder={formatMessage("ui.manualAddress.postalCode")}
            showFooterButtons={false}
            value={address?.postalCode}
          />
        </Col>
        {!disabled && (
          <Col span={24}>
            <Button
              className={block("search-manual-button")}
              onClick={onToggleManualAddress}
              type="link"
            >
              {formatMessage("ui.manualAddress.searchGoogle")}
            </Button>
          </Col>
        )}
        {isAddressChanged && !disabled && (
          <Col span={24}>
            <Row justify="space-between">
              <span>{formatMessage("ui.manualAddress.saveAllFields")}</span>
              <InlineButtons onCancel={handleCancel} onSave={handleSave} />
            </Row>
          </Col>
        )}
      </Row>
    </Labeled>
  );
};

export default ManualAddress;
