import { all, put, select, takeEvery } from "redux-saga/effects";

import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";

import {
  addCompanyChildCompanies,
  addCompanyDeals,
  addCompanyParentCompany,
  addCompanyPeople,
  addDealParentCompany,
  addDealParentPerson,
  addPersonCompanies,
  addPersonDeals,
} from "./actions";

function* onAddCompanyChildCompanies({
  payload: { id, companiesIds },
}: ReturnType<typeof addCompanyChildCompanies.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    yield all(
      companiesIds.map((companyId) =>
        callApi("updateCompany", orgId, undefined, {
          id: companyId,
          parentAccount: { id },
        })
      )
    );

    const childCompanies: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
      $filters: { $and: [{ parentAccountId: { $eq: id } }], includeAccessStatus: true },
      $limit: 1000,
      $order: "name",
    });

    yield put(addCompanyChildCompanies.success(childCompanies.data));
    yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: childCompanies.data }));
  } catch (error) {
    yield put(addCompanyChildCompanies.failure());
    yield put(handleError({ error }));
  }
}

function* onAddCompanyDeals({
  payload: { id, dealIds },
}: ReturnType<typeof addCompanyDeals.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield all(
      dealIds.map((dealId) =>
        callApi("updateDeal", orgId, undefined, {
          id: dealId,
          account: { id },
        })
      )
    );

    const dealsResponse: ListResponse<Deal> = yield callApi("fetchDeals", orgId, {
      $filters: { $and: [{ accountId: id }], includeAccessStatus: true },
      $limit: 1000,
      $order: "name",
    });

    yield put(addCompanyDeals.success(dealsResponse.data));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: dealsResponse.data }));
  } catch (error) {
    yield put(addCompanyDeals.failure());
    yield put(handleError({ error }));
  }
}

function* onAddPersonDeals({
  payload: { id, dealIds },
}: ReturnType<typeof addPersonDeals.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    yield all(
      dealIds.map((dealId: Deal["id"]) =>
        callApi("updateDeal", orgId, undefined, {
          id: dealId,
          contact: { id },
        })
      )
    );

    const dealsResponse: ListResponse<Deal> = yield callApi("fetchDeals", orgId, {
      $filters: { $and: [{ contactId: id }], includeAccessStatus: true },
      $limit: 1000,
      $order: "name",
    });

    yield put(addPersonDeals.success(dealsResponse.data));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: dealsResponse.data }));
  } catch (error) {
    yield put(addPersonDeals.failure());
    yield put(handleError({ error }));
  }
}

function* onAddCompanyParentCompany({
  payload: { id, parentCompanyId },
}: ReturnType<typeof addCompanyParentCompany.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const [company, parentCompany]: [Company, Company] = yield all([
      callApi("updateCompany", orgId, undefined, {
        id,
        parentAccount: { id: parentCompanyId },
      }),
      callApi("fetchCompany", orgId, parentCompanyId, { includeAccessStatus: true }),
    ]);

    yield put(addCompanyParentCompany.success(parentCompany));
    yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [company] }));
  } catch (error) {
    yield put(addCompanyParentCompany.failure());
    yield put(handleError({ error }));
  }
}

function* onAddPersonCompanies({
  payload: { id, companyIds },
}: ReturnType<typeof addPersonCompanies.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const [person, companiesResponse]: [Person, ListResponse<Company>] = yield all([
      callApi("updatePerson", orgId, undefined, {
        id,
        accounts: companyIds.map((id) => ({ id })).slice(0, 100),
      }),
      callApi("fetchCompanies", orgId, {
        $filters: {
          $and: [
            {
              id: { $in: companyIds },
            },
          ],
          includeAccessStatus: true,
          includeCustomFields: true,
          includeGroups: true,
          includeTerritories: true,
        },
        $limit: 100,
        $order: "name",
      }),
    ]);

    yield put(addPersonCompanies.success(companiesResponse?.data ?? []));
    yield put(notifyAboutChanges({ entityType: EntityType.PERSON, updated: [person] }));
  } catch (error) {
    yield put(addPersonCompanies.failure());
    yield put(handleError({ error }));
  }
}

function* onAddDealParentCompany({
  payload: { id, parentCompanyId },
}: ReturnType<typeof addDealParentCompany.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const [deal, parentCompany]: [Deal, Company] = yield all([
      callApi("updateDeal", orgId, undefined, {
        id,
        account: { id: parentCompanyId },
      }),
      callApi("fetchCompany", orgId, parentCompanyId, { includeAccessStatus: true }),
    ]);

    yield put(addDealParentCompany.success(parentCompany));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [deal] }));
  } catch (error) {
    yield put(addDealParentCompany.failure());
    yield put(handleError({ error }));
  }
}

function* onAddCompanyPeople({
  payload: { id: companyId, peopleIds },
}: ReturnType<typeof addCompanyPeople.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const peopleResponse: ListResponse<Person> | undefined = yield peopleIds && peopleIds.length > 0
      ? callApi("fetchPeople", orgId, {
          $filters: { $and: [{ id: { $in: peopleIds } }], includeAccessStatus: true },
          $limit: 100,
          $order: "name",
        })
      : undefined;

    yield all(
      (peopleResponse?.data ?? []).map(({ id, accounts }) =>
        callApi("updatePerson", orgId, undefined, {
          id,
          accounts: [...(accounts ?? []), { id: companyId }].slice(0, 100),
        })
      )
    );

    const updatedPeopleResponse: ListResponse<Person> = yield callApi("fetchPeople", orgId, {
      $filters: { $and: [{ id: { $in: peopleIds } }], includeAccessStatus: true },
      $limit: 100,
      $order: "name",
    });

    yield put(addCompanyPeople.success(updatedPeopleResponse.data));
    yield put(
      notifyAboutChanges({ entityType: EntityType.PERSON, updated: updatedPeopleResponse.data })
    );
  } catch (error) {
    yield put(addCompanyPeople.failure());
    yield put(handleError({ error }));
  }
}

function* onAddDealParentPerson({
  payload: { id, parentPersonId },
}: ReturnType<typeof addDealParentPerson.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const [deal, parentPerson]: [Deal, Person] = yield all([
      callApi("updateDeal", orgId, undefined, {
        id,
        contact: { id: parentPersonId },
      }),
      callApi("fetchPerson", orgId, parentPersonId, { includeAccessStatus: true }),
    ]);

    yield put(addDealParentPerson.success(parentPerson));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [deal] }));
  } catch (error) {
    yield put(addDealParentPerson.failure());
    yield put(handleError({ error }));
  }
}

export function* postCreationSaga() {
  yield takeEvery(addCompanyChildCompanies.request, onAddCompanyChildCompanies);
  yield takeEvery(addCompanyDeals.request, onAddCompanyDeals);
  yield takeEvery(addPersonDeals.request, onAddPersonDeals);
  yield takeEvery(addCompanyParentCompany.request, onAddCompanyParentCompany);
  yield takeEvery(addPersonCompanies.request, onAddPersonCompanies);
  yield takeEvery(addDealParentCompany.request, onAddDealParentCompany);
  yield takeEvery(addCompanyPeople.request, onAddCompanyPeople);
  yield takeEvery(addDealParentPerson.request, onAddDealParentPerson);
}
