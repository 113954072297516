import React, { useCallback } from "react";

import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";

import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import getInitials from "@mapmycustomers/shared/util/ui/getInitials";

import Chip from "@app/component/Chip";
import ContactInfo from "@app/component/ContactInfo/ContactInfo";
import LastActivityChip from "@app/component/LastActivityChip";
import Path from "@app/enum/Path";
import { isMember } from "@app/store/iam/util";
import Scope from "@app/types/dashboard/Scope";

import useUserAccess from "../util/useUserAccess";

import styles from "./Content.module.scss";

const messages = defineMessages({
  openProfile: {
    id: "userPreviewCard.tooltip.openProfile",
    defaultMessage: "Open Profile",
    description: "Open Profile button",
  },
  openTeam: {
    id: "userPreviewCard.tooltip.openTeam",
    defaultMessage: "Open {teamName}",
    description: "Open team button",
  },
});

interface Props {
  currentUser: User;
  logoUrl?: string;
  scope: Scope;
  user: User;
}
const Content: React.FC<Props> = ({ currentUser, logoUrl, scope, user }) => {
  const initials = getInitials(user);
  const intl = useIntl();
  const { teamId, userId } = scope;
  const [allowOpenUser] = useUserAccess(currentUser, user);

  const handleOpenTeam = useCallback(
    (id: Team["id"]) => window.open(`${Path.DASHBOARD}/teams/${id}`, "_blank"),
    []
  );

  const imageContainer = (
    <div
      className={cn(styles.imageContainer, "mmc-user-preview-allow-click", {
        [styles.imageHover]: allowOpenUser,
        [styles.noImage]: !logoUrl,
      })}
    >
      {logoUrl ? (
        <img
          alt={logoUrl}
          className={cn(styles.image, "mmc-user-preview-allow-click")}
          src={logoUrl}
        />
      ) : (
        initials
      )}
    </div>
  );

  return (
    <div className={styles.content}>
      {allowOpenUser ? (
        <Tooltip title={intl.formatMessage(messages.openProfile)}>
          <a
            href={`${Path.DASHBOARD}${teamId ? `/teams/${teamId}` : ""}${
              userId ? `/users/${userId}` : ""
            }`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {imageContainer}
          </a>
        </Tooltip>
      ) : (
        imageContainer
      )}
      <div className={styles.info}>
        <div className={styles.title}>
          {allowOpenUser ? (
            <Tooltip title={intl.formatMessage(messages.openProfile)}>
              <a
                className={cn(styles.name, "mmc-user-preview-allow-click", styles.nameHover)}
                href={`${Path.DASHBOARD}${teamId ? `/teams/${teamId}` : ""}${
                  userId ? `/users/${userId}` : ""
                }`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {userDisplayName(user)}
              </a>
            </Tooltip>
          ) : (
            <div className={cn(styles.name)}>{userDisplayName(user)}</div>
          )}
          <ContactInfo size="small" user={user} />
        </div>
        <div className={styles.chips}>
          <LastActivityChip lastActivity={user.lastActivity} placement="bottom" userId={user.id} />
          {user.teams.map((team) =>
            isMember(currentUser) ? (
              <Chip className={styles.chip} icon={<FontAwesomeIcon icon={faUsers} />} key={team.id}>
                {team.name}
              </Chip>
            ) : (
              <Tooltip
                key={team.id}
                title={intl.formatMessage(messages.openTeam, { teamName: team.name })}
              >
                <Chip
                  className={cn(styles.chip, styles.chipHover)}
                  icon={<FontAwesomeIcon icon={faUsers} />}
                  key={team.id}
                  onClick={() => handleOpenTeam(team.id)}
                >
                  {team.name}
                </Chip>
              </Tooltip>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;
