import Location from "@mapmycustomers/shared/types/Location";

import LocationGroup from "@app/types/map/LocationGroup";

const locationsToLocationGroup = (locationArray: Location[][]): LocationGroup[] =>
  locationArray.map((locations) => ({
    id: locations
      .map(({ id }) => id)
      .sort()
      .join("_"),
    locations,
  }));

export default locationsToLocationGroup;
