import { defineMessages } from "react-intl";

const messages = defineMessages({
  activityAssignee: {
    id: "component.accessReasonExposer.activityAssignee",
    defaultMessage: "You are able to <b>edit</b> this activity because it is assigned to you.",
    description: "Access Reason Exposer - activity assignee",
  },
  activityOwner: {
    id: "component.accessReasonExposer.activityOwner",
    defaultMessage:
      "You are able to <b>edit</b> this activity because your role gives you permission to edit all activities.",
    description: "Access Reason Exposer - message for activity owner",
  },
  activityPublic: {
    id: "component.accessReasonExposer.activityPublic",
    defaultMessage:
      "You are able to <b>view</b> this activity because it has been shared with the entire organization.",
    description: "Access Reason Exposer - activity public",
  },
  activitySharedCompanyScoped: {
    id: "component.accessReasonExposer.activitySharedCompanyScoped",
    defaultMessage:
      "You are able to <b>view</b> this activity because you have access to its related Company.",
    description: "Access Reason Exposer - shared Company scoped",
  },
  activitySharedDealScoped: {
    id: "component.accessReasonExposer.activitySharedDealScoped",
    defaultMessage:
      "You are able to <b>view</b> this activity because you have access to its related Deal.",
    description: "Access Reason Exposer - shared Deal scoped",
  },
  activitySharedPersonScoped: {
    id: "component.accessReasonExposer.activitySharedPersonScoped",
    defaultMessage:
      "You are able to <b>view</b> this activity because you have access to its related Person.",
    description: "Access Reason Exposer - shared Person scoped",
  },
  activityTeam: {
    id: "component.accessReasonExposer.activityTeam",
    defaultMessage:
      "You are able to <b>view</b> this activity because you’re on the same team as the assignee or owner.",
    description: "Access Reason Exposer - activity team",
  },
  activityTeamScoped: {
    id: "component.accessReasonExposer.activityTeamScoped",
    defaultMessage:
      "You are able to <b>view</b> this activity because it has been shared with your team.",
    description: "Access Reason Exposer - activity team scoped",
  },
  activityUser: {
    id: "component.accessReasonExposer.activityUser",
    defaultMessage: "You are able to <b>edit</b> this activity because you are its creator.",
    description: "Access Reason Exposer - activity user",
  },
  edit: {
    id: "component.accessReasonExposer.edit",
    defaultMessage: "edit",
    description: "Access Reason Exposer - edit action",
  },
  entityShared: {
    id: "component.accessReasonExposer.entityShared",
    defaultMessage:
      "You are able to <b>view</b> this record because you have access to a related Company, Person, and/or Deal.",
    description: "Access Reason Exposer - entity shared",
  },
  notRecordOwner: {
    id: "component.accessReasonExposer.notRecordOwner",
    defaultMessage:
      "You are able to <b>{action}</b> this record because it has been shared with you.",
    description: "Access Reason Exposer - message for not record owner",
  },
  owner: {
    id: "component.accessReasonExposer.owner",
    defaultMessage:
      "You are able to <b>edit</b> this record because your role gives you permission to edit all records.",
    description: "Access Reason Exposer - message for owner",
  },
  question: {
    id: "component.accessReasonExposer.question",
    defaultMessage: "Why do I have access to this record?",
    description: "Access Reason Exposer - question",
  },
  questionActivity: {
    id: "component.accessReasonExposer.questionActivity",
    defaultMessage: "Why do I have access to this activity?",
    description: "Access Reason Exposer - question activity",
  },
  recordOwner: {
    id: "component.accessReasonExposer.recordOwner",
    defaultMessage: "You are able to <b>edit</b> this record because you are its owner.",
    description: "Access Reason Exposer - message for record owner",
  },
  view: {
    id: "component.accessReasonExposer.view",
    defaultMessage: "view",
    description: "Access Reason Exposer - view action",
  },
});

export default messages;
