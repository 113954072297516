import React from "react";

import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import Email from "@app/component/field/Email";
import { RegistryProps } from "@app/component/FieldGrid/utils/ComponentRegistry";

import styles from "./EmailField.module.scss";

const EmailField: React.FC<RegistryProps> = ({ entity, field }) => {
  const intl = useIntl();

  const value = field.getValueFor(entity) as string;
  return (
    <Tooltip
      placement="top"
      title={intl.formatMessage({
        id: "fieldGrid.field.email.tooltip",
        defaultMessage: "Send email",
        description: "Send email tooltip",
      })}
    >
      <div className={styles.container}>
        <Email
          className={styles.email}
          entity={entity as EntitiesSupportedByEmailFeature}
          showIcon={false}
          value={value}
        />
      </div>
    </Tooltip>
  );
};

export default EmailField;
