import {
  endOfMonth,
  endOfYesterday,
  startOfMonth,
  startOfYesterday,
  subMonths,
  subWeeks,
} from "date-fns/esm";

import { getWeekEnd, getWeekStart } from "@app/util/dates";

import DateRangeType from "../enums/DateRangeType";
import DateRange from "../types/DateRange";

const dateRangeTypeToDateRange = (dateRangeType: DateRangeType): DateRange | undefined => {
  const defaultRange = {
    endDate: endOfYesterday(),
    startDate: startOfYesterday(),
  };

  switch (dateRangeType) {
    case DateRangeType.LAST_WEEK:
      return {
        endDate: getWeekEnd(subWeeks(Date.now(), 1)),
        startDate: getWeekStart(subWeeks(Date.now(), 1)),
      };
    case DateRangeType.PREVIOUS_MONTH:
      return {
        endDate: endOfMonth(subMonths(Date.now(), 1)),
        startDate: startOfMonth(subMonths(Date.now(), 1)),
      };
    case DateRangeType.THIS_MONTH:
      return { ...defaultRange, startDate: startOfMonth(defaultRange.startDate) };
    case DateRangeType.THIS_WEEK:
      return { ...defaultRange, startDate: getWeekStart(defaultRange.startDate) };
    case DateRangeType.YESTERDAY:
      return defaultRange;
  }
};

export default dateRangeTypeToDateRange;
