import React from "react";

import { bem } from "@react-md/utils";
import { RenderElementProps } from "slate-react/dist/components/editable";
import { MentionElement } from "type/slate-types";

const block = bem("mmc-mention");

const Mention: React.FC<RenderElementProps> = ({ attributes, children, element }) => {
  const { disabled, name } = element as MentionElement;

  return (
    <span className={block({ disabled })} contentEditable={false} {...attributes}>
      {name}
      {children}
    </span>
  );
};

export default Mention;
