import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import { EntityTypeMap } from "@mapmycustomers/shared/types/layout/ILayoutModel";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";

const hasMissingRequiredFields = <T extends EntityTypesSupportingFieldCustomization>(
  schema: LayoutSchemaField[],
  entity: OnlyRequiredFields<EntityTypeMap[T], "entity">
): boolean => {
  const fieldModel = getFieldModelByEntityType(entity.entity);
  return schema.some((schemaField) => {
    if (!schemaField.required) {
      return false;
    }
    const field = fieldModel.getByPlatformName(schemaField.field);
    return field ? !field.hasNonEmptyValueFor(entity) : false;
  });
};

export default hasMissingRequiredFields;
