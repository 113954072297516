import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

export const pinLegendsState = (state: RootState) => state.scene.map.pinLegends;

export const isPinLegendSettingsOpen = createSelector(
  pinLegendsState,
  ({ pinLegendSettingsVisibility }) => pinLegendSettingsVisibility
);
