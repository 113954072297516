import React from "react";

import { defineMessages, IntlShape } from "react-intl";

import { PASSWORD_MINIMUM_LENGTH } from "../consts";

import styles from "./PlatformErrorCodes.module.scss";

const platformErrorMessageByCode = defineMessages({
  v0001: {
    id: "platformError.v0001",
    defaultMessage: "The team name cannot be empty",
    description: "Message for Platform's error code v0001",
  },
  v0002: {
    id: "platformError.v0002",
    defaultMessage: "The funnel name cannot be empty",
    description: "Message for Platform's error code v0002",
  },
  v0003: {
    id: "platformError.v0003",
    defaultMessage: "The note cannot be empty",
    description: "Message for Platform's error code v0003",
  },
  v0004: {
    id: "platformError.v0004",
    defaultMessage: "Invalid parent record for notes request",
    description: "Message for Platform's error code v0004",
  },
  v0005: {
    id: "platformError.v0005",
    defaultMessage: "The checkin type name cannot be empty",
    description: "Message for Platform's error code v0005",
  },
  v0006: {
    id: "platformError.v0006",
    defaultMessage: "Invalid user",
    description: "Message for Platform's error code v0006",
  },
  v0010: {
    id: "platformError.v0010",
    defaultMessage: "Invalid route type",
    description: "Message for Platform's error code v0010",
  },
  v0011: {
    id: "platformError.v0011",
    defaultMessage: "Invalid latitude/longitude for route starting location",
    description: "Message for Platform's error code v0011",
  },
  v0012: {
    id: "platformError.v0012",
    defaultMessage: "The route name cannot be empty",
    description: "Message for Platform's error code v0012",
  },
  v0013: {
    id: "platformError.v0013",
    defaultMessage: "The product update type cannot be empty",
    description: "Message for Platform's error code v0013",
  },
  v0014: {
    id: "platformError.v0014",
    defaultMessage: "The product update platform cannot be empty",
    description: "Message for Platform's error code v0014",
  },
  v0015: {
    id: "platformError.v0015",
    defaultMessage: "The product update description cannot be empty",
    description: "Message for Platform's error code v0015",
  },
  v0016: {
    id: "platformError.v0016",
    defaultMessage: "The tutorial link url cannot be empty must be a valid URL",
    description: "Message for Platform's error code v0016",
  },
  v0017: {
    id: "platformError.v0017",
    defaultMessage: "The tutorial link title cannot be empty",
    description: "Message for Platform's error code v0017",
  },
  v0018: {
    id: "platformError.v0018",
    defaultMessage: "The tutorial link platform cannot be empty",
    description: "Message for Platform's error code v0018",
  },
  v0019: {
    id: "platformError.v0019",
    defaultMessage: "The tutorial link thumbnail cannot be empty",
    description: "Message for Platform's error code v0019",
  },
  v0020: {
    id: "platformError.v0020",
    defaultMessage: "The reminder type name cannot be empty",
    description: "Message for Platform's error code v0020",
  },
  v0021: {
    id: "platformError.v0021",
    defaultMessage: "The reminder due date must be valid",
    description: "Message for Platform's error code v0021",
  },
  v0022: {
    id: "platformError.v0022",
    defaultMessage: "The reminder type is invalid",
    description: "Message for Platform's error code v0022",
  },
  v0023: {
    id: "platformError.v0023",
    defaultMessage: "A valid person must be selected for a reminder",
    description: "Message for Platform's error code v0023",
  },
  v0024: {
    id: "platformError.v0024",
    defaultMessage: "A sent email user is required",
    description: "Message for Platform's error code v0024",
  },
  v0025: {
    id: "platformError.v0025",
    defaultMessage: "The email sent message id cannot be empty",
    description: "Message for Platform's error code v0025",
  },
  v0026: {
    id: "platformError.v0026",
    defaultMessage: "The email sent time of creation is required",
    description: "Message for Platform's error code v0026",
  },
  v0027: {
    id: "platformError.v0027",
    defaultMessage: "The email sent requires a valid email template",
    description: "Message for Platform's error code v0027",
  },
  v0028: {
    id: "platformError.v0028",
    defaultMessage: "The email sent replied text must be valid text",
    description: "Message for Platform's error code v0028",
  },
  v0029: {
    id: "platformError.v0029",
    defaultMessage: "The email sent time of reply must be a valid time",
    description: "Message for Platform's error code v0029",
  },
  v0030: {
    id: "platformError.v0030",
    defaultMessage: "The email sent clicked flag is required",
    description: "Message for Platform's error code v0030",
  },
  v0031: {
    id: "platformError.v0031",
    defaultMessage: "The email sent opened flag is required",
    description: "Message for Platform's error code v0031",
  },
  v0032: {
    id: "platformError.v0032",
    defaultMessage: "The email sent requires a valid number of opens",
    description: "Message for Platform's error code v0032",
  },
  v0033: {
    id: "platformError.v0033",
    defaultMessage: "The email sent campaign must be valid",
    description: "Message for Platform's error code v0033",
  },
  v0034: {
    id: "platformError.v0034",
    defaultMessage: "The email sent name must be a valid text",
    description: "Message for Platform's error code v0034",
  },
  v0035: {
    id: "platformError.v0035",
    defaultMessage: "The email sent automationRun flag must be a valid boolean",
    description: "Message for Platform's error code v0035",
  },
  v0036: {
    id: "platformError.v0036",
    defaultMessage: "The email sent time of click must be a valid time",
    description: "Message for Platform's error code v0036",
  },
  v0037: {
    id: "platformError.v0037",
    defaultMessage: "The email sent time of opening must be a valid time",
    description: "Message for Platform's error code v0037",
  },
  v0038: {
    id: "platformError.v0038",
    defaultMessage: "The email sent has replied flag must be a valid boolean",
    description: "Message for Platform's error code v0038",
  },
  v0039: {
    id: "platformError.v0039",
    defaultMessage: "The email sent requires a valid person",
    description: "Message for Platform's error code v0039",
  },
  v0040: {
    id: "platformError.v0040",
    defaultMessage: "The email sent requires a valid email address",
    description: "Message for Platform's error code v0040",
  },
  v0041: {
    id: "platformError.v0041",
    defaultMessage: "The email sent company value must be valid text",
    description: "Message for Platform's error code v0041",
  },
  v0042: {
    id: "platformError.v0042",
    defaultMessage: "An valid status must be provided",
    description: "Message for Platform's error code v0042",
  },
  v0043: {
    id: "platformError.v0043",
    defaultMessage: "A valid type must be provided",
    description: "Message for Platform's error code v0043",
  },
  v0044: {
    id: "platformError.v0044",
    defaultMessage: "The referral must be a valid email address",
    description: "Message for Platform's error code v0044",
  },
  v0045: {
    id: "platformError.v0045",
    defaultMessage: "A valid user must be provided as the referrer",
    description: "Message for Platform's error code v0045",
  },
  v0051: {
    id: "platformError.v0051",
    defaultMessage: "The territory name can't be empty",
    description: "Message for Platform's error code v0051",
  },
  v0052: {
    id: "platformError.v0052",
    defaultMessage: "The territory shape can't be empty",
    description: "Message for Platform's error code v0052",
  },
  v0053: {
    id: "platformError.v0053",
    defaultMessage: "The person id(s) can't be empty",
    description: "Message for Platform's error code v0053",
  },
  v0054: {
    id: "platformError.v0054",
    defaultMessage: "A valid email address must be provided",
    description: "Message for Platform's error code v0054",
  },
  v0055: {
    id: "platformError.v0055",
    defaultMessage:
      "An invalid password was provided.  A password must be at least {passwordMinimumLength} {passwordMinimumLength, plural, one {character} other {characters}} in length, have an uppercase character and a special character",
    description: "Message for Platform's error code v0055",
  },
  v0056: {
    id: "platformError.v0056",
    defaultMessage: "The user's full name is required and  it should be less than 255 characters",
    description: "Message for Platform's error code v0056",
  },
  v0057: {
    id: "platformError.v0057",
    defaultMessage: "A valid phone number must be provided",
    description: "Message for Platform's error code v0057",
  },
  v0058: {
    id: "platformError.v0058",
    defaultMessage: "An invalid promo code was provided",
    description: "Message for Platform's error code v0058",
  },
  v0059: {
    id: "platformError.v0059",
    defaultMessage: "An invalid company type was provided",
    description: "Message for Platform's error code v0059",
  },
  v0061: {
    id: "platformError.v0061",
    defaultMessage: "An invalid referral code was provided",
    description: "Message for Platform's error code v0061",
  },
  v0062: {
    id: "platformError.v0062",
    defaultMessage: "The owner id must be valid",
    description: "Message for Platform's error code v0062",
  },
  v0063: {
    id: "platformError.v0063",
    defaultMessage: "The plan id must be valid",
    description: "Message for Platform's error code v0063",
  },
  v0064: {
    id: "platformError.v0064",
    defaultMessage: "The name cannot be empty",
    description: "Message for Platform's error code v0064",
  },
  v0066: {
    id: "platformError.v0066",
    defaultMessage: "The username should be valid",
    description: "Message for Platform's error code v0066",
  },
  v0067: {
    id: "platformError.v0067",
    defaultMessage: "The role should be valid",
    description: "Message for Platform's error code v0067",
  },
  v0068: {
    id: "platformError.v0068",
    defaultMessage: "Owner cannot be added to teams",
    description: "Message for Platform's error code v0068",
  },
  v0069: {
    id: "platformError.v0069",
    defaultMessage: "The password cannot be empty",
    description: "Message for Platform's error code v0069",
  },
  v0070: {
    id: "platformError.v0070",
    defaultMessage: "The team name should be unique",
    description: "Message for Platform's error code v0070",
  },
  v0071: {
    id: "platformError.v0071",
    defaultMessage: "The user should be valid",
    description: "Message for Platform's error code v0071",
  },
  v0072: {
    id: "platformError.v0072",
    defaultMessage: "The team should be valid",
    description: "Message for Platform's error code v0072",
  },
  v0073: {
    id: "platformError.v0073",
    defaultMessage: "The invitation code has expired or is invalid",
    description: "Message for Platform's error code v0073",
  },
  v0074: {
    id: "platformError.v0074",
    defaultMessage: "The numFieldReps should be valid",
    description: "Message for Platform's error code v0074",
  },
  v0075: {
    id: "platformError.v0075",
    defaultMessage: "Organization with the same name already exist",
    description: "Message for Platform's error code v0075",
  },
  v0076: {
    id: "platformError.v0076",
    defaultMessage: "The title should be valid",
    description: "Message for Platform's error code v0076",
  },
  v0077: {
    id: "platformError.v0077",
    defaultMessage: "Activity Type with same name already exists",
    description: "Message for Platform's error code v0077",
  },
  v0078: {
    id: "platformError.v0078",
    defaultMessage: "Reminder Type with same name already exist",
    description: "Message for Platform's error code v0078",
  },
  v0079: {
    id: "platformError.v0079",
    defaultMessage: "Funnel with same name already exist",
    description: "Message for Platform's error code v0079",
  },
  v0080: {
    id: "platformError.v0080",
    defaultMessage: "Deal Stage with same name already exist",
    description: "Message for Platform's error code v0080",
  },
  v0081: {
    id: "platformError.v0081",
    defaultMessage: "Username already exists",
    description: "Message for Platform's error code v0081",
  },
  v0082: {
    id: "platformError.v0082",
    defaultMessage: "Please choose a new password that is at least 12 characters.",
    description: "Message for Platform's error code v0082",
  },
  v0083: {
    id: "platformError.v0083",
    defaultMessage: "User already belongs to the team",
    description: "Message for Platform's error code v0083",
  },
  v0084: {
    id: "platformError.v0084",
    defaultMessage: "Cannot add an owner to the team",
    description: "Message for Platform's error code v0084",
  },
  v0085: {
    id: "platformError.v0085",
    defaultMessage:
      "User already belongs to different team. Cannot add a member to more than one team",
    description: "Message for Platform's error code v0085",
  },
  v0086: {
    id: "platformError.v0086",
    defaultMessage: "The team id must be valid",
    description: "Message for Platform's error code v0086",
  },
  v0087: {
    id: "platformError.v0087",
    defaultMessage: "Funnels cannot be reassigned",
    description: "Message for Platform's error code v0087",
  },
  v0088: {
    id: "platformError.v0088",
    defaultMessage: "Organization name is empty or is larger than 255 characters",
    description: "Message for Platform's error code v0088",
  },
  v0089: {
    id: "platformError.v0089",
    defaultMessage: "An invalid plan was provided",
    description: "Message for Platform's error code v0089",
  },
  v0090: {
    id: "platformError.v0090",
    defaultMessage: "Team plans may not convert to individual plans and vice versa",
    description: "Message for Platform's error code v0090",
  },
  v0091: {
    id: "platformError.v0091",
    defaultMessage: "Plans may not downgrade",
    description: "Message for Platform's error code v0091",
  },
  v0092: {
    id: "platformError.v0092",
    defaultMessage: "Color cannot be empty",
    description: "Message for Platform's error code v0092",
  },
  v0093: {
    id: "platformError.v0093",
    defaultMessage: "Color must be valid",
    description: "Message for Platform's error code v0093",
  },
  v0094: {
    id: "platformError.v0094",
    defaultMessage: "An invalid payment token was provided",
    description: "Message for Platform's error code v0094",
  },
  v0095: {
    id: "platformError.v0095",
    defaultMessage: "Funnel id should be valid",
    description: "Message for Platform's error code v0095",
  },
  v0096: {
    id: "platformError.v0096",
    defaultMessage: "Funnel cannot have more than 8 deal stages",
    description: "Message for Platform's error code v0096",
  },
  v0097: {
    id: "platformError.v0097",
    defaultMessage: "Funnel cannot have less than 2 deal stages",
    description: "Message for Platform's error code v0097",
  },
  v0100: {
    id: "platformError.v0100",
    defaultMessage: "Quantity must be an integer",
    description: "Message for Platform's error code v0100",
  },
  v0101: {
    id: "platformError.v0101",
    defaultMessage:
      "Quantity must be greater than or equal to the number of users in the organization",
    description: "Message for Platform's error code v0101",
  },
  v0102: {
    id: "platformError.v0102",
    defaultMessage: "Cannot change manager to member. The manager is part of multiple teams",
    description: "Message for Platform's error code v0102",
  },
  v0103: {
    id: "platformError.v0103",
    defaultMessage: "Cannot change team owner role if there is only one team owner",
    description: "Message for Platform's error code v0103",
  },
  v0104: {
    id: "platformError.v0104",
    defaultMessage: "Only an owner can change the role to team owner",
    description: "Message for Platform's error code v0104",
  },
  v0105: {
    id: "platformError.v0105",
    defaultMessage: "Weight should be between 0 and 1",
    description: "Message for Platform's error code v0105",
  },
  v0106: {
    id: "platformError.v0106",
    defaultMessage: "Cannot change access rights",
    description: "Message for Platform's error code v0106",
  },
  v0107: {
    id: "platformError.v0107",
    defaultMessage: "Access right already exists",
    description: "Message for Platform's error code v0107",
  },
  v0108: {
    id: "platformError.v0108",
    defaultMessage: "Permission id does not exist",
    description: "Message for Platform's error code v0108",
  },
  v0109: {
    id: "platformError.v0109",
    defaultMessage: "Permission id not valid",
    description: "Message for Platform's error code v0109",
  },
  v0110: {
    id: "platformError.v0110",
    defaultMessage: "Type not valid",
    description: "Message for Platform's error code v0110",
  },
  v0111: {
    id: "platformError.v0111",
    defaultMessage: "An invalid creation date was provided",
    description: "Message for Platform's error code v0111",
  },
  v0112: {
    id: "platformError.v0112",
    defaultMessage: "An invalid value for the device's GPS accuracy was provided",
    description: "Message for Platform's error code v0112",
  },
  v0113: {
    id: "platformError.v0113",
    defaultMessage: "An invalid GeoJSON Point was provided",
    description: "Message for Platform's error code v0113",
  },
  v0114: {
    id: "platformError.v0114",
    defaultMessage: "An invalid device type was provided",
    description: "Message for Platform's error code v0114",
  },
  v0115: {
    id: "platformError.v0115",
    defaultMessage: "An invalid date value was provided",
    description: "Message for Platform's error code v0115",
  },
  v0116: {
    id: "platformError.v0116",
    defaultMessage: "The company name cannot be empty",
    description: "Message for Platform's error code v0116",
  },
  v0117: {
    id: "platformError.v0117",
    defaultMessage: "An company with the same name already exists",
    description: "Message for Platform's error code v0117",
  },
  v0118: {
    id: "platformError.v0118",
    defaultMessage: "The parent company provided for this company is invalid",
    description: "Message for Platform's error code v0118",
  },
  v0119: {
    id: "platformError.v0119",
    defaultMessage: "The phone number provided for this company has an invalid format",
    description: "Message for Platform's error code v0119",
  },
  v0120: {
    id: "platformError.v0120",
    defaultMessage: "Email provided has an invalid format",
    description: "Message for Platform's error code v0120",
  },
  v0121: {
    id: "platformError.v0121",
    defaultMessage: "Annual revenue is not a valid number",
    description: "Message for Platform's error code v0121",
  },
  v0122: {
    id: "platformError.v0122",
    defaultMessage: "The number of employees is not a valid number",
    description: "Message for Platform's error code v0122",
  },
  v0123: {
    id: "platformError.v0123",
    defaultMessage: "The parent company cannot be changed",
    description: "Message for Platform's error code v0123",
  },
  v0124: {
    id: "platformError.v0124",
    defaultMessage: "The name cannot be empty",
    description: "Message for Platform's error code v0124",
  },
  v0125: {
    id: "platformError.v0125",
    defaultMessage: "Person with the same name already exists",
    description: "Message for Platform's error code v0125",
  },
  v0126: {
    id: "platformError.v0126",
    defaultMessage: "Company must be valid",
    description: "Message for Platform's error code v0126",
  },
  v0127: {
    id: "platformError.v0127",
    defaultMessage: "Phone number must be valid",
    description: "Message for Platform's error code v0127",
  },
  v0128: {
    id: "platformError.v0128",
    defaultMessage: "Email must be valid",
    description: "Message for Platform's error code v0128",
  },
  v0131: {
    id: "platformError.v0131",
    defaultMessage: "Note cannot be empty",
    description: "Message for Platform's error code v0131",
  },
  v0132: {
    id: "platformError.v0132",
    defaultMessage: "Person must be valid",
    description: "Message for Platform's error code v0132",
  },
  v0133: {
    id: "platformError.v0133",
    defaultMessage: "Checkin must be valid",
    description: "Message for Platform's error code v0133",
  },
  v0134: {
    id: "platformError.v0134",
    defaultMessage: "Checkin Type must be valid",
    description: "Message for Platform's error code v0134",
  },
  v0135: {
    id: "platformError.v0135",
    defaultMessage: "CrmId must be an object",
    description: "Message for Platform's error code v0135",
  },
  v0136: {
    id: "platformError.v0136",
    defaultMessage: "The note cannot be empty",
    description: "Message for Platform's error code v0136",
  },
  v0137: {
    id: "platformError.v0137",
    defaultMessage: "A valid latitude and longitude must be provided",
    description: "Message for Platform's error code v0137",
  },
  v0138: {
    id: "platformError.v0138",
    defaultMessage: "A valid value for the address must be provided",
    description: "Message for Platform's error code v0138",
  },
  v0139: {
    id: "platformError.v0139",
    defaultMessage: "A valid city must be provided",
    description: "Message for Platform's error code v0139",
  },
  v0140: {
    id: "platformError.v0140",
    defaultMessage: "A valid region must be provided",
    description: "Message for Platform's error code v0140",
  },
  v0141: {
    id: "platformError.v0141",
    defaultMessage:
      "A valid postal code must be provided that is not empty and no greater than 16 characters",
    description: "Message for Platform's error code v0141",
  },
  v0142: {
    id: "platformError.v0142",
    defaultMessage: "A valid country must be provided",
    description: "Message for Platform's error code v0142",
  },
  v0143: {
    id: "platformError.v0143",
    defaultMessage: "The website must be a valid URL",
    description: "Message for Platform's error code v0143",
  },
  v0144: {
    id: "platformError.v0144",
    defaultMessage: "Cannot create a new record with an id in the payload",
    description: "Message for Platform's error code v0144",
  },
  v0145: {
    id: "platformError.v0145",
    defaultMessage: "The note cannot be empty",
    description: "Message for Platform's error code v0145",
  },
  v0146: {
    id: "platformError.v0146",
    defaultMessage: "Deal stage must be valid",
    description: "Message for Platform's error code v0146",
  },
  v0147: {
    id: "platformError.v0147",
    defaultMessage: "Closing date must be valid",
    description: "Message for Platform's error code v0147",
  },
  v0148: {
    id: "platformError.v0148",
    defaultMessage: "Deal name should be unique",
    description: "Message for Platform's error code v0148",
  },
  v0149: {
    id: "platformError.v0149",
    defaultMessage: "Score must be valid",
    description: "Message for Platform's error code v0149",
  },
  v0150: {
    id: "platformError.v0150",
    defaultMessage: "Amount must be a valid number",
    description: "Message for Platform's error code v0150",
  },
  v0151: {
    id: "platformError.v0151",
    defaultMessage: "Deal stage does not belong to funnel",
    description: "Message for Platform's error code v0151",
  },
  v0152: {
    id: "platformError.v0152",
    defaultMessage: "The company cannot be changed",
    description: "Message for Platform's error code v0152",
  },
  v0153: {
    id: "platformError.v0153",
    defaultMessage: "The funnel cannot be changed",
    description: "Message for Platform's error code v0153",
  },
  v0154: {
    id: "platformError.v0154",
    defaultMessage: "The person cannot be changed",
    description: "Message for Platform's error code v0154",
  },
  v0155: {
    id: "platformError.v0155",
    defaultMessage: "The group name must be valid",
    description: "Message for Platform's error code v0155",
  },
  v0158: {
    id: "platformError.v0158",
    defaultMessage: "Your current password is incorrect.",
    description: "Message for Platform's error code v0158",
  },
  v0159: {
    id: "platformError.v0159",
    defaultMessage: "The batch object format is invalid",
    description: "Message for Platform's error code v0159",
  },
  v0160: {
    id: "platformError.v0160",
    defaultMessage: "The batch object list contains invalid objects",
    description: "Message for Platform's error code v0160",
  },
  v0161: {
    id: "platformError.v0161",
    defaultMessage: "The person group name should be unique",
    description: "Message for Platform's error code v0161",
  },
  v0162: {
    id: "platformError.v0162",
    defaultMessage: "Frequency interval should be a valid integer",
    description: "Message for Platform's error code v0162",
  },
  v0163: {
    id: "platformError.v0163",
    defaultMessage: "Group name should be unique",
    description: "Message for Platform's error code v0163",
  },
  v0164: {
    id: "platformError.v0164",
    defaultMessage: "An invalid setting key was provided",
    description: "Message for Platform's error code v0164",
  },
  v0165: {
    id: "platformError.v0165",
    defaultMessage: "An invalid setting value was provided",
    description: "Message for Platform's error code v0165",
  },
  v0166: {
    id: "platformError.v0166",
    defaultMessage: "An invalid organizational setting key was provided",
    description: "Message for Platform's error code v0166",
  },
  v0167: {
    id: "platformError.v0167",
    defaultMessage: "An invalid organiation setting value was provided",
    description: "Message for Platform's error code v0167",
  },
  v0168: {
    id: "platformError.v0168",
    defaultMessage: "The route name should be unique",
    description: "Message for Platform's error code v0168",
  },
  v0169: {
    id: "platformError.v0169",
    defaultMessage: "The route name must be valid",
    description: "Message for Platform's error code v0169",
  },
  v0170: {
    id: "platformError.v0170",
    defaultMessage: "The route must contain a routeDetail object",
    description: "Message for Platform's error code v0170",
  },
  v0171: {
    id: "platformError.v0171",
    defaultMessage: "The routeDetail type is invalid",
    description: "Message for Platform's error code v0171",
  },
  v0172: {
    id: "platformError.v0172",
    defaultMessage: "The routeDetail must have a startAddress",
    description: "Message for Platform's error code v0172",
  },
  v0173: {
    id: "platformError.v0173",
    defaultMessage: "The routeDetail must have a valid startGeoPoint",
    description: "Message for Platform's error code v0173",
  },
  v0174: {
    id: "platformError.v0174",
    defaultMessage: "The routeDetail endGeoPoint is invalid",
    description: "Message for Platform's error code v0174",
  },
  v0175: {
    id: "platformError.v0175",
    defaultMessage: "The routeDetail endAddress is invalid",
    description: "Message for Platform's error code v0175",
  },
  v0176: {
    id: "platformError.v0176",
    defaultMessage: "If passing an end location, endAddress and endGeoPoint are required",
    description: "Message for Platform's error code v0176",
  },
  v0177: {
    id: "platformError.v0177",
    defaultMessage: "The alottedTime field must be an integer",
    description: "Message for Platform's error code v0177",
  },
  v0178: {
    id: "platformError.v0178",
    defaultMessage: "An invalid organization setting value was provided",
    description: "Message for Platform's error code v0178",
  },
  v0179: {
    id: "platformError.v0179",
    defaultMessage: "The template name must be null or a valid string",
    description: "Message for Platform's error code v0179",
  },
  v0180: {
    id: "platformError.v0180",
    defaultMessage: "A template with the provided name already exists for the user",
    description: "Message for Platform's error code v0180",
  },
  v0181: {
    id: "platformError.v0181",
    defaultMessage: "The template subject cannot be empty",
    description: "Message for Platform's error code v0181",
  },
  v0182: {
    id: "platformError.v0182",
    defaultMessage: "The template body cannot be empty",
    description: "Message for Platform's error code v0182",
  },
  v0183: {
    id: "platformError.v0183",
    defaultMessage: "An invalid display name was provided",
    description: "Message for Platform's error code v0183",
  },
  v0184: {
    id: "platformError.v0184",
    defaultMessage: "The custom field with the provided display name already exists",
    description: "Message for Platform's error code v0184",
  },
  v0185: {
    id: "platformError.v0185",
    defaultMessage: "An invalid CRM property key was provided",
    description: "Message for Platform's error code v0185",
  },
  v0186: {
    id: "platformError.v0186",
    defaultMessage: "An invalid display order value was provided",
    description: "Message for Platform's error code v0186",
  },
  v0187: {
    id: "platformError.v0187",
    defaultMessage: "The required property must be true or false",
    description: "Message for Platform's error code v0187",
  },
  v0188: {
    id: "platformError.v0188",
    defaultMessage: "The readonly property must be true or false",
    description: "Message for Platform's error code v0188",
  },
  v0189: {
    id: "platformError.v0189",
    defaultMessage: "The options property must be an array",
    description: "Message for Platform's error code v0189",
  },
  v0190: {
    id: "platformError.v0190",
    defaultMessage: "An invalid data type was provided",
    description: "Message for Platform's error code v0190",
  },
  v0191: {
    id: "platformError.v0191",
    defaultMessage: "The custom field's data type may not be modified",
    description: "Message for Platform's error code v0191",
  },
  v0192: {
    id: "platformError.v0192",
    defaultMessage:
      "When single-option or multi-option is selected the options property must provide at least one display/value pair",
    description: "Message for Platform's error code v0192",
  },
  v0193: {
    id: "platformError.v0193",
    defaultMessage: "The default value property must be an array",
    description: "Message for Platform's error code v0193",
  },
  v0194: {
    id: "platformError.v0194",
    defaultMessage:
      "The default value property may only have a length greater than 1 when multi-option data type is selcted",
    description: "Message for Platform's error code v0194",
  },
  v0195: {
    id: "platformError.v0195",
    defaultMessage: "The default value does not meet the requirements of the given data type",
    description: "Message for Platform's error code v0195",
  },
  v0196: {
    id: "platformError.v0196",
    defaultMessage: "The option must be a valid object",
    description: "Message for Platform's error code v0196",
  },
  v0197: {
    id: "platformError.v0197",
    defaultMessage: "An option display name cannot be empty",
    description: "Message for Platform's error code v0197",
  },
  v0198: {
    id: "platformError.v0198",
    defaultMessage: "An option value's type may only be string or number",
    description: "Message for Platform's error code v0198",
  },
  v0199: {
    id: "platformError.v0199",
    defaultMessage: "Options are only allowed when the data type is single-option or multi-option",
    description: "Message for Platform's error code v0199",
  },
  v0200: {
    id: "platformError.v0200",
    defaultMessage: "The custom field does not exist",
    description: "Message for Platform's error code v0200",
  },
  v0201: {
    id: "platformError.v0201",
    defaultMessage: "The custom field type cannot change",
    description: "Message for Platform's error code v0201",
  },
  v0202: {
    id: "platformError.v0202",
    defaultMessage: "The value property must be an array",
    description: "Message for Platform's error code v0202",
  },
  v0203: {
    id: "platformError.v0203",
    defaultMessage:
      "The value array may only contain more than 1 element when the custom field's data type is multi-option",
    description: "Message for Platform's error code v0203",
  },
  v0204: {
    id: "platformError.v0204",
    defaultMessage: "The value did not meet the requirements of the custom field",
    description: "Message for Platform's error code v0204",
  },
  v0205: {
    id: "platformError.v0205",
    defaultMessage: "Location field is not valid",
    description: "Message for Platform's error code v0205",
  },
  v0206: {
    id: "platformError.v0206",
    defaultMessage: "An email campaign's name cannot be empty",
    description: "Message for Platform's error code v0206",
  },
  v0207: {
    id: "platformError.v0207",
    defaultMessage: "An email campagn with the given name already exists for the user",
    description: "Message for Platform's error code v0207",
  },
  v0208: {
    id: "platformError.v0208",
    defaultMessage: "An invalid email template was provided for the campaign",
    description: "Message for Platform's error code v0208",
  },
  v0209: {
    id: "platformError.v0209",
    defaultMessage: "An invalid scheduledAt date was provided.  Must be in ISO-8601 format",
    description: "Message for Platform's error code v0209",
  },
  v0210: {
    id: "platformError.v0210",
    defaultMessage: "The automated property must be either TRUE or FALSE",
    description: "Message for Platform's error code v0210",
  },
  v0211: {
    id: "platformError.v0211",
    defaultMessage: "An invalid followup campaign condition was provided",
    description: "Message for Platform's error code v0211",
  },
  v0212: {
    id: "platformError.v0212",
    defaultMessage: "The provided followup campaign condition was not found",
    description: "Message for Platform's error code v0212",
  },
  v0213: {
    id: "platformError.v0213",
    defaultMessage: "An invalid followup email template was provided",
    description: "Message for Platform's error code v0213",
  },
  v0214: {
    id: "platformError.v0214",
    defaultMessage: "The provided followup email template was not found",
    description: "Message for Platform's error code v0214",
  },
  v0215: {
    id: "platformError.v0215",
    defaultMessage: "The provided frequency was invalid. Must be 0-3 inclusive",
    description: "Message for Platform's error code v0215",
  },
  v0216: {
    id: "platformError.v0216",
    defaultMessage: "The followupCadenceCount must be greater than or equal to 0",
    description: "Message for Platform's error code v0216",
  },
  v0217: {
    id: "platformError.v0217",
    defaultMessage: "The totalRuns property must be greater than or equal to 0",
    description: "Message for Platform's error code v0217",
  },
  v0218: {
    id: "platformError.v0218",
    defaultMessage: "The lastRunAt property must be null or a valid ISO-8601 date",
    description: "Message for Platform's error code v0218",
  },
  v0219: {
    id: "platformError.v0219",
    defaultMessage:
      "An email template may not be deleted if it is associated with 1 or more email campaigns",
    description: "Message for Platform's error code v0219",
  },
  v0220: {
    id: "platformError.v0220",
    defaultMessage: "An invalid person was provided",
    description: "Message for Platform's error code v0220",
  },
  v0221: {
    id: "platformError.v0221",
    defaultMessage: "An invalid email campaign was provided",
    description: "Message for Platform's error code v0221",
  },
  v0222: {
    id: "platformError.v0222",
    defaultMessage: "The person has already been added to the email campaign",
    description: "Message for Platform's error code v0222",
  },
  v0223: {
    id: "platformError.v0223",
    defaultMessage: "An invalid group was provided",
    description: "Message for Platform's error code v0223",
  },
  v0224: {
    id: "platformError.v0224",
    defaultMessage: "An invalid email campaign was provided",
    description: "Message for Platform's error code v0224",
  },
  v0225: {
    id: "platformError.v0225",
    defaultMessage: "The group has already been added to the email campaign",
    description: "Message for Platform's error code v0225",
  },
  v0226: {
    id: "platformError.v0226",
    defaultMessage:
      "A value has already been created and assigned to the custom field and CRM object combination",
    description: "Message for Platform's error code v0226",
  },
  v0227: {
    id: "platformError.v0227",
    defaultMessage: "Chart filters are not valid",
    description: "Message for Platform's error code v0227",
  },
  v0228: {
    id: "platformError.v0228",
    defaultMessage: "The file name cannot be empty",
    description: "Message for Platform's error code v0228",
  },
  v0229: {
    id: "platformError.v0229",
    defaultMessage: "The checkout date must be null or a valid ISO-8601 date",
    description: "Message for Platform's error code v0229",
  },
  v0230: {
    id: "platformError.v0230",
    defaultMessage: "The followup interval is invalid",
    description: "Message for Platform's error code v0230",
  },
  v0231: {
    id: "platformError.v0231",
    defaultMessage: "Hidden field is not valid",
    description: "Message for Platform's error code v0231",
  },
  v0232: {
    id: "platformError.v0232",
    defaultMessage: "Both points and zips cannot be empty",
    description: "Message for Platform's error code v0232",
  },
  v0233: {
    id: "platformError.v0233",
    defaultMessage: "Zip format is not valid",
    description: "Message for Platform's error code v0233",
  },
  v0234: {
    id: "platformError.v0234",
    defaultMessage: "Points format is not valid",
    description: "Message for Platform's error code v0234",
  },
  v0235: {
    id: "platformError.v0235",
    defaultMessage: "The territory name should be unique",
    description: "Message for Platform's error code v0235",
  },
  v0236: {
    id: "platformError.v0236",
    defaultMessage: "The territory name must be valid",
    description: "Message for Platform's error code v0236",
  },
  v0237: {
    id: "platformError.v0237",
    defaultMessage: "The territory must contain a territoryDetail object",
    description: "Message for Platform's error code v0237",
  },
  v0238: {
    id: "platformError.v0238",
    defaultMessage: "Opacity field is not valid",
    description: "Message for Platform's error code v0238",
  },
  v0239: {
    id: "platformError.v0239",
    defaultMessage: "Activity id is not valid",
    description: "Message for Platform's error code v0239",
  },
  v0240: {
    id: "platformError.v0240",
    defaultMessage: "Record id is not valid",
    description: "Message for Platform's error code v0240",
  },
  v0241: {
    id: "platformError.v0241",
    defaultMessage: "TriggeredAt is invalid or not present",
    description: "Message for Platform's error code v0241",
  },
  v0242: {
    id: "platformError.v0242",
    defaultMessage: "Deal stage display order is not valid",
    description: "Message for Platform's error code v0242",
  },
  v0243: {
    id: "platformError.v0243",
    defaultMessage: "Won Stage or Lost Stage cannot be deleted",
    description: "Message for Platform's error code v0243",
  },
  v0244: {
    id: "platformError.v0244",
    defaultMessage: "Call or email actvities cannot be created by internal service client",
    description: "Message for Platform's error code v0244",
  },
  v0245: {
    id: "platformError.v0245",
    defaultMessage: "The start date must be null or a valid ISO-8601 date",
    description: "Message for Platform's error code v0245",
  },
  v0246: {
    id: "platformError.v0246",
    defaultMessage: "Duration must be null or an integer",
    description: "Message for Platform's error code v0246",
  },
  v0247: {
    id: "platformError.v0247",
    defaultMessage: "GpsAccuracy must be null or an integer",
    description: "Message for Platform's error code v0247",
  },
  v0248: {
    id: "platformError.v0248",
    defaultMessage: "AutoGenerated must be null or a bool value",
    description: "Message for Platform's error code v0248",
  },
  v0249: {
    id: "platformError.v0249",
    defaultMessage: "The note cannot be empty",
    description: "Message for Platform's error code v0249",
  },
  v0250: {
    id: "platformError.v0250",
    defaultMessage: "CrmId must be a string and less than 100 characters",
    description: "Message for Platform's error code v0250",
  },
  v0251: {
    id: "platformError.v0251",
    defaultMessage: "Activity Type must be valid",
    description: "Message for Platform's error code v0251",
  },
  v0252: {
    id: "platformError.v0252",
    defaultMessage: "People group or Individual format is not valid",
    description: "Message for Platform's error code v0252",
  },
  v0253: {
    id: "platformError.v0253",
    defaultMessage: "An invalid email template was provided for email threads",
    description: "Message for Platform's error code v0253",
  },
  v0254: {
    id: "platformError.v0254",
    defaultMessage: "Status is invalid",
    description: "Message for Platform's error code v0254",
  },
  v0255: {
    id: "platformError.v0255",
    defaultMessage: "External Id is invalid",
    description: "Message for Platform's error code v0255",
  },
  v0256: {
    id: "platformError.v0256",
    defaultMessage: "FirstOpenedAt is not a valid date type",
    description: "Message for Platform's error code v0256",
  },
  v0257: {
    id: "platformError.v0257",
    defaultMessage: "FirstRepliedAt is not a valid date type",
    description: "Message for Platform's error code v0257",
  },
  v0258: {
    id: "platformError.v0258",
    defaultMessage: "User does not have access to person",
    description: "Message for Platform's error code v0258",
  },
  v0259: {
    id: "platformError.v0259",
    defaultMessage: "User does not have access to company",
    description: "Message for Platform's error code v0259",
  },
  v0260: {
    id: "platformError.v0260",
    defaultMessage: "User does not have access to delete this record.",
    description: "Message for Platform's error code v0260",
  },
  v0261: {
    id: "platformError.v0261",
    defaultMessage: "The deal group name should be unique",
    description: "Message for Platform's error code v0261",
  },
  v0262: {
    id: "platformError.v0262",
    defaultMessage: "An invalid teamId was provided",
    description: "Message for Platform's error code v0262",
  },
  v0263: {
    id: "platformError.v0263",
    defaultMessage:
      "An invalid Geo Management State was provided. Only automatic or manual are allowed",
    description: "Message for Platform's error code v0263",
  },
  v0264: {
    id: "platformError.v0264",
    defaultMessage: "An invalid deal ID was provided",
    description: "Message for Platform's error code v0264",
  },
  v0265: {
    id: "platformError.v0265",
    defaultMessage: "An invalid company ID was provided",
    description: "Message for Platform's error code v0265",
  },
  v0266: {
    id: "platformError.v0266",
    defaultMessage: "An invalid person ID was provided",
    description: "Message for Platform's error code v0266",
  },
  v0267: {
    id: "platformError.v0267",
    defaultMessage:
      "The mapping property must be an object and contain two properties: type and properties",
    description: "Message for Platform's error code v0267",
  },
  v0268: {
    id: "platformError.v0268",
    defaultMessage:
      "The type field must be a string and one of: accounts, contacts, deals or crm-activities",
    description: "Message for Platform's error code v0268",
  },
  v0269: {
    id: "platformError.v0269",
    defaultMessage: "The mapping.properties field must be an object",
    description: "Message for Platform's error code v0269",
  },
  v0270: {
    id: "platformError.v0270",
    defaultMessage: "The specified property to be mapped must be a non-empty string",
    description: "Message for Platform's error code v0270",
  },
  v0271: {
    id: "platformError.v0271",
    defaultMessage: "A valid file object and corresponding id is required",
    description: "Message for Platform's error code v0271",
  },
  v0272: {
    id: "platformError.v0272",
    defaultMessage: "The status must be a valid string of queued, running, errored, or completed",
    description: "Message for Platform's error code v0272",
  },
  v0273: {
    id: "platformError.v0273",
    defaultMessage: "The metadata property must be a valid object",
    description: "Message for Platform's error code v0273",
  },
  v0274: {
    id: "platformError.v0274",
    defaultMessage: "The mapping.identification property must be an array",
    description: "Message for Platform's error code v0274",
  },
  v0275: {
    id: "platformError.v0275",
    defaultMessage: "The identification field must be a non-empty string",
    description: "Message for Platform's error code v0275",
  },
  v0276: {
    id: "platformError.v0276",
    defaultMessage: "The route format is invalid",
    description: "Message for Platform's error code v0276",
  },
  v0277: {
    id: "platformError.v0277",
    defaultMessage: "Route Type sent is invalid",
    description: "Message for Platform's error code v0277",
  },
  v0278: {
    id: "platformError.v0278",
    defaultMessage: "Start GeoPoint should be valid",
    description: "Message for Platform's error code v0278",
  },
  v0279: {
    id: "platformError.v0279",
    defaultMessage: "End GeoPoint sent is invalid",
    description: "Message for Platform's error code v0279",
  },
  v0280: {
    id: "platformError.v0280",
    defaultMessage: "Need at-least 1 record to create a route. 0 records detected in the request.",
    description: "Message for Platform's error code v0280",
  },
  v0281: {
    id: "platformError.v0281",
    defaultMessage:
      "No route could be found between the starting point, stops and the ending point.",
    description: "Message for Platform's error code v0281",
  },
  v0282: {
    id: "platformError.v0282",
    defaultMessage: "Cannot add more than 69 records to a route",
    description: "Message for Platform's error code v0282",
  },
  v0283: {
    id: "platformError.v0283",
    defaultMessage: "Search must contain at least 3 characters.",
    description: "Message for Platform's error code v0283",
  },
  v0284: {
    id: "platformError.v0284",
    defaultMessage: "Profile photo is invalid",
    description: "Message for Platform's error code v0284",
  },
  v0285: {
    id: "platformError.v0285",
    defaultMessage: "Object format is not valid",
    description: "Message for Platform's error code v0285",
  },
  v0286: {
    id: "platformError.v0286",
    defaultMessage: "A valid date in ISO-8601 format must be provided",
    description: "Message for Platform's error code v0286",
  },
  v0287: {
    id: "platformError.v0287",
    defaultMessage: "A boolean value must be provided",
    description: "Message for Platform's error code v0287",
  },
  v0288: {
    id: "platformError.v0288",
    defaultMessage: "A valid deal loss reason must be provided when the deal is lost",
    description: "Message for Platform's error code v0288",
  },
  v0289: {
    id: "platformError.v0289",
    defaultMessage: "A deal loss comment must not be greater than 250 characters",
    description: "Message for Platform's error code v0289",
  },
  v0290: {
    id: "platformError.v0290",
    defaultMessage: "A deal loss reason cannot be specified until the deal is lost",
    description: "Message for Platform's error code v0290",
  },
  v0291: {
    id: "platformError.v0291",
    defaultMessage: "A deal loss comment cannot be specified until the deal is lost",
    description: "Message for Platform's error code v0291",
  },
  v0292: {
    id: "platformError.v0292",
    defaultMessage: "A deal loss reason name is required and must be less than 128 characters",
    description: "Message for Platform's error code v0292",
  },
  v0293: {
    id: "platformError.v0293",
    defaultMessage: "A deal loss reason must have a unique name",
    description: "Message for Platform's error code v0293",
  },
  v0294: {
    id: "platformError.v0294",
    defaultMessage: "A deal loss reason cannot be deleted when associated with 1 or more deals",
    description: "Message for Platform's error code v0294",
  },
  v0295: {
    id: "platformError.v0295",
    defaultMessage: "A valid startDate in ISO-8601 format must be provided",
    description: "Message for Platform's error code v0295",
  },
  v0296: {
    id: "platformError.v0296",
    defaultMessage: "A valid endDate in ISO-8601 format must be provided",
    description: "Message for Platform's error code v0296",
  },
  v0297: {
    id: "platformError.v0297",
    defaultMessage: "An invalid user was provided for the note",
    description: "Message for Platform's error code v0297",
  },
  v0298: {
    id: "platformError.v0298",
    defaultMessage: "The report name should be unique and less than 128 characters",
    description: "Message for Platform's error code v0298",
  },
  v0299: {
    id: "platformError.v0299",
    defaultMessage: "Table name is invalid",
    description: "Message for Platform's error code v0299",
  },
  v0300: {
    id: "platformError.v0300",
    defaultMessage: "Selected columns format is invalid",
    description: "Message for Platform's error code v0300",
  },
  v0301: {
    id: "platformError.v0301",
    defaultMessage: "Selected filters are invalid",
    description: "Message for Platform's error code v0301",
  },
  v0302: {
    id: "platformError.v0302",
    defaultMessage: "Description format is invalid or lager than 512 characters",
    description: "Message for Platform's error code v0302",
  },
  v0303: {
    id: "platformError.v0303",
    defaultMessage: "Link format is invalid",
    description: "Message for Platform's error code v0303",
  },
  v0304: {
    id: "platformError.v0304",
    defaultMessage: "Status is invalid",
    description: "Message for Platform's error code v0304",
  },
  v0305: {
    id: "platformError.v0305",
    defaultMessage: "LastGeneratedAt is invalid",
    description: "Message for Platform's error code v0305",
  },
  v0306: {
    id: "platformError.v0306",
    defaultMessage: "Custom fields format is invalid",
    description: "Message for Platform's error code v0306",
  },
  v0307: {
    id: "platformError.v0307",
    defaultMessage: "isStarred format is invalid",
    description: "Message for Platform's error code v0307",
  },
  v0308: {
    id: "platformError.v0308",
    defaultMessage: "The import must contain a mapping.options object",
    description: "Message for Platform's error code v0308",
  },
  v0309: {
    id: "platformError.v0309",
    defaultMessage:
      "The import must specify a mapping.options.geoManagementState property with a valid value",
    description: "Message for Platform's error code v0309",
  },
  v0310: {
    id: "platformError.v0310",
    defaultMessage: "The user to be assigned should be valid and should belong to the organization",
    description: "Message for Platform's error code v0310",
  },
  v0312: {
    id: "platformError.v0312",
    defaultMessage: "A valid person must be provided",
    description: "Message for Platform's error code v0312",
  },
  v0313: {
    id: "platformError.v0313",
    defaultMessage: "A valid deal must be provided",
    description: "Message for Platform's error code v0313",
  },
  v0314: {
    id: "platformError.v0314",
    defaultMessage: "A valid deal must be provided",
    description: "Message for Platform's error code v0314",
  },
  v0315: {
    id: "platformError.v0315",
    defaultMessage: "A funnel may not re-assigned while deals exist in one or more of its stages",
    description: "Message for Platform's error code v0315",
  },
  v0316: {
    id: "platformError.v0316",
    defaultMessage:
      "The report is currently in progress.  It can be started again once it completes",
    description: "Message for Platform's error code v0316",
  },
  v0317: {
    id: "platformError.v0317",
    defaultMessage: "The token is invalid. It must be a valid non-empty string",
    description: "Message for Platform's error code v0317",
  },
  v0318: {
    id: "platformError.v0318",
    defaultMessage: "The calendar activity type must be valid",
    description: "Message for Platform's error code v0318",
  },
  v0319: {
    id: "platformError.v0319",
    defaultMessage: "The calendar id must be valid",
    description: "Message for Platform's error code v0319",
  },
  v0320: {
    id: "platformError.v0320",
    defaultMessage: "Invalid value provide for twoWaySync",
    description: "Message for Platform's error code v0320",
  },
  v0321: {
    id: "platformError.v0321",
    defaultMessage: "The object format is invalid",
    description: "Message for Platform's error code v0321",
  },
  v0322: {
    id: "platformError.v0322",
    defaultMessage: "The object contains invalid activity types",
    description: "Message for Platform's error code v0322",
  },
  v0323: {
    id: "platformError.v0323",
    defaultMessage: "The username must be a valid non-empty string",
    description: "Message for Platform's error code v0323",
  },
  v0324: {
    id: "platformError.v0324",
    defaultMessage: "An unexpected username was provided",
    description: "Message for Platform's error code v0324",
  },
  v0325: {
    id: "platformError.v0325",
    defaultMessage:
      "Password couldn’t be saved - please try again. If the problem persists, please contact support.",
    description: "Message for Platform's error code v0325",
  },
  v0326: {
    id: "platformError.v0326",
    defaultMessage: "The password request has expired",
    description: "Message for Platform's error code v0326",
  },
  v0327: {
    id: "platformError.v0327",
    defaultMessage: "The user associated with the password reset no longer exists",
    description: "Message for Platform's error code v0327",
  },
  v0328: {
    id: "platformError.v0328",
    defaultMessage: "The route companies format is invalid",
    description: "Message for Platform's error code v0328",
  },
  v0329: {
    id: "platformError.v0329",
    defaultMessage: "Length exceeded for maximum allowed companies for routing",
    description: "Message for Platform's error code v0329",
  },
  v0330: {
    id: "platformError.v0330",
    defaultMessage: "All the companies sent are not accessible by the user",
    description: "Message for Platform's error code v0330",
  },

  v0331: {
    id: "platformError.v0331",
    defaultMessage: "ImportId cannot be changed",
    description: "Message for Platform's error code v0331",
  },
  v0332: {
    id: "platformError.v0332",
    defaultMessage: "An invalid display order value was provided",
    description: "Message for Platform's error code v0332",
  },
  v0333: {
    id: "platformError.v0333",
    defaultMessage: "Organization already have a paid plan",
    description: "Message for Platform's error code v0333",
  },
  v0334: {
    id: "platformError.v0334",
    defaultMessage: "Device Token is invalid",
    description: "Message for Platform's error code v0334",
  },
  v0335: {
    id: "platformError.v0335",
    defaultMessage: "Device Type is invalid",
    description: "Message for Platform's error code v0335",
  },
  v0336: {
    id: "platformError.v0336",
    defaultMessage: "User does not have access to all groups",
    description: "Message for Platform's error code v0336",
  },
  v0337: {
    id: "platformError.v0337",
    defaultMessage: "User does not have access to all people",
    description: "Message for Platform's error code v0337",
  },
  v0338: {
    id: "platformError.v0338",
    defaultMessage: "User does not have access to all deals",
    description: "Message for Platform's error code v0338",
  },
  v0339: {
    id: "platformError.v0339",
    defaultMessage: "User does not have access to all companies",
    description: "Message for Platform's error code v0339",
  },

  v0340: {
    id: "platformError.v0340",
    defaultMessage: "Value cannot be changed for frozen custom field",
    description: "Message for Platform's error code v0340",
  },
  v0341: {
    id: "platformError.v0341",
    defaultMessage: "Current User Role cannot create or change status for frozen custom field",
    description: "Message for Platform's error code v0341",
  },
  v0342: {
    id: "platformError.v0342",
    defaultMessage: "Current User Role cannot delete frozen custom field",
    description: "Message for Platform's error code v0342",
  },
  v0343: {
    id: "platformError.v0343",
    defaultMessage: "User is not verified",
    description: "Message for Platform's error code v0343",
  },
  v0344: {
    id: "platformError.v0344",
    defaultMessage: "Cannot update, delete or deactivate system-defined activity types.",
    description: "Message for Platform's error code v0344",
  },
  v0345: {
    id: "platformError.v0345",
    defaultMessage: "An invalid activity ID was provided",
    description: "Message for Platform's error code v0345",
  },
  v0346: {
    id: "platformError.v0346",
    defaultMessage: "Search must contain valid entity type or no type",
    description: "Message for Platform's error code v0346",
  },
  v0347: {
    id: "platformError.v0347",
    defaultMessage: "Use either GroupId or GroupKey",
    description: "Message for Platform's error code v0347",
  },
  v0348: {
    id: "platformError.v0348",
    defaultMessage: "GroupId or GroupKey field must be provided",
    description: "Message for Platform's error code v0348",
  },
  v0349: {
    id: "platformError.v0349",
    defaultMessage: "ID does not exist or you are not authorized to access this record.",
    description: "Message for Platform's error code v0349",
  },
  v0350: {
    id: "platformError.v0350",
    defaultMessage: "We do not support processing more than 1000 records in bulk operations.",
    description: "Message for Platform's error code v0350",
  },
  v0351: {
    id: "platformError.v0351",
    defaultMessage:
      "Parent Account that you are trying to set is causing a circular reference. Please check the parent account and all other accounts that inherit the parent account.",
    description: "Message for Platform's error code v0351",
  },
  v0352: {
    id: "platformError.v0352",
    defaultMessage: "Cannot set parent account same as account being created or modified.",
    description: "Message for Platform's error code v0352",
  },
  v0353: {
    id: "platformError.v0353",
    defaultMessage: "Update with patch requires all records in the payload to have same columns.",
    description: "Message for Platform's error code v0353",
  },
  v0354: {
    id: "platformError.v0354",
    defaultMessage:
      "Unable to generate this report as location on radius filter is blank. Please reset the filters.",
    description: "Message for Platform's error code v0354",
  },
  v0355: {
    id: "platformError.v0355",
    defaultMessage: "The email provided for sendOptions has an invalid format",
    description: "Message for Platform's error code v0355",
  },
  v0356: {
    id: "platformError.v0356",
    defaultMessage:
      "The deliveryType value provided in sendOptions has an invalid format. It can either be attachment or sftp.",
    description: "Message for Platform's error code v0356",
  },
  v0357: {
    id: "platformError.v0357",
    defaultMessage:
      "The sendFullReport value provided in sendOptions has an invalid format. It can only be true or false.",
    description: "Message for Platform's error code v0357",
  },
  v0358: {
    id: "platformError.v0358",
    defaultMessage: "Frequency is not in a valid format.",
    description: "Message for Platform's error code v0358",
  },
  v0359: {
    id: "platformError.v0359",
    defaultMessage: "Invalid account synchronization status for Nylas-Email syncing.",
    description: "Message for Platform's error code v0359",
  },
  v0362: {
    id: "platformError.v0362",
    defaultMessage: "An application name is required.",
    description: "Message for Platform's error code v0362",
  },
  v0363: {
    id: "platformError.v0363",
    defaultMessage: "Client ID and Secret cannot be modified.",
    description: "Message for Platform's error code v0363",
  },
  v0364: {
    id: "platformError.v0364",
    defaultMessage: "Organization ID cannot be part of the request object.",
    description: "Message for Platform's error code v0364",
  },
  v0365: {
    id: "platformError.v0365",
    defaultMessage: "A valid account/contact must be selected to create check-in activity",
    description: "Message for Platform's error code v0365",
  },
  v0366: {
    id: "platformError.v0366",
    defaultMessage:
      "<title>Something went wrong</title><description>v0366: Only supported CRM types are Hubspot, Dynamics, Salesforce and Zoho. Please contact customer support.</description>",
    description: "Message for Platform's error code v0366",
  },
  v0367: {
    id: "platformError.v0367",
    defaultMessage:
      "A valid CRM type (name of CRM) is required. Only supported CRM types are Hubspot and Salesforce.",
    description: "Message for Platform's error code v0367",
  },
  v0368: {
    id: "platformError.v0368",
    defaultMessage:
      "<title>Something went wrong</title><description>v0368: CRM authentication code is a required field to set up the integration. Please contact customer support.</description>",
    description: "Message for Platform's error code v0368",
  },
  v0369: {
    id: "platformError.v0369",
    defaultMessage: "Cannot create a contact with an id in the payload",
    description: "Message for Platform's error code v0369",
  },
  v0370: {
    id: "platformError.v0370",
    defaultMessage:
      "<title>Something went wrong</title><description>v0370: Only one integration per organization is allowed. Please contact customer support.</description>",

    description: "Message for Platform's error code v0370",
  },
  v0371: {
    id: "platformError.v0371",
    defaultMessage:
      "<title>Cannot match with deleted user</title><description>One or more of the selected Map My Customers users has been deleted.</description>",
    description: "Message for Platform's error code v0371",
  },
  v0372: {
    id: "platformError.v0372",
    defaultMessage:
      "<title>Something went wrong</title><description>v0372: One or more of the id's you are trying to update is either duplicate or incorrect. Please contact customer support.</description>",

    description: "Message for Platform's error code v0372",
  },
  v0373: {
    id: "platformError.v0373",
    defaultMessage:
      "<title>Something went wrong</title><description>v0373: Id, entityType, mmcField, mmcGroupField and syncing are required fields. Please contact customer support.</description>",

    description: "Message for Platform's error code v0373",
  },
  v0374: {
    id: "platformError.v0374",
    defaultMessage: "Cannot create a deal with an id in the payload",
    description: "Message for Platform's error code v0374",
  },
  v0375: {
    id: "platformError.v0375",
    defaultMessage:
      "<title>Missing required field</title><description>You must match a CRM field to 'First Name' in order to sync with People.</description>",

    description: "Message for Platform's error code v0375",
  },
  v0376: {
    id: "platformError.v0376",
    defaultMessage:
      "<title>Missing required field</title><description>You must match a CRM field to 'Name' in order to sync with Companies.</description>",
    description: "Message for Platform's error code v0376",
  },
  v0377: {
    id: "platformError.v0377",
    defaultMessage:
      "<title>Missing required field</title><description>You must match a CRM field to 'Name' in order to sync with Deals.</description>",
    description: "Message for Platform's error code v0377",
  },
  v0378: {
    id: "platformError.v0378",
    defaultMessage:
      "<title>Something went wrong</title><description>v0378: Only acceptable values for entity are Accounts, Contacts, Deals, Leads or Activities. Please contact customer support.</description>",
    description: "Message for Platform's error code v0378",
  },
  v0379: {
    id: "platformError.v0379",
    defaultMessage: "entityType cannot be blank.",
    description: "Message for Platform's error code v0379",
  },
  v0380: {
    id: "platformError.v0380",
    defaultMessage: "mmcField should be unique.",
    description: "Message for Platform's error code v0380",
  },
  v0381: {
    id: "platformError.v0381",
    defaultMessage:
      "<title>Missing required field(s)</title><description>You must match CRM fields to 'Name' and “Activity Type” in order to sync with Activities.</description>",
    description: "Message for Platform's error code v0381",
  },
  v0382: {
    id: "platformError.v0382",
    defaultMessage: "Cannot create an activity with an id in the payload",
    description: "Message for Platform's error code v0382",
  },
  v0383: {
    id: "platformError.v0383",
    defaultMessage:
      "Integration logs upsert endpoint only accepts an array of records. Please check the data format of the payload.",
    description: "Message for Platform's error code v0383",
  },
  v0384: {
    id: "platformError.v0384",
    defaultMessage:
      "integrationSyncId, entityType and mmcId are required fields when logging an error in direction toCRM",
    description: "Message for Platform's error code v0384",
  },
  v0385: {
    id: "platformError.v0385",
    defaultMessage:
      "integrationSyncId, entityType and crmId are required fields when logging an error in direction toMMC",
    description: "Message for Platform's error code v0385",
  },
  v0386: {
    id: "platformError.v0386",
    defaultMessage:
      "Duplicate records detected in the route. Please remove the overlapping records and try again",
    description: "Message for Platform's error code v0386",
  },
  v0387: {
    id: "platformError.v0387",
    defaultMessage:
      "Few stops in the route do not have a valid location. Please remove from the route and try again.",
    description: "Message for Platform's error code v0387",
  },
  v0388: {
    id: "platformError.v0388",
    defaultMessage:
      "Frequency, Next Run Time and Type are required fields when defining a schedule.",
    description: "Message for Platform's error code v0388",
  },
  v0389: {
    id: "platformError.v0389",
    defaultMessage:
      "<title>Something went wrong</title><description>v0389: Frequency or Interval is missing or not in the correct format. Please contact customer support.</description>",
    description: "Message for Platform's error code v0389",
  },
  v0390: {
    id: "platformError.v0390",
    defaultMessage:
      "<title>Something went wrong</title><description>v0390: Cannot modify a custom schedule. Please contact customer support.</description>",

    description: "Message for Platform's error code v0390",
  },
  v0391: {
    id: "platformError.v0391",
    defaultMessage:
      "<title>Something went wrong</title><description>v0391: Next Run Time is not in correct format. Should be of data type timestamp. Please contact customer support.</description>",
    description: "Message for Platform's error code v0391",
  },
  v0392: {
    id: "platformError.v0392",
    defaultMessage:
      "<title>Invalid sync start date and time</title><description>Sync start cannot be in the past.</description>",
    description: "Message for Platform's error code v0392",
  },
  v0393: {
    id: "platformError.v0393",
    defaultMessage: "Frequency cannot be set less than 10 minutes.",
    description: "Message for Platform's error code v0393",
  },
  v0394: {
    id: "platformError.v0394",
    defaultMessage:
      "<title>Something went wrong</title><description>v0394: Schedule type value can only be standard or custom. Please contact customer support.</description>",
    description: "Message for Platform's error code v0394",
  },
  v0395: {
    id: "platformError.v0395",
    defaultMessage: "Coordinates Point has Incorrect Value.",
    description: "Message for Platform's error code v0395",
  },
  v0396: {
    id: "platformError.v0396",
    defaultMessage:
      "Parent field not in correct format. It should be of type object with id as a key and value should be parent team's id",
    description: "Message for Platform's error code v0396",
  },
  v0397: {
    id: "platformError.v0397",
    defaultMessage:
      "Parent team id does not exist or you are not authorized to access the parent team.",
    description: "Message for Platform's error code v0397",
  },
  v0398: {
    id: "platformError.v0398",
    defaultMessage:
      "Circular reference detected. Parent Team you are trying to set is same as this team or a child of this team.",
    description: "Message for Platform's error code v0398",
  },
  v0399: {
    id: "platformError.v0399",
    defaultMessage:
      "This team has sub-teams attached to it. Cannot delete unless sub-teams are deleted first.",
    description: "Message for Platform's error code v0399",
  },
  v0400: {
    id: "platformError.v0400",
    defaultMessage: "Role with same name already exists",
    description: "Message for Platform's error code v0400",
  },
  v0401: {
    id: "platformError.v0401",
    defaultMessage:
      "<title>Problem matching users</title><description>You cannot match more than one CRM user to a single Map My Customers user.</description>",
    description: "Message for Platform's error code v0401",
  },
  v0402: {
    id: "platformError.v0402",
    defaultMessage:
      "<title>Something went wrong</title><description>v0402: Syncing is a required field.</description>",
    description: "Message for Platform's error code v0402",
  },
  v0403: {
    id: "platformError.v0403",
    defaultMessage: "User does not have access to one or more leads",
    description: "Message for Platform's error code v0403",
  },
  v0404: {
    id: "platformError.v0404",
    defaultMessage: "Id must be an integer value",
    description: "Message for Platform's error code v0404",
  },
  v0405: {
    id: "platformError.v0405",
    defaultMessage: "This lead has already been converted.",
    description: "Message for Platform's error code v0405",
  },
  v0406: {
    id: "platformError.v0406",
    defaultMessage: "Filter condition is not defined for this workflow",
    description: "Message for Platform's error code v0406",
  },
  v0407: {
    id: "platformError.v0407",
    defaultMessage:
      "There is mismatch between Saved Filter and Workflow types.Please check the url",
    description: "Message for Platform's error code v0407",
  },
  v0408: {
    id: "platformError.v0408",
    defaultMessage: "Only owner can change the role of team owner",
    description: "Message for Platform's error code v0408",
  },
  v0409: {
    id: "platformError.v0409",
    defaultMessage: "You are not allowed to change your role",
    description: "Message for Platform's error code v0409",
  },
  v0410: {
    id: "platformError.v0410",
    defaultMessage: "Password was used previously -  please pick a new one",
    description: "Message for Platform's error code v0410",
  },
  v0411: {
    id: "platformError.v0411",
    defaultMessage:
      "<title>Something went wrong</title><description>v0411: Only acceptable values for envType are Production and Sandbox. Please contact customer support.</description>",
    description: "Message for Platform's error code v0411",
  },
  v0412: {
    id: "platformError.v0412",
    defaultMessage: "Resync Time cannot be greater than present time.",
    description: "Message for Platform's error code v0412",
  },
  v0413: {
    id: "platformError.v0413",
    defaultMessage:
      "Sync Entities field must be an array and combination of accounts, contacts, deals, activities and leads.",
    description: "Message for Platform's error code v0413",
  },
  v0414: {
    id: "platformError.v0414",
    defaultMessage: "userCrmIds field expects to be an array of userCrmId's.",
    description: "Message for Platform's error code v0414",
  },
  v0415: {
    id: "platformError.v0415",
    defaultMessage:
      "some users selected for sync are incorrect. Please check the syncing status via integrations wizard.",
    description: "Message for Platform's error code v0415",
  },
  v0418: {
    id: "platformError.v0418",
    defaultMessage: "Integration is Locked. Please Unlock it to run custom sync.",
    description: "Message for Platform's error code v0418",
  },
  v0419: {
    id: "platformError.v0419",
    defaultMessage: "IncomingOnly and OutgoingOnly cannot be true in same sync.",
    description: "Message for Platform's error code v0419",
  },
  v0420: {
    id: "platformError.v0420",
    defaultMessage:
      "<title>Missing required field</title><description>You must match a CRM field to 'First Name' in order to sync with Leads.</description>",
    description: "Message for Platform's error code v0420",
  },
  v0421: {
    id: "platformError.v0421",
    defaultMessage: "id and value are required fields to define Custom workflow.",
    description: "Message for Platform's error code v0421",
  },
  v0422: {
    id: "platformError.v0422",
    defaultMessage: "Some fields defined are deleted or invalid.",
    description: "Message for Platform's error code v0422",
  },
  v0423: {
    id: "platformError.v0423",
    defaultMessage: "You do not have sufficient permissions to change ownership of records",
    description: "Message for Platform's error code v0423",
  },
  v0424: {
    id: "platformError.v0424",
    defaultMessage: "Workflow must have valid groupExpression or field to be executed upon",
    description: "Message for Platform's error code v0424",
  },
  v0425: {
    id: "platformError.v0425",
    defaultMessage: "Timezone offset should be a string",
    description: "Message for Platform's error code v0425",
  },
  v0426: {
    id: "platformError.v0426",
    defaultMessage: "Should be a non-empty valid string and less than 255 characters.",
    description: "Message for Platform's error code v0426",
  },
  v0427: {
    id: "platformError.v0427",
    defaultMessage: "Payload can not be empty.",
    description: "Message for Platform's error code v0427",
  },
  v0428: {
    id: "platformError.v0428",
    defaultMessage: "Deal stage name can not be empty.",
    description: "Message for Platform's error code v0428",
  },
  v0429: {
    id: "platformError.v0429",
    defaultMessage: "accountsUrl is required field to setup zoho integrations",
    description: "Message for Platform's error code v0429",
  },
  v0430: {
    id: "platformError.v0430",
    defaultMessage: "The lead group name should be unique.",
    description: "Message for Platform's error code v0430",
  },
  v0431: {
    id: "platformError.v0431",
    defaultMessage: "Array must contain only string values",
    description: "Message for Platform's error code v0431",
  },
  v0432: {
    id: "platformError.v0432",
    defaultMessage: "User does not have access to one or more person",
    description: "Message for Platform's error code v0432",
  },
  v0433: {
    id: "platformError.v0433",
    defaultMessage: "You must accept terms and conditions to signup",
    description: "Message for Platform's error code v0433",
  },
  v0434: {
    id: "platformError.v0434",
    defaultMessage:
      "Your free trial has expired. Please log in to https://app.mapmycustomers.com to upgrade your account.",
    description: "Message for Platform's error code v0434",
  },
  v0435: {
    id: "platformError.v0435",
    defaultMessage: "Your account has been deactivated. Please contact your organization owner.",
    description: "Message for Platform's error code v0435",
  },
  v0436: {
    id: "platformError.v0436",
    defaultMessage: "Please contact support to upgrade your account.",
    description: "Message for Platform's error code v0436",
  },
  v0437: {
    id: "platformError.v0437",
    defaultMessage: "A mapping between this record and this file could not be found.",
    description: "Message for Platform's error code v0437",
  },
  v0438: {
    id: "platformError.v0438",
    defaultMessage:
      "Grant must be an array which can have a combination of password,refresh_token or authorization_code types",
    description: "Message for Platform's error code v0438",
  },
  v0439: {
    id: "platformError.v0439",
    defaultMessage: "Redirect URIs must be an array",
    description: "Message for Platform's error code v0439",
  },
  v0440: {
    id: "platformError.v0440",
    defaultMessage:
      "<title>Problem matching users</title><description>The user selected for syncing unassinged records is invalid. Please contact customer support.</description>",
    description: "Message for Platform's error code v0440",
  },
  v0441: {
    id: "platformError.v0441",
    defaultMessage: "rottingInterval is not a valid number",
    description: "Message for Platform's error code v0441",
  },
  v0442: {
    id: "platformError.v0442",
    defaultMessage: "The visibility field must be a string and one of: public, private or teams",
    description: "Message for Platform's error code v0442",
  },
  v0443: {
    id: "platformError.v0443",
    defaultMessage:
      "You have permissions those depend upon these permissions. Please revoke those first to proceed.",
    description: "Message for Platform's error code v0443",
  },
  v0444: {
    id: "platformError.v0444",
    defaultMessage:
      "You cannot assign some of the permissions due to missing required permission criteria on them",
    description: "Message for Platform's error code v0444",
  },
  v0451: {
    id: "platformError.v0451",
    defaultMessage:
      "<title>No matched users</title><description>At least one user from your CRM must be matched to a Map My Customers user.</description>",
    description: "Message for Platform's error code v0451",
  },
  v0472: {
    id: "platformError.v0472",
    defaultMessage:
      "<title>Something went erong</title><description>The selected instance is not associated with the current Dynamics account.</description>",
    description: "Message for Platform's error code v0472",
  },
  v0516: {
    id: "platformError.v0516",
    defaultMessage:
      "<title>Invalid Frequency</title><description>Frequency cannot be more frequent than Daily.</description>",
    description: "Message for Platform's error code v0516",
  },
  v0597: {
    id: "platformError.v0597",
    defaultMessage: "Dynamic groups cannot be removed from this record manually.",
    description: "Message for Platform's error code v0597",
  },
  v0748: {
    id: "platformError.v0748",
    defaultMessage:
      "This email isn’t recognized. If your organization uses MMC, an account for this email may not have been set up yet. In this case, please contact your admin to invite you.",
    description: "Message for Platform's error code v0748",
  },
  v0749: {
    id: "platformError.v0749",
    defaultMessage:
      "SSO is not set up with your organization. Please go back and sign in with your username and password.",
    description: "Message for Platform's error code v0749",
  },
  v0752: {
    id: "platformError.v0752",
    defaultMessage: "Session id is invalid or expired",
    description: "Message for Platform's error code v0752",
  },
  v0755: {
    id: "platformError.v0755",
    defaultMessage: "A request to add more seats so you can join has been sent to your admin.",
    description: "Message for Platform's error code v0755",
  },
  v0764: {
    id: "platformError.v0764",
    defaultMessage: "You can only relate up to 100 Companies to an individual Person",
    description: "Message for Platform's error code v0764",
  },
});

const getErrorMessageByCode = (intl: IntlShape, code: string): string | undefined =>
  code in platformErrorMessageByCode
    ? (intl.formatMessage(
        platformErrorMessageByCode[code as keyof typeof platformErrorMessageByCode],
        {
          description: (text) => <div className={styles.description}>{text}</div>,
          passwordMinimumLength: PASSWORD_MINIMUM_LENGTH,
          title: (text) => <div className={styles.title}>{text}</div>,
        }
      ) as string)
    : undefined;

export default getErrorMessageByCode;
