import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const notificationState = (state: RootState) => state.notification;

export const getNotifications = createSelector(
  notificationState,
  ({ notifications }) => notifications
);

export const getNotificationUnreadCount = createSelector(
  notificationState,
  ({ unreadTotal }) => unreadTotal
);

export const getNotificationUnreadUnfilteredCount = createSelector(
  notificationState,
  ({ unreadUnfilteredTotal }) => unreadUnfilteredTotal
);

export const getNotificationTotalCount = createSelector(notificationState, ({ total }) => total);

export const isOnlyMentions = createSelector(notificationState, ({ onlyMentions }) => onlyMentions);

export const isNotificationLoading = createSelector(notificationState, ({ loading }) => loading);

export const isCadenceModalShowing = createSelector(
  notificationState,
  ({ cadenceModal }) => cadenceModal.visible
);

export const getCadenceModalEntities = createSelector(
  notificationState,
  ({ cadenceModal }) => cadenceModal.entities
);

export const getCadenceModalNotification = createSelector(
  notificationState,
  ({ cadenceModal }) => cadenceModal.notification
);

export const isNotificationCadenceDataLoading = createSelector(
  notificationState,
  ({ cadenceModal }) => cadenceModal.loading
);

export const isNotificationsVisible = createSelector(notificationState, ({ visible }) => visible);
