import {
  AreaCondition,
  EntityAreaCondition,
  PointAreaCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

export const isEntityAreaCondition = (condition: AreaCondition): condition is EntityAreaCondition =>
  "entity" in condition;

export const isPointAreaCondition = (condition: AreaCondition): condition is PointAreaCondition =>
  "point" in condition;
