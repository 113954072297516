import React from "react";

import Select from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import countryListOptions from "@app/util/country-options.json";

import useCountryListOptions from "../countries/useCountryListOptions";

import styles from "./CountryFilter.module.scss";
import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "./getHumanReadableDescriptionForOptionsFilter";

export const COUNTRY_FILTER_OPERATORS = [FilterOperator.IN_ANY, FilterOperator.NONE_OF];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "string") &&
  COUNTRY_FILTER_OPERATORS.includes(operator);

interface CountryFilterProps extends IFilterComponentProps {}

const CountryFilter: IFilterInstance = {
  doesSupportValue,
  getComponent:
    (): React.FC<CountryFilterProps> =>
    ({ className, onChange, value }) => {
      const intl = useIntl();
      const countryListOptions = useCountryListOptions(intl);

      return (
        <Select<string[]>
          className={cn(styles.container, className)}
          filterOption={defaultFilterOption}
          mode="multiple"
          onChange={(countries: string[]) => onChange?.({ ...value, value: countries })}
          options={countryListOptions}
          placeholder={intl.formatMessage({
            id: "filters.country.select.placeholder",
            defaultMessage: "Click or type to select colors",
            description: "Placeholder displayed in CountryFilter's select field",
          })}
          value={Array.isArray(value.value) ? value.value : []}
        />
      );
    },
  getHumanReadableDescription: (value: SimpleCondition, field: IField) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const selectedValues = new Set(value.value as string[]);
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      countryListOptions.filter(({ value }) => selectedValues.has(value)).map(({ label }) => label)
    );
  },
};

export default CountryFilter;
