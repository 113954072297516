import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const settingsState = (state: RootState) => state.scene.settings.customFields;

export const getCreateEditEntityType = createSelector(
  settingsState,
  ({ createEdit: { entityType } }) => entityType
);

export const getEditedCustomField = createSelector(
  settingsState,
  ({ createEdit: { customField } }) => customField
);

export const getEditCustomFieldStep = createSelector(
  settingsState,
  ({ createEdit: { step } }) => step
);
