import React, { useCallback, useMemo } from "react";

import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import SelectField from "@app/component/input/SelectField";
import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import defaultSelectOptionMapper from "@app/util/mappers/defaultSelectOptionMapper";

export const FUNNEL_FILTER_OPERATORS = [
  FilterOperator.EQUALS,
  FilterOperator.IN_ANY,
  FilterOperator.NONE_OF,
];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  (Array.isArray(value) &&
    value.every((item) => typeof item === "number") &&
    (operator === FilterOperator.IN_ANY || operator === FilterOperator.NONE_OF)) ||
  (typeof value === "number" && operator === FilterOperator.EQUALS);

interface FunnelFilterProps extends IFilterComponentProps {}

const FunnelFilter: IFilterInstance = {
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_FUNNELS,
  doesSupportValue,
  getComponent:
    (): React.FC<FunnelFilterProps> =>
    ({ className, focus, onChange, options, value }) => {
      const selectOptions = useMemo(
        () => ((options?.availableFunnels ?? []) as Funnel[]).map(defaultSelectOptionMapper),
        [options?.availableFunnels]
      );

      const setRef = useCallback(
        (ref: null | SelectField<any>) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return value.operator === FilterOperator.EQUALS ? (
        <SelectField<Funnel["id"]>
          className={className}
          filterOption={defaultFilterOption}
          onChange={(funnelId: Funnel["id"]) => onChange?.({ ...value, value: funnelId })}
          options={selectOptions}
          ref={setRef}
          size="middle"
          value={typeof value.value === "number" ? value.value : undefined}
        />
      ) : (
        <SelectField<Funnel["id"][]>
          className={className}
          filterOption={defaultFilterOption}
          maxTagCount="responsive"
          mode="multiple"
          onChange={(funnelIds: Funnel["id"][]) => onChange?.({ ...value, value: funnelIds })}
          options={selectOptions}
          ref={setRef}
          size="middle"
          value={Array.isArray(value.value) ? value.value : undefined}
        />
      );
    },
};

export default FunnelFilter;
