import packageJson from "../../package.json";

export const basepath = packageJson.homepage;

enum Path {
  ROOT = "/",

  ACTIVITY = "/activity",
  BILLING = "/billing",
  COMPANY = "/company",
  DASHBOARD = "/dashboard",
  DEAL = "/deals",
  EXTERNAL_LOGIN = "/ext-auth",
  FORGOT_PASSWORD = "/forgot-password",
  HOME = "/home",
  IMPORT = "/import",
  LOGIN = "/login",
  MANAGE_USER_ACCESS = "/sso-access",
  MAP = "/map",
  NOTIFICATIONS = "/notifications",
  PEOPLE = "/people",
  REGISTER = "/register",
  REPORTS = "/reports",
  RESET_PASSWORD = "/reset-password",
  SETTINGS = "/settings",
  SSO_LOGIN = "/ssologin",
  WEB_1 = "/web1",
}

export default Path;
