import React, { useCallback } from "react";

import { faRedo, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSlate } from "slate-react";

import { stopEvents } from "@mapmycustomers/shared/util/browser";

import ActionTooltipWrapper from "../ActionTooltipWrapper";
import styles from "../Toolbar.module.scss";

interface Props {
  redo?: boolean;
}

const HistoryControl: React.FC<Props> = ({ redo }) => {
  const editor = useSlate();

  const handleAction = useCallback(() => {
    (redo ? editor.redo : editor.undo)();
  }, [editor, redo]);

  return (
    <ActionTooltipWrapper
      translationString={redo ? "ui.emailBody.toolbar.redo" : "ui.emailBody.toolbar.undo"}
    >
      <span className={styles.togglerButton} onClick={handleAction} onMouseDown={stopEvents}>
        <FontAwesomeIcon icon={redo ? faRedo : faUndo} size="xs" />
      </span>
    </ActionTooltipWrapper>
  );
};

export default HistoryControl;
