import { FormInstance } from "antd";

import { Company } from "@mapmycustomers/shared/types/entity";

import locatedToAddress from "@app/util/locatedToAddress";

export const inheritAddressFromCompany = (form: FormInstance<any>, inheritAddressFrom: Company) => {
  form.setFieldsValue({
    addressDetails:
      inheritAddressFrom.address !== undefined ? locatedToAddress(inheritAddressFrom) : undefined,
    latitude: inheritAddressFrom.geoPoint?.coordinates[1]?.toString(),
    longitude: inheritAddressFrom.geoPoint?.coordinates[0]?.toString(),
  });
};
