enum LeaderboardMetricFieldName {
  ACTIVITIES_COMPLETED = "activitiesCompleted",
  CHECK_INS_COMPLETED = "checkInsCompleted",
  COMPANIES_CREATED = "accountsCreated",
  DEALS_CLOSED_LOST = "dealsClosedLost",
  DEALS_CLOSED_WON = "dealsClosedWon",
  DEALS_CREATED = "dealsCreated",
  DEALS_ROTTING = "dealsRotting",
  NEW_PIPELINE_REVENUE = "newPipelineRevenue",
  PEOPLE_CREATED = "contactsCreated",
  REVENUE_CLOSED_LOST = "revenueClosedLost",
  REVENUE_CLOSED_WON = "revenueClosedWon",
  ROUTES_CREATED = "routesCreated",
  VERIFIED_CHECK_INS = "verifiedCheckIns",
  VERIFIED_FACE_TIME = "verifiedFaceTime",
  TOTAL_FACE_TIME = "totalFaceTime",
}

export default LeaderboardMetricFieldName;
