@use "./layout" as *;

// Need to export from other file because of this CRA 4 issue:
// https://github.com/facebook/create-react-app/issues/10047
:export {
  spacerXS: $spacer-xs-value;
  spacerS: $spacer-xs-value;
  spacerM: $spacer-m-value;
  spacerL: $spacer-l-value;
  spacerXL: $spacer-xl-value;
  breakpointWideDesktop: $breakpoint-wide-desktop;
  breakpointDesktop: $breakpoint-desktop;
  breakpointTablet: $breakpoint-tablet;
  breakpointTabletPortrait: $breakpoint-tablet-portrait;
  breakpointMobile: $breakpoint-mobile;
}
