import { useCallback, useRef, useState } from "react";

type SetMutator<T> = (set: Set<T>) => boolean;
type MutableSetHookResult<T> = [
  Set<T>,
  (mutator: SetMutator<T>) => void,
  (item: T, included: boolean) => void
];
/**
 * This is a hook used for having a mutable set. Set is stored as a ref (using useRef) to prevent
 * recreating the set (as if it was stored in useState). However, changing any ref doesn't trigger
 * rendering. That's why we have to use a state via useState which is changed every time the set is
 * mutated.
 *
 * @param setCreator a method used to create an initial set
 */
const useMutableSet = <T>(setCreator?: () => Set<T>): MutableSetHookResult<T> => {
  const set = useRef<Set<T>>(setCreator ? setCreator() : new Set<T>());
  const [, changeSetVersion] = useState(0);
  const mutateSet = useCallback((mutator: SetMutator<T>) => {
    if (mutator(set.current)) {
      changeSetVersion((currentVersion) => currentVersion + 1);
    }
  }, []);
  const toggleItem = useCallback(
    (item: T, included: boolean) =>
      mutateSet((set: Set<T>) => {
        const setChanged = included ? !set.has(item) : set.has(item);
        if (setChanged && included) {
          set.add(item);
        } else if (setChanged) {
          set.delete(item);
        }
        return setChanged;
      }),
    [mutateSet]
  );
  return [set.current, mutateSet, toggleItem];
};

export default useMutableSet;
