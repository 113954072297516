import { IntlShape } from "react-intl";

import { EntityType } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";

const getFieldDynamicVariableName = (
  intl: IntlShape,
  entityType: EntityTypesSupportedByEmailFeature,
  field: IField
): string => {
  if (entityType === EntityType.COMPANY && field.name === CompanyFieldName.NAME) {
    return intl.formatMessage({
      id: "utils.email.getFieldDynamicVariableName.company.name",
      defaultMessage: "Company Name",
      description: "Dynamic variants name  - company name",
    });
  }
  return field.displayName;
};

export default getFieldDynamicVariableName;
