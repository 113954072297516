import LongLat from "../../types/base/LongLat";

const isValidLongLat = (value?: LongLat) => {
  return (
    Array.isArray(value) &&
    value.length === 2 &&
    !isNaN(value[0]) &&
    !isNaN(value[1]) &&
    Math.abs(value[0]) <= 180 &&
    Math.abs(value[1]) <= 90
  );
};
export default isValidLongLat;
