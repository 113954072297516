import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

import Field, { FieldProperties } from "./Field";

class AddressField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.RECORD_PREVIEW_VISIBLE_BY_DEFAULT,
        FieldFeature.MAP_PINNED_FIELD,
        FieldFeature.ADDRESS,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
      ],
      formProperties: {
        fullWidth: true,
      },
      importProperties: {
        // This field needs to be excluded from list of required fields on import,
        // even if it is defined as systemRequired in schema
        required: false,
      },
      type: FieldType.STRING,
      ...data,
    });
  }

  get isReadable() {
    // system-required fields are always readable, except Address which can be masked
    return this._schemaDetails?.accessStatus.read !== false;
  }

  get doesSupportPhi() {
    return true;
  }
}

export default AddressField;
