import React from "react";

import AccessStatus from "@mapmycustomers/shared/types/entity/common/AccessStatus";
import { RawFile } from "@mapmycustomers/shared/types/File";

import FileRow from "@app/component/FileRow";

import styles from "./NonViewableFileItems.module.scss";

interface Props {
  accessStatus?: AccessStatus;
  canDelete?: boolean;
  deleteTooltip?: string;
  nonViewableFiles: RawFile[];
  onDelete: (file: RawFile) => void;
  onDownload: (file: RawFile) => void;
  size: "default" | "small";
}

const NonViewableFileItems: React.FC<Props> = ({
  accessStatus,
  canDelete = true,
  deleteTooltip,
  nonViewableFiles,
  onDelete,
  onDownload,
  size,
}) => {
  return (
    <div className={styles.container}>
      {nonViewableFiles.map((file) => (
        <FileRow
          accessStatus={accessStatus}
          canDelete={canDelete}
          deleteTooltip={deleteTooltip}
          file={file}
          key={file.id}
          onClick={onDownload}
          onDelete={onDelete}
          onDownload={onDownload}
          size={size}
        />
      ))}
    </div>
  );
};

export default NonViewableFileItems;
