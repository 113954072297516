import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";
import MapFilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

const convertMapFilterToPlatformFilterModel = (
  filter: MapFilterModel,
  visibleEntityTypes: EntityTypesSupportedByMapsPage[],
  viewAs: undefined | User["id"]
): PlatformFilterModel => {
  const entitiesFilters = visibleEntityTypes.reduce((result, entityType) => {
    const fieldModel = getFieldModelByEntityType(entityType);
    return {
      ...result,
      [entityType]: {
        ...convertToPlatformFilterModel(
          filter[entityType] ?? {},
          fieldModel.fields.map((field) => ({ field, visible: true })),
          fieldModel,
          true
        ),
        includeGroups: true,
      },
    };
  }, {});

  const universalFilters =
    !filter.universal && !viewAs
      ? {}
      : convertToPlatformFilterModel(
          filter.universal || {},
          getFieldModelByEntityType("universal").fields.map((field) => ({ field, visible: true })),
          getFieldModelByEntityType("universal"),
          true,
          viewAs
        );

  return {
    ...universalFilters,
    entities: entitiesFilters,
  };
};

export default convertMapFilterToPlatformFilterModel;
