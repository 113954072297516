import React from "react";
import { connect } from "react-redux";

import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import Path from "@app/enum/Path";
import UserRequestStatus from "@app/enum/sso/UserRequestStatus";
import { getUserDetails } from "@app/store/auth";
import { RootState } from "@app/store/rootReducer";
import UserDetails from "@app/types/auth/UserDetails";

import commonStyles from "../AuthCommon.module.scss";

import messages from "./messages";
import styles from "./RequestStatusPage.module.scss";

interface Props {
  onManageUsersClick: () => void;
  status: UserRequestStatus;
  userDetails?: UserDetails;
}

const RequestStatusPage: React.FC<Props> = ({ onManageUsersClick, status, userDetails }) => {
  const intl = useIntl();

  if (!userDetails) {
    return null;
  }

  const {
    org: { availableLicences, totalLicences },
  } = userDetails;

  return (
    <section className={commonStyles.container}>
      <div className={styles.container}>
        {status === UserRequestStatus.APPROVED ? (
          <FontAwesomeIcon className={styles.approved} icon={faCheckCircle} size="3x" />
        ) : (
          <FontAwesomeIcon className={styles.denied} icon={faCircleXmark} size="3x" />
        )}
        <h1 className={commonStyles.title}>
          {intl.formatMessage(
            status === UserRequestStatus.APPROVED
              ? messages.approvalSuccessTitle
              : messages.requestDeniedTitle
          )}
        </h1>
        <span className={styles.description}>
          {intl.formatMessage(
            status === UserRequestStatus.APPROVED
              ? messages.approvalSuccessMessage
              : messages.requestDeniedMessage
          )}
        </span>
        <span className={styles.description}>
          {intl.formatMessage(messages.seatsRemaining, {
            approved: status === UserRequestStatus.APPROVED,
            count: availableLicences,
            total: totalLicences,
          })}
        </span>
        <div className={styles.buttonContainer}>
          <Link to={Path.DASHBOARD}>
            <Button type="default">{intl.formatMessage(messages.home)}</Button>
          </Link>
          <Button onClick={onManageUsersClick} type="primary">
            {intl.formatMessage(messages.manageUsers)}
          </Button>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: getUserDetails(state),
});

export default connect(mapStateToProps)(RequestStatusPage);
