import { defineMessages } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";

import FieldModel from "./impl/FieldModel";
import { createField } from "./impl/fieldModelUtil";

const displayNames = defineMessages<"name" | LeaderboardMetricFieldName>({
  [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED]: {
    id: "leaderboard.field.activitiesCompleted",
    defaultMessage: "Activities Done",
    description: "Activities done metric on leaderboard",
  },
  [LeaderboardMetricFieldName.CHECK_INS_COMPLETED]: {
    id: "leaderboard.field.checkInsCompleted",
    defaultMessage: "Check-ins",
    description: "Check-ins metric on leaderboard",
  },
  [LeaderboardMetricFieldName.COMPANIES_CREATED]: {
    id: "leaderboard.field.accountsCreated",
    defaultMessage: "Companies Added",
    description: "accounts created metric on leaderboard",
  },
  [LeaderboardMetricFieldName.DEALS_CLOSED_LOST]: {
    id: "leaderboard.field.dealsLost",
    defaultMessage: "Deals Lost",
    description: "Deals lost metric on leaderboard",
  },
  [LeaderboardMetricFieldName.DEALS_CLOSED_WON]: {
    id: "leaderboard.field.dealsWon",
    defaultMessage: "Deals Won",
    description: "Deals won metric on leaderboard",
  },
  [LeaderboardMetricFieldName.DEALS_CREATED]: {
    id: "leaderboard.field.dealsCreated",
    defaultMessage: "Deals Added",
    description: "Deals created metric on leaderboard",
  },
  [LeaderboardMetricFieldName.DEALS_ROTTING]: {
    id: "leaderboard.field.dealsRotting",
    defaultMessage: "Deals Rotting",
    description: "Deals rotting metric on leaderboard",
  },
  [LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE]: {
    id: "leaderboard.field.newPipelineRevenue",
    defaultMessage: "New Pipeline",
    description: "New pipeline created metric on leaderboard",
  },
  [LeaderboardMetricFieldName.PEOPLE_CREATED]: {
    id: "leaderboard.field.contactsCreated",
    defaultMessage: "People Added",
    description: "contacts created metric on leaderboard",
  },
  [LeaderboardMetricFieldName.REVENUE_CLOSED_LOST]: {
    id: "leaderboard.field.revenueLost",
    defaultMessage: "Revenue Lost",
    description: "Revenue lost metric on leaderboard",
  },
  [LeaderboardMetricFieldName.REVENUE_CLOSED_WON]: {
    id: "leaderboard.field.revenueWon",
    defaultMessage: "Revenue Won",
    description: "Revenue won metric on leaderboard",
  },
  [LeaderboardMetricFieldName.ROUTES_CREATED]: {
    id: "leaderboard.field.routesCreated",
    defaultMessage: "Routes Added",
    description: "routes created metric on leaderboard",
  },
  [LeaderboardMetricFieldName.TOTAL_FACE_TIME]: {
    id: "leaderboard.field.totalFaceTime",
    defaultMessage: "Total Face Time",
    description: "Total faceTime metric on leaderboard",
  },
  [LeaderboardMetricFieldName.VERIFIED_CHECK_INS]: {
    id: "leaderboard.field.verifiedCheckIns",
    defaultMessage: "Verified Check-ins",
    description: "verified checkins metric on leaderboard",
  },
  [LeaderboardMetricFieldName.VERIFIED_FACE_TIME]: {
    id: "leaderboard.field.verifiedFaceTime",
    defaultMessage: "Verified Face Time",
    description: "verified faceTime metric on leaderboard",
  },
  name: {
    id: "leaderboard.field.name",
    defaultMessage: "Name",
    description: "Name field on leaderboard",
  },
});

const leaderboardFieldModel = new FieldModel(
  [
    {
      displayName: displayNames.name,
      features: [FieldFeature.ALWAYS_VISIBLE, FieldFeature.VISIBLE_BY_DEFAULT],
      name: "name",
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.ACTIVITIES_COMPLETED],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.ACTIVITIES_COMPLETED,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.DEALS_CREATED],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.DEALS_CREATED,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.CHECK_INS_COMPLETED],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.CHECK_INS_COMPLETED,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.DEALS_CLOSED_LOST],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.DEALS_CLOSED_LOST,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.DEALS_CLOSED_WON],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.DEALS_CLOSED_WON,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.DEALS_ROTTING],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.DEALS_ROTTING,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.REVENUE_CLOSED_WON],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.REVENUE_CLOSED_WON,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.REVENUE_CLOSED_LOST],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.REVENUE_CLOSED_LOST,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.COMPANIES_CREATED],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.COMPANIES_CREATED,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.PEOPLE_CREATED],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.PEOPLE_CREATED,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.ROUTES_CREATED],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.ROUTES_CREATED,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.VERIFIED_CHECK_INS],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.VERIFIED_CHECK_INS,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.VERIFIED_FACE_TIME],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.VERIFIED_FACE_TIME,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[LeaderboardMetricFieldName.TOTAL_FACE_TIME],
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT],
      name: LeaderboardMetricFieldName.TOTAL_FACE_TIME,
      type: FieldType.NUMBER,
    },
  ].map(createField)
);

export default leaderboardFieldModel;
