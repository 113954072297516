import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { EntityTypesSupportedByImport } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";

import getByEntityType from "@app/util/fieldModel/getByEntityType";
import { isCalculatedField, isCustomField } from "@app/util/fieldModel/impl/assert";

import ImportMappingOption from "../type/ImportMappingOption";

import { MAPPING_SKIP_KEY } from "./consts";
import isFieldRequiredInImports from "./isFieldRequired";

const labelComparator = (a: ImportMappingOption, b: ImportMappingOption) =>
  (a.label ?? "").localeCompare(b.label ?? "");

const skipOption: ImportMappingOption = { skip: true, value: MAPPING_SKIP_KEY };
const separateOption: ImportMappingOption = { label: "---", separator: true };
const uniqueIdOption: ImportMappingOption = { unique: true, value: "importId" };

const convertFieldToOption = (fields: IField[]): ImportMappingOption[] =>
  fields
    .map((field) => ({
      label: field.importDisplayName,
      required: isFieldRequiredInImports(field),
      value: field.importName,
    }))
    .sort(labelComparator);

const getImportMappingOptions = (
  entityType: EntityTypesSupportedByImport
): ImportMappingOption[] => {
  const fieldModel: IFieldModel = getByEntityType(entityType);

  let importMapOptions: ImportMappingOption[] = [skipOption, uniqueIdOption, separateOption];

  const allDefaultFields = fieldModel.fields.filter(
    (field) =>
      !isCustomField(field) &&
      !field.hasFeature(FieldFeature.NON_IMPORT) &&
      field.name !== uniqueIdOption.value
  );

  const regularFieldOptions: ImportMappingOption[] = convertFieldToOption(
    allDefaultFields.filter((field) => !field.importProperties?.bottomPosition)
  );
  importMapOptions = [...importMapOptions, ...regularFieldOptions];

  const customFieldOptions: ImportMappingOption[] = convertFieldToOption(
    fieldModel.fields.filter((field) => isCustomField(field) && !isCalculatedField(field))
  );

  if (customFieldOptions.length) {
    importMapOptions = [...importMapOptions, separateOption, ...customFieldOptions];
  }

  const bottomFieldOptions = convertFieldToOption(
    allDefaultFields.filter((field) => field.importProperties?.bottomPosition)
  );
  if (bottomFieldOptions.length) {
    importMapOptions = [...importMapOptions, separateOption, ...bottomFieldOptions];
  }

  return importMapOptions;
};
export default getImportMappingOptions;
