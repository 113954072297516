import { put, select, takeEvery } from "redux-saga/effects";

import {
  toggleLegendExpandedState,
  toggleLegendVisibility,
} from "@app/scene/map/store/legends/actions";
import { LegendsState } from "@app/scene/map/store/legends/reducers";
import { getLegendsState } from "@app/scene/map/store/legends/selectors";
import { updateMetadata } from "@app/store/iam/actions";

function* onPersistLegendsState() {
  const state: LegendsState = yield select(getLegendsState);
  yield put(updateMetadata.request({ legendsSettings: state }));
}

export function* legendsSagas() {
  yield takeEvery(toggleLegendVisibility, onPersistLegendsState);
  yield takeEvery(toggleLegendExpandedState, onPersistLegendsState);
}
