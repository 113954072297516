import React from "react";

import { bem } from "@react-md/utils";
import Row from "antd/es/row";

const block = bem("mmc-error-row");

interface Props {
  children: React.ReactNode;
}

const ErrorRow: React.FC<Props> = ({ children }) => {
  return <Row className={block()}>{children}</Row>;
};

export default ErrorRow;
