import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import Form, { Rule } from "antd/es/form";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import Popconfirm from "antd/es/popconfirm";
import { useIntl } from "react-intl";

import { GeocodeResult, GeoManagementState } from "@mapmycustomers/shared/types/base/Located";
import GeoAddress from "@mapmycustomers/shared/types/GeoAddress";
import { Alert, FormItem, TextField } from "@mapmycustomers/ui";

import ButtonLink from "@app/component/ButtonLink";
import AddressField from "@app/component/input/Address/AddressField";
import { reverseGeocodeAddress } from "@app/store/location/actions";
import geoAddressToAddress from "@app/util/geoAddressToAddress";
import floorToFixed from "@app/util/number/floorToFixed";
import { useCoordinatesValidation } from "@app/util/validation/coordinatesValidation";

import styles from "./AddressFormItem.module.scss";
import messages from "./messages";

interface Props {
  disabled?: boolean;
  locked?: boolean;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  placeholder?: string;
  required?: boolean;
  rules?: Rule[];
}

const AddressFormItem: React.FC<Props> = ({
  disabled,
  locked,
  onReverseGeocodeAddress,
  placeholder,
  required,
  rules,
}) => {
  const intl = useIntl();
  const form = useFormInstance();
  const addressChangedManually = form.isFieldTouched("addressDetails");
  const [open, setOpen] = useState(false);

  const longitude: string = useWatch("longitude", form) ?? form.getFieldValue("longitude"); // Form Item is inside Popover, useWatch gives undefined till popover is opened.
  const latitude: string = useWatch("latitude", form) ?? form.getFieldValue("latitude");
  const addressDetails: GeoAddress | undefined =
    useWatch("addressDetails", form) ?? form.getFieldValue("addressDetails");

  const [coordinates, setCoordinates] = useState<{
    latitude: string | undefined;
    longitude: string | undefined;
  }>({
    latitude,
    longitude,
  });
  const coordinatesValidation = useCoordinatesValidation(intl, form);
  const formattedLatitude = floorToFixed(parseFloat(latitude), 6);
  const formattedLongitude = floorToFixed(parseFloat(longitude), 6);

  const disableOkButton = useMemo(() => {
    if (!isNaN(formattedLatitude) && !isNaN(formattedLongitude)) {
      const latitude = Math.abs(formattedLatitude);
      const longitude = Math.abs(formattedLongitude);
      return !(latitude <= 90 && latitude >= -90 && longitude >= -180 && longitude <= 180);
    }
    return true;
  }, [formattedLatitude, formattedLongitude]);

  const handleConfirm = useCallback(() => {
    setCoordinates({ latitude, longitude });
    if (!isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude))) {
      onReverseGeocodeAddress({
        callback: ({ address }: GeocodeResult) => {
          const updatedAddress = geoAddressToAddress(address);
          form.setFields([
            {
              name: "addressDetails",
              touched: false,
              value: {
                ...updatedAddress,
                geoAddress: address,
                geoManagementState: GeoManagementState.MANUAL,
              },
            },
          ]);
        },
        coordinates: [parseFloat(longitude), parseFloat(latitude)],
      });
    }
  }, [form, latitude, longitude, onReverseGeocodeAddress]);

  const handleCancel = useCallback(() => {
    form.setFieldValue("latitude", coordinates.latitude);
    form.setFieldValue("longitude", coordinates.longitude);
  }, [form, coordinates]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open && disableOkButton) {
        handleCancel();
      }
      setOpen(open);
    },
    [disableOkButton, handleCancel]
  );

  useEffect(() => {
    if (addressChangedManually) {
      form.setFieldValue("latitude", null);
      form.setFieldValue("longitude", null);
      form.setFieldValue("addressDetails", {
        ...addressDetails,
        geoAdress: null,
        geoManagementState: GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
      });
      setCoordinates({ latitude: undefined, longitude: undefined });
    }
  }, [addressChangedManually, addressDetails, form]);

  const shouldUpdateItem = useCallback(
    (prevValues, curValues) =>
      prevValues.latitude !== curValues.latitude || prevValues.longitude !== curValues.longitude,
    []
  );

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdateItem}>
      <div className={styles.container}>
        <div className={styles.left}>
          <FormItem
            label={intl.formatMessage(messages.address)}
            name="addressDetails"
            required={required}
            rules={rules}
          >
            <AddressField
              className={styles.address}
              disabled={disabled || locked}
              label={intl.formatMessage(messages.address)}
              placeholder={placeholder}
              required={required}
            />
          </FormItem>
          <Form.Item hidden name="latitude" />
          <Form.Item hidden name="longitude" />
        </div>

        <div className={styles.right}>
          <Popconfirm
            cancelText={intl.formatMessage(messages.cancel)}
            icon={undefined}
            okButtonProps={{ disabled: disableOkButton }}
            okText={intl.formatMessage(messages.ok)}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onOpenChange={handleOpenChange}
            open={open}
            overlayClassName={styles.popconfirm}
            placement="bottomRight"
            title={
              <div className={styles.coordinates}>
                <Alert message={intl.formatMessage(messages.alert)} showIcon type="info" />
                <Form.Item name="latitude" required={required} rules={coordinatesValidation}>
                  <TextField disabled={disabled} label={intl.formatMessage(messages.latitude)} />
                </Form.Item>
                <Form.Item name="longitude" required={required} rules={coordinatesValidation}>
                  <TextField disabled={disabled} label={intl.formatMessage(messages.longitude)} />
                </Form.Item>
              </div>
            }
          >
            <ButtonLink className={styles.modeBtn} disabled={disabled || locked}>
              {!latitude && !longitude
                ? intl.formatMessage(messages.useCoordinates)
                : `${!isNaN(formattedLatitude) ? formattedLatitude : 0}, ${
                    !isNaN(formattedLongitude) ? formattedLongitude : 0
                  }`}
            </ButtonLink>
          </Popconfirm>
        </div>
      </div>
    </Form.Item>
  );
};

const mapDispatchToProps = {
  onReverseGeocodeAddress: reverseGeocodeAddress,
};

export default connect(null, mapDispatchToProps)(AddressFormItem);
