import Address from "@mapmycustomers/shared/types/Address";
import GeoAddress from "@mapmycustomers/shared/types/GeoAddress";

const geoAddressToAddress = ({
  address,
  city,
  countryCode,
  fullPostalCode,
  postalCode,
  region,
  regionCode,
}: GeoAddress): Address => ({
  address: address ?? undefined,
  city: city ?? undefined,
  countryCode: countryCode ?? undefined,
  postalCode: fullPostalCode ?? postalCode ?? undefined,
  region: region ?? undefined,
  regionCode: regionCode ?? undefined,
});

export default geoAddressToAddress;
