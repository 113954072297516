export const HISTORY_ADDRESS_FIELDS = [
  "address",
  "city",
  "country",
  "countryCode",
  "geoPoint",
  "postalCode",
  "region",
];

export const HISTORY_ROWS_PER_PAGE = 20;
