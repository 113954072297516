import { createReducer } from "typesafe-actions";

import TextSearchResult from "@app/types/TextSearchResult";

import { Actions, doGlobalSearch } from "./actions";

export interface NavigationBarState {
  searchLoading: boolean;
  searchResults: TextSearchResult[];
}

const initialState: NavigationBarState = {
  searchLoading: false,
  searchResults: [],
};

const navigationBar = createReducer<NavigationBarState, Actions>(initialState)
  .handleAction(doGlobalSearch.request, (state) => ({
    ...state,
    searchLoading: true,
  }))
  .handleAction(doGlobalSearch.success, (state, action) => ({
    ...state,
    searchLoading: false,
    searchResults: action.payload,
  }))
  .handleAction(doGlobalSearch.failure, (state) => ({
    ...state,
    searchLoading: false,
  }));

export * from "./selectors";
export type NavigationBarActions = Actions;
export default navigationBar;
