import { createReducer } from "typesafe-actions";

import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import { NEW_ROUTES_ENTITY_TYPES } from "@app/scene/dashboard/components/cards/NewRoutes/consts";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import EntityTypesSupportedInNewRoutes from "@app/types/dashboard/cards/newRoutes/EntityTypesSupportedInNewRoutes";
import NewRoutesCardConfiguration from "@app/types/dashboard/cards/newRoutes/NewRoutesCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";

import {
  applyNewRoutesDrillDownListViewSettings,
  fetchNewRoutesCardDrillDownData,
  hideNewRoutesDrillDown,
  NewRoutesCardActions,
  showNewRoutesDrillDown,
} from "./actions";

export interface NewRoutesCardState {
  configuration?: NewRoutesCardConfiguration; // also works as a "visible" trigger, visible if set
  drillDownCustomDateRange?: DateRange;
  loading?: boolean;
  totalFilteredRoutes: Record<EntityTypesSupportedInNewRoutes, number>;
  totalRoutes: Record<EntityTypesSupportedInNewRoutes, number>;
  viewStates: Record<EntityTypesSupportedInNewRoutes, DrillDownViewState>;
}

export const newRoutesInitialState: NewRoutesCardState = {
  totalFilteredRoutes: NEW_ROUTES_ENTITY_TYPES.reduce(
    (result, entityType) => ({ ...result, [entityType]: 0 }),
    {} as Record<EntityTypesSupportedInNewRoutes, number>
  ),
  totalRoutes: NEW_ROUTES_ENTITY_TYPES.reduce(
    (result, entityType) => ({ ...result, [entityType]: 0 }),
    {} as Record<EntityTypesSupportedInNewRoutes, number>
  ),
  viewStates: NEW_ROUTES_ENTITY_TYPES.reduce((result, entityType) => {
    result[entityType] = {
      columns: [],
      filter: {},
      range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
      sort: [],
      viewAs: undefined,
    };
    return result;
  }, {} as Record<EntityTypesSupportedInNewRoutes, DrillDownViewState>),
};

const newRoutesState = createReducer<NewRoutesCardState, NewRoutesCardActions>(
  newRoutesInitialState
)
  .handleAction(showNewRoutesDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
    drillDownCustomDateRange: payload.customDateRange,
  }))
  .handleAction(showNewRoutesDrillDown.success, (state, { payload }) => ({
    ...state,
    viewStates: payload.viewStates,
  }))
  .handleAction(hideNewRoutesDrillDown, (state) => ({
    ...state,
    configuration: undefined,
    drillDownCustomDateRange: undefined,
  }))
  .handleAction(fetchNewRoutesCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchNewRoutesCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRoutes: {
      ...state.totalFilteredRoutes,
      [payload.entityType]: payload.totalFilteredRoutes,
    },
    totalRoutes: {
      ...state.totalRoutes,
      [payload.entityType]: payload.totalRoutes,
    },
  }))
  .handleAction(fetchNewRoutesCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyNewRoutesDrillDownListViewSettings, (state, { payload }) => {
    const viewState = state.viewStates[payload.entityType];
    return {
      ...state,
      viewStates: {
        ...state.viewStates,
        [payload.entityType]: {
          ...viewState,
          columns: payload.columns ?? viewState.columns,
          filter: payload.filter ?? viewState.filter,
          range: payload.range ?? viewState.range,
          sort: payload.sort ?? viewState.sort,
          // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
          viewAs: "viewAs" in payload ? payload.viewAs : viewState.viewAs,
        },
      },
    };
  });

export default newRoutesState;
