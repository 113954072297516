import React from "react";

import cn from "classnames";

import logo from "@app/assets/auth/logo.png";

import styles from "./AuthLayout.module.scss";

interface Props {
  className?: string;
}

const AuthLayout: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <aside aria-hidden={true} className={styles.leftPanel}>
        <img alt="MapMyCustomers logo" className={styles.leftLogo} src={logo} />
        <div className={styles.leftImage}>&nbsp;</div>
      </aside>
      <main className={styles.main}>{children}</main>
      <footer aria-hidden={true} className={styles.bottomPanel} />
    </div>
  );
};

export default AuthLayout;
