import React, { useMemo } from "react";

import { IntlShape } from "react-intl";

import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

import CountryWithFlag from "../../component/country/CountryWithFlag";

import formatCountryName from "./formatCountryName";

const useCountryListOptions = (intl: IntlShape) =>
  useMemo(
    () =>
      Object.values(CountryCode).map((countryCode) => ({
        label: <CountryWithFlag countryCode={countryCode} />,
        text: formatCountryName(intl, countryCode),
        value: countryCode,
      })),
    [intl]
  );

export default useCountryListOptions;
