import { createReducer } from "typesafe-actions";

import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import TerritoriesState from "@app/store/territories/TerritoriesState";

import {
  Actions,
  createTerritory,
  deleteTerritory,
  fetchTerritories,
  updateTerritory,
  updateTerritorySharing,
} from "./actions";

const initialState: TerritoriesState = {
  creating: false,
  deleting: false,
  loading: false,
  territories: [],
  total: 0,
};

const territories = createReducer<TerritoriesState, Actions>(initialState)
  .handleAction(fetchTerritories.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchTerritories.success, (state, { payload: { territories, total } }) => ({
    ...state,
    loading: false,
    territories,
    total,
  }))
  .handleAction(fetchTerritories.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(createTerritory.request, (state) => ({
    ...state,
    creating: true,
  }))
  .handleAction(createTerritory.success, (state, { payload: { territory } }) => ({
    ...state,
    creating: false,
    territories: [...state.territories, territory].sort(nameComparator),
  }))
  .handleAction(createTerritory.failure, (state) => ({
    ...state,
    creating: false,
  }))
  .handleAction(
    [updateTerritory.success, updateTerritorySharing.success],
    (state, { payload: { territory } }) => ({
      ...state,
      territories: state.territories.map((item) => (item.id === territory.id ? territory : item)),
    })
  )
  .handleAction(deleteTerritory.request, (state) => ({ ...state, deleting: true }))
  .handleAction(deleteTerritory.success, (state, { payload: { territoryId } }) => ({
    ...state,
    deleting: false,
    territories: state.territories.filter(({ id }) => id !== territoryId),
  }))
  .handleAction(deleteTerritory.failure, (state) => ({ ...state, deleting: false }));

export * from "./selectors";
export type TerritoriesActions = Actions;
export default territories;
