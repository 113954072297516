import { defineMessages, IntlShape } from "react-intl";

import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";

const messages = defineMessages<MarkerSize>({
  [MarkerSize.LARGE]: {
    id: "markerSize.large",
    defaultMessage: "Large",
    description: "Title of Large Marker size option",
  },
  [MarkerSize.SMALL]: {
    id: "markerSize.small",
    defaultMessage: "Small",
    description: "Title of Small Marker size option",
  },
});

const getMarkerSizeDisplayName = (intl: IntlShape, markerSize: MarkerSize) =>
  intl.formatMessage(messages[markerSize]);

export default getMarkerSizeDisplayName;
