enum EntityAccessScope {
  ALL_ACCESS_SCOPED = "all-access-scoped", // user has all read permission
  OWNED_OR_SHARED_SCOPED = "owned-or-shared-scoped", // entity is shared with the user or owned by the user
  ACCOUNT_SHARED_ENTITY_SCOPED = "account-shared-entity-scoped", // entity is shared via account
  CONTACT_SHARED_ENTITY_SCOPED = "contact-shared-entity-scoped", // entity is shared via contact
  PUBLIC_SCOPED = "public-scoped", // activity is public
  TEAM_ACTIVITY_SCOPED = "team-activity-scoped", // activity is shared with the entire team
  USER_SCOPED = "user-scoped", // activity is owned by the user
  ASSIGNEE_SCOPED = "assignee-scoped", // activity is assigned to the user
  TEAM_USER_SCOPED = "team-user-scoped", // activity is owned by user in the same team
  TEAM_ASSIGNEE_SCOPED = "team-assignee-scoped", // activity is assigned to user in the same team
  DEAL_SHARED_ENTITY_SCOPED = "deal-shared-entity-scoped", // entity is shared via deal.
}

export default EntityAccessScope;
