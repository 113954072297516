import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";

const getLeaderboardFieldValue = (metric: LeaderboardMetricFieldName, data: LeaderboardItem) => {
  let value = 0;
  if (metric === LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE) {
    for (const key in data.values) {
      if (key.startsWith(LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE)) {
        //@ts-ignore
        value += data.values[key]; //This is done as earlier we were getting only a single key from platform i.e newPipelineRevenue but now with the enhanced filters, we are getting different keys for newPipelineRevenue with stage names
      }
    }
  } else if (metric === LeaderboardMetricFieldName.DEALS_CREATED) {
    for (const key in data.values) {
      if (key.startsWith(LeaderboardMetricFieldName.DEALS_CREATED)) {
        //@ts-ignore
        value += data.values[key]; //This is done as earlier we were getting only a single key from platform i.e newPipelineRevenue but now with the enhanced filters, we are getting different keys for newPipelineRevenue with stage names
      }
    }
  } else if (metric === LeaderboardMetricFieldName.DEALS_CLOSED_LOST) {
    for (const key in data.values) {
      if (key.startsWith(LeaderboardMetricFieldName.DEALS_CLOSED_LOST)) {
        //@ts-ignore
        value += data.values[key]; //This is done as earlier we were getting only a single key from platform i.e newPipelineRevenue but now with the enhanced filters, we are getting different keys for newPipelineRevenue with stage names
      }
    }
  } else if (metric === LeaderboardMetricFieldName.DEALS_CLOSED_WON) {
    for (const key in data.values) {
      if (key.startsWith(LeaderboardMetricFieldName.DEALS_CLOSED_WON)) {
        //@ts-ignore
        value += data.values[key]; //This is done as earlier we were getting only a single key from platform i.e newPipelineRevenue but now with the enhanced filters, we are getting different keys for newPipelineRevenue with stage names
      }
    }
  } else if (metric === LeaderboardMetricFieldName.DEALS_ROTTING) {
    for (const key in data.values) {
      if (key.startsWith(LeaderboardMetricFieldName.DEALS_ROTTING)) {
        //@ts-ignore
        value += data.values[key]; //This is done as earlier we were getting only a single key from platform i.e newPipelineRevenue but now with the enhanced filters, we are getting different keys for newPipelineRevenue with stage names
      }
    }
  } else {
    value = data.values[metric] ?? 0;
  }
  return value;
};

export default getLeaderboardFieldValue;
