import { EntityType, EntityTypeSupportingGroups } from "@mapmycustomers/shared/types/entity";
import Group from "@mapmycustomers/shared/types/entity/Group";
import { PickWithType } from "@mapmycustomers/shared/util/ts";

import GroupsState from "./GroupsState";

export const stateFieldBaseName: Readonly<
  Record<EntityTypeSupportingGroups, keyof PickWithType<GroupsState, Group[]>>
> = {
  [EntityType.COMPANY]: "companyGroups",
  [EntityType.DEAL]: "dealGroups",
  [EntityType.PERSON]: "peopleGroups",
};
