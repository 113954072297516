import DateRangeType from "@mapmycustomers/shared/enum/DateRangeType";

const regExp = new RegExp(`^(${Object.values(DateRangeType).join("|")})(-?\\d*)$`);

const parseRange = (
  range: string | undefined
): { dateRangeType: DateRangeType; offset: number } | undefined => {
  if (range === undefined) {
    return undefined;
  }
  const match = range.match(regExp);
  if (match) {
    return {
      dateRangeType: match[1] as DateRangeType,
      offset: match[2] ? parseInt(match[2], 10) || 0 : 0,
    };
  }
};

export default parseRange;
