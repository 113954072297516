import React, { useCallback, useMemo } from "react";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import { FormInstance } from "antd/es/form";
import Menu from "antd/es/menu";
import Modal from "antd/es/modal";
import Popconfirm from "antd/es/popconfirm";
import { useIntl } from "react-intl";

import styles from "../../CreateEditActivityModal.module.scss";
import { RecurringActionType } from "../../enum/RecurringActionType";
import FormValues from "../../types/FormValues";
import SaveOption from "../../types/SaveOption";

import messages from "./messages";

interface Props {
  buttonTitle: string;
  disabled?: boolean;
  doesActivityMeetCriteria?: boolean;
  form?: FormInstance<FormValues>;
  isActivityPublic: boolean;
  isAssigneeNotCreator?: boolean;
  isRecurChanged?: boolean;
  loading: boolean;
  onCreate?: () => void;
  saveOptions?: SaveOption[];
  showCheckInSubmit?: boolean;
}

const SubmitButton: React.FC<Props> = ({
  buttonTitle,
  disabled,
  doesActivityMeetCriteria = true,
  form,
  isActivityPublic,
  isAssigneeNotCreator,
  isRecurChanged,
  loading,
  onCreate,
  saveOptions,
  showCheckInSubmit,
}) => {
  const intl = useIntl();
  const [modal, contextHolder] = Modal.useModal();

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return;
    }

    if (isActivityPublic) {
      modal.confirm({
        cancelButtonProps: {
          type: "text",
        },
        cancelText: intl.formatMessage(messages.publicConfirmationCancel),
        className: styles.confirmationModal,
        content: intl.formatMessage(messages.publicConfirmationContent),
        okText: intl.formatMessage(messages.publicConfirmationOk),
        onOk: onCreate,
        title: intl.formatMessage(messages.publicConfirmationTitle),
      });
    } else {
      onCreate?.();
    }
  }, [disabled, intl, isActivityPublic, modal, onCreate]);

  const handleMenuItemClick = useCallback(
    (updateType?: RecurringActionType) => () => {
      form?.setFieldsValue({ updateType });
      if (isRecurChanged) {
        return;
      }
      handleSubmit();
    },
    [form, handleSubmit, isRecurChanged]
  );

  const withRecurConfirm = useCallback(
    (children: React.ReactNode) => (
      <Popconfirm
        cancelText={intl.formatMessage(messages.recurConfirmationCancel)}
        disabled={!isRecurChanged}
        okText={intl.formatMessage(messages.recurConfirmationOk)}
        onConfirm={handleSubmit}
        placement="rightTop"
        title={intl.formatMessage(messages.recurConfirmationTitle)}
      >
        {children}
      </Popconfirm>
    ),
    [handleSubmit, intl, isRecurChanged]
  );

  const withCheckInConfirm = useCallback(
    (children: React.ReactNode) => (
      <Popconfirm
        cancelText={intl.formatMessage(messages.checkInConfirmationCancel)}
        okText={intl.formatMessage(messages.checkInConfirmationOk)}
        onConfirm={handleSubmit}
        placement="rightTop"
        title={intl.formatMessage(messages.checkInConfirmationTitle, {
          br: <br />,
          strong: (text: string) => <strong>{text}</strong>,
        })}
      >
        {children}
      </Popconfirm>
    ),
    [handleSubmit, intl]
  );
  const withFrequencyCriteriaConfirm = useCallback(
    (children: React.ReactNode) => (
      <Popconfirm
        cancelText={intl.formatMessage(messages.frequencyCriteriaConfirmationCancel)}
        okText={intl.formatMessage(messages.frequencyCriteriaConfirmationOk)}
        onConfirm={handleSubmit}
        placement="rightTop"
        title={intl.formatMessage(messages.frequencyCriteriaConfirmationTitle)}
      >
        {children}
      </Popconfirm>
    ),
    [handleSubmit, intl]
  );

  const submitButton = useMemo(() => {
    if (showCheckInSubmit) {
      return withCheckInConfirm(
        <Button disabled={disabled || loading} loading={loading} type="primary">
          {buttonTitle}
        </Button>
      );
    }
    if (!doesActivityMeetCriteria) {
      return withFrequencyCriteriaConfirm(
        <Button disabled={disabled || loading} loading={loading} type="primary">
          {buttonTitle}
        </Button>
      );
    }
    if (saveOptions?.length) {
      return (
        <Dropdown
          overlay={
            <Menu>
              <Menu.ItemGroup
                title={
                  isAssigneeNotCreator
                    ? intl.formatMessage(messages.updateMenuAssigneeNotCreatorTitle)
                    : intl.formatMessage(messages.updateMenuTitle)
                }
              >
                {saveOptions.includes(SaveOption.JUST_THIS_ACTIVITY) && (
                  <Menu.Item key="just-this" onClick={handleMenuItemClick()}>
                    {isRecurChanged
                      ? withRecurConfirm(intl.formatMessage(messages.updateMenuOnlyThis))
                      : intl.formatMessage(messages.updateMenuOnlyThis)}
                  </Menu.Item>
                )}
                {saveOptions.includes(SaveOption.THIS_AND_FEATURE_ACTIVITIES) && (
                  <Menu.Item
                    key="this-and-future"
                    onClick={handleMenuItemClick(RecurringActionType.Future)}
                  >
                    {isRecurChanged
                      ? withRecurConfirm(intl.formatMessage(messages.updateMenuThisAndFuture))
                      : intl.formatMessage(messages.updateMenuThisAndFuture)}
                  </Menu.Item>
                )}

                {saveOptions.includes(SaveOption.ALL_ACTIVITIES) && (
                  <Menu.Item key="all" onClick={handleMenuItemClick(RecurringActionType.All)}>
                    {isRecurChanged
                      ? withRecurConfirm(intl.formatMessage(messages.updateMenuAll))
                      : intl.formatMessage(messages.updateMenuAll)}
                  </Menu.Item>
                )}
              </Menu.ItemGroup>
            </Menu>
          }
          overlayClassName={styles.actionDropdown}
          trigger={["click"]}
        >
          <Button disabled={disabled || loading} loading={loading} type="primary">
            {buttonTitle} <FontAwesomeIcon icon={faAngleDown} />
          </Button>
        </Dropdown>
      );
    }
    return isRecurChanged ? (
      withRecurConfirm(
        <Button disabled={disabled || loading} loading={loading} type="primary">
          {buttonTitle}
        </Button>
      )
    ) : (
      <Button
        disabled={disabled || loading}
        loading={loading}
        onClick={handleSubmit}
        type="primary"
      >
        {buttonTitle}
      </Button>
    );
  }, [
    buttonTitle,
    disabled,
    doesActivityMeetCriteria,
    handleMenuItemClick,
    handleSubmit,
    intl,
    isAssigneeNotCreator,
    isRecurChanged,
    loading,
    saveOptions,
    showCheckInSubmit,
    withCheckInConfirm,
    withFrequencyCriteriaConfirm,
    withRecurConfirm,
  ]);

  return (
    <>
      {submitButton}
      {contextHolder}
    </>
  );
};

export default SubmitButton;
