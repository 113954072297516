import { createReducer } from "typesafe-actions";

import { EntityType } from "@mapmycustomers/shared/enum";

import localSettings from "@app/config/LocalSettings";
import AreaSearchMode from "@app/enum/AreaSearchMode";
import {
  addRecordsToRoute,
  cancelLeadFinderSearch,
  clearLeadFinderLocation,
  clearLeadFinderRecords,
  exitLeadFinderMode,
  filterRoutes,
  finishAddRecords,
  geocodeLocation,
  hideDuplicates,
  hideLeads,
  hideSavedSearchesModal,
  initializeAddRecords,
  LeadFinderActions,
  setAddRecordsVisibility,
  setCenterPoint,
  setConfigurationVisibility,
  setCurrentBounds,
  setCustomSearchDistances,
  setLeadAnnotation,
  setLeadFinderQuery,
  setOtherPins,
  setPrimaryRecord,
  setSearchDistance,
  setSearchHistory,
  showDuplicates,
  showHiddenLeads,
  showSavedSearchesModal,
  startLeadFinderSearch,
  submitAddRecords,
  toggleDuplicateLeadsVisibility,
  toggleHiddenLeadsVisibility,
  updateAddRecordsProgress,
} from "@app/scene/map/store/leadFinder/actions";
import LeadFinderState from "@app/scene/map/store/leadFinder/LeadFinderState";
import {
  LEAD_FINDER_DEFAULT_DISTANCE_KM,
  LEAD_FINDER_DEFAULT_DISTANCE_MILES,
  LEAD_FINDER_MAX_SEARCHES,
} from "@app/scene/map/utils/consts";

export const leadFinderInitialState: LeadFinderState = {
  addRecords: {
    availableRoutes: [],
    entityType: EntityType.COMPANY,
    groups: [],
    initializing: false,
    loading: false,
    progress: {},
    resultsVisible: false,
    routes: [],
    visible: false,
  },
  configurationVisible: false,
  currentBounds: undefined,
  customDistances: [],
  duplicates: {
    primaryRecord: undefined,
    records: [],
    visible: false,
  },
  hiddenLeads: {
    records: [],
    visible: false,
  },
  loading: false,
  otherPins: true,
  potentialDuplicateLeads: {
    records: [],
    visible: true,
  },
  processing: false,
  query: {
    address: undefined,
    mode: AreaSearchMode.CURRENT_MAP_VIEW_CENTER,
    search: undefined,
  },
  recentSearches: [],
  results: {
    records: [],
    totalFilteredRecords: 0,
    totalRecords: 0,
  },
  savedSearches: [],
  savedSearchesModal: false,
  searchDistance: localSettings.doesUseSiUnits()
    ? LEAD_FINDER_DEFAULT_DISTANCE_KM
    : LEAD_FINDER_DEFAULT_DISTANCE_MILES,
};

const leadFinder = createReducer<LeadFinderState, LeadFinderActions>(leadFinderInitialState)
  .handleAction(startLeadFinderSearch.request, (state, { payload }) => ({
    ...state,
    currentBounds: undefined,
    processing: true,
    query: {
      ...state.query,
      search: payload.search,
    },
    recentSearches: [
      // Do not add recent search if the saved search with same criteria is present
      ...(state.savedSearches?.includes(payload.search) ? [] : [payload.search]),
      ...state.recentSearches
        .filter((search) => search !== payload.search)
        .slice(0, LEAD_FINDER_MAX_SEARCHES - 1),
    ],
  }))
  .handleAction(startLeadFinderSearch.success, (state, { payload }) => ({
    ...state,
    processing: false,
    results: {
      ...state.results,
      ...payload,
    },
  }))
  .handleAction(startLeadFinderSearch.failure, (state) => ({
    ...state,
    processing: false,
  }))
  .handleAction(geocodeLocation.success, (state, { payload }) => ({
    ...state,
    query: {
      ...state.query,
      ...payload,
    },
  }))
  .handleAction(setAddRecordsVisibility, (state, { payload }) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      visible: payload.visible,
    },
  }))
  .handleAction(initializeAddRecords.request, (state, { payload }) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      entityType: payload.entityType,
      groups: [],
      initializing: true,
      loading: false,
      routes: [],
    },
  }))
  .handleAction(initializeAddRecords.success, (state, { payload }) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      availableRoutes: payload.routes,
      initializing: false,
    },
  }))
  .handleAction(submitAddRecords.request, (state) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      loading: true,
      resultsVisible: true,
    },
  }))
  .handleAction(submitAddRecords.success, (state) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      loading: false,
    },
  }))
  .handleAction(addRecordsToRoute.success, (state, { payload: { isNewRoute, routeId } }) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      progress: {
        ...state.addRecords.progress,
        newRouteIds: [
          ...(state.addRecords.progress.newRouteIds ?? []),
          ...(isNewRoute ? [routeId] : []),
        ],
        routeIds: [...(state.addRecords.progress.routeIds ?? []), routeId],
      },
    },
  }))
  .handleAction(addRecordsToRoute.failure, (state, { payload }) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      progress: {
        ...state.addRecords.progress,
        routeErrors: {
          ...state.addRecords.progress.routeErrors,
          [payload.routeId]: payload.errors,
        },
      },
    },
  }))
  .handleAction(updateAddRecordsProgress, (state, { payload }) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      progress: {
        ...state.addRecords.progress,
        ...payload,
      },
    },
  }))
  .handleAction(finishAddRecords, (state) => ({
    ...state,
    addRecords: {
      ...leadFinderInitialState.addRecords,
    },
  }))
  .handleAction(setConfigurationVisibility, (state, { payload }) => ({
    ...state,
    configurationVisible: payload.visible,
  }))
  .handleAction(setOtherPins, (state, { payload }) => ({
    ...state,
    otherPins: payload.otherPins,
  }))
  .handleAction(cancelLeadFinderSearch, (state) => ({
    ...state,
    processing: false,
  }))
  .handleAction(setSearchHistory, (state, { payload }) => ({
    ...state,
    recentSearches: payload.recentSearches ?? state.recentSearches,
    savedSearches: payload.savedSearches ?? state.savedSearches,
  }))
  .handleAction(showSavedSearchesModal, (state) => ({
    ...state,
    savedSearchesModal: true,
  }))
  .handleAction(hideSavedSearchesModal, (state) => ({
    ...state,
    savedSearchesModal: false,
  }))
  .handleAction(setCenterPoint, (state, { payload }) => ({
    ...state,
    centerPoint: payload,
  }))
  .handleAction(setCurrentBounds, (state, { payload }) => ({
    ...state,
    currentBounds: payload.bounds,
  }))
  .handleAction(setPrimaryRecord, (state, { payload }) => ({
    ...state,
    duplicates: {
      ...state.duplicates,
      ...payload,
    },
  }))
  .handleAction(showDuplicates, (state, { payload }) => ({
    ...state,
    duplicates: {
      ...state.duplicates,
      ...payload,
      visible: true,
    },
  }))
  .handleAction(hideDuplicates, (state) => ({
    ...state,
    duplicates: {
      ...state.duplicates,
      primaryRecord: undefined,
      records: [],
      visible: false,
    },
  }))
  .handleAction(exitLeadFinderMode, (state) => ({
    ...state,
    configurationVisible: false,
    currentBounds: undefined,
  }))
  .handleAction(setCustomSearchDistances, (state, { payload }) => ({
    ...state,
    customDistances: payload.customDistances,
  }))
  .handleAction(setSearchDistance, (state, { payload }) => ({
    ...state,
    searchDistance: payload.distance,
  }))
  .handleAction(setLeadFinderQuery, (state, { payload }) => ({
    ...state,
    query: {
      ...state.query,
      ...payload,
    },
  }))
  .handleAction(clearLeadFinderRecords, (state) => ({
    ...state,
    results: leadFinderInitialState.results,
  }))
  .handleAction(clearLeadFinderLocation, (state) => ({
    ...state,
    query: {
      address: undefined,
      entity: undefined,
      location: undefined,
      mode: AreaSearchMode.CURRENT_MAP_VIEW_CENTER,
      point: undefined,
      search: undefined,
    },
  }))
  .handleAction(toggleDuplicateLeadsVisibility, (state) => ({
    ...state,
    potentialDuplicateLeads: {
      ...state.potentialDuplicateLeads,
      visible: !state.potentialDuplicateLeads.visible,
    },
  }))
  .handleAction(toggleHiddenLeadsVisibility, (state) => ({
    ...state,
    hiddenLeads: {
      ...state.hiddenLeads,
      visible: !state.hiddenLeads.visible,
    },
  }))
  .handleAction(hideLeads.success, (state, { payload }) => ({
    ...state,
    results: {
      ...state.results,
      records: state.results.records.map((record) => {
        if (payload.placeIds.includes(record.place_id)) {
          return { ...record, blackListed: true };
        }
        return record;
      }),
    },
  }))
  .handleAction(showHiddenLeads.success, (state, { payload }) => ({
    ...state,
    results: {
      ...state.results,
      records: state.results.records.map((record) => {
        if (payload.placeIds.includes(record.place_id)) {
          return { ...record, blackListed: false };
        }
        return record;
      }),
    },
  }))
  .handleAction(setLeadAnnotation, (state, { payload }) => ({ ...state, annotation: payload }))
  .handleAction(filterRoutes.success, (state, { payload }) => ({
    ...state,
    addRecords: {
      ...state.addRecords,
      availableRoutes: payload,
    },
  }));

export default leadFinder;
