import React from "react";
import { connect } from "react-redux";

import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";

import FrequencySelector from "@app/component/frequency/FrequencySelector";
import FrequencyPreviewConfig from "@app/component/frequency/type/FrequencyPreviewConfig";
import { getFrequencyPanelConfig } from "@app/store/frequency";
import { RootState } from "@app/store/rootReducer";

import useFrequencyContext from "../../util/useFrequencyContext";
import FrequencyInfo from "../FrequencyInfo";

import styles from "./FrequencySelectorWithConfig.module.scss";

interface Props {
  config: FrequencyPreviewConfig;
  onChange: (cadence: Partial<Cadenced>) => void;
}

const FrequencySelectorWithConfig: React.FC<Props> = ({
  config: { entity, isGroup },
  onChange,
}) => {
  const { isEntityFrequencyUpdating } = useFrequencyContext();

  return (
    <div className={styles.container}>
      <FrequencySelector
        entity={entity}
        frequencyIntervalValue={entity.cadenceInterval ?? undefined}
        isGroup={isGroup}
        onChange={onChange}
        updating={isEntityFrequencyUpdating}
      />
      <FrequencyInfo />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  config: getFrequencyPanelConfig(state),
});

export default connect(mapStateToProps)(FrequencySelectorWithConfig);
