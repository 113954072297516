import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";

export const OPERATORS_ALLOWING_EMPTY_VALUE = [
  FilterOperator.EMPTY,
  FilterOperator.NOT_EMPTY,
  FilterOperator.IS_OVERDUE,
  FilterOperator.IS_UPCOMING,
  FilterOperator.IS_TRUE,
  FilterOperator.IS_FALSE,
];
