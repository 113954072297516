import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import IntegrationService from "@mapmycustomers/shared/enum/integrations/IntegrationService";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import { ActivityFieldName } from "../fieldModel/ActivityFieldModel";
import { isCustomField } from "../fieldModel/impl/assert";

const isFieldRequiredForIntegration = (field: IField, service?: IntegrationService): boolean => {
  if (
    field.hasFeature(FieldFeature.FUNNEL_FIELD) &&
    (service === IntegrationService.HUBSPOT || service === IntegrationService.ZOHO)
  ) {
    return false;
  }

  return (
    field.hasFeature(FieldFeature.REQUIRED) &&
    !isCustomField(field) &&
    ((service !== IntegrationService.DYNAMICS && service !== IntegrationService.SALESFORCE) ||
      (!field.hasFeature(FieldFeature.STAGE_FIELD) && field.name !== ActivityFieldName.ASSIGNEE))
  );
};

export default isFieldRequiredForIntegration;
