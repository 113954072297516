import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import EmptyFilter from "@app/util/filters/EmptyFilter";
import RoutesFilter, { ROUTES_FILTER_OPERATORS } from "@app/util/filters/Routes";
import { getRegularFieldConditionValue } from "@app/util/viewModel/convertFromPlatformFilterModel";

import Field, { FieldProperties } from "./Field";
import { listFormatter } from "./fieldUtil";

class RoutesField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "routes",
        filterInstances: {
          empty: EmptyFilter,
          routes: RoutesFilter,
        },
        operators: [
          ...ROUTES_FILTER_OPERATORS,
          { instance: "empty", operator: FilterOperator.EMPTY },
          { instance: "empty", operator: FilterOperator.NOT_EMPTY },
        ],
      },
      customGridProperties: {
        cellRenderer: "routesCellRenderer",
      },

      extraPlatformFiltersGetter: () => ({ includeRoutes: true }),
      features: [
        FieldFeature.DATA_VIEW,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.RELATIONSHIPS,
        FieldFeature.ROUTE_FIELD,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.VISIBLE_BY_DEFAULT,
      ],
      type: FieldType.LIST,
      valueFormatter: listFormatter("name"),
      ...data,
    });
  }

  get isCustomizableField() {
    // Route field is an exclusion - it shouldn't be present on list view, but available for selection in
    // entity records preview customization window
    return true;
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    // route field filter is applied as a meta-filter, thus it doesn't have a name
    // so we're adding a field name back for it.
    return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
  }
}

export default RoutesField;
