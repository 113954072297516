import { ActionType, createAction } from "typesafe-actions";

import Address from "@mapmycustomers/shared/types/Address";
import { EntityWithLocation } from "@mapmycustomers/shared/types/entity";

export const updateEntityAddress = createAction("@entityAddress/updateEntityAddress")<{
  address: Address;
  entity: EntityWithLocation;
}>();

export type EntityAddressActions = ActionType<typeof updateEntityAddress>;
