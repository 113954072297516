import color from "color";

import ColorEnum, { knownColors } from "@mapmycustomers/shared/enum/Color";

import colorNameToHex from "./colorNameToHex";

interface GetContrastColor {
  (sourceColor: string, extreme?: boolean): "black" | "white";
  (sourceColor: ColorEnum, extreme?: boolean): "black" | "white";
}

const getContrastColor: GetContrastColor = (
  sourceColor: unknown,
  extreme?: boolean
): "black" | "white" => {
  if (knownColors.includes(sourceColor as ColorEnum)) {
    sourceColor = colorNameToHex(sourceColor as ColorEnum);
  }
  // Darken just a tiny bit to make it select "white" on what I consider a dark background :)
  return color(sourceColor as string)
    .darken(extreme ? 0.35 : 0.15)
    .isLight()
    ? "black"
    : "white";
};

export default getContrastColor;
