import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

import SourceFilter, { SOURCE_FILTER_OPERATORS } from "../../filters/SourceFilter";

import Field, { FieldProperties } from "./Field";

class HistorySource extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "historySource",
        filterInstances: {
          historySource: SourceFilter,
        },
        operators: SOURCE_FILTER_OPERATORS,
      },
      features: [FieldFeature.FILTERABLE],
      platformFilterName: "source",
      type: FieldType.STRING,
      ...data,
    });
  }
}

export default HistorySource;
