import React, { useMemo } from "react";

import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons/faUserFriends";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip, { TooltipPropsWithTitle } from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import { ActivityTypeRef } from "@mapmycustomers/shared/types/entity/activities/ActivityType";

import { useActivityTypeColors } from "@app/util/activity/getActivityTypeColors";

import styles from "./ActivityTypeIcon.module.scss";

const defaultActivityNameIcon = {
  Call: faPhone,
  Email: faEnvelope,
  Lunch: faUtensils,
  Meeting: faUserFriends,
  Task: faCheckDouble,
  Text: faCommentDots,
  Visit: faMapMarkerAlt,
} as const;

type DefaultActivityType = keyof typeof defaultActivityNameIcon;

interface Props {
  activityType: ActivityTypeRef;
  activityTypeIconClassName?: string;
  className?: string;
  isAutoEmail?: boolean;
  size?: "lg" | "md" | "sm";
  tooltipProps?: false | TooltipPropsWithTitle;
}

const ActivityTypeIcon: React.FC<Props> = ({
  activityType,
  activityTypeIconClassName,
  className: externalClassName,
  isAutoEmail,
  size = "md",
  tooltipProps,
}) => {
  const intl = useIntl();

  const isDefaultActivityType = activityType.name in defaultActivityNameIcon;
  const icon = isDefaultActivityType
    ? defaultActivityNameIcon[activityType.name as DefaultActivityType]
    : faCalendarCheck;

  const tooltipText = useMemo(
    () =>
      isAutoEmail
        ? intl.formatMessage({
            id: "component.activityTypeIcon.autoEmail",
            defaultMessage: "Auto Email",
            description: "Auto email tooltip text",
          })
        : activityType.name,
    [activityType.name, intl, isAutoEmail]
  );

  const [color, bgColor, borderColor] = useActivityTypeColors(activityType);

  const iconContent = (
    <div
      className={cn(
        styles.activityTypeIcon,
        styles[size],
        activityTypeIconClassName,
        externalClassName
      )}
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor,
        color,
      }}
    >
      {isAutoEmail && <FontAwesomeIcon className={styles.autoEmail} icon={faBolt} />}
      <FontAwesomeIcon icon={icon} />
    </div>
  );

  return tooltipProps !== false ? (
    <Tooltip title={tooltipText} {...tooltipProps}>
      {iconContent}
    </Tooltip>
  ) : (
    iconContent
  );
};

export default ActivityTypeIcon;
