import configService from "../config/ConfigService";

export const getCaptchaToken = (action: string): PromiseLike<string> =>
  grecaptcha.execute(configService.getRecaptchaSiteKey(), { action });

export const executeWithCaptcha = async <T>(
  action: string,
  callback: (token: string) => Promise<T>
): Promise<T> => {
  return new Promise((resolve, reject) => {
    getCaptchaToken(action).then((token: string) => {
      Promise.resolve(callback(token)).then(resolve, reject);
    });
  });
};
