import React from "react";

import { faBucket } from "@fortawesome/pro-solid-svg-icons/faBucket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useIntl } from "react-intl";

import Color from "@mapmycustomers/shared/enum/Color";
import { Group } from "@mapmycustomers/shared/types/entity";

import Chip from "@app/component/Chip";
import anyColorToHex from "@app/util/colors/anyColorToHex";

import styles from "./FrequencyInterval.module.scss";

interface Props {
  className?: string;
  frequencyInterval?: null | number;
  groupColor?: Group["color"];
  isGroup?: boolean;
}

const FrequencyInterval: React.FC<Props> = ({
  className,
  frequencyInterval,
  groupColor,
  isGroup,
}) => {
  const intl = useIntl();

  return (
    <Chip
      className={cn(styles.container, className)}
      icon={
        isGroup && (
          <div
            className={styles.groupIconContainer}
            style={{
              backgroundColor: anyColorToHex(groupColor || Color.BLACK),
            }}
          >
            <FontAwesomeIcon className={styles.groupIcon} icon={faBucket} />
          </div>
        )
      }
    >
      <span className={styles.text}>
        {intl.formatMessage(
          {
            id: "component.frequencyInterval",
            defaultMessage: "{count} {count, plural, one {day} other {days}}",
            description: "Days out for the given group on the Reports page",
          },
          {
            count: frequencyInterval ?? 0,
          }
        )}
      </span>
    </Chip>
  );
};

export default FrequencyInterval;
