import { MessageDescriptor } from "@formatjs/intl/src/types";
import notification, { ArgsProps } from "antd/es/notification";
import { defineMessages } from "react-intl";
import { call, put, takeEvery } from "redux-saga/effects";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import { isApiError } from "@mapmycustomers/shared/util/assert";

import i18nService from "@app/config/I18nService";
import { login } from "@app/store/auth/actions";
import getErrorMessage from "@app/util/errorHandling/getErrorMessage";
import loggingService from "@app/util/logging";

import { handleError } from "./actions";

type NotificationType = "error" | "info" | "success" | "warning";
const messages = defineMessages({
  otherStatus: {
    id: "apiErrorMessage.otherStatus",
    defaultMessage:
      "Something went wrong processing the request. {status, select, undefined {({errorMessage})} other {{status} {errorMessage}}}",
    description: "Other statuses message",
  },
  status401: {
    id: "apiErrorMessage.status401",
    defaultMessage: "Session expired. Please sign in again",
    description: "401 status message",
  },
  status403: {
    id: "apiErrorMessage.status403",
    defaultMessage: "Access denied.",
    description: "403 status message",
  },
  status404: {
    id: "apiErrorMessage.status404",
    defaultMessage: "You may not have access to edit this record or it might have been deleted.",
    description: "404 status message",
  },
  status504: {
    id: "apiErrorMessage.status504",
    defaultMessage: "Error trying to access the server. {status}",
    description: "504 status message",
  },
});

export const showNotification = (type: NotificationType, payload: ArgsProps) => {
  loggingService.debug("showNotification", { payload, type });
  notification[type](payload);
};

// FIXME: In order to have localized error messages, we need to operate message descriptors instead of strings.
// See more about message descriptor: https://formatjs.io/docs/react-intl/api/#message-descriptor
// That said, we need to call defineMessage to create a predefined error messages and return one of those
// messages as a result of the getRequestErrorMessage. defaultMessage argument should also have a MessageDescriptor
// type.
// In order to format these messages to display in a notification, we need to store `intl` object in the redux
// store. Or share it somehow differently. And the whole app must be wrapped with <RawIntlProvider> instead of
// <IntlProvider> as now in the /src/App.tsx.
// Keep in mind that all these things won't help to translate Platform error messages. Unless Platform would accept
// locale to provide a localized message. Another approach is to copy server/services/validators/validation-codes.js
// from mmc-server repo and translate each validation message.

export function getRequestErrorMessage(
  status: number | undefined,
  error: ApiError,
  messageByErrorCodeMap?: Record<string, MessageDescriptor>
) {
  switch (status) {
    case 400:
      return getErrorMessage(error, messageByErrorCodeMap);

    case 401:
      return i18nService.formatMessage(messages.status401);

    case 403:
      return i18nService.formatMessage(messages.status403);

    case 404:
      return i18nService.formatMessage(messages.status404);

    case 502:
    case 503:
    case 504:
      return i18nService.formatMessage(
        messages.status504,
        `Error trying to access the server. ${status}`,
        { status: status }
      );

    default:
      return i18nService.formatMessage(
        messages.otherStatus,
        `Something went wrong processing the request. (${status ?? error.message})`,
        { errorMessage: error.message, status: status }
      );
  }
}

export function* onError(action: ReturnType<typeof handleError>) {
  const { error, messageByErrorCodeMap } = action.payload;

  if (error instanceof Error && process.env.NODE_ENV === "development") {
    console.error(error);
  }

  // TODO: add logging to the log server here

  let message: React.ReactNode | undefined =
    (error instanceof Error || (typeof error === "object" && error && "message" in error)
      ? (error as { message: string } | Error)?.message
      : undefined) ?? "Ooops. Something went wrong.";

  let key: string | undefined;

  if (isApiError(error)) {
    const { status } = error;
    loggingService.debug("error status", { status });

    if (status === 401) {
      yield put(login());

      // key is needed to only show one such notification, and close all previous ones
      key = "401";
      yield call(notification.close, key);
    }

    // too many requests error
    if (status === 429) {
      key = "429";
      yield call(notification.close, key);
    }

    message = getRequestErrorMessage(status, error, messageByErrorCodeMap);
  }

  yield call(showNotification, "error", { key, message });
}

export function* errorsSaga() {
  yield takeEvery(handleError, onError);
}
