import React from "react";

import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import Route from "@mapmycustomers/shared/types/entity/Route";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "../getHumanReadableDescriptionForOptionsFilter";

import RoutesFilterSelectComponent from "./RoutesFilter";

export const ROUTES_FILTER_OPERATORS = [
  FilterOperator.IN_ALL,
  FilterOperator.IN_ANY,
  FilterOperator.NOT_IN,
];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  ROUTES_FILTER_OPERATORS.includes(operator);

interface RouteFilterProps extends IFilterComponentProps {}

const RoutesFilter: IFilterInstance = {
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_ROUTES,
  doesSupportValue,
  getComponent:
    (): React.FC<RouteFilterProps> =>
    ({ className, focus, onChange, options, value }) => {
      return (
        <RoutesFilterSelectComponent
          className={className}
          focus={focus}
          onChange={onChange}
          options={options}
          value={value}
        />
      );
    },
  getHumanReadableDescription: (value: SimpleCondition, field: IField, options) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const routesMap = new Map(
      ((options?.availableRoutes ?? []) as Route[]).map(({ id, name }) => [id, name])
    );
    const selectedValues = value.value as Route["id"][];
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      selectedValues.map((routeId) => routesMap.get(routeId) ?? String(routeId))
    );
  },
};

export default RoutesFilter;
