import { defineMessages, IntlShape } from "react-intl";

import DateRangeType from "@mapmycustomers/shared/enum/DateRangeType";

const messages = defineMessages<DateRangeType>({
  [DateRangeType.ALL]: {
    id: "dateRangeType.all",
    defaultMessage: "All-Time",
    description: "All Time date range type name",
  },
  [DateRangeType.CUSTOM]: {
    id: "dateRangeType.custom",
    defaultMessage: "Custom",
    description: "Custom date range type name",
  },
  [DateRangeType.DAY]: {
    id: "dateRangeType.day",
    defaultMessage: "Day",
    description: "Day date range type name",
  },
  [DateRangeType.MONTH]: {
    id: "dateRangeType.month",
    defaultMessage: "Month",
    description: "Month date range type name",
  },
  [DateRangeType.QUARTER]: {
    id: "dateRangeType.quarter",
    defaultMessage: "Quarter",
    description: "Quarter date range type name",
  },
  [DateRangeType.WEEK]: {
    id: "dateRangeType.week",
    defaultMessage: "Week",
    description: "Week date range type name",
  },
});

const getDateRangeTypeDisplayName = (intl: IntlShape, dateRangeType: DateRangeType) =>
  intl.formatMessage(messages[dateRangeType]);

export default getDateRangeTypeDisplayName;
