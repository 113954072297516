import React from "react";
import { connect } from "react-redux";

import { defineMessage } from "react-intl";
import { Redirect, useLocation } from "react-router-dom";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";

import Path from "@app/enum/Path";
import { getError, isSignedIn, isUserLimitExceeded } from "@app/store/auth";
import { loggedInWithSso } from "@app/store/auth/actions";
import { RootState } from "@app/store/rootReducer";
import getErrorMessage from "@app/util/errorHandling/getErrorMessage";

import AuthLayout from "../AuthLayout";

import SsoErrorCodes from "./getSsoErrorMessage";
import SsoLoginForm from "./SsoLoginForm";

const genericErrorMessage = defineMessage({
  id: "auth.ssoLogin.error.unknown",
  defaultMessage:
    "Something went wrong. Please try again. If problems persist, please contact support.",
  description: "A generic sso login failed error message text",
});

interface Props {
  error?: ApiError;
  loggedInWithSso: typeof loggedInWithSso;
  signedIn: boolean | undefined;
  userLimitExceeded: boolean;
}

const SsoLoginPage: React.FC<Props> = ({ error, loggedInWithSso, signedIn, userLimitExceeded }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const mmcSessionId = urlParams.get("mmc_session_id");
  let mmcMessage = urlParams.get("mmc_message");

  if (mmcSessionId && !mmcMessage) {
    loggedInWithSso({ sessionId: mmcSessionId });
    return null;
  }

  // Api call errors
  let message = undefined;
  if (error && !userLimitExceeded) {
    message = getErrorMessage(error, {}, genericErrorMessage);
  }

  if (userLimitExceeded) {
    mmcMessage = SsoErrorCodes.USER_LIMIT_EXCEEDED;
  }

  if (signedIn) {
    return <Redirect to={Path.DASHBOARD} />;
  }

  return (
    <AuthLayout>
      <SsoLoginForm
        errorMessage={message}
        mmcMessage={mmcMessage as SsoErrorCodes}
        mmcSessionId={mmcSessionId}
      />
    </AuthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  signedIn: isSignedIn(state),
  userLimitExceeded: isUserLimitExceeded(state),
});

const mapDispatchToProps = {
  loggedInWithSso,
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoLoginPage);
