import React from "react";
import { connect } from "react-redux";

import { faMapMarkerQuestion } from "@fortawesome/pro-regular-svg-icons/faMapMarkerQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import { FormattedMessage } from "react-intl";

import { RootState } from "@app/store/rootReducer";

import { hide, hideInSession } from "./actions";
import { getVisibility } from "./selectors";
import styles from "./UserLocationRequest.module.scss";

interface Props {}

const UserLocationRequest: React.FC<
  Props & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProp
> = ({ hide, hideInSession, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faMapMarkerQuestion} />
        </div>
        <div>
          <p>
            <FormattedMessage
              id="layout.userLocationRequest.errorDescription"
              defaultMessage="We’re unable to access your current location. Improve your experience by granting location permission."
              description="User location request bar: description of the problem"
            />
          </p>
          <p>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://support.mapmycustomers.com/hc/en-us/articles/360062391934"
              target="_blank"
            >
              <FormattedMessage
                id="layout.userLocationRequest.helpCenterLinkText"
                defaultMessage="How to share your location with Map My Customers"
                description="User location request bar: link to a solution text"
              />
            </a>
          </p>
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          onClick={() => {
            hideInSession();
          }}
        >
          <FormattedMessage
            id="layout.userLocationRequest.actions.dismissForGood"
            defaultMessage="Dismiss and don’t show again"
            description="User location request bar: dismiss for good button caption"
          />
        </Button>
        <Button
          onClick={() => {
            hide();
          }}
          type="primary"
        >
          <FormattedMessage
            id="layout.userLocationRequest.actions.dismiss"
            defaultMessage="Dismiss"
            description="User location request bar: dismiss button caption"
          />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  visible: getVisibility(state),
});

const mapDispatchToProp = {
  hide,
  hideInSession,
};

export default connect(mapStateToProps, mapDispatchToProp)(UserLocationRequest);
