import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";

import EmptyFilter from "../../filters/EmptyFilter";
import LossReasonFilter, { LOSS_REASON_FILTER_OPERATORS } from "../../filters/LossReason";

import Field, { FieldProperties } from "./Field";

class LossReasonField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "lossReason",
        filterInstances: {
          empty: EmptyFilter,
          lossReason: LossReasonFilter,
        },
        operators: [
          ...LOSS_REASON_FILTER_OPERATORS,
          { instance: "empty", operator: FilterOperator.EMPTY },
          { instance: "empty", operator: FilterOperator.NOT_EMPTY },
        ],
      },
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.DETAILS,
        FieldFeature.SUPPORTS_SET_FILTER,
      ],
      importProperties: {
        name: "dealLossReason",
      },
      platformFilterName: "dealLossReasonId",
      type: FieldType.STRING,
      valueGetter: ["dealLossReason", "name"],
      ...data,
    });
  }
}

export default LossReasonField;
