import { ActionType, createAction } from "typesafe-actions";

import { Company } from "@mapmycustomers/shared/types/entity";

import ApiService from "@app/store/api/ApiService";

// WARNING! Only use this action when you can't get company from the store
export const fetchCompany = createAction("@company/fetchCompany")<{
  callback: (company: Company) => void;
  failureCallback?: () => void;
  id: Company["id"];
  options?: Parameters<ApiService["fetchCompany"]>[2];
}>();

export const fetchCompanies = createAction("@company/fetchCompanies")<{
  callback: (companies: Company[]) => void;
  failureCallback?: () => void;
  ids: Company["id"][];
}>();

export type Actions = ActionType<typeof fetchCompany>;
