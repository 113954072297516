import { Dispatch, useState } from "react";

import { isFunction } from "lodash-es";

import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";

const useStateWithTransformer = <BasicState, UpdatedState = BasicState>(
  initialState: (() => BasicState) | BasicState,
  transformer: (value: UpdatedState) => BasicState
): [BasicState, Dispatch<((prevState: BasicState) => UpdatedState) | UpdatedState>] => {
  const [state, setState] = useState<BasicState>(initialState);

  const handleSetState = useDynamicCallback(
    (value: ((prevState: BasicState) => UpdatedState) | UpdatedState) => {
      setState((prevState) => {
        const updatedState: UpdatedState = isFunction(value) ? value(prevState) : value;
        return transformer(updatedState);
      });
    }
  );

  return [state, handleSetState];
};

export default useStateWithTransformer;
