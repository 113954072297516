import { ActionType, createAsyncAction } from "typesafe-actions";

import TimelineEventType from "@app/scene/dashboard/enum/TimelineEventType";
import TimelineViewFilter from "@app/scene/dashboard/enum/TimelineViewFilter";
import ViewType from "@app/scene/dashboard/enum/ViewType";
import { ActivitiesLoggedCardActions } from "@app/scene/dashboard/store/cards/activitiesLogged/actions";
import { ActivitiesOverdueCardActions } from "@app/scene/dashboard/store/cards/activitiesOverdue/actions";
import { CheckInsCardActions } from "@app/scene/dashboard/store/cards/checkIns/actions";
import { RecordsPastDueCardActions } from "@app/scene/dashboard/store/cards/recordsPastDue/actions";
import CardConfiguration, { GoalConfiguration } from "@app/types/dashboard/CardConfiguration";
import Dashboard from "@app/types/dashboard/Dashboard";
import DashboardCard from "@app/types/dashboard/DashboardCard";
import Scope from "@app/types/dashboard/Scope";
import TimelineEvent from "@app/types/events/TimelineEvent";

import { CustomerFaceTimeCardActions } from "./cards/customerFaceTime/actions";
import { NewRecordsCardActions } from "./cards/newRecords/actions";
import { NewRoutesCardActions } from "./cards/newRoutes/actions";
import { NoContactInCardActions } from "./cards/noContactIn/actions";
import { OutOfFrequencyCardActions } from "./cards/outOfFrequency/actions";
import { StackRankActions } from "./stackRank/actions";

export * from "./cards/activitiesLogged/actions";
export * from "./cards/activitiesOverdue/actions";
export * from "./cards/checkIns/actions";
export * from "./cards/customerFaceTime/actions";
export * from "./cards/newRecords/actions";
export * from "./cards/newRoutes/actions";
export * from "./cards/noContactIn/actions";
export * from "./cards/outOfFrequency/actions";
export * from "./cards/recordsPastDue/actions";
export * from "./stackRank/actions";

export const initialize = createAsyncAction(
  "@dashboard/initialize/request",
  "@dashboard/initialize/success",
  "@dashboard/initialize/failure"
)<void, void, void>();

export const changeScope = createAsyncAction(
  "@dashboard/changeScope/request",
  "@dashboard/changeScope/success",
  "@dashboard/changeScope/failure"
)<
  { scope: Scope; view: ViewType },
  { boards?: Dashboard[]; events?: TimelineEvent[]; oldestEventDate?: Date; view: ViewType },
  void
>();

export const fetchTimelineEvents = createAsyncAction(
  "@dashboard/fetchTimelineEvents/request",
  "@dashboard/fetchTimelineEvents/success",
  "@dashboard/fetchTimelineEvents/failure"
)<
  {
    eventTypes?: TimelineEventType[];
    onComplete?: () => void;
    query?: string;
    scope: Scope;
    startDate?: Date;
    viewFilter?: TimelineViewFilter;
  },
  { append?: boolean; events: TimelineEvent[]; total?: number },
  unknown
>();

export const addCard = createAsyncAction(
  "@dashboard/addCard/request",
  "@dashboard/addCard/success",
  "@dashboard/addCard/failure"
)<{ card: DashboardCard; scope: Scope }, Dashboard, void>();

export const saveCard = createAsyncAction(
  "@dashboard/saveCard/request",
  "@dashboard/saveCard/success",
  "@dashboard/saveCard/failure"
)<
  {
    cardId: DashboardCard["id"];
    configuration: CardConfiguration<any, any, any>;
    fullWidth?: DashboardCard["fullWidth"];
    scope: Scope;
    teamGoalConfiguration?: GoalConfiguration;
    updateGoalOverrides: boolean;
    updateMetaData?: Pick<DashboardCard, "updatedAt" | "updatedBy">;
    updatePreferencesOverrides: boolean;
  },
  Dashboard[],
  void
>();

export const saveDashboardLayout = createAsyncAction(
  "@dashboard/saveDashboardLayout/request",
  "@dashboard/saveDashboardLayout/success",
  "@dashboard/saveDashboardLayout/failure"
)<
  { cardPositions: Record<DashboardCard["id"], Pick<DashboardCard, "x" | "y">>; scope: Scope },
  Dashboard,
  void
>();

export const saveDashboardSettings = createAsyncAction(
  "@dashboard/saveDashboardSettings/request",
  "@dashboard/saveDashboardSettings/success",
  "@dashboard/saveDashboardSettings/failure"
)<
  {
    scope: Scope;
    settings: Partial<Omit<Dashboard["settings"], "cards" | "overrides">>;
  },
  Dashboard,
  void
>();

export const overrideTeamDashboards = createAsyncAction(
  "@dashboard/overrideTeamDashboards/request",
  "@dashboard/overrideTeamDashboards/success",
  "@dashboard/overrideTeamDashboards/failure"
)<void, Dashboard[], void>();

export const removeCard = createAsyncAction(
  "@dashboard/removeCard/request",
  "@dashboard/removeCard/success",
  "@dashboard/removeCard/failure"
)<{ cardId: DashboardCard["id"]; scope: Scope }, Dashboard[], void>();

export type Actions =
  | ActionType<
      | typeof addCard
      | typeof changeScope
      | typeof fetchTimelineEvents
      | typeof initialize
      | typeof overrideTeamDashboards
      | typeof removeCard
      | typeof saveCard
      | typeof saveDashboardLayout
      | typeof saveDashboardSettings
    >
  | ActivitiesLoggedCardActions
  | ActivitiesOverdueCardActions
  | CheckInsCardActions
  | CustomerFaceTimeCardActions
  | NewRecordsCardActions
  | NewRoutesCardActions
  | NoContactInCardActions
  | OutOfFrequencyCardActions
  | RecordsPastDueCardActions
  | StackRankActions;
