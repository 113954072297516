import docusignPaleLogo from "@app/assets/cloud/docusign-pale.svg";
import dropboxPaleLogo from "@app/assets/cloud/dropbox-pale.svg";
import quickbooksPaleLogo from "@app/assets/cloud/quickbooks-pale.svg";
import dynamicsRound from "@app/assets/integrations/services/dynamics-round.svg";
import dynamicsLogo from "@app/assets/integrations/services/dynamics.svg";
import hubspotLogoRound from "@app/assets/integrations/services/hubspot-round.svg";
import hubspotLogo from "@app/assets/integrations/services/hubspot.svg";
import salesforceLogoRound from "@app/assets/integrations/services/salesforce-round.svg";
import salesforceLogo from "@app/assets/integrations/services/salesforce.svg";
import zapierLogo from "@app/assets/integrations/services/zapier.svg";
import zohoLogoRound from "@app/assets/integrations/services/zoho-round.svg";
import zohoLogo from "@app/assets/integrations/services/zoho.svg";
import mapmycustomersColoredLogo from "@app/assets/mapmycustomers-colored.svg";

// We don't have small logos yet, so we're using a full-sized logos
export const dynamicsSmallLogo = dynamicsLogo;
export const hubspotSmallLogo = hubspotLogo;
export const salesforceSmallLogo = salesforceLogo;
export const zohoSmallLogo = zohoLogo;
export const hubspotRoundLogo = hubspotLogoRound;
export const salesforceRoundLogo = salesforceLogoRound;
export const zohoRoundLogo = zohoLogoRound;

export {
  docusignPaleLogo,
  dropboxPaleLogo,
  dynamicsLogo,
  dynamicsRound,
  hubspotLogo,
  hubspotLogoRound,
  mapmycustomersColoredLogo,
  quickbooksPaleLogo,
  salesforceLogo,
  salesforceLogoRound,
  zapierLogo,
  zohoLogo,
  zohoLogoRound,
};
