import React, { FC, useCallback } from "react";

import generatePicker, { PickerDateProps } from "antd/es/date-picker/generatePicker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import "antd/es/date-picker/style/index";

import antdPickerLocaleConfig from "./utils/antdPickerLocaleConfig";

const DatePicker = generatePicker<Date>({
  ...dateFnsGenerateConfig,
  locale: antdPickerLocaleConfig,
});

export interface ClearableDatePickerProps<DateType>
  extends Omit<PickerDateProps<DateType>, "onChange" | "onSelect"> {
  onChange?: (date: Date | undefined) => void;
  use12Hours?: boolean;
}

export const ClearableDatePicker: FC<ClearableDatePickerProps<Date>> = ({ onChange, ...props }) => {
  const handleChange = useCallback(
    (date: Date | null) => {
      onChange?.(date || undefined);
    },
    [onChange]
  );

  return <DatePicker onChange={handleChange} onSelect={onChange} {...props} />;
};

export default DatePicker;
