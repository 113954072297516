import React from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification } from "antd";
import { Col } from "antd/es/grid";
import Row from "antd/es/row";
import cn from "classnames";
import { push } from "connected-react-router";
import { call, put, select, takeEvery, takeLeading } from "redux-saga/effects";

import Email from "@mapmycustomers/shared/types/email/Email";
import EmailPreview from "@mapmycustomers/shared/types/email/EmailPreview";
import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { hideMapEntityView } from "@app/scene/map/store/mapMode/actions";
import { connectToMailNylas } from "@app/scene/settings/util/connectToNylas";
import { handleError } from "@app/store/errors/actions";
import { doesOrganizationSupportEmailService, getOrganizationId } from "@app/store/iam";
import { EMAIL_WARNING_MODAL_CLASS_NAME } from "@app/util/email/const";
import { getDefaultActivityEmailLayout } from "@app/util/email/getActivityEmailLayouts";

import styles from "../../component/view/SelectBar/components/EmailButton/EmailButton.module.scss";
import i18nService from "../../config/I18nService";
import NylasStatus from "../../enum/NylasStatus";
import Path from "../../enum/Path";
import SettingPath from "../../enum/settings/SettingPath";
import layout from "../../styles/layout";
import NylasInfo from "../../types/nylas/NylasInfo";
import { downloadFileByUrl } from "../../util/file";
import { callApi } from "../api/callApi";
import { getEmailNylasInfo } from "../nylas";

import {
  checkEmailSupporting,
  clearEmailQueue,
  createNewEmailTemplate,
  deleteEmailTemplate,
  downloadAttachedFile,
  fetchEmailInfo,
  fetchEmailTemplates,
  fetchNewEmailPreview,
  fetchSelectedEntities,
  fetchTemplatePreview,
  loadImageToEmailBody,
  sendEmail,
  updateEmailTemplate,
  uploadFileForNewEmail,
} from "./actions";
import messages from "./messages";

function* onFetchEmailInfo({ payload }: ReturnType<typeof fetchEmailInfo.request>) {
  try {
    const orgId: Organization["id"] | undefined = yield select(getOrganizationId);
    if (orgId) {
      const emailQuota: EmailQuota = yield callApi("getEmailQuota", orgId);
      if (payload?.callback) {
        yield call(payload.callback, emailQuota);
      }
      yield put(fetchEmailInfo.success(emailQuota));
    }
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchEmailInfo.failure(error));
  }
}

function* onClearEmailQueue() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("clearEmailQueue", orgId);
    yield put(clearEmailQueue.success());
    yield put(fetchEmailInfo.request());
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchEmailInfo.failure(error));
  }
}

function* onFetchEmailTemplates() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<EmailTemplate> = yield callApi("fetchEmailTemplates", orgId);
    yield put(fetchEmailTemplates.success(response.data));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchEmailTemplates.failure(error));
  }
}

function* onFetchNewEmailPreview({ payload }: ReturnType<typeof fetchNewEmailPreview.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const preview: EmailPreview = yield callApi("previewEmail", orgId, payload);
    yield put(fetchNewEmailPreview.success(preview));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchNewEmailPreview.failure(error));
  }
}

function* onFetchTemplatePreview({ payload }: ReturnType<typeof fetchTemplatePreview.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const preview: EmailPreview = yield callApi("previewEmail", orgId, payload);
    yield put(fetchTemplatePreview.success(preview));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchTemplatePreview.failure(error));
  }
}

function* onFetchSelectedEntities({
  payload: { callback, entityIds, entityType },
}: ReturnType<typeof fetchSelectedEntities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<EntitiesSupportedByEmailFeature> = yield callApi(
      entityType === EntityType.COMPANY ? "fetchCompanies" : "fetchPeople",
      orgId,
      {
        $filters: {
          $and: [
            {
              id: { $in: entityIds },
            },
          ],
        },
      }
    );
    yield call(callback, response.data);

    yield put(fetchSelectedEntities.success(response.data));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchSelectedEntities.failure(error));
  }
}

export function* onLoadImageToEmailBody({
  payload: { callback, file },
}: ReturnType<typeof loadImageToEmailBody>) {
  try {
    const FILE_SIZE_LIMIT = 25 * 1024 * 1024;

    if (file.size > FILE_SIZE_LIMIT) {
      const intl = i18nService.getIntl();
      notification.error({
        message: (
          <div>
            <div className={styles.errorTitle}>
              {intl?.formatMessage(messages.fileSizeExceeded)}
            </div>
            <div className={styles.errorDescription}>
              {intl?.formatMessage(messages.fileSizeExceededDescription)}
            </div>
          </div>
        ),
      });
    } else {
      const orgId: Organization["id"] = yield select(getOrganizationId);
      const rawFile: RawFile = yield callApi("createFile", orgId, file, true);

      if (rawFile.publicURI) {
        yield call(callback, rawFile.publicURI);
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onCreateNewEmailTemplate({
  payload: { callback, template },
}: ReturnType<typeof createNewEmailTemplate.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const createdTemplate: EmailTemplate = yield callApi("createEmailTemplate", orgId, template);
    if (callback) {
      yield call(callback, createdTemplate);
    }
    yield put(fetchEmailTemplates.request());
    yield put(createNewEmailTemplate.success(createdTemplate));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(createNewEmailTemplate.failure(error));
  }
}

function* onUpdateEmailTemplate({
  payload: { callback, template },
}: ReturnType<typeof updateEmailTemplate.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedTemplate: EmailTemplate = yield callApi("updateEmailTemplate", orgId, template);
    if (callback) {
      yield call(callback, updatedTemplate);
    }
    yield put(fetchEmailTemplates.request());
    yield put(updateEmailTemplate.success(updatedTemplate));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(updateEmailTemplate.failure(error));
  }
}

export function* onDownloadAttachedFile({
  payload: { attachedFile, preview },
}: ReturnType<typeof downloadAttachedFile>) {
  try {
    if (attachedFile.file) {
      const orgId: Organization["id"] = yield select(getOrganizationId);
      const fileBlob: Blob = yield callApi(
        "fetchFile",
        orgId,
        attachedFile.file.id,
        true,
        false,
        undefined,
        undefined,
        {
          responseType: "blob",
        }
      );
      const fileUrl = window.URL.createObjectURL(fileBlob);
      if (preview) {
        window.open(fileUrl, "_blank");
      } else {
        yield call(downloadFileByUrl, fileUrl, attachedFile.file.name);
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onCheckEmailSupporting({
  payload: { modal, successfulCallback },
}: ReturnType<typeof checkEmailSupporting>) {
  try {
    let success = true;
    const nylasInfo: NylasInfo | undefined = yield select(getEmailNylasInfo);
    const organizationSupportEmailService: boolean = yield select(
      doesOrganizationSupportEmailService
    );

    const intl = i18nService.getIntl();
    if (!organizationSupportEmailService) {
      // we need to hide entity pane if this redirection was called from the map
      yield put(hideMapEntityView());
      yield put(push(`${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`));
      success = false;
    } else {
      const emailLayout = getDefaultActivityEmailLayout();
      const someEmailLayoutHasRequiredRelationshipFields = emailLayout?.schema.some(
        (field) => ["accountId", "contactId", "dealId"].includes(field.field) && field.required
      );
      if (someEmailLayoutHasRequiredRelationshipFields) {
        modal.error({
          cancelButtonProps: { hidden: true },
          centered: true,
          className: cn(
            "mmc-modal--bordered",
            styles.confirmationModal,
            styles.confirmationModalError,
            EMAIL_WARNING_MODAL_CLASS_NAME
          ),
          content: intl?.formatMessage(messages.someEmailLayoutHasRequiredRelationshipFieldsText),
          okCancel: true,
          okText: intl?.formatMessage(messages.someEmailLayoutHasRequiredRelationshipFieldsOk),
          title: intl?.formatMessage(messages.someEmailLayoutHasRequiredRelationshipFieldsTitle),
        });
        success = false;
      } else {
        const isSyncFailed =
          nylasInfo &&
          [null, NylasStatus.INVALID, NylasStatus.STOPPED, NylasStatus.SYNC_ERROR].includes(
            nylasInfo.status
          );

        if (!nylasInfo || isSyncFailed) {
          modal.info({
            cancelText: intl?.formatMessage(messages.syncModalCancel),
            centered: true,
            className: cn(
              "mmc-modal--bordered",
              styles.confirmationModal,
              EMAIL_WARNING_MODAL_CLASS_NAME
            ),
            content: (
              <div className={styles.syncModalContent}>
                {isSyncFailed
                  ? intl?.formatMessage(messages.syncModalFailedConnectionText)
                  : intl?.formatMessage(messages.syncModalNoConnectionText, {
                      b: (text: string) => <b>{text}</b>,
                      br: <br />,
                      icon: <FontAwesomeIcon icon={faInfoCircle} />,
                    })}
              </div>
            ),
            icon: null,
            okCancel: true,
            okText: isSyncFailed
              ? intl?.formatMessage(messages.syncModalFailedConnectionOk)
              : intl?.formatMessage(messages.syncModalNoConnectionOk),
            onOk: connectToMailNylas,
            title: (
              <Row align="middle" className={styles.syncModalTitle} gutter={layout.spacerM}>
                <Col>
                  <FontAwesomeIcon
                    className={isSyncFailed ? styles.warningIcon : styles.infoIcon}
                    icon={faExclamationCircle}
                  />
                </Col>
                <Col>
                  {isSyncFailed
                    ? intl?.formatMessage(messages.syncModalFailedConnectionTitle)
                    : intl?.formatMessage(messages.syncModalNoConnectionTitle)}
                </Col>
              </Row>
            ),
          });
          success = false;
        }
      }
    }

    if (success) {
      yield call(successfulCallback);
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUploadFileForNewEmail({
  payload,
}: ReturnType<typeof uploadFileForNewEmail.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const uploadedFile: RawFile = yield callApi(
      "createFile",
      orgId,
      payload.preparedFile,
      false,
      undefined,
      undefined,
      {
        headers: {
          "x-mmc-email-attachment": true,
        },
      }
    );
    yield put(uploadFileForNewEmail.success({ ...payload, file: uploadedFile }));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(uploadFileForNewEmail.failure(payload));
  }
}

function* onDeleteEmailTemplate({
  payload: { callback, templateId },
}: ReturnType<typeof deleteEmailTemplate.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deleteEmailTemplate", orgId, templateId);
    callback?.();
    yield put(fetchEmailTemplates.request());
    yield put(deleteEmailTemplate.success());
  } catch (error) {
    yield put(handleError({ error }));
    yield put(deleteEmailTemplate.failure(error));
  }
}

function* onSendEmail({ payload: { callback, email } }: ReturnType<typeof sendEmail.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const createdEmail: Email = yield callApi("sendEmail", orgId, email);
    yield put(sendEmail.success(createdEmail));
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put(handleError({ error }));
    yield put(sendEmail.failure(error));
  }
}

export function* emailSaga() {
  yield takeLeading(clearEmailQueue.request, onClearEmailQueue);
  yield takeLeading(createNewEmailTemplate.request, onCreateNewEmailTemplate);
  yield takeLeading(deleteEmailTemplate.request, onDeleteEmailTemplate);
  yield takeLeading(fetchEmailInfo.request, onFetchEmailInfo);
  yield takeLeading(fetchEmailTemplates.request, onFetchEmailTemplates);
  yield takeLeading(loadImageToEmailBody, onLoadImageToEmailBody);
  yield takeLeading(sendEmail.request, onSendEmail);
  yield takeLeading(updateEmailTemplate.request, onUpdateEmailTemplate);
  yield takeEvery(uploadFileForNewEmail.request, onUploadFileForNewEmail);
  yield takeLeading(fetchNewEmailPreview.request, onFetchNewEmailPreview);
  yield takeLeading(fetchTemplatePreview.request, onFetchTemplatePreview);
  yield takeLeading(fetchSelectedEntities.request, onFetchSelectedEntities);
  yield takeLeading(downloadAttachedFile, onDownloadAttachedFile);
  yield takeEvery(checkEmailSupporting, onCheckEmailSupporting);
}
