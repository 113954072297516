import CountryCode from "../../enum/CountryCode";
import TerritoryLevel from "../../enum/TerritoryLevel";
import AreaType from "../../types/territory/AreaType";

export const parseAreaType = (areaType: AreaType) => {
  const [country, level] = areaType.split("_");
  if (
    !Object.values(TerritoryLevel).includes(level as TerritoryLevel) ||
    !Object.values(CountryCode).includes(country.toUpperCase() as CountryCode)
  ) {
    return undefined;
  }
  return {
    countryCode: country.toUpperCase() as CountryCode,
    level: level as TerritoryLevel,
  };
};

export const areaTypes: AreaType[] = [
  `${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM1}` as AreaType,
  `${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM2}` as AreaType,
  `${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM3}` as AreaType,
  `${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM1}` as AreaType,
  `${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM2}` as AreaType,
  `${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM3}` as AreaType,
  `${CountryCode["CANADA"]}_${TerritoryLevel.ADM1}` as AreaType,
  `${CountryCode["CANADA"]}_${TerritoryLevel.ADM2}` as AreaType,
  `${CountryCode["CANADA"]}_${TerritoryLevel.ADM3}` as AreaType,
  `${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM1}` as AreaType,
  `${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM2}` as AreaType,
  `${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM3}` as AreaType,
];
