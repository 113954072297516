import { useMemo } from "react";

import { Rule } from "antd/es/form";
import { IntlShape } from "react-intl";

import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";

const useActivityNameValidationRules = (intl: IntlShape): Rule[] =>
  useMemo(
    () => [
      (form) => ({
        validator: () => {
          if (
            form.getFieldValue(ActivityFieldName.NAME)?.trim() ||
            form.getFieldValue("activityTypeId")
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(
              intl.formatMessage({
                id: "createEditActivityModal.field.name.error.empty",
                defaultMessage: "Please enter Activity Name",
                description:
                  "Error message for emptiness ActivityName validation rule in Create Activity modal",
              })
            )
          );
        },
      }),
    ],
    [intl]
  );

export default useActivityNameValidationRules;
