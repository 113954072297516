import { createReducer } from "typesafe-actions";

import Dashboard from "@app/types/dashboard/Dashboard";
import DashboardCard from "@app/types/dashboard/DashboardCard";

import { Actions, fetchCardData, fetchCards, fetchDashboards } from "./actions";
import { CardData } from "./saga";

export interface UserPreviewCardState {
  boards: Dashboard[];
  cards: Record<number, DashboardCard["id"] | undefined>;
  cardsData: Record<string, CardData>;
  cardsLoading: boolean;
}

const initialState: UserPreviewCardState = {
  boards: [],
  cards: {},
  cardsData: {},
  cardsLoading: false,
};

const userPreviewCard = createReducer<UserPreviewCardState, Actions>(initialState)
  .handleAction(fetchDashboards.success, (state, { payload }) => ({
    ...state,
    boards: payload,
  }))
  .handleAction(fetchCards.request, (state) => ({
    ...state,
    cardsLoading: true,
  }))
  .handleAction(fetchCards.success, (state, { payload }) => ({
    ...state,
    cards: payload,
    cardsLoading: false,
  }))
  .handleAction(fetchCardData.success, (state, { payload }) => ({
    ...state,
    cardsData: { ...state.cardsData, [payload.id]: payload.data },
  }));

export * from "./selectors";
export type UserPreviewCardActions = Actions;
export default userPreviewCard;
