import { useMemo } from "react";

import User from "@mapmycustomers/shared/types/User";

import { isManager, isMember, isOwner } from "@app/store/iam/util";

const useUserAccess = (currentUser: User, user: undefined | User) => {
  const [allowOpenUser, viewOnly, viewOnlyNoAccess] = useMemo(() => {
    const userTeamIds = user?.teams.map(({ id }) => id) ?? [];
    const sameTeam = currentUser.teams.some(({ id }) => userTeamIds.includes(id));

    const viewOnly = isMember(currentUser) || (isManager(currentUser) && !sameTeam);
    const viewOnlyNoAccess =
      (!isOwner(currentUser) && !sameTeam) || (!!user && isMember(currentUser) && isManager(user));

    const allowOpenUser =
      !!user &&
      ((isMember(currentUser) && currentUser.id === user.id) ||
        (isManager(currentUser) && !isOwner(user) && sameTeam) ||
        isOwner(currentUser));

    return [allowOpenUser, viewOnly, viewOnlyNoAccess];
  }, [currentUser, user]);

  return [allowOpenUser, viewOnly, viewOnlyNoAccess];
};

export default useUserAccess;
