import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import FormLayout, {
  ChildLayout,
  LayoutSchemaField,
} from "@mapmycustomers/shared/types/layout/FormLayout";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import { getFunnelStages } from "@app/store/deal";
import getChildLayout from "@app/util/layout/getChildLayout";

interface UseActualLayoutSchemaArgs {
  crmActivityTypeId?: ActivityType["id"];
  entityType: EntityTypesSupportingFieldCustomization;
  layout: FormLayout;
  stageId?: Stage["id"];
}

const useActualLayoutSchema = ({
  crmActivityTypeId,
  entityType,
  layout,
  stageId,
}: UseActualLayoutSchemaArgs): [
  actualSchema: LayoutSchemaField[],
  childLayout: ChildLayout | undefined,
  isVariant: boolean
] => {
  const funnelStages = useSelector(getFunnelStages);

  return useMemo(() => {
    const funnelIdString = isDefined(stageId)
      ? Object.keys(funnelStages).find((funnelId) =>
          funnelStages[parseInt(funnelId) as Funnel["id"]].some(({ id }) => id === stageId)
        )
      : undefined;

    const childLayout = getChildLayout(
      layout,
      entityType === EntityType.DEAL && isDefined(funnelIdString) && isDefined(stageId)
        ? { funnelId: parseInt(funnelIdString), stageId }
        : entityType === EntityType.ACTIVITY && isDefined(crmActivityTypeId)
        ? { activityTypeId: crmActivityTypeId }
        : undefined
    );

    const schema = childLayout?.schema ?? layout.schema;
    const isVariant = !!childLayout;

    return [schema, childLayout, isVariant];
  }, [crmActivityTypeId, entityType, funnelStages, layout, stageId]);
};

export default useActualLayoutSchema;
