const messages = {
  "ui.address.autoCompleteAddress.placeholder": "Type to search",
  "ui.address.findMe.tooltip": "Find my location",
  "ui.autoComplete.address.manualButton": "Enter address manually",
  "ui.autoComplete.address.noResult": "No results",
  "ui.manualAddress.city": "City",
  "ui.manualAddress.country": "Country",
  "ui.manualAddress.postalCode": "Postal Code",
  "ui.manualAddress.regionProvince": "State/Province",
  "ui.manualAddress.saveAllFields": "Save all address fields",
  "ui.manualAddress.searchGoogle": "Search Addresses via Google",
  "ui.manualAddress.street": "Street",
} as const;

export default messages;
