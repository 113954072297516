import React from "react";

import Email from "@mapmycustomers/shared/types/email/Email";

interface Props {
  body: Email["body"];
}

const EmailBody: React.FC<Props> = ({ body }) => {
  return <div dangerouslySetInnerHTML={{ __html: body }} />;
};

export default EmailBody;
