import { createReducer } from "typesafe-actions";

import { EntityType } from "@mapmycustomers/shared/enum";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";

import MmcNotification from "@app/types/MmcNotification";

import {
  Actions,
  clearNotifications,
  clickOnNotification,
  fetchNotifications,
  fetchNotificationsUnreadTotal,
  hideCadenceModal,
  hideNotifications,
  showCadenceModal,
  showNotifications,
  updateNotificationReadStatus,
} from "./actions";

export interface NotificationState {
  cadenceModal: {
    entities: OutOfCadenceEntity[];
    loading: boolean;
    notification?: MmcNotification;
    visible: boolean;
  };
  loading: boolean;
  notifications: MmcNotification[];
  onlyMentions: boolean;
  total: number;
  unreadTotal: number;
  unreadUnfilteredTotal: number;
  visible: boolean;
}

const initialState: NotificationState = {
  cadenceModal: {
    entities: [],
    loading: false,
    notification: undefined,
    visible: false,
  },
  loading: false,
  notifications: [],
  onlyMentions: false,
  total: 0,
  unreadTotal: 0,
  unreadUnfilteredTotal: 0,
  visible: false,
};

const notification = createReducer<NotificationState, Actions>(initialState)
  .handleAction(
    fetchNotifications.request,
    (state, { payload: { onlyMentions, reload, updateTotalsOnly } }) => ({
      ...state,
      loading: !updateTotalsOnly,
      notifications:
        updateTotalsOnly || (!!onlyMentions === state.onlyMentions && !reload)
          ? state.notifications
          : [],
      onlyMentions: onlyMentions ?? state.onlyMentions,
    })
  )
  .handleAction(
    fetchNotifications.success,
    (state, { payload: { notifications, total, unreadTotal } }) => ({
      ...state,
      loading: false,
      notifications: [...state.notifications, ...notifications],
      total,
      unreadTotal,
    })
  )
  .handleAction(fetchNotifications.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchNotificationsUnreadTotal.success, (state, { payload }) => ({
    ...state,
    unreadUnfilteredTotal: payload,
  }))
  .handleAction(clearNotifications, (state) => ({
    ...state,
    notifications: [],
  }))
  .handleAction(showNotifications, (state) => ({
    ...state,
    visible: true,
  }))
  .handleAction(hideNotifications, (state) => ({
    ...state,
    visible: false,
  }))
  .handleAction(
    clickOnNotification,
    (
      state,
      {
        payload: {
          notification: { entity },
        },
      }
    ) => ({
      ...state,
      visible:
        entity && entity.length === 1 && entity[0].type === EntityType.ACTIVITY
          ? state.visible
          : false,
    })
  )
  .handleAction(updateNotificationReadStatus.request, (state, { payload: { id, readStatus } }) => ({
    ...state,
    notifications: state.notifications.map((notification) => ({
      ...notification,
      readStatus: id === notification.id ? readStatus : notification.readStatus,
    })),
  }))
  .handleAction(showCadenceModal.request, (state) => ({
    ...state,
    cadenceModal: {
      entities: [],
      loading: true,
      notification: undefined,
      visible: true,
    },
  }))
  .handleAction(showCadenceModal.success, (state, { payload: { entities, notification } }) => ({
    ...state,
    cadenceModal: {
      entities: entities,
      loading: false,
      notification,
      visible: true,
    },
  }))
  .handleAction([showCadenceModal.failure, hideCadenceModal], (state) => ({
    ...state,
    cadenceModal: {
      entities: [],
      loading: false,
      notification: undefined,
      visible: false,
    },
  }));

export * from "./selectors";
export type NotificationsActions = Actions;
export default notification;
