import React from "react";

import {
  faBold,
  faItalic,
  faList,
  faListOl,
  faStrikethrough,
  faUnderline,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";
import cn from "classnames";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import TextType from "@mapmycustomers/shared/enum/textEditor/TextType";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import FileUploadCallback from "../type/FileUploadCallback";

import InsertLink from "./InsertLink";
import styles from "./Toolbar.module.scss";
import ClearStyles from "./toolbar/ClearStyles";
import ElementAlignmentToggler from "./toolbar/ElementAlignmentToggler";
import ElementToggler from "./toolbar/ElementToggler";
import HistoryControl from "./toolbar/HistoryControl";
import IndentControl from "./toolbar/IndentControl";
import InsertImage from "./toolbar/InsertImage";
import TextColorToggler from "./toolbar/TextColorToggler";
import TextSizeSelector from "./toolbar/TextSizeSelector";
import TextToggler from "./toolbar/TextToggler";

interface Props {
  extraMargin?: boolean;
  onLoadFile: FileUploadCallback;
  showInsertLink?: boolean;
}

const Toolbar: React.FC<Props> = ({ extraMargin, onLoadFile, showInsertLink }) => {
  return (
    <div
      className={cn(styles.container, { [styles.extraMargin]: extraMargin })}
      onClick={stopEvents}
    >
      <TextSizeSelector />
      <Divider className={styles.divider} type="vertical" />
      <TextToggler
        tooltipTranslationString="ui.emailBody.toolbar.bold"
        value={{
          label: <FontAwesomeIcon icon={faBold} />,
          value: TextType.BOLD,
        }}
      />
      <TextToggler
        tooltipTranslationString="ui.emailBody.toolbar.italic"
        value={{
          label: <FontAwesomeIcon icon={faItalic} />,
          value: TextType.ITALIC,
        }}
      />
      <TextToggler
        tooltipTranslationString="ui.emailBody.toolbar.underline"
        value={{
          label: <FontAwesomeIcon icon={faUnderline} />,
          value: TextType.UNDERLINE,
        }}
      />
      <TextColorToggler />
      <Divider className={styles.divider} type="vertical" />
      <ElementAlignmentToggler />
      <ElementToggler
        tooltipTranslationString="ui.emailBody.toolbar.bulleted"
        value={{
          label: <FontAwesomeIcon icon={faList} />,
          value: ElementType.BULLETED_LIST,
        }}
      />
      <ElementToggler
        tooltipTranslationString="ui.emailBody.toolbar.numbered"
        value={{
          label: <FontAwesomeIcon icon={faListOl} />,
          value: ElementType.NUMBERED_LIST,
        }}
      />
      <Divider className={styles.divider} type="vertical" />
      <IndentControl offset={-1} />
      <IndentControl offset={1} />
      <Divider className={styles.divider} type="vertical" />
      <InsertImage onLoadFile={onLoadFile} />
      {showInsertLink && <InsertLink />}
      <Divider className={styles.divider} type="vertical" />
      <TextToggler
        tooltipTranslationString="ui.emailBody.toolbar.strikethrough"
        value={{
          label: <FontAwesomeIcon icon={faStrikethrough} />,
          value: TextType.STRIKE,
        }}
      />
      <Divider className={styles.divider} type="vertical" />
      <ClearStyles />
      <Divider className={styles.divider} type="vertical" />
      <HistoryControl />
      <HistoryControl redo />
    </div>
  );
};

export default Toolbar;
