import React, { ReactElement, ReactNode, useCallback } from "react";

import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import { ListWithOverflowItem as CommonListWithOverflowItem } from "@mapmycustomers/ui";

import styles from "./ListWithOverflowItem.module.scss";

interface Props {
  children: Array<ReactElement>;
  className?: string;
  overflowRender?: (hiddenItemsCount: number) => ReactNode;
}

const ListWithOverflowItem: React.FC<Props> = ({ children, className, overflowRender }) => {
  const handleOverflowRender = useCallback(
    (hiddenItemsCount: number) => {
      if (overflowRender) {
        return overflowRender(hiddenItemsCount);
      }

      const maxVisibleIndex = React.Children.count(children) - hiddenItemsCount - 1;
      return (
        <div
          className={cn(styles.overflowItem, {
            [styles.overflowHidden]: hiddenItemsCount === 0,
          })}
          key="invisible"
        >
          <Tag>
            <Tooltip
              title={React.Children.map(children, (child: ReactElement, index) =>
                index <= maxVisibleIndex ? (
                  <div key={child.key ?? index}>{React.cloneElement(child)}</div>
                ) : null
              )}
            >
              <FontAwesomeIcon className={styles.moreIcon} icon={faEllipsisH} />
            </Tooltip>
          </Tag>
        </div>
      );
    },
    [children, overflowRender]
  );

  return (
    <CommonListWithOverflowItem className={className} overflowRender={handleOverflowRender}>
      {children}
    </CommonListWithOverflowItem>
  );
};

export default ListWithOverflowItem;
