import { startOfDay } from "date-fns/esm";
import get from "lodash-es/get";

import ListResponseAggregation from "@app/types/viewModel/ListResponseAggregation";
import { parseApiDateAsNumber } from "@app/util/parsers";

const calculateNumberOfItemsPerDay = <T>(
  items: ListResponseAggregation<T>[],
  keyFieldPath: string,
  valueFieldPath: string
): Record<string, number> =>
  items.reduce((result: { [key: string]: number }, item) => {
    const dateKey = startOfDay(parseApiDateAsNumber(get(item, keyFieldPath)))
      .valueOf()
      .toString();
    return Object.assign(result, { [dateKey]: get(item, valueFieldPath, 0) });
  }, {});

export default calculateNumberOfItemsPerDay;
