import { createReducer } from "typesafe-actions";

import localSettings from "@app/config/LocalSettings";
import AlertBadgesType from "@app/enum/map/AlertBadgesType";

import {
  AlertBadgesActions,
  setAlertDismissed,
  setAlertsSettings,
  toggleAlertBadgeVisibility,
} from "./actions";
import AlertBadgesState from "./AlertBadgesState";

export const alertBadgesInitialState: AlertBadgesState = {
  alertDismissed: localSettings.getEngagementInfoAlertDismissed(),
  legendExpanded: false,
  visible: false,
  visibleBadges: [
    AlertBadgesType.OVERDUE,
    AlertBadgesType.OUT_OF_CADENCE_SOON,
    AlertBadgesType.UPCOMING_ACTIVITY,
  ],
};

const alertBadgesLayer = createReducer<AlertBadgesState, AlertBadgesActions>(
  alertBadgesInitialState
)
  .handleAction(toggleAlertBadgeVisibility, (state, { payload }) => ({
    ...state,
    visibleBadges: state.visibleBadges.includes(payload)
      ? state.visibleBadges.filter((type) => type !== payload)
      : [...state.visibleBadges, payload],
  }))
  .handleAction(setAlertsSettings, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setAlertDismissed, (state, { payload }) => {
    return {
      ...state,
      alertDismissed: payload.alertDismissed,
    };
  });

export default alertBadgesLayer;
