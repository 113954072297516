import isFunction from "lodash-es/isFunction";

import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import { MultiPin } from "@mapmycustomers/shared/types/map";

import MarkerEffect from "@app/enum/MarkerEffect";
import { PIN_ZINDEX } from "@app/util/map/consts";
import getResultingSvg from "@app/util/map/markerStyles/getResultingSvg";
import { getScaledSizeByMarker } from "@app/util/map/markerStyles/getScaledSize";
import getSvgEffect from "@app/util/map/markerStyles/getSvgEffect";
import getSvgForIcon from "@app/util/map/markerStyles/getSvgForIcon";
import getSvgForShape from "@app/util/map/markerStyles/getSvgForShape";
import ColorDefinition from "@app/util/map/markerStyles/types/ColorDefinition";
import svgToDataUrl from "@app/util/map/svgToDataUrl";

import defaultColorGetter from "./defaultColorGetter";

interface StyleArguments {
  colorGetter?: (multiPin: MultiPin) => ColorDefinition;
  effect?: ((entityPin: MultiPin) => MarkerEffect) | MarkerEffect;
  markerSize: MarkerSize;
  shape: ((multiPin: MultiPin) => MarkerShape) | MarkerShape;
  zIndex?: number;
}

const defaultMultiPinStyleGetter = ({
  colorGetter = defaultColorGetter,
  effect = MarkerEffect.SHADOW,
  markerSize,
  shape,
  zIndex = PIN_ZINDEX,
}: StyleArguments): ((item: MultiPin) => google.maps.Data.StyleOptions) => {
  return (multiPin: MultiPin) => {
    const colorDef = colorGetter(multiPin);

    const markerShape = isFunction(shape) ? shape(multiPin) : shape;
    const markerEffect = isFunction(effect) ? effect(multiPin) : effect;
    const iconSvg = getSvgForIcon(markerShape, "multipin", colorDef.color);

    const { shapeAnchor, shapeSize, shapeSvg } = getSvgForShape(
      markerShape,
      colorDef.fillColor,
      colorDef.color
    );

    const shapeEffect = getSvgEffect(markerEffect, shapeSize);

    const { anchor, size, svg } = getResultingSvg(
      shapeSvg,
      shapeSize,
      shapeAnchor,
      shapeEffect,
      iconSvg
    );

    return {
      icon: {
        anchor: new google.maps.Point(...getScaledSizeByMarker(anchor, markerSize)),
        scaledSize: new google.maps.Size(...getScaledSizeByMarker(size, markerSize)),
        url: svgToDataUrl(svg),
      },
      zIndex,
    };
  };
};

export default defaultMultiPinStyleGetter;
