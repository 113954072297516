import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import { OptionalFields } from "@mapmycustomers/shared";
import { Company, Person } from "@mapmycustomers/shared/types/entity";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { Alert, LoadingSpinner } from "@mapmycustomers/ui";

import ButtonLink from "@app/component/ButtonLink";

import RelateEntitiesRequestPayload from "../FormFields/components/Associations/RelateEntitiesRequestPayload";

import { messages } from "./messages";
import styles from "./RelatedEntitiesMatching.module.scss";

interface Props {
  associatedCompany?: Company;
  associatedPerson?: Person;
  entityRelatingSuccess: boolean;
  isEntityRelating: boolean;
  relateUnrelateEntities: (
    payload: OptionalFields<
      RelateEntitiesRequestPayload,
      "associatedDeal" | "isDealCorrectlyRelatedToCompany" | "isDealCorrectlyRelatedToPerson"
    >
  ) => void;
}

const RelatedEntitiesMatching: React.FC<Props> = ({
  associatedCompany,
  associatedPerson,
  entityRelatingSuccess,
  isEntityRelating,
  relateUnrelateEntities,
}) => {
  const intl = useIntl();

  const [visible, show, hide] = useBoolean();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [savedCompanyId, setSavedCompanyId] = useState<Company["id"] | undefined>();
  const [savedPersonId, setSavedPersonId] = useState<Person["id"] | undefined>();

  const isPersonCorrectlyRelatedToCompany =
    !associatedCompany ||
    !associatedPerson ||
    (associatedPerson?.accounts ?? []).some(({ id }) => associatedCompany?.id === id);

  useEffect(() => {
    setSavedCompanyId(associatedCompany?.id);
    setSavedPersonId(associatedPerson?.id);
  }, [associatedCompany?.id, associatedPerson?.id, savedCompanyId, savedPersonId]);

  const message = useMemo(() => {
    if (!isPersonCorrectlyRelatedToCompany) {
      return intl.formatMessage(messages.recommend);
    }
    if (entityRelatingSuccess) {
      return successMessage;
    }
    return "";
  }, [entityRelatingSuccess, intl, isPersonCorrectlyRelatedToCompany, successMessage]);

  useEffect(() => {
    if (!isPersonCorrectlyRelatedToCompany) {
      show();
      setSuccessMessage(intl.formatMessage(messages.nowRelated));
    } else {
      hide();
    }
    if (entityRelatingSuccess) {
      show();
    }
  }, [entityRelatingSuccess, hide, intl, isPersonCorrectlyRelatedToCompany, show]);

  const handleRelateEntities = useCallback(() => {
    relateUnrelateEntities({
      associatedCompany,
      associatedPerson,
      isPersonCorrectlyRelatedToCompany,
    });
  }, [
    associatedCompany,
    associatedPerson,
    isPersonCorrectlyRelatedToCompany,
    relateUnrelateEntities,
  ]);

  const handleUndo = useCallback(() => {
    relateUnrelateEntities({
      associatedCompany,
      associatedPerson,
      isPersonCorrectlyRelatedToCompany: true,
    });
  }, [associatedCompany, associatedPerson, relateUnrelateEntities]);

  if (!visible) {
    return null;
  }

  return (
    <Alert
      action={
        isEntityRelating ? (
          <LoadingSpinner micro />
        ) : entityRelatingSuccess ? (
          <ButtonLink onClick={handleUndo}>{intl.formatMessage(messages.undo)}</ButtonLink>
        ) : (
          <ButtonLink onClick={handleRelateEntities}>
            {intl.formatMessage(messages.relateRecords)}
          </ButtonLink>
        )
      }
      className={styles.container}
      closable={entityRelatingSuccess}
      message={message}
      onClose={hide}
      showIcon
      type={entityRelatingSuccess ? "success" : "info"}
    />
  );
};

export default RelatedEntitiesMatching;
