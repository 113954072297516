import { createReducer } from "typesafe-actions";

import { EntityType, EntityTypesSupportingSavedFilters } from "@mapmycustomers/shared/types/entity";
import SavedFilter from "@mapmycustomers/shared/types/viewModel/SavedFilter";

import {
  Actions,
  createSavedFilter,
  fetchSavedFilters,
  selectSavedFilter,
  updateSavedFilter,
} from "./actions";

type SavedFiltersState = Record<
  EntityTypesSupportingSavedFilters,
  { list: SavedFilter[]; loading: boolean; selectedFilter?: SavedFilter }
> & {
  createLoading: boolean;
  updateLoading: boolean;
};

const initialState: SavedFiltersState = {
  createLoading: false,
  [EntityType.ACTIVITY]: { list: [], loading: false },
  [EntityType.COMPANY]: { list: [], loading: false },
  [EntityType.DEAL]: { list: [], loading: false },
  [EntityType.PERSON]: { list: [], loading: false },
  [EntityType.PIN]: { list: [], loading: false },
  updateLoading: false,
};

const savedFilters = createReducer<SavedFiltersState, Actions>(initialState)
  .handleAction(createSavedFilter.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction(createSavedFilter.success, (state) => ({
    ...state,
    createLoading: false,
  }))
  .handleAction(createSavedFilter.failure, (state) => ({
    ...state,
    createLoading: false,
  }))
  .handleAction(updateSavedFilter.request, (state) => ({
    ...state,
    updateLoading: true,
  }))
  .handleAction(updateSavedFilter.success, (state) => ({
    ...state,
    updateLoading: false,
  }))
  .handleAction(updateSavedFilter.failure, (state) => ({
    ...state,
    updateLoading: false,
  }))
  .handleAction(fetchSavedFilters.request, (state, { payload }) => ({
    ...state,
    [payload.entityType]: { ...state[payload.entityType], list: [], loading: true },
  }))
  .handleAction(fetchSavedFilters.success, (state, { payload: { entityType, savedFilters } }) => ({
    ...state,
    [entityType]: { ...state[entityType], list: savedFilters, loading: false },
  }))
  .handleAction(fetchSavedFilters.failure, (state, { payload }) => ({
    ...state,
    [payload]: { ...state[payload], list: [], loading: false },
  }))
  .handleAction(selectSavedFilter, (state, { payload: { entityType, savedFilter } }) => ({
    ...state,
    [entityType]: { ...state[entityType], selectedFilter: savedFilter },
  }));

export * from "./selectors";
export type SavedFiltersActions = Actions;
export default savedFilters;
