import React, { useCallback, useMemo } from "react";

import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "antd/es/select";
import cn from "classnames";
import sortBy from "lodash-es/sortBy";
import { useIntl } from "react-intl";

import User, { UserRef } from "@mapmycustomers/shared/types/User";
import { AvatarWithName, SelectField, SelectFieldProps } from "@mapmycustomers/ui";

import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import { userDisplayName } from "@app/util/formatters";

import styles from "./UserSelectField.module.scss";

const DEFAULT_OPTION_KEY = -1;
const Option = Select.Option;

interface Props
  extends Omit<
    SelectFieldProps<User["id"]>,
    "children" | "className" | "onChange" | "options" | "value"
  > {
  className?: string;
  defaultUsersOption?: boolean | string;
  hideAllUsersIcon?: boolean;
  label?: boolean | string;
  onChange?: (user: undefined | User) => void;
  users: User[];
  value?: User | UserRef;
}

const UserSelectField: React.FC<Props> = ({
  className,
  defaultUsersOption = true,
  hideAllUsersIcon,
  label,
  onChange,
  users,
  value,
  ...props
}) => {
  const intl = useIntl();

  const handleSelectUser = useCallback(
    (userId: User["id"]) => {
      onChange?.(
        userId === DEFAULT_OPTION_KEY ? undefined : users.find((user) => user.id === userId)
      );
    },
    [onChange, users]
  );

  const sortedUsers = useMemo(() => sortBy(users, [userDisplayName]), [users]);

  return (
    <SelectField<User["id"]>
      className={cn(styles.container, className)}
      cypressId="user-select-field"
      dropdownMatchSelectWidth={false}
      filterOption={defaultFilterOption}
      label={
        label === false
          ? undefined
          : label ??
            intl.formatMessage({
              id: "userSelectField.label",
              defaultMessage: "Select User",
              description: "Default label of generic User Select field",
            })
      }
      onChange={handleSelectUser}
      showSearch
      value={value?.id ?? (defaultUsersOption ? DEFAULT_OPTION_KEY : undefined)}
      {...props}
    >
      {defaultUsersOption && (
        <Option
          className={styles.allUsersOption}
          key={DEFAULT_OPTION_KEY}
          value={DEFAULT_OPTION_KEY}
        >
          {!hideAllUsersIcon && <FontAwesomeIcon className={styles.allUsersIcon} icon={faUsers} />}
          {typeof defaultUsersOption === "string"
            ? defaultUsersOption
            : intl.formatMessage({
                id: "userSelectField.allUsers",
                defaultMessage: "All Users",
                description: "All Users option name in a generic User Select field",
              })}
        </Option>
      )}
      {/* If owner/assignee on any record is `Deleted/Deactivated` */}
      {value && !users.find((user) => user.id === value.id) && (
        <Option key={value.id} value={value.id}>
          <AvatarWithName avatarClassName={styles.avatar} user={value} />
        </Option>
      )}
      {sortedUsers.map((user) => (
        <Option key={user.id} label={userDisplayName(user)} value={user.id}>
          <AvatarWithName avatarClassName={styles.avatar} user={user} />
        </Option>
      ))}
    </SelectField>
  );
};

export default UserSelectField;
