import { put, select, takeEvery } from "redux-saga/effects";

import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization } from "@app/store/iam";

import {
  createDealLossReason,
  deleteDealLossReason,
  fetchDealLossReasons,
  updateDealLossReason,
} from "./actions";
import { getDealLossReasons } from "./selectors";

export function* onFetchDealLossReasons({
  payload: funnelId,
}: ReturnType<typeof fetchDealLossReasons.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const dealLossReasons: ListResponse<DealLossReason> = yield callApi(
      "fetchDealLossReasons",
      org.id
    );

    let lossReasons = dealLossReasons.data;
    if (funnelId) {
      lossReasons = dealLossReasons.data.filter((lossReason: DealLossReason) => {
        if (!lossReason.funnelId || lossReason.funnelId.length === 0) {
          return true;
        }

        return lossReason.funnelId.includes(funnelId);
      });
    }
    yield put(fetchDealLossReasons.success(lossReasons));
  } catch (error) {
    yield put(fetchDealLossReasons.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateDealLossReason({
  payload,
}: ReturnType<typeof createDealLossReason.request>) {
  const { callback, reason } = payload;
  try {
    const org: Organization = yield select(getOrganization);
    const lossReasons: DealLossReason[] = yield select(getDealLossReasons);
    const createdDealLossReason: DealLossReason = yield callApi("createDealLossReason", org.id, {
      ...reason,
      displayOrder: reason.displayOrder ?? lossReasons.length,
    });
    callback?.(createdDealLossReason);
    yield put(createDealLossReason.success(createdDealLossReason));
    yield put(fetchDealLossReasons.request());
  } catch (error) {
    yield put(createDealLossReason.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteDealLossReason({
  payload: dealLossReasonId,
}: ReturnType<typeof deleteDealLossReason.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteDealLossReason", org.id, dealLossReasonId);
    yield put(deleteDealLossReason.success(dealLossReasonId));
    yield put(fetchDealLossReasons.request());
  } catch (error) {
    yield put(deleteDealLossReason.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateDealLossReason({
  payload: lossReason,
}: ReturnType<typeof updateDealLossReason.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const updatedDealLossReason: DealLossReason = yield callApi(
      "updateDealLossReason",
      org.id,
      lossReason
    );
    yield put(updateDealLossReason.success(updatedDealLossReason));
  } catch (error) {
    yield put(updateDealLossReason.failure());
    yield put(handleError({ error }));
  }
}

export function* dealLossReasonsSaga() {
  yield takeEvery(createDealLossReason.request, onCreateDealLossReason);
  yield takeEvery(updateDealLossReason.request, onUpdateDealLossReason);
  yield takeEvery(deleteDealLossReason.request, onDeleteDealLossReason);
  yield takeEvery(fetchDealLossReasons.request, onFetchDealLossReasons);
}
