import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { MapEntity, Territory } from "@mapmycustomers/shared/types/entity";
import { CategorizedMapEntries } from "@mapmycustomers/shared/types/map";
import { GeoMultiPolygon, GeoPolygon } from "@mapmycustomers/shared/types/shapes";
import AreaRegion from "@mapmycustomers/shared/types/territory/AreaRegion";
import AreaType from "@mapmycustomers/shared/types/territory/AreaType";
import SmartTerritoryConfig from "@mapmycustomers/shared/types/territory/SmartTerritoryConfig";
import User from "@mapmycustomers/shared/types/User";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";

import DataViewEntityTypeCount from "@app/component/DataView/type/DataViewEntityTypeCount";
import TerritoryModeState from "@app/scene/map/store/territoryMode/TerritoryModeState";
import { MapFetcherPayload } from "@app/scene/map/types/MapFetcher";
import DownloadEntitiesMapping from "@app/types/DownloadEntitiesMapping";
import MapViewportState from "@app/types/map/MapViewportState";

export const enterTerritoryMode = createAction("map/territory/enter")<{
  areaType?: AreaType; // used when creating smart territory
  territory?: Territory; // useful to specify if you do have territory already, helps UI to update faster
  territoryId: "create" | Territory["id"];
}>();

export const exitTerritoryMode = createAsyncAction(
  "map/territory/exit/request",
  "map/territory/exit/success",
  "map/territory/exit/failure"
)<true | void, void, void>(); // true when we wanna force-exit, usually after leave was approved by user

export const initializeTerritoryMode = createAsyncAction(
  "map/territory/initialize/request",
  "map/territory/initialize/success",
  "map/territory/initialize/failure"
)<
  {
    callback?: (bounds: google.maps.LatLngBounds) => void;
    territoryId?: Territory["id"];
    viewport: MapViewportState;
  },
  {
    territory?: Territory;
  },
  void
>();

export const applyTerritoryMapViewSettings = createAction("map/territory/applyMapViewSettings")<
  Partial<MapViewState>
>();

export const fetchTerritoryPins = createAsyncAction(
  "map/territory/fetchPins/request",
  "map/territory/fetchPins/success",
  "map/territory/fetchPins/failure"
)<
  MapFetcherPayload,
  CategorizedMapEntries & {
    externalEntries: CategorizedMapEntries;
    pinsCount: number;
  },
  unknown
>();

export const fetchTerritoryRecords = createAsyncAction(
  "map/territory/fetchRecords/request",
  "map/territory/fetchRecords/success",
  "map/territory/fetchRecords/failure"
)<
  Partial<Pick<MapViewState, "range" | "search" | "sort">>,
  {
    records: MapEntity[];
    recordsCount: number;
  },
  unknown
>();

export const updateTerritory = createAsyncAction(
  "map/territory/updateTerritory/request",
  "map/territory/updateTerritory/success",
  "map/territory/updateTerritory/failure"
)<
  {
    onSuccess?: (updatedTerritory: Territory) => void;
    shape?: GeoMultiPolygon | GeoPolygon;
    territory: Territory;
  },
  Territory,
  void
>();

export const updateTerritorySharing = createAsyncAction(
  "map/territory/updateTerritorySharing/request",
  "map/territory/updateTerritorySharing/success",
  "map/territory/updateTerritorySharing/failure"
)<
  {
    onSuccess?: (updatedTerritory: Territory) => void;
    territory: Territory;
    userIdsToShareWith: User["id"][];
  },
  Territory,
  void
>();

export const setTerritoryEditFormHasChanges = createAction(
  "map/territory/setEditFormHasChanges"
)<boolean>();

export const countPinsInTerritory = createAsyncAction(
  "map/territory/countPinsInTerritory/request",
  "map/territory/countPinsInTerritory/success",
  "map/territory/countPinsInTerritory/failure"
)<
  | { areaType: AreaType; regionIds: AreaRegion["id"][] } // either path for drawn territories or selected regions for smart ones
  | LongLat[][],
  number,
  void
>();

export const setTerritoryMode = createAction("map/territory/setTerritoryMode")<
  TerritoryModeState["mode"]
>();

export const toggleDataViewVisibility = createAction(
  "map/territory/toggleDataViewVisibility"
)<void>();

export const updateDataViewEntityTypeCount = createAsyncAction(
  "map/territory/updateDataViewEntityTypeCount/request",
  "map/territory/updateDataViewEntityTypeCount/success",
  "map/territory/updateDataViewEntityTypeCount/failure"
)<
  void,
  {
    entityTypeCount: DataViewEntityTypeCount;
  },
  void
>();

export const setDataViewData = createAction("map/territory/setDataViewData")<
  LongLat[][] | SmartTerritoryConfig
>();

export const fetchTerritoryLassoSelection = createAsyncAction(
  "map/territory/fetchTerritoryLassoSelection/request",
  "map/territory/fetchTerritoryLassoSelection/success",
  "map/territory/fetchTerritoryLassoSelection/failure"
)<
  MapFetcherPayload,
  {
    records: MapEntity[];
  },
  unknown
>();
export const exitTerritoryLassoMode = createAction("map/territory/exitTerritoryLassoMode")<void>();

export const downloadTerritoryRecords = createAction(
  "map/territory/downloadTerritoryRecords"
)<DownloadEntitiesMapping>();

export type TerritoryModeActions = ActionType<
  | typeof applyTerritoryMapViewSettings
  | typeof countPinsInTerritory
  | typeof countPinsInTerritory
  | typeof countPinsInTerritory
  | typeof downloadTerritoryRecords
  | typeof enterTerritoryMode
  | typeof exitTerritoryLassoMode
  | typeof exitTerritoryMode
  | typeof fetchTerritoryLassoSelection
  | typeof fetchTerritoryPins
  | typeof fetchTerritoryRecords
  | typeof initializeTerritoryMode
  | typeof setDataViewData
  | typeof setTerritoryEditFormHasChanges
  | typeof setTerritoryMode
  | typeof setTerritoryMode
  | typeof toggleDataViewVisibility
  | typeof updateDataViewEntityTypeCount
  | typeof updateTerritory
  | typeof updateTerritorySharing
>;
