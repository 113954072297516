import React, { ReactNode, useEffect, useMemo, useRef } from "react";

import cn from "classnames";

import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";

import { FeatureType } from "@app/component/map/FeaturedMap/types";
import IMapContext from "@app/component/map/FeaturedMap/types/IMapContext";
import { FEATURE_TYPE_PROPERTY } from "@app/component/map/FeaturedMap/utils/consts";
import MapContext from "@app/component/map/FeaturedMap/utils/MapContext";

type Feature = google.maps.Data.Feature;
type StyleOptions = google.maps.Data.StyleOptions;
type StylingFunction = google.maps.Data.StylingFunction;

interface Props {
  children: ReactNode;
  className?: string;
  map: google.maps.Map;
}

const MapContent: React.FC<Props> = ({ children, className, map }) => {
  const stylesMap = useRef<Map<FeatureType, StylingFunction>>(new Map());

  // This redrawer forces google maps to re-render.
  // Basically, we need to redraw when style function is changed
  // Previously we stored a stylesMap in local state and hence were able to trigger useEffect
  // when map was changed and re-set style function by calling map.data.setStyle(...)
  // This still works, but when you do so, google maps tend to re-render all features.
  // Whereas we'd prefer to only re-render features of the type which was changed.
  // That's the purpose of this redrawer.
  // Re-rendering only a subset of features usually works faster, so this is a
  // performance optimization.
  // Also, using a debounced callback here, to avoid doing any map.data.* calls if there will be
  // a successive call to redrawDataLayer soon. Ideally, we should debounce by type arg.
  // But I hope this is good enough for now. However, if it will create problems, we should
  // try using memoized version of debounce: https://github.com/lodash/lodash/issues/2403#issuecomment-1158234729
  const redrawDataLayer = useDebouncedCallback([
    (type?: string) => {
      if (!type) {
        // redraw full map
        map.data.setMap(map);
      } else {
        map.data.forEach((feature) => {
          const featureType = feature.getProperty(FEATURE_TYPE_PROPERTY);
          if (featureType !== type) {
            return;
          }
          map.data.overrideStyle(feature, {});
        });
      }
    },
    100,
  ]);

  // Initialize styling function once
  useEffect(() => {
    map.data.setStyle((feature: Feature): StyleOptions => {
      const type = feature.getProperty(FEATURE_TYPE_PROPERTY) as FeatureType;
      const styler = stylesMap.current.get(type);
      return styler?.(feature) ?? {};
    });
  }, [map]);

  const context: IMapContext = useMemo(() => {
    return {
      map,
      registerStylingFunction: (type: FeatureType, styler: google.maps.Data.StylingFunction) => {
        stylesMap.current = new Map([...Array.from(stylesMap.current), [type, styler]]);
        redrawDataLayer(type);
      },
      unregisterStylingFunction: (type: FeatureType) => {
        stylesMap.current = new Map(Array.from(stylesMap.current).filter(([t]) => t !== type));
        redrawDataLayer(type);
      },
    };
  }, [map, redrawDataLayer]);

  return (
    <MapContext.Provider value={context}>
      <div className={cn("mmc-map-content", className)}>{children}</div>
    </MapContext.Provider>
  );
};

export default MapContent;
