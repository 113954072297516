import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import NewRecordsCardConfiguration from "@app/types/dashboard/cards/newRecords/NewRecordsCardConfiguration";
import Scope from "@app/types/dashboard/Scope";

import NewRecordsCardData from "./NewRecordsCardData";

export const fetchNewRecordsCardData = createAction("@dashboard/fetchNewRecordsCardData")<{
  callback: (data: NewRecordsCardData) => void;
  configuration: NewRecordsCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showNewRecordsDrillDown = createAsyncAction(
  "@dashboard/showNewRecordsCardDrillDownModal/request",
  "@dashboard/showNewRecordsCardDrillDownModal/success",
  "@dashboard/showNewRecordsCardDrillDownModal/failure"
)<
  {
    configuration: NewRecordsCardConfiguration;
    customDateRange?: DateRange;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideNewRecordsDrillDown = createAction(
  "@dashboard/hideNewRecordsCardDrillDownModal"
)<void>();

export const fetchNewRecordsCardDrillDownData = createAsyncAction(
  "@dashboard/fetchNewRecordsCardDrillDownData/request",
  "@dashboard/fetchNewRecordsCardDrillDownData/success",
  "@dashboard/fetchNewRecordsCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<AnyEntity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyNewRecordsDrillDownListViewSettings = createAction(
  "@dashboard/applyNewRecordsDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportNewRecordsCardDrillDownData = createAction(
  "@dashboard/exportNewRecordsCardData"
)<{
  configuration: NewRecordsCardConfiguration;
  scope: Scope;
}>();

export type NewRecordsCardActions = ActionType<
  | typeof applyNewRecordsDrillDownListViewSettings
  | typeof exportNewRecordsCardDrillDownData
  | typeof fetchNewRecordsCardData
  | typeof fetchNewRecordsCardDrillDownData
  | typeof hideNewRecordsDrillDown
  | typeof showNewRecordsDrillDown
>;
