import ExportType from "./ExportType";

const getHeaderRow = (exportType: ExportType): string[] => {
  switch (exportType) {
    case ExportType.ACTIVITIES:
      return [
        "Title",
        "Assignee Email",
        "Assignee Name",
        "Assignee Team Name",
        "Completed",
        "CompletedAt",
        "CRMActivityType",
        "Note",
        "StartDate",
        "StartTime",
        "EndDate",
        "EndTime",
        "Creator Name",
        "Creator Email",
        "Creator Team Name",
        "Verified",
        "Company",
        "People",
        "Location at Check-In",
        "Check-In Distance Delta",
        "Teams",
      ];

    case ExportType.COMPANIES:
      return [
        "Title",
        "AnnualRevenue",
        "Website",
        "Phone",
        "NumEmployees",
        "Address",
        "City",
        "State",
        "Country",
        "Owner Name",
        "Owner Email",
        "Teams",
      ];

    case ExportType.DEALS:
      return [
        "Title",
        "Amount",
        "Adjusted Amount",
        "Owner Name",
        "Owner Email",
        "Stage",
        "Stage Color",
        "Closing Date",
        "Created At",
        "Associated Record",
        "Record Name",
        "Record Address",
        "Record City",
        "Record State",
        "Record Zip",
        "Record Id",
        "Teams",
      ];

    case ExportType.PEOPLE:
      return [
        "First Name",
        "Last Name",
        "Phone",
        "Email",
        "Address",
        "City",
        "State",
        "Country",
        "Owner Name",
        "Owner Email",
        "Teams",
      ];

    case ExportType.ROUTES:
      return ["Title", "StartAddress", "EndAddress", "Created At", "Username"];
  }
};

export default getHeaderRow;
