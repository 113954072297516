import React from "react";

import Modal from "antd/es/modal";
import cn from "classnames";
import { defineMessages, IntlShape } from "react-intl";

import { LeadFinderLimit } from "@app/scene/map/store/leadFinder/LeadFinderState";

import styles from "./ShowLeadFinderLimitAlert.module.scss";

export const FIRST_WARNING_LIMIT = 75;
export const SECOND_WARNING_LIMIT = 90;
export const THIRD_WARNING_LIMIT = 95;

const messages = defineMessages({
  cancelText: {
    id: "map.tool.leadFinder.limitAlert.cancel",
    defaultMessage: "Don’t Search",
    description: "Cancel text for lead finder limit modal",
  },
  exit: {
    id: "map.tool.leadFinder.limitAlert.exit",
    defaultMessage: "Exit Lead Finder",
    description: "Exit text for lead finder limit modal",
  },
  increaseLimit: {
    id: "map.tool.leadFinder.limitAlert.increaseLimit",
    defaultMessage:
      "<b>To increase your {isOwner, select, true {team’s } other {}}monthly search count, please contact your {isOwner, select, true {MMC account manager} other {administrator}}.</b>",
    description: "Help text to increase monthy limit",
  },
  noSearches: {
    id: "map.tool.leadFinder.limitAlert.noSearches",
    defaultMessage: "No searches remain",
    description: "No searches remaining text",
  },
  noSearchLeftTitle: {
    id: "map.tool.leadFinder.limitAlert.noSearchLeftTitle",
    defaultMessage: "You’re out of Lead Finder searches for the month",
    description: "No searches remaining title",
  },
  orgLimitTitle: {
    id: "map.tool.leadFinder.limitAlert.orgLimitTitle",
    defaultMessage:
      "{value, number, ::percent} of your organization’s monthly Lead Finder searches have been used",
    description: "Warning title for org limit reached",
  },
  proceed: {
    id: "map.tool.leadFinder.limitAlert.proceed",
    defaultMessage: "Proceed",
    description: "Proceed text for lead finder limit modal",
  },
  remainingSearches: {
    id: "map.tool.leadFinder.limitAlert.remainingSearches",
    defaultMessage: "{remainingSearches} of {totalLimit} searches remain",
    description: "Remaining searches description",
  },
  searchLimit: {
    id: "map.tool.leadFinder.limitAlert.searchLimit",
    defaultMessage:
      "Searches are limited to {totalLimit}/{isOrgLimitReached, select, true {month} other {user/month}} and reset on the 1st of each month. {firstWarning, select, true {When you've used up 90% of your searches for the month, we’ll notify you again.} other {}}",
    description: "Lead finder search limit description",
  },
  tip: {
    id: "map.tool.leadFinder.limitAlert.tip",
    defaultMessage: `Tip: When you click on "Get more results in this area," it also counts towards your monthly search limit.`,
    description: "Search tip",
  },
  userLimitTitle: {
    id: "map.tool.leadFinder.limitAlert.userLimitTitle",
    defaultMessage: "You’ve used {value, number, ::percent} of your monthly Lead Finder searches",
    description: "Warning title for user limit reached",
  },
});

const showLeadFinderLimitAlert = (
  intl: IntlShape,
  { orgLimit, userLimit }: LeadFinderLimit,
  isOwner: boolean,
  onSearch: (value: string) => void,
  value: string
) => {
  const handleOk = () => {
    onSearch(value);
  };
  const isOrgLimitReached =
    orgLimit.percentageConsumed >= FIRST_WARNING_LIMIT && orgLimit.limit !== 0;
  const totalLimit = isOrgLimitReached ? orgLimit.limit : userLimit.limit;
  const remainingSearches = isOrgLimitReached
    ? orgLimit.remainingSearches
    : userLimit.remainingSearches;
  const isTotalLimitReached = remainingSearches <= 0;
  const percentUsed = ((totalLimit - remainingSearches) / totalLimit) * 100;

  Modal.confirm({
    cancelText: intl?.formatMessage(isTotalLimitReached ? messages.exit : messages.cancelText),
    className: cn(styles.alert, { [styles.error]: isTotalLimitReached }),
    content: (
      <div className={styles.container}>
        <span
          className={cn(styles.remaining, {
            [styles.noSearches]: isTotalLimitReached,
            [styles.warn]: percentUsed >= SECOND_WARNING_LIMIT,
          })}
        >
          {intl?.formatMessage(
            !isTotalLimitReached ? messages.remainingSearches : messages.noSearches,
            { remainingSearches, totalLimit }
          )}
        </span>
        <div className={styles.content}>
          <span>
            {intl?.formatMessage(messages.searchLimit, {
              firstWarning: percentUsed < SECOND_WARNING_LIMIT,
              isOrgLimitReached,
              totalLimit,
            })}
          </span>
          {!isTotalLimitReached && <span>{intl?.formatMessage(messages.tip)}</span>}
          <span>
            {intl?.formatMessage(messages.increaseLimit, {
              b: (text) => <b>{text}</b>,
              isOwner,
            })}
          </span>
        </div>
      </div>
    ),
    okButtonProps: { hidden: isTotalLimitReached },
    okText: intl?.formatMessage(messages.proceed),
    onOk: handleOk,
    title: intl?.formatMessage(
      isTotalLimitReached
        ? messages.noSearchLeftTitle
        : isOrgLimitReached
        ? messages.orgLimitTitle
        : messages.userLimitTitle,
      {
        value: percentUsed / 100,
      }
    ),
    wrapClassName: styles.alertWrapper,
  });
};

export default showLeadFinderLimitAlert;
