import { defineMessages } from "react-intl";

enum MapMode {
  GROUPS = "groupsMode",
  LEAD_FINDER = "leadFinderMode",
  TERRITORIES = "territoriesMode",
}

export const displayNames = defineMessages({
  [MapMode.GROUPS]: {
    id: "map.mode.group",
    defaultMessage: "Group",
    description: "Title of the Group map mode",
  },
  [MapMode.LEAD_FINDER]: {
    id: "map.mode.leadFinder",
    defaultMessage: "Lead Finder",
    description: "Title of the Lead Finder map mode",
  },
  [MapMode.TERRITORIES]: {
    id: "map.mode.territory",
    defaultMessage: "Territory",
    description: "Title of the Territory map mode",
  },
});

export default MapMode;
