import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { CANCEL } from "redux-saga";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";

export type RequestOptions = { noAuth?: boolean } & AxiosRequestConfig;

export default abstract class BaseApiService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  protected get(url: string, { noAuth = false, ...options }: RequestOptions = {}) {
    const { cancel, config } = this.processOptions(options, { noAuth });
    return this.processResponse(this.httpClient.get(url, config), cancel);
  }

  protected post(url: string, data: any, { noAuth = false, ...options }: RequestOptions = {}) {
    const { cancel, config } = this.processOptions(options, { noAuth });
    return this.processResponse(this.httpClient.post(url, data, config), cancel);
  }

  protected patch(url: string, data: any, { noAuth = false, ...options }: RequestOptions = {}) {
    const { cancel, config } = this.processOptions(options, { noAuth });
    return this.processResponse(this.httpClient.patch(url, data, config), cancel);
  }

  protected put(url: string, data: any, { noAuth = false, ...options }: RequestOptions = {}) {
    const { cancel, config } = this.processOptions(options, { noAuth });
    return this.processResponse(this.httpClient.put(url, data, config), cancel);
  }

  protected delete(url: string, { noAuth = false, ...options }: RequestOptions = {}) {
    const { cancel, config } = this.processOptions(options, { noAuth });
    return this.processResponse(this.httpClient.delete(url, config), cancel);
  }

  protected uploadFileAsFormData(
    url: string,
    file: Blob | File,
    formData: FormData = new FormData(),
    { noAuth = false, ...options }: RequestOptions = {}
  ) {
    const { cancel, config } = this.processOptions(options, { noAuth });
    formData.append("file", file);
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
      "x-mmc-client": "WEB_2",
      "x-ms-blob-type": "BlockBlob",
    };
    // we don't limit timeout for file uploads because user can be on a slow connection,
    // but we still wanna let them to upload a file no matter what
    config.timeout = 0;
    return this.processResponse(this.httpClient.post(url, formData, config), cancel);
  }

  private processOptions(
    { data, ...options }: AxiosRequestConfig,
    { noAuth }: { noAuth?: boolean } = {}
  ) {
    const config: AxiosRequestConfig = { headers: {}, ...options };

    let cancel;
    if (!config.cancelToken) {
      const source = axios.CancelToken.source();
      config.cancelToken = source.token;
      cancel = source.cancel;
    }

    if (!noAuth) {
      const authToken = this.getAuthToken();
      if (authToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${authToken}`,
        };
      }
    }

    // add default headers
    config.headers = {
      "Content-Type": "application/json",
      // "Accept-Encoding": "gzip", -- no need to specify this header, browser will specify it for you
      "x-compression": true,
      "x-mmc-client": "WEB_2",
      ...config.headers,
    };

    config.data = data;

    return { cancel, config };
  }

  private processResponse(promise: Promise<AxiosResponse>, cancel?: any) {
    const response: any = promise
      .then((reply) => ({ reply }))
      .catch((error) => (axios.isCancel(error) ? {} : { error: this.processError(error) }))
      .then(({ error, reply }: { error?: ApiError; reply?: AxiosResponse }) => {
        if (error) {
          throw error;
        }
        return reply ? reply.data : undefined;
      });
    if (cancel) {
      response[CANCEL] = cancel;
    }
    return response;
  }

  private processError(error: { response: AxiosResponse } | Error): ApiError | undefined {
    const response = (error as { response: AxiosResponse }).response;
    const status = response && response.status;
    const data = response && response.data;
    const result: ApiError =
      (data && this.errorFromData(data)) ||
      (status && this.errorFromStatus(status)) ||
      (error instanceof Error && new ApiError(error.message));

    if (result && !result.status) {
      result.status = status;
    }
    return result;
  }

  private errorFromData(data: any | string): ApiError {
    // eslint-disable-line class-methods-use-this
    if (typeof data === "string") {
      return new ApiError(data);
    }
    return new ApiError(data.message || "Server error", data.errors || data);
  }

  private errorFromStatus(status: number): ApiError {
    switch (status) {
      case 401:
        return new ApiError("Authentication failed");
      default:
        return new ApiError("Unknown error");
    }
  }

  protected abstract getAuthToken(): string | undefined;
}
