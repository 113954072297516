import { put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { EntityType } from "@mapmycustomers/shared/enum";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import localSettings from "@app/config/LocalSettings";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganization } from "@app/store/iam";
import { initializeSavedFiltersForView } from "@app/store/savedFilters/actions";
import companyFieldModel from "@app/util/fieldModel/CompanyFieldModel";
import loggingService from "@app/util/logging";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import { applyListViewSettings, downloadList, fetchList, initializeListView } from "./actions";
import { getListViewState, getTotalFilteredRecordsCount } from "./selectors";

export function* onInitializeListView() {
  yield put(
    initializeSavedFiltersForView({ entityType: EntityType.COMPANY, viewKey: "company/listView" })
  );
  yield put(initializeListView.success());
}

export function* onFetchList({ payload }: ReturnType<typeof fetchList.request>) {
  try {
    loggingService.debug("company store: onFetchList", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyListViewSettings(payload.request));
    }

    const listViewState: ViewState = yield select(getListViewState);

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(listViewState, "company/listView");
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : listViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : listViewState.range.endRow - listViewState.range.startRow;

    const organization: Organization = yield select(getOrganization);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : listViewState.filter,
          listViewState.columns,
          companyFieldModel,
          true,
          listViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(listViewState.sort),
    };
    const response: ListResponse<Company> = yield callApi(
      "fetchCompanies",
      organization.id,
      requestPayload
    );
    payload.dataCallback?.(response);
    yield put(
      fetchList.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchList.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDownloadList({ payload: { columns } }: ReturnType<typeof downloadList>) {
  const listViewState: ViewState = yield select(getListViewState);
  const total: number = yield select(getTotalFilteredRecordsCount);
  yield put(
    exportEntities.request({
      entityType: EntityType.COMPANY,
      total,
      viewState: { ...listViewState, columns },
    })
  );
}

export function* companySaga() {
  yield takeLatest(initializeListView.request, onInitializeListView);
  yield takeEvery(fetchList.request, onFetchList);
  yield takeEvery(downloadList, onDownloadList);
}
