import configService from "../config/ConfigService";
import localSettings from "../config/LocalSettings";
// This file contains code needed to be executed when app starts
// It's moved from the index.tsx file to shorten it
import authService from "../store/auth/AuthService";

/**
 * Extracts token from the URL if it is present there, saves it, and removes query
 * from the URL.
 */
export const readTokenFromUrlIfPresent = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("mmc_token")) {
    const token = urlParams.get("mmc_token") as string;
    if (token !== authService.getToken()) {
      authService.setToken(token);
    }
    // delete mmc_token from the url and replace state
    urlParams.delete("mmc_token");
    window.history.replaceState(
      {},
      document.title,
      `${window.location.origin}${window.location.pathname}?${urlParams.toString()}${
        window.location.hash
      }`
    );
  }
};

/**
 * Logouts and redirects to the login page if mmc_logout param is present.
 */
export const logoutIfAskedFor = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("mmc_logout")) {
    authService.resetSession();
    window.history.replaceState({}, document.title, configService.getLogoutUrl());
  }
};

/**
 * Remembers mmc_redirect parameter to redirect to after logging in.
 */
export const rememberRedirectLink = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("mmc_redirect")) {
    const redirect = urlParams.get("mmc_redirect")!;
    localSettings.setLocation(redirect);
  }
};

/**
 * Remembers if app was opened from the mobile app.
 */
export const checkIfOpenedFromMobileApp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("mmc_mobile")) {
    localSettings.setOpenedFromMobileApp(true);
  }
};
