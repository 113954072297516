import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import RecordPreviewConfiguration from "./RecordPreviewConfiguration";

export const updateRecordsPreviewConfiguration = createAsyncAction(
  "@recordPreview/updateRecordsPreviewConfiguration/request",
  "@recordPreview/updateRecordsPreviewConfiguration/success",
  "@recordPreview/updateRecordsPreviewConfiguration/failure"
)<Partial<RecordPreviewConfiguration>, void, void>();

export const setRecordsPreviewConfiguration = createAction(
  "@recordPreview/setRecordsPreviewConfiguration"
)<Partial<RecordPreviewConfiguration>>();

export type Actions = ActionType<
  typeof setRecordsPreviewConfiguration | typeof updateRecordsPreviewConfiguration
>;
