import unescape from "lodash-es/unescape";

import CountryCode from "../../enum/CountryCode";
import { GeoPoint } from "../../types";
import Address from "../../types/Address";
import LongLat from "../../types/base/LongLat";
import { BasePin } from "../../types/map";
import PlatformBounds from "../../types/map/PlatformBounds";
import { findRegionByName } from "../regions/lookup";

type LatLngLiteral = google.maps.LatLngLiteral;

export const createCoordinates = (
  coordinates: Partial<GeolocationCoordinates>
): GeolocationCoordinates => ({
  accuracy: 0,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  latitude: 0,
  longitude: 0,
  speed: null,
  ...coordinates,
});

export const convertPlaceToAddress = (
  formatCountryName: (countryCode: CountryCode) => string,
  placeDetails: google.maps.places.PlaceResult
): Address => {
  const addressDetails = placeDetails.address_components ?? [];

  // use locality or sub-locality for city
  const city =
    addressDetails.find((item) => item.types.includes("locality"))?.long_name ??
    addressDetails.find((item) => item.types.includes("sublocality"))?.long_name ??
    addressDetails.find((item) => item.types.includes("postal_town"))?.long_name ??
    "";
  const postalCode =
    addressDetails.find((item) => item.types.includes("postal_code"))?.long_name ?? "";
  const countryCode =
    addressDetails.find((item) => item.types.includes("country"))?.short_name ?? "";
  // take formatted country name by country code or use whatever google returned
  const country =
    formatCountryName(countryCode as CountryCode) ||
    (addressDetails.find((item) => item.types.includes("country"))?.long_name ?? "");
  const regionItem = addressDetails.find((item) =>
    item.types.includes("administrative_area_level_1")
  );
  const region = regionItem?.long_name ?? "";
  const shouldFindRegionCode =
    countryCode &&
    regionItem?.short_name !== undefined &&
    regionItem?.short_name === regionItem?.long_name;
  const regionCode =
    (shouldFindRegionCode
      ? findRegionByName(countryCode, region)?.regionCode
      : regionItem?.short_name) ?? "";

  // Getting street address field is tough. Because:
  // 1. street number and address fields order may differ. It is "SN, ADDR" in US, but "ADDR, SN" in Russia
  // 2. there's no ready-to-use field, besides placeDetails might have a street address without building number
  // There's two ways to take street address. One is more reliable: extract it from adr_address field
  // which has the following format: `<span class="street_address">SN+ADDR</span>, <span class="locality">CITY</span>, ...`
  // We try to extract value of the span with "street_address" class name.
  // The other, fallback way is to take formatted_address field. However, it includes all other
  // details too: city, state, etc. To remove these details from there, we collect all non street
  // number and non address fields values and then replace these values in formatted_address by an
  // empty string. Resulting value is [hopefully] a street address.
  let address = "";
  try {
    address = unescape(
      (placeDetails.adr_address ?? "")
        .split(/<\/\w+>(,\s*)?/) // split by closing tag
        .find((s) => s.includes('class="street-address"')) // find tag with street-address class name
        ?.split(/<\w+[^>]*>/)?.[1] ?? "" // remove opening tag or use empty string as a result
    );
  } catch {
    // nevermind
  }
  if (!address) {
    const nonStreetAddressValues = addressDetails
      .filter((item) => !item.types.includes("route") && !item.types.includes("street_number"))
      .flatMap((item) => [item.long_name, item.short_name]);
    address = unescape(
      nonStreetAddressValues
        .reduce(
          (result, name) => result.replace(new RegExp(`(,\\s*)?${name}`, "g"), ""),
          placeDetails.formatted_address ?? ""
        )
        .replace(/(^\P{L}+)|(\P{L}+$)/gu, "") // remove any leading/trailing non-alphanumeric chars
    );
  }

  if (!address) {
    address =
      Array.from({ length: 4 }, (_, i) => `administrative_area_level_${7 - i}`)
        .map((type) => addressDetails.find((item) => item.types.includes(type))?.long_name)
        ?.find((name) => !!name) ?? "";
  }

  return {
    address,
    city,
    country,
    countryCode,
    postalCode,
    region,
    regionCode,
  };
};

export const convertLatLngToCoordinates = (latLng: google.maps.LatLng): GeolocationCoordinates =>
  createCoordinates({
    latitude: latLng.lat(),
    longitude: latLng.lng(),
  });

export const convertLatLngToLongLat = (latLng: google.maps.LatLng): LongLat => [
  latLng.lng(),
  latLng.lat(),
];

export const convertLongLatToLatLngLiteral = ([lng, lat]: LongLat): LatLngLiteral => ({ lat, lng });

export const convertCoordinatesToLatLngLiteral = (
  coordinates: GeolocationCoordinates
): LatLngLiteral => ({
  lat: coordinates.latitude,
  lng: coordinates.longitude,
});

export const convertCoordinatesToLatLng = (
  coordinates: GeolocationCoordinates
): google.maps.LatLng => new google.maps.LatLng(convertCoordinatesToLatLngLiteral(coordinates));

export const convertPlatformBoundsToLatLngBoundsLiteral = ({
  bottom_right: [east, south],
  top_left: [west, north],
}: PlatformBounds): google.maps.LatLngBoundsLiteral => ({
  east,
  north,
  south,
  west,
});

export const convertPlatformBoundsToLatLngBounds = (
  bounds: PlatformBounds
): google.maps.LatLngBounds =>
  new google.maps.LatLngBounds(
    { lat: bounds.bottom_right[1], lng: bounds.top_left[0] },
    { lat: bounds.top_left[1], lng: bounds.bottom_right[0] }
  );

export const convertLatLngBoundsToPlatformBounds = (
  bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral
): PlatformBounds => {
  if ((bounds as google.maps.LatLngBounds).getCenter) {
    const sw = (bounds as google.maps.LatLngBounds).getSouthWest();
    const ne = (bounds as google.maps.LatLngBounds).getNorthEast();
    return {
      bottom_right: [ne.lng(), sw.lat()],
      top_left: [sw.lng(), ne.lat()],
    };
  } else {
    const { east, north, south, west } = bounds as google.maps.LatLngBoundsLiteral;
    return { bottom_right: [east, south], top_left: [west, north] };
  }
};

export const convertRegionToLatLngBounds = (
  region: Required<BasePin>["region"]
): google.maps.LatLngBounds =>
  new google.maps.LatLngBounds(
    {
      lat: region.latitude - region.latitudeDelta / 2,
      lng: region.longitude - region.longitudeDelta / 2,
    },
    {
      lat: region.latitude + region.latitudeDelta / 2,
      lng: region.longitude + region.longitudeDelta / 2,
    }
  );

export const convertGeoPointToLatLngLiteral = (geoPoint: GeoPoint): LatLngLiteral => ({
  lat: geoPoint.coordinates[1],
  lng: geoPoint.coordinates[0],
});

export const convertCoordinatesToGeoPoint = (coordinates: GeolocationCoordinates): GeoPoint => ({
  coordinates: [coordinates.longitude, coordinates.latitude],
  type: "Point",
});

export const convertLongLatToGeoPoint = (coordinates: LongLat): GeoPoint => ({
  coordinates,
  type: "Point",
});

export const convertCoordinatesToLongLat = (coordinates: GeolocationCoordinates): LongLat => [
  coordinates.longitude,
  coordinates.latitude,
];

export const convertGeoPointToLatLng = (geoPoint: GeoPoint): google.maps.LatLng =>
  new google.maps.LatLng(convertGeoPointToLatLngLiteral(geoPoint));

export const isPlatformBoundsEmpty = (bounds: PlatformBounds): boolean =>
  convertPlatformBoundsToLatLngBounds(bounds).isEmpty();

type GeoServiceErrorHandler = (e?: GeolocationPositionError) => void;
type GeoServiceErrorType = "onGeolocationNotAvailable";

class GeoService {
  private errorHandlers: Record<GeoServiceErrorType, GeoServiceErrorHandler[]> = {
    onGeolocationNotAvailable: [],
  };

  registerErrorHandler(errorType: GeoServiceErrorType, handler: GeoServiceErrorHandler) {
    this.errorHandlers[errorType].push(handler);
  }

  getCurrentPosition = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          resolve,
          (e) => {
            this.errorHandlers.onGeolocationNotAvailable.forEach((handler) => {
              handler(e);
            });
            reject(e);
          },
          { timeout: 5000 }
        );
      } else {
        reject(false);
      }
    });
  };

  isGeoPointInArea = (center: google.maps.LatLng, geoPoint: GeoPoint, radiusInMeters: number) => {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      center,
      convertGeoPointToLatLng(geoPoint)
    );
    return distance <= radiusInMeters;
  };
}

const geoService = new GeoService();
export default geoService;
