import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import Form from "antd/es/form";
import cn from "classnames";
import { FieldData } from "rc-field-form/lib/interface";
import { defineMessage, FormattedMessage } from "react-intl";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import { Alert } from "@mapmycustomers/ui";

import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";
import signupAnalytics from "@app/scene/auth/signupAnalytics";
import { getError, isSignUpLoading } from "@app/store/auth";
import { signUp } from "@app/store/auth/actions";
import { RootState } from "@app/store/rootReducer";
import getErrorMessage from "@app/util/errorHandling/getErrorMessage";
import validatePasswords, {
  NOTHING_VALID,
  PasswordValidationResult,
} from "@app/util/validatePasswords";

import SignupField from "../../enum/SignupField";
import SignupFormValues from "../../types/SignupFormValues";
import styles from "../RegisterForm.module.scss";

import ContactInfoStep from "./steps/ContactInfoStep";
import OrgDetailsStep from "./steps/OrgDetailsStep";
import PasswordStep from "./steps/PasswordStep";
import useStepValidator from "./steps/useStepValidator";

const genericErrorMessage = defineMessage({
  id: "auth.register.error.unknown",
  defaultMessage: "Server issue, please try again later",
  description: "A generic owner registration failed error message text",
});

const DEFAULT_INITIAL_VALUES: Partial<SignupFormValues> = {
  // need to default all checkboxes to some value, otherwise their value is undefined and
  // field doesn't pass validation by useStepValidator hook (see areStepFieldsValid method there)
  [SignupField.AGREE_WITH_TNC]: false,
  [SignupField.NUM_FIELDS_REPS]: 2,
  [SignupField.PROMO_EMAILS]: true,
};

interface Props {
  email?: string;
  error?: ApiError;
  isLoading: boolean;
  signUp: typeof signUp.request;
}

export const RegisterOwner: React.FC<Props> = ({ email, error, isLoading, signUp }) => {
  const [form] = Form.useForm<SignupFormValues>();

  // Registration pages steps handling
  const [currentStep, setCurrentStep] = useState<number>(0);
  const handleBackClick = useCallback(() => {
    setCurrentStep((currentStep) => (currentStep + 3 - 1) % 3);
    signupAnalytics.clicked(["Go back"]);
  }, [setCurrentStep]);
  const handleNextClick = useCallback(() => {
    setCurrentStep((currentStep) => (currentStep + 1) % 3);
    signupAnalytics.clicked(["Next step"]);
  }, [setCurrentStep]);

  const [onFormFieldsChange, validity] = useStepValidator(form);
  const [passwordValidationResult, setPasswordValidationResult] =
    useState<PasswordValidationResult>(NOTHING_VALID);
  const handleFormFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      onFormFieldsChange(changedFields);
      setPasswordValidationResult(
        validatePasswords(
          form.getFieldValue(SignupField.PASSWORD),
          form.getFieldValue(SignupField.PASSWORD_CONFIRMATION)
        )
      );
    },
    [form, onFormFieldsChange, setPasswordValidationResult]
  );

  useEffect(() => {
    let stepName = "Contact Info";
    if (currentStep === 1) {
      stepName = "Password";
    }
    if (currentStep === 2) {
      stepName = "Org Details";
    }
    signupAnalytics.clicked([stepName]);
  }, [currentStep]);

  let errorMessage = error ? getErrorMessage(error, {}, genericErrorMessage) : undefined;
  if (errorMessage?.startsWith("Invalid grant")) {
    errorMessage = undefined;
  }

  return (
    <Form
      className={commonStyles.container}
      form={form}
      initialValues={{ ...DEFAULT_INITIAL_VALUES, [SignupField.EMAIL]: email }}
      layout="vertical"
      onFieldsChange={handleFormFieldsChange}
      onFinish={signUp}
    >
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <p className={commonStyles.title}>
        <FormattedMessage
          id="auth.register.title"
          defaultMessage="Get started for free"
          description="Title of sign up form"
        />
      </p>

      <ContactInfoStep
        className={cn({ [styles.hidden]: currentStep !== 0 })}
        email={email}
        form={form}
        isValid={validity.isContactsStepValid}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        title={
          <FormattedMessage
            id="auth.register.contactInfoStep.description"
            defaultMessage="Let’s start with the basics in case we have to recover your account"
            description="Brief description of first step of the register form"
          />
        }
      />
      <PasswordStep
        className={cn({ [styles.hidden]: currentStep !== 1 })}
        form={form}
        isValid={validity.isPasswordStepValid}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        passwordValidationResult={passwordValidationResult}
      />
      <OrgDetailsStep
        className={cn({ [styles.hidden]: currentStep !== 2 })}
        form={form}
        isLoading={isLoading}
        isValid={validity.isOrgDetailsStepValid}
        onBackClick={handleBackClick}
      />
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  isLoading: isSignUpLoading(state),
});

const mapDispatchToProps = {
  signUp: signUp.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterOwner);
