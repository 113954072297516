import React from "react";

import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { ButtonProps } from "antd/es/button";
import cn from "classnames";

import styles from "./MoreButton.module.scss";

const MoreButton: React.FC<ButtonProps> = ({
  className,
  shape = "circle",
  type = "link",
  ...props
}) => (
  <Button
    className={cn(styles.button, className)}
    icon={<FontAwesomeIcon icon={faEllipsisV} />}
    shape={shape}
    type={type}
    {...props}
  />
);
export default MoreButton;
