import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Tabs from "antd/es/tabs";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import { getNotifications, isOnlyMentions } from "@app/store/notification";
import { RootState } from "@app/store/rootReducer";
import MmcNotification from "@app/types/MmcNotification";
import useAnalytics from "@app/util/contexts/useAnalytics";

import NotificationList from "./component/NotificationList";
import NotificationViewMode from "./enum/NotificationViewMode";
import styles from "./NotificationView.module.scss";

interface Props {
  isOnlyMentions: boolean;
  notifications: MmcNotification[];
  onFetch: (payload: { onlyMentions: boolean }) => void;
  onHide: () => void;
}

const NotificationView: React.FC<Props> = ({ isOnlyMentions, notifications, onFetch, onHide }) => {
  const history = useHistory();
  const intl = useIntl();
  const analyticsIssuer = useAnalytics();

  const handleSettingsClick = useCallback(() => {
    history.push(`${Path.SETTINGS}/${SettingPath.PRIVACY_NOTIFICATIONS}`);
    analyticsIssuer.clicked(["Settings"]);
    onHide();
  }, [analyticsIssuer, history, onHide]);

  useEffect(() => {
    analyticsIssuer.clicked(isOnlyMentions ? ["Mentions Tab"] : []);
  }, [analyticsIssuer, isOnlyMentions]);

  return (
    <div className={styles.container}>
      <Row className={styles.top} justify="space-between">
        <Col className={styles.title}>
          {intl.formatMessage({
            id: "navbar.notifications.list.title",
            defaultMessage: "Notifications",
            description: "Navbar Notifications - title",
          })}
        </Col>
        <Col>
          <Tooltip
            title={intl.formatMessage({
              id: "navbar.notifications.list.settings.tooltip",
              defaultMessage: "Notification preferences",
              description: "Navbar Notifications - list - notification - Go to settings - tooltip",
            })}
          >
            <Button
              icon={<FontAwesomeIcon className={styles.icon} icon={faCog} size="lg" />}
              onClick={handleSettingsClick}
              type="text"
            />
          </Tooltip>
        </Col>
      </Row>
      <Tabs
        activeKey={isOnlyMentions ? NotificationViewMode.MENTIONS : NotificationViewMode.ALL}
        className={styles.tabs}
        onChange={(key) => onFetch({ onlyMentions: key === NotificationViewMode.MENTIONS })}
      >
        <Tabs.TabPane
          key={NotificationViewMode.ALL}
          tab={intl.formatMessage({
            id: "navbar.notifications.list.tab.all",
            defaultMessage: "All Notifications",
            description: "Navbar Notifications - tabs - All Notifications",
          })}
        />
        <Tabs.TabPane
          key={NotificationViewMode.MENTIONS}
          tab={intl.formatMessage({
            id: "navbar.notifications.list.tab.mentions",
            defaultMessage: "Mentions",
            description: "Navbar Notifications - tabs - Mentions",
          })}
        />
      </Tabs>
      <NotificationList notifications={notifications} onGoToSettings={handleSettingsClick} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOnlyMentions: isOnlyMentions(state),
  notifications: getNotifications(state),
});

export default connect(mapStateToProps)(NotificationView);
