import { defineMessages, IntlShape } from "react-intl";

enum DateRangeType {
  TODAY = "today",
  TOMORROW = "tomorrow",
  THIS_WEEK = "thisWeek",
  NEXT_WEEK = "nextWeek",
  CUSTOM = "custom",
}

export const DATE_RANGES = [
  DateRangeType.TODAY,
  DateRangeType.TOMORROW,
  DateRangeType.THIS_WEEK,
  DateRangeType.NEXT_WEEK,
  DateRangeType.CUSTOM,
];

const messages = defineMessages({
  [DateRangeType.CUSTOM]: {
    id: "activities.calendarView.routeBuilder.rangeType.custom",
    defaultMessage: "Custom time range",
    description: "'Custom' date range option name in Route Builder in Activities Calendar view",
  },
  [DateRangeType.NEXT_WEEK]: {
    id: "activities.calendarView.routeBuilder.rangeType.nextWeek",
    defaultMessage: "Next week's activities",
    description: "'Next week' date range option name in Route Builder in Activities Calendar view",
  },
  [DateRangeType.THIS_WEEK]: {
    id: "activities.calendarView.routeBuilder.rangeType.thisWeek",
    defaultMessage: "This week's activities",
    description: "'This week' date range option name in Route Builder in Activities Calendar view",
  },
  [DateRangeType.TODAY]: {
    id: "activities.calendarView.routeBuilder.rangeType.today",
    defaultMessage: "Today's activities",
    description: "'Today' date range option name in Route Builder in Activities Calendar view",
  },
  [DateRangeType.TOMORROW]: {
    id: "activities.calendarView.routeBuilder.rangeType.tomorrow",
    defaultMessage: "Tomorrow's activities",
    description: "'Tomorrow' date range option name in Route Builder in Activities Calendar view",
  },
});

export const getDateRangeTypeDisplayName = (intl: IntlShape, rangeType: DateRangeType) =>
  intl.formatMessage(messages[rangeType]);

export default DateRangeType;
