import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const newRoutesState = (state: RootState) => state.scene.dashboard.newRoutesState;

export const getNewRoutesDrillDownViewState = createSelector(
  newRoutesState,
  ({ viewStates }) => viewStates
);

export const getNewRoutesDrillDownTotalFilteredRoutes = createSelector(
  newRoutesState,
  ({ totalFilteredRoutes }) => totalFilteredRoutes
);

export const getNewRoutesDrillDownTotalRoutes = createSelector(
  newRoutesState,
  ({ totalRoutes }) => totalRoutes
);

export const getNewRoutesDrillDownCustomDateRange = createSelector(
  newRoutesState,
  ({ drillDownCustomDateRange }) => drillDownCustomDateRange
);

export const getNewRoutesDrillDownConfiguration = createSelector(
  newRoutesState,
  ({ configuration }) => configuration
);

export const isNewRoutesDrillDownLoading = createSelector(newRoutesState, ({ loading }) => loading);
