import { createReducer } from "typesafe-actions";

import { Actions, fetchCompanyRoutes, fetchPeopleRoutes } from "./actions";
import RouteState from "./RouteState";

const initialState: RouteState = {
  companyRoutes: [],
  companyRoutesError: undefined,
  companyRoutesLoading: false,
  peopleRoutes: [],
  peopleRoutesError: undefined,
  peopleRoutesLoading: false,
};

const routes = createReducer<RouteState, Actions>(initialState)
  .handleAction(fetchCompanyRoutes.request, (state) => {
    return {
      ...state,
      companyRoutesLoading: true,
    };
  })
  .handleAction(fetchPeopleRoutes.request, (state) => ({
    ...state,
    peopleRoutes: [],
    peopleRoutesError: undefined,
    peopleRoutesLoading: true,
  }))
  .handleAction(fetchCompanyRoutes.success, (state, action) => ({
    ...state,
    companyRoutes: action.payload,
    companyRoutesLoading: false,
  }))
  .handleAction(fetchPeopleRoutes.success, (state, action) => ({
    ...state,
    peopleRoutes: action.payload,
    peopleRoutesLoading: false,
  }))
  .handleAction(fetchCompanyRoutes.failure, (state) => ({
    ...state,
    companyRoutesLoading: false,
  }))
  .handleAction(fetchPeopleRoutes.failure, (state) => ({
    ...state,
    peopleRoutesLoading: false,
  }));

export type RoutesActions = Actions;
export default routes;
