import { useMemo } from "react";

import { addDays } from "date-fns/esm";

import { Group } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import FrequencyCriteria from "@app/types/frequncy/FrequencyCriteria";

import FrequencyEntity from "../../type/FrequencyEntity";
import FrequencyPreviewConfig from "../../type/FrequencyPreviewConfig";
import getFrequencySequence from "../../util/getFrequencySequence";
import hasFrequency from "../../util/hasFrequency";

export const getFrequencyPreviewConfig = (
  entity: FrequencyEntity,
  isGroup?: boolean,
  initialGroups?: Group[]
): FrequencyPreviewConfig => {
  const groups = initialGroups?.filter(hasFrequency);
  const sequence = getFrequencySequence(entity, groups);

  const minimumCadenceInterval = sequence.reduce(
    (min, { cadenceInterval }) => Math.min(min, cadenceInterval),
    Number.MAX_SAFE_INTEGER
  );

  const { cadenceDaysOut, cadenceInterval, crmActivityTypeId } = sequence.reduce(
    (record, candidate) => (record.cadenceDaysOut < candidate.cadenceDaysOut ? candidate : record),
    {
      cadenceDaysOut: Number.MIN_SAFE_INTEGER,
      cadenceInterval: 0,
    }
  );

  const actualFrequencyCriteria = {
    activityTypesIds: crmActivityTypeId,
    cadenceInterval,
    dueDate: cadenceDaysOut > 0 ? new Date() : addDays(new Date(), -cadenceDaysOut),
  } as FrequencyCriteria;

  const isEntityFrequencied = hasFrequency(entity);
  const isSomeGroupFrequencied = !!groups?.length;

  const typeIds = sequence.reduce<ActivityType["id"][] | undefined>(
    (result, row) =>
      isDefined(result) && isDefined(row.crmActivityTypeId)
        ? [...result, ...(row.crmActivityTypeId ?? [])]
        : undefined,
    []
  );

  const activityTypeIds = typeIds ? Array.from(new Set(typeIds)) : undefined;

  return {
    activityTypeIds,
    actualFrequencyCriteria,
    entity,
    groups,
    isEntityFrequencied,
    isGroup,
    isSomeGroupFrequencied,
    minimumCadenceInterval,
  };
};

const useFrequencyPreviewConfig = (
  entity: FrequencyEntity,
  isGroup?: boolean,
  initialGroups?: Group[]
) =>
  useMemo(
    () => getFrequencyPreviewConfig(entity, isGroup, initialGroups),
    [entity, isGroup, initialGroups]
  );

export default useFrequencyPreviewConfig;
