import utcToZonedTime from "date-fns-tz/esm/utcToZonedTime";
// See https://date-fns.org/v2.16.1/docs/parseJSON docs
import { parseJSON } from "date-fns/esm";

import { getLocalTimeZone } from "../dates";
import isValidDate from "../isValidDate";

export const parseApiDate = (date: Date | number | string): Date => {
  if (typeof date === "string" && date.length === 10) {
    date = `${date}T00:00:00Z`;
  }
  return parseJSON(date);
};

export const parseApiDateAsNumber = (date: Date | number | string): number => {
  const result = parseJSON(date);
  return isValidDate(result) ? result.getTime() : 0;
};

export const parseApiDateWithTz = (date: Date | number | string, timeZone?: string): Date => {
  if (!date) {
    return new Date(Number.NaN); // Invalid Date
  }
  return utcToZonedTime(date, timeZone ?? getLocalTimeZone());
};

export const parseApiDateWithUtcTz = (date: Date | number | string): Date => {
  if (!date) {
    return new Date(Number.NaN); // Invalid Date
  }
  return parseApiDateWithTz(date, "UTC");
};
