import React from "react";

import { bem } from "@react-md/utils";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import Team from "@mapmycustomers/shared/types/Team";

import TeamAvatar from "./TeamAvatar";

const block = bem("mmc-avatar");

interface Props {
  avatarClassName?: string;
  className?: string;
  team: Pick<Team, "id" | "name">;
}

const TeamAvatarWithName: React.FC<Props> = ({ avatarClassName, className, team }) => {
  return (
    <div className={cn(block(), className)}>
      <TeamAvatar
        className={cn(block("avatar"), avatarClassName)}
        randomColor
        team={team}
        tooltip
      />{" "}
      <Tooltip title={team.name}>
        <span className={block("name")}>{team.name}</span>
      </Tooltip>
    </div>
  );
};

export default TeamAvatarWithName;
