@use "./../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 0.25rem 0.5rem;
        display: flex;
        gap: 0.25rem;
        height: 2.5rem;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
        background-color: colors.$mmc-white;
        width: fit-content;
      }

      .extraMargin {
        position: initial;
        margin-bottom: 0.25rem;
        margin-left: 0.25rem;
        box-shadow: none;
      }

      .divider:global(.ant-divider):global(.ant-divider-vertical) {
        border-left-color: colors.$mmc-disabled-gray;
        margin-top: 0.5rem;
        height: 1.25rem;
      }

      .togglerButton {
        padding: 0 0.125rem;
        cursor: pointer;
        color: colors.$mmc-soft-slate;
        border-radius: 0.25rem;
        display: flex;
        height: 2rem;
        min-width: 2rem;
        align-items: center;
        justify-content: center;
        width: max-content;

        &:hover {
          background-color: colors.$mmc-soft-gray;
        }

        svg {
          height: 1rem;
        }
      }

      .togglerButtonContent {
        width: max-content;
      }

      .dropDownChevron {
        width: 0.75rem;
      }

      .active {
        background-color: colors.$mmc-primary-010;
        color: colors.$mmc-primary-100;
      }
    }
  }
}
