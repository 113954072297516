import { defineMessages, IntlShape } from "react-intl";

enum IntervalUnit {
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export default IntervalUnit;

const messages = defineMessages<IntervalUnit>({
  [IntervalUnit.DAY]: {
    id: "intervalUnit.day",
    defaultMessage: "{count, plural, one {day} other {days}}",
    description: "Days interval unit",
  },
  [IntervalUnit.HOUR]: {
    id: "intervalUnit.hour",
    defaultMessage: "{count, plural, one {hour} other {hours}}",
    description: "hours interval unit",
  },
  [IntervalUnit.MINUTE]: {
    id: "intervalUnit.minute",
    defaultMessage: "{count, plural, one {minute} other {minutes}}",
    description: "minutes interval unit",
  },
  [IntervalUnit.MONTH]: {
    id: "intervalUnit.month",
    defaultMessage: "{count, plural, one {month} other {months}}",
    description: "Months interval unit",
  },
  [IntervalUnit.WEEK]: {
    id: "intervalUnit.week",
    defaultMessage: "{count, plural, one {week} other {weeks}}",
    description: "Weeks interval unit",
  },
  [IntervalUnit.YEAR]: {
    id: "intervalUnit.year",
    defaultMessage: "{count, plural, one {year} other {years}}",
    description: "years interval unit",
  },
});

export const getIntervalUnitDisplayName = (
  intl: IntlShape,
  unit: IntervalUnit,
  count: number = 1
) => intl.formatMessage(messages[unit], { count });
