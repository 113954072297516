import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";

import cn from "classnames";
import { useIntl } from "react-intl";

import { EntityTypeSupportingDataMerging } from "@mapmycustomers/shared/types/entity";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";

import ButtonLink from "@app/component/ButtonLink";
import { showEntityView } from "@app/store/entityView/actions";
import useEntityLinkGetter from "@app/util/contexts/useEntityLinkGetter";

import styles from "./DuplicateCell.module.scss";

interface Props {
  duplicate: DuplicateEntity;
  entityType?: EntityTypeSupportingDataMerging;
  showAsLinks?: boolean;
  showEntityView: typeof showEntityView;
}

const DuplicateCell: React.FC<Props> = ({
  duplicate: { id, address, city, country, entity, hasAccess, name, postalCode },
  entityType,
  showAsLinks,
  showEntityView,
}) => {
  const intl = useIntl();
  const getEntityLink = useEntityLinkGetter();
  const isAccessible = hasAccess ?? true;
  const path = getEntityLink(entityType ?? entity, id);

  const primaryLine = useMemo(
    () =>
      isAccessible ? (
        showAsLinks ? (
          <ButtonLink href={path} target="_blank">
            {name}
          </ButtonLink>
        ) : (
          name
        )
      ) : (
        intl.formatMessage({
          id: "createEditEntity.duplicates.table.unknownRecord.primaryLine",
          defaultMessage: "Unknown Record",
          description: "Duplicate modal - Unknown Record",
        })
      ),
    [intl, isAccessible, name, path, showAsLinks]
  );

  const secondaryLine = useMemo(
    () =>
      isAccessible
        ? [address, city, country, postalCode]
            .filter((part) => (part ?? "").trim().length)
            .join(", ")
        : intl.formatMessage({
            id: "createEditEntity.duplicates.table.unknownRecord.secondaryLine",
            defaultMessage: "No access",
            description: "Duplicate modal - Unknown Record - no access",
          }),
    [address, city, country, isAccessible, intl, postalCode]
  );

  const handleClick = useCallback(() => {
    if (isAccessible) {
      showEntityView({
        entityId: id,
        entityType: entityType ?? entity,
      });
    }
  }, [id, entity, entityType, isAccessible, showEntityView]);

  return (
    <div>
      <div
        className={cn(styles.primary, { [styles.name]: isAccessible })}
        onClick={showAsLinks ? undefined : handleClick}
      >
        {primaryLine}
      </div>
      <div className={styles.address}>{secondaryLine}</div>
    </div>
  );
};

const mapDispatchToProps = {
  showEntityView,
};

export default connect(null, mapDispatchToProps)(DuplicateCell);
