import { createSelector } from "reselect";

import { RootState } from "../rootReducer";

const dealLossReasonsState = (state: RootState) => state.dealLossReasons;

export const getDealLossReasons = createSelector(
  dealLossReasonsState,
  ({ lossReasons }) => lossReasons
);

export const isDealLossReasonsLoading = createSelector(
  dealLossReasonsState,
  ({ loading }) => loading
);

export const isLostReasonCreating = createSelector(
  dealLossReasonsState,
  ({ createLoading }) => createLoading
);

export const isDeleting = createSelector(
  dealLossReasonsState,
  ({ deleteLoading }) => deleteLoading
);
