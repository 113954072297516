import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import FrequencyType from "@mapmycustomers/shared/enum/FrequencyType";
import IntegrationService from "@mapmycustomers/shared/enum/integrations/IntegrationService";
import { EntityTypesSupportedByIntegrations } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { IntegrationMetadata } from "@mapmycustomers/shared/types/integrations/IntegrationMetadata";
import User from "@mapmycustomers/shared/types/User";

import Integration from "@app/types/integrations/Integration";
import IntegrationField from "@app/types/integrations/IntegrationField";
import IntegrationFieldResponse from "@app/types/integrations/IntegrationFieldResponse";
import { IntegrationSchedule } from "@app/types/integrations/IntegrationSchedule";
import IntegrationSync from "@app/types/integrations/IntegrationSync";
import IntegrationUser from "@app/types/integrations/IntegrationUser";
import IntegrationSyncViewState from "@app/types/viewModel/IntegrationSyncsViewState";

import { IntegrationFieldsMapping } from "./index";

export const selectService = createAction("@integrations/selectService")<{
  integrationService: IntegrationService;
  isSandbox?: boolean;
}>();

export const createIntegration = createAsyncAction(
  "@integrations/create/request",
  "@integrations/create/success",
  "@integrations/create/failure"
)<Map<string, string>, Integration, unknown>();

export const updateIntegrationSyncsViewState = createAsyncAction(
  "@integrations/updateIntegrationViewState/request",
  "@integrations/updateIntegrationViewState/success",
  "@integrations/updateIntegrationViewState/failure"
)<
  { page?: number; viewState?: Partial<IntegrationSyncViewState> },
  {
    integrationSyncs: IntegrationSync[];
    total: number;
  },
  unknown
>();

export const initializeHomePage = createAsyncAction(
  "@integrations/initializeHomePage/request",
  "@integrations/initializeHomePage/success",
  "@integrations/initializeHomePage/failure"
)<
  void,
  {
    integration?: Integration;
    lastIntegrationSync?: IntegrationSync;
  },
  unknown
>();

export const initializeEditPage = createAsyncAction(
  "@integrations/initEditPage/request",
  "@integrations/initEditPage/success",
  "@integrations/initEditPage/failure"
)<
  Integration["id"],
  {
    defaultSchedule: IntegrationSchedule;
    integration: Integration;
    integrationFields?: IntegrationFieldsMapping;
    integrationSyncs?: IntegrationSync[];
    integrationUsers?: IntegrationUser[];
    needToSelectDynamicsInstance?: boolean;
  },
  unknown
>();

export const initializeMappingStep = createAsyncAction(
  "@integrations/initMappingStep/request",
  "@integrations/initMappingStep/success",
  "@integrations/initMappingStep/failure"
)<void, { integrationFields: IntegrationFieldsMapping }, unknown>();

export const fetchIntegration = createAsyncAction(
  "@integrations/fetch/request",
  "@integrations/fetch/success",
  "@integrations/fetch/failure"
)<Integration["id"], Integration, unknown>();

export const deleteIntegration = createAsyncAction(
  "@integrations/delete/request",
  "@integrations/delete/success",
  "@integrations/delete/failure"
)<Integration["id"], undefined, unknown>();

export const saveIntegration = createAsyncAction(
  "@integrations/save/request",
  "@integrations/save/success",
  "@integrations/save/failure"
)<void, Integration, unknown>();

export const setCurrentIntegration = createAction("@integrations/setCurrentIntegration")<{
  currentIntegration: Integration;
}>();

export const changeFrequency = createAction("@integrations/changeFrequency")<FrequencyType>();

export const changeStartSyncDate = createAction("@integrations/changeStartSyncDate")<Date>();

export const changeSyncOption = createAction("@integrations/changeSyncOption")<{
  entityType: EntityTypesSupportedByIntegrations;
  incoming: boolean;
  outgoing: boolean;
}>();

export const setIntegrationFields = createAction("@integrations/setIntegrationFields")<{
  integrationFields: IntegrationFieldsMapping;
}>();

export const changeIntegrationUser = createAction("@integrations/changeIntegrationUser")<{
  integrationUser: IntegrationUser;
  mmcUserId?: User["id"];
}>();

export const changeIntegrationUserSync = createAction("@integrations/changeIntegrationUserSync")<{
  enabled: boolean;
  integrationUser: IntegrationUser;
}>();

export const changeIntegrationField = createAction("@integrations/changeIntegrationField")<{
  entityType: EntityTypesSupportedByIntegrations;
  field: IField | undefined;
  integrationField: IntegrationField;
  isCustomField: boolean;
}>();

export const changeIntegrationFieldGroup = createAction(
  "@integrations/changeIntegrationFieldGroup"
)<{
  enabled: boolean;
  entityType: EntityTypesSupportedByIntegrations;
  integrationField: IntegrationField;
}>();

export const toggleIntegrationStatus = createAction("@integrations/toggleIntegrationStatus")<{
  integrationId: Integration["id"];
}>();

export const toggleEmailSyncState = createAction("@integrations/toggleEmailSyncState")<void>();

export const changeUnassignedRecordsOwner = createAction(
  "@integrations/changeUnassignedRecordsOwner"
)<IntegrationMetadata["unassignedRecordsOwner"]>();

export const toggleNoteSyncing = createAction(
  "@integrations/toggleNoteSyncing"
)<EntityTypesSupportedByIntegrations>();

export const toggleFileSyncing = createAction(
  "@integrations/toggleFileSyncing"
)<EntityTypesSupportedByIntegrations>();

export const setCustomFieldIntegrationResponse = createAction(
  "@integrations/setCustomFieldIntegrationResponse"
)<IntegrationFieldResponse[]>();

export const changeOrgUrl = createAsyncAction(
  "@integrations/changeOrgUrl/request",
  "@integrations/changeOrgUrl/success",
  "@integrations/changeOrgUrl/failure"
)<string, void, unknown>();

export const hideMatchingFieldsWarning = createAction(
  "@integrations/hideMatchingFieldsWarning"
)<void>();

export type Actions = ActionType<
  | typeof changeFrequency
  | typeof changeIntegrationField
  | typeof changeIntegrationFieldGroup
  | typeof changeIntegrationUser
  | typeof changeIntegrationUserSync
  | typeof changeOrgUrl
  | typeof changeStartSyncDate
  | typeof changeSyncOption
  | typeof changeUnassignedRecordsOwner
  | typeof createIntegration
  | typeof deleteIntegration
  | typeof fetchIntegration
  | typeof hideMatchingFieldsWarning
  | typeof initializeEditPage
  | typeof initializeHomePage
  | typeof initializeMappingStep
  | typeof saveIntegration
  | typeof selectService
  | typeof setCurrentIntegration
  | typeof setCustomFieldIntegrationResponse
  | typeof setIntegrationFields
  | typeof toggleEmailSyncState
  | typeof toggleFileSyncing
  | typeof toggleIntegrationStatus
  | typeof toggleNoteSyncing
  | typeof updateIntegrationSyncsViewState
>;
