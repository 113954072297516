import { Editor } from "slate";

import TextType from "@mapmycustomers/shared/enum/textEditor/TextType";

import isTextTypeActive from "./isTextTypeActive";

const toggleTextType = (editor: Editor, type: TextType) => {
  const isActive = isTextTypeActive(editor, type);
  if (isActive) {
    Editor.removeMark(editor, type);
  } else {
    Editor.addMark(editor, type, true);
  }
};

export default toggleTextType;
