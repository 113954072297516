import ActivityRecurringUnit from "@mapmycustomers/shared/enum/activity/ActivityRecurringUnit";

import IntervalUnit from "@app/enum/IntervalUnit";

import RecurringIntervalType from "../enum/RecurringIntervalType";
import FormValues from "../types/FormValues";

const isAnnualInterval = ({ recurInterval, recurIntervalType }: Partial<FormValues>) =>
  recurIntervalType === RecurringIntervalType.CUSTOM &&
  (recurInterval?.unit === IntervalUnit.YEAR ||
    (recurInterval?.unit === ActivityRecurringUnit.MONTH && recurInterval?.value === 12));

export default isAnnualInterval;
