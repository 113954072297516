import { ActionType, createAsyncAction } from "typesafe-actions";

import Dashboard from "@app/types/dashboard/Dashboard";
import DashboardCard from "@app/types/dashboard/DashboardCard";
import Scope from "@app/types/dashboard/Scope";

import { CardData } from "./saga";

export const fetchDashboards = createAsyncAction(
  "@component/userPreviewCard/fetchDashboards/request",
  "@component/userPreviewCard/fetchDashboards/success",
  "@component/userPreviewCard/fetchDashboards/failure"
)<void, Dashboard[], void>();

export const fetchCards = createAsyncAction(
  "@component/userPreviewCard/fetchCards/request",
  "@component/userPreviewCard/fetchCards/success",
  "@component/userPreviewCard/fetchCards/failure"
)<Scope, Record<number, DashboardCard["id"] | undefined>, void>();

export const updateCards = createAsyncAction(
  "@component/userPreviewCard/updateCards/request",
  "@component/userPreviewCard/updateCards/success",
  "@component/userPreviewCard/updateCards/failure"
)<{ cardIds: Record<number, DashboardCard["id"] | undefined>; scope: Scope }, void, void>();

export const fetchCardData = createAsyncAction(
  "@component/userPreviewCard/fetchCardData/request",
  "@component/userPreviewCard/fetchCardData/success",
  "@component/userPreviewCard/fetchCardData/failure"
)<
  {
    callback: (data: CardData) => void;
    card: DashboardCard;
    failureCallback?: () => void;
    scope: Scope;
  },
  { data: CardData; id: string },
  void
>();

export type Actions = ActionType<
  typeof fetchCardData | typeof fetchCards | typeof fetchDashboards | typeof updateCards
>;
