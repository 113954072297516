import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";

const accept = () => true;

const getAddableFields = (
  entityType: EntityTypesSupportingFieldCustomization,
  schema: LayoutSchemaField[],
  isCreateForm: boolean,
  fieldFilter: (field: IField) => boolean = accept
) => {
  const fieldModel = getFieldModelByEntityType(entityType);
  const usedSchemaFields = new Set(schema.map((schemaField) => schemaField.field));

  return fieldModel.fields.filter(
    (field) =>
      !usedSchemaFields.has(field.platformName) &&
      // we should be able to read or write this field
      (field.isReadable || field.isEditable) &&
      !field.hasAnyFeature(FieldFeature.NON_ADDABLE_FORM_FIELD) &&
      // for create form we only show editable non-calculated fields
      (!isCreateForm ||
        (field.isEditable && !field.hasAnyFeature(FieldFeature.CALCULATED_FIELD))) &&
      fieldFilter(field)
  );
};

export default getAddableFields;
