import React from "react";

import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ModalStaticFunctions } from "antd/es/modal/confirm";
import cn from "classnames";
import { IntlShape } from "react-intl";

import { formatEmailLink } from "../formatters";

import { EMAIL_WARNING_MODAL_CLASS_NAME } from "./const";
import styles from "./Email.module.scss";

const sendEmailViaClient = (
  email: string,
  intl: IntlShape,
  entityName: string,
  modal?: Omit<ModalStaticFunctions, "warn">,
  onTrackActivity?: () => void
) => {
  window.open(formatEmailLink(email) ?? undefined, "_blank");
  if (modal && onTrackActivity) {
    modal.confirm({
      cancelText: intl.formatMessage({
        id: "utils.email.sendEmailViaClient.confirmation.cancelText",
        defaultMessage: "No thanks",
        description: "Text for the confirmation modal's 'Don't log' button",
      }),
      centered: true,
      className: cn(styles.container, EMAIL_WARNING_MODAL_CLASS_NAME),
      content: (
        <div className={styles.content}>
          {intl.formatMessage({
            id: "utils.email.sendEmailViaClient.confirmation.content",
            defaultMessage:
              "We recommend logging an email activity to more accurately track interactions with this account.",
            description: "Content for the confirmation modal after sending an email",
          })}
        </div>
      ),
      icon: <FontAwesomeIcon className={styles.icon} icon={faQuestionCircle} size="lg" />,
      okText: intl.formatMessage({
        id: "utils.email.sendEmailViaClient.confirmation.okText",
        defaultMessage: "Yes, log email",
        description: "Text for the confirmation modal's 'Log email' button",
      }),
      onOk: onTrackActivity,
      title: intl.formatMessage(
        {
          id: "utils.email.sendEmailViaClient.confirmation.title",
          defaultMessage: "Log email with {entityName}?",
          description: "Title for the confirmation modal after sending an email",
        },
        { entityName }
      ),
    });
  }
};

export default sendEmailViaClient;
