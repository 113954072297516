import React from "react";

import Tag from "antd/es/tag";
import { useIntl } from "react-intl";

interface Props {}

const PreviewModeIndicator: React.FC<Props> = () => {
  const intl = useIntl();
  return (
    <Tag>
      {intl.formatMessage({
        id: "previewMode.text",
        defaultMessage: "PREVIEW",
        description: "Preview Mode indicator",
      })}
    </Tag>
  );
};

export default PreviewModeIndicator;
