import React, { useMemo } from "react";

import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

import { useConfigProvider } from "../../../ConfigProvider";
import CountryWithFlag from "../../../CountryWithFlag";

const useCountryListOptions = () => {
  const configProvider = useConfigProvider();

  return useMemo(() => {
    return Object.values(CountryCode).map((countryCode) => ({
      label: <CountryWithFlag countryCode={countryCode} />,
      text: configProvider.formatCountryName(countryCode),
      value: countryCode,
    }));
  }, [configProvider]);
};

export default useCountryListOptions;
