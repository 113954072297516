import { all, call, put, select, takeEvery, takeLeading } from "redux-saga/effects";

import { EntityType } from "@mapmycustomers/shared/enum";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { dealLayoutModel } from "@app/util/layout/impl";

import { toggleFavoriteFunnel } from "../iam/actions";
import { notifyAboutChanges } from "../uiSync/actions";

import {
  createFunnel,
  createStage,
  deleteFunnel,
  deleteStage,
  ensureStagesFetched,
  fetchDeal,
  fetchFunnels,
  updateDeal,
  updateFunnel,
  updateStages,
} from "./actions";
import { getFunnels, getFunnelStages } from "./selectors";

export function* onFetchFunnels() {
  try {
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Funnel> = yield callApi("fetchFunnels", organization.id, {
      $filters: { includeStages: true },
      $order: "name",
    });

    yield put(fetchFunnels.success(response.data));
  } catch (error) {
    yield put(fetchFunnels.failure(error));
  }
}

export function* onCreateFunnel({
  payload: { callback, funnel },
}: ReturnType<typeof createFunnel.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const addedFunnel: Funnel = yield callApi("createFunnel", org.id, funnel);
    callback?.(addedFunnel);
    yield put(ensureStagesFetched.request([addedFunnel.id]));
    yield put(createFunnel.success(addedFunnel));
  } catch (error) {
    yield put(createFunnel.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateFunnel({
  payload: { favorite, funnel },
}: ReturnType<typeof updateFunnel.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const updatedFunnel: Funnel = yield callApi("updateFunnel", org.id, funnel);
    yield put(updateFunnel.success(updatedFunnel));
    if (favorite !== undefined) {
      yield put(toggleFavoriteFunnel.request({ funnelId: funnel.id }));
    }
  } catch (error) {
    yield put(updateFunnel.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteFunnel({ payload }: ReturnType<typeof deleteFunnel.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteFunnel", org.id, payload.id);
    yield put(toggleFavoriteFunnel.request({ deletingFunnel: true, funnelId: payload.id }));
    yield put(deleteFunnel.success(payload));
  } catch (error) {
    yield put(deleteFunnel.failure());
    yield put(handleError({ error }));
  }
}

export function* onEnsureStagesFetched({
  payload,
}: ReturnType<typeof ensureStagesFetched.request>) {
  try {
    const funnels: Funnel[] = yield select(getFunnels);
    const funnelIdsToCheck = payload ?? funnels.map(({ id }) => id); // check given or all funnels

    const currentFunnelStages: Record<Funnel["id"], Stage[]> = yield select(getFunnelStages);
    const missingFunnelIds = funnelIdsToCheck.filter((funnelId) => !currentFunnelStages[funnelId]);

    // all fetched already, return success
    if (!missingFunnelIds.length) {
      yield put(ensureStagesFetched.success({}));
      return;
    }

    const org: Organization = yield select(getOrganization);
    const stagesResponses: Array<ListResponse<Stage>> = yield all(
      missingFunnelIds.map((funnelId) =>
        callApi("fetchStages", org.id, funnelId, { $order: "displayOrder" })
      )
    );

    yield put(
      ensureStagesFetched.success(
        missingFunnelIds.reduce<Record<Funnel["id"], Stage[]>>(
          (result, funnelId, index) => ({
            ...result,
            [funnelId]: stagesResponses[index].data,
          }),
          {}
        )
      )
    );
  } catch (error) {
    yield put(ensureStagesFetched.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateStage({
  payload: { funnelId, stage },
}: ReturnType<typeof createStage.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const createdStage: Stage = yield callApi("createStage", org.id, funnelId, stage);
    yield put(createStage.success(createdStage));
  } catch (error) {
    yield put(createStage.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateStages({
  payload: { funnelId, stages },
}: ReturnType<typeof updateStages.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const updatedStages: Stage[] = yield all(
      stages.map((stage) => callApi("updateStage", org.id, funnelId, stage))
    );
    yield put(updateStages.success({ funnelId, stages: updatedStages }));
  } catch (error) {
    yield put(updateStages.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteStage({ payload: stage }: ReturnType<typeof deleteStage.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteStage", org.id, stage.funnel.id, stage.id);
    yield put(deleteStage.success(stage));
  } catch (error) {
    yield put(deleteStage.failure());
    yield put(handleError({ error }));
  }
}

function* onUpdateDeal({ payload: deal }: ReturnType<typeof updateDeal.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const layout = dealLayoutModel.getLayoutFor(deal);
    const updatedDeal: Deal = yield callApi("updateDeal", orgId, layout.id, deal);
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [updatedDeal] }));
    yield put(updateDeal.success(updatedDeal));
  } catch (error) {
    yield put(updateDeal.failure());
    yield put(handleError({ error }));
  }
}

function* onFetchDeal({ payload }: ReturnType<typeof fetchDeal>) {
  const { id, callback, failureCallback, options = {} } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const deal: Deal = yield callApi("fetchDeal", orgId, id, options);
    yield call(callback, deal);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

export function* dealSaga() {
  yield takeLeading(fetchFunnels.request, onFetchFunnels);
  yield takeEvery(ensureStagesFetched.request, onEnsureStagesFetched);
  yield takeEvery(createFunnel.request, onCreateFunnel);
  yield takeEvery(updateFunnel.request, onUpdateFunnel);
  yield takeEvery(deleteFunnel.request, onDeleteFunnel);
  yield takeEvery(createStage.request, onCreateStage);
  yield takeEvery(updateStages.request, onUpdateStages);
  yield takeEvery(deleteStage.request, onDeleteStage);
  yield takeEvery(updateDeal.request, onUpdateDeal);
  yield takeEvery(fetchDeal, onFetchDeal);
}
