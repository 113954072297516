import React from "react";

import styles from "./Activities.module.scss";
import NextActivities from "./NextActivities";
import PreviousActivities from "./PreviousActivities";

interface Props {}

const Activities: React.FC<Props> = () => {
  return (
    <div className={styles.container}>
      <PreviousActivities />
      <NextActivities />
    </div>
  );
};

export default Activities;
