import React from "react";

import { useDropzone } from "react-dropzone";

import styles from "./DropZone.module.scss";

interface Props {
  accept?: string | string[];
  children: React.ReactNode;
  maxFiles?: number;
  maxSize?: number;
  onSelect: (files: File[]) => void;
}

const DropZone: React.FC<Props> = ({ accept, children, maxFiles, maxSize, onSelect }) => {
  const { getInputProps, getRootProps } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    onDrop: onSelect,
  });

  return (
    <div className={styles.container} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default DropZone;
