import { IntlShape } from "react-intl";

import MmcNotification from "@app/types/MmcNotification";

import { formatDate, getRelativeMessagePrefix } from "../formatters";
import { parseApiDate } from "../parsers";

const getFormattedDate = (intl: IntlShape, notification: MmcNotification) => {
  const updatedAtAsDate = parseApiDate(notification.updatedAt);

  return intl.formatMessage(
    {
      id: "util.notifications.date",
      defaultMessage: "{start} at {time}",
      description: "Util  - notification - date format",
    },
    {
      start: formatDate(
        updatedAtAsDate,
        intl.formatMessage(getRelativeMessagePrefix(updatedAtAsDate))
      ),
      time: formatDate(updatedAtAsDate, "p"),
    }
  );
};

export default getFormattedDate;
