import React, { ReactNode, useMemo } from "react";

import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "antd/es/input";
import Select from "antd/es/select";
import cn from "classnames";
import { IntlShape, useIntl } from "react-intl";

import Visibility from "@mapmycustomers/shared/enum/Visibility";
import Team from "@mapmycustomers/shared/types/Team";
import { Labeled } from "@mapmycustomers/ui";

import itemToValueLabel from "./utils/itemToValueLabel";
import styles from "./VisibilitySelectField.module.scss";

const sameNode = (node: ReactNode) => node;

interface Props<T extends Visibility> {
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  noteGetter: (intl: IntlShape, visibility: T) => string;
  onChange?: (visibility: T) => void;
  onTeamIdsChange?: (teamIds: Team["id"][]) => void;
  options?: { label: string; value: T }[];
  teamIds?: Team["id"][];
  teams: Team[];
  teamsRenderer?: (node: ReactNode) => ReactNode;
  value?: T;
  visibilityRenderer?: (node: ReactNode) => ReactNode;
}

const VisibilitySelectField = <T extends Visibility>({
  className,
  disabled,
  label,
  noteGetter,
  onChange,
  onTeamIdsChange,
  options,
  teamIds,
  teams,
  teamsRenderer = sameNode,
  value,
  visibilityRenderer = sameNode,
}: Props<T>) => {
  const intl = useIntl();
  const teamOptions = useMemo(() => teams.map(itemToValueLabel), [teams]);

  const note = value ? noteGetter(intl, value) : "";

  return (
    <Labeled className={className} label={label}>
      <Input.Group
        className={cn(styles.inputGroup, { [styles.space]: value === Visibility.SHARED_WITH_TEAM })}
        compact
        size="large"
      >
        {visibilityRenderer(
          <Select<T>
            className={styles.selectField}
            disabled={disabled}
            dropdownMatchSelectWidth={false}
            onChange={onChange}
            options={options}
            size="large"
            suffixIcon={disabled ? <FontAwesomeIcon icon={faLock} /> : undefined}
            value={value}
          />
        )}
        {value === Visibility.SHARED_WITH_TEAM &&
          teamsRenderer(
            <Select<Team["id"][]>
              // the latter style tell antd to not apply any focus styles
              className={styles.selectField}
              dropdownMatchSelectWidth={false}
              mode="multiple"
              onChange={onTeamIdsChange}
              options={teamOptions}
              showSearch
              size="large"
              value={teamIds}
            />
          )}
      </Input.Group>
      {note && <div className={styles.note}>{note}</div>}
    </Labeled>
  );
};

export default VisibilitySelectField;
