import { createReducer } from "typesafe-actions";

import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import localSettings from "@app/config/LocalSettings";
import { Actions as AppActions, initializeApp } from "@app/store/app/actions";
import companyFieldModel from "@app/util/fieldModel/CompanyFieldModel";

import { Actions, applyListViewSettings, fetchList, setOfferedFilters } from "./actions";

export interface CompanyListViewState extends ViewState {
  error: undefined | unknown;
  loading: boolean;
  offerFilters?: Partial<FilterModel>;
  totalFilteredRecords: number;
  totalRecords: number;
}

const initialState: CompanyListViewState = {
  ...companyFieldModel.getDefaultListViewState(),
  error: undefined,
  // loading is true by default to avoid flashing a "no rows" overlay when the page
  // is just opened but loading is not yet started
  loading: true,
  totalFilteredRecords: 0,
  totalRecords: 0,
};

const company = createReducer<CompanyListViewState, Actions | AppActions>(initialState)
  // TODO: change this to catch fetchCustomFields.success for company entity type
  // to update view state every time custom fields are modified
  // load view settings after app initialization to ensure custom fields are fetched
  // OR not change, but add one more action
  .handleAction(initializeApp.success, (state) => ({
    ...state,
    ...localSettings.getViewSettings("company/listView", companyFieldModel),
  }))
  .handleAction(applyListViewSettings, (state, { payload }) => ({
    ...state,
    columns: payload.columns ?? state.columns,
    filter: payload.filter ?? state.filter,
    range: payload.range ?? state.range,
    // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
    selectedSavedFilterId:
      "selectedSavedFilterId" in payload
        ? payload.selectedSavedFilterId
        : state.selectedSavedFilterId,
    sort: payload.sort ?? state.sort,
    // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
    viewAs: "viewAs" in payload ? payload.viewAs : state.viewAs,
  }))
  .handleAction(setOfferedFilters, (state, action) => ({
    ...state,
    offerFilters: action.payload,
  }))
  .handleAction(fetchList.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchList.success, (state, action) => ({
    ...state,
    loading: false,
    totalFilteredRecords: action.payload.totalFilteredRecords,
    totalRecords: action.payload.totalRecords,
  }))
  .handleAction(fetchList.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }));

export type CompanyActions = Actions;
export default company;
