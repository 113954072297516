import MmcNotificationAction from "@app/enum/MmcNotificationAction";

const getNotificationActionAnalyticGroup = (action: MmcNotificationAction): string => {
  if (action === MmcNotificationAction.MENTION) {
    return "mention";
  }
  if (
    [
      MmcNotificationAction.DEALS_MARKED_LOST,
      MmcNotificationAction.DEALS_MARKED_WON,
      MmcNotificationAction.DEALS_ROTTING,
      MmcNotificationAction.DEALS_STAGE_CHANGED,
      MmcNotificationAction.OUT_OF_CADENCE,
      MmcNotificationAction.OVERDUE_ACTIVITIES,
    ].includes(action)
  ) {
    return "daily update";
  }
  if (
    [
      MmcNotificationAction.COMPANY_GROUP_SHARED,
      MmcNotificationAction.COMPANY_ROUTE_SHARED,
      MmcNotificationAction.DEAL_GROUP_SHARED,
      MmcNotificationAction.OWNERSHIP_CHANGED,
      MmcNotificationAction.PEOPLE_GROUP_SHARED,
      MmcNotificationAction.PEOPLE_ROUTE_SHARED,
    ].includes(action)
  ) {
    return "sharing";
  }
  return "notes & activities";
};

export default getNotificationActionAnalyticGroup;
