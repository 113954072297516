import React from "react";

import Button from "antd/es/button";
import { useIntl } from "react-intl";

import noAccessImage from "@app/assets/activity/annotation/no-access.svg";

import styles from "./NoAccess.module.scss";

interface Props {
  onDismiss: () => void;
}

const NoAccess: React.FC<Props> = ({ onDismiss }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <img alt="No access icon" className={styles.image} src={noAccessImage} />
      <div className={styles.title}>
        {intl.formatMessage({
          id: "createEditActivityModal.notFound.title",
          defaultMessage: "Activity not found",
          description: "Title for the NotFound state of activity modal",
        })}
      </div>
      <div className={styles.description}>
        {intl.formatMessage({
          id: "createEditActivityModal.notFound.description",
          defaultMessage: "You don’t have access to this activity or it may have been deleted",
          description: "Description for the NotFound state of activity modal",
        })}
      </div>
      <Button onClick={onDismiss}>
        {intl.formatMessage({
          id: "createEditActivityModal.notFound.dismiss",
          defaultMessage: "Dismiss",
          description: "Dismiss action for the NotFound state of activity modal",
        })}
      </Button>
    </div>
  );
};

export default NoAccess;
