import { UserRef } from "@mapmycustomers/shared/types/User";
import getSvgForCircleWithText, {
  SvgCircleWithTextConfig,
} from "@mapmycustomers/shared/util/svg/getSvgForCircleWithText";
import getInitials from "@mapmycustomers/shared/util/ui/getInitials";

import getUserColor from "./userColor/getUserColor";

export interface UserAvatarConfig extends Omit<SvgCircleWithTextConfig, "text"> {
  greyed?: boolean;
  user: UserRef;
  userImageString?: string;
}

const getAvatarSvg = (
  {
    fillColor,
    fontFamily = "Twemoji Country Flags, Roboto, sans-serif",
    fontSize = "12px",
    greyed,
    size,
    strokeColor = "white",
    textColor = "white",
    user,
    userImageString,
  }: UserAvatarConfig,
  getUserColorClassName: (user: UserRef) => string | undefined
): string => {
  const half = size / 2;

  const [backgroundColor, defaultTextColor] = getUserColor(getUserColorClassName(user));
  if (userImageString) {
    return `
 <defs>
  <clipPath id="imageContainer">
     <circle cx="${half}" cy="${half}" r="${half}"  />
  </clipPath>
</defs>
<image ${
      greyed ? `style="filter: grayscale(100%);"` : ""
    } href="${userImageString}" height="${size}" width="${size}" clip-path="url(#imageContainer)"/>`;
  }

  return getSvgForCircleWithText({
    fillColor: fillColor ?? backgroundColor,
    fontFamily,
    fontSize,
    size,
    strokeColor,
    text: getInitials(user),
    textColor: textColor ?? defaultTextColor,
  });
};

export default getAvatarSvg;
