import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";

import { getDefaultColorPinLegend, getDefaultShapePinLegend } from "../../pinLegend";

const getColorShapeForEntity = (
  mapViewState: MapViewState | undefined,
  colorPinLegends: PinLegend[],
  shapePinLegends: PinLegend[],
  entityType: EntityTypesSupportedByMapsPage
): { color?: PinLegend["id"]; shape?: PinLegend["id"] } | undefined => {
  const color =
    mapViewState?.colorKey?.[entityType] ??
    getDefaultColorPinLegend(colorPinLegends, entityType)?.id;
  const shape =
    mapViewState?.shapeKey?.[entityType] ??
    getDefaultShapePinLegend(shapePinLegends, entityType)?.id;
  return color || shape ? { color, shape } : undefined;
};

export default getColorShapeForEntity;
