import memoize from "fast-memoize";

import FieldCategory from "@mapmycustomers/shared/enum/fieldModel/FieldCategory";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import IntegrationService from "@mapmycustomers/shared/enum/integrations/IntegrationService";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import CustomFieldType from "@mapmycustomers/shared/types/customField/CustomField";
import { EntitySupportingCustomFields } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import IFieldModel, {
  CategorizedFields,
} from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import { FilterOptionValue } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import RangeModel from "@mapmycustomers/shared/types/viewModel/internalModel/RangeModel";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import i18nService, { Intl } from "@app/config/I18nService";
import { displayNameComparator } from "@app/util/comparator";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";

import isFieldRequiredForIntegration from "../../integrations/isFieldRequiredForIntegration";

import CustomField from "./CustomField";
import { sortFields } from "./fieldModelUtil";

const skipInvisibleFieldsFilter = (field: IField) => !field.isArchived;

export const getEmptyCategorizedFields = (): CategorizedFields => ({
  [FieldCategory.ADDRESS]: [],
  [FieldCategory.COMPANY_ADDRESS_FIELDS]: [],
  [FieldCategory.DEAL_ADDRESS_FIELDS]: [],
  [FieldCategory.DETAILS]: [],
  [FieldCategory.NON_REQUIRED]: [],
  [FieldCategory.PARENT_COMPANY_ADDRESS_FIELDS]: [],
  [FieldCategory.PEOPLE_ADDRESS_FIELDS]: [],
  [FieldCategory.RELATIONSHIPS]: [],
  [FieldCategory.REQUIRED]: [],
});

class FieldModel implements IFieldModel {
  public static readonly NAME_FIELD_NAME = "name";

  private readonly _fields: IField[];
  private _customFields: CustomField[];
  private _schema: SchemaField[];
  private readonly _nameToFieldMap: Map<string, IField>;
  private readonly _platformNameToFieldMap: Map<string, IField>;
  private readonly _filterNameToFieldMap: Map<string, IField>;
  private _fieldFilters: Array<(field: IField) => boolean>;

  private _fieldsGetter: (customFields: CustomField[]) => IField[] = () => [];
  private _sortedFieldsGetter: (customFields: CustomField[], intl: Intl | undefined) => IField[];
  readonly _integrationNameToFieldMap: Map<string, IField>;

  /**
   * @param fields fields
   */
  constructor(fields: IField[]) {
    this._fields = fields;
    this._customFields = [];
    this._schema = [];
    this._fieldFilters = [
      // let's always skip invisible fields in all results
      skipInvisibleFieldsFilter,
    ];
    this._nameToFieldMap = new Map(fields.map((field) => [field.name, field]));
    this._platformNameToFieldMap = new Map(fields.map((field) => [field.platformName, field]));
    this._filterNameToFieldMap = new Map(
      fields
        .filter(({ filterName, name }) => name === filterName) // only include original fields
        .map((field) => [field.filterName, field])
    );
    this._integrationNameToFieldMap = new Map(
      fields.map((field) => [field.integrationName, field])
    );

    this._createFieldsGetter();

    // intl argument is needed to force re-sort when intl gets initialized
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._sortedFieldsGetter = memoize((customFields: CustomField[], intl): IField[] =>
      sortFields(this._fieldsGetter(customFields))
    );
  }

  private _createFieldsGetter() {
    this._fieldsGetter = memoize((customFields: CustomField[]): IField[] => {
      const fields = this._fields.concat(customFields);
      return this._fieldFilters.reduce((result, filter) => result.filter(filter), fields);
    });
  }

  get fields(): IField[] {
    return this._fieldsGetter(this._customFields);
  }

  get sortedFields() {
    // pass intl to force re-sort when intl gets initialized
    return this._sortedFieldsGetter(this._customFields, i18nService.getIntl());
  }

  get categorizedFields(): CategorizedFields {
    const result = this.sortedFields.reduce<CategorizedFields>((result, field) => {
      if (field.hasFeature(FieldFeature.REQUIRED)) {
        result[FieldCategory.REQUIRED].push(field);
      } else if (field.hasFeature(FieldFeature.RELATIONSHIPS)) {
        result[FieldCategory.RELATIONSHIPS].push(field);
      } else if (field.hasFeature(FieldFeature.DETAILS)) {
        result[FieldCategory.DETAILS].push(field);
      } else if (field.hasFeature(FieldFeature.PARENT_COMPANY_ADDRESS_FIELD)) {
        result[FieldCategory.PARENT_COMPANY_ADDRESS_FIELDS].push(field);
      } else if (field.hasFeature(FieldFeature.COMPANY_ADDRESS_FIELD)) {
        result[FieldCategory.COMPANY_ADDRESS_FIELDS].push(field);
      } else if (field.hasFeature(FieldFeature.PEOPLE_ADDRESS_FIELD)) {
        result[FieldCategory.PEOPLE_ADDRESS_FIELDS].push(field);
      } else if (field.hasFeature(FieldFeature.DEAL_ADDRESS_FIELD)) {
        result[FieldCategory.DEAL_ADDRESS_FIELDS].push(field);
      } else if (field.hasFeature(FieldFeature.ADDRESS)) {
        result[FieldCategory.ADDRESS].push(field);
      } else {
        result[FieldCategory.NON_REQUIRED].push(field);
      }
      return result;
    }, getEmptyCategorizedFields());
    Object.keys(result).forEach((category) => {
      result[category as FieldCategory] =
        result[category as FieldCategory].sort(displayNameComparator);
    });
    return result;
  }

  getIntegrationCategorizedFields = (service?: IntegrationService): CategorizedFields => {
    const result = this.categorizedFields;
    const requiredAndNonRequiredFields = [
      ...result[FieldCategory.REQUIRED],
      ...result[FieldCategory.NON_REQUIRED],
    ];
    result[FieldCategory.REQUIRED] = [];
    result[FieldCategory.NON_REQUIRED] = [];
    requiredAndNonRequiredFields
      // SALESFORCE and DYNAMICS service don't support funnel field
      .filter(
        (field) =>
          !field.hasFeature(FieldFeature.FUNNEL_FIELD) ||
          (service !== IntegrationService.SALESFORCE && service !== IntegrationService.DYNAMICS)
      )
      .forEach((field) => {
        if (isFieldRequiredForIntegration(field, service)) {
          result[FieldCategory.REQUIRED].push(field);
        } else {
          result[FieldCategory.NON_REQUIRED].push(field);
        }
      });
    return result;
  };

  get categorizedFieldsForDataView(): Record<FieldCategory, IField[]> {
    const categories = this.categorizedFields;
    return Object.keys(categories).reduce((result, categoryKey) => {
      const fields = categories[categoryKey as FieldCategory].filter((field) =>
        field.hasFeature(FieldFeature.DATA_VIEW)
      );
      return {
        ...result,
        ...(fields.length ? { [categoryKey as FieldCategory]: fields } : {}),
      };
    }, {} as Record<FieldCategory, IField[]>);
  }

  getByName(name: string): IField | undefined {
    const field = this._nameToFieldMap.get(name);
    return field && this._fieldFilters.every((filter) => filter(field)) ? field : undefined;
  }

  getByPlatformName(name: string): IField | undefined {
    return this._platformNameToFieldMap.get(name);
  }

  getByFilterName(name: string): IField | undefined {
    const field = this._filterNameToFieldMap.get(name);
    return field && this._fieldFilters.every((filter) => filter(field)) ? field : undefined;
  }

  /**
   * Updates field model with given custom fields
   * @param {CustomFieldType[]} fields custom fields as they're returned by the backend
   */
  setCustomFields = (fields: CustomFieldType[]) => {
    // remove current custom field from field mapping first
    this._customFields.forEach((cf) => {
      this._nameToFieldMap.delete(cf.name);
      this._platformNameToFieldMap.delete(cf.name);
      this._integrationNameToFieldMap.delete(cf.customFieldData.crmPropertyKey);
    });
    this._customFields = fields.map((field) => {
      const customField = new CustomField(field);
      // For custom fields we have two mappings. From CF name (which is esKey) to field. And from CF id to field
      // This is needed for backwards compatibility when we changed the meaning of the CustomField.name
      // field from id to esKey. Specifically this is needed to guarantee that all filters
      // saved in localstorage are readable.
      this._nameToFieldMap.set(customField.name, customField);
      this._nameToFieldMap.set(`${customField.customFieldData.id}`, customField);
      this._platformNameToFieldMap.set(customField.name, customField);
      this._platformNameToFieldMap.set(`${customField.customFieldData.id}`, customField);
      this._filterNameToFieldMap.set(customField.filterName, customField);
      this._filterNameToFieldMap.set(`${customField.customFieldData.id}`, customField);
      this._integrationNameToFieldMap.set(customField.customFieldData.crmPropertyKey, customField);
      return customField;
    });
  };

  setSchema = (schema: SchemaField[]) => {
    this._schema = schema;
    schema.forEach((schemaField) => {
      const field = this._platformNameToFieldMap.get(schemaField.field);
      field?.setSchema(schemaField);
    });
    // re-create fields getter
    this._createFieldsGetter();
  };

  get hasCustomFields(): boolean {
    return !!this._customFields.length;
  }

  doesEntityHaveCustomFieldsWithValues(entity: EntitySupportingCustomFields): boolean {
    return this._customFields.some((field) => field.hasNonEmptyValueFor(entity));
  }

  doesEntityHaveAddress(entity: unknown): boolean {
    return this._fields.some(
      (field) => field.hasFeature(FieldFeature.ADDRESS) && field.hasNonEmptyValueFor(entity)
    );
  }

  getDefaultListViewState(): ViewState {
    const range: RangeModel = { endRow: DEFAULT_PAGE_SIZE, startRow: 0 };
    const filter: FilterModel = {};
    const sort: SortModel = this.getDefaultSortOrder();

    // by default get fields in the same order they were defined
    // in a field model. Make custom fields hidden.
    const columns: ColumnModel = this.sortedFields
      .filter((field) => !field.hasFeature(FieldFeature.NON_LIST_VIEW))
      .map((field) => ({
        field,
        pinned: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? "left" : undefined,
        visible: field.hasFeature(FieldFeature.VISIBLE_BY_DEFAULT),
        width: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? 380 : undefined,
      }));

    return { columns, filter, range, selectedSavedFilterId: undefined, sort, viewAs: undefined };
  }

  // try to sort by updatedAt descending if such field exists
  // try to sort by name ascending if such field exists
  // otherwise don't sort, use some arbitrary order from platform
  private getDefaultSortOrder(): SortModel {
    const sortModel: SortModel = [];
    const updatedAtField = this.getByName("updatedAt");
    if (updatedAtField) {
      sortModel.push({ field: updatedAtField, order: SortOrder.DESC });
    } else {
      const nameField = this.getByName("name");
      if (nameField) {
        sortModel.push({ field: nameField, order: SortOrder.ASC });
      }
    }
    return sortModel;
  }

  getByIntegrationName(name: string): IField | undefined {
    return this._integrationNameToFieldMap.get(name);
  }

  setFilterOption(option: FilterOption, value: FilterOptionValue, onlyField?: IField) {
    this.fields.forEach((field) => {
      if (!onlyField || (onlyField && onlyField.name === field.name)) {
        field.setFilterOption(option, value);
      }
    });
  }

  addFieldFilter(filter: (field: IField) => boolean): void {
    this._fieldFilters.push(filter);

    // re-create field getters
    this._fieldsGetter = memoize((customFields: CustomField[]): IField[] => {
      const fields = this._fields.concat(customFields);
      return this._fieldFilters.reduce((result, filter) => result.filter(filter), fields);
    });
    // intl is needed to force re-sort when intl gets initialized
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._sortedFieldsGetter = memoize((customFields: CustomField[], intl): IField[] =>
      sortFields(this._fieldsGetter(customFields))
    );
  }

  removeFieldFilter(filter: (field: IField) => boolean): void {
    this._fieldFilters = this._fieldFilters.filter((item) => item !== filter);

    // re-create field getters
    this._fieldsGetter = memoize((customFields: CustomField[]): IField[] => {
      const fields = this._fields.concat(customFields);
      return this._fieldFilters.reduce((result, filter) => result.filter(filter), fields);
    });
    // intl is needed to force re-sort when intl gets initialized
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._sortedFieldsGetter = memoize((customFields: CustomField[], intl): IField[] =>
      sortFields(this._fieldsGetter(customFields))
    );
  }

  get emailDynamicVarFields(): IField[] {
    return this.fields.filter((field) => {
      return field.hasFeature(FieldFeature.EMAIL_DYNAMIC_VAR);
    });
  }
}

export default FieldModel;
