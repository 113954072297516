import React, { useCallback } from "react";
import { connect } from "react-redux";

import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";

import PeopleAssociation from "@app/component/associations/PeopleAssociation";

import { addCompanyPeople, addDealParentPerson } from "../store/actions";

interface Props {
  addCompanyPeople: typeof addCompanyPeople.request;
  addDealParentPerson: typeof addDealParentPerson.request;
  createdRecord: Company | Deal | Person;
  entityType?: EntityTypeSupportingPostCreationModal;
  onHide: () => void;
}
const People: React.FC<Props> = ({
  addCompanyPeople,
  addDealParentPerson,
  createdRecord,
  entityType,
  onHide,
}) => {
  const onAddPeople = useCallback(
    (peopleIds: Person["id"][]) => {
      if (entityType === EntityType.COMPANY) {
        addCompanyPeople({ id: createdRecord.id, peopleIds });
      } else if (entityType === EntityType.DEAL) {
        addDealParentPerson({ id: createdRecord.id, parentPersonId: peopleIds[0] });
      }
      onHide();
    },
    [createdRecord, addCompanyPeople, addDealParentPerson, entityType, onHide]
  );

  const parentPerson = entityType === EntityType.DEAL ? (createdRecord as Deal).contact : undefined;

  return (
    <PeopleAssociation
      assignedPeople={parentPerson ? [parentPerson] : undefined}
      fixedCompany={entityType === EntityType.COMPANY ? (createdRecord as Company) : undefined}
      multiselect={entityType === EntityType.DEAL ? false : true}
      onHide={onHide}
      onSelect={onAddPeople}
    />
  );
};

const mapDispatchToProps = {
  addCompanyPeople: addCompanyPeople.request,
  addDealParentPerson: addDealParentPerson.request,
};

export default connect(null, mapDispatchToProps)(People);
