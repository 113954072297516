import notification from "antd/es/notification";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";
import { defineMessage } from "react-intl";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as uuid from "uuid";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import { Deal, EntityType } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import Organization from "@mapmycustomers/shared/types/Organization";

import i18nService from "@app/config/I18nService";
import { callApi } from "@app/store/api/callApi";
import getNextAssociationsState, {
  NextAssociationsState,
} from "@app/store/associations/getNextAssociationsState";
import { handleError } from "@app/store/errors/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import { DEFAULT_NOTIFICATION_DURATION } from "@app/util/consts";
import { allSettled, SettleResult } from "@app/util/effects";
import dealFieldModel from "@app/util/fieldModel/DealFieldModel";

import { changeAssociatedEntities, updateDeal, uploadDealFiles } from "./actions";
import { getOriginalDeal } from "./selectors";

const successMessage = defineMessage({
  id: "editStageModal.success",
  defaultMessage: "Deal updated successfully",
  description: "Deal update success message",
});

export function* onUpdateDeal({ payload }: ReturnType<typeof updateDeal.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const { callback, customFields, deal, groupIdsToAdd, groupIdsToRemove, layoutId } = payload;
    const originalDeal: Deal = yield select(getOriginalDeal);

    const readOnlyFields = new Set(
      dealFieldModel.fields
        .filter((field) => !field.isEditable || field.hasFeature(FieldFeature.CALCULATED_FIELD))
        .map((field) => field.name)
    );

    const changedCustomFields = Object.values(customFields ?? {}).filter((customField) => {
      if (!customField || readOnlyFields.has(customField.esKey)) {
        return false;
      }

      const oldCustomField = originalDeal?.customFields?.find(
        ({ customField: { id } }) => id === customField.customField.id
      );
      return !oldCustomField || !isEqual(oldCustomField.value, customField.value);
    });

    const strippedDeal = readOnlyFields.size
      ? // implement proper fields deletion later
        (omit(deal, Array.from(readOnlyFields)) as Identified)
      : deal;

    yield callApi("updateDeal", org.id, layoutId, {
      ...strippedDeal,
    });

    if (changedCustomFields.length) {
      yield callApi(
        "upsertCustomFieldsValues",
        true,
        org.id,
        layoutId,
        EntityType.DEAL,
        deal.id,
        changedCustomFields
      );
    }

    const dealIds: Deal["id"][] = [deal.id];
    yield all([
      ...(groupIdsToAdd ?? []).map((groupId) =>
        callApi("addToGroup", org.id, groupId, EntityType.DEAL, dealIds)
      ),
      ...(groupIdsToRemove ?? []).map((groupId) =>
        callApi("deleteFromGroup", org.id, groupId, EntityType.DEAL, dealIds)
      ),
    ]);

    const updatedDeal: Deal = yield callApi("fetchDeal", org.id, deal.id, {
      includeAccessStatus: true,
      includeCustomFields: true,
      includeGroups: true,
      includeTerritories: true,
    });

    notification.success({
      duration: DEFAULT_NOTIFICATION_DURATION,
      message: i18nService.formatMessage(successMessage, "Deal updated successfully"),
    });
    yield put(updateDeal.success(updatedDeal));
    if (callback) {
      yield call(callback, updatedDeal);
    }

    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [updatedDeal] }));
  } catch (error) {
    yield put(updateDeal.failure());
    yield put(handleError({ error }));
  }
}

export function* onChangeAssociatedEntity({
  payload,
}: ReturnType<typeof changeAssociatedEntities.request>) {
  try {
    const associationsState: NextAssociationsState = yield call(getNextAssociationsState, {
      ...payload,
      entityType: EntityType.DEAL,
    });

    yield put(changeAssociatedEntities.success(associationsState));
  } catch (error) {
    yield put(changeAssociatedEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onUploadDealFiles({ payload }: ReturnType<typeof uploadDealFiles.request>) {
  const { callback, files } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const fileGroupId = uuid.v4();
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", orgId, file, undefined, undefined, undefined, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        })
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    if (callback) {
      yield call(callback, fileList);
    }
    yield put(uploadDealFiles.success(fileList));
  } catch (error) {
    if (callback) {
      yield call(
        callback,
        payload.files.map((file) => ({ errored: true, file, uploading: false }))
      );
    }
    yield put(uploadDealFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* editStageModalSaga() {
  yield takeLatest(updateDeal.request, onUpdateDeal);
  yield takeEvery(changeAssociatedEntities.request, onChangeAssociatedEntity);
  yield takeEvery(uploadDealFiles.request, onUploadDealFiles);
}
