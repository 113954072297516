import React from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import Tooltip, { TooltipProps } from "antd/es/tooltip";
import cn from "classnames";

import styles from "./TextWithInfo.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  iconProps?: Omit<FontAwesomeIconProps, "icon">;
  info?: React.ReactNode;
  inline?: boolean;
  placeIconOnLeftSide?: boolean;
  placement?: TooltipProps["placement"];
  tooltipOverlayClassName?: string;
}

const TextWithInfo: React.FC<Props> = ({
  children,
  className,
  iconProps,
  info,
  inline,
  placeIconOnLeftSide,
  placement = "rightTop",
  tooltipOverlayClassName,
}) => {
  const { className: iconClassName, ...otherIconProps } = iconProps ?? {};
  return (
    <div className={cn(styles.container, className, { [styles.inline]: inline })}>
      {children && !placeIconOnLeftSide && <span className={styles.text}>{children}</span>}
      {info && (
        <Tooltip overlayClassName={tooltipOverlayClassName} placement={placement} title={info}>
          <FontAwesomeIcon
            className={cn(styles.icon, iconClassName, { [styles.sized]: !iconProps?.size })}
            icon={faInfoCircle}
            {...otherIconProps}
          />
        </Tooltip>
      )}
      {children && placeIconOnLeftSide && <span className={styles.text}>{children}</span>}
    </div>
  );
};

export default TextWithInfo;
