import { ActionType, createAction } from "typesafe-actions";

import { MapLayer } from "@app/scene/map/store/index";

import { AlertBadgesActions } from "./alerts/actions";
import { BoundariesActions } from "./boundaries/actions";
import { HeatMapActions } from "./heatMap/actions";
import { TrafficActions } from "./traffic/actions";
import { UserLocationActions } from "./userLocations/actions";

export const toggleLayerVisibility = createAction("map/layers/toggleVisibility")<{
  name: MapLayer;
  visible: boolean;
}>();

export const toggleLayerLegendExpandedState = createAction("map/layers/toggleLegendExpandedState")<{
  expanded: boolean;
  name: MapLayer;
}>();

export * from "./alerts/actions";
export * from "./boundaries/actions";
export * from "./heatMap/actions";
export * from "./userLocations/actions";

export type LayersActions =
  | ActionType<typeof toggleLayerLegendExpandedState | typeof toggleLayerVisibility>
  | AlertBadgesActions
  | BoundariesActions
  | HeatMapActions
  | TrafficActions
  | UserLocationActions;
