import AssociationsState from "./AssociationsState";

export const DEFAULT_ASSOCIATIONS_STATE: Readonly<AssociationsState> = {
  associatedCompany: undefined,
  associatedDeal: undefined,
  associatedPerson: undefined,
  availableCompanies: [],
  availableDeals: [],
  availableLoading: false,
  availablePeople: [],
  inaccessibleEntity: false,
};
