import { MapEntity } from "@mapmycustomers/shared/types/entity";

export const mapEntityIdParser = (
  mapEntityId: string
): Pick<MapEntity, "entity" | "id"> | undefined => {
  const parts = mapEntityId.split(":");
  return parts.length === 2
    ? { id: parseInt(parts[1], 10), entity: parts[0] as MapEntity["entity"] }
    : undefined;
};
