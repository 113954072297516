import { createSelector } from "reselect";
import { createReducer } from "typesafe-actions";

import { RootState } from "../rootReducer";

import { Actions, initializeApp } from "./actions";

export type AppState = {
  initialized: boolean;
  initializing: boolean;
};

const initialState: AppState = {
  initialized: false,
  initializing: false,
};

const app = createReducer<AppState, Actions>(initialState)
  .handleAction(initializeApp.request, (state) => ({
    ...state,
    initialized: false,
    initializing: true,
  }))
  .handleAction(initializeApp.success, (state) => ({
    ...state,
    initialized: true,
    initializing: false,
  }))
  .handleAction(initializeApp.failure, (state) => ({
    ...state,
    initialized: false,
  }));

const appState = (state: RootState) => state.app;
export const isInitialized = createSelector(appState, ({ initialized }) => initialized);
export const isInitializing = createSelector(appState, ({ initializing }) => initializing);

export type AppActions = Actions;
export default app;
