import React, { ReactNode } from "react";

import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "antd/es/modal";
import { defineMessages, IntlShape } from "react-intl";

import LeadFinderSubscriptionPlan from "@mapmycustomers/shared/enum/LeadFinderSubscriptionPlan";

import upsellImage from "@app/assets/map/leadFinderUpsell.svg";
import { LEAD_FINDER_UPSELL_URL } from "@app/scene/map/utils/consts";

import styles from "./ShowLeaderFinderUpsell.module.scss";

const messages = defineMessages({
  addCompanies: {
    id: "map.tool.leadFinder.upsell.description.addCompanies",
    defaultMessage: "Add companies directly from the map",
    description: "Add companies directly bullet point",
  },
  cancel: {
    id: "map.tool.leadFinder.upsell.cancel.button.title",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
  contactOwner: {
    id: "map.tool.leadFinder.upsell.contactOwner.description",
    defaultMessage: "Let your manager or administrator know you’d like to use Lead Finder!",
    description: "Contact owner description",
  },
  extraData: {
    id: "map.tool.leadFinder.upsell.description.extraData",
    defaultMessage: "Extra data like hours, site, and contact info",
    description: "Extra data bullet point",
  },
  findNewLeads: {
    id: "map.tool.leadFinder.upsell.description.findNewLeads",
    defaultMessage: "Find new leads by location",
    description: "Find new leads bullet point",
  },
  interestedButton: {
    id: "map.tool.leadFinder.upsell.interested.button.title",
    defaultMessage: "I’m Interested",
    description: "I’m Interested button",
  },
  interestedDescription: {
    id: "map.tool.leadFinder.upsell.interested.description",
    defaultMessage: "Tap “I’m interested” and we’ll have your account manager reach out.",
    description: "I am interested message for owners",
  },
  moveAround: {
    id: "map.tool.leadFinder.upsell.description.moveAround",
    defaultMessage: "Move around the map to get more results",
    description: "Move around map bullet point",
  },
  multipleResults: {
    id: "map.tool.leadFinder.upsell.description.multipleResults",
    defaultMessage: "3x results per search",
    description: "Multiple results bullet point",
  },
  okText: {
    id: "map.tool.leadFinder.upsell.ok.button.title",
    defaultMessage: "Ok",
    description: "Ok button",
  },
  purchaseModalTitle: {
    id: "map.tool.leadFinder.upsell.purchase.modalTitle",
    defaultMessage: "Add on <p>Lead Finder</p> to prospect from anywhere:",
    description: "Upgrade lead finder title",
  },
  syncData: {
    id: "map.tool.leadFinder.upsell.description.syncData",
    defaultMessage: "Sync new companies back to your CRM",
    description: "Sync data bullet point",
  },
  upgradeModalTitle: {
    id: "map.tool.leadFinder.upsell.upgrade.modalTitle",
    defaultMessage: "Get more from your lead results with <p>Lead Finder</p>",
    description: "Upgrade lead finder title",
  },
  upgradeTitle: {
    id: "map.tool.leadFinder.upsell.upgrade.title",
    defaultMessage: "Upgrade from your basic lead gen for:",
    description: "Updrade from basic",
  },
});

const basicPlanBulletPoints = [messages.multipleResults, messages.moveAround, messages.extraData];
const upsellBulletPoints = [
  messages.findNewLeads,
  messages.addCompanies,
  messages.syncData,
  messages.extraData,
];

const showLeadFinderUpsell = (
  leadGenPlan: LeadFinderSubscriptionPlan | undefined,
  intl: IntlShape,
  isOwner: boolean
) => {
  const isBasicPlan = leadGenPlan === LeadFinderSubscriptionPlan.BASIC;
  const handleOk = () => {
    window.open(LEAD_FINDER_UPSELL_URL, "_blank");
  };

  Modal.confirm({
    cancelButtonProps: { hidden: !isOwner },
    cancelText: isOwner ? intl.formatMessage(messages.cancel) : undefined,
    className: styles.alert,
    content: (
      <div className={styles.container}>
        <img alt="leadFinderUpsell" className={styles.image} src={upsellImage} />
        <div className={styles.content}>
          <div className={styles.title}>
            {intl.formatMessage(
              isBasicPlan ? messages.upgradeModalTitle : messages.purchaseModalTitle,
              {
                p: (text: ReactNode[]) => <span className={styles.primary}>{text}</span>,
              }
            )}
          </div>
          {isBasicPlan && <span>{intl.formatMessage(messages.upgradeTitle)}</span>}
          <div className={styles.bullets}>
            {(isBasicPlan ? basicPlanBulletPoints : upsellBulletPoints).map((message) => (
              <div className={styles.point} key={message.id}>
                <FontAwesomeIcon className={styles.primary} icon={faCircleCheck} size="lg" />
                <span>{intl.formatMessage(message)}</span>
              </div>
            ))}
          </div>
          <span className={styles.bottomText}>
            {intl.formatMessage(isOwner ? messages.interestedDescription : messages.contactOwner)}
          </span>
        </div>
      </div>
    ),
    icon: null,
    okText: intl.formatMessage(isOwner ? messages.interestedButton : messages.okText),
    onOk: isOwner ? handleOk : undefined,
    wrapClassName: styles.upsellWrapper,
  });
};

export default showLeadFinderUpsell;
