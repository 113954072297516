import React, { CSSProperties, MouseEvent, useCallback } from "react";

import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { EntityType } from "@mapmycustomers/shared/enum";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import styles from "./EntityTypeShapedIcon.module.scss";
import EntityTypeIcon from "./index";

interface Props extends Omit<FontAwesomeIconProps, "icon"> {
  containerClassName?: string;
  entityType: EntityType;
  onClick?: () => void;
  shape?: "circle" | "square";
  style?: CSSProperties;
}

const EntityTypeShapedIcon: React.FC<Props> = ({
  containerClassName,
  entityType,
  onClick,
  shape = "circle",
  style,
  ...fontAwesomeIconProps
}) => {
  const handleClick = useCallback(
    (event?: MouseEvent | React.MouseEvent<HTMLElement>) => {
      stopEvents(event);
      onClick?.();
    },
    [onClick]
  );
  return (
    <div
      className={cn(styles.container, styles[shape], styles[entityType], containerClassName)}
      onClick={handleClick}
      style={style}
    >
      <EntityTypeIcon {...fontAwesomeIconProps} color="white" entityType={entityType} />
    </div>
  );
};

export default EntityTypeShapedIcon;
