import React, { useCallback } from "react";
import { connect } from "react-redux";

import ImgCrop from "antd-img-crop";
import Upload from "antd/es/upload";
import { ShowUploadListInterface, UploadFile } from "antd/es/upload/interface";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { FormattedMessage, useIntl } from "react-intl";

import { UserRef } from "@mapmycustomers/shared/types/User";
import { Modal } from "@mapmycustomers/ui";

import { RootState } from "@app/store/rootReducer";

import {
  isConfirmDeleteProfilePhotoModalVisible,
  isDeleteProfilePhotoLoading,
  isUploadProfilePhotoLoading,
} from "../../store/iam";
import {
  deleteProfilePhoto,
  hideDeleteProfilePhotoModal,
  showDeleteProfilePhotoModal,
  uploadProfilePhoto,
} from "../../store/iam/actions";

import useProfilePhotoFileList from "./useProfilePhotoFileList";

const uploadIconsConfig: ShowUploadListInterface = {
  showDownloadIcon: false,
  showPreviewIcon: false,
  showRemoveIcon: true,
} as const;

interface Props {
  deleteProfilePhoto: typeof deleteProfilePhoto.request;
  hideDeleteProfilePhotoModal: typeof hideDeleteProfilePhotoModal;
  isConfirmDeleteProfilePhotoModalVisible: boolean;
  isDeletionInProgress: boolean;
  isUploadingInProgress: boolean;
  showDeleteProfilePhotoModal: typeof showDeleteProfilePhotoModal;
  uploadProfilePhoto: typeof uploadProfilePhoto.request;
  user: UserRef;
}

const EditProfilePhoto: React.FC<Props> = ({
  deleteProfilePhoto,
  hideDeleteProfilePhotoModal,
  isConfirmDeleteProfilePhotoModalVisible,
  isDeletionInProgress,
  isUploadingInProgress,
  showDeleteProfilePhotoModal,
  uploadProfilePhoto,
  user,
}) => {
  const intl = useIntl();

  const fileList: UploadFile[] = useProfilePhotoFileList(isUploadingInProgress, user.profilePhoto);

  const handleCustomRequest = useCallback(
    async ({ file }: UploadRequestOption) => {
      uploadProfilePhoto(file as Blob | File);
    },
    [uploadProfilePhoto]
  );

  const handleRemoveClick = useCallback(() => {
    showDeleteProfilePhotoModal();
    return false; // don't update the fileList
  }, [showDeleteProfilePhotoModal]);

  const handleDeletePhoto = useCallback(() => deleteProfilePhoto(), [deleteProfilePhoto]);

  return (
    <div>
      <ImgCrop
        modalTitle={intl.formatMessage({
          id: "editProfilePicture.crop.title",
          defaultMessage: "Edit image",
          description: "Crop/rotate user profile photo modal title",
        })}
        rotationSlider
      >
        <Upload
          customRequest={handleCustomRequest}
          fileList={fileList}
          iconRender={() => null}
          listType="picture-card"
          multiple={false}
          onRemove={handleRemoveClick}
          showUploadList={uploadIconsConfig}
        >
          {fileList.length > 0
            ? intl.formatMessage({
                id: "editProfilePicture.replace",
                defaultMessage: "Click here to replace photo",
                description: "Title of the upload user profile CTA when he has an avatar already",
              })
            : intl.formatMessage({
                id: "editProfilePicture.upload",
                defaultMessage: "Click here to choose photo",
                description: "Title of the upload user profile CTA when he has no avatar yet",
              })}
        </Upload>
      </ImgCrop>
      <Modal
        cancelText={intl.formatMessage({
          id: "editProfilePicture.deleteConfirmation.cancel",
          defaultMessage: "Cancel",
          description: "Cancel button title in a delete profile photo confirmation modal.",
        })}
        okButtonProps={{ loading: isDeletionInProgress }}
        okText={intl.formatMessage({
          id: "editProfilePicture.deleteConfirmation.ok",
          defaultMessage: "Delete",
          description: "Ok button title in a delete profile photo confirmation modal.",
        })}
        onCancel={hideDeleteProfilePhotoModal}
        onOk={handleDeletePhoto}
        visible={isConfirmDeleteProfilePhotoModalVisible}
      >
        <br />
        <FormattedMessage
          id="editProfilePhoto.deleteConfirmation"
          defaultMessage="Do you want to delete your profile photo? This action can not be undone."
          description="Text in a delete profile photo confirmation modal."
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isConfirmDeleteProfilePhotoModalVisible: isConfirmDeleteProfilePhotoModalVisible(state),
  isDeletionInProgress: isDeleteProfilePhotoLoading(state),
  isUploadingInProgress: isUploadProfilePhotoLoading(state),
});

const mapDispatchToProps = {
  deleteProfilePhoto: deleteProfilePhoto.request,
  hideDeleteProfilePhotoModal: hideDeleteProfilePhotoModal,
  showDeleteProfilePhotoModal: showDeleteProfilePhotoModal,
  uploadProfilePhoto: uploadProfilePhoto.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePhoto);
