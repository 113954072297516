import CurrentPlan from "@mapmycustomers/shared/types/plans/CurrentPlan";

import Iam from "@app/types/Iam";
import hasTrialExpired from "@app/util/hasTrialExpired";
import { parseApiDate } from "@app/util/parsers";

import { convertPlanToTier } from "./tier";

const buildCurrentPlan = (me: Iam): CurrentPlan => {
  const { plan } = me.organization;
  let nextPaymentDate = new Date(); // now, will be used for current paid plans
  if (plan.trialing) {
    nextPaymentDate = parseApiDate(me.organization.trialExpiresAt);
  } else if (plan.nextPaymentDate) {
    nextPaymentDate = parseApiDate(plan.nextPaymentDate);
  }
  return {
    isExpired: hasTrialExpired(me.organization) ?? false,
    isOldPlan: !(plan.metaData && plan.metaData.licenseLimit),
    licenses: plan.trialing ? 0 : plan.quantity,
    nextPaymentDate,
    tier: convertPlanToTier(plan),
  };
};

export default buildCurrentPlan;
