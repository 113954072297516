enum BaseMapStyle {
  STANDARD = "standard",
  MUTED = "muted",
  GRAY = "gray",
  MIDNIGHT = "midnight",
  HOPPER = "hopper",
  ASSASSIAN_CREED = "assassianCreed",
  SUBTLE_GRAY = "subtleGray",
  TRIPPITY = "trippity",
  NIGHT = "night",
  SATELLITE = "satellite",
  HYBRID = "hybrid",
}

export const isDarkMapStyle = (style: BaseMapStyle): boolean =>
  [BaseMapStyle.HYBRID, BaseMapStyle.MIDNIGHT, BaseMapStyle.NIGHT, BaseMapStyle.SATELLITE].includes(
    style
  );

export default BaseMapStyle;
