import React from "react";

import Form from "antd/es/form";
import { defineMessage, useIntl } from "react-intl";

import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { FormItem, TextField } from "@mapmycustomers/ui";

import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";

import useActivityNameValidationRules from "../utils/useActivityNameValidationRules";

interface Props {
  activityTypes: ActivityType[];
  disabled?: boolean;
  onChange?(name: string): void;
  required?: boolean;
  title?: string;
}

const label = defineMessage({
  id: "createEditActivityModal.field.name",
  defaultMessage: "Activity Name",
  description: "Activity name field on the Create Activity modal",
});

const ActivityNameField: React.FC<Props> = ({
  activityTypes,
  disabled,
  onChange,
  required,
  title,
}) => {
  const intl = useIntl();

  const [nameFocused, setNameFocused, resetNameFocused] = useBoolean();
  const activityNameValidationRules = useActivityNameValidationRules(intl);

  return (
    <Form.Item dependencies={[ActivityFieldName.NAME, "activityTypeId"]} noStyle>
      {(form) => {
        const name = form.getFieldValue(ActivityFieldName.NAME);
        const activityTypeId = form.getFieldValue("activityTypeId");

        // Name or activity type name. Thing with nameFocused is to allow user to clear field
        // when its focused to allow user enter his own name.
        const nameFieldValue = nameFocused
          ? name
          : name.trim() || activityTypes.find(({ id }) => id === activityTypeId)?.name || "";

        return (
          <FormItem
            dependencies={["activityTypeId"]}
            label={title ?? intl.formatMessage(label)}
            name={ActivityFieldName.NAME}
            rules={required ? activityNameValidationRules : undefined}
          >
            <TextField
              disabled={disabled}
              label={intl.formatMessage(label)}
              onBlur={resetNameFocused}
              onChange={onChange}
              onFocus={setNameFocused}
              required={required}
              value={nameFieldValue}
            />
          </FormItem>
        );
      }}
    </Form.Item>
  );
};

export default ActivityNameField;
