import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const componentState = (state: RootState) => state.component.navigationBar;

export const isSearchLoading = createSelector(componentState, ({ searchLoading }) => searchLoading);
export const getSearchResults = createSelector(
  componentState,
  ({ searchResults }) => searchResults
);
