import React from "react";

import FieldModel from "@app/util/fieldModel/impl/FieldModel";

import IViewContext from "../types/IViewContext";

// Create view context with some default values
const ViewContext = React.createContext<IViewContext>({
  entityType: undefined,
  fieldModel: new FieldModel([]),
  register: () => {},
  totalRecords: 0,
  unregister: () => {},
  updateColumnVisibility: () => {},
  updateEntity: () => {},
  updateFilterModel: () => {},
  updateViewAs: () => {},
  updateViewState: () => {},
  updateViewStateAndFetch: () => {},
  viewState: {
    columns: [],
    filter: {},
    range: { endRow: 0, startRow: 0 },
    sort: [],
    viewAs: undefined,
  },
});

export default ViewContext;
