import React from "react";

import { FormattedMessage } from "react-intl";

import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";

interface Props {}

const ExpiredCodeError: React.FC<Props> = () => {
  return (
    <div>
      <p className={commonStyles.title}>
        <FormattedMessage
          id="auth.registerMember.error.expiredCode.title"
          defaultMessage="Your invite has expired"
          description="Title of the expired code state in the member register form"
        />
      </p>
      <p className={commonStyles.description}>
        <FormattedMessage
          id="auth.registerMember.error.expiredCode.description"
          defaultMessage="Your invitation link to join Map My Customers is no longer valid. Please contact your organization owner to send another invite."
          description="Description of the expired code state in the member register form"
        />
      </p>
    </div>
  );
};

export default ExpiredCodeError;
