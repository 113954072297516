import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";

import SelectField from "@app/component/input/SelectField";
import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import { fetchDealLossReasons } from "@app/store/dealLossReasons/actions";
import { getDealLossReasons, isDealLossReasonsLoading } from "@app/store/dealLossReasons/selectors";
import { RootState } from "@app/store/rootReducer";

import defaultSelectOptionMapper from "../../mappers/defaultSelectOptionMapper";

interface Props {
  className?: string;
  fetchDealLossReasons: typeof fetchDealLossReasons.request;
  loading: boolean;
  lossReasons: DealLossReason[];
  onChange: (lossReasons: DealLossReason["id"][]) => void;
  value?: DealLossReason["id"][];
}

const LossReasonComponent: React.FC<Props> = ({
  className,
  fetchDealLossReasons,
  loading,
  lossReasons,
  onChange,
  value,
}) => {
  useEffect(() => {
    fetchDealLossReasons();
  }, [fetchDealLossReasons]);

  const selectOptions = useMemo(() => lossReasons.map(defaultSelectOptionMapper), [lossReasons]);

  return (
    <SelectField<DealLossReason["id"][]>
      className={className}
      filterOption={defaultFilterOption}
      loading={loading}
      maxTagCount="responsive"
      mode="multiple"
      onChange={onChange}
      options={selectOptions}
      value={value}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: isDealLossReasonsLoading(state),
  lossReasons: getDealLossReasons(state),
});

const mapDispatchToProps = {
  fetchDealLossReasons: fetchDealLossReasons.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(LossReasonComponent);
