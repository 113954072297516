import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { faLocationArrow, faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import AutoComplete from "antd/es/auto-complete";
import Input, { InputRef } from "antd/es/input";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import Address from "@mapmycustomers/shared/types/Address";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { getFormattedAddressForUi } from "@mapmycustomers/shared/util/formatters";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useStateWithDebouncedListener from "@mapmycustomers/shared/util/hook/useStateWithDebouncedListener";

import { useConfigProvider } from "../../../ConfigProvider";
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorRow from "../../ErrorRow";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import useGoogleAutoCompleteOptions, {
  MANUAL_ADDRESS_VALUE,
  NO_RESULT_VALUE,
} from "../utils/useGoogleAutoCompleteOptions";
import usePlacePredictionsGetter from "../utils/usePlacePredictionsGetter";
import usePlaceDetailsGetter from "../utils/usePlacesService";

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

const block = bem("mmc-auto-complete-address");

export interface AutoCompleteAddressProps extends Omit<LabeledFieldProps, "children"> {
  allowClear?: boolean;
  caption?: string;
  className?: string;
  disabled?: boolean;
  disableFindMe?: boolean;
  error?: string;
  onChange?: (address?: Address) => void;
  onEnterManually?: (value: string) => void;
  onPressEnter?: () => void;
  onReverseGeocodeAddress: (payload: {
    callback: (result: GeocodeResult) => void;
    coordinates: LongLat;
    failureCallback?: () => void;
  }) => void;
  placeholder?: string;
  placePredictionTypes?: string[];
  ref?: React.Ref<InputRef>;
  value?: Address;
}

const AutoCompleteAddress = forwardRef<InputRef, AutoCompleteAddressProps>(
  (
    {
      allowClear,
      caption,
      className,
      disabled,
      disableFindMe,
      error,
      onChange,
      onEnterManually,
      onPressEnter,
      onReverseGeocodeAddress,
      placeholder,
      placePredictionTypes,
      required,
      value,
      ...labelProps
    },
    ref
  ) => {
    const { formatMessage, useFindMyLocation } = useConfigProvider();

    const [placeList, setPlacesList] = useState<AutocompletePrediction[]>([]);
    const [placeListLoading, startLoading, stopLoading] = useBoolean();

    // Text field state + search listener to find suggestions
    const [placePredictionsGetter] = usePlacePredictionsGetter(placePredictionTypes);
    const [fieldValue, setFieldValue, setFieldValueWithoutSearch] = useStateWithDebouncedListener(
      [
        useCallback(
          async (value: string) => {
            try {
              startLoading();
              const places = await placePredictionsGetter(value);
              setPlacesList(places);
            } finally {
              stopLoading();
            }
          },
          [placePredictionsGetter, startLoading, stopLoading]
        ),
        100,
      ],
      ""
    );
    useEffect(() => {
      setFieldValueWithoutSearch(getFormattedAddressForUi(value));
    }, [setFieldValueWithoutSearch, value]);

    // Get address by place
    const [placeDetailsGetter] = usePlaceDetailsGetter();
    const selectPlace = useCallback(
      async (placeId: string) => {
        const address = await placeDetailsGetter(placeId);
        setFieldValueWithoutSearch(getFormattedAddressForUi(address));
        onChange?.(address);
      },
      [onChange, placeDetailsGetter, setFieldValueWithoutSearch]
    );

    // Handle clicking on any option in a dropdown
    const handleSelect = useCallback(
      (placeId: string) => {
        if ([MANUAL_ADDRESS_VALUE, NO_RESULT_VALUE].includes(placeId)) {
          if (placeId === MANUAL_ADDRESS_VALUE) {
            onEnterManually?.(fieldValue);
          }
          setFieldValue("");
          return;
        }
        const place = placeList.find(({ place_id }) => place_id === placeId);
        if (place) {
          setFieldValue(place.description);
        }
        selectPlace(placeId);
      },
      [fieldValue, onEnterManually, placeList, selectPlace, setFieldValue]
    );

    // Find Me functionality:
    const [, , findMeLoading, findMeError, handleFindMyLocation, geocodingResult] =
      useFindMyLocation(onReverseGeocodeAddress);
    const lastGeocodingResult = useRef<GeocodeResult>();
    useEffect(() => {
      if (lastGeocodingResult.current !== geocodingResult) {
        lastGeocodingResult.current = geocodingResult;
        setPlacesList([]);
        onChange?.(geocodingResult?.address);
      }
    }, [geocodingResult, onChange]);

    const options = useGoogleAutoCompleteOptions(placeList, !!onEnterManually);

    const checkTextAndFindPlaceIfNeeded = useCallback(async () => {
      if (getFormattedAddressForUi(value) !== fieldValue) {
        const places = await placePredictionsGetter(fieldValue);
        if (places.length > 0) {
          selectPlace(places[0].place_id);
        } else if (allowClear) {
          onChange?.(undefined);
        }
      }
    }, [allowClear, fieldValue, placePredictionsGetter, onChange, selectPlace, value]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === "Escape") {
          setFieldValueWithoutSearch(getFormattedAddressForUi(value));
        } else if (event.key === "Enter") {
          checkTextAndFindPlaceIfNeeded();
        }
      },
      [checkTextAndFindPlaceIfNeeded, setFieldValueWithoutSearch, value]
    );

    const handleClear = useCallback(() => {
      onChange?.(undefined);
      setFieldValueWithoutSearch("");
    }, [onChange, setFieldValueWithoutSearch]);

    return (
      <Labeled required={required} {...labelProps}>
        <AutoComplete
          className={cn(block(), className)}
          disabled={disabled}
          dropdownMatchSelectWidth={false}
          onBlur={disabled ? undefined : checkTextAndFindPlaceIfNeeded}
          onChange={disabled ? undefined : setFieldValue}
          onClear={disabled ? undefined : handleClear}
          onKeyDown={disabled ? undefined : handleKeyDown}
          onSelect={disabled ? undefined : handleSelect}
          options={options}
          showSearch={!disabled}
          value={fieldValue}
        >
          <Input
            allowClear={allowClear && !disabled}
            disabled={disabled}
            onPressEnter={onPressEnter}
            placeholder={
              placeholder ??
              (disabled ? undefined : formatMessage("ui.address.autoCompleteAddress.placeholder"))
            }
            ref={ref}
            size="large"
            suffix={
              findMeLoading || placeListLoading ? (
                <LoadingSpinner mini />
              ) : disabled ? (
                <FontAwesomeIcon className={block("lock")} icon={faLock} size="sm" />
              ) : disableFindMe ? (
                // suffix doesn't really work well when markup changes to undefined or null
                // so using an empty div when we don't need to display anything
                <div />
              ) : (
                <Tooltip title={formatMessage("ui.address.findMe.tooltip")}>
                  <FontAwesomeIcon
                    className={block("icon")}
                    icon={faLocationArrow}
                    onClick={handleFindMyLocation}
                    size="lg"
                  />
                </Tooltip>
              )
            }
          />
        </AutoComplete>
        {findMeError || error ? (
          <ErrorRow>{findMeError ? findMeError : error}</ErrorRow>
        ) : caption ? (
          <div className={block("caption")}>{caption}</div>
        ) : null}
      </Labeled>
    );
  }
);

AutoCompleteAddress.displayName = "AutoCompleteAddress";

export default AutoCompleteAddress;
