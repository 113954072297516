import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { EntityType } from "@mapmycustomers/shared";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { Activity } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import localSettings from "@app/config/LocalSettings";
import {
  applyActivitiesOverdueDrillDownListViewSettings,
  exportActivitiesOverdueCardDrillDownData,
  fetchActivitiesOverdueCardData,
  fetchActivitiesOverdueCardDrillDownData,
  showActivitiesOverdueDrillDown,
} from "@app/scene/dashboard/store/cards/activitiesOverdue/actions";
import {
  getActivitiesOverdueDrillDownTotalFilteredRecords,
  getActivitiesOverdueDrillDownViewState,
} from "@app/scene/dashboard/store/cards/activitiesOverdue/selectors";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import expandColumns from "@app/scene/dashboard/utils/expandColumns";
import { callApi } from "@app/store/api/callApi";
import { fetchActivitiesOverdueCardDataHelper } from "@app/store/dashboard/cardDataFetchHelpers";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganizationId } from "@app/store/iam";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";
import loggingService from "@app/util/logging";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

function* onFetchActivitiesOverdueCardData({
  payload,
}: ReturnType<typeof fetchActivitiesOverdueCardData>) {
  const { callback, configuration, failureCallback, scope } = payload;

  try {
    yield call(fetchActivitiesOverdueCardDataHelper, { callback, configuration, scope });
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showActivitiesOverdueDrillDown.request>) {
  const { configuration, scope } = payload;
  const { criteria } = configuration;

  const drillDownViewState: DrillDownViewState = yield select(
    getActivitiesOverdueDrillDownViewState
  );

  const viewState = localSettings.getViewSettings(
    `dashboard/activitiesOverdue`,
    activityFieldModel,
    drillDownViewState
  );

  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const filter: FilterModel = {};
  if (criteria.activityTypeIds.length) {
    filter.activityType = { operator: FilterOperator.IN_ANY, value: criteria.activityTypeIds };
  }
  if (isTeamScope) {
    filter.teams = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
  }
  if (scope?.userId) {
    filter.assignee = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
  }
  if (criteria.sources.length) {
    filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: criteria.sources };
  }
  viewState.filter = filter;

  yield put(showActivitiesOverdueDrillDown.success({ viewState }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchActivitiesOverdueCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: activities overdue card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyActivitiesOverdueDrillDownListViewSettings(payload.request));
    }

    const drillDownViewState: DrillDownViewState = yield select(
      getActivitiesOverdueDrillDownViewState
    );

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(drillDownViewState, `dashboard/activitiesOverdue`);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          activityFieldModel,
          true,
          drillDownViewState.viewAs
        ),
        completed: false,
        startAt: { [PlatformFilterOperator.LESS_THAN]: [new Date().toISOString()] },
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const response: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      orgId,
      requestPayload
    );
    payload.dataCallback?.(response);
    yield put(
      fetchActivitiesOverdueCardDrillDownData.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchActivitiesOverdueCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport() {
  const drillDownViewState: DrillDownViewState = yield select(
    getActivitiesOverdueDrillDownViewState
  );
  const total: number = yield select(getActivitiesOverdueDrillDownTotalFilteredRecords);

  yield put(
    exportEntities.request({
      entityType: EntityType.ACTIVITY,
      total,
      viewState: expandColumns(drillDownViewState),
    })
  );
}

export function* activitiesOverdueSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchActivitiesOverdueCardData, onFetchActivitiesOverdueCardData);
  yield takeLatest(showActivitiesOverdueDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchActivitiesOverdueCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportActivitiesOverdueCardDrillDownData, onExport);
}
