import React from "react";

import { bem } from "@react-md/utils";
import Dropdown, { DropDownProps } from "antd/es/dropdown";

import AnyColor from "@mapmycustomers/shared/types/AnyColor";

import ColorCircle from "./ColorCircleComponent";
import ColorPanel, { ColorPanelProps } from "./ColorPanel";

const block = bem("mmc-color-panel");

interface ColorDropdownProps extends Omit<DropDownProps, "overlay"> {
  colorCircleClassName?: string;
  colorPanelProps?: Omit<ColorPanelProps, "onChange" | "value">;
  disabled?: boolean;
  onChange?: (color: AnyColor) => void;
  onOpacityChange?: (opacity: number) => void;
  opacity?: number;
  placement?:
    | "bottom"
    | "bottomCenter"
    | "bottomLeft"
    | "bottomRight"
    | "top"
    | "topCenter"
    | "topLeft"
    | "topRight";
  value?: AnyColor;
}

const ColorDropdown: React.FC<ColorDropdownProps> = ({
  children,
  colorCircleClassName,
  colorPanelProps,
  disabled,
  onChange,
  onOpacityChange,
  opacity,
  placement,
  value,
  ...props
}) => {
  return (
    <Dropdown
      disabled={disabled}
      overlay={
        <ColorPanel
          className={block()}
          onChange={onChange}
          onOpacityChange={onOpacityChange}
          opacity={opacity}
          value={value}
          {...colorPanelProps}
        />
      }
      placement={placement ?? "bottomLeft"}
      trigger={["click"]}
      {...props}
    >
      {children ?? (
        <div>
          <ColorCircle className={colorCircleClassName} disabled={disabled} value={value} />
        </div>
      )}
    </Dropdown>
  );
};

export default ColorDropdown;
