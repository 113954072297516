import React from "react";

import { useIntl } from "react-intl";

import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { OptionalFields } from "@mapmycustomers/shared/util/ts";

import Lexer from "@app/component/Lexer";
import MultilineText from "@app/component/typography/MultilineText";

import styles from "./CalculatedFieldValue.module.scss";

const urlRegExp = new RegExp(
  "^(?:https?:\\/\\/)?" + // protocol
    "(?<domain>(?:(?:(?:[a-z\\d](?:[a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
    "(?:(?:\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(?:\\:\\d+)?)" + // port
    "(?:\\/[-a-z\\d%_.~+]*)*" + // path
    "(?:\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(?:\\#[-a-z\\d_]*)?$", // fragment locator
  "i"
);

interface Props {
  field: IField;
  value?: OptionalFields<CustomFieldValue, "id">;
}

const CalculatedFieldValue: React.FC<Props> = ({ field, value }) => {
  const intl = useIntl();
  const formatted = value ? field.getFormattedValueFor({ customFields: [value] }) : "";

  const urlMatch = formatted.match(urlRegExp);
  if (urlMatch && urlMatch.groups?.domain) {
    // value is a single url, let's use special formatting
    return (
      <div>
        <a href={formatted} rel="noopener noreferrer" target="_blank">
          {urlMatch.groups.domain}
        </a>
      </div>
    );
  }

  return (
    <div>
      {formatted ? (
        <MultilineText wrapLinesWith={Lexer}>{formatted}</MultilineText>
      ) : (
        <span className={styles.noValue}>
          {intl.formatMessage({
            id: "customField.calculated.noValue",
            defaultMessage: "Unknown",
            description: "A text to show instead of the undefined calculated custom field value",
          })}
        </span>
      )}
    </div>
  );
};

export default CalculatedFieldValue;
