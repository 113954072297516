enum CustomFieldDataType {
  ADDRESS = "address",
  BOOLEAN = "boolean",
  DATE = "date",
  DECIMAL = "decimal",
  INTEGER = "integer",
  LARGE_TEXT = "large-text",
  MONETARY = "monetary",
  MULTI_OPTION = "multi-option",
  PHONE = "phone",
  SINGLE_OPTION = "single-option",
  TEXT = "text",
  TIME = "time",
}

export default CustomFieldDataType;
