import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { AnyEntity, EntityType } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import localSettings from "@app/config/LocalSettings";
import { getDatesRange } from "@app/enum/dashboard/dateRanges";
import { NEW_ROUTES_ENTITY_TYPES } from "@app/scene/dashboard/components/cards/NewRoutes/consts";
import { getNewRoutesDrillDownViewState } from "@app/scene/dashboard/store/cards/newRoutes/selectors";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { fetchNewRoutesCardDataHelper } from "@app/store/dashboard/cardDataFetchHelpers";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import EntityTypesSupportedInNewRoutes from "@app/types/dashboard/cards/newRoutes/EntityTypesSupportedInNewRoutes";
import NewRoutesCardConfiguration from "@app/types/dashboard/cards/newRoutes/NewRoutesCardConfiguration";
import Scope from "@app/types/dashboard/Scope";
import AggregatedListResponse from "@app/types/viewModel/AggregatedListResponse";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import FieldModel from "@app/util/fieldModel/impl/FieldModel";
import { RouteFieldName } from "@app/util/fieldModel/RouteFieldModel";
import loggingService from "@app/util/logging";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyNewRoutesDrillDownListViewSettings,
  fetchNewRoutesCardData,
  fetchNewRoutesCardDrillDownData,
  showNewRoutesDrillDown,
} from "./actions";

const defaultColumns: Record<EntityTypesSupportedInNewRoutes, string[]> = {
  [EntityType.COMPANY_ROUTE]: [
    RouteFieldName.NAME,
    RouteFieldName.START_ADDRESS,
    RouteFieldName.USER,
    RouteFieldName.CREATED_AT,
    RouteFieldName.COUNT,
  ],
  [EntityType.PEOPLE_ROUTE]: [
    RouteFieldName.NAME,
    RouteFieldName.START_ADDRESS,
    RouteFieldName.USER,
    RouteFieldName.CREATED_AT,
    RouteFieldName.COUNT,
  ],
};

const defaultSortColumn: Record<EntityTypesSupportedInNewRoutes, string> = {
  [EntityType.COMPANY_ROUTE]: RouteFieldName.CREATED_AT,
  [EntityType.PEOPLE_ROUTE]: RouteFieldName.CREATED_AT,
};

const fetchMethodByEntityType: Record<EntityTypesSupportedInNewRoutes, ApiMethodName> = {
  [EntityType.COMPANY_ROUTE]: "fetchCompanyRoutesAggregated",
  [EntityType.PEOPLE_ROUTE]: "fetchPeopleRoutesAggregated",
};

export const getFilters = (scope: Scope | undefined, configuration: NewRoutesCardConfiguration) => {
  const { dateRange } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const range = getDatesRange(dateRange.range, dateRange.subRange);

  return {
    createdAt: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    createdBy: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
  };
};

function* onFetchNewRoutesCardData({ payload }: ReturnType<typeof fetchNewRoutesCardData>) {
  const { callback, configuration, failureCallback, scope } = payload;

  try {
    yield call(fetchNewRoutesCardDataHelper, { callback, configuration, scope });
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showNewRoutesDrillDown.request>) {
  const { configuration, customDateRange, scope } = payload;
  const { criteria, dateRange } = configuration;

  const viewStates: Record<EntityTypesSupportedInNewRoutes, DrillDownViewState> = yield select(
    getNewRoutesDrillDownViewState
  );

  const entityTypes =
    Array.isArray(criteria.entityTypes) && criteria.entityTypes.length
      ? criteria.entityTypes
      : NEW_ROUTES_ENTITY_TYPES;

  entityTypes.forEach((entityType) => {
    const fieldModel = getFieldModelByEntityType(entityType);
    const viewState = localSettings.getViewSettings(
      `dashboard/newRoutes/${entityType}`,
      fieldModel,
      {
        ...viewStates[entityType],
        columns: getDefaultDrillDownColumns(defaultColumns[entityType], fieldModel),
        sort: [
          {
            field: fieldModel.getByName(defaultSortColumn[entityType])!,
            order: SortOrder.DESC,
          },
        ],
      }
    );
    viewStates[entityType] = viewState;
  });

  entityTypes.forEach((entityType) => {
    const viewState: DrillDownViewState = viewStates[entityType];

    const isTeamScope = !!scope?.teamId && !scope?.userId;
    const range = customDateRange ?? getDatesRange(dateRange.range, dateRange.subRange);

    const filter: FilterModel = {
      createdAt: { operator: FilterOperator.IN_RANGE, value: [range.startDate, range.endDate] },
    };
    if (isTeamScope) {
      filter.team = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
    }
    if (scope?.userId) {
      filter.creator = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
    }
    viewState.filter = filter;

    viewStates[entityType] = viewState;
  });

  yield put(showNewRoutesDrillDown.success({ viewStates }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchNewRoutesCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: new routes card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(
        applyNewRoutesDrillDownListViewSettings({
          ...payload.request,
          entityType: payload.entityType,
        })
      );
    }
    const drillDownViewStates: Record<EntityTypesSupportedInNewRoutes, DrillDownViewState> =
      yield select(getNewRoutesDrillDownViewState);
    const drillDownViewState = drillDownViewStates[payload.entityType];

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(
        drillDownViewState,
        `dashboard/newRoutes/${payload.entityType}`
      );
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const fieldModel: FieldModel = yield call(getFieldModelByEntityType, EntityType.ROUTE);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          fieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const fetchMethod = fetchMethodByEntityType[payload.entityType];
    const response: AggregatedListResponse<AnyEntity> = yield callApi(
      fetchMethod,
      orgId,
      requestPayload
    );
    if (payload.dataCallback) {
      payload.dataCallback(response);
    }
    yield put(
      fetchNewRoutesCardDrillDownData.success({
        entityType: payload.entityType,
        totalFilteredRoutes: response.total,
        totalRoutes: response.accessible,
      })
    );
  } catch (error) {
    if (payload.failCallback) {
      payload.failCallback();
    }
    yield put(fetchNewRoutesCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* newRoutesSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchNewRoutesCardData, onFetchNewRoutesCardData);
  yield takeLatest(showNewRoutesDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchNewRoutesCardDrillDownData.request, onFetchDrillDownData);
}
