import { useMemo } from "react";

import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

const useFirstLastNameRequirement = (schema: LayoutSchemaField[]): [boolean, boolean] =>
  useMemo(() => {
    let firstNameRequired = false,
      lastNameRequired = false;
    schema.forEach((schemaRow) => {
      if (schemaRow.field === "firstName") {
        firstNameRequired = schemaRow.required;
      } else if (schemaRow.field === "lastName") {
        lastNameRequired = schemaRow.required;
      }
    });
    return [firstNameRequired, lastNameRequired];
  }, [schema]);

export default useFirstLastNameRequirement;
