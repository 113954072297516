import React from "react";

import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import styles from "./DynamicVarChip.module.scss";

interface Props {
  allEntitiesHaveValues: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  tooltipText?: string;
}

const DynamicVarChip: React.FC<Props> = ({
  allEntitiesHaveValues,
  children,
  onClick,
  tooltipText,
}) => {
  return (
    <Tooltip title={tooltipText} trigger={["hover"]}>
      <span
        className={cn(styles.container, {
          [styles.allEntitiesHaveValues]: allEntitiesHaveValues,
        })}
        contentEditable={false}
        onClick={onClick}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default DynamicVarChip;
