import { useEffect, useState } from "react";

const useToggleBooleanAfterTimeout = (
  delayMs: number,
  initialValue: boolean = false,
  active: boolean = true,
  resetWhenInactive: boolean = false
) => {
  const [value, setValue] = useState<boolean>(initialValue);
  useEffect(() => {
    if (!active) {
      if (resetWhenInactive) {
        setValue(initialValue);
      }
      return;
    }
    const timerId = setTimeout(() => setValue(!initialValue), delayMs);
    return () => clearTimeout(timerId);
  }, [active, delayMs, initialValue, resetWhenInactive]);
  return value;
};

export default useToggleBooleanAfterTimeout;
