import invariant from "tiny-invariant";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import CombineOperator from "@mapmycustomers/shared/types/viewModel/internalModel/CombineOperator";
import FilterModel, {
  SimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import AgGridFilterOperator from "../../enum/AgGridFilterOperator";
import AgGridCombineOperator from "../../types/viewModel/agGridModel/AgGridCombineOperator";
import AgGridFilterModel, {
  AgGridCondition,
  AgGridSimpleCondition,
  AreaFilterCondition,
  DateCondition,
  EmptyCondition,
  GroupFilterCondition,
  NumberCondition,
  OptionsCondition,
  SetCondition,
  TextCondition,
} from "../../types/viewModel/agGridModel/AgGridFilterModel";

import { isAgGridCombinedCondition } from "./assert";

// Operator used to represent AgGrid sets in our filter model
export const SET_FILTER_OPERATOR = FilterOperator.IN_ANY;

const agGridFilterOperatorToFilterOperatorMap: { [key in AgGridFilterOperator]: FilterOperator } = {
  [AgGridFilterOperator.CONTAINS]: FilterOperator.CONTAINS,
  [AgGridFilterOperator.ENDS_WITH]: FilterOperator.ENDS_WITH,
  [AgGridFilterOperator.EQUALS]: FilterOperator.EQUALS,
  [AgGridFilterOperator.GREATER_THAN]: FilterOperator.GREATER_THAN,
  [AgGridFilterOperator.GREATER_THAN_OR_EQUAL]: FilterOperator.GREATER_THAN_OR_EQUAL,
  [AgGridFilterOperator.IN_RANGE]: FilterOperator.IN_RANGE,
  [AgGridFilterOperator.LESS_THAN]: FilterOperator.LESS_THAN,
  [AgGridFilterOperator.LESS_THAN_OR_EQUAL]: FilterOperator.LESS_THAN_OR_EQUAL,
  [AgGridFilterOperator.NOT_CONTAINS]: FilterOperator.NOT_CONTAINS,
  [AgGridFilterOperator.NOT_EQUAL]: FilterOperator.NOT_EQUAL,
  [AgGridFilterOperator.STARTS_WITH]: FilterOperator.STARTS_WITH,
} as const;

const agGridCombineOperatorToCombineOperatorMap: {
  [key in AgGridCombineOperator]: CombineOperator;
} = {
  AND: "AND",
  OR: "OR",
} as const;

// FIXME: should convert names into ids as well
// AgGrid only gives names in the `values` field, whereas it makes sense
// to store those values as a corresponding ids. E.g. if it was an array
// of group names, it makes more sense to store group ids.
// Especially since platform requires ids for groups.
const convertFromSetCondition = (condition: SetCondition, field: IField) => ({
  operator: field.hasFeature(FieldFeature.USES_COLORS)
    ? FilterOperator.CONTAINS
    : SET_FILTER_OPERATOR,
  value: (condition as SetCondition).values,
});

const convertFromNumberCondition = (condition: NumberCondition) => {
  const operator = agGridFilterOperatorToFilterOperatorMap[condition.type];
  return {
    operator,
    value:
      operator === FilterOperator.IN_RANGE
        ? [condition.filter, condition.filterTo]
        : condition.filter,
  };
};

const convertFromTextCondition = (condition: TextCondition) => ({
  operator: agGridFilterOperatorToFilterOperatorMap[condition.type],
  value: condition.filter,
});

const convertFromDateCondition = (condition: DateCondition) => {
  const operator = agGridFilterOperatorToFilterOperatorMap[condition.type];
  return {
    operator,
    value:
      operator === FilterOperator.IN_RANGE
        ? [condition.dateFrom, condition.dateTo]
        : condition.dateFrom,
  };
};

const convertFromEmptyCondition = (condition: EmptyCondition) => ({
  operator:
    condition.type === AgGridFilterOperator.EQUALS
      ? FilterOperator.EMPTY
      : FilterOperator.NOT_EMPTY,
  value: undefined,
});

const convertFromAgGridCondition = (
  condition:
    | AgGridSimpleCondition
    | AreaFilterCondition
    | EmptyCondition
    | GroupFilterCondition
    | OptionsCondition
    | SetCondition,
  field: IField
): SimpleCondition => {
  switch (condition.filterType) {
    case "set":
      return convertFromSetCondition(condition as SetCondition, field);
    case "number":
      return convertFromNumberCondition(condition as NumberCondition);
    case "text":
      return convertFromTextCondition(condition as TextCondition);
    case "date":
      return convertFromDateCondition(condition as DateCondition);
    case "emptiness":
      return convertFromEmptyCondition(condition as EmptyCondition);
    case "areaFilter":
    case "groupsFilter":
    case "optionsFilter":
      return condition;
  }
};

const convertFromAgGridFilterModel = (
  gridFilterModel: AgGridFilterModel,
  fieldModel: IFieldModel
): FilterModel => {
  const filterModel: FilterModel = {};
  Object.entries(gridFilterModel).forEach(([columnName, value]) => {
    const condition = value as AgGridCondition;
    const field = fieldModel.getByName(columnName);
    invariant(field, `Failed to find field for column name "${columnName}"`);

    if (isAgGridCombinedCondition(condition)) {
      const operator = agGridCombineOperatorToCombineOperatorMap[condition.operator];
      filterModel[field.filterName] = {
        conditions: [
          convertFromAgGridCondition(condition.condition1 as AgGridSimpleCondition, field),
          convertFromAgGridCondition(condition.condition2 as AgGridSimpleCondition, field),
        ],
        operator,
      };
    } else {
      filterModel[field.filterName] = convertFromAgGridCondition(condition, field);
    }
  });
  return filterModel;
};

export default convertFromAgGridFilterModel;
