import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { StageSummary } from "@mapmycustomers/shared/types/entity/deals/StageSummary";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import { BoardColumnId } from "@app/component/DnDBoard/types/BoardColumn";
import { FetcherPayload } from "@app/types/viewModel/Fetcher";
import { ListFetcherPayload } from "@app/types/viewModel/ListFetcher";

import ForecastViewMode from "../component/ForecastPage/enum/ForecastViewMode";
import ForecastViewState from "../component/ForecastPage/types/ForecastViewState";
import ViewMode from "../enum/ViewMode";
import FunnelSummaryInfo from "../type/FunnelSummaryInfo";

import { DatesWithDeals } from "./types/ForecastView";
import { StagesWithDeals } from "./types/KanbanView";

export const switchView = createAction("deals/switchView")<{ from: ViewMode; to: ViewMode }>();

export const initializeListView = createAsyncAction(
  "dealList/initialize/request",
  "dealList/initialize/success",
  "dealList/initialize/failure"
)<void, void, void>();

export const fetchList = createAsyncAction(
  "dealList/fetch/request",
  "dealList/fetch/success",
  "dealList/fetch/failure"
)<
  ListFetcherPayload<Deal>,
  { funnelSummary: FunnelSummaryInfo; totalFilteredRecords: number; totalRecords: number },
  void
>();

export const applyListViewSettings = createAction("dealList/applyViewSettings")<
  Partial<ListRequest>
>();

export const initializeKanbanView = createAsyncAction(
  "dealsKanban/initialize/request",
  "dealsKanban/initialize/success",
  "dealsKanban/initialize/failure"
)<void, void, void>();

export const fetchKanbanDeals = createAsyncAction(
  "dealsKanban/fetch/request",
  "dealsKanban/fetch/success",
  "dealsKanban/fetch/failure"
)<
  FetcherPayload<Deal>,
  {
    funnelSummary: FunnelSummaryInfo;
    groupedDeals: StagesWithDeals;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  void
>();

export const fetchKanbanStageDeals = createAsyncAction(
  "dealsKanban/fetchStageDeals/request",
  "dealsKanban/fetchStageDeals/success",
  "dealsKanban/fetchStageDeals/failure"
)<
  {
    callback?: (deals: Deal[]) => void;
    fetchAll?: boolean;
    preventUpdate?: boolean;
    stageId: Stage["id"];
  },
  {
    deals: Deal[];
    preventUpdate?: boolean;
    stageId: Stage["id"];
    total: number;
  },
  {
    stageId: Stage["id"];
  }
>();

export const exportStageDeals = createAction("dealsKanban/exportStageDeals")<{
  columns: ColumnModel;
  stageId: Stage["id"];
}>();

export const applyKanbanViewSettings = createAction("dealsKanban/applyViewSettings")<
  Partial<ViewState>
>();

export const setKanbanHiddenColumnIds = createAction("dealsKanban/setKanbanHiddenColumnIds")<
  BoardColumnId[]
>();

export const updateKanbanByDealChanging = createAsyncAction(
  "dealsKanban/updateKanbanByDealChanging/request",
  "dealsKanban/updateKanbanByDealChanging/success",
  "dealsKanban/updateKanbanByDealChanging/failure"
)<
  Deal[],
  {
    funnelSummary: FunnelSummaryInfo;
    groupedDeals: StagesWithDeals;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  Deal[]
>();

export const initializeForecastView = createAsyncAction(
  "dealsForecast/initialize/request",
  "dealsForecast/initialize/success",
  "dealsForecast/initialize/failure"
)<void, void, void>();

export const fetchForecastDeals = createAsyncAction(
  "dealsForecast/fetch/request",
  "dealsForecast/fetch/success",
  "dealsForecast/fetch/failure"
)<
  FetcherPayload<Deal, ListResponse<Deal>, ForecastViewState>,
  {
    funnelSummary?: FunnelSummaryInfo;
    groupedDeals: DatesWithDeals;
    totalFilteredRecords?: number;
    totalRecords?: number;
  },
  void
>();

export const exportDateDeals = createAction("dealsKanban/exportDateDeals")<{
  columns: ColumnModel;
  date?: Date;
}>();

export const fetchForecastDateDeals = createAsyncAction(
  "dealsForecast/fetchForecastDateDeals/request",
  "dealsForecast/fetchForecastDateDeals/success",
  "dealsForecast/fetchForecastDateDeals/failure"
)<
  { callback?: (deals: Deal[]) => void; date?: Date; fetchAll?: boolean; preventUpdate?: boolean },
  {
    date?: Date;
    deals: Deal[];
    preventUpdate?: boolean;
  },
  { date?: Date }
>();

export const fetchFunnelStagesSummary = createAsyncAction(
  "dealList/fetchFunnelStagesSummary/request",
  "dealList/fetchFunnelStagesSummary/success",
  "dealList/fetchFunnelStagesSummary/failure"
)<Funnel["id"], StageSummary[], void>();

export const toggleFunnelChartDisplay = createAction(
  "dealList/toggleFunnelChartDisplay"
)<boolean>();

export const applyForecastViewSettings = createAction("dealsForecast/applyViewSettings")<
  Partial<ForecastViewState>
>();

export const setForecastViewMode = createAction("dealsForecast/setViewMode")<ForecastViewMode>();

export const setForecastHiddenColumnIds = createAction("dealsForecast/setForecastHiddenColumnIds")<
  BoardColumnId[]
>();

export const fetchForecastNextPeriodDeals = createAsyncAction(
  "dealsForecast/fetchForecastNextPeriodDeals/request",
  "dealsForecast/fetchForecastNextPeriodDeals/success",
  "dealsForecast/fetchForecastNextPeriodDeals/failure"
)<
  void,
  {
    groupedDeals: DatesWithDeals;
  },
  unknown
>();

export const updateForecastByDealChanging = createAsyncAction(
  "dealsForecast/updateForecastByDealChanging/request",
  "dealsForecast/updateForecastByDealChanging/success",
  "dealsForecast/updateForecastByDealChanging/failure"
)<
  Deal[],
  {
    funnelSummary: FunnelSummaryInfo;
    groupedDeals: DatesWithDeals;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  Deal[]
>();

export const setChangedFunnelData = createAction("dealFunnel/setChangedFunnelData")<boolean>();
export const setChangedStagesData = createAction("dealFunnel/setChangedStagesData")<boolean>();

export const setActiveFunnelId = createAction("dealFunnel/setActiveFunnel")<
  Funnel["id"] | undefined
>();

export const openUnsavedPanel = createAction("dealFunnel/openUnsavedPanel")<boolean>();

export const downloadDealData = createAction("dealFunnel/downloadListData")<{
  columns: ColumnModel<IField>;
  viewMode: ViewMode;
}>();

export const subscribeOnViewStateUpdating = createAction("deal/subscribeOnViewStateUpdating")<{
  callback: (state: Partial<ViewState>) => void;
}>();

export const unsubscribeOnViewStateUpdating = createAction(
  "deal/unsubscribeOnViewStateUpdating"
)<void>();

export const updateDealView = createAction("deal/updateDealView")<{
  viewState: Partial<ViewState>;
}>();

export const setOfferedFilters = createAction("deal/setOfferedFilter")<
  Partial<FilterModel> | undefined
>();

export type Actions = ActionType<
  | typeof applyForecastViewSettings
  | typeof applyKanbanViewSettings
  | typeof applyListViewSettings
  | typeof downloadDealData
  | typeof exportStageDeals
  | typeof fetchForecastDateDeals
  | typeof fetchForecastDeals
  | typeof fetchForecastNextPeriodDeals
  | typeof fetchFunnelStagesSummary
  | typeof fetchKanbanDeals
  | typeof fetchKanbanStageDeals
  | typeof fetchList
  | typeof initializeForecastView
  | typeof initializeKanbanView
  | typeof initializeListView
  | typeof openUnsavedPanel
  | typeof setActiveFunnelId
  | typeof setChangedFunnelData
  | typeof setChangedStagesData
  | typeof setForecastHiddenColumnIds
  | typeof setForecastViewMode
  | typeof setKanbanHiddenColumnIds
  | typeof setOfferedFilters
  | typeof subscribeOnViewStateUpdating
  | typeof switchView
  | typeof toggleFunnelChartDisplay
  | typeof unsubscribeOnViewStateUpdating
  | typeof updateDealView
  | typeof updateForecastByDealChanging
  | typeof updateKanbanByDealChanging
>;
