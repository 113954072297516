@use "../styles/userColors" as colors;

:global {
  body[class*="theme-"].theme {
    :local {
      @each $suffix, $colors in colors.$base-user-colors {
        .#{$suffix} {
          background: nth($colors, 1);
          color: nth($colors, 2);
        }
      }

      @each $suffix, $colors in colors.$selected-user-colors {
        .#{$suffix} {
          background: nth($colors, 1);
          color: nth($colors, 3);
        }
      }
    }
  }
}
