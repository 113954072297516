import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const globalSearchState = (state: RootState) => state.globalSearch;

export const isGlobalSearchVisible = createSelector(globalSearchState, ({ visible }) => visible);

export const isUserRecentsLoading = createSelector(globalSearchState, ({ loading }) => loading);

export const getUserRecents = createSelector(globalSearchState, ({ userRecents }) => userRecents);

export const getGlobalSearchResults = createSelector(
  globalSearchState,
  ({ searchResult }) => searchResult
);
