import allCountries from "./countries.json";

interface CountryRecord {
  country: string;
  countryCode: string;
  regions: Array<RegionRecord>;
}

interface RegionRecord {
  name: string;
  regionCode: string;
}

export function findCountry(countryCode: string): CountryRecord | undefined {
  const countries = allCountries as CountryRecord[];
  return countries.find((country) => country.countryCode === countryCode);
}

export function hasRegions(country: CountryRecord): boolean {
  return country.regions?.length > 0;
}

export function findRegion(
  countryCode: string,
  regionCode: string
): RegionRecord | undefined {
  if (!countryCode || !regionCode) {
    return;
  }

  const country = findCountry(countryCode);
  if (country && hasRegions(country)) {
    return country.regions.find((region) => region.regionCode === regionCode);
  }
}

export function findRegionByName(
  countryCode: string,
  regionName: string
): RegionRecord | undefined {
  if (!countryCode || !regionName) {
    return;
  }

  const country = findCountry(countryCode);
  if (country && hasRegions(country)) {
    return country.regions.find((region) => region.name === regionName);
  }
}
