import get from "lodash-es/get";

import ListResponseAggregation from "@app/types/viewModel/ListResponseAggregation";

const groupRecordsByKey = <T>(
  items: ListResponseAggregation<T>[],
  keyFieldPath: string,
  valueFieldPath: string
): Record<string, number> =>
  items.reduce((result: { [key: string]: number }, item) => {
    return Object.assign(result, { [get(item, keyFieldPath)]: get(item, valueFieldPath, 0) });
  }, {});

export default groupRecordsByKey;
