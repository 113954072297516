@use "../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        position: relative;
        width: fit-content;
      }

      .image {
        display: block;
        pointer-events: none;
      }

      .delete {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
      }
      .resizer {
        position: absolute;
        border: 2px solid colors.$mmc-primary;
      }
      .resizeControl {
        width: 0.5rem;
        height: 0.5rem;
        background-color: colors.$mmc-primary;
        position: absolute;
        border: 2px solid colors.$mmc-white;
      }
      .toolbar {
        border-radius: 0.25rem;
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem;
        width: fit-content;
      }

      .divider {
        width: 1px;
        height: 1rem;
        background-color: colors.$mmc-soft-slate;
      }
      .control {
        font-weight: 500;
        &:disabled {
          color: colors.$mmc-slate;
        }
      }
      .removeControl {
        color: colors.$mmc-red-100;
      }

      .altModalContent {
        padding-top: 0.5rem;
      }
      .altModalCaption {
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
