import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

import ComponentRegistry from "@app/component/FieldGrid/utils/ComponentRegistry";

import ColorField from "./ColorField";
import CompaniesField from "./CompaniesField";
import CompanyField from "./CompanyField";
import EmailField from "./EmailField";
import ExternalUrlField from "./ExternalUrlField";
import FrequencyIntervalField from "./FrequencyIntervalField";
import GroupField from "./GroupField";
import OwnerField from "./OwnerField";
import PhoneNumberField from "./PhoneNumberField";
import RoutesField from "./RoutesField";
import TeamField from "./TeamField";
import TerritoryField from "./TerritoryField";

const registerAll = () => {
  ComponentRegistry.register(ColorField, (field) => field.name === "color");
  ComponentRegistry.register(EmailField, (field) => field.name === "email");
  ComponentRegistry.register(PhoneNumberField, (field) => field.name === "phone");
  ComponentRegistry.register(TeamField, (field) => field.hasFeature(FieldFeature.TEAM_FIELD));
  ComponentRegistry.register(GroupField, (field) => field.hasFeature(FieldFeature.GROUP_FIELD));
  ComponentRegistry.register(RoutesField, (field) => field.hasFeature(FieldFeature.ROUTE_FIELD));
  ComponentRegistry.register(TerritoryField, (field) =>
    field.hasFeature(FieldFeature.TERRITORY_FIELD)
  );
  ComponentRegistry.register(CompaniesField, (field) => {
    return field.name === "accounts";
  });
  ComponentRegistry.register(CompanyField, (field) => {
    return field.name === "account" || field.name === "parentAccount";
  });
  ComponentRegistry.register(OwnerField, (field) => field.name === "user");
  ComponentRegistry.register(FrequencyIntervalField, (field) =>
    field.hasFeature(FieldFeature.FREQUENCY_INTERVAL)
  );
  ComponentRegistry.register(ExternalUrlField, (field) =>
    field.hasFeature(FieldFeature.EXTERNAL_URL_FIELD)
  );
};

export default registerAll;
