import FormLayout, { ChildLayout } from "@mapmycustomers/shared/types/layout/FormLayout";

import { activityLayoutModel } from "../layout/impl";

import { EMAIL_ACTIVITY_TYPE_NAME } from "./const";

const getActivityEmailLayouts = (): [FormLayout | undefined, ChildLayout | undefined] => [
  activityLayoutModel.defaultFormLayout,
  activityLayoutModel.defaultFormLayout?.childLayouts?.find(
    ({ crmActivityType }) => crmActivityType?.name === EMAIL_ACTIVITY_TYPE_NAME
  ),
];

export const getDefaultActivityEmailLayout = (): ChildLayout | FormLayout | undefined => {
  const [layout, emailLayout] = getActivityEmailLayouts();
  return emailLayout ?? layout;
};

export default getActivityEmailLayouts;
