import MapHandlerMap from "@app/types/map/MapHandlerMap";
import useGenericMapEventHandler from "@app/util/hook/map/useMapEventHandler";

import useMap from "./useMap";

type Map = google.maps.Map;

const useMapEventHandler = <N extends keyof MapHandlerMap<Map>>(
  eventName: N,
  handler: MapHandlerMap<Map>[N]
) => {
  const { map } = useMap();
  useGenericMapEventHandler(map, eventName, handler);
};

export default useMapEventHandler;
