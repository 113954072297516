import { useCallback, useState } from "react";

const useRerenderer = () => {
  const [, setRenderCount] = useState(0);
  return useCallback(
    () => setRenderCount((prevCount) => (prevCount + 1) % 1000),
    []
  );
};

export default useRerenderer;
