import { Descendant } from "slate";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const parseText = (value: string): Descendant[] =>
  value.split("\n").map((text) => {
    const children: Descendant[] = [];
    children.push({ text });
    return {
      children,
      type: ElementType.PARAGRAPH,
    };
  });

export default parseText;
