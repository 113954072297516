import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { EntityTypeSupportingDataView } from "@mapmycustomers/shared/types/entity";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import User from "@mapmycustomers/shared/types/User";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import DataViewDisplayType from "@app/enum/DataViewDisplayType";
import DataViewMode from "@app/enum/DataViewMode";

import { FunnelDataRecord, GroupDataRecord, UserDataRecord } from "../type/DataRecord";
import DataViewEntityTypeCount from "../type/DataViewEntityTypeCount";

export const selectEntityType = createAction(
  "@component/dataView/selectEntityType"
)<EntityTypeSupportingDataView>();

export const selectField = createAction("@component/dataView/selectField")<string>();

export const initializeDataView = createAction("@component/dataView/initializeDataView")<{
  entityTypeRecordsCount: DataViewEntityTypeCount;
  filter: PlatformFilterModel;
  users: User[];
}>();

export const fetchData = createAsyncAction(
  "@component/dataView/fetchData/request",
  "@component/dataView/fetchData/success",
  "@component/dataView/fetchData/failure"
)<
  void,
  {
    funnelData: FunnelDataRecord[];
    groupData: GroupDataRecord[];
    totalValue: number;
    userData: UserDataRecord[];
  },
  unknown
>();

export const selectFunnelId = createAction("@component/dataView/selectFunnelId")<Funnel["id"]>();

export const selectDataMode = createAction("@component/dataView/selectDataMode")<DataViewMode>();

export const selectDataDisplayType = createAction(
  "@component/dataView/selectDataDisplayType"
)<DataViewDisplayType>();

export const selectUsers = createAction("@component/dataView/selectUsers")<User["id"][]>();

export const downloadEntities = createAsyncAction(
  "@component/dataView/downloadEntities/request",
  "@component/dataView/downloadEntities/success",
  "@component/dataView/downloadEntities/failure"
)<void, void, unknown>();

export type Actions = ActionType<
  | typeof downloadEntities
  | typeof fetchData
  | typeof initializeDataView
  | typeof selectDataDisplayType
  | typeof selectDataMode
  | typeof selectEntityType
  | typeof selectField
  | typeof selectFunnelId
  | typeof selectUsers
>;
