import React from "react";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import Chip from "../../component/Chip";

const activityTypeTagRender = ({ closable, label, onClose }: CustomTagProps) => (
  <Chip closable={closable} onClose={onClose} type="closable">
    {label}
  </Chip>
);

export default activityTypeTagRender;
