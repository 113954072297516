import compact from "lodash-es/compact";
import { createReducer } from "typesafe-actions";

import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import { RawFile } from "@mapmycustomers/shared/types/File";
import User from "@mapmycustomers/shared/types/User";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";

import { fileCreatedAtDescComparator } from "@app/component/preview/util/comparators";
import localSettings from "@app/config/LocalSettings";
import RecapRange from "@app/enum/preview/RecapRange";
import RecordPaneTab from "@app/enum/preview/RecordPaneTab";
import { DEFAULT_ASSOCIATIONS_STATE } from "@app/store/associations/const";
import FileListItem from "@app/types/FileListItem";
import dealFieldModel from "@app/util/fieldModel/DealFieldModel";

import {
  Actions,
  addDealParentCompany,
  addDealParentPerson,
  changeActivitiesAssignees,
  changeActivitiesFilter,
  changeActivitiesOrder,
  changeActivitiesRecapRange,
  changeActivitiesSearchQuery,
  changeActivitiesSelectedActivityTypes,
  changeAssociatedEntities,
  clearAllUploadedDealFiles,
  createDealNote,
  deleteDeal,
  deleteDealFile,
  deleteDealNote,
  fetchDeal,
  fetchDealActivities,
  fetchDealActivitiesCompletedByType,
  fetchDealActivitiesTotal,
  fetchPreviewData,
  postponeActivity,
  relateUnrelateEntities,
  removeDealParentCompany,
  removeDealParentPerson,
  setActiveTab,
  setActivityAutoScrolling,
  setHasChangesFlag,
  toggleCompleteActivity,
  updateActivityNote,
  updateDeal,
  updateDealFrequency,
  updateDealNote,
  uploadDealFiles,
} from "./actions";
import DealRecordData from "./DealRecordData";

const INITIAL_RECORD_DATA: Readonly<DealRecordData> = {
  activities: [],
  activitiesCompletedByType: [],
  activitiesTotal: 0,
  associations: DEFAULT_ASSOCIATIONS_STATE,
  deal: undefined,
  files: [],
  notes: [],
  uncompletedActivities: [],
};

export interface DealRecordState {
  activeTab?: RecordPaneTab;
  activitiesCompletedByTypeLoading: boolean;
  activitiesFilter: ActivityStatusOption;
  activitiesLoading: boolean;
  activitiesOrder: SortModel;
  activitiesRecapRange: RecapRange;
  activitiesSearchQuery: string;
  activitiesSelectedActivityTypes: ActivityType[];
  activitiesSelectedAssignees: User["id"][];
  activitiesTotalLoading: boolean;
  activityAutoScrolling?: boolean;
  addDealParentCompanyLoading: boolean;
  addDealParentPersonLoading: boolean;
  dealLoading: boolean;
  dealNoteCreateLoading: boolean;
  dealNoteDeleteLoadingIds: Note["id"][];
  dealNoteUpdateLoadingIds: Note["id"][];
  dealUpdateLoading: boolean;
  deleteLoading: boolean;
  entityRelating: boolean;
  entityRelatingSuccess: boolean;
  filesAdded: FileListItem[];
  filesToDeletedIds: RawFile["id"][];
  filesUploading: boolean;
  hasChanges: boolean;
  loading: boolean;
  postponeActivityLoadingIds: Activity["id"][];
  recordData: DealRecordData;
  removeDealParentCompanyLoading: boolean;
  removeDealParentPersonLoading: boolean;
  toggleCompleteActivityLoadingIds: Activity["id"][];
  updateActivityNoteLoadingIds: Activity["id"][];
}

const initialState: DealRecordState = {
  activeTab: undefined,
  activitiesCompletedByTypeLoading: false,
  activitiesFilter: ActivityStatusOption.ALL,
  activitiesLoading: false,
  activitiesOrder: [{ field: dealFieldModel.getByName("createdAt")!, order: SortOrder.DESC }],
  activitiesRecapRange: localSettings.getRecapChartRange() ?? RecapRange.THIS_YEAR,
  activitiesSearchQuery: "",
  activitiesSelectedActivityTypes: [],
  activitiesSelectedAssignees: [],
  activitiesTotalLoading: false,
  addDealParentCompanyLoading: false,
  addDealParentPersonLoading: false,
  dealLoading: false,
  dealNoteCreateLoading: false,
  dealNoteDeleteLoadingIds: [],
  dealNoteUpdateLoadingIds: [],
  dealUpdateLoading: false,
  deleteLoading: false,
  entityRelating: false,
  entityRelatingSuccess: false,
  filesAdded: [],
  filesToDeletedIds: [],
  filesUploading: false,
  hasChanges: false,
  loading: false,
  postponeActivityLoadingIds: [],
  recordData: INITIAL_RECORD_DATA,
  removeDealParentCompanyLoading: false,
  removeDealParentPersonLoading: false,
  toggleCompleteActivityLoadingIds: [],
  updateActivityNoteLoadingIds: [],
};

const updateRecordData = (state: DealRecordState, payload: Partial<Activity>) => {
  const updateActivities = (activities: Activity[]) =>
    activities.map((item) => {
      if (payload.id === item.id) {
        return {
          ...item,
          ...payload,
        };
      }
      return item;
    });

  return {
    ...state.recordData,
    activities: updateActivities(state.recordData.activities),
    activitiesCompletedByType: updateActivities(state.recordData.activitiesCompletedByType),
  };
};

const dealRecord = createReducer<DealRecordState, Actions>(initialState)
  .handleAction(fetchPreviewData.request, (state) => ({
    ...state,
    hasChanges: false,
    loading: true,
    recordData: INITIAL_RECORD_DATA,
  }))
  .handleAction(fetchPreviewData.success, (state, { payload }) => ({
    ...state,
    activitiesSelectedActivityTypes: payload.selectedActivityTypes,
    hasChanges: false,
    loading: false,
    recordData: payload.recordData,
  }))
  .handleAction(fetchPreviewData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchDeal.request, (state) => ({
    ...state,
    dealLoading: true,
  }))
  .handleAction(fetchDeal.success, (state, { payload }) => ({
    ...state,
    dealLoading: false,
    recordData: {
      ...state.recordData,
      associations: {
        ...state.recordData.associations,
        assignedCompany: payload.account,
        assignedPerson: payload.contact,
        // TODO: fixme 2023-11-07, shouldn't we also have available companies/people here?
      },
      deal: Object.assign({}, state.recordData.deal, payload),
    },
  }))
  .handleAction(fetchDeal.failure, (state) => ({
    ...state,
    dealLoading: false,
  }))
  .handleAction(fetchDealActivities.request, (state) => ({
    ...state,
    activitiesLoading: true,
  }))
  .handleAction(fetchDealActivities.success, (state, action) => ({
    ...state,
    activitiesLoading: false,
    recordData: {
      ...state.recordData,
      activities: action.payload,
    },
  }))
  .handleAction(fetchDealActivities.failure, (state) => ({
    ...state,
    activitiesLoading: false,
  }))
  .handleAction(fetchDealActivitiesCompletedByType.request, (state) => ({
    ...state,
    activitiesCompletedByTypeLoading: true,
  }))
  .handleAction(fetchDealActivitiesCompletedByType.success, (state, action) => ({
    ...state,
    activitiesCompletedByTypeLoading: false,
    recordData: {
      ...state.recordData,
      activitiesCompletedByType: action.payload,
    },
  }))
  .handleAction(fetchDealActivitiesCompletedByType.failure, (state) => ({
    ...state,
    activitiesCompletedByTypeLoading: false,
  }))
  .handleAction(fetchDealActivitiesTotal.request, (state) => ({
    ...state,
    activitiesTotalLoading: true,
  }))
  .handleAction(fetchDealActivitiesTotal.success, (state, action) => ({
    ...state,
    activitiesTotalLoading: false,
    recordData: {
      ...state.recordData,
      activitiesTotal: action.payload,
    },
  }))
  .handleAction(fetchDealActivitiesTotal.failure, (state) => ({
    ...state,
    activitiesTotalLoading: false,
  }))
  .handleAction(updateDeal.request, (state) => ({
    ...state,
    dealUpdateLoading: true,
  }))
  .handleAction(updateDeal.success, (state, { payload }) => ({
    ...state,
    dealUpdateLoading: false,
    recordData: {
      ...state.recordData,
      associations: {
        ...state.recordData.associations,
        assignedCompany: payload.account,
        assignedPerson: payload.contact,
        // TODO: fixme 2023-11-07, shouldn't we also have available companies/people here?
      },
      deal: { ...state.recordData.deal, ...payload },
    },
  }))
  .handleAction(updateDeal.failure, (state) => ({
    ...state,
    dealUpdateLoading: false,
  }))
  .handleAction(deleteDeal.request, (state) => ({
    ...state,
    deleteLoading: true,
    hasChanges: false,
  }))
  .handleAction([deleteDeal.success, deleteDeal.failure], (state) => ({
    ...state,
    deleteLoading: false,
  }))
  .handleAction(createDealNote.request, (state) => ({
    ...state,
    dealNoteCreateLoading: true,
  }))
  .handleAction(createDealNote.success, (state, action) => ({
    ...state,
    dealNoteCreateLoading: false,
    recordData: { ...state.recordData, notes: [action.payload, ...state.recordData.notes] },
  }))
  .handleAction(createDealNote.failure, (state) => ({
    ...state,
    dealNoteCreateLoading: false,
  }))
  .handleAction(updateDealNote.request, (state, { payload }) => ({
    ...state,
    dealNoteUpdateLoadingIds: [...state.dealNoteUpdateLoadingIds, payload.id],
  }))
  .handleAction(updateDealNote.success, (state, action) => ({
    ...state,
    dealNoteUpdateLoadingIds: state.dealNoteUpdateLoadingIds.filter(
      (id) => id !== action.payload.id
    ),
    recordData: {
      ...state.recordData,
      notes: [
        action.payload,
        ...state.recordData.notes.filter((note) => note.id !== action.payload.id),
      ],
    },
  }))
  .handleAction(updateDealNote.failure, (state, action) => ({
    ...state,
    dealNoteUpdateLoadingIds: state.dealNoteUpdateLoadingIds.filter((id) => id !== action.payload),
  }))
  .handleAction(deleteDealNote.request, (state, action) => ({
    ...state,
    dealNoteDeleteLoadingIds: state.dealNoteDeleteLoadingIds.concat(action.payload.id),
  }))
  .handleAction(deleteDealNote.success, (state, action) => ({
    ...state,
    dealNoteDeleteLoadingIds: state.dealNoteDeleteLoadingIds.filter((id) => id !== action.payload),
    recordData: {
      ...state.recordData,
      notes: state.recordData.notes.filter((note) => note.id !== action.payload),
    },
  }))
  .handleAction(deleteDealNote.failure, (state, action) => ({
    ...state,
    dealNoteDeleteLoadingIds: state.dealNoteDeleteLoadingIds.filter((id) => id !== action.payload),
  }))
  .handleAction(uploadDealFiles.request, (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadDealFiles.success, (state, action) => {
    const filesUploaded = compact(action.payload.map(({ uploadedFile }) => uploadedFile));
    return {
      ...state,
      filesAdded: [...state.filesAdded, ...action.payload],
      filesUploading: false,
      recordData: {
        ...state.recordData,
        files: [...state.recordData.files, ...filesUploaded].sort(fileCreatedAtDescComparator),
      },
    };
  })
  .handleAction(uploadDealFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(deleteDealFile.request, (state, { payload }) => ({
    ...state,
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== payload.id),
    filesToDeletedIds: state.filesToDeletedIds.concat(payload.id),
    recordData: {
      ...state.recordData,
      files: state.recordData.files.filter((file) => file.id !== payload.id),
    },
  }))
  .handleAction(deleteDealFile.success, (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.file.id),
    recordData: payload.removed
      ? {
          ...state.recordData,
          // filtering out removed file for case when we navigate back to original deal before undo expired
          files:
            state.recordData.deal?.id === payload.dealId
              ? state.recordData.files.filter((file) => file.id !== payload.file.id)
              : state.recordData.files,
        }
      : {
          ...state.recordData,
          files:
            state.recordData.deal?.id === payload.dealId &&
            !state.recordData.files.some((file) => file.id === payload.file.id)
              ? [...state.recordData.files, payload.file].sort(fileCreatedAtDescComparator)
              : state.recordData.files,
        },
  }))
  .handleAction(deleteDealFile.failure, (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.file.id),
    recordData: {
      ...state.recordData,
      files:
        state.recordData.deal?.id === payload.dealId
          ? [...state.recordData.files, payload.file].sort(fileCreatedAtDescComparator)
          : state.recordData.files,
    },
  }))
  .handleAction(clearAllUploadedDealFiles, (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }))
  .handleAction(addDealParentCompany.request, (state) => ({
    ...state,
    addDealParentCompanyLoading: true,
  }))
  .handleAction(addDealParentCompany.success, (state, { payload }) => ({
    ...state,
    addDealParentCompanyLoading: false,
    recordData: {
      ...state.recordData,
      associations: { ...state.recordData.associations, associatedCompany: payload },
    },
  }))
  .handleAction(addDealParentCompany.failure, (state) => ({
    ...state,
    addDealParentCompanyLoading: false,
  }))
  .handleAction(removeDealParentCompany.request, (state) => ({
    ...state,
    removeDealParentCompanyLoading: true,
  }))
  .handleAction(removeDealParentCompany.success, (state) => ({
    ...state,
    recordData: {
      ...state.recordData,
      associations: { ...state.recordData.associations, associatedCompany: undefined },
    },
    removeDealParentCompanyLoading: false,
  }))
  .handleAction(removeDealParentCompany.failure, (state) => ({
    ...state,
    removeDealParentCompanyLoading: false,
  }))
  .handleAction(addDealParentPerson.request, (state) => ({
    ...state,
    addDealParentPersonLoading: true,
  }))
  .handleAction(addDealParentPerson.success, (state, { payload }) => ({
    ...state,
    addDealParentPersonLoading: false,
    recordData: {
      ...state.recordData,
      associations: { ...state.recordData.associations, associatedPerson: payload },
    },
  }))
  .handleAction(addDealParentPerson.failure, (state) => ({
    ...state,
    addDealParentPersonLoading: false,
  }))
  .handleAction(removeDealParentPerson.request, (state) => ({
    ...state,
    removeDealParentPersonLoading: true,
  }))
  .handleAction(removeDealParentPerson.success, (state) => ({
    ...state,
    recordData: {
      ...state.recordData,
      associations: { ...state.recordData.associations, associatedPerson: undefined },
    },
    removeDealParentPersonLoading: false,
  }))
  .handleAction(removeDealParentPerson.failure, (state) => ({
    ...state,
    removeDealParentPersonLoading: false,
  }))
  .handleAction(postponeActivity.request, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.concat(payload.id),
  }))
  .handleAction(postponeActivity.success, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
    recordData: updateRecordData(state, payload),
  }))
  .handleAction(postponeActivity.failure, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))

  .handleAction(toggleCompleteActivity.request, (state, { payload }) => ({
    ...state,
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.concat(payload.id),
  }))
  .handleAction(toggleCompleteActivity.success, (state, { payload }) => ({
    ...state,
    recordData: updateRecordData(state, payload),
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
  }))
  .handleAction(toggleCompleteActivity.failure, (state, { payload }) => ({
    ...state,
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))
  .handleAction(updateActivityNote.request, (state, { payload }) => ({
    ...state,
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.concat(payload.activity.id),
  }))
  .handleAction(updateActivityNote.success, (state, { payload }) => ({
    ...state,
    recordData: updateRecordData(state, payload),
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
  }))
  .handleAction(updateActivityNote.failure, (state, { payload }) => ({
    ...state,
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))
  .handleAction(changeActivitiesFilter, (state, action) => ({
    ...state,
    activitiesFilter: action.payload,
  }))
  .handleAction(changeActivitiesSearchQuery, (state, action) => ({
    ...state,
    activitiesSearchQuery: action.payload,
  }))
  .handleAction(changeActivitiesRecapRange, (state, action) => ({
    ...state,
    activitiesRecapRange: action.payload,
  }))
  .handleAction(changeActivitiesSelectedActivityTypes, (state, { payload }) => ({
    ...state,
    activitiesSelectedActivityTypes: payload,
  }))
  .handleAction(setActiveTab, (state, { payload }) => ({
    ...state,
    activeTab: payload,
  }))
  .handleAction(setActivityAutoScrolling, (state, { payload }) => ({
    ...state,
    activityAutoScrolling: payload,
  }))
  .handleAction(changeActivitiesOrder, (state, action) => ({
    ...state,
    activitiesOrder: action.payload,
  }))
  .handleAction(changeActivitiesAssignees, (state, { payload }) => ({
    ...state,
    activitiesSelectedAssignees: payload,
  }))
  .handleAction(setHasChangesFlag, (state, { payload }) => ({
    ...state,
    hasChanges: payload,
  }))
  .handleAction(changeAssociatedEntities.request, (state, { payload }) => ({
    ...state,
    entityRelatingSuccess: false,
    recordData: {
      ...state.recordData,
      associations: {
        ...state.recordData.associations,
        associatedCompany:
          "company" in payload ? payload.company : state.recordData.associations.associatedCompany,
        associatedPerson:
          "person" in payload ? payload.person : state.recordData.associations.associatedPerson,
        availableLoading: true,
      },
    },
  }))
  .handleAction(changeAssociatedEntities.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      associations: { ...state.recordData.associations, ...payload, availableLoading: false },
    },
  }))
  .handleAction(changeAssociatedEntities.failure, (state) => ({
    ...state,
    recordData: {
      ...state.recordData,
      associations: { ...state.recordData.associations, availableLoading: false },
    },
  }))
  .handleAction(relateUnrelateEntities.success, (state, { payload: { person, unrelate } }) => ({
    ...state,
    entityRelating: false,
    entityRelatingSuccess: unrelate ? false : true,
    recordData: {
      ...state.recordData,
      associations: {
        ...state.recordData.associations,
        associatedPerson: person ?? state.recordData.associations.associatedPerson,
      },
    },
  }))
  .handleAction(relateUnrelateEntities.failure, (state) => ({
    ...state,
    entityRelating: false,
    entityRelatingSuccess: false,
  }))
  .handleAction(updateDealFrequency.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      deal: { ...state.recordData.deal, ...payload },
    },
  }));

export type DealRecordActions = Actions;

export default dealRecord;
