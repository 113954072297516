import { defineMessages } from "react-intl";

const messages = defineMessages({
  activityDateTimeDescription: {
    id: "email.creationModal.activityCreationModal.activityDateTimeDescription",
    defaultMessage: "Date and time will be a reflection of when each email is sent",
    description: "Activity date and time description in activity creation modal",
  },
  activityName: {
    id: "email.creationModal.activityCreationModal.activityName",
    defaultMessage: "Title",
    description: "Activity name label in activity creation modal",
  },
  activityType: {
    id: "email.creationModal.activityCreationModal.activityType",
    defaultMessage: "Type",
    description: "Activity type label in activity creation modal",
  },
  assignee: {
    id: "email.creationModal.activityCreationModal.assignee",
    defaultMessage: "Assignee",
    description: "Assignee label in activity creation modal",
  },
  closeConfirmationNo: {
    id: "email.creationModal.activityCreationModal.closeConfirmationNo",
    defaultMessage: "No, Stay",
    description: "Close confirmation no",
  },
  closeConfirmationTitle: {
    id: "email.creationModal.activityCreationModal.closeConfirmationTitle",
    defaultMessage: "Are you sure? Emails will not be sent and activities will not be created.",
    description: "Close confirmation title",
  },
  closeConfirmationYes: {
    id: "email.creationModal.activityCreationModal.closeConfirmationYes",
    defaultMessage: "Yes, exit",
    description: "Close confirmation yes",
  },
  doesNotRepeat: {
    id: "email.creationModal.activityCreationModal.doesNotRepeat",
    defaultMessage: "Does not repeat",
    description: "Does not repeat label in activity creation modal",
  },
  goBack: {
    id: "email.creationModal.activityCreationModal.goBack",
    defaultMessage: "Go back",
    description: "Go back button in activity creation modal",
  },
  note: {
    id: "email.creationModal.activityCreationModal.note",
    defaultMessage:
      "Values will be filled for all activities generated. You can edit individual activities after creation. Activities are not generated until the email is actually sent.",
    description: "Note in activity creation modal",
  },
  notificationsDisabled: {
    id: "email.creationModal.notificationsDisabled",
    defaultMessage: "Notifications disabled",
    description: "Notifications are disabled for this email",
  },
  title: {
    id: "email.creationModal.activityCreationModal.title",
    defaultMessage: "Create {count} {count, plural, one {Activity} other {Activities}}",
    description: "Title of activity creation modal",
  },
});

export default messages;
