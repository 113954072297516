import { isAfter } from "date-fns/esm";

import Organization from "@mapmycustomers/shared/types/Organization";

import { parseApiDateAsNumber } from "./parsers";

const hasTrialExpired = (organization?: Organization) =>
  organization &&
  organization.plan.trialing &&
  isAfter(Date.now(), parseApiDateAsNumber(organization.trialExpiresAt));

export default hasTrialExpired;
