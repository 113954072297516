import React from "react";

import { FormattedMessage } from "react-intl";

import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";

interface Props {
  stepIndex: number;
}

const RegisterMemberTitle: React.FC<Props> = ({ stepIndex }) => {
  return (
    <p className={commonStyles.title}>
      {stepIndex === 0 && (
        <FormattedMessage
          id="auth.registerMember.title.invitationInfoStep"
          defaultMessage="Welcome!"
          description="First step's title in sign up form for team member"
        />
      )}
      {stepIndex === 1 && (
        <FormattedMessage
          id="auth.registerMember.title.contactInfoStep"
          defaultMessage="A bit about you"
          description="Second step's title in sign up form for team member"
        />
      )}
      {stepIndex === 2 && (
        <FormattedMessage
          id="auth.registerMember.title.passwordStep"
          defaultMessage="One last thing"
          description="Third step's title in sign up form for team member"
        />
      )}
    </p>
  );
};

export default RegisterMemberTitle;
