import React from "react";

import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import ParticipantStatus from "@mapmycustomers/shared/enum/ParticipantStatus";
import Participant from "@mapmycustomers/shared/types/Participant";

import { getParticipantStatusDisplayName } from "@app/util/ui";

import styles from "./AttendeeItem.module.scss";

const participantStatusIcons: Record<ParticipantStatus, IconDefinition | null> = {
  [ParticipantStatus.MAYBE]: faQuestionCircle,
  [ParticipantStatus.NO]: faTimesCircle,
  [ParticipantStatus.NOREPLY]: null,
  [ParticipantStatus.YES]: faCheckCircle,
};
const participantStatusIconClassNames: Record<ParticipantStatus, null | string> = {
  [ParticipantStatus.MAYBE]: styles.gray,
  [ParticipantStatus.NO]: styles.red,
  [ParticipantStatus.NOREPLY]: null,
  [ParticipantStatus.YES]: styles.green,
};

interface Props {
  className?: string;
  participant: Participant;
}

const AttendeeItem: React.FC<Props> = ({ className, participant }) => {
  const intl = useIntl();
  const currentStatusIcon: IconDefinition | null = participantStatusIcons[participant.status];

  return (
    <div className={cn(styles.container, className)}>
      {currentStatusIcon && (
        <FontAwesomeIcon
          className={cn(styles.icon, participantStatusIconClassNames[participant.status])}
          icon={currentStatusIcon}
        />
      )}
      <Tooltip placement="right" title={getParticipantStatusDisplayName(intl, participant.status)}>
        <div className={styles.email}>{participant.email}</div>
      </Tooltip>
    </div>
  );
};

export default AttendeeItem;
