import React, { useCallback } from "react";

import { faChevronDown, faTextSize } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import Dropdown from "antd/es/dropdown";
import { Editor } from "slate";
import { useSlate } from "slate-react";

import TextSize from "@mapmycustomers/shared/enum/textEditor/TextSize";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { useConfigProvider } from "../../../ConfigProvider";
import commonStyles from "../../EmailBody.module.scss";
import ActionTooltipWrapper from "../ActionTooltipWrapper";
import styles from "../Toolbar.module.scss";

interface Props {}

const TextSizeSelector: React.FC<Props> = () => {
  const editor = useSlate();
  const configProvider = useConfigProvider();

  const handleToggleTextSize = useCallback(
    (size: TextSize) => {
      Editor.addMark(editor, "size", size);
    },
    [editor]
  );

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: TextSize.SMALL,
            label: (
              <span className={commonStyles.sizeSmall}>
                {configProvider.formatMessage("ui.emailBody.toolbar.small")}
              </span>
            ),
          },
          {
            key: TextSize.MEDIUM,
            label: (
              <span className={commonStyles.sizeMedium}>
                {configProvider.formatMessage("ui.emailBody.toolbar.medium")}
              </span>
            ),
          },
          {
            key: TextSize.LARGE,
            label: (
              <span className={commonStyles.sizeLarge}>
                {configProvider.formatMessage("ui.emailBody.toolbar.large")}
              </span>
            ),
          },
        ],
        onClick: (e) => handleToggleTextSize(e.key as TextSize),
        onMouseDown: stopEvents,
      }}
      placement="top"
    >
      <span className={styles.togglerButton} onMouseDown={stopEvents}>
        <ActionTooltipWrapper placement="bottom" translationString="ui.emailBody.toolbar.size">
          <Row align="middle" className={styles.togglerButtonContent} gutter={4}>
            <Col>
              <FontAwesomeIcon icon={faTextSize} />
            </Col>
            <Col>
              <FontAwesomeIcon className={styles.dropDownChevron} icon={faChevronDown} size="xs" />
            </Col>
          </Row>
        </ActionTooltipWrapper>
      </span>
    </Dropdown>
  );
};

export default TextSizeSelector;
