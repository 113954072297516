import React from "react";

import { SizeType } from "antd/es/config-provider/SizeContext";
import { useIntl } from "react-intl";

import { TextField } from "@mapmycustomers/ui";

interface Props {
  error?: string;
  isDuplicateName?: boolean;
  onChange: (name: string) => void;
  size?: SizeType;
  value: string;
}

const RouteName: React.FC<Props> = ({ error, isDuplicateName, onChange, size, value }) => {
  const intl = useIntl();
  return (
    <TextField
      error={
        isDuplicateName
          ? intl.formatMessage({
              id: "createRouteForm.error.duplicateName",
              defaultMessage: "Route with such name already exists",
              description: "Duplicate route name error message on the Create Route form",
            })
          : error
      }
      label={intl.formatMessage({
        id: "createRouteForm.field.name",
        defaultMessage: "* Route Name",
        description: "Route name field on the Create Route form",
      })}
      onChange={onChange}
      required
      size={size}
      value={value}
    />
  );
};

export default RouteName;
