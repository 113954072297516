enum Legend {
  ALERTS = "alerts",
  BOUNDARIES = "boundaries",
  COLOR_KEY = "colorKey",
  HEAT_MAP = "heatMap",
  SHAPE_KEY = "shapeKey",
  TRAFFIC = "traffic",
  USER_LOCATIONS = "userLocations",
}

export default Legend;
