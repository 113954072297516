import { Lead, MapEntity } from "@mapmycustomers/shared/types/entity";
import { EntityPin, MultiPin } from "@mapmycustomers/shared/types/map";

export const mapEntityIdGetter = (entity: MapEntity) => `${entity.entity}:${entity.id}`;

export const annotationIdGetter = (annotation: {
  entityId: MapEntity["id"];
  entityType: MapEntity["entity"];
}) => `${annotation.entityType}:${annotation.entityId}`;

export const mapPinIdGetter = (entity: EntityPin) => `${entity.data.entity}:${entity.data.id}`;
export const multiPinIdGetter = (entity: MultiPin) => entity.id;

export const leadIdGetter = (entity: Lead) => entity.place_id;
