import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faBriefcase } from "@fortawesome/free-solid-svg-icons/faBriefcase";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { faFileImport } from "@fortawesome/free-solid-svg-icons/faFileImport";
import { faFlag } from "@fortawesome/free-solid-svg-icons/faFlag";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons/faFunnelDollar";
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap";
import { faRoad } from "@fortawesome/free-solid-svg-icons/faRoad";
import { faRoute } from "@fortawesome/free-solid-svg-icons/faRoute";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUsersCog } from "@fortawesome/free-solid-svg-icons/faUsersCog";
import AntDMenu, { MenuProps } from "antd/es/menu";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";

import Feature from "@mapmycustomers/shared/enum/Feature";
import CumulDashboard from "@mapmycustomers/shared/types/cumul/CumulDashboard";
import User from "@mapmycustomers/shared/types/User";

import Path from "@app/enum/Path";
import ReportsMenuOptions from "@app/enum/ReportsMenuOptions";
import { getPinnedDashboards } from "@app/store/cumul";
import {
  isCumulEnabled,
  isCumulPromotionalUIVisited,
  isCurrentUserManager,
  isCurrentUserOwner,
} from "@app/store/iam";
import { getUsers } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import { navbarMenuClick } from "@app/util/analytic/navbarAnalytics";
import useFeatures from "@app/util/hook/useFeatures";
import useVersion from "@app/util/hook/useVersion";

import styles from "./CommonMenu.module.scss";
import MenuItemTitle from "./MenuItemTitle";

interface Props {
  isCumulEnabled?: boolean;
  isCumulPromotionalUIVisited?: boolean;
  isOwnerOrManager?: boolean;
  pinnedDashboards?: CumulDashboard[];
  users: User[];
}

const Menu: React.FC<Props> = ({
  isCumulEnabled,
  isCumulPromotionalUIVisited,
  isOwnerOrManager,
  pinnedDashboards,
  users,
}) => {
  const intl = useIntl();

  const { pathname } = useLocation();
  const mapActive = pathname.startsWith(Path.MAP);
  const planActive = pathname.startsWith(Path.ACTIVITY);
  const listActive =
    pathname.startsWith(Path.COMPANY) ||
    pathname.startsWith(Path.PEOPLE) ||
    pathname.startsWith(Path.DEAL);
  const manageActive = pathname.startsWith(Path.IMPORT);
  const reportsActive = pathname.startsWith(Path.REPORTS);

  const handleNavigate: MenuProps["onSelect"] = useCallback((info) => {
    navbarMenuClick(info!.key);
  }, []);

  const reportsDefaultPath = isCumulEnabled
    ? pinnedDashboards && pinnedDashboards.length
      ? `dashboard/${pinnedDashboards[0].id}`
      : ReportsMenuOptions.ALL_DASHBOARDS
    : !isCumulPromotionalUIVisited && isOwnerOrManager && users.length > 10
    ? ReportsMenuOptions.UPGRADE_YOUR_REPORTS
    : ReportsMenuOptions.OVERVIEW;
  const { isVersion2Beta } = useVersion();

  const { hasFeature } = useFeatures();

  return (
    <nav className={styles.container}>
      <AntDMenu
        className={styles.menu}
        mode="horizontal"
        onSelect={handleNavigate}
        triggerSubMenuAction="click"
      >
        {
          // showing one menu or another, depends on map feature
          hasFeature(Feature.MAP) ? (
            <>
              <AntDMenu.Item className={styles.topItem} key="map">
                <MenuItemTitle active={mapActive} icon={faMap} path={Path.MAP}>
                  {intl.formatMessage({
                    id: "navbar.menu.map",
                    defaultMessage: "Map",
                    description: "Title of the Map menu item",
                  })}
                </MenuItemTitle>
              </AntDMenu.Item>
              <AntDMenu.Item className={styles.topItem} key="planner">
                <MenuItemTitle active={planActive} icon={faCalendarAlt} path={Path.ACTIVITY}>
                  {intl.formatMessage({
                    id: "navbar.menu.planner",
                    defaultMessage: "Planner",
                    description: "Title of the Planner menu item",
                  })}
                </MenuItemTitle>
              </AntDMenu.Item>
              {/* Routes Menu */}
              <AntDMenu.SubMenu
                className={styles.topItem}
                key="routes"
                popupClassName={styles.submenu}
                title={
                  <MenuItemTitle hasSubmenu icon={faRoad}>
                    {intl.formatMessage({
                      id: "navbar.menu.routes",
                      defaultMessage: "Routes",
                      description: "Title of the Routes menu item",
                    })}
                  </MenuItemTitle>
                }
              >
                <AntDMenu.Item key="companyRoutesPlan">
                  <MenuItemTitle legacyPath="/accounts/routing/list">
                    {intl.formatMessage({
                      id: "navbar.menu.companyRoutes",
                      defaultMessage: "Company Routes",
                      description: "Title of the Company Routes menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="peopleRoutesPlan">
                  <MenuItemTitle legacyPath="/contacts/routing/list">
                    {intl.formatMessage({
                      id: "navbar.menu.peopleRoutes",
                      defaultMessage: "People Routes",
                      description: "Title of the People Routes menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
              </AntDMenu.SubMenu>
              {/* Records Menu */}
              <AntDMenu.SubMenu
                className={styles.topItem}
                key="records"
                popupClassName={styles.submenu}
                title={
                  <MenuItemTitle active={listActive} hasSubmenu icon={faClipboardList}>
                    {intl.formatMessage({
                      id: "navbar.menu.records",
                      defaultMessage: "Records",
                      description: "Title of the Records menu item",
                    })}
                  </MenuItemTitle>
                }
              >
                <AntDMenu.Item key="companiesListRecords">
                  <MenuItemTitle path={Path.COMPANY}>
                    {intl.formatMessage({
                      id: "navbar.menu.companies",
                      defaultMessage: "Companies",
                      description: "Title of the Companies List menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="peopleListRecords">
                  <MenuItemTitle path={Path.PEOPLE}>
                    {intl.formatMessage({
                      id: "navbar.menu.people",
                      defaultMessage: "People",
                      description: "Title of the People List menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="funnelsDealsRecords">
                  <MenuItemTitle path={Path.DEAL}>
                    {intl.formatMessage({
                      id: "navbar.menu.funnelsAndDeals",
                      defaultMessage: "Deals and Funnels",
                      description: "Title of the Funnels/Deals menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
              </AntDMenu.SubMenu>
            </>
          ) : (
            <>
              {/* Maps Menu */}
              <AntDMenu.SubMenu
                className={styles.topItem}
                key="oldmap"
                popupClassName={styles.submenu}
                title={
                  <MenuItemTitle hasSubmenu icon={faMap}>
                    {intl.formatMessage({
                      id: "navbar.menu.map",
                      defaultMessage: "Map",
                      description: "Title of the Map menu item",
                    })}
                  </MenuItemTitle>
                }
              >
                <AntDMenu.Item key="companiesMap">
                  <MenuItemTitle icon={faBuilding} legacyPath="/accounts/map">
                    {intl.formatMessage({
                      id: "navbar.menu.companiesMap",
                      defaultMessage: "Companies Map",
                      description: "Title of the Companies Map menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="peopleMap">
                  <MenuItemTitle icon={faUsers} legacyPath="/contacts/map">
                    {intl.formatMessage({
                      id: "navbar.menu.peopleMap",
                      defaultMessage: "People Map",
                      description: "Title of the People Map menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="dealsMap">
                  <MenuItemTitle icon={faHandshake} legacyPath="/deals/map">
                    {intl.formatMessage({
                      id: "navbar.menu.dealsMap",
                      defaultMessage: "Deals Map",
                      description: "Title of the Deals Map menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="territoriesMap">
                  <MenuItemTitle icon={faFlag} legacyPath="/accounts/territories/map">
                    {intl.formatMessage({
                      id: "navbar.menu.territoriesMap",
                      defaultMessage: "Territories Map",
                      description: "Title of the Territories Map menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
              </AntDMenu.SubMenu>
              {/* Plan Menu */}
              <AntDMenu.SubMenu
                className={styles.topItem}
                key="plan"
                popupClassName={styles.submenu}
                title={
                  <MenuItemTitle active={planActive} hasSubmenu icon={faCheckDouble}>
                    {intl.formatMessage({
                      id: "navbar.menu.plan",
                      defaultMessage: "Plan",
                      description: "Title of the Plan menu item",
                    })}
                  </MenuItemTitle>
                }
              >
                <AntDMenu.Item key="activitiesPlan">
                  <MenuItemTitle icon={faCalendarAlt} path={Path.ACTIVITY}>
                    {intl.formatMessage({
                      id: "navbar.menu.activities",
                      defaultMessage: "Activities",
                      description: "Title of the Activities menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.SubMenu
                  key="routesPlan"
                  title={
                    <MenuItemTitle hasSubmenu icon={faRoute}>
                      {intl.formatMessage({
                        id: "navbar.menu.routes",
                        defaultMessage: "Routes",
                        description: "Title of the Routes menu item",
                      })}
                    </MenuItemTitle>
                  }
                >
                  <AntDMenu.Item key="companyRoutesPlan">
                    <MenuItemTitle legacyPath="/accounts/routing/list">
                      {intl.formatMessage({
                        id: "navbar.menu.companyRoutes",
                        defaultMessage: "Company Routes",
                        description: "Title of the Company Routes menu item",
                      })}
                    </MenuItemTitle>
                  </AntDMenu.Item>
                  <AntDMenu.Item key="peopleRoutesPlan">
                    <MenuItemTitle legacyPath="/contacts/routing/list">
                      {intl.formatMessage({
                        id: "navbar.menu.peopleRoutes",
                        defaultMessage: "People Routes",
                        description: "Title of the People Routes menu item",
                      })}
                    </MenuItemTitle>
                  </AntDMenu.Item>
                </AntDMenu.SubMenu>
                <AntDMenu.SubMenu
                  key="groupsPlan"
                  title={
                    <MenuItemTitle hasSubmenu icon={faLayerGroup}>
                      {intl.formatMessage({
                        id: "navbar.menu.groups",
                        defaultMessage: "Groups",
                        description: "Title of the Groups menu item",
                      })}
                    </MenuItemTitle>
                  }
                >
                  <AntDMenu.Item key="companyGroupsPlan">
                    <MenuItemTitle legacyPath="/accounts/groups/list">
                      {intl.formatMessage({
                        id: "navbar.menu.companyGroups",
                        defaultMessage: "Company Groups",
                        description: "Title of the Company Groups menu item",
                      })}
                    </MenuItemTitle>
                  </AntDMenu.Item>
                  <AntDMenu.Item key="peopleGroupsPlan">
                    <MenuItemTitle legacyPath="/contacts/groups/list">
                      {intl.formatMessage({
                        id: "navbar.menu.peopleGroups",
                        defaultMessage: "People Groups",
                        description: "Title of the People Groups menu item",
                      })}
                    </MenuItemTitle>
                  </AntDMenu.Item>
                  <AntDMenu.Item key="dealGroupsPlan">
                    <MenuItemTitle legacyPath="/deals/groups/list">
                      {intl.formatMessage({
                        id: "navbar.menu.dealGroups",
                        defaultMessage: "Deal Groups",
                        description: "Title of the Deal Groups menu item",
                      })}
                    </MenuItemTitle>
                  </AntDMenu.Item>
                </AntDMenu.SubMenu>
              </AntDMenu.SubMenu>

              {/* Records Menu */}
              <AntDMenu.SubMenu
                className={styles.topItem}
                key="records"
                popupClassName={styles.submenu}
                title={
                  <MenuItemTitle active={listActive} hasSubmenu icon={faClipboardList}>
                    {intl.formatMessage({
                      id: "navbar.menu.records",
                      defaultMessage: "Records",
                      description: "Title of the Records menu item",
                    })}
                  </MenuItemTitle>
                }
              >
                <AntDMenu.Item key="companiesListRecords">
                  <MenuItemTitle icon={faBuilding} path={Path.COMPANY}>
                    {intl.formatMessage({
                      id: "navbar.menu.companiesList",
                      defaultMessage: "Companies List",
                      description: "Title of the Companies List menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="peopleListRecords">
                  <MenuItemTitle icon={faUsers} path={Path.PEOPLE}>
                    {intl.formatMessage({
                      id: "navbar.menu.peopleList",
                      defaultMessage: "People List",
                      description: "Title of the People List menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="funnelsDealsRecords">
                  <MenuItemTitle
                    icon={faFunnelDollar}
                    legacyPath={isVersion2Beta ? undefined : Path.DEAL}
                    path={isVersion2Beta ? Path.DEAL : undefined}
                  >
                    {intl.formatMessage({
                      id: "navbar.menu.dealsList",
                      defaultMessage: "Funnels/Deals",
                      description: "Title of the Funnels/Deals menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
              </AntDMenu.SubMenu>

              {/* Manage Menu */}
              <AntDMenu.SubMenu
                className={styles.topItem}
                key="manage"
                popupClassName={styles.submenu}
                title={
                  <MenuItemTitle active={manageActive} hasSubmenu icon={faBriefcase}>
                    {intl.formatMessage({
                      id: "navbar.menu.manage",
                      defaultMessage: "Manage",
                      description: "Title of the Manage menu item",
                    })}
                  </MenuItemTitle>
                }
              >
                <AntDMenu.Item key="importsManage">
                  <MenuItemTitle icon={faFileImport} path={Path.IMPORT}>
                    {intl.formatMessage({
                      id: "navbar.menu.imports",
                      defaultMessage: "Imports",
                      description: "Title of the Imports menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
                <AntDMenu.Item key="manageUsersManage">
                  <MenuItemTitle icon={faUsersCog} legacyPath="/dashboard/teams">
                    {intl.formatMessage({
                      id: "navbar.menu.manageUsers",
                      defaultMessage: "Manage Users",
                      description: "Title of the Manage Users menu item",
                    })}
                  </MenuItemTitle>
                </AntDMenu.Item>
              </AntDMenu.SubMenu>
            </>
          )
        }

        {/* Reports Menu */}
        <AntDMenu.Item className={styles.topItem} key="reports">
          <MenuItemTitle
            active={reportsActive}
            icon={faChartBar}
            path={`${Path.REPORTS}/${reportsDefaultPath}`}
          >
            {intl.formatMessage({
              id: "navbar.menu.reports",
              defaultMessage: "Reports",
              description: "Title of the Reports menu item",
            })}
          </MenuItemTitle>
        </AntDMenu.Item>
      </AntDMenu>
    </nav>
  );
};

const mapStateToProps = (state: RootState) => ({
  isCumulEnabled: isCumulEnabled(state),
  isCumulPromotionalUIVisited: isCumulPromotionalUIVisited(state),
  isOwnerOrManager: isCurrentUserOwner(state) || isCurrentUserManager(state),
  pinnedDashboards: getPinnedDashboards(state),
  users: getUsers(state),
});

export default connect(mapStateToProps)(Menu);
