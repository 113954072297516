import React, { useCallback } from "react";

import { faA, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import Tooltip from "antd/es/tooltip";
import { Editor } from "slate";
import { useSlate } from "slate-react";

import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import ColorDropdown from "../../../colorComponents/ColorDropdown";
import { useConfigProvider } from "../../../ConfigProvider";
import styles from "../Toolbar.module.scss";

interface Props {}

const TextColorToggler: React.FC<Props> = () => {
  const editor = useSlate();
  const configProvider = useConfigProvider();

  const handleToggleBlock = useCallback(
    (color: AnyColor) => {
      Editor.addMark(editor, "color", color);
    },
    [editor]
  );

  const color = Editor.marks(editor)?.color ?? ("black" as AnyColor);

  return (
    <ColorDropdown
      colorPanelProps={{ showColorSpace: true, showNamedColors: true }}
      onChange={handleToggleBlock}
      placement="top"
      value={color}
    >
      <Tooltip title={configProvider.formatMessage("ui.emailBody.toolbar.textColor")}>
        <Row align="middle" className={styles.togglerButton} gutter={4} onMouseDown={stopEvents}>
          <Col>
            <FontAwesomeIcon
              icon={faA}
              style={{
                borderBottom: `2px solid ${color}`,
              }}
            />
          </Col>
          <Col>
            <FontAwesomeIcon className={styles.dropDownChevron} icon={faChevronDown} size="xs" />
          </Col>
        </Row>
      </Tooltip>
    </ColorDropdown>
  );
};

export default TextColorToggler;
