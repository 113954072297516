import React, { ReactNode, useCallback, useEffect } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import { ColumnsType } from "antd/es/table/Table";
import { useIntl } from "react-intl";

import { Deal } from "@mapmycustomers/shared/types/entity";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { Modal } from "@mapmycustomers/ui";

import { DealListModalTitle } from "@app/component/DealListModalForCharts/DealListModalTitle";
import Table from "@app/component/Table";
import configService from "@app/config/ConfigService";
import Path from "@app/enum/Path";
import ReportType from "@app/enum/ReportType";
import ViewMode from "@app/scene/deal/enum/ViewMode";
import MapFilter from "@app/scene/reports/enums/MapFilter";
import downloadEntitiesAsCsv from "@app/scene/reports/utils/csv/downloadEntitiesAsCsv";
import authService from "@app/store/auth/AuthService";
import { RootState } from "@app/store/rootReducer";
import { getLegacyAppUrl } from "@app/util/appUrl";
import { PREVIEW_MODAL_LIMIT } from "@app/util/consts";
import useVersion from "@app/util/hook/useVersion";

import { DownloadConfirmation } from "./DownloadConfirmation";
import styles from "./PreviewDealsListModal.module.scss";
import { getDeals, getLoading, getTotal } from "./store";
import { fetchDeals } from "./store/actions";

export const DEFAULT_PAGE_SIZE = 5;

interface Props {
  columns: ColumnsType<Deal>;
  deals: Deal[];
  fetchDeals: typeof fetchDeals.request;
  filter: FilterModel;
  isLoading: boolean;
  onDealClick: (deal: Deal) => void;
  onHide: () => void;
  stageName?: string;
  subtitle?: ReactNode | string;
  title?: string;
  total: number;
}

const PreviewDealsListModal: React.FC<Props> = ({
  columns,
  deals,
  fetchDeals,
  filter,
  isLoading,
  onDealClick,
  onHide,
  stageName,
  subtitle,
  title,
  total,
}) => {
  const handlePageChange = useCallback(
    (page: number) => {
      fetchDeals({
        request: {
          filter,
          range: {
            endRow: page * DEFAULT_PAGE_SIZE,
            startRow: (page - 1) * DEFAULT_PAGE_SIZE,
          },
        },
      });
    },
    [fetchDeals, filter]
  );

  useEffect(() => {
    fetchDeals({ request: { filter } });
  }, [fetchDeals, filter]);

  const intl = useIntl();
  const token = authService.getToken();
  const { isVersion2Beta } = useVersion();

  const handleRowEvents = useCallback(
    (deal: Deal) => ({
      onClick: () => onDealClick?.(deal),
    }),
    [onDealClick]
  );

  if (!token) {
    return null;
  }

  return (
    <Modal
      destroyOnClose
      footer={[
        <Button
          className={styles.viewAllDealsLink}
          href={
            isVersion2Beta
              ? `${Path.DEAL}/${ViewMode.LIST}`
              : getLegacyAppUrl(configService.getBaseOldAppUrl(), token, "/deals/list")
          }
          key="viewAll"
          target="_blank"
          type="link"
        >
          {intl.formatMessage({
            id: "dealListModal.viewAllDeals",
            defaultMessage: "View all Deals",
            description: "View all Deals link on the Deal list modal",
          })}
        </Button>,
        <DownloadConfirmation
          key="download"
          onConfirm={() => downloadEntitiesAsCsv(deals, ReportType.DEALS, MapFilter.ALL_DEALS)}
        >
          <Button type="primary">
            {intl.formatMessage({
              id: "dealListModal.downloadDealsButton",
              defaultMessage: "Quick export",
              description: "Download Deals button on the list modal",
            })}
          </Button>
        </DownloadConfirmation>,
      ]}
      onCancel={onHide}
      title={
        <DealListModalTitle
          dealsTotal={total}
          stageName={stageName}
          subTitle={subtitle}
          title={title}
        />
      }
      visible
      width="clamp(300px, 70vw, 780px)"
    >
      <Table<Deal>
        clickableRows
        columns={columns}
        dataSource={deals}
        loading={isLoading}
        noHover
        onRow={handleRowEvents}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          onChange: handlePageChange,
          position: ["bottomCenter"],
          showSizeChanger: false,
          total: total < PREVIEW_MODAL_LIMIT ? total : PREVIEW_MODAL_LIMIT,
        }}
        rowKey="id"
        scrollable
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  deals: getDeals(state),
  isLoading: getLoading(state),
  total: getTotal(state),
});

const mapDispatchToProps = {
  fetchDeals: fetchDeals.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDealsListModal);
