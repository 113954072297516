import uniqBy from "lodash-es/uniqBy";

import {
  Activity,
  EntityType,
  EntityTypeSupportingRoutes,
} from "@mapmycustomers/shared/types/entity";

const getValidStops = (activities: Activity[], entityType: EntityTypeSupportingRoutes) =>
  uniqBy(
    activities.filter(({ account, contact }) =>
      entityType === EntityType.COMPANY ? account?.geoPoint : contact?.geoPoint
    ),
    entityType === EntityType.COMPANY ? "account.id" : "contact.id"
  );

export default getValidStops;
