import notification from "antd/es/notification";
import { defineMessages } from "react-intl";
import { all, delay, put, select, takeLatest } from "redux-saga/effects";

import { EntityType } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Organization from "@mapmycustomers/shared/types/Organization";
import Setting from "@mapmycustomers/shared/types/Setting";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import i18nService from "@app/config/I18nService";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import Iam from "@app/types/Iam";

import { callApi } from "../api/callApi";
import { handleError } from "../errors/actions";
import { getOrganization, getOrganizationSettings } from "../iam";
import { updateOrganization } from "../iam/actions";
import { notifyAboutChanges } from "../uiSync/actions";

import { getActivityTypes } from ".";
import {
  bulkUpdateActivityType,
  createActivityType,
  deleteActivityType,
  fetchActivityTypes,
  notifyActivityRelatedEntities,
  updateActivityType,
} from "./actions";

const messages = defineMessages({
  createSuccess: {
    id: "activity.activityTypeCreate.success",
    defaultMessage: "New activity type created",
    description: "New activity type create success message",
  },
  deleteSuccess: {
    id: "activity.activityTypeDelete.success",
    defaultMessage: "Activity type deleted succesfully",
    description: "Activity type delete success message",
  },
});

function* onFetchActivityTypes() {
  try {
    const org: Organization = yield select(getOrganization);
    const response: ListResponse<ActivityType> = yield callApi("fetchActivityTypes", org.id, {
      $limit: 1000,
      $order: "displayOrder",
    });
    yield put(fetchActivityTypes.success(response.data));
  } catch (error) {
    yield put(fetchActivityTypes.failure());
    yield put(handleError({ error }));
  }
}

function* onCreateActivityType({
  payload: { activityType, callback },
}: ReturnType<typeof createActivityType.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const response: ActivityType = yield callApi("createActivityType", org.id, activityType);
    yield put(createActivityType.success(response));

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.createSuccess),
      });
    }
    callback?.();
  } catch (error) {
    yield put(createActivityType.failure());
    yield put(handleError({ error }));
  }
}

function* onUpdateActivityType({ payload }: ReturnType<typeof updateActivityType.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const response: ActivityType = yield callApi("updateActivityType", org.id, payload);
    yield put(updateActivityType.success(response));
  } catch (error) {
    yield put(updateActivityType.failure(payload.id));
    yield put(handleError({ error }));
  }
}

function* onBulkUpdateActivityType({ payload }: ReturnType<typeof bulkUpdateActivityType.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const responseWithCheckInEnabledActivityTypes: ActivityType[] = yield all(
      payload
        .filter(({ checkInEnabled }) => checkInEnabled)
        .map((activityType) => callApi("updateActivityType", org.id, activityType))
    );
    const responseWithCheckInDisabledActivityTypes: ActivityType[] = yield all(
      payload
        .filter(({ checkInEnabled }) => !checkInEnabled)
        .map((activityType) => callApi("updateActivityType", org.id, activityType))
    );
    yield put(
      bulkUpdateActivityType.success([
        ...responseWithCheckInEnabledActivityTypes,
        ...responseWithCheckInDisabledActivityTypes,
      ])
    );
  } catch (error) {
    yield put(bulkUpdateActivityType.failure());
    yield put(handleError({ error }));
  }
}

function* onNotifyActivityRelatedEntities({
  payload,
}: ReturnType<typeof notifyActivityRelatedEntities>) {
  yield delay(1500);
  if (payload.account) {
    yield put(
      notifyAboutChanges({ entityType: EntityType.COMPANY, updatedIds: [payload.account.id] })
    );
  }
  if (payload.contact) {
    yield put(
      notifyAboutChanges({ entityType: EntityType.PERSON, updatedIds: [payload.contact.id] })
    );
  }
  if (payload.deal) {
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updatedIds: [payload.deal.id] }));
  }
}

function* onDeleteActivityType({ payload }: ReturnType<typeof deleteActivityType.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const organizationSettings: Organization["settings"] = yield select(getOrganizationSettings);
    const activityTypes: ActivityType[] = yield select(getActivityTypes);
    const activityTypeVisit: ActivityType | undefined = activityTypes.find(
      (at) => at.name === "Visit"
    );
    const defaultCheckInActivityTypeSetting: Setting | undefined = organizationSettings?.find(
      (s) => s.key === OrganizationSetting.DEFAULT_CHECK_IN_ACTIVITY_TYPE
    );
    yield callApi("deleteActivityType", org.id, payload);

    if (defaultCheckInActivityTypeSetting?.value === payload && activityTypeVisit?.id) {
      yield callApi("updateOrganizationSetting", org.id, {
        ...defaultCheckInActivityTypeSetting,
        value: activityTypeVisit?.id,
      });
      const updatedProfile: Iam = yield callApi("fetchMe");
      yield put(updateOrganization.success(updatedProfile));
    }

    yield put(deleteActivityType.success(payload));

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.deleteSuccess),
      });
    }
  } catch (error) {
    yield put(deleteActivityType.failure(payload));
    yield put(handleError({ error }));
  }
}

export function* activitySaga() {
  yield takeLatest(fetchActivityTypes.request, onFetchActivityTypes);
  yield takeLatest(createActivityType.request, onCreateActivityType);
  yield takeLatest(updateActivityType.request, onUpdateActivityType);
  yield takeLatest(bulkUpdateActivityType.request, onBulkUpdateActivityType);
  yield takeLatest(deleteActivityType.request, onDeleteActivityType);
  yield takeLatest(notifyActivityRelatedEntities, onNotifyActivityRelatedEntities);
}
