import { connect } from "react-redux";

import FileUploader from "@app/component/FileUploader";
import { RootState } from "@app/store/rootReducer";

import {
  clearAllUploadedCompanyFiles,
  removeCompanyFile,
  uploadCompanyFiles,
} from "../store/actions";
import { isFilesUploading } from "../store/selectors";

const mapStateToProps = (state: RootState) => ({
  fileUploading: isFilesUploading(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedCompanyFiles,
  onFileUpload: uploadCompanyFiles.request,
  onRemoveFile: removeCompanyFile.request,
};

const File = connect(mapStateToProps, mapDispatchToProps)(FileUploader);
export default File;
