import React, { useCallback } from "react";

import Group from "antd/es/radio/group";
import { RadioChangeEvent, RadioGroupProps } from "antd/es/radio/interface";
import cn from "classnames";

import styles from "./RadioGroup.module.scss";

interface Props<T> extends Omit<RadioGroupProps, "onChange" | "value"> {
  className?: string;
  onChange?: (value: T, event: RadioChangeEvent) => void;
  value?: T;
  vertical?: boolean;
}

const RadioGroup = <T = string,>({
  buttonStyle = "solid",
  children,
  className,
  onChange,
  vertical,
  ...props
}: Props<T>) => {
  const handleChange = useCallback(
    (event: RadioChangeEvent) => onChange && onChange(event.target.value, event),
    [onChange]
  );

  return (
    <Group
      buttonStyle={buttonStyle}
      className={cn(className, { [styles.vertical]: vertical })}
      onChange={handleChange}
      {...props}
    >
      {children}
    </Group>
  );
};

export default RadioGroup;
