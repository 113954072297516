import invariant from "tiny-invariant";

export interface Key
  extends Partial<
      Pick<
        KeyboardEvent,
        "altKey" | "code" | "ctrlKey" | "key" | "metaKey" | "shiftKey"
      >
    >,
    Pick<KeyboardEvent, "type"> {
  strict?: boolean;
}
export interface ShortcutCallback {
  (event: KeyboardEvent): void;
}

const VALID_TYPES = ["keyup", "keydown", "keypress"];

class ShortcutService {
  protected root: HTMLElement;
  protected listeners: Record<
    (typeof VALID_TYPES)[number],
    Map<Key, ShortcutCallback[]>
  >;

  constructor(root: HTMLElement) {
    this.root = root;
    this.listeners = VALID_TYPES.reduce(
      (result, type) => ({ ...result, [type]: new Map() }),
      {}
    );
    this.root.addEventListener("keypress", this.handleKey);
    this.root.addEventListener("keyup", this.handleKey);
    this.root.addEventListener("keydown", this.handleKey);
  }

  addKeyListener(key: Key, callback: ShortcutCallback) {
    this.validateKey(key);
    if (!this.listeners[key.type].has(key)) {
      this.listeners[key.type].set(key, []);
    }
    this.listeners[key.type].get(key)!.push(callback);
  }

  removeKeyListener(key: Key, callback: ShortcutCallback) {
    this.validateKey(key);
    if (!this.listeners[key.type].has(key)) {
      return;
    }
    const updatedListeners = this.listeners[key.type]
      .get(key)!
      .filter((item) => item !== callback);
    if (updatedListeners.length) {
      this.listeners[key.type].set(key, updatedListeners);
    } else {
      this.listeners[key.type].delete(key);
    }
  }

  destroy() {
    this.root.removeEventListener("keypress", this.handleKey);
    this.root.removeEventListener("keyup", this.handleKey);
    this.root.removeEventListener("keydown", this.handleKey);
    VALID_TYPES.forEach((type) => {
      this.listeners[type].clear();
    });
  }

  private validateKey(key: Key) {
    invariant(
      VALID_TYPES.includes(key.type),
      `Key type should be one of ${VALID_TYPES.join(", ")}.`
    );
    invariant(key.code || key.key, `Either key or code should be specified.`);
  }

  private doesKeyMatchEvent(event: KeyboardEvent, key: Key) {
    return Object.entries(key).every(([k, v]) => {
      if (k === "strict" || v == null) {
        return true; // we skip "strict" field and we skip all fields with no specific value (null or undefined)
      }
      if (!key.strict && (k === "ctrlKey" || k === "metaKey")) {
        return event.ctrlKey === v || event.metaKey === v;
      } else {
        return event[k as keyof KeyboardEvent] === v;
      }
    });
  }

  private handleKey = (event: KeyboardEvent) => {
    for (const key of this.listeners[event.type].keys()) {
      if (this.doesKeyMatchEvent(event, key)) {
        this.listeners[event.type]
          .get(key)!
          .forEach((callback) => callback(event));
      }
    }
  };
}

export default ShortcutService;
