import EntityType from "@mapmycustomers/shared/enum/EntityType";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";

import { EntityTypeWithFieldModel } from "@app/util/fieldModel/getByEntityType";

export const EVENTS_PAGE_SIZE = 10;

export const METRIC_TO_ENTITY_TYPE: Record<LeaderboardMetricFieldName, EntityTypeWithFieldModel> = {
  [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED]: EntityType.ACTIVITY,
  [LeaderboardMetricFieldName.CHECK_INS_COMPLETED]: EntityType.ACTIVITY,
  [LeaderboardMetricFieldName.COMPANIES_CREATED]: EntityType.COMPANY,
  [LeaderboardMetricFieldName.DEALS_CLOSED_LOST]: EntityType.DEAL,
  [LeaderboardMetricFieldName.DEALS_CLOSED_WON]: EntityType.DEAL,
  [LeaderboardMetricFieldName.DEALS_CREATED]: EntityType.DEAL,
  [LeaderboardMetricFieldName.DEALS_ROTTING]: EntityType.DEAL,
  [LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE]: EntityType.DEAL,
  [LeaderboardMetricFieldName.PEOPLE_CREATED]: EntityType.PERSON,
  [LeaderboardMetricFieldName.REVENUE_CLOSED_LOST]: EntityType.DEAL,
  [LeaderboardMetricFieldName.REVENUE_CLOSED_WON]: EntityType.DEAL,
  [LeaderboardMetricFieldName.ROUTES_CREATED]: EntityType.ROUTE,
  [LeaderboardMetricFieldName.TOTAL_FACE_TIME]: EntityType.ACTIVITY,
  [LeaderboardMetricFieldName.VERIFIED_CHECK_INS]: EntityType.ACTIVITY,
  [LeaderboardMetricFieldName.VERIFIED_FACE_TIME]: EntityType.ACTIVITY,
};
