enum PlatformFilterOperator {
  EQUALS = "$eq",
  NOT_EQUAL = "$ne",
  LESS_THAN = "$lt",
  GREATER_THAN = "$gt",
  LESS_THAN_OR_EQUAL = "$lte",
  GREATER_THAN_OR_EQUAL = "$gte",
  CONTAINS = "$in",
  NOT_CONTAINS = "$nin",
  STARTS_WITH = "$sw",
  ENDS_WITH = "$ew",
  GROUP_IN_ALL = "$all",
  GROUP_IN_ANY = "$any",
  INTERVAL = "$interval",
}

export default PlatformFilterOperator;
