import React from "react";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import { useIntl } from "react-intl";

import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { Alert } from "@mapmycustomers/ui";

import styles from "./CreateEditActivityModal.module.scss";

const CheckInActivityAlert = () => {
  const [info, , , toggleInfo] = useBoolean();
  const intl = useIntl();

  return (
    <Alert
      action={
        <Button className={styles.actionButton} onClick={toggleInfo} type="link">
          <FontAwesomeIcon className={styles.chevron} icon={info ? faAngleUp : faAngleDown} />
        </Button>
      }
      className={styles.checkInVerifiedAlert}
      message={
        <div>
          <p className={styles.message}>
            {intl.formatMessage(
              {
                id: "editCheckInActivityModal.alert.title",
                defaultMessage:
                  "<strong>Some edits may affect the verification status of this Check-In</strong>",
                description: "Create/Edit activity popconfirm title",
              },
              {
                br: <br />,
                strong: (text: string) => <strong>{text}</strong>,
              }
            )}
          </p>
          {info && (
            <p className={styles.description}>
              {intl.formatMessage({
                id: "editCheckInActivityModal.alert.description.description",
                defaultMessage:
                  "This Check-In is currently “Verified” based on the assignee of the user when checking in. Changes to the following fields will make this activity “Unverified”:",
                description: "Create/Edit activity alert description",
              })}
            </p>
          )}
          {info && (
            <ul>
              <li className={styles.description}>
                {intl.formatMessage({
                  id: "editCheckInActivityModal.alert.description.firstCase",
                  defaultMessage: "The Company or Person that was initially checked in with",
                  description: "Create/Edit activity alert description",
                })}
              </li>
              <li className={styles.message}>
                {intl.formatMessage({
                  id: "editCheckInActivityModal.alert.description.secondCase",
                  defaultMessage: "Start or End date and time",
                  description: "Create/Edit activity alert description",
                })}
              </li>
              <li className={styles.message}>
                {intl.formatMessage({
                  id: "editCheckInActivityModal.aler.description.thirdCase",
                  defaultMessage: "Completion status",
                  description: "Create/Edit activity alert description",
                })}
              </li>
            </ul>
          )}
        </div>
      }
      showIcon
      type="warning"
    />
  );
};

export default CheckInActivityAlert;
