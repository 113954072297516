import {
  EntitiesSupportingFieldCustomization,
  EntityType,
} from "@mapmycustomers/shared/types/entity";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";

import useActualLayoutSchema from "@app/util/useActualLayoutSchema";

interface UseEntityLayoutSchemaArgs {
  entity: EntitiesSupportingFieldCustomization;
  layout: FormLayout;
}

const useEntityLayoutSchema = ({ entity, layout }: UseEntityLayoutSchemaArgs) => {
  const crmActivityTypeId =
    entity.entity === EntityType.ACTIVITY ? entity.crmActivityType.id : undefined;
  const stageId = entity.entity === EntityType.DEAL ? entity.stage.id : undefined;

  return useActualLayoutSchema({ crmActivityTypeId, entityType: entity.entity, layout, stageId });
};

export default useEntityLayoutSchema;
