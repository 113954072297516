import { createReducer } from "typesafe-actions";

import EntityType from "@mapmycustomers/shared/enum/EntityType";

import {
  applyRecordsListSettings,
  fetchMultiPinRecords,
  hideMultiPinRecords,
  MultiPinRecordsActions,
  showMultiPinRecords,
  updateMultiPinRecordsSorting,
} from "./actions";
import { MULTIPIN_RECORDS_PAGE_SIZE } from "./consts";
import MultiPinState from "./MultiPinState";

export const initialMultiPinState: MultiPinState = {
  range: { endRow: MULTIPIN_RECORDS_PAGE_SIZE, startRow: 0 },
  records: [],
  sort: [],
  totalFilteredRecords: 0,
  totalRecords: 0,
  visibleEntities: [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL],
};

const multiPin = createReducer<MultiPinState, MultiPinRecordsActions>(initialMultiPinState)
  .handleAction(showMultiPinRecords.request, (state, { payload }) => ({
    ...state,
    multiPin: payload.multiPin,
    multiPinAddress: undefined,
    platformFilterModel: payload.platformFilterModel,
    visibleEntities: payload.visibleEntities,
  }))
  .handleAction(showMultiPinRecords.success, (state, { payload }) => ({
    ...state,
    multiPinAddress: payload.address,
  }))
  .handleAction(hideMultiPinRecords, (state) => ({
    ...state,
    multiPin: undefined,
  }))
  .handleAction(fetchMultiPinRecords.request, (state, { payload }) => ({
    ...state,
    loading: true,
    search: payload.request?.search ?? state.search,
  }))
  .handleAction(fetchMultiPinRecords.success, (state, { payload }) => ({
    ...state,
    loading: false,
    range: {
      endRow: payload.offset + payload.limit,
      startRow: payload.offset,
    },
    records: payload.data,
    totalFilteredRecords: payload.total,
    totalRecords: state.search ? state.totalRecords : payload.total,
  }))
  // .handleAction(resetRecordsListPagination, (state) => ({
  //   ...state,
  //     range: {
  //       startRow: 0,
  //       endRow: MAP_RECORDS_PAGE_SIZE,
  //   },
  // }))
  .handleAction(fetchMultiPinRecords.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyRecordsListSettings, (state, { payload }) => ({
    ...state,
    sort: payload.sort ?? state.sort,
  }))
  .handleAction(updateMultiPinRecordsSorting, (state, { payload }) => ({
    ...state,
    query: payload.query,
    sort: payload.sort,
  }));

export default multiPin;
