import React from "react";

import { useIntl } from "react-intl";

import { formatRawDate } from "@app/util/formatters";
import { parseApiDateWithTz } from "@app/util/parsers";

import styles from "./Title.module.scss";

interface Props {
  cadencedAt: string;
  title?: string;
}

const Title: React.FC<Props> = ({ cadencedAt, title }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title ||
          intl.formatMessage({
            id: "activities.calendarView.suggestions.cadence.modal.title",
            defaultMessage: "Records due to be seen",
          })}
      </div>
      <div className={styles.subTitle}>
        {formatRawDate(cadencedAt.substring(0, 10), "PPP", "", parseApiDateWithTz)}
      </div>
    </div>
  );
};

export default Title;
