import { createReducer } from "typesafe-actions";

import { EntityHistoryRowWithRecord } from "@mapmycustomers/shared/types/entity/EntityHistoryRow";

import { CreateEditActivityModalActions } from "@app/component/createEditEntity/Activity/store";
import { Actions as AppActions } from "@app/store/app/actions";
import { UiSyncActions } from "@app/store/uiSync";

import { Actions, fetchHistory } from "./actions";

export interface EntityHistoryState {
  history: EntityHistoryRowWithRecord[];
  loading: boolean;
  pageCount: number;
}

const initialState: EntityHistoryState = {
  history: [],
  loading: false,
  pageCount: 0,
};

const entityHistory = createReducer<
  EntityHistoryState,
  Actions | AppActions | CreateEditActivityModalActions | UiSyncActions
>(initialState)
  .handleAction(fetchHistory.request, (state) => ({
    ...state,
    history: [],
    loading: true,
  }))
  .handleAction(fetchHistory.success, (state, { payload: { history, pageCount } }) => ({
    ...state,
    history,
    loading: false,
    pageCount,
  }))
  .handleAction(fetchHistory.failure, (state) => ({
    ...state,
    history: [],
    loading: false,
  }));

export * from "./selectors";
export type EntityHistoryActions = Actions;
export default entityHistory;
