import { Descendant } from "slate";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

export const createCache = () => new Map<Descendant, number>();

const getTextLength = (value: Descendant[], cache: Map<Descendant, number>): number => {
  const keys = new Set(cache.keys());

  const result = value.reduce<number>((sum, element, index) => {
    if ("text" in element) {
      return sum + element.text.length;
    }

    if (cache.has(element)) {
      keys.delete(element);
    } else {
      cache.set(
        element,
        element.children.reduce<number>(
          (elementResult: number, child: Descendant) =>
            elementResult +
            ("text" in child
              ? child.text.length
              : child.type === ElementType.MENTION
              ? child.email.length + 1
              : 0),
          // We add 1 to length for all elements except last one, because we join them by 1 space
          index === value.length - 1 ? 0 : 1
        )
      );
    }
    return sum + (cache.get(element) as number);
  }, 0);

  // remove cache for all non-existing elements.
  keys.forEach((element) => cache.delete(element));

  return result;
};
export default getTextLength;
