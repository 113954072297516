import { all, select } from "redux-saga/effects";

import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";

import AssociationsState from "@app/store/associations/AssociationsState";
import { getCurrentUser, getOrganizationId } from "@app/store/iam";
import { getUsers } from "@app/store/members";

import ChangeAssociationsPayload from "./ChangeAssociationsPayload";
import { findCompanies, findDeals, findPeople } from "./fetchHelpers";

export type NextAssociationsState = Pick<
  AssociationsState,
  "availableCompanies" | "availableDeals" | "availablePeople" | "deniedUsers" | "inaccessibleEntity"
>;

const getNextAssociationsState = function* getNextAssociationsState({
  assignee,
  company,
  deal,
  entityType,
  person,
}: ChangeAssociationsPayload): Generator<any, NextAssociationsState, any> {
  const orgId: Organization["id"] = yield select(getOrganizationId);
  const currentUser: User = yield select(getCurrentUser);

  const [companies, people, deals]: [
    Company[] | undefined,
    Person[] | undefined,
    Deal[] | undefined
  ] = yield all([
    deal?.account?.id || person?.accounts
      ? findCompanies(orgId, [
          ...(deal?.account?.id ? [deal.account.id] : []),
          ...(person?.accounts ? person.accounts.map(({ id }) => id) : []),
        ])
      : undefined,
    company?.id ? findPeople(orgId, company?.id) : undefined,
    entityType !== EntityType.ACTIVITY || (!company?.id && !person?.id)
      ? undefined
      : findDeals(orgId, company?.id, person?.id),
  ]);

  let deniedUsers: undefined | User[];
  let inaccessibleEntity = false;

  if (entityType === EntityType.ACTIVITY) {
    const allUsers: User[] = yield select(getUsers);

    // when assignee is not set explicitly, we assume it's current user
    const assigneeId = assignee?.id ?? currentUser.id;
    deniedUsers = allUsers.filter(
      ({ id }) =>
        company?.userIds?.includes(id) === false ||
        person?.userIds?.includes(id) === false ||
        deal?.userIds?.includes(id) === false
    );

    inaccessibleEntity = new Set(deniedUsers.map((user) => user.id)).has(assigneeId);
  }
  return {
    availableCompanies: companies ?? [],
    availableDeals: deals ?? [],
    availablePeople: people ?? [],
    deniedUsers,
    inaccessibleEntity,
  };
};

export default getNextAssociationsState;
