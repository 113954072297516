import { push } from "connected-react-router";
import { put, select, takeEvery } from "redux-saga/effects";

import { Activity, Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse, {
  SimpleListResponse,
} from "@mapmycustomers/shared/types/viewModel/ListResponse";

import UserRecent from "@app/types/userRecent/UserRecent";

import configService from "../../config/ConfigService";
import localSettings from "../../config/LocalSettings";
import GlobalSearchItemType from "../../enum/GlobalSearchItemType";
import Path from "../../enum/Path";
import GlobalSearchItem, {
  GlobalSearchActivity,
  GlobalSearchEntity,
} from "../../types/globalSearch/GlobalSearchItem";
import TextSearchResult from "../../types/TextSearchResult";
import { getLegacyAppUrl } from "../../util/appUrl";
import locatedToAddress from "../../util/locatedToAddress";
import { callApi } from "../api/callApi";
import authService from "../auth/AuthService";
import { showEntityView } from "../entityView/actions";
import { handleError } from "../errors/actions";
import { getOrganization } from "../iam";

import { addUserRecent, clickOnGlobalSearchItem, fetchUserRecents, performSearch } from "./actions";

const userRecentToGlobalSearchItem = (item: UserRecent): GlobalSearchItem => {
  if (item.entity?.id) {
    return {
      entity: {
        ...item.entity,
        ...locatedToAddress(item.entity as Company | Person),
      } as GlobalSearchEntity,
      entityType: item.entity.entity ?? undefined,
      title:
        item.entity.entity === EntityType.ACTIVITY
          ? (item.entity as Activity).account?.name ??
            (item.entity as Activity).contact?.name ??
            (item.entity as Activity).deal?.name ??
            item.entity.name
          : item.entity.name,
      type: GlobalSearchItemType.ENTITY,
    };
  }
  return {
    title: item.query ?? "",
    type: GlobalSearchItemType.QUERY,
  };
};

const textSearchResultToGlobalSearchItem = (item: TextSearchResult): GlobalSearchItem => {
  if (item.metaData) {
    return {
      entity: item.metaData,
      entityType: item.entity as EntityType,
      title:
        item.entity === EntityType.ACTIVITY
          ? (item.metaData as GlobalSearchActivity).account?.name ??
            (item.metaData as GlobalSearchActivity).contact?.name ??
            (item.metaData as GlobalSearchActivity).deal?.name ??
            item.metaData.name
          : item.metaData.name,
      type: GlobalSearchItemType.ENTITY,
    };
  }
  // if there is no metaData by some reason
  return {
    title: "",
    type: GlobalSearchItemType.QUERY,
  };
};

function* onFetchUserRecents() {
  try {
    const org: Organization = yield select(getOrganization);
    const response: ListResponse<UserRecent> = yield callApi("fetchUserRecents", org.id, {
      $limit: 20,
    });
    const savedQuery = localSettings.getGlobalSearchQuery();
    yield put(
      fetchUserRecents.success([
        ...(savedQuery
          ? [
              {
                title: savedQuery,
                type: GlobalSearchItemType.LOCAL_QUERY,
              },
            ]
          : []),
        ...response.data.map((item) => userRecentToGlobalSearchItem(item as UserRecent)),
      ])
    );
  } catch (error) {
    yield put(fetchUserRecents.failure(error));
    yield put(handleError({ error }));
  }
}

function* onAddUserRecent({ payload }: ReturnType<typeof addUserRecent>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("addUserRecent", org.id, payload);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onPerformSearch({
  payload: { entityTypes, query },
}: ReturnType<typeof performSearch.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const response: SimpleListResponse<TextSearchResult> = yield callApi(
      "search",
      org.id,
      query,
      entityTypes
    );
    yield put(performSearch.success(response.data.map(textSearchResultToGlobalSearchItem)));
  } catch (error) {
    yield put(performSearch.failure(error));
    yield put(handleError({ error }));
  }
}

function* onClickOnGlobalSearchItem({
  payload: { entityId, entityType },
}: ReturnType<typeof clickOnGlobalSearchItem>) {
  const baseOldAppUrl = configService.getBaseOldAppUrl();
  const token = authService.getToken();
  if (
    [EntityType.ACTIVITY, EntityType.COMPANY, EntityType.DEAL, EntityType.PERSON].includes(
      entityType
    )
  ) {
    yield put(
      showEntityView({
        entityId,
        entityType,
        focusOnPin: true,
      })
    );
  } else if (
    [EntityType.COMPANY_GROUP, EntityType.DEAL_GROUP, EntityType.PEOPLE_GROUP].includes(entityType)
  ) {
    yield put(
      push(
        `${Path.MAP}/groups/${
          entityType === EntityType.COMPANY_GROUP
            ? EntityType.COMPANY
            : entityType === EntityType.PEOPLE_GROUP
            ? EntityType.PERSON
            : EntityType.DEAL
        }/${entityId}`
      )
    );
  } else if (EntityType.COMPANY_ROUTE === entityType) {
    window.location.href = getLegacyAppUrl(
      baseOldAppUrl,
      token,
      `accounts/routing/edit/${entityId}`
    );
  } else if (EntityType.PEOPLE_ROUTE === entityType) {
    window.location.href = getLegacyAppUrl(
      baseOldAppUrl,
      token,
      `contacts/routing/edit/${entityId}`
    );
  } else if (EntityType.TERRITORY === entityType) {
    yield put(push(`${Path.MAP}/territories/${entityId}`));
  }
}

export function* globalSearchSaga() {
  yield takeEvery(fetchUserRecents.request, onFetchUserRecents);
  yield takeEvery(performSearch.request, onPerformSearch);
  yield takeEvery(addUserRecent, onAddUserRecent);
  yield takeEvery(clickOnGlobalSearchItem, onClickOnGlobalSearchItem);
}
