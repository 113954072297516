import pick from "lodash-es/pick";
import { defineMessages, IntlShape } from "react-intl";

import AreaSearchMode from "@app/enum/AreaSearchMode";

type GeoPointSortType =
  | AreaSearchMode.ADDRESS
  | AreaSearchMode.CURRENT_USER_LOCATION
  | AreaSearchMode.ENTITY;

const GeoPointSortType = pick(AreaSearchMode, ["CURRENT_USER_LOCATION", "ENTITY", "ADDRESS"]);

export const messages = defineMessages<GeoPointSortType>({
  [GeoPointSortType.ADDRESS]: {
    id: "recordList.customization.sort.type.geoPoint.address",
    defaultMessage: "Closest to a specific address",
    description: "Your current location label",
  },
  [GeoPointSortType.CURRENT_USER_LOCATION]: {
    id: "recordList.customization.sort.type.geoPoint.currentLocation",
    defaultMessage: "Closest to my current location",
    description: "Your current location label",
  },
  [GeoPointSortType.ENTITY]: {
    id: "recordList.customization.sort.type.geoPoint.entity",
    defaultMessage: "Closest to a specific Company or Person",
    description: "Your current location label",
  },
});

export const getGeopointSortTypeDisplayName = (
  intl: IntlShape,
  geoPointSortType: GeoPointSortType
) => intl.formatMessage(messages[geoPointSortType]);

export default GeoPointSortType;
