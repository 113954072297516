import { createReducer } from "typesafe-actions";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import CheckInsCardConfiguration from "@app/types/dashboard/cards/checkIns/CheckInsCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";
import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyCheckInsDrillDownListViewSettings,
  CheckInsCardActions,
  fetchCheckInsCardDrillDownData,
  hideCheckInsDrillDown,
  showCheckInsDrillDown,
} from "./actions";

const defaultColumns: string[] = [
  ActivityFieldName.PRIMARY_ASSOCIATION,
  ActivityFieldName.ACTIVITY_TYPE,
  ActivityFieldName.ASSIGNEE,
  ActivityFieldName.COMPLETED_AT,
  ActivityFieldName.CHECK_IN_DISTANCE,
  ActivityFieldName.RELIABILITY,
  ActivityFieldName.NOTE,
];

const defaultSortColumn = ActivityFieldName.COMPLETED_AT;

export interface CheckInsCardState {
  configuration?: CheckInsCardConfiguration; // also works as a "visible" trigger, visible if set
  drillDownCustomDateRange?: DateRange;
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const checkInsInitialState: CheckInsCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: getDefaultDrillDownColumns(defaultColumns, activityFieldModel),
    filter: {},
    range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
    sort: [
      {
        field: activityFieldModel.getByName(defaultSortColumn)!,
        order: SortOrder.DESC,
      },
    ],
    viewAs: undefined,
  },
};

const checkInsState = createReducer<CheckInsCardState, CheckInsCardActions>(checkInsInitialState)
  .handleAction(showCheckInsDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
    drillDownCustomDateRange: payload.customDateRange,
  }))
  .handleAction(showCheckInsDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideCheckInsDrillDown, (state) => ({
    ...state,
    configuration: undefined,
    drillDownCustomDateRange: undefined,
  }))
  .handleAction(fetchCheckInsCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchCheckInsCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchCheckInsCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyCheckInsDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      columns: payload.columns ?? state.viewState.columns,
      filter: payload.filter ?? state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default checkInsState;
