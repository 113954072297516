import { AnalyticsService } from "./AnalyticsService";

const navbarAnalytics = new AnalyticsService("Navbar");

const analyticsPathByMenuKey: Record<string, string[]> = {
  activitiesPlan: ["Plan", "Activities"],
  companiesListRecords: ["Records", "Company List"],
  companiesMap: ["Map", "Companies Map"],
  companyGroupsPlan: ["Plan", "Groups", "Company Groups"],
  companyRoutesPlan: ["Plan", "Routes", "Company Routes"],
  dealGroupsPlan: ["Plan", "Groups", "Deal Groups"],
  dealsMap: ["Map", "Deals Map"],
  funnelsDealsRecords: ["Records", "Deals"],
  importsManage: ["Manage", "Imports"],
  integrationsManage: ["Manage", "Integrations"],
  manageUsersManage: ["Manage", "Manage Users"],
  peopleGroupsPlan: ["Plan", "Groups", "People Groups"],
  peopleListRecords: ["Records", "People List"],
  peopleMap: ["Map", "People Map"],
  peopleRoutesPlan: ["Plan", "Routes", "People Routes"],
  reports: ["Reports"],
  territoriesMap: ["Map", "Territories Map"],
};

export const navbarMenuClick = (key: string) => {
  if (analyticsPathByMenuKey[key]) {
    navbarAnalytics.clicked(analyticsPathByMenuKey[key]);
  }
};

const analyticsPathByRightMenuKey: Record<string, string[]> = {
  addActivity: ["Plus", "Add Activity"],
  addCompany: ["Plus", "Add Company"],
  addDeal: ["Plus", "Add Deal"],
  addPerson: ["Plus", "Add Person"],
  import: ["Plus", "Import"],
  integrations: ["Plus", "Integrations"],
};

export const navbarRightMenuClick = (key: string) => {
  if (analyticsPathByRightMenuKey[key]) {
    navbarAnalytics.clicked(analyticsPathByRightMenuKey[key]);
  }
};

const analyticsPathByUserMenuKey: Record<string, string[]> = {
  changePassword: ["Change Password"],
  manageSubscription: ["Manage Subscription"],
  support: ["Support"],
};

export const navbarUserMenuClick = (key: string) => {
  if (analyticsPathByUserMenuKey[key]) {
    navbarAnalytics.clicked(analyticsPathByUserMenuKey[key]);
  }
};

export const extendNavbarAnalyticWithMenuKey = (key: string): AnalyticsService =>
  analyticsPathByRightMenuKey[key]
    ? navbarAnalytics.extend(analyticsPathByRightMenuKey[key])
    : navbarAnalytics;

export default navbarAnalytics;
