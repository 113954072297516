enum FilterOption {
  AVAILABLE_ACTIVITY_TYPES = "availableActivityTypes",
  AVAILABLE_COLORS = "availableColors",
  AVAILABLE_FUNNELS = "availableFunnels",
  AVAILABLE_GROUPS = "availableGroups",
  AVAILABLE_ROUTES = "availableRoutes",
  AVAILABLE_SOURCES = "availableSources",
  AVAILABLE_TEAMS = "availableTeams",
  AVAILABLE_USERS = "availableUsers",
  ENTITY_TYPE = "entityType",
  MAP_CENTER = "mapCenter",
  OPTIONS_LIST = "optionsList",
  USER_POSITION = "userPosition",
}

export default FilterOption;
