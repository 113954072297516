import React, { useMemo } from "react";

import { faCalculator } from "@fortawesome/free-solid-svg-icons/faCalculator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";

import CustomFieldType from "@mapmycustomers/shared/types/customField/CustomField";

import Lexer from "../Lexer";
import MultilineText from "../typography/MultilineText";

interface Props {
  customField: CustomFieldType;
}

const CalculatedFieldInfoIcon: React.FC<Props> = ({ customField }) => {
  const text = useMemo(
    () => (
      <MultilineText wrapLinesWith={Lexer}>
        {((customField.expressionDesc ?? "").trim().length
          ? customField.expressionDesc
          : customField.expression) ?? ""}
      </MultilineText>
    ),
    [customField.expressionDesc, customField.expression]
  );
  return (
    <Tooltip title={text}>
      <FontAwesomeIcon icon={faCalculator} />
    </Tooltip>
  );
};

export default CalculatedFieldInfoIcon;
