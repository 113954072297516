import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { AnyEntityId, EntityType } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";

export const downloadFile = createAction("@files/downloadFile")<{
  entityId?: AnyEntityId;
  entityType?: EntityType;
  fileId: RawFile["id"];
  fileName?: string;
}>();

export const createFile = createAsyncAction(
  "@files/createFile/request",
  "@files/createFile/success",
  "@files/createFile/failure"
)<{ callback?: (file: RawFile) => void; file: Blob | File }, RawFile, unknown>();

export const fetchFile = createAction("@files/fetchFile")<{
  entityId?: AnyEntityId;
  entityType?: EntityType;
  fileId: RawFile["id"];
  onFailure?: () => void;
  onSuccess: (file: RawFile, fileBlob: Blob) => void;
}>();

export type Actions = ActionType<typeof createFile | typeof downloadFile | typeof fetchFile>;
