import React, { useCallback } from "react";

import Region from "@mapmycustomers/shared/types/Region";
import defaultFilterOption from "@mapmycustomers/shared/util/input/defaultFilterOption";
import useRegionsListOptions from "@mapmycustomers/shared/util/regions/useRegionsListOptions";

import { useConfigProvider } from "../../../../ConfigProvider";
import SelectField from "../../../SelectField";
import TextField from "../../../TextField";

interface Props {
  countryCode?: string;
  disabled?: boolean;
  onChange?: (region: Region) => void;
  value?: Region;
}

const RegionField: React.FC<Props> = ({ countryCode, disabled, onChange, value }) => {
  const configProvider = useConfigProvider();
  const regions = useRegionsListOptions(countryCode);
  const hasRegions = regions?.length > 0;

  const handleChange = useCallback(
    (value: string | undefined, item) => {
      onChange?.({
        region: value !== undefined ? item.label : undefined,
        regionCode: value,
      });
    },
    [onChange]
  );

  const handleTextChange = useCallback(
    (value) => {
      onChange?.({
        region: value,
        regionCode: undefined,
      });
    },
    [onChange]
  );

  return hasRegions ? (
    <SelectField<string | undefined>
      allowClear
      dropdownMatchSelectWidth
      filterOption={defaultFilterOption}
      locked={disabled}
      onChange={handleChange}
      options={regions}
      placeholder={configProvider.formatMessage("ui.manualAddress.regionProvince")}
      showSearch
      value={value?.regionCode}
    />
  ) : (
    <TextField
      locked={disabled}
      onChange={handleTextChange}
      placeholder={configProvider.formatMessage("ui.manualAddress.regionProvince")}
      value={value?.region}
    />
  );
};

export default RegionField;
