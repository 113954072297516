import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const frequencyState = (state: RootState) => state.frequency;

export const getLoggedActivities = createSelector(
  frequencyState,
  ({ loggedActivities }) => loggedActivities
);

export const getPreviousActivities = createSelector(
  frequencyState,
  ({ previousActivities }) => previousActivities
);

export const getPreviousActivitiesTotal = createSelector(
  frequencyState,
  ({ previousActivitiesTotal }) => previousActivitiesTotal
);

export const isPreviousActivitiesLoading = createSelector(
  frequencyState,
  ({ previousActivitiesLoading }) => previousActivitiesLoading
);

export const getLastFrequencyActivity = createSelector(
  frequencyState,
  ({ lastFrequencyActivity }) => lastFrequencyActivity
);

export const getPendingActivity = createSelector(
  frequencyState,
  ({ pendingActivity }) => pendingActivity
);

export const isPendingActivityUpdate = createSelector(
  frequencyState,
  ({ pendingActivityUpdate }) => pendingActivityUpdate
);

export const isFrequencyPanelLoading = createSelector(
  frequencyState,
  ({ frequencyPanelLoading }) => frequencyPanelLoading
);

export const isFrequencyModalVisible = createSelector(
  frequencyState,
  ({ frequencyModalVisible }) => frequencyModalVisible
);

export const getFrequencyModalAnalyticsPath = createSelector(
  frequencyState,
  ({ frequencyModalAnalyticsPath }) => frequencyModalAnalyticsPath
);

export const isFrequencyModalLoading = createSelector(
  frequencyState,
  ({ frequencyModalLoading }) => frequencyModalLoading
);

export const getFrequencyModalConfig = createSelector(
  frequencyState,
  ({ frequencyModalConfig }) => frequencyModalConfig
);

export const getFrequencyPanelConfig = createSelector(
  frequencyState,
  ({ frequencyPanelConfig }) => frequencyPanelConfig
);

export const getFrequencyPanelConfigActivityTypeIds = createSelector(
  getFrequencyPanelConfig,
  (config) => config?.activityTypeIds
);

export const getFrequencyPanelConfigEntity = createSelector(
  getFrequencyPanelConfig,
  (config) => config?.entity
);

export const isEntityFrequencyUpdating = createSelector(
  frequencyState,
  ({ entityFrequencyUpdating }) => entityFrequencyUpdating
);
