import React from "react";
import { connect } from "react-redux";

import { EntityTypeSupportingGroups, Group } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { FormItem } from "@mapmycustomers/ui";

import GroupsSelection from "@app/component/GroupsSelection";
import { getGroupsForEntity } from "@app/store/groups";
import { RootState } from "@app/store/rootReducer";

interface OwnProps {
  disabled?: boolean;
  entityType: EntityTypeSupportingGroups;
  field: IField;
  required?: boolean;
}

interface Props extends OwnProps {
  groups: Group[];
}

const GroupsField: React.FC<Props> = ({ disabled, entityType, field, groups, required }) => {
  return (
    <FormItem
      label={field.displayName}
      name={field.name}
      required={required}
      rules={[{ required }]}
      valuePropName="selectedGroupIds"
    >
      <GroupsSelection
        createGroupPosition="top"
        disabled={disabled}
        entityType={entityType}
        groups={groups}
        label={field.displayName}
        required={required}
      />
    </FormItem>
  );
};

const mapStateToProps = (state: RootState, { entityType }: OwnProps) => ({
  groups: getGroupsForEntity(state)(entityType),
});

export default connect(mapStateToProps)(GroupsField);
