import React, { useCallback } from "react";

import cn from "classnames";
import { useSlate } from "slate-react";

import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { TextControl } from "../../enum/Control";
import isTextTypeActive from "../../util/isTextTypeActive";
import toggleTextType from "../../util/toggleTextType";
import ActionTooltipWrapper from "../ActionTooltipWrapper";
import styles from "../Toolbar.module.scss";

interface TextTogglerProps {
  tooltipTranslationString: string;
  value: TextControl;
}

const TextToggler: React.FC<TextTogglerProps> = ({ tooltipTranslationString, value }) => {
  const editor = useSlate();
  const handleToggleBlock = useCallback(() => {
    toggleTextType(editor, value.value);
  }, [editor, value.value]);

  return (
    <ActionTooltipWrapper translationString={tooltipTranslationString}>
      <span
        className={cn(styles.togglerButton, {
          [styles.active]: isTextTypeActive(editor, value.value),
        })}
        onClick={handleToggleBlock}
        onMouseDown={stopEvents}
      >
        {value.label}
      </span>
    </ActionTooltipWrapper>
  );
};

export default TextToggler;
