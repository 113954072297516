import { addMinutes } from "date-fns/esm";

import FrequencyType from "@mapmycustomers/shared/enum/FrequencyType";
import SyncType from "@mapmycustomers/shared/enum/SyncType";

import { parseApiDateWithTz } from "@app/util/parsers";

const getSchedule = (timezone?: string) => ({
  frequency: FrequencyType.DAILY,
  nextRunAt: parseApiDateWithTz(addMinutes(Date.now(), 3), timezone),
  type: SyncType.STANDARD,
});

export default getSchedule;
