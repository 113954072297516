import { call, takeLatest } from "redux-saga/effects";

import localSettings from "@app/config/LocalSettings";

import { hideInSession } from "./actions";

function* onHideInSession() {
  yield call(localSettings.setLocationReminderAllowed, false);
}

export function* userLocationRequestSaga() {
  yield takeLatest(hideInSession, onHideInSession);
}
