import { call, select, takeLatest } from "redux-saga/effects";

import { MapEntity } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";

import { callApi } from "@app/store/api/callApi";
import { getOrganizationId } from "@app/store/iam";
import { getMapViewSettings } from "@app/store/map";

import { fetchAnnotationData } from "./actions";

function* getFetchPayload(entityType: MapEntity["entity"], entityId: MapEntity["id"]) {
  const mapViewState: MapViewState = yield select(getMapViewSettings);
  return {
    $filters: {
      cadence: true,
      entities: {
        [entityType]: {
          $and: [{ id: entityId }],
          includeGroups: true,
          includeTerritories: true,
        },
      },
      includeAccessStatus: true,
      includeCustomFields: true,
      includeUsersWithAccess: true,
      pinLegends: {
        [entityType]: {
          color: mapViewState.colorKey![entityType],
          shape: mapViewState.shapeKey![entityType],
        },
      },
    },
    $limit: 1,
  };
}

export function* onFetchAnnotationPreview({
  payload: { callback, entityId, entityType, failureCallback },
}: ReturnType<typeof fetchAnnotationData>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<MapEntity> = yield callApi(
      "fetchPins",
      orgId,
      yield call(getFetchPayload, entityType, entityId)
    );
    yield call(callback, response.data[0]);
  } catch {
    yield call(failureCallback);
  }
}

export function* entityAnnotationSagas() {
  yield takeLatest(fetchAnnotationData, onFetchAnnotationPreview);
}
