import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Select, { RefSelectProps } from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";

import Territory from "@mapmycustomers/shared/types/entity/Territory";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import EntityBadge from "@app/component/EntityBadge";
import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import { RootState } from "@app/store/rootReducer";
import { areTerritoriesLoading, getTerritories } from "@app/store/territories";
import { fetchAllTerritories } from "@app/store/territories/actions";

import styles from "./TerritoriesFilter.module.scss";

interface Props {
  className?: string;
  focus?: boolean;
  loading?: boolean;
  onChange?: (value: SimpleCondition) => void;
  onFetchTerritories?: () => void;
  territories: Territory[];
  value: SimpleCondition;
}

const TerritoriesFilter: React.FC<Props> = ({
  className,
  focus,
  loading,
  onChange,
  onFetchTerritories,
  territories,
  value,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onFetchTerritories?.();
  }, [onFetchTerritories]);

  const sortedTerritories = useMemo(() => [...territories].sort(nameComparator), [territories]);

  const setRef = useCallback(
    (ref: null | RefSelectProps) => {
      if (focus && ref) {
        ref.focus();
      }
    },
    [focus]
  );

  return (
    <Select<Array<Territory["id"]>>
      className={cn(styles.container, className)}
      filterOption={defaultFilterOption}
      loading={loading}
      mode="multiple"
      onChange={(territoryIds: Territory["id"][]) => onChange?.({ ...value, value: territoryIds })}
      placeholder={intl.formatMessage({
        id: "filters.territory.select.placeholder",
        defaultMessage: "Click or type to select territories",
        description: "Placeholder displayed in a TerritoryFilter's select field",
      })}
      ref={setRef}
      value={Array.isArray(value.value) ? value.value : []}
    >
      {sortedTerritories.map((territory) => (
        <Select.Option key={territory.id} label={territory.name} value={territory.id}>
          <EntityBadge entity={territory} />
        </Select.Option>
      ))}
    </Select>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: areTerritoriesLoading(state),
  territories: getTerritories(state),
});

const mapDispatchToProps = {
  onFetchTerritories: fetchAllTerritories.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoriesFilter);
