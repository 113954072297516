import React, { useMemo } from "react";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import StageSelectBox from "@app/component/input/StageSelectBox";
import { isSimpleCondition } from "@app/util/viewModel/assert";

export const STAGE_FILTER_OPERATORS = [FilterOperator.IN_ANY, FilterOperator.NONE_OF];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  STAGE_FILTER_OPERATORS.includes(operator);

interface StageFilterProps extends IFilterComponentProps {}

const StageFilter: IFilterInstance = {
  doesSupportValue,
  getComponent:
    (): React.FC<StageFilterProps> =>
    ({ className, filterModel, onChange, value }) => {
      const selectedFunnelsIds = useMemo(() => {
        if (filterModel.funnel && isSimpleCondition(filterModel.funnel)) {
          const { operator, value } = filterModel.funnel;
          if (operator === FilterOperator.EQUALS && typeof value === "number") {
            return [value];
          } else if (STAGE_FILTER_OPERATORS.includes(operator) && Array.isArray(value)) {
            return value;
          }
          return [];
        }
      }, [filterModel.funnel]);

      return (
        <StageSelectBox
          className={className}
          onChange={(stagesIds: Stage["id"][]) => onChange?.({ ...value, value: stagesIds })}
          selectedFunnelsIds={selectedFunnelsIds}
          selectedStagesIds={value.value}
        />
      );
    },
};

export default StageFilter;
