import React, { useCallback } from "react";

import Radio, { RadioChangeEvent, RadioProps } from "antd/es/radio";

export interface Props extends Omit<RadioProps, "onChange"> {
  onChange?: (checked: boolean, event: RadioChangeEvent) => void;
}

const RadioButton: React.FC<Props> = ({ onChange, ...props }) => {
  const handleChange = useCallback((e) => onChange?.(e.target.checked, e), [onChange]);

  return <Radio {...props} onChange={handleChange} />;
};

export default RadioButton;
