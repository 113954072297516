import { RawFile } from "@mapmycustomers/shared/types/File";

export default class FileCache {
  private maxFileSize: number;
  private cachedFileIds: RawFile["id"][] = []; // use to track addition time
  private cachedFiles: Map<RawFile["id"], { file: RawFile; fileBlob: Blob }> = new Map();
  private totalSize: number = 0;

  constructor(maxFileSize: number) {
    this.maxFileSize = maxFileSize;
  }

  hasFile(fileId: RawFile["id"]) {
    return this.cachedFiles.has(fileId);
  }

  getFile(fileId: RawFile["id"]) {
    return this.cachedFiles.get(fileId);
  }

  addFile(file: RawFile, fileBlob: Blob) {
    // remove old files when exceeded cache size
    let currentSize = this.totalSize;
    while (this.cachedFileIds.length && currentSize + fileBlob.size > this.maxFileSize) {
      const fileId = this.cachedFileIds.shift()!;
      if (this.hasFile(fileId)) {
        const { fileBlob } = this.getFile(fileId)!;
        currentSize -= fileBlob.size;
        this.cachedFiles.delete(fileId);
      }
    }

    this.totalSize = currentSize + fileBlob.size;
    this.cachedFileIds.push(file.id);
    this.cachedFiles.set(file.id, { file, fileBlob });
  }
}
