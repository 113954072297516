import { ActionType, createAsyncAction } from "typesafe-actions";

export const initializeApp = createAsyncAction(
  "@app/initialize/request",
  "@app/initialize/success",
  "@app/initialize/failure"
)<void, void, void>();

export const fetchSandboxAccessToken = createAsyncAction(
  "@app/fetchSandboxAccessToken/request",
  "@app/fetchSandboxAccessToken/success",
  "@app/fetchSandboxAccessToken/failure"
)<{ callback?: (token: string) => void }, string, void>();

export type Actions = ActionType<typeof fetchSandboxAccessToken | typeof initializeApp>;
