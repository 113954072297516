enum ElementType {
  BULLETED_LIST = "bulleted-list",
  DYNAMIC_VAR = "dynamic-var",
  IMAGE = "image",
  INDENT = "indent",
  LIST_ITEM = "list-item",
  MENTION = "mention",
  NUMBERED_LIST = "numbered-list",
  PARAGRAPH = "paragraph",
}

export default ElementType;
