import moduleLayout from "./_layout.module.scss";

interface Layout {
  breakpointDesktop: number;
  breakpointMobile: number;
  breakpointTablet: number;
  breakpointTabletPortrait: number;
  breakpointWideDesktop: number;
  spacerL: number;
  spacerM: number;
  spacerS: number;
  spacerXL: number;
  spacerXS: number;
}

const layout: Layout = {
  breakpointDesktop: parseInt(moduleLayout.breakpointDesktop, 10),
  breakpointMobile: parseInt(moduleLayout.breakpointMobile, 10),
  breakpointTablet: parseInt(moduleLayout.breakpointTablet, 10),
  breakpointTabletPortrait: parseInt(moduleLayout.breakpointTabletPortrait, 10),
  breakpointWideDesktop: parseInt(moduleLayout.breakpointWideDesktop, 10),
  spacerL: parseInt(moduleLayout.spacerL, 10),
  spacerM: parseInt(moduleLayout.spacerM, 10),
  spacerS: parseInt(moduleLayout.spacerS, 10),
  spacerXL: parseInt(moduleLayout.spacerXL, 10),
  spacerXS: parseInt(moduleLayout.spacerXS, 10),
};

export default layout;
