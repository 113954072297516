import { useCallback, useState } from "react";

import { FormInstance } from "antd/es/form";
import get from "lodash-es/get";
import { FieldData } from "rc-field-form/lib/interface";
import invariant from "tiny-invariant";

import SignupField from "@app/scene/auth/component/Register/enum/SignupField";
import loggingService from "@app/util/logging";

const contactsStepFields = [SignupField.FULL_NAME, SignupField.EMAIL, SignupField.PHONE];
const passwordStepFields = [
  SignupField.PASSWORD,
  SignupField.PASSWORD_CONFIRMATION,
  SignupField.AGREE_WITH_TNC,
  SignupField.PROMO_EMAILS,
];

const areStepFieldsValid = (
  form: FormInstance,
  fieldName: SignupField,
  stepFields: SignupField[]
) =>
  !form
    .getFieldsError()
    .filter((field) => stepFields.includes(field.name[0] as SignupField))
    .some((field) => field.errors.length > 0 || form.getFieldValue(field.name[0]) === undefined);

const useStepValidator = (form: FormInstance) => {
  const [isContactInfoStepValid, setContactsStepValidity] = useState<boolean>(false);
  const [isPasswordStepValid, setPasswordStepValidity] = useState<boolean>(false);

  const handleFormFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      const fieldName = get(changedFields, [0, "name", 0]) as SignupField;
      if (!fieldName) {
        loggingService.warning(`Invalid field name shape: ${JSON.stringify(changedFields)}`);
        return;
      }

      if (contactsStepFields.includes(fieldName)) {
        setContactsStepValidity(areStepFieldsValid(form, fieldName, contactsStepFields));
      } else if (passwordStepFields.includes(fieldName)) {
        setPasswordStepValidity(areStepFieldsValid(form, fieldName, passwordStepFields));
      } else {
        invariant(false, `Unknown field name, probably missing validation: ${fieldName}`);
      }
    },
    [form]
  );

  return [
    handleFormFieldsChange,
    { isContactsStepValid: isContactInfoStepValid, isPasswordStepValid },
  ] as const;
};

export default useStepValidator;
