import React, { useCallback } from "react";
import { connect } from "react-redux";

import { EntityTypeSupportingRoutes, Route } from "@mapmycustomers/shared/types/entity";
import { RoutePayload } from "@mapmycustomers/shared/types/entity/Route";

import { RootState } from "@app/store/rootReducer";

import CreateRoutePureModal from "./CreateRoutePureModal";
import { isCreating } from "./store";
import { createRoute, hideCreateRouteModal } from "./store/actions";

interface Props {
  createOnly?: boolean;
  createRoute: typeof createRoute.request;
  creating: boolean;
  entityType: EntityTypeSupportingRoutes;
  hideCreateRouteModal: typeof hideCreateRouteModal;
  onCreated?: (route: Route) => void;
  routes: Route[];
}

const CreateRouteModal: React.FC<Props> = ({
  createOnly,
  createRoute,
  creating,
  entityType,
  hideCreateRouteModal,
  onCreated,
  routes,
}) => {
  const handleHideClick = useCallback(() => hideCreateRouteModal(), [hideCreateRouteModal]);

  const handleCreate = useCallback(
    (payload: RoutePayload) =>
      createRoute({
        callback: (route) => onCreated?.(route),
        route: payload,
      }),
    [createRoute, onCreated]
  );

  return (
    <CreateRoutePureModal
      createOnly={createOnly}
      creating={creating}
      entityType={entityType}
      onCreate={handleCreate}
      onHide={handleHideClick}
      routes={routes}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  creating: isCreating(state),
});

const mapDispatchToProps = {
  createRoute: createRoute.request,
  hideCreateRouteModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRouteModal);
