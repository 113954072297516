import { defineMessages, IntlShape } from "react-intl";

enum DataViewMode {
  SUM = "sum",
  AVG = "avg",
}

const messages = defineMessages({
  [DataViewMode.AVG]: {
    id: "dataView.avg",
    defaultMessage: "AVERAGE",
    description: "Data view - data mode - avg",
  },
  [DataViewMode.SUM]: {
    id: "dataView.sum",
    defaultMessage: "SUM",
    description: "Data view - data mode - sum",
  },
});

export const getDataModeDisplayName = (intl: IntlShape, mode: DataViewMode) =>
  intl.formatMessage(messages[mode]);

export default DataViewMode;
