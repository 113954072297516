import React, { useMemo } from "react";

import Select from "antd/es/select";
import { useIntl } from "react-intl";

import FieldCategory from "@mapmycustomers/shared/enum/fieldModel/FieldCategory";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { EntityTypeSupportingHistory } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { CategorizedFields } from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import { getFieldCategoryDisplayName } from "@app/util/ui";

import getFieldModelByEntityType from "../../fieldModel/getByEntityType";
import { isCustomField } from "../../fieldModel/impl/assert";
import { getEmptyCategorizedFields } from "../../fieldModel/impl/FieldModel";

import styles from "./HistoryField.module.scss";

export const HISTORY_FIELD_FILTER_OPERATORS = [FilterOperator.IN_ANY];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  operator === FilterOperator.IN_ANY;

interface HistoryFieldFilterProps extends IFilterComponentProps {}

const HistoryFieldFilter: IFilterInstance = {
  doesSupportOption: (option: FilterOption) => option === FilterOption.ENTITY_TYPE,
  doesSupportValue,
  getComponent:
    (): React.FC<HistoryFieldFilterProps> =>
    ({ onChange, options, value }) => {
      const intl = useIntl();

      const categorizedFields: CategorizedFields = useMemo(() => {
        if (!options?.entityType) {
          return getEmptyCategorizedFields();
        }

        const result = getFieldModelByEntityType(
          options.entityType as EntityTypeSupportingHistory
        ).categorizedFields;

        Object.keys(result).forEach((category) => {
          result[category as FieldCategory] = result[category as FieldCategory] = result[
            category as FieldCategory
          ].filter(
            (field) => !isCustomField(field) && field.hasFeature(FieldFeature.HISTORY_FIELD)
          );
        });
        return result;
      }, [options?.entityType]);

      const customFieldLabel = useMemo(
        () =>
          intl.formatMessage({
            id: "filters.historyField.customFields",
            defaultMessage: "Custom Fields",
            description: "Custom Fields label on in history field filter",
          }),
        [intl]
      );

      return (
        <Select<IField["name"][]>
          className={styles.container}
          filterOption={defaultFilterOption}
          maxTagCount="responsive"
          mode="multiple"
          onChange={(fieldNames: IField["name"][]) => onChange?.({ ...value, value: fieldNames })}
          placeholder={intl.formatMessage({
            id: "filters.historyField.placeholder",
            defaultMessage: "Click or type to select",
            description: "Custom Fields placeholder on history field filter",
          })}
          value={Array.isArray(value.value) ? value.value : undefined}
        >
          {(Object.keys(categorizedFields) as FieldCategory[]).map((category) =>
            categorizedFields[category].length ? (
              <Select.OptGroup key={category} label={getFieldCategoryDisplayName(intl, category)}>
                {categorizedFields[category].map((field) => (
                  <Select.Option
                    key={field.name}
                    label={field.displayName}
                    value={field.platformFilterName}
                  >
                    {field.hasFeature(FieldFeature.ADDRESS)
                      ? intl.formatMessage({
                          id: "filters.historyField.addressField",
                          defaultMessage: "Address",
                          description: "Single address field on history field filter",
                        })
                      : field.displayName}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ) : null
          )}
          <Select.OptGroup key="customField" label={customFieldLabel}>
            <Select.Option key="customFieldOption" label={customFieldLabel} value="value">
              {customFieldLabel}
            </Select.Option>
          </Select.OptGroup>
        </Select>
      );
    },
};

export default HistoryFieldFilter;
