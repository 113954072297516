import { defineMessages } from "react-intl";

const messages = defineMessages({
  datePlaceholder: {
    id: "formFields.date.placeholder",
    defaultMessage: "Select date",
    description: "Placeholder for date field",
  },
  emailPlaceholder: {
    id: "formFields.email.placeholder",
    defaultMessage: "Email",
    description: "Placeholder for email field",
  },
  fileRequired: {
    id: "formFields.files.validator.required",
    defaultMessage: "File is required. Please upload at least one file.",
    description: "An error message to appear when files are not provided",
  },
  maskedTooltip: {
    id: "formFields.name.masked.tooltip",
    defaultMessage: "Protected Information",
    description: "Tooltip to show when name field is masked",
  },
  namePlaceholder: {
    id: "formFields.name.placeholder",
    defaultMessage: "{entityName} name",
    description: "Placeholder for name field",
  },
  timePlaceholder: {
    id: "formFields.time.placeholder",
    defaultMessage: "Select time",
    description: "Placeholder for time field",
  },
  websitePlaceholder: {
    id: "formFields.website.placeholder",
    defaultMessage: "Website",
    description: "Placeholder for website field",
  },
});

export default messages;
