import React, { useMemo } from "react";

import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons/faRedoAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { EntityType } from "@mapmycustomers/shared/enum";
import AnyColor, { HexColor } from "@mapmycustomers/shared/types/AnyColor";

import ActivityTypeIcon from "@app/component/activity/ActivityTypeIcon";
import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";
import GlobalSearchItemType from "@app/enum/GlobalSearchItemType";
import GlobalSearchItem, { GlobalSearchActivity } from "@app/types/globalSearch/GlobalSearchItem";
import anyColorToHex from "@app/util/colors/anyColorToHex";
import getContrastColor from "@app/util/colors/getContrastColor";

import { isSearchGroup, isSearchTerritory } from "../../util/assert";

import styles from "./Item.module.scss";

interface Props {
  item: GlobalSearchItem;
}

const Icon: React.FC<Props> = ({ item }) => {
  const entityType: EntityType | undefined = item.entityType;

  const backgroundColor: HexColor | undefined = useMemo(() => {
    let result = undefined;
    if (item.entity) {
      let color;
      if (isSearchGroup(item.entity)) {
        color = item.entity.color ?? "grey";
      } else if (isSearchTerritory(item.entity)) {
        color = item.entity.color ?? "grey";
      }
      if (color) {
        result = anyColorToHex(color as AnyColor);
      }
    }
    return result;
  }, [item]);

  if (entityType === EntityType.ACTIVITY) {
    return (
      <ActivityTypeIcon activityType={(item.entity as GlobalSearchActivity).crmActivityType} />
    );
  }
  if (entityType) {
    return (
      <EntityTypeShapedIcon
        containerClassName={cn(styles.icon, {
          [styles.blackIcon]: backgroundColor && getContrastColor(backgroundColor) === "black",
        })}
        entityType={entityType}
        style={backgroundColor ? { backgroundColor } : undefined}
      />
    );
  }

  return (
    <div className={cn(styles.icon, styles.queryIcon)}>
      <FontAwesomeIcon icon={item.type === GlobalSearchItemType.QUERY ? faClock : faRedoAlt} />
    </div>
  );
};

export default Icon;
