import React from "react";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";

import Chip from "../../Chip";

import styles from "./ActivityScheduled.module.scss";

interface Props {}

const ActivityScheduled: React.FC<Props> = () => {
  const intl = useIntl();
  return (
    <Chip
      icon={<FontAwesomeIcon className={styles.activityScheduledIcon} icon={faCheckCircle} />}
      iconPosition="end"
    >
      {intl.formatMessage({
        id: "frequency.frequencyModal.activities.next.suggestion.activityScheduled",
        defaultMessage: "Activity Scheduled",
        description: "frequency component - frequency modal - next activities - Activity Scheduled",
      })}
    </Chip>
  );
};

export default ActivityScheduled;
