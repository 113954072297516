import React from "react";

import { faAngleDown, faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import AntdCollapse, { CollapseProps } from "antd/es/collapse";
import cn from "classnames";

const block = bem("mmc-collapse");

export type MmcCollapseProps = CollapseProps;

const Collapse: React.FC<CollapseProps> = ({
  children,
  className,
  expandIconPosition = "start",
  ...restProps
}) => {
  return (
    <AntdCollapse
      className={cn(block(), className)}
      expandIcon={({ isActive }) => (
        <FontAwesomeIcon className={block("icon")} icon={isActive ? faAngleUp : faAngleDown} />
      )}
      expandIconPosition={expandIconPosition}
      {...restProps}
    >
      {children}
    </AntdCollapse>
  );
};

export const CollapsePanel = AntdCollapse.Panel;

export default Collapse;
