import type { NodeEntry, Range } from "slate";

import findThingsInText from "./findThingsInText";

const decorator = ([node, path]: NodeEntry): Range[] => {
  if (!("text" in node)) {
    return [];
  }

  return findThingsInText(node.text).map((link) => {
    return {
      anchor: {
        offset: link.index ?? 0,
        path,
      },
      decoration: link.type,
      focus: {
        offset: (link.index ?? 0) + link.length,
        path,
      },
    };
  });
};

export default decorator;
