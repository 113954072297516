import { createReducer } from "typesafe-actions";

import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import NewRecordsCardConfiguration from "@app/types/dashboard/cards/newRecords/NewRecordsCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";

import {
  applyNewRecordsDrillDownListViewSettings,
  fetchNewRecordsCardDrillDownData,
  hideNewRecordsDrillDown,
  NewRecordsCardActions,
  showNewRecordsDrillDown,
} from "./actions";

export interface NewRecordsCardState {
  configuration?: NewRecordsCardConfiguration; // also works as a "visible" trigger, visible if set
  drillDownCustomDateRange?: DateRange;
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const newRecordsInitialState: NewRecordsCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: [],
    filter: {},
    range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
    sort: [],
    viewAs: undefined,
  },
};

const newRecordsState = createReducer<NewRecordsCardState, NewRecordsCardActions>(
  newRecordsInitialState
)
  .handleAction(showNewRecordsDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
    drillDownCustomDateRange: payload.customDateRange,
  }))
  .handleAction(showNewRecordsDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideNewRecordsDrillDown, (state) => ({
    ...state,
    configuration: undefined,
    drillDownCustomDateRange: undefined,
  }))
  .handleAction(fetchNewRecordsCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchNewRecordsCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchNewRecordsCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyNewRecordsDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      columns: payload.columns ?? state.viewState.columns,
      filter: payload.filter ?? state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default newRecordsState;
