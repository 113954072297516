import { defineMessages } from "react-intl";

const messages = defineMessages({
  deletePopConfirmCancelText: {
    id: "files.fileUploader.deletePopConfirm.cancelText",
    defaultMessage: "Cancel",
    description: "Delete popconfirm cancel text",
  },
  deletePopConfirmOkText: {
    id: "files.fileUploader.deletePopConfirm.okText",
    defaultMessage: "Yes",
    description: "Delete popconfirm ok text",
  },
  deletePopConfirmTitle: {
    id: "files.fileUploader.deletePopConfirm.title",
    defaultMessage: "Delete file?",
    description: "Delete popconfirm title",
  },
  dropZoneTitle: {
    id: "files.fileUploader.dropZone.title",
    defaultMessage: "Drag and drop a file or <a>upload</a>",
    description: "Title for file uploader on create modals",
  },
  emptyFileName: {
    id: "files.fileUploader.emptyFileName",
    defaultMessage: "File",
    description: "Name to display when file item has no given name",
  },
  erroredFilesTitle: {
    id: "files.fileUploader.erroredFiles.title",
    defaultMessage: "There was a problem uploading one or more files.",
    description: "Error file title for file uploader",
  },
  exceededSizeLimitError: {
    id: "files.fileUploader.error.exceededSizeLimit",
    defaultMessage:
      "One or more file is too large. Please select files that are {fileLimit, number, ::K}MB or smaller.",
    description: "Error for file uploader when uploaded file exceeds the limit",
  },
  label: {
    id: "files.fileUploader.label",
    defaultMessage: "Files",
    description: "Files uploader label for create modals",
  },
  labelInfoTooltip: {
    id: "files.fileUploader.label.infoTooltip",
    defaultMessage:
      "We support most images, pdfs, documents and videos up to {fileLimit, number, ::K}MB",
    description: "Files uploader label tooltip for create modals",
  },
  minimumOneFileRequiredTooltip: {
    id: "files.fileUploader.tooltip.minimumOneFileRequiredTooltip",
    defaultMessage: "At least one file is required",
    description: "Last required file remove disabled tooltip",
  },
});

export default messages;
