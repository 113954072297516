import {
  CategorizedMapEntries,
  Cluster,
  EntityPin,
  MapEntry,
  MultiPin,
} from "@mapmycustomers/shared/types/map";
import { isCluster, isEntityPin, isMultiPin } from "@mapmycustomers/shared/util/assert";

import loggingService from "@app/util/logging";

const categorizeMapEntries = (entries: MapEntry[], skip?: boolean): CategorizedMapEntries => {
  const clusters: Cluster[] = [];
  const multiPins: MultiPin[] = [];
  const entities: EntityPin[] = [];
  const unknown: MapEntry[] = [];
  if (!skip) {
    entries.forEach((entry) => {
      if (isCluster(entry)) {
        clusters.push(entry);
      } else if (isMultiPin(entry)) {
        multiPins.push(entry);
      } else if (isEntityPin(entry)) {
        entities.push(entry);
      } else {
        unknown.push(entry);
      }
    });
  }
  if (unknown.length) {
    loggingService.warning("Unknown map entries found: ", unknown);
  }
  return { clusters, entities, multiPins };
};

export default categorizeMapEntries;
