import React from "react";

import AnyColor from "@mapmycustomers/shared/types/AnyColor";

import { RegistryProps } from "@app/component/FieldGrid/utils/ComponentRegistry";
import anyColorToHex from "@app/util/colors/anyColorToHex";

import styles from "./ColorField.module.scss";

const ColorField: React.FC<RegistryProps> = ({ entity, field }) => {
  const value = field.getValueFor(entity) as AnyColor;
  const hexValue = anyColorToHex(value);
  const isHex = value?.startsWith("#");

  return (
    <div className={styles.container}>
      <div className={styles.dot} style={{ backgroundColor: hexValue }}></div>
      {!isHex && <div className={styles.name}>{value}</div>}
    </div>
  );
};

export default ColorField;
