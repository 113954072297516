import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import type LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import { AnyEntity } from "@mapmycustomers/shared/types/entity/types";
import User from "@mapmycustomers/shared/types/User";

import StackRankDrillDownViewState from "@app/scene/dashboard/store/stackRank/DrillDownViewState";
import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import PerformanceData from "@app/scene/dashboard/types/PerformanceData";

import StackRankViewState from "./StackRankViewState";

export const fetchMetrics = createAsyncAction(
  "@dashboard/fetchMetrics/request",
  "@dashboard/fetchMetrics/success",
  "@dashboard/fetchMetrics/failure"
)<Partial<StackRankViewState>, PerformanceData, void>();

// basically, the same as fetchMetrics, but updates the viewState w/o fetching the data
export const updateStackRankViewState = createAction("@dashboard/updateStackRankViewState")<
  Partial<StackRankViewState>
>();

export const showStackRankDrillDown = createAction("@dashboard/showStackRankDrillDown")<void>();
export const hideStackRankDrillDown = createAction("@dashboard/hideStackRankDrillDown")<void>();

export const fetchDrillDownData = createAsyncAction(
  "@dashboard/fetchDrillDownData/request",
  "@dashboard/fetchDrillDownData/success",
  "@dashboard/fetchDrillDownData/failure"
)<
  Partial<{
    drillDownViewState: Partial<StackRankDrillDownViewState>;
    viewState: Partial<StackRankViewState>;
  }>,
  LeaderboardItem[],
  void
>();

export const updateDrillDownViewState = createAction("@dashboard/updateDrillDownViewState")<
  Partial<StackRankDrillDownViewState>
>();

export const exportDrillDownData = createAction("@dashboard/exportDrillDownData")<void>();

export const showUserDrillDown = createAsyncAction(
  "@dashboard/showUserDrillDown/request",
  "@dashboard/showUserDrillDown/success",
  "@dashboard/showUserDrillDown/failure"
)<undefined | User["id"][], { viewState: DrillDownViewState }, void>();
export const hideUserDrillDown = createAction("@dashboard/hideUserDrillDown")<void>();

export const fetchUserDrillDownData = createAsyncAction(
  "@dashboard/fetchUserDrillDownData/request",
  "@dashboard/fetchUserDrillDownData/success",
  "@dashboard/fetchUserDrillDownData/failure"
)<
  DrillDownListFetcherPayload<AnyEntity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyUserDrillDownListViewSettings = createAction(
  "@dashboard/applyUserDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export type StackRankActions = ActionType<
  | typeof applyUserDrillDownListViewSettings
  | typeof exportDrillDownData
  | typeof fetchDrillDownData
  | typeof fetchMetrics
  | typeof fetchUserDrillDownData
  | typeof hideStackRankDrillDown
  | typeof hideUserDrillDown
  | typeof showStackRankDrillDown
  | typeof showUserDrillDown
  | typeof updateDrillDownViewState
  | typeof updateStackRankViewState
>;
