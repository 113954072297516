import { defineMessages, IntlShape } from "react-intl";

enum DailyChangeChartType {
  COMPLETED_ACTIVITIES = "completedActivities",
  COMPANIES_ADDED = "companiesAdded",
  PEOPLE_ADDED = "peopleAdded",
  DEALS_ADDED = "dealsAdded",
  REVENUE_CLOSED_WON = "revenueClosedWon",
}

const messages = defineMessages({
  [DailyChangeChartType.COMPANIES_ADDED]: {
    id: "dailyChangeChartType.companiesAdded",
    defaultMessage: "Companies Added",
    description: "Daily change type: Companies Added",
  },
  [DailyChangeChartType.COMPLETED_ACTIVITIES]: {
    id: "dailyChangeChartType.completedActivities",
    defaultMessage: "Activities Done",
    description: "Daily change type: Activities Done",
  },
  [DailyChangeChartType.DEALS_ADDED]: {
    id: "dailyChangeChartType.dealsAdded",
    defaultMessage: "Deals Added",
    description: "Daily change type: Deals Added",
  },
  [DailyChangeChartType.PEOPLE_ADDED]: {
    id: "dailyChangeChartType.peopleAdded",
    defaultMessage: "People Added",
    description: "Daily change type: People Added",
  },
  [DailyChangeChartType.REVENUE_CLOSED_WON]: {
    id: "dailyChangeChartType.revenueClosedWon",
    defaultMessage: "Revenue Closed Won",
    description: "Daily change type: Revenue Closed Won",
  },
});

export const getDailyChangeChartTypeDisplayName = (
  intl: IntlShape,
  chartType: DailyChangeChartType
) => intl.formatMessage(messages[chartType]);

export default DailyChangeChartType;
