import { useSelector } from "react-redux";

import Version from "@mapmycustomers/shared/enum/Version";

import { getVersion } from "@app/store/iam";

/**
 * Use useFeatures instead
 *
 * @deprecated
 */
const useVersion = () => {
  const version = useSelector(getVersion);
  return {
    isVersion1: version === Version.V1,
    // Use isVersion2 when you don't care if it's a v2 or v2beta, and use isVersion2Beta when you do need
    // to distinguish 2nd versions.
    isVersion2: [Version.V2, Version.V2_BETA].includes(version),
    isVersion2Beta: version === Version.V2_BETA,
    version,
  };
};

export default useVersion;
