declare const mimeTypeTag: unique symbol;
export type MimesType = string & { readonly [mimeTypeTag]: true };

export const createMime = (value: string): MimesType => value as MimesType;

export const IMAGE_PNG: MimesType[] = [createMime("image/png")];
export const IMAGE_JPG: MimesType[] = [createMime("image/jpg"), createMime("image/jpeg")];
export const IMAGE_GIF: MimesType[] = [createMime("image/gif")];
export const IMAGE_ANY: MimesType[] = [...IMAGE_PNG, ...IMAGE_JPG, ...IMAGE_GIF];

export const AUDIO_MPEG: MimesType[] = [createMime("audio/mpeg")];
export const AUDIO_ANY: MimesType[] = [...AUDIO_MPEG];

export const VIDEO_MP4: MimesType[] = [createMime("video/mp4")];
export const VIDEO_ANY: MimesType[] = [...VIDEO_MP4];

export const DOCUMENT_PDF: MimesType[] = [createMime("application/pdf")];
export const DOCUMENT_ANY: MimesType[] = [...DOCUMENT_PDF];
