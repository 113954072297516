import React, { useCallback, useMemo } from "react";

import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import Groupable from "@mapmycustomers/shared/types/base/Groupable";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import Chip from "@app/component/Chip";
import { RegistryProps } from "@app/component/FieldGrid/utils/ComponentRegistry";
import FrequencyInterval from "@app/component/frequency/FrequencyInterval";
import FrequencyEntity from "@app/component/frequency/type/FrequencyEntity";
import hasFrequency from "@app/component/frequency/util/hasFrequency";
import ListWithOverflowItem from "@app/component/ListWithOverflowItem";

import styles from "./FrequencyIntervalField.module.scss";
import GroupBadge from "./GroupBadge";

const FrequencyIntervalField: React.FC<RegistryProps> = ({ entity }) => {
  const intl = useIntl();

  const entityItem = useMemo(
    () =>
      hasFrequency(entity as FrequencyEntity) ? (
        <span key="record-frequency">
          <Tooltip
            title={intl.formatMessage(
              {
                id: "fieldGrid.field.frequencyInterval.frequencyForEntity",
                defaultMessage: "<b>Set just to this record</b>",
                description: "Frequency interval field - Set just to this record",
              },
              { b: (text) => <b>{text}</b> }
            )}
          >
            <span>
              <FrequencyInterval
                className={styles.frequencyInterval}
                frequencyInterval={(entity as FrequencyEntity).cadenceInterval}
                isGroup={false}
              />
            </span>
          </Tooltip>
        </span>
      ) : undefined,
    [entity, intl]
  );

  const filteredGroups = useMemo(
    () => (entity as Groupable).groups?.filter(hasFrequency).sort(nameComparator) ?? [],
    [entity]
  );

  const groupItems = useMemo(
    () =>
      filteredGroups.map((group) => (
        <span key={group.id}>
          <Tooltip
            title={intl.formatMessage(
              {
                id: "fieldGrid.field.frequencyInterval.frequencyForGroup",
                defaultMessage: "<b>Set by Group</b>{br}{groupIcon}",
                description: "Frequency interval field - Set just to this record",
              },
              {
                b: (text) => <b>{text}</b>,
                br: <br />,
                groupIcon: <GroupBadge group={group} />,
              }
            )}
          >
            <span>
              <FrequencyInterval
                className={styles.frequencyInterval}
                frequencyInterval={group.cadenceInterval}
                groupColor={group.color}
                isGroup
              />
            </span>
          </Tooltip>
        </span>
      )),
    [filteredGroups, intl]
  );

  const getDaysText = useCallback(
    (count: number) => (
      <span className={styles.counter}>
        {intl.formatMessage(
          {
            id: "fieldGrid.field.frequencyInterval.entityCounter",
            defaultMessage: "{count} {count, plural, one {day} other {days}}",
            description: "counter for frequency interval",
          },
          { count }
        )}
      </span>
    ),
    [intl]
  );

  const items = useMemo(
    () => [...(entityItem ? [entityItem] : []), ...groupItems].map((item) => item),
    [entityItem, groupItems]
  );

  const activityTypeOverflowRender = useCallback(
    (count: number) => {
      const lines = [];
      if (count > filteredGroups.length && entityItem) {
        lines.push(<div> {getDaysText((entity as FrequencyEntity).cadenceInterval ?? 0)}</div>);
      }
      filteredGroups.slice(filteredGroups.length - count).forEach((group) => {
        lines.push(<div> {getDaysText(group.cadenceInterval ?? 0)}</div>);
        lines.push(<GroupBadge group={group} />);
      });

      return (
        <span>
          <Tooltip title={<div>{lines.map((line) => line)}</div>}>
            <Chip type="light">
              {intl.formatMessage(
                {
                  id: "fieldGrid.field.frequencyInterval.overflowRender",
                  defaultMessage: "or + {count} more",
                  description: "overflow Render for frequency interval",
                },
                { count }
              )}
            </Chip>
          </Tooltip>
        </span>
      );
    },
    [intl, filteredGroups, entityItem, entity, getDaysText]
  );

  return (
    <ListWithOverflowItem className={styles.container} overflowRender={activityTypeOverflowRender}>
      {items}
    </ListWithOverflowItem>
  );
};

export default FrequencyIntervalField;
