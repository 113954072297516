import { useEffect } from "react";

import { FormInstance } from "antd/es/form";
import { isEqual } from "lodash-es";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { Group } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { ChildLayout, LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

import { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import { isCustomField } from "@app/util/fieldModel/impl/assert";
import FieldModel from "@app/util/fieldModel/impl/FieldModel";
import { parseApiDate } from "@app/util/parsers";

const useDefaultValues = (
  actualSchema: LayoutSchemaField[],
  childLayout: ChildLayout | undefined,
  isCreateForm: boolean | undefined,
  fieldModel: FieldModel,
  form: FormInstance<any>,
  funnelStages: Record<Funnel["id"], Stage[]>
) => {
  useEffect(() => {
    if (!isCreateForm) {
      return;
    }

    if (childLayout && !form.getFieldValue("funnelId")) {
      form.setFieldValue("funnelId", childLayout.funnel?.id);
    }
    if (childLayout && !form.getFieldValue("stageId")) {
      form.setFieldValue("stageId", childLayout.stage?.id);
    }

    if (childLayout && !form.getFieldValue("activityTypeId")) {
      form.setFieldValue("activityTypeId", childLayout.crmActivityType?.id);
    }

    actualSchema.forEach((schemaField) => {
      const field = fieldModel.getByPlatformName(schemaField.field);
      if (!field?.isDefaultValueAllowed || !schemaField.defaultValue?.length) {
        return;
      }

      if (field.hasFeature(FieldFeature.GROUP_FIELD)) {
        if (!form.getFieldValue(field.name)) {
          form.setFieldValue(field.name, schemaField.defaultValue as Group["id"][]);
        }
      } else if (field.hasFeature(FieldFeature.FUNNEL_FIELD) && !childLayout) {
        if (!form.getFieldValue("funnelId")) {
          form.setFieldValue("funnelId", schemaField.defaultValue[0] as Funnel["id"]);
        }
      } else if (field.hasFeature(FieldFeature.ACTIVITY_TYPE_FIELD) && !childLayout) {
        if (!form.getFieldValue("activityTypeId")) {
          form.setFieldValue("activityTypeId", schemaField.defaultValue[0] as ActivityType["id"]);
        }
      } else if (schemaField.field === DealFieldName.CLOSING_DATE) {
        if (!form.getFieldValue(DealFieldName.CLOSING_DATE)) {
          form.setFieldValue(
            DealFieldName.CLOSING_DATE,
            parseApiDate(schemaField.defaultValue[0] as string)
          );
        }
      } else if (field.hasFeature(FieldFeature.STAGE_FIELD) && !childLayout) {
        const defaultStageId = schemaField.defaultValue[0] as Stage["id"];
        const funnelIdString = Object.keys(funnelStages).find((funnelId) =>
          funnelStages[parseInt(funnelId) as Funnel["id"]].some(({ id }) => id === defaultStageId)
        );
        if (funnelIdString && !form.getFieldValue("stageId") && !form.getFieldValue("funnelId")) {
          form.setFieldValue("funnelId", parseInt(funnelIdString));
          form.setFieldValue("stageId", defaultStageId);
        }
      } else if (isCustomField(field)) {
        const definition = field.customFieldData;

        if (!form.getFieldValue(["customFields", String(definition.id)])?.value) {
          form.setFieldValue(["customFields", String(definition.id)], {
            customField: { id: definition.id },
            value: schemaField.defaultValue,
          });
        }
      } else if (!form.getFieldValue(schemaField.field)) {
        form.setFieldValue(schemaField.field, schemaField.defaultValue[0]);
      }
    });

    return () => {
      if (!isCreateForm) {
        return;
      }
      actualSchema.forEach((schemaField) => {
        const field = fieldModel.getByPlatformName(schemaField.field);
        if (!field?.isDefaultValueAllowed || !schemaField.defaultValue?.length) {
          return;
        }
        if (isCustomField(field)) {
          const definition = field.customFieldData;
          if (
            isEqual(
              form.getFieldValue(["customFields", String(definition.id)])?.value,
              schemaField.defaultValue
            )
          ) {
            form.setFieldValue(["customFields", String(definition.id)], {
              customField: { id: definition.id },
              value: undefined,
            });
          }
        } else if (field.hasFeature(FieldFeature.GROUP_FIELD)) {
          if (isEqual(form.getFieldValue("groups"), schemaField.defaultValue)) {
            form.setFieldValue("groups", undefined);
          }
        } else if (field.hasFeature(FieldFeature.ACTIVITY_TYPE_FIELD)) {
          if (form.getFieldValue("activityTypeId") === schemaField.defaultValue[0]) {
            form.setFieldValue("activityTypeId", undefined);
          }
        } else if (field.hasFeature(FieldFeature.FUNNEL_FIELD)) {
          if (
            form.getFieldValue("funnelId") === schemaField.defaultValue[0] &&
            !form.isFieldTouched("stageId")
          ) {
            form.setFieldValue("funnelId", undefined);
          }
        } else if (field.hasFeature(FieldFeature.STAGE_FIELD)) {
          if (form.getFieldValue("stageId") === schemaField.defaultValue[0]) {
            form.setFieldValue("stageId", undefined);
            form.setFieldValue("funnelId", undefined);
          }
        } else if (schemaField.field === DealFieldName.CLOSING_DATE) {
          if (
            isEqual(
              form.getFieldValue(DealFieldName.CLOSING_DATE),
              parseApiDate(schemaField.defaultValue[0] as string)
            )
          ) {
            form.setFieldValue(DealFieldName.CLOSING_DATE, undefined);
          }
        } else if (isEqual(form.getFieldValue(schemaField.field), schemaField.defaultValue[0])) {
          form.setFieldValue(schemaField.field, undefined);
        }
      });
    };
  }, [actualSchema, childLayout, isCreateForm, fieldModel, form, funnelStages]);
};

export default useDefaultValues;
