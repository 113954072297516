enum ReportType {
  ALL_DASHBOARDS = "allDashboards",
  UPGRADE_YOUR_REPORTS = "upgradeYourReports",
  PINNED_DASHBOARD = "pinnedDashboard",
  OVERVIEW = "overview",
  ACTIVITY = "activity",
  DEALS = "deals",
  DEALS_BY_GEOGRAPHY = "dealsByGeography",
  EXPORTS = "exports",
  EXPORT_EDIT = "exportEdit",
  REVENUE = "revenue",
  WEEKLY_REPORT_CARD = "weeklyReportCard",
  COMPANIES = "companies",
  PEOPLE = "people",
  ROUTES = "routes",
  LEADERBOARD = "stackRank",
}

export default ReportType;
