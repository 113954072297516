import { endOfDay, parseISO } from "date-fns/esm";

import isValidDate from "@app/util/isValidDate";

import DateRangeType from "../enums/DateRangeType";
import DateRange from "../types/DateRange";

import dateRangeTypeToDateRange from "./dateRangeTypeToDateRange";

const rangeToDateRange = (range?: string): DateRange | undefined => {
  const dateRange = dateRangeTypeToDateRange(range as DateRangeType);
  if (dateRange) {
    return dateRange;
  }

  if (range) {
    const dates = range.split("_");
    if (dates.length) {
      const range = {
        endDate: parseISO(dates[1]),
        startDate: parseISO(dates[0]),
      };
      if (isValidDate(range.startDate) && isValidDate(range.endDate)) {
        return { ...range, endDate: endOfDay(range.endDate) };
      }
    }
  }
};

export default rangeToDateRange;
