import {
  AreaFilterAddressValue,
  AreaFilterCondition,
  AreaFilterEntityValue,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

export const isEntityValue = (
  value?: AreaFilterCondition["value"]
): value is AreaFilterEntityValue => !!value && !!(value as AreaFilterEntityValue).entity;

export const isAddressValue = (
  value?: AreaFilterCondition["value"]
): value is AreaFilterAddressValue => !!value && !isEntityValue(value);
