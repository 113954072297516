:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.mmc-text-field__input) {
          :global(.ant-input) {
            border: none;
            padding: 0.5rem 0;
            width: 100%;
            &:focus {
              border: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
