import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import { displayNameComparator } from "@app/util/comparator";
import leaderboardFieldModel from "@app/util/fieldModel/LeaderboardFieldModel";
import downloadFile from "@app/util/file/downloadFile";
import { userDisplayName } from "@app/util/formatters";

const getCsvRowFromItem = (
  item: LeaderboardItem,
  visibleMetrics: LeaderboardMetricFieldName[],
  percents?: boolean
) => [
  `"${item.team ? item.team.name : item.user ? userDisplayName(item.user) : ""}"`,
  ...visibleMetrics.map((visibleMetric) => {
    let result = 0;
    const values = item[percents ? "percentChangeInValues" : "values"]!;
    Object.keys(values).forEach((metric) => {
      if (metric.startsWith(visibleMetric)) {
        result += values[metric as keyof Record<LeaderboardMetricFieldName, number>];
      }
    });
    return result || "";
  }),
];

const downloadStackRankDataAsCsv = (
  data: LeaderboardItem[],
  visibleFields: IField[],
  percents?: boolean
) => {
  if (!data.length) {
    return; // nothing to export
  }

  const nameField = leaderboardFieldModel.getByName("name")!; // we know it always exists

  const visibleMetrics = visibleFields
    .filter((field) => field.hasFeature(FieldFeature.METRIC))
    .sort(displayNameComparator);

  const dataRows = data.map((item) =>
    getCsvRowFromItem(
      item,
      visibleMetrics.map(({ name }) => name as LeaderboardMetricFieldName),
      percents
    )
  );

  const headerRow = [
    nameField.displayName,
    ...visibleMetrics.map(({ displayName }) => displayName),
  ];
  const csvText = [headerRow.join(","), ...dataRows.map((row) => row.join(","))].join("\n");

  const blob = new Blob([csvText], { type: "text/plain" });

  // TODO: think about customizing name depending on current filters
  const fileName = `StackRank.csv`;
  downloadFile(fileName, blob);
};

export default downloadStackRankDataAsCsv;
