import React, { useCallback } from "react";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import InputNumber from "antd/es/input-number";
import Menu from "antd/es/menu";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import sortBy from "lodash-es/sortBy";
import uniq from "lodash-es/uniq";
import { MenuInfo } from "rc-menu/lib/interface";
import { defineMessages, useIntl } from "react-intl";

import { isDefined } from "@mapmycustomers/shared/util/assert";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import localSettings from "@app/config/LocalSettings";
import useStateWithTransformer from "@app/util/hook/useStateWithTransformer";
import roundToPrecision from "@app/util/number/roundToPrecision";

import styles from "./Distance.module.scss";

const roundValue = (value: null | number | undefined) =>
  value != null ? roundToPrecision(value, 2) : value || undefined;

interface Props {
  availableDistances: number[];
  customDistances: number[];
  dropDownClassName?: string;
  maxNumberOfCustomDistances?: number;
  maxValue?: number;
  onChange?: (radius: number) => void;
  onCustomDistancesChange?: (distances: number[]) => void;
  value: number;
}

const messages = defineMessages({
  addItem: {
    id: "component.searchAreaInput.distance.addCustom",
    defaultMessage: "Add",
    description: "Add Item button title",
  },
  alreadyInList: {
    id: "component.searchAreaInput.distance.error.alreadyInList",
    defaultMessage: "Distance already available on list",
    description: "Distance already available on list",
  },
  distanceLimit: {
    id: "component.searchAreaInput.distance.error.maxValue",
    defaultMessage: "Distance must be less than {distance}",
    description: "Distance must be less than X",
  },
  km: {
    id: "component.searchAreaInput.distance.label.km",
    defaultMessage: "{distance} km",
    description: "Kilometer unit in custom distance menu",
  },
  limit: {
    id: "component.searchAreaInput.distance.error.limit",
    defaultMessage: "Cannot add more than {num} custom distances",
    description: "Cannot add more than X custom distances",
  },
  miles: {
    id: "component.searchAreaInput.distance.label.miles",
    defaultMessage: "{distance} {distance, plural, =0 {miles} one {mile} other {miles}}",
    description: "Mile unit in custom distance menu",
  },
  placeholder: {
    id: "component.searchAreaInput.distance.placeholder",
    defaultMessage: "< {distance}",
    description: "Placeholder for distance input",
  },
  unitKm: {
    id: "component.searchAreaInput.distance.unit.km",
    defaultMessage: "km",
    description: "Kilometer unit in custom distance menu",
  },
  unitMiles: {
    id: "component.searchAreaInput.distance.unit.miles",
    defaultMessage: "miles",
    description: "Mile unit in custom distance menu",
  },
});

const Distance: React.FC<Props> = ({
  availableDistances,
  customDistances,
  dropDownClassName,
  maxNumberOfCustomDistances,
  maxValue,
  onChange,
  onCustomDistancesChange,
  value,
}) => {
  const intl = useIntl();

  const [customValue, setCustomValue] = useStateWithTransformer<
    number | undefined,
    null | number | undefined
  >(undefined, roundValue);

  const doesUseSiUnits = localSettings.doesUseSiUnits();
  const distanceFormatMessage = doesUseSiUnits ? messages.km : messages.miles;
  const unitMessage = doesUseSiUnits ? messages.unitKm : messages.unitMiles;

  const errorValueMissing = customValue === undefined || isNaN(customValue);
  const errorValueTooBig = !errorValueMissing && isDefined(maxValue) && customValue > maxValue;
  const errorValueOutOfRange = !errorValueMissing && (customValue < 0.1 || errorValueTooBig);
  const errorValueIncorrect = errorValueMissing || errorValueOutOfRange;
  const errorValueAlreadyInList = customValue
    ? availableDistances.includes(customValue) || customDistances.includes(customValue)
    : false;
  const errorLimitValues =
    isDefined(maxNumberOfCustomDistances) && customDistances?.length >= maxNumberOfCustomDistances;

  const errorMessage = errorValueTooBig
    ? intl.formatMessage(messages.distanceLimit, {
        distance: intl.formatMessage(distanceFormatMessage, { distance: maxValue }),
      })
    : errorValueAlreadyInList
    ? intl.formatMessage(messages.alreadyInList)
    : errorLimitValues
    ? intl.formatMessage(messages.limit, { num: maxNumberOfCustomDistances })
    : null;

  const hasCustomDistances = customDistances?.length > 0;
  const isCustomValueInvalid = errorValueIncorrect || errorValueAlreadyInList || errorLimitValues;

  const handleClick = useCallback(
    ({ key }: MenuInfo) => onChange?.(parseInt(key as string, 10)),
    [onChange]
  );

  const handleAddCustomValue = useCallback(() => {
    if (isCustomValueInvalid) {
      return; // don't process invalid value
    }

    onCustomDistancesChange?.(sortBy(uniq([...customDistances, customValue])));
    onChange?.(customValue);
    setCustomValue(undefined);
  }, [
    customDistances,
    customValue,
    isCustomValueInvalid,
    onChange,
    onCustomDistancesChange,
    setCustomValue,
  ]);

  const handleKeyPress = useCallback(
    (event) => {
      if (!isCustomValueInvalid && event.code === "Enter") {
        stopEvents(event);
        handleAddCustomValue();
      } else if (event.code === "Escape") {
        stopEvents(event);
        setCustomValue(undefined);
      }
    },
    [isCustomValueInvalid, handleAddCustomValue, setCustomValue]
  );

  const handleRemove = useCallback(
    (distance) => {
      const updatedCustomDistances = sortBy(customDistances.filter((value) => value !== distance));
      onCustomDistancesChange?.(updatedCustomDistances);

      if (distance === value) {
        const newValue =
          sortBy([...availableDistances, ...customDistances]).find((value) => value > distance) ??
          maxValue ??
          availableDistances.at(-1)!; // we're pretty sure availableDistances are provided
        onChange?.(newValue);
      }
    },
    [availableDistances, customDistances, maxValue, onChange, onCustomDistancesChange, value]
  );

  return (
    <Dropdown
      overlay={
        <div className={styles.overlay}>
          <Menu
            className={styles.menu}
            onClick={handleClick}
            selectedKeys={value ? [String(value)] : undefined}
          >
            {availableDistances.map((distance) => (
              <Menu.Item key={String(distance)}>
                {intl.formatMessage(distanceFormatMessage, { distance })}
              </Menu.Item>
            ))}

            {hasCustomDistances && (
              <>
                <Menu.Divider />
                {customDistances.map((distance) => (
                  <Menu.Item className={styles.menuItem} key={String(distance)}>
                    {intl.formatMessage(distanceFormatMessage, { distance })}

                    <Button
                      className={styles.remove}
                      icon={<FontAwesomeIcon icon={faMinusCircle} />}
                      onClick={(event) => {
                        stopEvents(event);
                        handleRemove(distance);
                      }}
                      type="link"
                    />
                  </Menu.Item>
                ))}
              </>
            )}
          </Menu>

          <div className={styles.custom} onClick={stopEvents}>
            <div className={styles.group}>
              <InputNumber
                bordered={false}
                className={cn({ [styles.error]: errorValueOutOfRange })}
                max={maxValue}
                min={0.1}
                onChange={setCustomValue}
                onKeyPress={handleKeyPress}
                placeholder={
                  maxValue
                    ? intl.formatMessage(messages.placeholder, { distance: maxValue })
                    : undefined
                }
                value={customValue}
              />
              <span className={styles.unit}>{intl.formatMessage(unitMessage)}</span>
            </div>

            <Tooltip
              overlayClassName={styles.tooltip}
              title={errorMessage}
              trigger={errorMessage ? ["hover"] : []}
            >
              <Button
                className={styles.add}
                disabled={isCustomValueInvalid}
                onClick={handleAddCustomValue}
              >
                <FontAwesomeIcon icon={faPlus} />
                {intl.formatMessage(messages.addItem)}
              </Button>
            </Tooltip>
          </div>
        </div>
      }
      overlayClassName={styles.container}
      placement="bottomLeft"
      trigger={["click"]}
    >
      <Button className={cn(styles.btn, dropDownClassName)} type="text">
        {intl.formatMessage(distanceFormatMessage, { distance: value })}
        <FontAwesomeIcon className={styles.arrow} icon={faAngleDown} />
      </Button>
    </Dropdown>
  );
};

export default Distance;
