import { createReducer } from "typesafe-actions";

import { EntityType } from "@mapmycustomers/shared/enum";

import GeoPointSortType from "@app/component/RecordList/components/CustomizeMapSortModal/enum/GeoPointSortType";
import MultiPinGroupMode from "@app/scene/map/enums/MultiPinGroupMode";
import { MAP_RECORDS_PAGE_SIZE } from "@app/scene/map/utils/consts";
import toggleItemInSet, { toggleMultiplyItemsInSet } from "@app/util/toggleItemInSet";

import {
  addToSelection,
  applyMapViewSettings,
  applyRecordsListSettings,
  clearHighlight,
  clearRecordsList,
  clearSelection,
  fetchPins,
  fetchRecords,
  hideAnnotation,
  hideMultiPin,
  hideSidebar,
  MapModeActions,
  removeFromSelection,
  resetRecordsListPagination,
  restartSelection,
  selectItem,
  selectMultiplyItems,
  setChangesDiscarded,
  setFocusedEntity,
  setHighlight,
  setListViewLocationSortQuery,
  setMultiPinGroupMode,
  setPinHover,
  setSelectedPin,
  setSelection,
  setSelectionEntities,
  showAnnotation,
  showMultiPinRecords,
  showSidebar,
  toggleSelectedItem,
} from "./actions";
import MapModeState from "./MapModeState";

export const mapModeInitialState: MapModeState = {
  annotation: undefined,
  categorizedMapEntries: {
    clusters: [],
    entities: [],
    multiPins: [],
  },
  error: undefined,
  loading: false,
  mode: GeoPointSortType.CURRENT_USER_LOCATION,
  pinHover: undefined,
  query: undefined,
  recordsList: {
    highlight: new Set(),
    loading: false,
    multiPin: undefined,
    range: { endRow: MAP_RECORDS_PAGE_SIZE, startRow: 0 },
    records: [],
    search: undefined,
    sort: [],
    totalFilteredRecords: 0,
    totalRecords: 0,
  },
  selected: {
    entities: [],
    excluded: new Set(),
    included: new Set(),
    items: new Set(),
  },
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: [],
    filter: {},
    range: { endRow: 10000, startRow: 0 },
    sidebarVisible: false,
    sort: [],
    tool: undefined,
    viewAs: undefined,
    visibleEntities: [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL],
  },
};

const mapMode = createReducer<MapModeState, MapModeActions>(mapModeInitialState)
  .handleAction(applyMapViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      colorKey: { ...(state.viewState.colorKey ?? {}), ...payload.colorKey },
      columns: payload.columns ?? state.viewState.columns,
      filter: payload.filter
        ? {
            ...state.viewState.filter,
            ...payload.filter,
          }
        : state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
      selectedSavedFilterId:
        "selectedSavedFilterId" in payload
          ? payload.selectedSavedFilterId
          : state.viewState.selectedSavedFilterId,
      shapeKey: { ...(state.viewState.shapeKey ?? {}), ...payload.shapeKey },
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
      visibleEntities: payload.visibleEntities ?? state.viewState.visibleEntities,
    },
  }))
  .handleAction(fetchPins.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(
    fetchPins.success,
    (state, { payload: { totalFilteredRecords, totalRecords, ...mapEntries } }) => ({
      ...state,
      categorizedMapEntries: mapEntries,
      loading: false,
      totalFilteredRecords,
      totalRecords,
    })
  )
  .handleAction(fetchPins.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
  }))
  .handleAction(fetchRecords.request, (state, { payload }) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      loading: true,
      search:
        payload.request?.search !== undefined ? payload.request?.search : state.viewState.search,
    },
  }))
  .handleAction(fetchRecords.success, (state, { payload }) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      loading: false,
      range: {
        endRow: payload.response.offset + payload.response.limit,
        startRow: payload.response.offset,
      },
      records: payload.response.data,
      totalFilteredRecords: payload.response.total,
      totalRecords: payload.isFiltered ? state.recordsList?.totalRecords : payload.response.total,
    },
  }))
  .handleAction(clearRecordsList, (state) => ({
    ...state,
    entityView: undefined,
    recordsList: {
      ...state.recordsList,
      loading: false,
      multiPin: undefined,
      multiPinAddress: undefined,
      range: {
        endRow: MAP_RECORDS_PAGE_SIZE,
        startRow: 0,
      },
      records: [],
      search: "",
      totalFilteredRecords: 0,
      totalRecords: 0,
    },
  }))
  .handleAction(resetRecordsListPagination, (state) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      range: {
        endRow: MAP_RECORDS_PAGE_SIZE,
        startRow: 0,
      },
    },
  }))
  .handleAction(fetchRecords.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    recordsList: {
      ...state.recordsList,
      loading: false,
    },
  }))
  .handleAction(applyRecordsListSettings, (state, { payload }) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      sort: payload.sort ?? state.recordsList.sort,
    },
  }))
  .handleAction(showAnnotation.success, (state, { payload }) => ({
    ...state,
    annotation: payload,
  }))
  .handleAction(hideAnnotation, (state) => ({ ...state, annotation: undefined }))
  .handleAction(setPinHover, (state, { payload }) => ({
    ...state,
    pinHover: payload,
  }))
  .handleAction(toggleSelectedItem, (state, { payload }) => ({
    ...state,
    selected: {
      ...state.selected,
      items: toggleItemInSet(state.selected.items, payload.entity, payload.value),
    },
  }))
  .handleAction(selectItem, (state, { payload }) => ({
    ...state,
    selected: {
      ...state.selected,
      items: toggleItemInSet(state.selected.items, payload, true),
    },
  }))
  .handleAction(selectMultiplyItems, (state, { payload }) => ({
    ...state,
    selected: {
      ...state.selected,
      items: toggleMultiplyItemsInSet(state.selected.items, payload, true),
    },
  }))
  .handleAction(setSelection, (state, { payload }) => ({
    ...state,
    selected: {
      ...state.selected,
      items: payload,
    },
  }))
  .handleAction(setSelectionEntities, (state, { payload }) => ({
    ...state,
    selected: {
      ...state.selected,
      entities: payload,
    },
  }))
  .handleAction(addToSelection, (state, { payload }) => ({
    ...state,
    selected: {
      ...state.selected,
      excluded: new Set(
        payload instanceof Set
          ? Array.from(state.selected.excluded).filter((value: string) => !payload.has(value))
          : Array.from(state.selected.excluded).filter((value: string) => payload !== value)
      ),
      included: new Set(
        payload instanceof Set
          ? [...state.selected.included, ...payload]
          : [...state.selected.included, payload]
      ),
    },
  }))
  .handleAction(removeFromSelection, (state, { payload }) => ({
    ...state,
    selected: {
      ...state.selected,
      excluded: new Set(
        payload instanceof Set
          ? [...state.selected.excluded, ...payload]
          : [...state.selected.excluded, payload]
      ),
      included: new Set(
        payload instanceof Set
          ? Array.from(state.selected.included).filter((value: string) => !payload.has(value))
          : Array.from(state.selected.included).filter((value: string) => payload !== value)
      ),
    },
  }))
  .handleAction(restartSelection, (state) => ({
    ...state,
    selected: {
      ...state.selected,
      entities: [],
      excluded: new Set<string>(),
      included: new Set<string>(),
      items: new Set<string>(),
    },
  }))
  .handleAction(clearSelection, (state) => ({
    ...state,
    selected: {
      ...state.selected,
      entities: [],
      items: new Set<string>(),
    },
  }))
  .handleAction(setHighlight, (state, { payload }) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      highlight: payload,
    },
  }))
  .handleAction(clearHighlight, (state) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      highlight: new Set(),
    },
  }))
  .handleAction(showMultiPinRecords.request, (state, { payload }) => ({
    ...state,
    entityView: undefined,
    pinHover: payload.id,
    recordsList: {
      ...state.recordsList,
      multiPin: {
        address: undefined,
        entity: payload,
        groupMode: MultiPinGroupMode.WITHIN_GROUP,
      },
    },
  }))
  .handleAction(showMultiPinRecords.success, (state, { payload }) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      multiPin: {
        ...state.recordsList.multiPin,
        address: payload.address,
      },
    },
  }))
  .handleAction(hideMultiPin, (state) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      multiPin: undefined,
    },
  }))
  .handleAction(setMultiPinGroupMode, (state, { payload }) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      multiPin: {
        ...state.recordsList.multiPin,
        groupMode: payload,
      },
    },
  }))
  .handleAction(showSidebar, (state, { payload }) => ({
    ...state,
    recordsList: {
      ...state.recordsList,
      loading: payload?.skipLoading ? state.recordsList.loading : true, // imitate loading
    },
    viewState: {
      ...state.viewState,
      sidebarVisible: true,
    },
  }))
  .handleAction(hideSidebar, (state) => ({
    ...state,
    viewState: {
      ...state.viewState,
      sidebarVisible: false,
    },
  }))
  .handleAction(setFocusedEntity, (state, { payload }) => ({
    ...state,
    focusedEntity: payload,
    selectedPin: undefined,
  }))
  .handleAction(setSelectedPin, (state, { payload }) => ({
    ...state,
    focusedEntity: undefined,
    selectedPin: payload,
  }))
  .handleAction(setListViewLocationSortQuery, (state, { payload }) => ({
    ...state,
    query: payload,
  }))
  .handleAction(setChangesDiscarded, (state, { payload }) => ({
    ...state,
    changesDiscarded: payload,
  }));

export default mapMode;
