import { createSelector } from "reselect";

import { RootState } from "../rootReducer";

const authState = (state: RootState) => state.auth;
export const isLoading = createSelector(authState, ({ loading }) => loading);
export const getError = createSelector(authState, ({ error }) => error);
export const getToken = createSelector(authState, ({ token }) => token);
export const isSignedIn = createSelector(authState, ({ loading, token }) =>
  loading ? undefined : !!token && token.length > 0
);

export const isForgotPasswordLoading = createSelector(
  authState,
  ({ forgotPasswordLoading }) => forgotPasswordLoading
);

export const isForgotPasswordSuccess = createSelector(
  authState,
  ({ forgotPasswordSuccess }) => forgotPasswordSuccess
);

export const isResetPasswordLoading = createSelector(
  authState,
  ({ resetPasswordLoading }) => resetPasswordLoading
);

export const isResetPasswordSuccess = createSelector(
  authState,
  ({ resetPasswordSuccess }) => resetPasswordSuccess
);

export const doesUserExist = createSelector(authState, ({ userExists }) => userExists);
export const isUserExistsLoading = createSelector(
  authState,
  ({ userExistsLoading }) => userExistsLoading
);

export const isSignUpLoading = createSelector(authState, ({ signupLoading }) => signupLoading);

export const isInvitationInfoLoading = createSelector(
  authState,
  ({ invitationInfoLoading }) => invitationInfoLoading
);
export const getInvitationInfo = createSelector(authState, ({ invitationInfo }) => invitationInfo);

export const isResetPasswordLinkExpired = createSelector(
  authState,
  ({ resetPasswordLinkExpired }) => resetPasswordLinkExpired
);

export const isInvalidUser = createSelector(authState, ({ invalidUser }) => invalidUser);

export const getUserRequestStatus = createSelector(
  authState,
  ({ userRequestStatus }) => userRequestStatus
);

export const getLinkExpired = createSelector(authState, ({ linkExpired }) => linkExpired);

export const getUserDetails = createSelector(authState, ({ userDetails }) => userDetails);

export const isUserLimitExceeded = createSelector(
  authState,
  ({ userLimitExceeded }) => userLimitExceeded
);
