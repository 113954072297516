import { Editor, Transforms } from "slate";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

import { ImageElement } from "../../type/slate-types";

const insertImage = (editor: Editor, alt: string, url: string) => {
  const text = { text: "" };
  const image: ImageElement = {
    alt,
    children: [text],
    type: ElementType.IMAGE,
    url,
  };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    children: [{ text: "" }],
    type: ElementType.PARAGRAPH,
  });
};

export default insertImage;
