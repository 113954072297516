import notification from "antd/es/notification";
import { defineMessages } from "react-intl";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import Organization from "@mapmycustomers/shared/types/Organization";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import i18nService from "@app/config/I18nService";
import CardType from "@app/enum/dashboard/CardType";
import { callApi } from "@app/store/api/callApi";
import {
  fetchActivitiesLoggedCardDataHelper,
  fetchActivitiesOverdueCardDataHelper,
  fetchCheckInsCardDataHelper,
  fetchNewRecordsCardDataHelper,
  fetchNoContactInCountCardDataHelper,
  fetchRecordsPastDueCardDataHelper,
} from "@app/store/dashboard/cardDataFetchHelpers";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { getTeams, getUsersOfEntireOrganization } from "@app/store/members";
import { updateTeam, updateUser } from "@app/store/members/actions";
import Dashboard from "@app/types/dashboard/Dashboard";

import { fetchCardData, fetchCards, fetchDashboards, updateCards } from "./actions";
import { getCardsData } from "./selectors";

const successMessages = defineMessages({
  description: {
    id: "userPreviewCard.addCard.success.description",
    defaultMessage: "Hover over the user to see it updated.",
    description: "Metric added successful description",
  },
  title: {
    id: "userPreviewCard.addCard.success.title",
    defaultMessage: "Metric added successfully",
    description: "Metric added successful title",
  },
});

export interface CardData {
  count: number;
  previousCount?: number;
  rank?: number;
}

export function* onFetchDashboards() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<Dashboard> = yield callApi("fetchDashboards", orgId);
    yield put(fetchDashboards.success(response.data));
  } catch (error) {
    yield put(fetchDashboards.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchCards({ payload }: ReturnType<typeof fetchCards.request>) {
  try {
    const users: User[] = yield select(getUsersOfEntireOrganization);
    const teams: Team[] = yield select(getTeams);

    const { teamId, userId } = payload;
    const user = users.find(({ id }) => id === userId);
    const team = teams.find(({ id }) => id === teamId);

    if (team && userId) {
      const cardIds = team.metaData?.previewCardIds;
      yield put(fetchCards.success(cardIds ?? {}));
    } else if (user) {
      const cardIds = user.metaData?.previewCardIds;
      yield put(fetchCards.success(cardIds ?? {}));
    }
  } catch (error) {
    yield put(fetchCards.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateCards({ payload }: ReturnType<typeof updateCards.request>) {
  try {
    const intl = i18nService.getIntl();
    const users: User[] = yield select(getUsersOfEntireOrganization);
    const teams: Team[] = yield select(getTeams);
    const {
      cardIds,
      scope: { teamId, userId },
    } = payload;
    const user = users.find(({ id }) => id === userId);
    const team = teams.find(({ id }) => id === teamId);
    let isNewCardAdded = false;

    if (team) {
      isNewCardAdded =
        Object.keys({ ...team.metaData?.previewCardIds, ...cardIds }).length >
        Object.keys(team.metaData?.previewCardIds ?? {}).length;
      yield put(
        updateTeam.request({
          ...team,
          metaData: {
            ...team.metaData,
            previewCardIds: { ...team.metaData?.previewCardIds, ...cardIds },
          },
        })
      );
    } else if (user) {
      isNewCardAdded =
        Object.keys({ ...user.metaData?.previewCardIds, ...cardIds }).length >
        Object.keys(user.metaData?.previewCardIds ?? {}).length;
      yield put(
        updateUser.request({
          ...user,
          metaData: {
            ...user.metaData,
            previewCardIds: { ...user.metaData?.previewCardIds, ...cardIds },
          },
        })
      );
    }

    if (isNewCardAdded) {
      notification.success({
        description: intl?.formatMessage(successMessages.description),
        message: intl?.formatMessage(successMessages.title),
      });
    }

    yield put(fetchCards.request({ teamId, userId }));
    yield put(updateCards.success());
  } catch (error) {
    yield put(updateCards.failure());
    yield put(handleError({ error }));
  }
}

function* onFetchCardData({ payload }: ReturnType<typeof fetchCardData.request>) {
  const { callback, card, failureCallback, scope } = payload;
  const cardsData: Record<string, CardData> = yield select(getCardsData);
  const cardsDataId = `${scope.teamId ?? ""}_${scope.userId ?? ""}_${card.id}`;
  if (Object.hasOwn(cardsData, cardsDataId)) {
    yield call(callback, cardsData[cardsDataId]);
  }

  const result: CardData = {
    count: 0,
  };

  try {
    switch (card.type) {
      case CardType.CHECK_INS:
        yield call(fetchCheckInsCardDataHelper, {
          callback: (data) => {
            result.count = data.verifiedCount + (data.unverifiedCount ?? 0);
            result.previousCount = data.previousCount;
            result.rank = data.rank;
          },
          configuration: card.configuration,
          scope,
        });
        break;

      case CardType.NEW_RECORDS:
        yield call(fetchNewRecordsCardDataHelper, {
          callback: (data) => {
            result.count = data.count;
            result.previousCount = data.previousCount;
            result.rank = data.rank;
          },
          configuration: card.configuration,
          scope,
        });
        break;

      case CardType.ACTIVITIES_LOGGED:
        yield call(fetchActivitiesLoggedCardDataHelper, {
          callback: (data) => {
            result.count = data.count;
            result.previousCount = data.previousCount;
            result.rank = data.rank;
          },
          configuration: card.configuration,
          scope,
        });
        break;

      case CardType.ACTIVITIES_OVERDUE:
        yield call(fetchActivitiesOverdueCardDataHelper, {
          callback: (data) => {
            result.count = data.count;
          },
          configuration: card.configuration,
          scope,
        });
        break;

      case CardType.NO_CONTACT_IN_COUNT:
        yield call(fetchNoContactInCountCardDataHelper, {
          callback: (data) => {
            result.count = data.total;
          },
          configuration: card.configuration,
          scope,
        });
        break;

      case CardType.RECORDS_PAST_DUE:
        yield call(fetchRecordsPastDueCardDataHelper, {
          callback: (data) => {
            result.count = data.count;
          },
          configuration: card.configuration,
          scope,
        });
        break;
    }

    yield call(callback, result);
    yield put(fetchCardData.success({ id: cardsDataId, data: result }));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchCardData.failure());
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

export function* userPreviewCardSaga() {
  yield takeLatest(fetchDashboards.request, onFetchDashboards);
  yield takeLatest(fetchCards.request, onFetchCards);
  yield takeLatest(updateCards.request, onUpdateCards);
  yield takeEvery(fetchCardData.request, onFetchCardData);
}
