import notification from "antd/es/notification";
import { replace } from "connected-react-router";
import pick from "lodash-es/pick";
import { call, put, select, take, takeEvery } from "redux-saga/effects";

import { FieldVisibilityFilter } from "@mapmycustomers/shared/enum/FieldVisibility";
import SchemaFieldType, {
  SchemaFieldTypeFilter,
} from "@mapmycustomers/shared/enum/SchemaFieldType";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import {
  EntityTypesSupportingFieldCustomization,
  EntityTypeSupportingCustomFields,
} from "@mapmycustomers/shared/types/entity";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import Organization from "@mapmycustomers/shared/types/Organization";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import i18nService from "@app/config/I18nService";
import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import { callApi } from "@app/store/api/callApi";
import { getCustomFieldsForEntity } from "@app/store/customFields";
import { fetchCustomFields, updateCustomField } from "@app/store/customFields/actions";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { getAllColorLegends, getAllShapeLegends } from "@app/store/pinLegends";
import { fetchPinLegends } from "@app/store/pinLegends/actions";
import { fetchSchemaForEntityType } from "@app/store/schema/actions";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { isCustomField } from "@app/util/fieldModel/impl/assert";

import {
  archiveField,
  bulkUpdateField,
  cancelEditField,
  cloneCustomField,
  createField,
  editField,
  enterArchiveMode,
  exitArchiveMode,
  unarchiveField,
  updateField,
  updateSchema,
  updateViewState,
} from "./actions";
import { ManageFieldsViewState } from "./index";
import { getActiveEntityType, getFields, getViewState, isArchiveMode } from "./selectors";

export function* onUpdateSchema() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);
    const archived: boolean = yield select(isArchiveMode);
    const viewState: ManageFieldsViewState = yield select(getViewState);
    const fieldModel = getFieldModelByEntityType(entityType);

    const visibilityFilter =
      viewState.visibility === FieldVisibilityFilter.ALL
        ? undefined
        : { visibility: viewState.visibility };

    let fieldTypeFilter = [
      SchemaFieldType.STANDARD,
      SchemaFieldType.FILE,
      SchemaFieldType.NOTE,
      SchemaFieldType.CUSTOM,
    ];
    switch (viewState.type) {
      case SchemaFieldType.STANDARD:
      case SchemaFieldType.CUSTOM:
        fieldTypeFilter = [viewState.type];
    }

    const systemRequiredFilter =
      viewState.type === SchemaFieldTypeFilter.REQUIRED
        ? { systemRequired: true }
        : viewState.type === SchemaFieldType.STANDARD
        ? { systemRequired: false }
        : {};

    const response: ListResponse<SchemaField> = yield callApi(
      "getSchemaFieldAccess",
      orgId,
      entityType,
      {
        $filters: {
          ...visibilityFilter,
          fieldType: { $in: fieldTypeFilter },
          ...systemRequiredFilter,
          archived,
        },
        $limit: 1000,
        $offset: 0,
        $order: "-updatedAt",
      }
    );

    // sort fields by display name
    let data = response.data.sort((a, b) => {
      const nameA = fieldModel.getByPlatformName(a.field)?.fieldManagementDisplayName ?? a.field;
      const nameB = fieldModel.getByPlatformName(b.field)?.fieldManagementDisplayName ?? b.field;
      return nameA.localeCompare(nameB);
    });

    if (viewState.visibility !== FieldVisibilityFilter.ALL) {
      data = data.filter((field) => field.visibility === viewState.visibility);
    }

    if (viewState.search) {
      const criteria = String(viewState.search).trim().toLowerCase();
      data = data.filter((schemaField) => {
        const field = fieldModel.getByName(schemaField.field);
        if (field) {
          return field.fieldManagementDisplayName.toLowerCase().includes(criteria);
        }

        return schemaField.field.toLowerCase().includes(criteria);
      });
    }

    yield put(updateSchema.success({ fields: data, total: data.length }));
  } catch (error) {
    yield put(updateSchema.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onArchiveField({ payload }: ReturnType<typeof archiveField.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const fieldModel = getFieldModelByEntityType(payload.entity);
    const field = fieldModel.getByName(payload.field);
    const name = field?.fieldManagementDisplayName ?? payload.field;

    yield callApi("updateFieldAccess", orgId, { id: payload.id, archived: true });

    yield put(archiveField.success());
    yield put(updateSchema.request());

    // we need to call this separately
    yield put(fetchSchemaForEntityType.request(payload.entity));

    const colorLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapeLegends: PinLegend[] = yield select(getAllShapeLegends);
    const customFieldId = field && isCustomField(field) ? field.customFieldData.id : undefined;
    if (
      customFieldId &&
      (colorLegends.some((legend) => legend.customFieldId === customFieldId) ||
        shapeLegends.some((legend) => legend.customFieldId === customFieldId))
    ) {
      // re-fetch pin legends since there will be less now
      yield put(fetchPinLegends.request());
    }

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(
          {
            id: "settings.manageFields.notifications.archived",
            defaultMessage: 'Field "{name}" has been archived',
            description: "Archived field - notification message",
          },
          {
            name,
          }
        ),
      });
    }
  } catch (error) {
    yield put(archiveField.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUnarchiveField({ payload }: ReturnType<typeof unarchiveField.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const fieldModel = getFieldModelByEntityType(payload.entity);
    const field = fieldModel.getByName(payload.field);
    const name = field?.fieldManagementDisplayName ?? payload.field;

    yield callApi("updateFieldAccess", orgId, {
      id: payload.id,
      archived: false,
    });

    yield put(unarchiveField.success());
    yield put(updateSchema.request());

    // we need to call this separately
    yield put(fetchSchemaForEntityType.request(payload.entity));

    // re-fetch pin legends, since there might be more legends now
    yield put(fetchPinLegends.request());

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(
          {
            id: "settings.manageFields.notifications.unarchived",
            defaultMessage: 'Field "{name}" has been unarchived and is active again',
            description: "Unarchived field - notification message",
          },
          {
            name,
          }
        ),
      });
    }
  } catch (error) {
    yield put(unarchiveField.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateField({
  payload: { callback, customField, entityType, field },
}: ReturnType<typeof createField.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const newCustomField: CustomField = yield callApi(
      "createCustomField",
      orgId,
      entityType,
      customField
    );

    // TODO: Optimize together with platform changes
    // After creating a custom field itself, we need to get ID of this custom field in access mappings
    // Platform doesn't have nice way for this, so this can be considered as useful TODO
    const responseList: ListResponse<SchemaField> = yield callApi(
      "getSchemaFieldAccess",
      orgId,
      entityType,
      {
        $filters: { field: newCustomField.esKey, fieldType: { $in: [SchemaFieldType.CUSTOM] } },
        $limit: 100,
        $offset: 0,
        $order: "-updatedAt",
      }
    );

    const customFieldAccessRecord = (responseList.data ?? []).find(
      (field: SchemaField) => field.customFieldId === newCustomField.id
    );
    if (customFieldAccessRecord) {
      yield callApi("updateFieldAccess", orgId, {
        id: customFieldAccessRecord.id,
        ...field,
      });
    }

    yield put(fetchCustomFields.request({ entityType }));
    yield put(updateSchema.request());

    yield put(createField.success());
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put(createField.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onEditField({ payload }: ReturnType<typeof editField.request>) {
  try {
    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);

    const fields: SchemaField[] = yield select(getFields);
    const field = fields.find((row) => row.id === payload);

    if (field) {
      const customFieldsGetter: (entityType: EntityTypeSupportingCustomFields) => CustomField[] =
        yield select(getCustomFieldsForEntity);
      const customField = customFieldsGetter(entityType)?.find(
        (cf) => cf.id === field.customFieldId
      );

      yield put(editField.success({ customField, field }));
    } else {
      yield put(editField.failure());
    }
  } catch (error) {
    yield put(editField.failure());
    yield put(handleError({ error }));
  }
}

export function* onCancelEditField({ payload }: ReturnType<typeof cancelEditField>) {
  try {
    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);
    if (payload !== false) {
      yield put(replace(`${Path.SETTINGS}/${SettingPath.FORMS_MANAGE_FIELDS}/${entityType}`));
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUpdateField({ payload }: ReturnType<typeof updateField.request>) {
  try {
    if (!payload.field?.id || !payload.field?.entity) {
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);

    const entityType = payload.field.entity;
    const isCustomField =
      payload.field.fieldType === SchemaFieldType.CUSTOM &&
      payload.field.customFieldId &&
      payload.customField;

    if (isCustomField) {
      const customFieldsGetter: (entityType: EntityTypeSupportingCustomFields) => CustomField[] =
        yield select(getCustomFieldsForEntity);
      const customField = customFieldsGetter(entityType).find(
        (cf) => cf.id === payload.field.customFieldId
      );

      const customFieldUpdatePayload = pick(payload.customField, [
        "currencyId",
        "displayName",
        "displayType",
        "expressionDesc",
        "options",
        "placeholder",
      ]);

      if (customField) {
        const updatedOptions =
          customFieldUpdatePayload.options?.map((option, index) => ({
            ...option,
            displayOrder: index + 1,
          })) ?? [];
        yield put(
          updateCustomField.request({
            customField: { ...customField, ...customFieldUpdatePayload, options: updatedOptions },
            entityType,
          })
        );
        yield take([updateCustomField.success, updateCustomField.failure]);
      }
    }

    yield callApi("updateFieldAccess", orgId, {
      id: payload.field.id,
      phiEnabled: payload.field.phiEnabled,
      teamVisibility: payload.field.teamVisibility,
      visibility: payload.field.visibility,
    });

    yield put(updateSchema.request());
    // we need to call this separately
    yield put(fetchSchemaForEntityType.request(entityType));

    yield put(updateField.success());
    yield put(cancelEditField());
  } catch (error) {
    yield put(updateField.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onSwitchArchiveMode() {
  try {
    yield put(updateSchema.request());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUpdateViewState() {
  try {
    yield put(updateSchema.request());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onBulkUpdateField({ payload }: ReturnType<typeof bulkUpdateField.request>) {
  try {
    if (!payload.length) {
      yield put(bulkUpdateField.success());
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType = payload[0].entity;

    yield callApi("bulkUpdateFields", orgId, payload);

    yield put(updateSchema.request());
    // we need to call this separately
    yield put(fetchSchemaForEntityType.request(entityType));

    yield put(bulkUpdateField.success());

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(
          {
            id: "settings.manageFields.notifications.fieldAccess",
            defaultMessage:
              "{count} {count, plural, one {field} other {fields}} were updated successfully",
            description: "Field access changed - notification message",
          },
          {
            count: payload.length,
          }
        ),
      });
    }
  } catch (error) {
    yield put(bulkUpdateField.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCloneCustomField({
  payload: { callback, data },
}: ReturnType<typeof cloneCustomField.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);

    const customField: CustomField = yield callApi("cloneCustomField", orgId, entityType, data);

    yield put(fetchCustomFields.request({ entityType }));

    yield put(updateSchema.request());
    yield take([updateSchema.success, updateSchema.failure]);

    yield put(cloneCustomField.success());

    if (callback) {
      const schemaFields: SchemaField[] = yield select(getFields);
      const schemaField = schemaFields.find((field) => field.customFieldId === customField.id);
      if (schemaField) {
        yield call(callback, schemaField);
      }
    }
  } catch (error) {
    yield put(cloneCustomField.failure(error));
    yield put(handleError({ error }));
  }
}

export function* manageFieldsSaga() {
  yield takeEvery([enterArchiveMode, exitArchiveMode], onSwitchArchiveMode);
  yield takeEvery(updateViewState, onUpdateViewState);
  yield takeEvery(createField.request, onCreateField);
  yield takeEvery(editField.request, onEditField);
  yield takeEvery(cancelEditField, onCancelEditField);
  yield takeEvery(updateField.request, onUpdateField);
  yield takeEvery(archiveField.request, onArchiveField);
  yield takeEvery(unarchiveField.request, onUnarchiveField);
  yield takeEvery(updateSchema.request, onUpdateSchema);
  yield takeEvery(bulkUpdateField.request, onBulkUpdateField);
  yield takeEvery(cloneCustomField.request, onCloneCustomField);
}
