import { Descendant } from "slate";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const isValueEmpty = (value: Descendant[]): boolean =>
  // if document is not empty then both loops will end of the first iteration
  // if document is empty, then we don't really expect that it has [m]any tree nodes
  !value.some((element) =>
    "children" in element
      ? element.children.some((child: Descendant) => {
          return "text" in child ? child.text.length > 0 : child.type === ElementType.MENTION;
        })
      : element.text.length > 0
  );

export default isValueEmpty;
