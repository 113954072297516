import React from "react";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import PastDueValue from "./PastDueValue";
import UpcomingValue from "./UpcomingValue";
import UpcomingVariantValue from "./UpcomingVariantValue";

export const FREQUENCY_STATUS_FILTER_OPERATORS = [
  FilterOperator.IS_OVERDUE,
  FilterOperator.WILL_BE_OVERDUE,
  FilterOperator.IS_UPCOMING,
];

interface CadenceStatusFilterProps extends IFilterComponentProps {}

const FrequencyStatusFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return typeof value === "number" && FREQUENCY_STATUS_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<CadenceStatusFilterProps> =>
    ({ className, onChange, value }) => {
      switch (value.operator) {
        case FilterOperator.IS_UPCOMING:
          return <UpcomingVariantValue className={className} onChange={onChange} value={value} />;
        case FilterOperator.IS_OVERDUE:
          return <PastDueValue className={className} onChange={onChange} value={value} />;
        case FilterOperator.WILL_BE_OVERDUE:
          return <UpcomingValue className={className} onChange={onChange} value={value} />;
        default:
          return null;
      }
    },
};

export default FrequencyStatusFilter;
