import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import loggingService from "@app/util/logging";

import { isInRangeCondition } from "../util";

const parseCadenceStatusCondition = (platformCondition: Condition): FilterCondition | undefined => {
  if (isInRangeCondition(platformCondition)) {
    const valueFrom = platformCondition[PlatformFilterOperator.GREATER_THAN_OR_EQUAL] as number;
    const valueTo = platformCondition[PlatformFilterOperator.LESS_THAN_OR_EQUAL] as number;
    return valueTo === 0
      ? { operator: FilterOperator.IS_OVERDUE_IN_LESS_THAN, value: -(valueFrom - 1) }
      : { operator: FilterOperator.IS_OVERDUE_BY_LESS_THAN, value: valueTo + 1 };
  }

  if (Object.keys(platformCondition).length !== 1) {
    loggingService.warning(
      "Invalid condition for cadenceDaysOut field: not range condition but has number of operators greater than 1"
    );
    return undefined;
  }

  const filterOperator = Object.keys(platformCondition)[0] as PlatformFilterOperator;
  const filterValue = platformCondition[filterOperator] as number;

  switch (filterOperator) {
    case PlatformFilterOperator.GREATER_THAN:
      return filterValue === 0
        ? undefined
        : { operator: FilterOperator.IS_OVERDUE_BY_MORE_THAN, value: filterValue };

    case PlatformFilterOperator.GREATER_THAN_OR_EQUAL:
      return filterValue === 0 ? { operator: FilterOperator.IS_OVERDUE, value: 0 } : undefined;
    case PlatformFilterOperator.LESS_THAN:
      return filterValue === 0
        ? { operator: FilterOperator.IS_UPCOMING, value: 0 }
        : { operator: FilterOperator.IS_OVERDUE_IN_MORE_THAN, value: -filterValue };
  }

  loggingService.warning(
    `Invalid condition for cadenceDaysOut field: unexpected operator (${filterOperator})`
  );
  return undefined;
};

export default parseCadenceStatusCondition;
