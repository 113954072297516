@use "../../styles/colors";
:global {
  body[class*="theme-"].theme {
    :local {
      .popover {
        :global(.ant-popover-inner) {
          border: 0;
        }
        :global(.ant-popover-inner-content) {
          padding: 0 0.5rem;
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
          border-radius: 0.25rem;
        }
      }
      .divider:global(.ant-divider):global(.ant-divider-vertical) {
        border-color: colors.$mmc-disabled-gray;
        margin: 0.5rem;
        height: 1.25rem;
      }

      .link {
        padding: 0;
        color: colors.$mmc-primary-100;
        font-weight: bold;
      }

      .button {
        padding: 0;
      }

      .url {
        margin-left: 0.5rem;
      }

      .goToLink {
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
