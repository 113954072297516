import { all, fork } from "redux-saga/effects";

import { alertsSagas } from "@app/scene/map/store/layers/alerts/sagas";
import { boundariesLayerSagas } from "@app/scene/map/store/layers/boundaries/sagas";
import { heatMapLayerSagas } from "@app/scene/map/store/layers/heatMap/sagas";
import { trafficLayerSagas } from "@app/scene/map/store/layers/traffic/sagas";
import { userLocationsSagas } from "@app/scene/map/store/layers/userLocations/sagas";

export function* layersSagas() {
  yield all([
    fork(alertsSagas),
    fork(heatMapLayerSagas),
    fork(boundariesLayerSagas),
    fork(trafficLayerSagas),
    fork(userLocationsSagas),
  ]);
}
