import configService from "@app/config/ConfigService";
import Environment from "@app/enum/Environment";

import ConsoleLogger from "./ConsoleLogger";
import ILogger from "./ILogger";
import LoggingService from "./LoggingService";
import LogLevel from "./LogLevel";
import SentryLogger from "./SentryLogger";

const consoleLogger = new ConsoleLogger();
const sentryLogger = new SentryLogger();

const LOGGERS_CONFIG: Record<Environment, Partial<Record<LogLevel, ILogger[]>>> = {
  [Environment.DEVELOPMENT]: {
    [LogLevel.DEBUG]: [consoleLogger],
    [LogLevel.ERROR]: [consoleLogger],
    [LogLevel.INFO]: [consoleLogger],
    [LogLevel.WARNING]: [consoleLogger],
  },
  [Environment.PRODUCTION]: {
    [LogLevel.DEBUG]: [sentryLogger],
    [LogLevel.ERROR]: [consoleLogger, sentryLogger],
    [LogLevel.INFO]: [sentryLogger],
    [LogLevel.WARNING]: [consoleLogger, sentryLogger],
  },
  [Environment.SANDBOX]: {
    [LogLevel.DEBUG]: [sentryLogger],
    [LogLevel.ERROR]: [consoleLogger, sentryLogger],
    [LogLevel.INFO]: [sentryLogger],
    [LogLevel.WARNING]: [consoleLogger, sentryLogger],
  },
  [Environment.STAGING]: {
    [LogLevel.DEBUG]: [consoleLogger],
    [LogLevel.ERROR]: [consoleLogger],
    [LogLevel.INFO]: [consoleLogger],
    [LogLevel.WARNING]: [consoleLogger],
  },
};

const loggingService = new LoggingService(LOGGERS_CONFIG[configService.getCurrentEnvironment()]);

export default loggingService;
