import React, { ReactNode } from "react";

import styles from "./NoData.module.scss";

interface Props {
  imageSrc: string;
  text: ReactNode;
}

const NoData: React.FC<Props> = ({ imageSrc, text }) => {
  return (
    <div className={styles.container}>
      <img alt="No data" src={imageSrc} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default NoData;
