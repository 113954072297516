import ClusterImpl from "@app/util/clustering/ClusterImpl";
import {
  Algorithm,
  AlgorithmInput,
  AlgorithmOptions,
  AlgorithmOutput,
} from "@app/util/clustering/types/algorithm";
import Cluster from "@app/util/clustering/types/Cluster";

abstract class AbstractAlgorithm<T> implements Algorithm<T> {
  protected maxZoom: number;

  protected constructor({ maxZoom = 16 }: AlgorithmOptions) {
    this.maxZoom = maxZoom;
  }

  /**
   * Helper function to bypass clustering based upon some map state such as
   * zoom, number of markers, etc.
   *
   * ```typescript
   *  cluster({markers, map}: AlgorithmInput): Cluster[] {
   *    if (shouldBypassClustering(map)) {
   *      return this.noop({markers})
   *    }
   * }
   * ```
   */
  protected noop<I extends Pick<AlgorithmInput<T>, "coordinateGetter" | "items">>({
    coordinateGetter,
    items,
  }: I): Cluster<T>[] {
    const result: Cluster<T>[] = [];
    for (const item of items) {
      result.push(new ClusterImpl<T>([item], coordinateGetter, coordinateGetter(item)));
    }
    return result;
  }
  /**
   * Calculates an array of {@link Cluster}. Calculate is separate from
   * {@link cluster} as it does preprocessing on the markers such as filtering
   * based upon the viewport as in {@link AbstractViewportAlgorithm}. Caching
   * and other optimizations can also be done here.
   */
  public abstract calculate({ items, map }: AlgorithmInput<T>): AlgorithmOutput<T>;

  /**
   * Clusters the markers and called from {@link calculate}.
   */
  protected abstract cluster({ items, map }: AlgorithmInput<T>): Cluster<T>[];
}

export default AbstractAlgorithm;
