import { put, select, takeEvery } from "redux-saga/effects";

import { Deal } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import dealFieldModel from "@app/util/fieldModel/DealFieldModel";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import { DEFAULT_PAGE_SIZE } from "..";

import { fetchDeals } from "./actions";

export function* onFetchDeals({ payload }: ReturnType<typeof fetchDeals.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const range = payload.request.range;

    const response: ListResponse<Deal> = yield callApi("fetchDeals", orgId, {
      $filters: {
        ...convertToPlatformFilterModel(payload.request.filter ?? {}, [], dealFieldModel),
      },
      $limit: range ? range.endRow - range.startRow : DEFAULT_PAGE_SIZE,
      $offset: range ? range.startRow : 0,
    });
    yield put(fetchDeals.success({ deals: response.data, total: response.total }));
  } catch (error) {
    yield put(fetchDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* dealsSaga() {
  yield takeEvery(fetchDeals.request, onFetchDeals);
}
