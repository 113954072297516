import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import { Modal, TextField } from "@mapmycustomers/ui";

import { getEmailRecipientsEntityType, isNewEmailTemplateCreating } from "@app/store/email";
import { createNewEmailTemplate } from "@app/store/email/actions";
import { RootState } from "@app/store/rootReducer";

import messages from "./messages";
import styles from "./NewTemplateModal.module.scss";

interface Props {
  creating: boolean;
  emailBody: string;
  emailSubject: string;
  entityType?: EntityTypesSupportedByEmailFeature;
  onCreateNewEmailTemplate: typeof createNewEmailTemplate.request;
  onHide: (template?: EmailTemplate) => void;
}

const NewTemplateModal: React.FC<Props> = ({
  creating,
  emailBody,
  emailSubject,
  entityType,
  onCreateNewEmailTemplate,
  onHide,
}) => {
  const intl = useIntl();
  const [name, setName] = useState<string>("");

  const handleOk = useCallback(() => {
    if (entityType) {
      onCreateNewEmailTemplate({
        callback: onHide,
        template: {
          body: emailBody,
          entityType,
          name,
          subject: emailSubject,
        },
      });
    }
  }, [entityType, emailBody, emailSubject, name, onHide, onCreateNewEmailTemplate]);

  return (
    <Modal
      cancelText={intl.formatMessage(messages.cancel)}
      className={styles.container}
      okButtonProps={{ disabled: !name || creating, loading: creating }}
      okText={intl.formatMessage(messages.save)}
      onCancel={() => onHide()}
      onOk={handleOk}
      open
      title={intl.formatMessage(messages.saveNewTemplate)}
    >
      <TextField
        className={styles.textField}
        label={intl.formatMessage(messages.templateName)}
        onChange={setName}
        placeholder={intl.formatMessage(messages.templateName)}
        value={name}
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  creating: isNewEmailTemplateCreating(state),
  entityType: getEmailRecipientsEntityType(state),
});

const mapDispatchToProps = {
  onCreateNewEmailTemplate: createNewEmailTemplate.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplateModal);
