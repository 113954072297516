import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import CustomerFaceTimeData from "@app/scene/dashboard/store/cards/customerFaceTime/CustomerFaceTimeData";
import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import CustomerFaceTimeCardConfiguration from "@app/types/dashboard/cards/customerFaceTime/CustomerFaceTimeCardConfiguration";
import Scope from "@app/types/dashboard/Scope";

export const fetchCustomerFaceTimeCardData = createAction(
  "@dashboard/fetchCustomerFaceTimeCardData"
)<{
  callback: (data: CustomerFaceTimeData) => void;
  configuration: CustomerFaceTimeCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showCustomerFaceTimeDrillDown = createAsyncAction(
  "@dashboard/showCustomerFaceTimeCardDrillDownModal/request",
  "@dashboard/showCustomerFaceTimeCardDrillDownModal/success",
  "@dashboard/showCustomerFaceTimeCardDrillDownModal/failure"
)<
  {
    configuration: CustomerFaceTimeCardConfiguration;
    customDateRange?: DateRange;
    customReliability?: boolean;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideCustomerFaceTimeDrillDown = createAction(
  "@dashboard/hideCustomerFaceTimeCardDrillDownModal"
)<void>();

export const fetchCustomerFaceTimeCardDrillDownData = createAsyncAction(
  "@dashboard/fetchCustomerFaceTimeCardDrillDownData/request",
  "@dashboard/fetchCustomerFaceTimeCardDrillDownData/success",
  "@dashboard/fetchCustomerFaceTimeCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Activity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyCustomerFaceTimeDrillDownListViewSettings = createAction(
  "@dashboard/applyCustomerFaceTimeDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportCustomerFaceTimeCardDrillDownData = createAction(
  "@dashboard/exportCustomerFaceTimeCardData"
)<{
  configuration: CustomerFaceTimeCardConfiguration;
  scope: Scope;
}>();

export type CustomerFaceTimeCardActions = ActionType<
  | typeof applyCustomerFaceTimeDrillDownListViewSettings
  | typeof exportCustomerFaceTimeCardDrillDownData
  | typeof fetchCustomerFaceTimeCardData
  | typeof fetchCustomerFaceTimeCardDrillDownData
  | typeof hideCustomerFaceTimeDrillDown
  | typeof showCustomerFaceTimeDrillDown
>;
