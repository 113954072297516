import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faCog } from "@fortawesome/pro-solid-svg-icons/faCog";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import AntDMenu, { MenuProps } from "antd/es/menu";
import cn from "classnames";
import { useIntl } from "react-intl";

import { showGlobalCreateActivityModal } from "@app/component/createEditEntity/Activity/store/actions";
import { showGlobalCreateCompanyModal } from "@app/component/createEditEntity/CreateCompanyModal/store/actions";
import { showGlobalCreateDealModal } from "@app/component/createEditEntity/CreateDealModal/store/actions";
import { showGlobalCreatePersonModal } from "@app/component/createEditEntity/CreatePersonModal/store/actions";
import Notifications from "@app/component/NavigationBar/component/Notifications";
import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import { doesUserHaveAccessToImports } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import { navbarRightMenuClick } from "@app/util/analytic/navbarAnalytics";

import menuStyles from "./CommonMenu.module.scss";
import MenuItemTitle from "./MenuItemTitle";
import styles from "./RightMenu.module.scss";

interface Props {
  doesUserHaveAccessToImports: boolean;
  onShowCreateActivityModal: typeof showGlobalCreateActivityModal;
  onShowCreateCompanyModal: typeof showGlobalCreateCompanyModal;
  onShowCreateDealModal: typeof showGlobalCreateDealModal;
  onShowCreatePersonModal: typeof showGlobalCreatePersonModal;
}

const RightMenu: React.FC<Props> = ({
  doesUserHaveAccessToImports,
  onShowCreateActivityModal,
  onShowCreateCompanyModal,
  onShowCreateDealModal,
  onShowCreatePersonModal,
}) => {
  const intl = useIntl();

  const handleNavigate: MenuProps["onSelect"] = useCallback((info) => {
    navbarRightMenuClick(info!.key);
  }, []);

  const showCreateCompanyModal = useCallback(() => {
    onShowCreateCompanyModal({ showPostCreatePrompt: true });
  }, [onShowCreateCompanyModal]);

  const showCreatePersonModal = useCallback(() => {
    onShowCreatePersonModal({ showPostCreatePrompt: true });
  }, [onShowCreatePersonModal]);

  const showCreateDealModal = useCallback(() => {
    onShowCreateDealModal({ showPostCreatePrompt: true });
  }, [onShowCreateDealModal]);

  return (
    <AntDMenu
      className={cn(menuStyles.menu, styles.menu)}
      mode="horizontal"
      onSelect={handleNavigate}
      triggerSubMenuAction="click"
    >
      <AntDMenu.SubMenu
        className={menuStyles.topItem}
        key="add"
        popupClassName={menuStyles.submenu}
        popupOffset={[16, 0]}
        title={<MenuItemTitle icon={faPlus} />}
      >
        <AntDMenu.Item key="addCompany" onClick={showCreateCompanyModal}>
          {intl.formatMessage({
            id: "navbar.rightMenu.addCompany",
            defaultMessage: "Add Company",
            description: "Title of the Add Company plus item",
          })}
        </AntDMenu.Item>
        <AntDMenu.Item key="addPerson" onClick={showCreatePersonModal}>
          {intl.formatMessage({
            id: "navbar.rightMenu.addPerson",
            defaultMessage: "Add Person",
            description: "Title of the Add Person plus item",
          })}
        </AntDMenu.Item>
        <AntDMenu.Item key="addDeal" onClick={showCreateDealModal}>
          {intl.formatMessage({
            id: "navbar.rightMenu.addDeal",
            defaultMessage: "Add Deal",
            description: "Title of the Add Deal plus item",
          })}
        </AntDMenu.Item>
        <AntDMenu.Item key="addActivity" onClick={() => onShowCreateActivityModal(undefined)}>
          {intl.formatMessage({
            id: "navbar.rightMenu.addActivity",
            defaultMessage: "Add Activity",
            description: "Title of the Add Activity plus item",
          })}
        </AntDMenu.Item>
        {doesUserHaveAccessToImports && <AntDMenu.Divider />}
        {doesUserHaveAccessToImports && (
          <AntDMenu.Item key="import">
            <MenuItemTitle path={`${Path.SETTINGS}/${SettingPath.DATA_NEW_IMPORT}`}>
              {intl.formatMessage({
                id: "navbar.rightMenu.import",
                defaultMessage: "Import",
                description: "Title of the Import plus item",
              })}
            </MenuItemTitle>
          </AntDMenu.Item>
        )}
      </AntDMenu.SubMenu>
      <AntDMenu.Item className={menuStyles.topItem} key="notifications">
        <Notifications />
      </AntDMenu.Item>
      <AntDMenu.Item className={menuStyles.topItem} key="settings">
        <MenuItemTitle icon={faCog} path={Path.SETTINGS} />
      </AntDMenu.Item>
    </AntDMenu>
  );
};

export const mapStateToProps = (state: RootState) => ({
  doesUserHaveAccessToImports: doesUserHaveAccessToImports(state),
});

const mapDispatchToProps = {
  onShowCreateActivityModal: showGlobalCreateActivityModal,
  onShowCreateCompanyModal: showGlobalCreateCompanyModal,
  onShowCreateDealModal: showGlobalCreateDealModal,
  onShowCreatePersonModal: showGlobalCreatePersonModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
