import React, { useCallback } from "react";

import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { defineMessages, useIntl } from "react-intl";

import useAnalytics from "@app/util/contexts/useAnalytics";

import { useMap } from "../../utils";

import ToolButton from "./ToolButton";

const messages = defineMessages({
  zoomIn: {
    id: "map.controls.zoom.zoomIn",
    defaultMessage: "Zoom In",
    description: "Zoom In button tooltip",
  },
  zoomOut: {
    id: "map.controls.zoom.zoomOut",
    defaultMessage: "Zoom Out",
    description: "Zoom Out button tooltip",
  },
});

interface Props {
  streetView?: boolean;
}

const ZoomButtons: React.FC<Props> = ({ streetView }) => {
  const intl = useIntl();
  const { map } = useMap();
  const analytics = useAnalytics();

  const handleZoomIn = useCallback(() => {
    if (streetView) {
      const streetView = map.getStreetView();
      streetView.setZoom(streetView.getZoom() + 1);
    } else {
      map.setZoom(map.getZoom()! + 1);
    }
    analytics.clicked(["Zoom In"]);
  }, [analytics, map, streetView]);

  const handleZoomOut = useCallback(() => {
    if (streetView) {
      const streetView = map.getStreetView();
      streetView.setZoom(streetView.getZoom() - 1);
    } else {
      map.setZoom(map.getZoom()! - 1);
    }
    analytics.clicked(["Zoom Out"]);
  }, [analytics, map, streetView]);

  return (
    <>
      <ToolButton
        icon={faPlus}
        onClick={handleZoomIn}
        title={intl.formatMessage(messages.zoomIn)}
      />
      <ToolButton
        icon={faMinus}
        onClick={handleZoomOut}
        title={intl.formatMessage(messages.zoomOut)}
      />
    </>
  );
};

export default ZoomButtons;
