import { defineMessages } from "react-intl";

const messages = defineMessages({
  featuresOk: {
    id: "settings.emailPreferences.disconnectedEmail.disconnectedEmail.InfoModal.ok",
    defaultMessage: "Okay",
    description: "Email Feature modal ok",
  },
  featuresPoint1Description: {
    id: "email.emailFeatureModal.point1Description",
    defaultMessage:
      "In order to protect accounts from being spammed, you have a daily sending limit of 700 emails per day and 100 emails per hour.",
    description: "Email Feature modal point 1 description",
  },
  featuresPoint1Title: {
    id: "email.emailFeatureModal.point1Title",
    defaultMessage: "Sending Limits",
    description: "Email Feature modal point 1 title",
  },
  featuresPoint2Description: {
    id: "email.emailFeatureModalPoint2Description",
    defaultMessage:
      "Each email sent will automatically generate an activity for you! We’re happy to save you some time with admin tasks so you can get back to doing what’s important.",
    description: "Email Feature modal point 2 description",
  },
  featuresPoint2Title: {
    id: "email.emailFeatureModalPoint2Title",
    defaultMessage: "Auto email activities",
    description: "Email Feature modal point 2 title",
  },
  featuresPoint3Description: {
    id: "email.emailFeatureModalPoint3Description",
    defaultMessage:
      "Every email sent through MMC will track the number of opens and clicks on links and attachments inside the email. Tracking preferences can be managed in settings.",
    description: "Email Feature modal point 3 description",
  },
  featuresPoint3Title: {
    id: "email.emailFeatureModalPoint3Title",
    defaultMessage: "Email tracking",
    description: "Email Feature modal point 3 title",
  },
  featuresPoint4Description: {
    id: "email.emailFeatureModalPoint4Description",
    defaultMessage:
      "Emails that cannot be sent will be added to the queue This can happen when either 1) it is outside the sending hours or 2) the max limit has been reached.",
    description: "Email Feature modal point 4 description",
  },
  featuresPoint4Description2: {
    id: "email.emailFeatureModalPoint4Description2",
    defaultMessage:
      "Clearing the queue cancels all unsent emails, leaving the queue empty and canceling all activities that are scheduled to be created.",
    description: "Email Feature modal point 4 description - part 2",
  },
  featuresPoint4Title: {
    id: "email.emailFeatureModalPoint4Title",
    defaultMessage: "Email Queue",
    description: "Email Feature modal point 4 title",
  },
  featuresTitle: {
    id: "email.emailFeatureModalTitle",
    defaultMessage: "About MMC Emails",
    description: "Email Feature modal title",
  },
  openEmailQueue: {
    id: "email.emailFeatureModal.openEmailQueue",
    defaultMessage: "Open Email Queue",
    description: "Email Feature modal open email queue",
  },
});

export default messages;
