import React from "react";
import { connect } from "react-redux";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "antd/es/popover";
import Tag from "antd/es/tag";
import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";

import OrganizationSetting from "@app/enum/OrganizationSetting";
import { areCheckInFieldsVisible, getOrganizationSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import getFormattedDate from "@app/util/activity/getFormattedDate";
import getOverdueStatus from "@app/util/activity/getOverdueStatus";
import { formatRawDate } from "@app/util/formatters";

import useView from "../view/utils/useView";

import styles from "./ActivityDate.module.scss";
import { RecurringTooltip } from "./RecurringTooltip";

const messages = defineMessages({
  allDay: {
    id: "activityDate.allDay",
    defaultMessage: "All day",
    description: "All day suffix for date tag",
  },
  completed: {
    id: "activityDate.completed",
    defaultMessage: "Marked done on:",
    description: "Completed label date tag tooltip",
  },
  createdAt: {
    id: "activityDate.createdAt",
    defaultMessage: "Created on {date}",
    description: "Created at label for displaying an additional date if sorted",
  },
  notVerifiedCheckIn: {
    id: "activityDate.notVerifiedCheckIn",
    defaultMessage: "Unverified check-in",
    description: "Check-in unverified label",
  },
  updatedAt: {
    id: "activityDate.updatedAt",
    defaultMessage: "Last modified on {date}",
    description: "Updated at label for displaying an additional date if sorted",
  },
  verifiedCheckIn: {
    id: "activityDate.verifiedCheckIn",
    defaultMessage: "Verified check-in",
    description: "Check-in verified label",
  },
  verifiedCheckInDescription: {
    id: "activityDate.verifiedCheckIn.description",
    defaultMessage:
      "A check-in visit is considered “Verified” if the assigned rep’s location was within {radius}m of the activity location provided",
    description: "A message for displaying verified checkin description",
  },
  verifiedCheckInTitle: {
    id: "activityDate.verifiedCheckIn.title",
    defaultMessage: "Verified check-in",
    description: "A message for displaying verified checkin title",
  },
});

interface Props {
  activity: Activity;
  checkInRadius: number;
  checkInVisible: boolean;
}

const ActivityDate: React.FC<Props> = ({ activity, checkInRadius, checkInVisible }) => {
  const { viewState } = useView();
  const dateFormat = activity.allDay ? "PPP" : "PPPp";
  let tagColor = undefined;
  const hasBothStartAndEnd = activity.startAt && activity.endAt;
  const isOverdue = getOverdueStatus(activity);
  if (isOverdue) {
    tagColor = "error";
  }

  const intl = useIntl();
  const sortFieldName = viewState?.sort[0]?.field?.name;
  const sortFieldMessage = messages[sortFieldName as keyof typeof messages];

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Popover
          content={
            <div className={styles.completedTooltipWrapper}>
              {hasBothStartAndEnd && (
                <div className={styles.durationWrapper}>
                  <span className={styles.date}>{formatRawDate(activity.startAt, dateFormat)}</span>
                  <span className={styles.date}>— {formatRawDate(activity.endAt, dateFormat)}</span>
                  {activity.allDay && (
                    <span className={styles.date}>{intl.formatMessage(messages.allDay)}</span>
                  )}
                </div>
              )}
              {activity.completedAt && (
                <>
                  <span className={styles.completedLabel}>
                    {intl.formatMessage(messages.completed)}
                  </span>
                  <span className={styles.date}>
                    {formatRawDate(activity.completedAt, dateFormat)}
                  </span>
                </>
              )}
            </div>
          }
          placement="bottom"
          trigger={activity.completedAt || hasBothStartAndEnd ? ["hover"] : []}
        >
          <Tag className={styles.tag} color={tagColor}>
            {intl.formatMessage(
              {
                id: "activityDate.dateLabel",
                defaultMessage:
                  "{showDate, select, true {{dateString} {allDay, select, true {(All day)} other {}}} other {Not scheduled}}",
                description: "A message for displaying date under different conditions",
              },
              {
                allDay: activity.allDay,
                dateString: <span className={styles.date}>{getFormattedDate(intl, activity)}</span>,
                showDate: !!activity.startAt || activity.completed,
              }
            )}
            <RecurringTooltip recurInterval={activity.recurInterval} />
          </Tag>
        </Popover>
        {sortFieldMessage && (sortFieldName === "updatedAt" || sortFieldName === "createdAt") && (
          <span className={styles.sortFieldMessage}>
            {intl.formatMessage(sortFieldMessage, {
              date: formatRawDate(activity[sortFieldName], "PPP"),
            })}
          </span>
        )}
        {checkInVisible && activity.reliability !== null && (
          <Popover
            content={
              <div className={styles.checkInDescription}>
                {intl.formatMessage(messages.verifiedCheckInDescription, { radius: checkInRadius })}
              </div>
            }
            placement="bottom"
            title={intl.formatMessage(messages.verifiedCheckInTitle)}
            trigger={["hover"]}
          >
            <span className={cn(styles.checkIn, { [styles.notVerified]: !activity.reliability })}>
              <FontAwesomeIcon icon={faCheckCircle} />
              {intl.formatMessage(
                activity.reliability ? messages.verifiedCheckIn : messages.notVerifiedCheckIn
              )}
            </span>
          </Popover>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  checkInRadius: getOrganizationSettingValue(state)(OrganizationSetting.CHECK_IN_RADIUS, 100),
  checkInVisible: areCheckInFieldsVisible(state),
});

export default connect(mapStateToProps)(ActivityDate);
