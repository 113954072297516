import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";

import SchedulerViewState from "@app/component/view/SchedulerView/types/SchedulerViewState";
import { FetcherPayload } from "@app/types/viewModel/Fetcher";

export const initializeSchedulerView = createAsyncAction(
  "activitiesScheduler/initialize/request",
  "activitiesScheduler/initialize/success",
  "activitiesScheduler/initialize/failure"
)<void, void, void>();

export const fetchSchedulerActivities = createAsyncAction(
  "activitiesScheduler/fetch/request",
  "activitiesScheduler/fetch/success",
  "activitiesScheduler/fetch/failure"
)<
  FetcherPayload<Activity>,
  {
    activities: Activity[];
    totalFilteredRecords: number;
    totalRecords: number;
  },
  void
>();

export const applySchedulerViewSettings = createAction("activitiesScheduler/applyViewSettings")<
  Partial<SchedulerViewState>
>();

export const updateActivityInList = createAction(
  "activitiesScheduler/updateActivityInList"
)<Activity>();

export const moveResizeActivity = createAsyncAction(
  "activitiesScheduler/moveResizeActivity/request",
  "activitiesScheduler/moveResizeActivity/success",
  "activitiesScheduler/moveResizeActivity/failure"
)<{ activity: Activity; failCallback?: (activity: Activity) => void }, Activity, void>();

export const exportSchedulerActivities = createAction("activitiesScheduler/exportActivities")<{
  viewState: SchedulerViewState;
}>();

export type Actions = ActionType<
  | typeof applySchedulerViewSettings
  | typeof exportSchedulerActivities
  | typeof fetchSchedulerActivities
  | typeof initializeSchedulerView
  | typeof moveResizeActivity
  | typeof updateActivityInList
>;
