import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import {
  EntitiesSupportingFiles,
  EntityTypeSupportingFiles,
} from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";

export const fetchPreview = createAsyncAction(
  "@component/filePreview/fetchPreview/request",
  "@component/filePreview/fetchPreview/success",
  "@component/filePreview/fetchPreview/failure"
)<
  {
    entityId: EntitiesSupportingFiles["id"];
    entityType: EntityTypeSupportingFiles;
    fileId: RawFile["id"];
  },
  Blob,
  void
>();

export const downloadFile = createAction("@component/filePreview/downloadFile")<{
  entityId: EntitiesSupportingFiles["id"];
  entityType: EntityTypeSupportingFiles;
  file: RawFile;
}>();

export const removeFile = createAsyncAction(
  "@component/filePreview/removeFile/request",
  "@component/filePreview/removeFile/success",
  "@component/filePreview/removeFile/failure"
)<
  {
    callback?: (file: RawFile) => void;
    entityId: EntitiesSupportingFiles["id"];
    entityType: EntityTypeSupportingFiles;
    file: RawFile;
  },
  RawFile["id"],
  void
>();

export type Actions = ActionType<typeof downloadFile | typeof fetchPreview | typeof removeFile>;
