import { defineMessage, MessageDescriptor } from "react-intl";

const inaccessibleEntityAlertMessage: MessageDescriptor = defineMessage({
  id: "createEditActivityModal.header.inaccessibleEntityAlert",
  defaultMessage:
    "The assignee does not have access to the selected Company, Person and/or Deal. To ensure the assignee can perform this activity, please select records to which they have access.",
  description: "Alert about inaccessible entity in create/edit activity modals",
});

export default inaccessibleEntityAlertMessage;
