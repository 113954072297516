import { csvDateFormatter } from "@app/util/formatters";

import DateTimeField from "./DateTimeField";
import { FieldProperties } from "./Field";
import { dateFormatter } from "./fieldUtil";

class DateField extends DateTimeField {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      csvExportProperties: { valueFormatter: csvDateFormatter },
      valueFormatter: dateFormatter,
      ...data,
    });
  }
}

export default DateField;
