enum SchemaFieldCategory {
  ADDRESS = "address",
  CUSTOM = "custom-field",
  FILE = "file",
  NOTE = "note",
  RELATIONSHIP = "relationship",
  STANDARD = "standard",
  SYSTEM_DEPENDENT = "system-dependent",
  SYSTEM_REQUIRED = "system-required",
  VARIANT = "variant",
  // Platform claims these two are possible, but I don't think they are ever returned by the /schema endpoint
  // https://mapmycustomers.slack.com/archives/C04719GK2HG/p1709202738847869?thread_ts=1709202124.038129&cid=C04719GK2HG
  GROUP = "group",
  // DIVIDER = "divider",
}

export default SchemaFieldCategory;
