import notification from "antd/es/notification";
import { put, select, takeLatest } from "redux-saga/effects";

import MapTool from "@mapmycustomers/shared/enum/map/MapTool";
import { GeoPoint } from "@mapmycustomers/shared/types";
import Located, {
  GeocodeResult,
  GeoManagementState,
} from "@mapmycustomers/shared/types/base/Located";
import {
  Company,
  EntityType,
  EntityWithLocation,
  Person,
} from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import {
  convertCoordinatesToGeoPoint,
  createCoordinates,
} from "@mapmycustomers/shared/util/geo/GeoService";

import i18nService from "@app/config/I18nService";
import MapMode from "@app/scene/map/enums/MapMode";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import { EMPTY_ADDRESS } from "@app/util/consts";
import locatedToAddress from "@app/util/locatedToAddress";

import {
  fetchGroupPins,
  fetchPins,
  fetchTerritoryPins,
  selectMapTool,
  setPinHover,
} from "../actions";
import { getMapMode, getPinLocationPoints, isEntityWithoutLocation } from "../selectors";

import {
  enterPinLocationMode,
  exitPinLocationMode,
  geocodePinLocation,
  updatePinLocation,
} from "./actions";
import {
  getPinLocationAddress,
  getPinLocationEntity,
  getPinLocationLocated,
  isPinAddressChanged,
  isPinCoordinatesChanged,
} from "./selectors";

export function* onEnterPinLocationMode({
  payload: { entityWithoutLocation },
}: ReturnType<typeof enterPinLocationMode>) {
  yield put(selectMapTool({ mapTool: MapTool.PIN_LOCATION }));
  yield put(setPinHover(undefined));
  if (entityWithoutLocation) {
    const coordinates: GeoPoint["coordinates"] = yield select(getPinLocationPoints);
    yield put(
      geocodePinLocation.request(
        createCoordinates({
          latitude: coordinates[1],
          longitude: coordinates[0],
        })
      )
    );
  }
}

export function* onExitPinLocationMode() {
  yield put(selectMapTool(undefined));
}

export function* onGeocodePinLocation({ payload }: ReturnType<typeof geocodePinLocation.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const initialAddress: string = yield select(getPinLocationAddress);

    if (payload) {
      const result: GeocodeResult = yield callApi(
        "reverseGeocodeAddress",
        orgId,
        convertCoordinatesToGeoPoint(payload)
      );

      if (result.status === google.maps.GeocoderStatus.OK) {
        const located: Located = {
          ...result.address,
          geoAddress: result.address,
          geoCodedAt: null,
          geoCodeType: null,
          geoManagementState: GeoManagementState.MANUAL,
          geoPoint: convertCoordinatesToGeoPoint(payload),
          geoSource: null,
          geoStatus: null,
        };

        yield put(
          geocodePinLocation.success({
            addressChanged: result.address.address !== initialAddress,
            located,
          })
        );

        let pinLocationAddress = result.address.formattedAddress ?? "";
        if (!pinLocationAddress && result.geoPoint?.coordinates) {
          pinLocationAddress = `(${result.geoPoint.coordinates.join(", ")})`;
        }
      } else {
        yield put(geocodePinLocation.failure());
      }
    } else {
      yield put(geocodePinLocation.success({}));
    }
  } catch (error) {
    yield put(geocodePinLocation.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdatePinLocation({ payload }: ReturnType<typeof updatePinLocation.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const intl = i18nService.getIntl();

    const entity: EntityWithLocation = yield select(getPinLocationEntity);
    const coordinatesChanged: boolean = yield select(isPinCoordinatesChanged);
    const addressChanged: boolean = yield select(isPinAddressChanged);
    const located: Located = yield select(getPinLocationLocated);
    const entityWithoutLocation: boolean | undefined = yield select(isEntityWithoutLocation);

    if (entity && (coordinatesChanged || addressChanged || entityWithoutLocation)) {
      switch (entity.entity) {
        case EntityType.COMPANY: {
          const updatedCompany = {
            id: entity.id,
            ...EMPTY_ADDRESS,
            ...locatedToAddress(located),
            geoAddress: located.geoAddress,
            geoCodedAt: located.geoCodedAt ?? null,
            geoCodeType: located.geoCodeType ?? null,
            geoManagementState: located.geoManagementState,
            geoPoint: located.geoPoint ?? null,
            geoSource: located.geoSource ?? null,
            geoStatus: located.geoStatus ?? null,
          };

          const company: Company = yield callApi(
            "updateCompany",
            orgId,
            undefined, // undefined since we're doing a partial update
            updatedCompany
          );
          yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [company] }));

          if (intl) {
            notification.success({
              message: intl.formatMessage({
                id: "map.tool.pinLocation.updateNotification.company",
                defaultMessage: "Company updated successfully",
                description: "Text of notification when company updated successfully",
              }),
            });
          }

          break;
        }

        case EntityType.PERSON: {
          const updatedPerson = {
            id: entity.id,
            ...EMPTY_ADDRESS,
            ...locatedToAddress(located),
            geoAddress: located.geoAddress,
            geoCodedAt: located.geoCodedAt ?? null,
            geoCodeType: located.geoCodeType ?? null,
            geoManagementState: located.geoManagementState,
            geoPoint: located.geoPoint ?? null,
            geoSource: located.geoSource ?? null,
            geoStatus: located.geoStatus ?? null,
          };

          const person: Person = yield callApi(
            "updatePerson",
            orgId,
            undefined, // undefined since we're doing a partial update
            updatedPerson
          );
          yield put(notifyAboutChanges({ entityType: EntityType.PERSON, updated: [person] }));

          if (intl) {
            notification.success({
              message: intl.formatMessage({
                id: "map.tool.pinLocation.updateNotification.person",
                defaultMessage: "Person updated successfully",
                description: "Text of notification when person updated successfully",
              }),
            });
          }

          break;
        }
      }
    }

    yield put(updatePinLocation.success());

    const mode: MapMode = yield select(getMapMode);
    switch (mode) {
      case MapMode.GROUPS:
        yield put(fetchGroupPins.request({ request: {} }));
        break;

      case MapMode.TERRITORIES:
        yield put(fetchTerritoryPins.request({ request: {} }));
        break;

      default:
        yield put(fetchPins.request({ request: {} }));
    }

    if (payload?.exitMode) {
      yield put(exitPinLocationMode());
    }
  } catch (error) {
    yield put(updatePinLocation.failure());
    yield put(handleError({ error }));
  }
}

export function* pinLocationSagas() {
  yield takeLatest(enterPinLocationMode, onEnterPinLocationMode);
  yield takeLatest(exitPinLocationMode, onExitPinLocationMode);
  yield takeLatest(geocodePinLocation.request, onGeocodePinLocation);
  yield takeLatest(updatePinLocation.request, onUpdatePinLocation);
}
