import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Tooltip from "antd/es/tooltip";

import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import CalculatedFieldInfoIcon from "@app/component/CalculatedFieldInfoIcon";
import EmptyValue from "@app/component/FieldGrid/components/EmptyValue";
import ComponentRegistry from "@app/component/FieldGrid/utils/ComponentRegistry";
import { isCalculatedField } from "@app/util/fieldModel/impl/assert";

import styles from "./Field.module.scss";

interface Props {
  className?: string;
  entity: AnyEntity;
  field: IField;
  showEmpty?: boolean;
}

const Field: React.FC<Props> = ({ entity, field, showEmpty }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasNameTooltip, setHasNameTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (ref?.current) {
      setHasNameTooltip(ref.current.offsetWidth < ref.current.scrollWidth);
    }
  }, [ref, setHasNameTooltip]);

  const isNonEmptyValue = field.hasNonEmptyValueFor(entity);
  const isVisible = !!showEmpty || isNonEmptyValue;

  let value;
  if (isNonEmptyValue) {
    const Component = ComponentRegistry.getComponent(field, entity);
    if (Component) {
      value = <Component entity={entity} field={field} />;
    } else {
      value = field.getFormattedValueFor(entity);
    }
  } else if (isVisible) {
    value = <EmptyValue />;
  }

  const handleClick = useCallback((ev) => ev.stopPropagation(), []);

  const calculatedFieldInfo = useMemo(
    () =>
      isCalculatedField(field) ? (
        <span className={styles.calculatedFieldInfo}>
          <CalculatedFieldInfoIcon customField={field.customFieldData} />
        </span>
      ) : null,
    [field]
  );

  return isVisible ? (
    <>
      <div className={styles.name}>
        {hasNameTooltip ? (
          <Tooltip
            className={styles.nameWrapper}
            mouseLeaveDelay={0}
            placement="top"
            title={field.displayName}
          >
            <span ref={ref}>
              <span>{field.displayName}</span>
              {calculatedFieldInfo}
            </span>
          </Tooltip>
        ) : (
          <span className={styles.nameWrapper} ref={ref}>
            <span>{field.displayName}</span>
            {calculatedFieldInfo}
          </span>
        )}
      </div>

      <div className={styles.value} onClick={handleClick}>
        {value}
      </div>
    </>
  ) : null;
};

export default Field;
