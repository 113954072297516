import { differenceInDays, differenceInMinutes } from "date-fns/esm";

import roundToPrecision from "@app/util/number/roundToPrecision";

type Unit = Parameters<Intl.RelativeTimeFormat["format"]>[1];

const getRelativeTimeValueAndUnit = (date?: Date): [value: number, unit: Unit] | undefined => {
  if (!date) {
    return undefined;
  }

  const diffInMinutes = differenceInMinutes(date, Date.now());
  const diffInDays = differenceInDays(date, Date.now());

  let value = diffInMinutes;
  let unit: Unit = "minutes";

  if (diffInDays !== 0) {
    unit = "days";
    value = diffInDays;
  } else if (Math.abs(diffInMinutes) >= 60) {
    unit = "hours";
    value = roundToPrecision(diffInMinutes / 60, 1);
  }

  return [value, unit];
};

export default getRelativeTimeValueAndUnit;
