import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { MessageDescriptor } from "@formatjs/intl/src/types";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";

import Address from "@mapmycustomers/shared/types/Address";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { EntityWithLocation } from "@mapmycustomers/shared/types/entity";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import geoService, {
  convertCoordinatesToLongLat,
} from "@mapmycustomers/shared/util/geo/GeoService";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { Alert, AutoCompleteAddress, Labeled } from "@mapmycustomers/ui";

import poweredByGoogle from "@app/assets/google/poweredByGoogle.png";
import LocatedEntityAssociation from "@app/component/associations/LocatedEntityAssociation";
import ButtonLink from "@app/component/ButtonLink";
import {
  isAddressAreaSearchQuery,
  isCoordinatesAreaSearchQuery,
  isEntityAreaSearchQuery,
} from "@app/component/input/AreaSearchInput/utils/assert";
import TextWithInfo from "@app/component/typography/TextWithInfo";
import AreaSearchMode from "@app/enum/AreaSearchMode";
import { showEntityView } from "@app/store/entityView/actions";
import { geocodeAddress, reverseGeocodeAddress } from "@app/store/location/actions";
import {
  AddressAreaSearchQuery,
  AreaSearchQuery,
  EntityAreaSearchQuery,
} from "@app/types/filters/AreaSearchQuery";
import { FULL_ADDRESS } from "@app/util/consts";
import useAnalytics from "@app/util/contexts/useAnalytics";
import { entityToAddressLines, getFormattedAddressForUi } from "@app/util/formatters";

import Distance from "./components/Distance";
import styles from "./SearchArea.module.scss";

const TYPES: string[] = ["geocode"];

const messages = defineMessages({
  addLocation: {
    id: "component.searchAreaInput.address.missing",
    defaultMessage: "Please add a location",
    description: "Address validation error",
  },
  addressLabel: {
    id: "component.searchAreaInput.address",
    defaultMessage: "Address",
    description: "Address label",
  },
  [AreaSearchMode.ADDRESS]: {
    id: "component.searchAreaInput.mode..address",
    defaultMessage: "a specific address",
    description: "Your current location label",
  },
  [AreaSearchMode.CURRENT_MAP_VIEW_CENTER]: {
    id: "component.searchAreaInput.mode.currentMapViewCenter",
    defaultMessage: "your current map view",
    description: "Your current map view label",
  },
  [AreaSearchMode.CURRENT_USER_LOCATION]: {
    id: "component.searchAreaInput.mode.currentUserLocation",
    defaultMessage: "your current location",
    description: "Your current location label",
  },
  [AreaSearchMode.ENTITY]: {
    id: "component.searchAreaInput.mode.entity",
    defaultMessage: "a specific Company or Person",
    description: "Your current location label",
  },
  changeRecord: {
    id: "component.searchAreaInput.entity.change",
    defaultMessage: "Change Record",
    description: "Change Record button title",
  },
  dynamicLocationWarning: {
    id: "component.searchAreaInput.dynamicLocationWarning",
    defaultMessage:
      "If this filter is applied later or if your map view changes, the results will be <b>within the radius around the currently displayed address</b>, rather than within the map view at the time the filter was applied.",
    description: "A warning to show for Area Filters",
  },
  locationError: {
    id: "component.searchAreaInput.userLocation.error",
    defaultMessage: "Unable to detect location",
    description: "Unable to detect location label",
  },
  locationErrorHelp: {
    id: "component.searchAreaInput.userLocation.error.help",
    defaultMessage: "Get help",
    description: "Unable to detect location - Get help button label",
  },
  search: {
    id: "component.searchAreaInput.title",
    defaultMessage: "Searching for records {distance} around {location}",
    description: "Area search label",
  },
});

interface Props {
  availableDistances: number[];
  className?: string;
  compact?: boolean;
  customDistances?: number[];
  distance: number;
  dropDownClassName?: string;
  locationClassName?: string;
  mapCenter?: LongLat; // if this prop is not provided, we don't offer the AreaSearchMode.CURRENT_MAP_VIEW_CENTER option
  maxDistance?: number;
  maxNumberOfCustomDistances?: number;
  onCustomDistancesChange?: (distances: number[]) => void;
  onDistanceChange?: (distance: number) => void;
  onGeocodeAddress: typeof geocodeAddress;
  onQueryChange?: (query: AreaSearchQuery) => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  onShowEntityView: typeof showEntityView;
  query?: AreaSearchQuery;
  showDynamicLocationsWarning?: boolean;
  title?: MessageDescriptor;
  userPosition?: GeolocationPosition;
}

const AVAILABLE_MODES = Object.values(AreaSearchMode);

const SearchArea: React.FC<Props> = ({
  availableDistances,
  className,
  compact = false,
  customDistances = [],
  distance,
  dropDownClassName,
  locationClassName,
  mapCenter,
  maxDistance,
  maxNumberOfCustomDistances,
  onCustomDistancesChange,
  onDistanceChange,
  onGeocodeAddress,
  onQueryChange,
  onReverseGeocodeAddress,
  onShowEntityView,
  query,
  showDynamicLocationsWarning,
  title,
  userPosition,
}) => {
  const analytics = useAnalytics();
  const intl = useIntl();

  const [entityPopupVisible, showEntityPopup, hideEntityPopup] = useBoolean();

  const [currentMode, setCurrentMode] = useState<AreaSearchMode | undefined>(query?.mode);

  const [showUserLocationWarning, setShowUserLocationWarning] = useState(!userPosition);

  const [locationText, setLocationText] = useState<string>("");
  useEffect(() => {
    if (query?.mode) {
      setCurrentMode(query.mode);
    }
    if (isCoordinatesAreaSearchQuery(query)) {
      if (query.address) {
        setLocationText(query.address);
      } else {
        setLocationText(query.coordinates.join(", "));
        onReverseGeocodeAddress({
          callback: (result: GeocodeResult) => {
            setLocationText(result.address.formattedAddress ?? "");
          },
          coordinates: query.coordinates,
        });
      }
    } else if (isAddressAreaSearchQuery(query)) {
      setLocationText(query.address);
    } else if (isEntityAreaSearchQuery(query)) {
      const { line1, line2, line3 } = entityToAddressLines(query.entity, FULL_ADDRESS, {
        locationWhenNoAddress: true,
      });
      const text = [line1, line2, line3].filter((line) => !!line).join(", ");
      setLocationText(text);
    }
  }, [query, onReverseGeocodeAddress]);

  const showLocation =
    !!locationText &&
    !showUserLocationWarning &&
    currentMode &&
    [AreaSearchMode.CURRENT_MAP_VIEW_CENTER, AreaSearchMode.CURRENT_USER_LOCATION].includes(
      currentMode
    );

  const handleModeClick = useCallback(
    async (value) => {
      const newMode: AreaSearchMode = value.key;
      if (currentMode === newMode) {
        return;
      }

      analytics.clicked(["Location Type", newMode]);
      setCurrentMode(newMode);
      setLocationText("");

      if (newMode === AreaSearchMode.ENTITY) {
        showEntityPopup();
      } else {
        hideEntityPopup();
      }

      // for these two modes we can call onChange immediately
      // for two others we will wait until user makes a decision
      if (
        newMode === AreaSearchMode.CURRENT_MAP_VIEW_CENTER ||
        newMode === AreaSearchMode.CURRENT_USER_LOCATION
      ) {
        let coordinates: LongLat | undefined;
        if (newMode === AreaSearchMode.CURRENT_USER_LOCATION) {
          try {
            const position = await geoService.getCurrentPosition();
            coordinates = convertCoordinatesToLongLat(position.coords);
            setShowUserLocationWarning(false);
          } catch {
            setShowUserLocationWarning(true);
          }
        } else if (newMode === AreaSearchMode.CURRENT_MAP_VIEW_CENTER && mapCenter) {
          coordinates = mapCenter;
        }

        if (!isDefined(coordinates)) {
          return; // can't proceed without coordinates
        }

        const address = await new Promise<string>((resolve) =>
          onReverseGeocodeAddress({
            callback: (result: GeocodeResult) => resolve(result.address.formattedAddress ?? ""),
            coordinates: coordinates!, // we just checked it's defined
            failureCallback: () => resolve(coordinates!.join(", ")),
          })
        );
        onQueryChange?.({ address, coordinates, mode: newMode });
      }
    },
    [
      analytics,
      hideEntityPopup,
      mapCenter,
      onQueryChange,
      onReverseGeocodeAddress,
      showEntityPopup,
      currentMode,
    ]
  );

  const handleAddressChange = useCallback(
    (address?: Address) => {
      if (!address) {
        return;
      }

      onGeocodeAddress({
        address,
        callback: (result: GeocodeResult) => {
          onQueryChange?.({
            address: getFormattedAddressForUi(address),
            coordinates: result.geoPoint?.coordinates,
            mode: AreaSearchMode.ADDRESS,
          });
        },
        failureCallback: () => {
          onQueryChange?.({
            address: getFormattedAddressForUi(address),
            mode: AreaSearchMode.ADDRESS,
          });
        },
      });
    },
    [onGeocodeAddress, onQueryChange]
  );

  const handleEntityViewClick = useCallback(() => {
    if (isEntityAreaSearchQuery(query)) {
      onShowEntityView({ entityId: query.entity.id, entityType: query.entity.entity });
    }
  }, [query, onShowEntityView]);

  const handleSelectEntity = useCallback(
    (entity: EntityWithLocation) => {
      onQueryChange?.({ entity: entity, mode: AreaSearchMode.ENTITY });
    },
    [onQueryChange]
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.selector}>
        {intl.formatMessage(title ?? messages.search, {
          distance: (
            <Distance
              availableDistances={availableDistances}
              customDistances={customDistances}
              dropDownClassName={dropDownClassName}
              maxNumberOfCustomDistances={maxNumberOfCustomDistances}
              maxValue={maxDistance}
              onChange={onDistanceChange}
              onCustomDistancesChange={onCustomDistancesChange}
              value={distance}
            />
          ),
          location: (
            <Dropdown
              overlay={
                <Menu
                  className={styles.menu}
                  onClick={handleModeClick}
                  selectedKeys={currentMode ? [currentMode] : undefined}
                >
                  {AVAILABLE_MODES.filter(
                    // skip CURRENT_MAP_VIEW_CENTER if mapCenter is not provided
                    // that will happen everywhere except the Map page
                    (mode) => mode !== AreaSearchMode.CURRENT_MAP_VIEW_CENTER || !!mapCenter
                  ).map((key) => (
                    <Menu.Item key={key}>{intl.formatMessage(messages[key])}</Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
            >
              <Button className={cn(styles.btn, dropDownClassName)} type="text">
                {currentMode && intl.formatMessage(messages[currentMode])}
                <FontAwesomeIcon className={styles.arrow} icon={faAngleDown} />
              </Button>
            </Dropdown>
          ),
        })}
      </div>

      {showUserLocationWarning && (
        <Alert
          action={
            <ButtonLink
              className={styles.helpLink}
              href="https://support.mapmycustomers.com/hc/en-us/articles/360062391934-How-to-share-your-location-with-your-web-browser"
              target="_blank"
            >
              {intl.formatMessage(messages.locationErrorHelp)}
            </ButtonLink>
          }
          message={intl.formatMessage(messages.locationError)}
          showIcon
          type="warning"
        />
      )}

      {showLocation && (
        <div className={cn(styles.current, locationClassName)}>
          <TextWithInfo
            info={
              showDynamicLocationsWarning
                ? intl.formatMessage(messages.dynamicLocationWarning, {
                    b: (texts: string[]) => <b>{texts}</b>,
                  })
                : undefined
            }
            placement="bottom"
          >
            {locationText}
          </TextWithInfo>
        </div>
      )}

      {currentMode === AreaSearchMode.ENTITY && (
        <div className={cn(styles.entity, { [styles.compact]: compact })}>
          {(query as EntityAreaSearchQuery).entity && (
            <div className={styles.entityInfo}>
              <div className={styles.entityName}>
                <ButtonLink onClick={handleEntityViewClick}>
                  {(query as EntityAreaSearchQuery).entity?.name ?? ""}
                </ButtonLink>
              </div>

              <div className={styles.entityAddress}>{locationText}</div>
            </div>
          )}

          <div>
            <Button disabled={entityPopupVisible} onClick={showEntityPopup}>
              {intl.formatMessage(messages.changeRecord)}
            </Button>
          </div>

          <LocatedEntityAssociation
            onHide={hideEntityPopup}
            onSelect={handleSelectEntity}
            selectedEntityId={(query as EntityAreaSearchQuery).entity?.id}
            visible={entityPopupVisible}
          />
        </div>
      )}

      {currentMode === AreaSearchMode.ADDRESS && (
        <div className={cn({ [styles.hasFeedback]: !(query as AddressAreaSearchQuery).address })}>
          <Labeled
            className={className}
            extra={<img alt="powered by Google" src={poweredByGoogle} />}
            label={intl.formatMessage(messages.addressLabel)}
            labelClassName={styles.addressLabel}
          >
            <AutoCompleteAddress
              allowClear
              onChange={handleAddressChange}
              onReverseGeocodeAddress={onReverseGeocodeAddress}
              placePredictionTypes={TYPES}
              value={{ address: (query as AddressAreaSearchQuery).address ?? "" }}
            />
          </Labeled>

          {!(query as AddressAreaSearchQuery).address && (
            <div className={styles.feedback}>{intl.formatMessage(messages.addLocation)}</div>
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  onGeocodeAddress: geocodeAddress,
  onReverseGeocodeAddress: reverseGeocodeAddress,
  onShowEntityView: showEntityView,
};

export default connect(null, mapDispatchToProps)(SearchArea);
