import { defineMessages } from "react-intl";

const messages = defineMessages({
  checkInConfirmationCancel: {
    id: "createEditCheckInActivityModal.footer.submit.popconfirm.cancelText",
    defaultMessage: "Wait",
    description: "Wait button on create/edit activity popconfirm",
  },
  checkInConfirmationOk: {
    id: "createEditCheckInActivityModal.footer.submit.popconfirm.okText",
    defaultMessage: "I'm sure",
    description: "Proceed button on create/save checkIn activity popconfirm",
  },
  checkInConfirmationTitle: {
    id: "createEditCheckInActivityModal.footer.submit.popconfirm.title",
    defaultMessage:
      "<strong>Are you sure you want to make this activity{br} unverified?</strong> {br}The attempted changes will remove the {br}“verified” status of this Check-In.",
    description: "Create/Edit activity popconfirm title",
  },
  frequencyCriteriaConfirmationCancel: {
    id: "createEditCheckInActivityModal.footer.frequencyCriteriaConfirmationTitle.popconfirm.cancelText",
    defaultMessage: "Cancel",
    description: "Wait button on create/edit activity popconfirm",
  },
  frequencyCriteriaConfirmationOk: {
    id: "createEditCheckInActivityModal.footer.frequencyCriteriaConfirmationTitle.popconfirm.okText",
    defaultMessage: "Schedule Anyway",
    description: "Proceed button on create/save checkIn activity popconfirm",
  },
  frequencyCriteriaConfirmationTitle: {
    id: "createEditCheckInActivityModal.footer.frequencyCriteriaConfirmationTitle.popconfirm.title",
    defaultMessage:
      "This activity does not meet the set criteria for the frequency set for this record.",
    description: "Create/Edit activity popconfirm title",
  },
  publicConfirmationCancel: {
    id: "createEditActivityModal.footer.submit.public.cancel",
    defaultMessage: "Wait",
    description: "Create/Edit activity confirmation public cancel",
  },
  publicConfirmationContent: {
    id: "createEditActivityModal.footer.submit.public.content",
    defaultMessage:
      "By saving, you'll be sharing this activity with all members of your organization.",
    description: "Create/Edit activity confirmation public content",
  },
  publicConfirmationOk: {
    id: "createEditActivityModal.footer.submit.public.ok",
    defaultMessage: "Got it",
    description: "Create/Edit activity confirmation public ok",
  },
  publicConfirmationTitle: {
    id: "createEditActivityModal.footer.submit.public.title",
    defaultMessage: "Activity marked as Public",
    description: "Create/Edit activity confirmation public title",
  },
  recurConfirmationCancel: {
    id: "createEditActivityModal.footer.submit.recurSubmit.cancelText",
    defaultMessage: "Wait",
    description: "Wait button on create/edit activity recur popconfirm",
  },
  recurConfirmationOk: {
    id: "createEditActivityModal.footer.submit.recurSubmit.okText",
    defaultMessage: "I’m sure",
    description: "Proceed button on create/save activity recur popconfirm",
  },
  recurConfirmationTitle: {
    id: "createEditActivityModal.footer.submit.recurSubmit.title",
    defaultMessage:
      "Are you sure to modify this recurring activity? All other instances of this activity will be changed.",
    description: "Create/Edit activity popconfirm recur title",
  },
  updateMenuAll: {
    id: "editActivityModal.footer.update.popover.menu.all",
    defaultMessage: "All activities (past and future)",
    description: "Save all option from edit modal popover menu",
  },
  updateMenuAssigneeNotCreatorTitle: {
    id: "editActivityModal.footer.update.popover.assigneeNotCreator.title",
    defaultMessage:
      "Please note, updates will only apply to activity instances you are the creator or assignee of.",
    description:
      "Update activity from edit modal popover title where assignee is not the creator of activity",
  },
  updateMenuOnlyThis: {
    id: "editActivityModal.footer.update.popover.menu.onlyThis",
    defaultMessage: "Just this activity",
    description: "Save only this option from edit modal popover menu",
  },
  updateMenuThisAndFuture: {
    id: "editActivityModal.footer.update.popover.menu.thisAndFuture",
    defaultMessage: "This and future activities",
    description: "Save this and future option from edit modal popover menu",
  },
  updateMenuTitle: {
    id: "editActivityModal.footer.update.popover.title",
    defaultMessage: "Apply changes to",
    description: "Update activity from edit modal popover title",
  },
});

export default messages;
