import { createReducer } from "typesafe-actions";

import DateRangeType from "@mapmycustomers/shared/enum/DateRangeType";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { AnyEntity, EntityType } from "@mapmycustomers/shared/types/entity";
import LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import cleanUpFilterModel from "@app/component/filtering/utils/cleanUpFilterModel";
import MapFilter from "@app/scene/reports/enums/MapFilter";
import getDefaultSelectedUsersState from "@app/scene/reports/store/getDefaultSelectedUsersState";
import dateRangeTypeToDateRange from "@app/scene/reports/utils/dateRangeTypeToDateRange";
import { Actions as AppActions } from "@app/store/app/actions";
import Report from "@app/types/Report";
import LeaderboardViewState from "@app/types/viewModel/LeaderboardViewState";
import getColumnsVisibleByDefault from "@app/util/fieldModel/getColumnsVisibleByDefault";
import leaderboardFieldModel from "@app/util/fieldModel/LeaderboardFieldModel";
import standardRangeToDateRange from "@app/util/range/standardRangeToDateRange";

import FilterType from "../components/Export/FilterType";
import ReportsDateRangeType from "../enums/DateRangeType";
import DateRange from "../types/DateRange";
import LeaderboardMetricsData from "../types/LeaderboardMetricsData";
import { DEFAULT_EXPORT_PAGE_SIZE } from "../utils/const";

import {
  Actions,
  applyPreviewViewSettings,
  changeReportDescription,
  changeReportId,
  changeReportName,
  changeReportType,
  deleteReport,
  downloadModalData,
  downloadReport,
  editReport,
  fetchActivityCardData,
  fetchActivityCardListData,
  fetchDealsByStage,
  fetchDealsGeoChartData,
  fetchDealsMapData,
  fetchExportCardData,
  fetchExtraInfo,
  fetchFunnelsStats,
  fetchLeaderboardData,
  fetchLeaderboardMetricsData,
  fetchModalPreviewData,
  fetchOverviewCardData,
  fetchOverviewMapDeals,
  fetchPreviewData,
  fetchReport,
  fetchReportRecordsCountInfo,
  initializeCreateReport,
  saveReport,
  saveWeeklyReport,
  scheduleReport,
  setDateRange,
  setDateRangeType,
  setUserIds,
} from "./actions";
import ActivityCardData from "./ActivityCardData";
import DealsGeoChartData from "./DealsGeoChartData";
import DealsMapData from "./DealsMapData";
import DealsProgressionChartData from "./DealsProgressionChartData";
import ExportCardData from "./ExportCardData";
import FunnelStatsData from "./FunnelStatsData";
import getDefaultDateRangeState from "./getDefaultDateRangeState";
import getDefaultReportPreviewViewState from "./getDefaultReportPreviewViewState";
import OverviewData, { OverviewMapData } from "./OverviewData";
import UsageCardData from "./UsageCardData";

const getNewReport = (): Readonly<Partial<Report>> => ({
  id: 0,
  customFields: [],
  description: "",
  name: "",
  selectedColumns: getColumnsVisibleByDefault(EntityType.COMPANY).map((field) => field.name),
  selectedFilters: {},
  tableName: EntityType.COMPANY,
});

export interface ReportState {
  activityCardData: ActivityCardData;
  dateRange?: DateRange;
  dateRangeType: ReportsDateRangeType;
  dealsGeoChartData: DealsGeoChartData;
  dealsMapData: DealsMapData;
  dealsProgressionChartData: DealsProgressionChartData;
  duplicateDashboardId?: string;
  error: undefined | unknown;
  exportCardData: ExportCardData;
  extraInfo: AnyEntity | undefined;
  funnelStatsData: FunnelStatsData;
  leaderboard: {
    loading?: boolean;
    metaData?: { lastRunAt?: string };
    metricsItems: Partial<Record<LeaderboardMetricFieldName, LeaderboardMetricsData>>;
    metricsLoading?: boolean;
    metricsViewState: {
      teamIds?: Team["id"][];
      userId?: User["id"];
    };
    tableItems: LeaderboardItem[];
    viewState: LeaderboardViewState;
  };
  loading: boolean;
  newDashboardId?: string;
  overviewData: OverviewData;
  overviewMapData: OverviewMapData;
  reportRecordsCountLoading: boolean;
  usageCardData: UsageCardData;
  userIds?: User["id"][];
  weeklyReportSaving: boolean;
}

const initialState: ReportState = {
  activityCardData: {
    activities: [],
    activitiesForMap: [],
    companies: [],
    companiesForMap: [],
    dateRange: dateRangeTypeToDateRange(ReportsDateRangeType.YESTERDAY)!,
    listPageNumber: 0,
    mapFilter: MapFilter.COMPLETED_ACTIVITIES,
    people: [],
    peopleForMap: [],
    pins: [],
    totalActivities: 0,
    totalCompanies: 0,
    totalPeople: 0,
    userIds: [], // this is not very accurate, but since we only use this after fetchActivityCardData is called, we're fine
  },
  ...getDefaultDateRangeState(),
  dealsGeoChartData: {
    dealsCountByHcKey: {},
    loading: false,
  },
  dealsMapData: {
    deals: [],
    loading: false,
    total: 0,
  },
  dealsProgressionChartData: {
    dealsError: undefined,
    dealsLoading: false,
    stageToDealsMap: {},
    stageToDealsTotalMap: {},
  },
  error: undefined,
  exportCardData: {
    currentReport: undefined,
    reportListViewState: {
      columns: [],
      filter: {},
      range: { endRow: DEFAULT_EXPORT_PAGE_SIZE, startRow: 0 },
      sort: [],
      viewAs: undefined,
    },
    reportPreviewError: undefined,
    reportPreviewLoading: false,
    reports: [],
    reportSaving: false,
    reportSavingError: undefined,
    reportTotalRowsCount: 0,
    total: 0,
    viewState: {
      columns: [],
      filter: {},
      range: { endRow: DEFAULT_EXPORT_PAGE_SIZE, startRow: 0 },
      sort: [],
      viewAs: undefined,
    },
  },
  extraInfo: undefined,
  funnelStatsData: {
    loading: false,
    orgStats: {},
    stageSummary: [],
  },
  leaderboard: {
    metricsItems: {},
    metricsViewState: {},
    tableItems: [],
    viewState: {
      activityTypeIds: undefined,
      columns: leaderboardFieldModel.getDefaultListViewState().columns,
      dateRange: standardRangeToDateRange(`${DateRangeType.WEEK}0`)!,
      dateRangeType: `${DateRangeType.WEEK}0`,
      grouping: "team",
      rankingMetric: LeaderboardMetricFieldName.ACTIVITIES_COMPLETED,
      teamIds: undefined,
    },
  },
  loading: false,
  overviewData: {
    activityTypesWithCount: [],
    closedWonDeals: new Map(),
    dealsPerFunnel: [],
    mapFunnelStages: {},
    maxAmountByStageType: {},
    modalPreviews: {
      downloading: false,
      [EntityType.ACTIVITY]: { items: [], total: 0 },
      [EntityType.COMPANY]: { items: [], total: 0 },
      [EntityType.DEAL]: { items: [], total: 0 },
      [EntityType.PERSON]: { items: [], total: 0 },
      [EntityType.ROUTE]: { items: [], total: 0 },
      error: undefined,
      loading: false,
    },
    numDealsLost: 0,
    numDealsWon: 0,
    parentNumDealsLost: 0,
    parentNumDealsWon: 0,
    parentRevenueLost: 0,
    parentRevenueWon: 0,
    parentStatistics: {},
    recordsOverTime: [],
    revenueLost: 0,
    revenueWon: 0,
    statistics: {},
  },
  overviewMapData: {
    deals: [],
    loading: false,
  },
  reportRecordsCountLoading: false,
  usageCardData: {
    locations: [],
  },
  userIds: getDefaultSelectedUsersState(),
  weeklyReportSaving: false,
};

const report = createReducer<ReportState, Actions | AppActions>(initialState)
  .handleAction(fetchFunnelsStats.request, (state) => ({
    ...state,
    funnelStatsData: {
      ...state.funnelStatsData,
      loading: true,
    },
  }))
  .handleAction(fetchFunnelsStats.success, (state, action) => ({
    ...state,
    funnelStatsData: {
      ...state.funnelStatsData,
      ...action.payload,
      loading: false,
    },
  }))
  .handleAction(fetchFunnelsStats.failure, (state) => ({
    ...state,
    funnelStatsData: {
      ...state.funnelStatsData,
      loading: false,
    },
  }))
  .handleAction(fetchExtraInfo.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchExtraInfo.success, (state, action) => ({
    ...state,
    extraInfo: action.payload,
    loading: false,
  }))
  .handleAction(fetchExtraInfo.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(fetchOverviewCardData.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchOverviewCardData.success, (state, action) => ({
    ...state,
    loading: false,
    overviewData: action.payload,
  }))
  .handleAction(fetchOverviewCardData.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(fetchOverviewMapDeals.request, (state) => ({
    ...state,
    overviewMapData: {
      deals: [],
      loading: true,
    },
  }))
  .handleAction(fetchOverviewMapDeals.success, (state, { payload }) => ({
    ...state,
    overviewMapData: {
      deals: payload,
      loading: false,
    },
  }))
  .handleAction(fetchOverviewMapDeals.failure, (state) => ({
    ...state,
    overviewMapData: {
      deals: [],
      loading: false,
    },
  }))
  .handleAction(fetchModalPreviewData.request, (state) => ({
    ...state,
    overviewData: {
      ...state.overviewData,
      modalPreviews: {
        ...state.overviewData.modalPreviews,
        error: undefined,
        loading: true,
      },
    },
  }))
  .handleAction(fetchModalPreviewData.success, (state, { payload }) => ({
    ...state,
    overviewData: {
      ...state.overviewData,
      modalPreviews: {
        downloading: false,
        [EntityType.ACTIVITY]:
          payload[EntityType.ACTIVITY] ?? state.overviewData.modalPreviews[EntityType.ACTIVITY],
        [EntityType.COMPANY]:
          payload[EntityType.COMPANY] ?? state.overviewData.modalPreviews[EntityType.COMPANY],
        [EntityType.DEAL]:
          payload[EntityType.DEAL] ?? state.overviewData.modalPreviews[EntityType.DEAL],
        [EntityType.PERSON]:
          payload[EntityType.PERSON] ?? state.overviewData.modalPreviews[EntityType.PERSON],
        [EntityType.ROUTE]:
          payload[EntityType.ROUTE] ?? state.overviewData.modalPreviews[EntityType.ROUTE],
        error: undefined,
        loading: false,
      },
    },
  }))
  .handleAction(fetchModalPreviewData.failure, (state, action) => ({
    ...state,
    overviewData: {
      ...state.overviewData,
      modalPreviews: {
        ...state.overviewData.modalPreviews,
        error: action.payload,
        loading: false,
      },
    },
  }))
  .handleAction(downloadModalData.request, (state) => ({
    ...state,
    overviewData: {
      ...state.overviewData,
      modalPreviews: {
        ...state.overviewData.modalPreviews,
        downloading: true,
        error: undefined,
      },
    },
  }))
  .handleAction(downloadModalData.success, (state) => ({
    ...state,
    overviewData: {
      ...state.overviewData,
      modalPreviews: {
        ...state.overviewData.modalPreviews,
        downloading: false,
        error: undefined,
      },
    },
  }))
  .handleAction(downloadModalData.failure, (state, action) => ({
    ...state,
    overviewData: {
      ...state.overviewData,
      modalPreviews: {
        ...state.overviewData.modalPreviews,
        downloading: false,
        error: action.payload,
      },
    },
  }))
  .handleAction(fetchActivityCardData.request, (state, { payload }) => ({
    ...state,
    activityCardData: {
      ...state.activityCardData,
      ...payload,
      bounds:
        payload.mapFilter && payload.mapFilter !== state.activityCardData.mapFilter
          ? undefined
          : payload.bounds ?? state.activityCardData.bounds,
    },
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchActivityCardData.success, (state, action) => ({
    ...state,
    activityCardData: {
      ...state.activityCardData,
      ...action.payload,
    },
    loading: false,
  }))
  .handleAction(fetchActivityCardData.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(fetchActivityCardListData.request, (state, { payload }) => ({
    ...state,
    activityCardData: {
      ...state.activityCardData,
      listPageNumber: payload,
    },
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchActivityCardListData.success, (state, action) => ({
    ...state,
    activityCardData: {
      ...state.activityCardData,
      ...action.payload,
    },
    loading: false,
  }))
  .handleAction(fetchActivityCardListData.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(fetchDealsMapData.request, (state) => ({
    ...state,
    dealsMapData: {
      ...state.dealsMapData,
      loading: true,
    },
  }))
  .handleAction(fetchDealsMapData.success, (state, { payload }) => ({
    ...state,
    dealsMapData: {
      ...payload,
      loading: false,
    },
    loading: false,
  }))
  .handleAction(fetchDealsMapData.failure, (state) => ({
    ...state,
    dealsMapData: {
      deals: [],
      loading: false,
      total: 0,
    },
  }))
  .handleAction(fetchExportCardData.request, (state, { payload }) => {
    // reset page to the first if we used any filters
    const page =
      payload.search ||
      payload.filterType === FilterType.DOWNLOADED ||
      ("entityType" in payload && payload.entityType !== undefined)
        ? 0
        : payload.page;
    return {
      ...state,
      error: undefined,
      exportCardData: {
        ...state.exportCardData,
        reportListViewState: {
          ...state.exportCardData.reportListViewState,
          filter: cleanUpFilterModel({
            ...state.exportCardData.reportListViewState.filter,
            lastGeneratedAt:
              "filterType" in payload && payload.filterType
                ? payload.filterType === FilterType.ALL
                  ? undefined
                  : { operator: FilterOperator.NOT_EMPTY, value: undefined }
                : state.exportCardData.reportListViewState.filter?.lastGeneratedAt,
            name:
              "search" in payload
                ? payload.search
                  ? { operator: FilterOperator.CONTAINS, value: payload.search }
                  : undefined
                : state.exportCardData.reportListViewState.filter?.name,
            tableName:
              "entityType" in payload
                ? payload.entityType
                  ? { operator: FilterOperator.EQUALS, value: payload.entityType }
                  : undefined
                : state.exportCardData.reportListViewState.filter?.tableName,
            user:
              "userIds" in payload && payload.userIds
                ? payload.userIds.length > 0
                  ? { operator: FilterOperator.CONTAINS, value: payload.userIds }
                  : undefined
                : state.exportCardData.reportListViewState.filter?.user,
          }) as FilterModel,
          range:
            page === undefined
              ? state.exportCardData.reportListViewState.range
              : {
                  endRow: (page + 1) * DEFAULT_EXPORT_PAGE_SIZE,
                  startRow: page * DEFAULT_EXPORT_PAGE_SIZE,
                },
        },
      },
      loading: true,
    };
  })
  .handleAction(fetchExportCardData.success, (state, { payload }) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reports: payload.reports,
      total: payload.total,
    },
    loading: false,
  }))
  .handleAction(fetchExportCardData.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(downloadReport.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(downloadReport.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(downloadReport.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(scheduleReport.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(scheduleReport.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(scheduleReport.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(deleteReport.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(deleteReport.success, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reports: state.exportCardData.reports.filter((report) => report.id !== action.payload),
    },
    loading: false,
  }))
  .handleAction(deleteReport.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(fetchReport.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchReport.success, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      currentReport: action.payload,
    },
    loading: false,
  }))
  .handleAction(fetchReport.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(initializeCreateReport, (state) => {
    const newReport = getNewReport();
    return {
      ...state,
      exportCardData: {
        ...state.exportCardData,
        currentReport: newReport,
        viewState: getDefaultReportPreviewViewState(newReport),
      },
    };
  })
  .handleAction(editReport, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      currentReport: action.payload,
    },
  }))
  .handleAction(applyPreviewViewSettings, (state, { payload }) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      viewState: {
        ...state.exportCardData.viewState,
        columns: payload.columns ?? state.exportCardData.viewState.columns,
        filter: payload.filter ?? state.exportCardData.viewState.filter,
        range: payload.range ?? state.exportCardData.viewState.range,
        sort: payload.sort ?? state.exportCardData.viewState.sort,
        viewAs: "viewAs" in payload ? payload.viewAs : state.exportCardData.viewState.viewAs,
      },
    },
  }))
  .handleAction(fetchDealsByStage.request, (state) => ({
    ...state,
    dealsProgressionChartData: {
      ...state.dealsProgressionChartData,
      dealsLoading: true,
    },
  }))
  .handleAction(fetchDealsByStage.success, (state, { payload: { deals, stageId, total } }) => ({
    ...state,
    dealsProgressionChartData: {
      ...state.dealsProgressionChartData,
      dealsLoading: false,
      stageToDealsMap: {
        ...state.dealsProgressionChartData.stageToDealsMap,
        [stageId]: deals,
      },
      stageToDealsTotalMap: {
        ...state.dealsProgressionChartData.stageToDealsTotalMap,
        [stageId]: total,
      },
    },
  }))
  .handleAction(fetchDealsByStage.failure, (state, { payload }) => ({
    ...state,
    dealsProgressionChartData: {
      ...state.dealsProgressionChartData,
      dealsError: payload,
      dealsLoading: false,
    },
  }))
  .handleAction(fetchDealsGeoChartData.request, (state) => ({
    ...state,
    dealsGeoChartData: {
      ...state.dealsGeoChartData,
      loading: true,
    },
  }))
  .handleAction(fetchDealsGeoChartData.success, (state, { payload }) => ({
    ...state,
    dealsGeoChartData: {
      dealsCountByHcKey: payload,
      loading: false,
    },
  }))
  .handleAction(fetchDealsGeoChartData.failure, (state) => ({
    ...state,
    dealsGeoChartData: {
      dealsCountByHcKey: {},
      loading: false,
    },
  }))
  .handleAction(fetchPreviewData.request, (state) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reportPreviewError: undefined,
      reportPreviewLoading: true,
    },
  }))
  .handleAction(fetchPreviewData.success, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reportPreviewLoading: false,
      reportTotalRowsCount: action.payload.reportTotalRowsCount,
    },
  }))
  .handleAction(fetchPreviewData.failure, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reportPreviewError: action.payload,
      reportPreviewLoading: false,
    },
  }))
  .handleAction(saveReport.request, (state) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reportSaving: true,
      reportSavingError: undefined,
    },
  }))
  .handleAction(saveReport.success, (state) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reportSaving: false,
    },
  }))
  .handleAction(saveReport.failure, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      reportSaving: false,
      reportSavingError: action.payload,
    },
  }))
  .handleAction(changeReportType, (state, action) => {
    const updatedReport = state.exportCardData.currentReport
      ? {
          ...state.exportCardData.currentReport,
          customFields: [],
          selectedColumns: getColumnsVisibleByDefault(action.payload).map((field) => field.name),
          selectedFilters: {},
          tableName: action.payload,
        }
      : undefined;

    return {
      ...state,
      exportCardData: {
        ...state.exportCardData,
        currentReport: updatedReport,
        viewState: updatedReport
          ? getDefaultReportPreviewViewState(updatedReport)
          : state.exportCardData.viewState,
      },
    };
  })
  .handleAction(changeReportDescription, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      currentReport: state.exportCardData.currentReport
        ? { ...state.exportCardData.currentReport, description: action.payload }
        : undefined,
    },
  }))
  .handleAction(changeReportName, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      currentReport: state.exportCardData.currentReport
        ? { ...state.exportCardData.currentReport, name: action.payload }
        : undefined,
    },
  }))
  .handleAction(changeReportId, (state, action) => ({
    ...state,
    exportCardData: {
      ...state.exportCardData,
      currentReport: state.exportCardData.currentReport
        ? { ...state.exportCardData.currentReport, id: action.payload }
        : undefined,
    },
  }))
  .handleAction(setDateRange, (state, { payload }) => ({
    ...state,
    dateRange: payload,
    dateRangeType: ReportsDateRangeType.CUSTOM,
  }))
  .handleAction(setDateRangeType, (state, { payload }) => ({
    ...state,
    dateRange: payload === ReportsDateRangeType.CUSTOM ? state.dateRange : undefined,
    dateRangeType: payload,
  }))
  .handleAction(setUserIds, (state, { payload }) => ({
    ...state,
    userIds: payload,
  }))
  .handleAction(saveWeeklyReport.request, (state) => ({
    ...state,
    weeklyReportSaving: true,
  }))
  .handleAction([saveWeeklyReport.success, saveWeeklyReport.failure], (state) => ({
    ...state,
    weeklyReportSaving: false,
  }))
  .handleAction(fetchLeaderboardData.request, (state, { payload }) => ({
    ...state,
    leaderboard: {
      ...state.leaderboard,
      loading: true,
      viewState: {
        ...state.leaderboard.viewState,
        activityTypeIds:
          "activityTypeIds" in payload
            ? payload.activityTypeIds
            : state.leaderboard.viewState.activityTypeIds,
        columns: payload.columns ?? state.leaderboard.viewState.columns,
        dateRange:
          (payload.dateRangeType === DateRangeType.CUSTOM
            ? payload.dateRange
            : payload.dateRangeType
            ? standardRangeToDateRange(payload.dateRangeType)
            : undefined) ?? state.leaderboard.viewState.dateRange,
        dateRangeType: payload.dateRangeType ?? state.leaderboard.viewState.dateRangeType,
        funnelIds:
          "funnelIds" in payload ? payload.funnelIds : state.leaderboard.viewState.funnelIds,
        grouping: payload.grouping ?? state.leaderboard.viewState.grouping,
        rankingMetric: payload.rankingMetric ?? state.leaderboard.viewState.rankingMetric,
        stageIds: "stageIds" in payload ? payload.stageIds : state.leaderboard.viewState.stageIds,
        teamIds: "teamIds" in payload ? payload.teamIds : state.leaderboard.viewState.teamIds,
      },
    },
  }))
  .handleAction(fetchLeaderboardData.success, (state, { payload }) => ({
    ...state,
    leaderboard: {
      ...state.leaderboard,
      loading: false,
      metaData: payload?.metaData ?? state.leaderboard.metaData,
      tableItems: payload?.items ?? state.leaderboard.tableItems,
    },
  }))
  .handleAction(fetchLeaderboardData.failure, (state) => ({
    ...state,
    leaderboard: {
      ...state.leaderboard,
      loading: false,
    },
  }))
  .handleAction(fetchLeaderboardMetricsData.request, (state, { payload }) => ({
    ...state,
    leaderboard: {
      ...state.leaderboard,
      metricsItems: payload.selectedMetrics
        ? Object.fromEntries(payload.selectedMetrics.map((metric) => [metric, undefined]))
        : state.leaderboard.metricsItems,
      metricsLoading: true,
      metricsViewState: {
        ...state.leaderboard.metricsViewState,
        activityTypeIds: payload.activityTypeIds ?? state.leaderboard.viewState.activityTypeIds,
        funnelIds: payload.funnelIds ?? state.leaderboard.viewState.funnelIds,
        stageIds: payload.stageIds ?? state.leaderboard.viewState.stageIds,
        teamIds:
          "teamIds" in payload ? payload.teamIds : state.leaderboard.metricsViewState.teamIds,
        userId:
          "userId" in payload
            ? payload.userId
            : "teamIds" in payload
            ? undefined
            : state.leaderboard.metricsViewState.userId,
      },
    },
  }))
  .handleAction(fetchLeaderboardMetricsData.success, (state, { payload }) => ({
    ...state,
    leaderboard: {
      ...state.leaderboard,
      metricsItems: payload,
      metricsLoading: false,
    },
  }))
  .handleAction(fetchLeaderboardMetricsData.failure, (state) => ({
    ...state,
    leaderboard: {
      ...state.leaderboard,
      metricsLoading: false,
    },
  }))
  .handleAction(fetchReportRecordsCountInfo.request, (state) => ({
    ...state,
    reportRecordsCountLoading: true,
  }))
  .handleAction(
    [fetchReportRecordsCountInfo.failure, fetchReportRecordsCountInfo.success],
    (state) => ({
      ...state,
      reportRecordsCountLoading: false,
    })
  );

export type ReportActions = Actions;
export default report;
