import { call, put, select, takeEvery } from "redux-saga/effects";

import { Company } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";

import { fetchCompanies, fetchCompany } from "./actions";

function* onFetchCompany({ payload }: ReturnType<typeof fetchCompany>) {
  const { id, callback, failureCallback, options = {} } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const company: Company = yield callApi("fetchCompany", orgId, id, options);
    yield call(callback, company);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onFetchCompanies({ payload }: ReturnType<typeof fetchCompanies>) {
  const { callback, failureCallback, ids } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const companiesResponse: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
      $filters: {
        $and: [
          {
            id: { $in: ids },
          },
        ],
      },
      $limit: 1000,
      $order: "name",
    });
    yield call(callback, companiesResponse.data);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

export function* companySaga() {
  yield takeEvery(fetchCompany, onFetchCompany);
  yield takeEvery(fetchCompanies, onFetchCompanies);
}
