import React from "react";

import {
  EntitiesSupportingPreview,
  EntityTypeSupportingPreview,
} from "@mapmycustomers/shared/types/entity";

import defaultEntityLinkGetter from "./defaultEntityLinkGetter";

export type IEntityLinkGetter = (
  entityType: EntityTypeSupportingPreview,
  entityId: EntitiesSupportingPreview["id"],
  options?: { edit?: boolean; noIframe?: boolean }
) => string;

const EntityLinkGetterContext = React.createContext<IEntityLinkGetter>(defaultEntityLinkGetter);

export default EntityLinkGetterContext;
