import React, { useCallback } from "react";

import Tag from "antd/es/tag";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import { Group } from "@mapmycustomers/shared/types/entity";

import EntityTag from "./index";
import styles from "./useGroupTagRender.module.scss";

const useGroupTagRender = (groups: Group[]) =>
  useCallback(
    (props: CustomTagProps) => {
      const { closable, label, onClose, value } = props;
      const group = groups.find((group) => group.id === value);
      return group ? (
        <EntityTag className={styles.tag} closable={closable} entity={group} onClose={onClose} />
      ) : (
        // should not really happen, but keeping as a failsafe solution
        <Tag>{label}</Tag>
      );
    },
    [groups]
  );

export default useGroupTagRender;
