import { createReducer } from "typesafe-actions";

import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import {
  Actions,
  createGroup,
  deleteGroup,
  fetchGroups,
  updateEntities,
  updateGroup,
  updateGroupSharing,
} from "./actions";
import GroupsState from "./GroupsState";
import { stateFieldBaseName } from "./util";

const initialState: GroupsState = {
  companyGroups: [],
  companyGroupsError: undefined,
  companyGroupsLoading: false,
  createGroupError: undefined,
  createGroupLoading: false,
  dealGroups: [],
  dealGroupsError: undefined,
  dealGroupsLoading: false,
  deleteGroupLoading: false,
  peopleGroups: [],
  peopleGroupsError: undefined,
  peopleGroupsLoading: false,
  updateEntitiesLoading: false,
  updateGroupLoading: false,
};

const groups = createReducer<GroupsState, Actions>(initialState)
  .handleAction(fetchGroups.request, (state, { payload: { entityType } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}`]: [],
    [`${stateFieldBaseName[entityType]}Error`]: undefined,
    [`${stateFieldBaseName[entityType]}Loading`]: true,
  }))
  .handleAction(fetchGroups.success, (state, { payload: { entityType, groups } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}`]: groups,
    [`${stateFieldBaseName[entityType]}Loading`]: false,
  }))
  .handleAction(fetchGroups.failure, (state, { payload: { entityType, error } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}Error`]: error,
    [`${stateFieldBaseName[entityType]}Loading`]: false,
  }))
  .handleAction(createGroup.request, (state) => ({
    ...state,
    createGroupError: undefined,
    createGroupLoading: true,
  }))
  .handleAction(createGroup.success, (state, { payload: { entityType, group } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}`]: [...state[stateFieldBaseName[entityType]], group].sort(
      nameComparator
    ),
    createGroupLoading: false,
  }))
  .handleAction(createGroup.failure, (state, action) => ({
    ...state,
    createGroupError: action.payload,
    createGroupLoading: false,
  }))
  .handleAction([updateGroup.request, updateGroupSharing.request], (state) => ({
    ...state,
    updateGroupLoading: true,
  }))
  .handleAction(
    [updateGroup.success, updateGroupSharing.success],
    (state, { payload: { entityType, group } }) => ({
      ...state,
      [`${stateFieldBaseName[entityType]}`]: state[stateFieldBaseName[entityType]].map((item) =>
        item.id !== group.id ? item : group
      ),
      updateGroupLoading: false,
    })
  )
  .handleAction([updateGroup.failure, updateGroupSharing.failure], (state) => ({
    ...state,
    updateGroupLoading: false,
  }))
  .handleAction(updateEntities.request, (state) => ({
    ...state,
    updateEntitiesLoading: true,
  }))
  .handleAction([updateEntities.success, updateEntities.failure], (state) => ({
    ...state,
    updateEntitiesLoading: false,
  }))
  .handleAction(deleteGroup.request, (state) => ({ ...state, deleteGroupLoading: true }))
  .handleAction(deleteGroup.success, (state, { payload: { entityType, groupId } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}`]: state[stateFieldBaseName[entityType]].filter(
      ({ id }) => id !== groupId
    ),
    deleteGroupLoading: false,
  }))
  .handleAction(deleteGroup.failure, (state) => ({ ...state, deleteGroupLoading: false }));

export * from "./selectors";
export type GroupsActions = Actions;
export default groups;
