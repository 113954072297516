import { all, fork } from "redux-saga/effects";

import { routeBuilderSaga } from "./activity/component/ActivityCalendarPage/components/Sidebar/RouteBuilder/store/saga";
import { calendarSaga } from "./activity/component/ActivityCalendarPage/store/saga";
import { schedulerSaga } from "./activity/component/ActivitySchedulerPage/store/saga";
import { activitySaga } from "./activity/store/saga";
import { billingSaga } from "./billing/store/saga";
import { companySaga } from "./company/store/saga";
import { dashboardSaga } from "./dashboard/store/saga";
import { dealSaga } from "./deal/store/saga";
import { homeSaga } from "./home/store/saga";
import { mapSaga } from "./map/store/saga";
import { peopleSaga } from "./people/store/saga";
import { reportSaga } from "./reports/store/saga";
import { settingsSaga } from "./settings/settingsSaga";

export function* sceneSaga() {
  yield all([
    fork(activitySaga),
    fork(billingSaga),
    fork(calendarSaga),
    fork(companySaga),
    fork(dashboardSaga),
    fork(dealSaga),
    fork(homeSaga),
    fork(mapSaga),
    fork(peopleSaga),
    fork(reportSaga),
    fork(schedulerSaga),
    fork(settingsSaga),
    fork(routeBuilderSaga),
  ]);
}
