import React from "react";

export const CompanyRecordPane = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./CompanyRecordPane"
    )
);

export const PersonRecordPane = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./PersonRecordPane"
    )
);

export const DealRecordPane = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./DealRecordPane"
    )
);
