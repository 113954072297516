import { put, select, takeEvery } from "redux-saga/effects";

import { Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import { companyLayoutModel, personLayoutModel } from "@app/util/layout/impl";

import { updateEntityAddress } from "./actions";

export function* onUpdateEntityAddress({ payload }: ReturnType<typeof updateEntityAddress>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    switch (payload.entity.entity) {
      case EntityType.COMPANY: {
        const companyLayout = companyLayoutModel.getLayoutFor(payload.entity);
        const company: Company = yield callApi("updateCompany", orgId, companyLayout.id, {
          id: payload.entity.id,
          ...payload.address,
        });
        yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [company] }));
        break;
      }

      case EntityType.PERSON: {
        const personLayout = personLayoutModel.getLayoutFor(payload.entity);
        const person: Person = yield callApi("updatePerson", orgId, personLayout.id, {
          id: payload.entity.id,
          ...payload.address,
        });
        yield put(notifyAboutChanges({ entityType: EntityType.PERSON, updated: [person] }));
        break;
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* entityAddressSaga() {
  yield takeEvery(updateEntityAddress, onUpdateEntityAddress);
}
