enum EntityType {
  ACTIVITY = "crm-activities",
  COMPANY = "accounts",
  COMPANY_GROUP = "account-groups",
  COMPANY_ROUTE = "account-routes",
  DEAL = "deals",
  DEAL_GROUP = "deal-groups",
  INTEGRATION = "integration",
  LEAD = "leads",
  LEADERBOARD = "leaderboard",
  PEOPLE_GROUP = "contact-groups",
  PEOPLE_ROUTE = "contact-routes",
  PERSON = "contacts",
  PIN = "pins",
  ROUTE = "routes",
  TERRITORY = "territories",
  HISTORY = "history",
}

export default EntityType;
