import { useEffect, useState } from "react";

import { FormInstance } from "antd/es/form";
import { ValidateStatus } from "antd/es/form/FormItem";

import SignupField from "@app/scene/auth/component/Register/enum/SignupField";

const useEmailValidationStatus = (
  form: FormInstance,
  doesUserExist: boolean,
  isLoading: boolean
): ValidateStatus => {
  const [emailValidationResult, setEmailValidationResult] = useState<ValidateStatus>("");
  useEffect(() => {
    const fieldValue = form.getFieldValue(SignupField.EMAIL);

    // If such user does not exist and we're not checking for that now
    // and the value is empty, set validation result to "none".
    // This is a default state.
    if (!doesUserExist && !isLoading && !fieldValue) {
      setEmailValidationResult("");
      return;
    }

    // if we're checking user existence now, set status to "validating"
    if (isLoading) {
      setEmailValidationResult("validating");
      return;
    }

    // otherwise, try to validate the field
    form.validateFields([SignupField.EMAIL]).then(
      () => setEmailValidationResult(fieldValue ? "success" : ""),
      () => setEmailValidationResult(fieldValue ? "error" : "")
    );
  }, [isLoading, doesUserExist, form, setEmailValidationResult]);

  return emailValidationResult;
};

export default useEmailValidationStatus;
