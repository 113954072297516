import { useMemo } from "react";

import SUPPORTED_SERVICES, { ServiceDescription } from "@app/store/integrations/supported-services";
import Integration from "@app/types/integrations/Integration";

export const findServiceByType = (serviceType?: string) =>
  SUPPORTED_SERVICES.find(({ type }) => type === serviceType);

const useServiceDescription = (
  integrationType?: Integration["type"]
): ServiceDescription | undefined =>
  useMemo(() => findServiceByType(integrationType), [integrationType]);

export default useServiceDescription;
