import { defineMessages } from "react-intl";

const messages = defineMessages({
  changePeriod: {
    id: "email.emailPeriod.changePeriod",
    defaultMessage: "Change",
    description: "Change period button in the email queue modal.",
  },
  period: {
    id: "email.emailPeriod.period",
    defaultMessage: "Sending between {startTime} - {endTime} {timezone}",
    description: "Period in the email queue modal.",
  },
});

export default messages;
