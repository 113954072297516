import React, { useMemo, useState } from "react";

import { Popconfirm } from "antd";

import { EntityType } from "@mapmycustomers/shared/types/entity";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import { useConfigProvider } from "../../../ConfigProvider";
import DynamicVarVariant from "../../type/DynamicVarVariant";
import { DYNAMIC_VAR_REGEX } from "../../util/findThingsInText";

import styles from "./DynamicVar.module.scss";
import DynamicVarChip from "./DynamicVarChip";

type DynamicVarProps = React.PropsWithChildren<{
  dynamicVarVariants?: DynamicVarVariant[];
  entityType?: EntityTypesSupportedByEmailFeature;
  text: string;
}>;

const DynamicVar: React.FC<DynamicVarProps> = ({
  children,
  dynamicVarVariants,
  entityType,
  text,
}) => {
  const configProvider = useConfigProvider();
  const [popconfirmVisible, setPopconfirmVisibility] = useState<boolean>(false);
  const [valueText, allEntitiesHaveValues] = useMemo(() => {
    let allEntitiesHaveValues = false;
    let result = "";
    const parts = Array.from(text.matchAll(DYNAMIC_VAR_REGEX)) as RegExpMatchArray[];

    if (parts.length) {
      const fieldName = parts[0].groups?.fieldName ?? "";
      const dynamicVarVariant = dynamicVarVariants?.find((variant) => variant.name === fieldName);
      if (dynamicVarVariant) {
        result = dynamicVarVariant.displayName;
        allEntitiesHaveValues = dynamicVarVariant.allEntitiesHaveValues;
      }
    }
    return [result, allEntitiesHaveValues];
  }, [dynamicVarVariants, text]);

  return (
    <span contentEditable={false}>
      <Popconfirm
        cancelButtonProps={{ hidden: true }}
        okText={configProvider.formatMessage("ui.emailBody.dynamicVar.someEntitiesMissingData.ok")}
        onConfirm={() => setPopconfirmVisibility(false)}
        open={popconfirmVisible}
        placement="topRight"
        title={
          <div className={styles.popconfirmContainer}>
            <div className={styles.popconfirmTitle}>
              {entityType === EntityType.COMPANY
                ? configProvider.formatMessage(
                    "ui.emailBody.dynamicVar.someEntitiesMissingData.company"
                  )
                : configProvider.formatMessage(
                    "ui.emailBody.dynamicVar.someEntitiesMissingData.person"
                  )}
            </div>
            <div>
              {configProvider.formatMessage(
                "ui.emailBody.dynamicVar.someEntitiesMissingData.description"
              )}
            </div>
          </div>
        }
      >
        <DynamicVarChip
          allEntitiesHaveValues={allEntitiesHaveValues}
          onClick={allEntitiesHaveValues ? undefined : () => setPopconfirmVisibility(true)}
        >
          {valueText}
        </DynamicVarChip>
      </Popconfirm>
      {children}
    </span>
  );
};

export default DynamicVar;
