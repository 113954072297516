import { createSelector } from "reselect";

import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import { RootState } from "@app/store/rootReducer";

const activityState = (state: RootState) => state.scene.activity;

export const getListViewState = createSelector(
  activityState,
  ({ columns, filter, range, selectedSavedFilterId, sort, viewAs }): ViewState => ({
    columns,
    filter,
    range,
    selectedSavedFilterId,
    sort,
    viewAs,
  })
);

export const getLoading = createSelector(activityState, ({ loading }) => loading);

export const getUpdateActivityLoadingIds = createSelector(
  activityState,
  ({ updateActivityLoading }) => updateActivityLoading
);

export const getBulkPostponeLoading = createSelector(
  activityState,
  ({ bulkPostponeLoading }) => bulkPostponeLoading
);

export const getToggleCompleteLoadingIds = createSelector(
  activityState,
  ({ toggleCompleteLoading }) => toggleCompleteLoading
);

export const getBulkToggleCompleteLoading = createSelector(
  activityState,
  ({ bulkToggleCompleteLoading }) => bulkToggleCompleteLoading
);

export const getOpenDuplicationModalLoadingIds = createSelector(
  activityState,
  ({ openDuplicationModalLoading }) => openDuplicationModalLoading
);

export const getDeleteLoadingIds = createSelector(
  activityState,
  ({ deleteLoading }) => deleteLoading
);

export const getBulkDeleteLoading = createSelector(
  activityState,
  ({ bulkDeleteLoading }) => bulkDeleteLoading
);

export const getUpdateNoteLoadingIds = createSelector(
  activityState,
  ({ updateNoteLoading }) => updateNoteLoading
);

export const getUploadedFiles = createSelector(activityState, ({ filesAdded }) => filesAdded);

export const getUploadedFileListIds = createSelector(getUploadedFiles, (filesAdded) =>
  filesAdded
    .filter(({ uploadedFile }) => !!uploadedFile)
    .map(({ uploadedFile }) => uploadedFile!.id)
);

export const getFilePreview = createSelector(activityState, ({ filePreview }) => filePreview);
export const getFilePreviewId = createSelector(activityState, ({ filePreviewId }) => filePreviewId);

export const isFilePreviewLoading = createSelector(
  activityState,
  ({ filePreviewLoading }) => filePreviewLoading
);

export const getList = createSelector(activityState, ({ list }) => list);

export const getSelected = createSelector(activityState, ({ selected }) => selected);

export const getActivityAssignee = createSelector(
  activityState,
  ({ activityAssignee }) => activityAssignee
);

export const getActivityVisibilityTeamIds = createSelector(
  activityState,
  ({ activityVisibilityTeamIds }) => activityVisibilityTeamIds
);

export const getActivityType = createSelector(activityState, ({ activityType }) => activityType);

export const getBulkEditLoading = createSelector(
  activityState,
  ({ bulkEditLoading }) => bulkEditLoading
);

export const getActivityVisibility = createSelector(
  activityState,
  ({ activityVisibility }) => activityVisibility
);

export const getTotalFilteredRecordsCount = createSelector(
  activityState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getTotalRecordsCount = createSelector(
  activityState,
  ({ totalRecords }) => totalRecords
);

export const getUpdateViewStateCallback = createSelector(
  activityState,
  ({ onViewStateChanged }) => onViewStateChanged
);
