import { useMemo } from "react";

import { Rule } from "antd/es/form";
import { IntlShape } from "react-intl";

const useNameRequiredValidationRules = (intl: IntlShape): Rule[] =>
  useMemo(
    () => [
      (form) => ({
        validator: () => {
          const isFirstNameEmpty = !form.getFieldValue("firstName");
          const isLastNameEmpty = !form.getFieldValue("lastName");
          if (isFirstNameEmpty && isLastNameEmpty) {
            return Promise.reject(
              new Error(
                intl.formatMessage({
                  id: "createPersonModal.basicFields.name.error.empty",
                  defaultMessage: "Please enter First or Last name",
                  description: "Validation error to show when both first and last names are empty",
                })
              )
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    [intl]
  );

export default useNameRequiredValidationRules;
