import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import { Form } from "antd";
import { TextAreaProps } from "antd/es/input/TextArea";
import cn from "classnames";
import { BaseEditor, createEditor, Descendant, Editor, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";
import { Editable, Slate, withReact } from "slate-react";

import User from "@mapmycustomers/shared/types/User";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { LARGE_TEXT_LENGTH_LIMIT } from "@mapmycustomers/shared/util/consts";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import useRerenderer from "@mapmycustomers/shared/util/hook/useRerenderer";

import { useConfigProvider } from "../../../ConfigProvider";
import ErrorRow from "../../ErrorRow";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import convertToText from "../util/convertToText";
import parseText from "../util/parseText";

import Element from "./mention/Element";
import MentionsDropdown from "./mention/MentionsDropdown";
import IsMentionDisabled from "./mention/type/IsMentionDisabled";
import convertToTextWithMentions from "./mention/util/convertToTextWithMentions";
import decorator from "./mention/util/decorator";
import displayElements from "./mention/util/displayElements";
import findMention from "./mention/util/findMention";
import getTextLength, { createCache } from "./mention/util/getTextLength";
import insertMention from "./mention/util/insertMention";
import isValueEmpty from "./mention/util/isValueEmpty";
import parseTextWithMentions from "./mention/util/parseTextWithMentions";
import withMentions from "./mention/util/withMentions";
import withSingleLine from "./mention/util/withSingleLineText";

const getUserFilter = (search: string) => {
  const searchString = search.toLowerCase().trim();
  return ({ fullName, username }: User) =>
    searchString.length === 0 ||
    (fullName ?? "").toLowerCase().includes(searchString) ||
    username.toLowerCase().includes(searchString);
};

// Not sure can we provide all text area props.
interface OwnProps
  extends Pick<
      TextAreaProps,
      "autoFocus" | "className" | "disabled" | "maxLength" | "placeholder" | "rows"
    >,
    Omit<LabeledFieldProps, "children"> {
  allowedUserIds?: User["id"][];
  caption?: string;
  disableMentions?: boolean;
  emptyTextForWithSkippedUsers?: string;
  error?: ReactNode;
  hideDisabledUsers?: boolean;
  isMentionDisabled?: IsMentionDisabled;
  // this prop can't be changed dynamically coz we use this during editor initialization, which can be performed only once
  isTextField?: boolean;
  locked?: boolean;
  onChange?: (text: string) => void;
  onModify?: (empty: boolean) => void;
  placeholder?: string;
  value?: string;
}

interface Props extends OwnProps {
  allUsers: User[];
}

const block = bem("mmc-text-area-with-mentions-field");

const TextAreaWithMentions: React.FC<Props> = ({
  allowedUserIds,
  allUsers,
  caption,
  className,
  disableMentions = false,
  emptyTextForWithSkippedUsers,
  error,
  hideDisabledUsers,
  isMentionDisabled,
  isTextField,
  label,
  labelClassName,
  labelPosition,
  locked,
  maxLength = LARGE_TEXT_LENGTH_LIMIT,
  onChange,
  onModify,
  placeholder,
  required,
  rowProps,
  sideLabelSpan,
  value,
  ...props
}) => {
  const configProvider = useConfigProvider();
  const { status } = Form.Item.useStatus();

  const users = useMemo(() => {
    if (allowedUserIds) {
      const userIds = new Set(allowedUserIds);
      return allUsers.filter(({ id }) => userIds.has(id));
    } else {
      return allUsers;
    }
  }, [allowedUserIds, allUsers]);

  const [parsedValue, setParsedValue] = useState<Descendant[]>(
    disableMentions
      ? parseText(value ?? "")
      : parseTextWithMentions(value ?? "", users, isMentionDisabled)
  );
  const textLengthCache = useRef(createCache());

  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState<number>(0);
  const [search, setSearch] = useState("");
  const renderElement = useCallback((props) => <Element {...props} />, []);

  const textLength = useMemo(
    () => getTextLength(parsedValue, textLengthCache.current),
    [parsedValue]
  );

  const isTextLimitReached = useMemo(() => textLength >= maxLength, [maxLength, textLength]);

  const editorRef = useRef<ReactEditor & BaseEditor & HistoryEditor>();
  if (!editorRef.current) {
    let editor = withReact(createEditor());
    if (isTextField) {
      editor = withSingleLine(editor);
    }
    editor = withMentions(editor);
    editorRef.current = editor;
  }

  const editor = editorRef.current!;

  const userFilter = useMemo(() => getUserFilter(search), [search]);

  const hasAnyFilteredUsers = useMemo(() => users.some(userFilter), [userFilter, users]);

  const filteredUsers = useMemo(() => {
    const preparedUsers =
      hideDisabledUsers && isMentionDisabled
        ? users.filter((user) => !isMentionDisabled(user))
        : users;
    return preparedUsers.filter(userFilter);
  }, [hideDisabledUsers, isMentionDisabled, userFilter, users]);

  const handleInsertMention = useCallback(
    (index: number) => {
      if (target) {
        Transforms.select(editor, target);
      }
      insertMention(editor, filteredUsers[index], isMentionDisabled);
      setTarget(undefined);
    },
    [editor, filteredUsers, isMentionDisabled, setTarget, target]
  );

  const handlePaste = useCallback(
    (e) => {
      const insertText: string | undefined = e?.clipboardData?.getData("Text");
      if (insertText) {
        if (textLength + insertText.length > maxLength) {
          e.preventDefault();
        }
      }
    },
    [maxLength, textLength]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (target) {
        switch (event.key) {
          case "ArrowDown": {
            stopEvents(event);
            const prevIndex = index >= filteredUsers.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          }
          case "ArrowUp": {
            stopEvents(event);
            const nextIndex = index <= 0 ? filteredUsers.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          }
          case "Tab":
          case "Enter":
            stopEvents(event);
            if (!disableMentions) {
              handleInsertMention(index);
            }
            break;
          case "Escape":
            stopEvents(event);
            setTarget(undefined);
            break;
        }
      }
    },
    [disableMentions, filteredUsers, handleInsertMention, index, setIndex, setTarget, target]
  );

  const handleBlur = useCallback(() => {
    onChange?.(
      disableMentions ? convertToText(parsedValue) : convertToTextWithMentions(parsedValue)
    );
  }, [disableMentions, onChange, parsedValue]);

  useChangeTracking(() => {
    onModify?.(isValueEmpty(parsedValue));
  }, [onModify, parsedValue]);

  const notifyAboutChanges = useDebouncedCallback([
    (elements) => {
      onChange?.(disableMentions ? convertToText(elements) : convertToTextWithMentions(elements));
    },
    500,
  ]);

  const handleChange = useCallback(
    (elements: Descendant[]) => {
      notifyAboutChanges(elements);
      setParsedValue(elements);
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const mention = findMention(selection, editor);
        if (mention) {
          const { search, target } = mention;
          setTarget(target);
          setSearch(search);
          setIndex(0);
        } else {
          setTarget(undefined);
        }
      }
      const length = getTextLength(elements, textLengthCache.current);
      if (length > maxLength) {
        Transforms.delete(editor, {
          at: {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
          },
        });
        editor.insertText(
          (disableMentions
            ? convertToText(elements)
            : convertToTextWithMentions(elements)
          ).substring(0, maxLength)
        );
      }
    },
    [
      disableMentions,
      editor,
      maxLength,
      notifyAboutChanges,
      setIndex,
      setParsedValue,
      setSearch,
      setTarget,
    ]
  );

  const rerender = useRerenderer();
  useEffect(() => {
    const oldValue = disableMentions
      ? convertToText(editorRef.current!.children)
      : convertToTextWithMentions(editorRef.current!.children);
    // only set new value when it is different, otherwise we're trying to avoid unnecessary re-rendering
    if (value !== oldValue) {
      const newValue = disableMentions
        ? parseText(value ?? "")
        : parseTextWithMentions(value ?? "", users, isMentionDisabled);
      editorRef.current!.children = newValue;
      rerender();
    }
  }, [disableMentions, value, users, isMentionDisabled, rerender]);

  const isDisabled = props.disabled || locked;

  const formattedError = useMemo(
    () =>
      maxLength && (value ?? "").length >= maxLength
        ? configProvider.formatMessage("ui.textField.error.limit")
        : error,
    [configProvider, error, maxLength, value]
  );

  return (
    <Labeled
      className={cn(block({ disabled: isDisabled, error: status === "error" }), className)}
      label={label}
      labelClassName={labelClassName}
      labelPosition={labelPosition}
      required={required}
      rowProps={rowProps}
      sideLabelSpan={sideLabelSpan}
    >
      <Slate editor={editor} onChange={isDisabled ? undefined : handleChange} value={parsedValue}>
        <Editable
          className={cn(block("editor", { singleLineEditor: isTextField }), className)}
          decorate={decorator}
          disabled={isDisabled}
          onBlur={isDisabled ? undefined : handleBlur}
          onKeyDown={isDisabled ? undefined : onKeyDown}
          onPaste={isDisabled ? undefined : handlePaste}
          placeholder={placeholder}
          readOnly={isDisabled}
          renderElement={renderElement}
          renderLeaf={displayElements}
          {...props}
        />
        {!isDisabled && !disableMentions && target && (
          <MentionsDropdown
            activeIndex={index}
            editor={editor}
            emptyTextForWithSkippedUsers={
              hasAnyFilteredUsers ? emptyTextForWithSkippedUsers : undefined
            }
            onHover={setIndex}
            onSelect={handleInsertMention}
            target={target}
            users={filteredUsers}
          />
        )}
        {locked && <FontAwesomeIcon className={block("lock")} icon={faLock} />}
      </Slate>
      {isTextLimitReached ? (
        <ErrorRow>{formattedError}</ErrorRow>
      ) : caption ? (
        <div className={block("caption")}>{caption}</div>
      ) : null}
    </Labeled>
  );
};

export default TextAreaWithMentions;
