import React, { ComponentProps } from "react";

import { useIntl } from "react-intl";

import Location from "./Location";

type Props = Omit<ComponentProps<typeof Location>, "label">;

const StartingLocation: React.FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <Location
      label={intl.formatMessage({
        id: "createRouteForm.field.startingLocation",
        defaultMessage: "* Starting Location",
        description: "Starting Location field on the Create Route form",
      })}
      {...props}
    />
  );
};

export default StartingLocation;
