import React from "react";

import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";

import { stopEvents } from "@mapmycustomers/shared/util/browser";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";

import { useConfigProvider } from "../../../ConfigProvider";
import CreateLink from "../actions/component/Links/CreateLink";
import toolbarStyles from "../Toolbar.module.scss";

interface InsertLinkProps {
  onAction?: (actionKey: string) => void;
}

const InsertLink: React.FC<InsertLinkProps> = ({ onAction }) => {
  const [insetLinkVisible, setInsetLinkVisible] = React.useState(false);
  const configProvider = useConfigProvider();

  useChangeTracking(() => {
    if (insetLinkVisible) {
      onAction?.("Insert Link");
    }
  }, [insetLinkVisible, onAction]);

  return (
    <>
      <Tooltip title={configProvider.formatMessage("ui.emailBody.toolbar.actions.insertLink")}>
        <span
          className={toolbarStyles.togglerButton}
          onClick={() => setInsetLinkVisible(true)}
          onMouseDown={stopEvents}
        >
          <FontAwesomeIcon icon={faLink} />
        </span>
      </Tooltip>
      {insetLinkVisible && <CreateLink onHide={() => setInsetLinkVisible(false)} />}
    </>
  );
};

export default InsertLink;
