import { useMemo } from "react";

import { FormInstance } from "antd/es/form/hooks/useForm";
import { RuleObject } from "rc-field-form/lib/interface";
import { IntlShape } from "react-intl";

import { parsePhoneNumber } from "@app/util/parsers";
import validatePasswords from "@app/util/validatePasswords";

import SignupField from "../enum/SignupField";

export const useRequiredValidation = (intl: IntlShape) =>
  useMemo(
    () => [
      {
        message: intl.formatMessage({
          id: "auth.register.field.required",
          defaultMessage: "This Field is required",
          description: "Validation message for any required field",
        }),
        required: true,
      },
    ],
    [intl]
  );

export const useAcceptAgreementValidation = (intl: IntlShape) =>
  useMemo(
    () => [
      {
        validator: (_: RuleObject, value: boolean) => {
          return value
            ? Promise.resolve()
            : Promise.reject(
                intl.formatMessage({
                  id: "auth.register.orgDetailsStep.tnc.notAccepted",
                  defaultMessage: "You should accept Terms and Conditions",
                  description: "Validation message for tnc not checked",
                })
              );
        },
      },
    ],
    [intl]
  );

export const usePhoneValidationRules = (intl: IntlShape) =>
  useMemo(
    () => [
      {
        message: intl.formatMessage({
          id: "auth.register.contactInfoStep.phone.required",
          defaultMessage: "Please input your phone number",
          description: "Validation message for required phone number",
        }),
        required: true,
      },
      {
        validator: (rule: RuleObject, value: string) => {
          const parsedPhoneNumber = parsePhoneNumber(value);
          if (parsedPhoneNumber !== undefined || value === "") {
            return Promise.resolve();
          }
          return Promise.reject(
            intl.formatMessage({
              id: "auth.register.contactInfoStep.phone.invalidNumber",
              defaultMessage: "Please input a valid phone number",
              description: "Validation message for invalid phone number",
            })
          );
        },
      },
    ],
    [intl]
  );

export const useUsernameValidationRules = (
  intl: IntlShape,
  doesUserExist: boolean,
  isLoading: boolean
) =>
  useMemo(
    () => [
      {
        message: intl.formatMessage({
          id: "auth.register.contactInfoStep.username.required",
          defaultMessage: "Please input your username",
          description: "Required error message for the username field",
        }),
        required: true,
      },
      {
        validator: async (rule: RuleObject, value: string) => {
          return (value && !doesUserExist) || !value || isLoading
            ? Promise.resolve()
            : Promise.reject(
                intl.formatMessage({
                  id: "auth.register.contactInfoStep.username.existingUsername",
                  defaultMessage: "User with this email already exists",
                  description: "Validation message for duplicate username",
                })
              );
        },
      },
    ],
    [intl, doesUserExist, isLoading]
  );

export const usePasswordFieldValidationRules = (intl: IntlShape) =>
  useMemo(
    () => [
      {
        message: intl.formatMessage({
          id: "auth.register.passwordStep.password.required",
          defaultMessage: "Please input your password",
          description: "Validation message for the Password field in the register form",
        }),
        required: true,
      },
      {
        validator: (rule: RuleObject, value: string) => {
          const { isValid } = validatePasswords(value, value);
          if (isValid) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
      },
    ],
    [intl]
  );

export const useConfirmPasswordFieldValidationRules = (intl: IntlShape, form: FormInstance) =>
  useMemo(
    () => [
      {
        message: intl.formatMessage({
          id: "auth.register.passwordStep.confirmPassword.required",
          defaultMessage: "Please confirm your password",
          description: "Validation message for the Confirm Password field in the register form",
        }),
        required: true,
      },
      {
        validator: (rule: RuleObject, value: string) => {
          if (!value || form.getFieldValue(SignupField.PASSWORD) === value) {
            return Promise.resolve();
          }
          return Promise.reject();
        },
      },
    ],
    [intl, form]
  );
