import { Editor, Element } from "slate";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const withDynamicVars = (editor: Editor): Editor => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: Element) => {
    return Element.isElementType(element, ElementType.DYNAMIC_VAR) || isInline(element);
  };

  editor.isVoid = (element: Element) => {
    return Element.isElementType(element, ElementType.DYNAMIC_VAR) || isVoid(element);
  };

  return editor;
};

export default withDynamicVars;
