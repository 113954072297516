enum DateRangeType {
  ALL = "all",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  CUSTOM = "custom",
}

export default DateRangeType;
