import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "antd/es/badge";
import Popover from "antd/es/popover";
import cn from "classnames";

import {
  getNotificationUnreadUnfilteredCount,
  isNotificationsVisible,
} from "@app/store/notification";
import {
  fetchNotifications,
  hideNotifications,
  showNotifications,
} from "@app/store/notification/actions";
import { RootState } from "@app/store/rootReducer";
import notificationAnalytics from "@app/util/analytic/notificationAnalytics";
import AnalyticsContext from "@app/util/contexts/AnalyticsContext";

import MenuItemTitle from "../MenuItemTitle";

import NotificationView from "./component/NotificationView";
import styles from "./Notifications.module.scss";

interface Props {
  fetchNotifications: typeof fetchNotifications.request;
  hideNotifications: typeof hideNotifications;
  showNotifications: typeof showNotifications;
  unreadCount: number;
  visible: boolean;
}

const Notifications: React.FC<Props> = ({
  fetchNotifications,
  hideNotifications,
  showNotifications,
  unreadCount,
  visible,
}) => {
  const handleChangeVisible = useCallback(
    (visible) => {
      if (visible) {
        showNotifications();
      } else {
        hideNotifications();
      }
    },
    [hideNotifications, showNotifications]
  );

  return (
    <AnalyticsContext.Provider value={notificationAnalytics}>
      <Popover
        align={{ offset: [-10] }}
        content={<NotificationView onFetch={fetchNotifications} onHide={hideNotifications} />}
        onVisibleChange={handleChangeVisible}
        overlayClassName={styles.container}
        placement="bottomLeft"
        trigger="click"
        visible={visible}
      >
        <MenuItemTitle>
          <Badge count={unreadCount} overflowCount={9}>
            <FontAwesomeIcon
              className={cn(styles.icon, { [styles.visible]: visible })}
              icon={faBell}
            />
          </Badge>
        </MenuItemTitle>
      </Popover>
    </AnalyticsContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => ({
  unreadCount: getNotificationUnreadUnfilteredCount(state),
  visible: isNotificationsVisible(state),
});

const mapDispatchToProps = {
  fetchNotifications: fetchNotifications.request,
  hideNotifications,
  showNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
