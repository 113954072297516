import { createSelector } from "reselect";
import { createReducer } from "typesafe-actions";

import { RootState } from "../rootReducer";

import { Actions, fetchReferenceData } from "./actions";
import ReferenceData from "./ReferenceData";

export interface ReferenceDataState extends ReferenceData {
  loading: boolean;
}

const initialState: ReferenceDataState = {
  currencies: [],
  loading: false,
};

const referenceData = createReducer<ReferenceDataState, Actions>(initialState)
  .handleAction(fetchReferenceData.request, (state) => ({
    ...state,
    currencies: [],
    loading: true,
  }))
  .handleAction(fetchReferenceData.success, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false,
  }))
  .handleAction(fetchReferenceData.failure, (state) => ({
    ...state,
    loading: false,
  }));

const referenceDataState = (state: RootState) => state.referenceData;
export const isLoading = createSelector(referenceDataState, ({ loading }) => loading);
export const getCurrencies = createSelector(referenceDataState, ({ currencies }) => currencies);

export type ReferenceDataActions = Actions;
export default referenceData;
