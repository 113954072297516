import React from "react";

import notificationManager from "antd/es/notification";
import { IntlShape } from "react-intl";

import Organization from "@mapmycustomers/shared/types/Organization";
import Setting from "@mapmycustomers/shared/types/Setting";
import User from "@mapmycustomers/shared/types/User";

import ButtonLink from "@app/component/ButtonLink";
import MmcNotificationAction from "@app/enum/MmcNotificationAction";
import { updateSetting } from "@app/store/iam/actions";

import switchOffNotificationSetting from "./switchOffNotificationSetting";

const optOutSetting = (
  intl: IntlShape,
  updateSettingAction: typeof updateSetting.request,
  organizationId: Organization["id"],
  currentUserId: User["id"],
  setting: Setting,
  action: MmcNotificationAction,
  onGoToSettings: () => void
) => {
  updateSettingAction({
    callback: () => {
      notificationManager.success({
        description: intl.formatMessage(
          {
            id: "navbar.notifications.list.notification.optedOutOfNotifications.description",
            defaultMessage:
              "Please note you may still receive other alerts. Manage all notifications from your <a>notification center preferences</a>.",
            description:
              "Navbar Notifications - list - notification - Opted out of notifications - description",
          },
          { a: (text) => <ButtonLink onClick={onGoToSettings}>{text}</ButtonLink> }
        ),
        message: intl.formatMessage({
          id: "navbar.notifications.list.notification.optedOutOfNotifications.title",
          defaultMessage: "Opted out of notifications of this type",
          description:
            "Navbar Notifications - list - notification - Opted out of notifications - title",
        }),
      });
    },
    orgId: organizationId,
    setting: {
      ...setting,
      value: switchOffNotificationSetting(setting.value, action),
    },
    userId: currentUserId,
  });
};

export default optOutSetting;
