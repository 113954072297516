import notification from "antd/es/notification";
import { getLocation, push, RouterLocation } from "connected-react-router";
import endOfDay from "date-fns/endOfDay";
import { addDays } from "date-fns/esm";
import startOfDay from "date-fns/startOfDay";
import { all, delay, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { isApiError } from "@mapmycustomers/shared/util/assert";

import configService from "@app/config/ConfigService";
import i18nService from "@app/config/I18nService";
import IntervalUnit from "@app/enum/IntervalUnit";
import MmcNotificationAction from "@app/enum/MmcNotificationAction";
import MmcNotificationGroupType from "@app/enum/MmcNotificationGroupType";
import MmcNotificationRouteType from "@app/enum/MmcNotificationRouteType";
import Path from "@app/enum/Path";
import RecordPaneTab from "@app/enum/preview/RecordPaneTab";
import {
  applyListViewSettings as applyActivityListViewSettings,
  updateActivityView,
} from "@app/scene/activity/store/actions";
import ViewMode from "@app/scene/deal/enum/ViewMode";
import {
  applyListViewSettings as applyDealListViewSettings,
  updateDealView,
} from "@app/scene/deal/store/actions";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import MmcNotification from "@app/types/MmcNotification";
import NotificationListResponse from "@app/types/viewModel/NotificationListResponse";
import mapActivityStatusOptionToFilter from "@app/util/activity/mapActivityStatusOptionToFilter";
import { getLegacyAppUrl } from "@app/util/appUrl";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";
import dealFieldModel from "@app/util/fieldModel/DealFieldModel";
import { parseApiDate } from "@app/util/parsers";

import authService from "../auth/AuthService";
import { getFunnelStages } from "../deal";
import { showEntityView } from "../entityView/actions";

import {
  clickOnNotification,
  fetchNotification,
  fetchNotifications,
  fetchNotificationsUnreadTotal,
  markNotificationsAsRead,
  showCadenceModal,
  showNotifications,
  updateNotificationReadStatus,
} from "./actions";
import { getNotifications, isOnlyMentions } from "./selectors";

const NOTIFICATION_PAGE_SIZE = 20;

const GROUP_URL_BY_TYPE: Record<MmcNotificationGroupType, string> = {
  [MmcNotificationGroupType.COMPANY]: "accounts",
  [MmcNotificationGroupType.DEAL]: "deals",
  [MmcNotificationGroupType.PERSON]: "contacts",
};

const ROUTE_URL_BY_TYPE: Record<MmcNotificationRouteType, string> = {
  [MmcNotificationRouteType.COMPANY]: "accounts",
  [MmcNotificationRouteType.PERSON]: "contacts",
};

export function* onFetchNotifications({
  payload: { updateTotalsOnly },
}: ReturnType<typeof fetchNotifications.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const notifications: MmcNotification[] = yield select(getNotifications);
    const onlyMentions: MmcNotification[] = yield select(isOnlyMentions);
    const response: NotificationListResponse<MmcNotification> = yield callApi(
      "fetchNotifications",
      organizationId,
      {
        $filters: onlyMentions
          ? {
              $and: [{ action: { $in: [MmcNotificationAction.MENTION] } }],
            }
          : undefined,
        $limit: NOTIFICATION_PAGE_SIZE,
        $offset: notifications.length,
        $order: "-updatedAt",
      }
    );
    const availableActionTypes = new Set(Object.values(MmcNotificationAction));

    yield put(
      fetchNotifications.success({
        notifications: updateTotalsOnly
          ? []
          : // We need to provide only notification types which web supports.
            response.data.filter(({ action }) => availableActionTypes.has(action)),
        total: response.total,
        unreadTotal: response.unreadTotal,
      })
    );
  } catch (error) {
    yield put(fetchNotifications.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onGetNotification({
  payload: { id, callback },
}: ReturnType<typeof fetchNotification.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const response: MmcNotification = yield callApi("getNotification", organizationId, id);
    callback?.(response);
    yield put(fetchNotification.success(response));
  } catch (error) {
    yield put(fetchNotification.failure(error));

    const hasNoAccess = isApiError(error) && error.status === 404;
    if (hasNoAccess) {
      const intl = i18nService.getIntl();
      if (intl) {
        notification.error({
          message: intl.formatMessage({
            id: "navbar.notifications.list.getNotification.noAccess",
            defaultMessage: "Cannot show notification as you are logged in to a different account.",
            description: "Navbar Notifications - list - cannot show notification error.",
          }),
        });
      }
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onFetchNotificationsUnreadTotal() {
  try {
    const organizationId: Organization["id"] | undefined = yield select(getOrganizationId);
    if (organizationId) {
      const response: NotificationListResponse<MmcNotification> = yield callApi(
        "fetchNotifications",
        organizationId,
        {
          $limit: 0,
        }
      );
      yield put(fetchNotificationsUnreadTotal.success(response.unreadTotal));
    }
  } catch (error) {
    yield put(fetchNotificationsUnreadTotal.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateNotificationReadStatus({
  payload: { id, readStatus },
}: ReturnType<typeof updateNotificationReadStatus.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("updateNotifications", organizationId, [id], { readStatus });
    yield put(updateNotificationReadStatus.success());
    yield put(fetchNotificationsUnreadTotal.request());
    yield put(fetchNotifications.request({ updateTotalsOnly: true }));
  } catch (error) {
    yield put(updateNotificationReadStatus.failure(error));
    yield put(handleError({ error }));
  }
}
export function* onMarkNotificationsAsRead() {
  try {
    const onlyMentions: boolean = yield select(isOnlyMentions);
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    yield callApi(
      "updateNotifications",
      organizationId,
      [],
      { readStatus: true },
      {
        $filters: onlyMentions ? { mention: true } : {},
      }
    );
    yield put(markNotificationsAsRead.success());
    yield put(fetchNotificationsUnreadTotal.request());
    yield put(fetchNotifications.request({ reload: true }));
  } catch (error) {
    yield put(markNotificationsAsRead.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onClickOnNotification({
  payload: { elementId, notification },
}: ReturnType<typeof clickOnNotification>) {
  const { action, entity, group, readStatus, route, updatedAt } = notification;
  const baseOldAppUrl = configService.getBaseOldAppUrl();
  const token = authService.getToken();
  const { pathname }: RouterLocation<any> = yield select(getLocation);
  const dealListPath = `${Path.DEAL}/${ViewMode.LIST}`;
  const activityListPath = `${Path.ACTIVITY}/${ViewMode.LIST}`;

  if (
    token &&
    group &&
    [
      MmcNotificationAction.COMPANY_GROUP_SHARED,
      MmcNotificationAction.DEAL_GROUP_SHARED,
      MmcNotificationAction.PEOPLE_GROUP_SHARED,
    ].includes(action)
  ) {
    if (!readStatus) {
      yield put(updateNotificationReadStatus.request({ id: notification.id, readStatus: true }));
    }
    window.location.href = getLegacyAppUrl(
      baseOldAppUrl,
      token,
      `${GROUP_URL_BY_TYPE[group.type]}/groups/list/${group.id}`
    );
  } else if (
    token &&
    route &&
    [
      MmcNotificationAction.COMPANY_ROUTE_SHARED,
      MmcNotificationAction.PEOPLE_ROUTE_SHARED,
    ].includes(action)
  ) {
    if (!readStatus) {
      yield put(updateNotificationReadStatus.request({ id: notification.id, readStatus: true }));
    }
    window.location.href = getLegacyAppUrl(
      baseOldAppUrl,
      token,
      `${ROUTE_URL_BY_TYPE[route.type]}/routing/edit/${route.id}`
    );
  }

  if (entity && entity.length === 1) {
    if (
      [
        MmcNotificationAction.ACTIVITY_ASSIGNED,
        MmcNotificationAction.ACTIVITY_LOGGED,
        MmcNotificationAction.ACTIVITY_STARTING,
        MmcNotificationAction.ACTIVITY_TIME_CHANGED,
        MmcNotificationAction.DEALS_MARKED_LOST,
        MmcNotificationAction.DEALS_MARKED_WON,
        MmcNotificationAction.DEALS_ROTTING,
        MmcNotificationAction.DEALS_STAGE_CHANGED,
        MmcNotificationAction.INCOMPLETE_ACTIVITY,
        MmcNotificationAction.MARK_ACTIVITY_AS_DONE,
        MmcNotificationAction.MENTION,
        MmcNotificationAction.NOTE_ADDED,
        MmcNotificationAction.OUT_OF_CADENCE,
        MmcNotificationAction.OVERDUE_ACTIVITIES,
        MmcNotificationAction.OWNED_DEALS_CLOSING,
        MmcNotificationAction.OWNERSHIP_CHANGED,
        MmcNotificationAction.SHARED_DEALS_CLOSING,
      ].includes(action)
    ) {
      yield put(
        showEntityView({
          elementId,
          entityId: entity[0].id,
          entityType: entity[0].type,
          focusOnPin: true,
          tab: [MmcNotificationAction.MENTION, MmcNotificationAction.NOTE_ADDED].includes(action)
            ? RecordPaneTab.NOTES
            : undefined,
        })
      );
    }
  } else if (entity && entity.length > 1) {
    const funnelStages: Record<Funnel["id"], Stage[]> = yield select(getFunnelStages);
    if (MmcNotificationAction.DEALS_MARKED_LOST === action) {
      const closedLostStageIds = Object.values(funnelStages)
        .reduce<Stage[]>((result, stages) => [...result, ...stages], [])
        .filter(({ type }) => [DealStageType.LOST].includes(type))
        .map(({ id }) => id);
      const stateToUpdate = {
        filter: {
          stage: {
            operator: FilterOperator.IN_ANY,
            value: closedLostStageIds,
          },
        },
      };
      if (pathname === dealListPath) {
        yield put(
          updateDealView({
            viewState: stateToUpdate,
          })
        );
      } else {
        yield put(push(`${Path.DEAL}/${ViewMode.LIST}`));
        yield put(applyDealListViewSettings(stateToUpdate));
      }
    } else if (MmcNotificationAction.DEALS_MARKED_WON === action) {
      const closedWonStageIds = Object.values(funnelStages)
        .reduce<Stage[]>((result, stages) => [...result, ...stages], [])
        .filter(({ type }) => [DealStageType.WON].includes(type))
        .map(({ id }) => id);

      const stateToUpdate = {
        filter: {
          stage: {
            operator: FilterOperator.IN_ANY,
            value: closedWonStageIds,
          },
        },
      };
      if (pathname === dealListPath) {
        yield put(
          updateDealView({
            viewState: stateToUpdate,
          })
        );
      } else {
        yield put(push(`${Path.DEAL}/${ViewMode.LIST}`));
        yield put(applyDealListViewSettings(stateToUpdate));
      }
    } else if (MmcNotificationAction.DEALS_ROTTING === action) {
      const stateToUpdate = {
        filter: {
          rottingDaysOut: {
            operator: FilterOperator.IS_OVERDUE,
            value: undefined,
          },
        },
      };
      if (pathname === dealListPath) {
        yield put(
          updateDealView({
            viewState: stateToUpdate,
          })
        );
      } else {
        yield put(push(`${Path.DEAL}/${ViewMode.LIST}`));
        yield put(applyDealListViewSettings(stateToUpdate));
      }
    } else if (MmcNotificationAction.DEALS_STAGE_CHANGED === action) {
      const stateToUpdate = {
        filter: {},
        sort: [{ field: dealFieldModel.getByName("updatedAt")!, order: SortOrder.DESC }],
      };
      if (pathname === dealListPath) {
        yield put(
          updateDealView({
            viewState: stateToUpdate,
          })
        );
      } else {
        yield put(push(`${Path.DEAL}/${ViewMode.LIST}`));
        yield put(applyDealListViewSettings(stateToUpdate));
      }
    } else if (
      [
        MmcNotificationAction.OWNED_DEALS_CLOSING,
        MmcNotificationAction.SHARED_DEALS_CLOSING,
      ].includes(action)
    ) {
      const daysToAdd =
        entity[0].interval === IntervalUnit.WEEK
          ? 7
          : entity[0].interval === IntervalUnit.MONTH
          ? 30
          : 1;
      const day = startOfDay(addDays(parseApiDate(updatedAt), daysToAdd));

      const stateToUpdate = {
        filter: {
          closingDate: {
            operator: FilterOperator.IN_RANGE,
            value: [startOfDay(day).toISOString(), endOfDay(day).toISOString()],
          },
        },
      };
      if (pathname === dealListPath) {
        yield put(
          updateDealView({
            viewState: stateToUpdate,
          })
        );
      } else {
        yield put(push(`${Path.DEAL}/${ViewMode.LIST}`));
        yield put(applyDealListViewSettings(stateToUpdate));
      }
    } else if (MmcNotificationAction.OVERDUE_ACTIVITIES === action) {
      const stateToUpdate = {
        filter: mapActivityStatusOptionToFilter[ActivityStatusOption.OVERDUE] as FilterModel,
        sort: [{ field: activityFieldModel.getByName("startAt")!, order: SortOrder.ASC }],
      };
      if (pathname === activityListPath) {
        yield put(
          updateActivityView({
            viewState: stateToUpdate,
          })
        );
      } else {
        yield put(push(`${Path.ACTIVITY}/${ViewMode.LIST}`));
        yield put(applyActivityListViewSettings(stateToUpdate));
      }
    } else if (MmcNotificationAction.OUT_OF_CADENCE === action) {
      yield put(showCadenceModal.request(notification));
    }
  }
  if (!readStatus) {
    yield put(updateNotificationReadStatus.request({ id: notification.id, readStatus: true }));
  }
}

export function* onShowCadenceModal({ payload }: ReturnType<typeof showCadenceModal.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const companyIds: Company["id"][] = [];
    const peopleIds: Person["id"][] = [];
    const dealIds: Deal["id"][] = [];
    payload.entity!.forEach(({ id, type }) => {
      if (type === EntityType.COMPANY) {
        companyIds.push(id);
      }
      if (type === EntityType.PERSON) {
        peopleIds.push(id);
      }
      if (type === EntityType.DEAL) {
        dealIds.push(id);
      }
    });
    const [companyResponse, peopleResponse, dealResponse]: [
      ListResponse<Company> | undefined,
      ListResponse<Person> | undefined,
      ListResponse<Deal> | undefined
    ] = yield all([
      companyIds.length
        ? callApi("fetchCompanies", organizationId, {
            $filters: {
              $and: [{ id: { $in: companyIds } }],
              includeGroups: true,
            },
          })
        : undefined,
      peopleIds.length
        ? callApi("fetchPeople", organizationId, {
            $filters: {
              $and: [{ id: { $in: peopleIds } }],
              includeGroups: true,
            },
          })
        : undefined,
      dealIds.length
        ? callApi("fetchDeals", organizationId, {
            $filters: {
              $and: [{ id: { $in: dealIds } }],
              includeGroups: true,
            },
          })
        : undefined,
    ]);
    yield put(
      showCadenceModal.success({
        entities: payload.entity!.map((notificationEntity) => {
          let entity: Company | Deal | Person | undefined;
          if (notificationEntity.type === EntityType.COMPANY) {
            entity = (companyResponse?.data ?? []).find(({ id }) => id === notificationEntity.id);
          }
          if (notificationEntity.type === EntityType.PERSON) {
            entity = (peopleResponse?.data ?? []).find(({ id }) => id === notificationEntity.id);
          }
          if (notificationEntity.type === EntityType.DEAL) {
            entity = (dealResponse?.data ?? []).find(({ id }) => id === notificationEntity.id);
          }

          return {
            ...entity,
            entity: notificationEntity.type,
          } as OutOfCadenceEntity;
        }),
        notification: payload,
      })
    );
  } catch (error) {
    yield put(showCadenceModal.failure(error));
    yield put(handleError({ error }));
  }
}

function* onShowNotifications() {
  // reload notifications every time popover is shown
  yield put(fetchNotifications.request({ onlyMentions: false, reload: true }));
}

function* fetchNotificationsPeriodically() {
  while (true) {
    // only fetch when token is defined
    if (authService.hasToken()) {
      yield put(fetchNotificationsUnreadTotal.request());
    }
    yield delay(60000);
  }
}

export function* notificationSaga() {
  yield takeEvery(clickOnNotification, onClickOnNotification);
  yield takeEvery(fetchNotifications.request, onFetchNotifications);
  yield takeEvery(fetchNotification.request, onGetNotification);
  yield takeEvery(fetchNotificationsUnreadTotal.request, onFetchNotificationsUnreadTotal);
  yield takeEvery(updateNotificationReadStatus.request, onUpdateNotificationReadStatus);
  yield takeEvery(markNotificationsAsRead.request, onMarkNotificationsAsRead);
  yield takeEvery(showCadenceModal.request, onShowCadenceModal);
  yield takeLatest(showNotifications, onShowNotifications);
  yield fork(fetchNotificationsPeriodically);
}
