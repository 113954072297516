import { useEffect } from "react";

import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";

import MapHandlerMap from "@app/types/map/MapHandlerMap";

import doubleClickDetector from "./doubleClickDetector";

const useMapEventHandler = <M extends google.maps.Map, N extends keyof MapHandlerMap<M>>(
  map: M | undefined,
  eventName: N,
  handler: MapHandlerMap<M>[N]
) => {
  const internalHandler = useDynamicCallback(function (this: M, ...args: any) {
    if (eventName === "click") {
      // Stop event from default handling.
      // We need this because with doubleClickDetector there's a timeout between event
      // and when we start processing it. Hence, in some cases google maps can handle
      // this event themselves, e.g. POI clicks, which is unwanted.
      (args[0] as google.maps.MapMouseEvent).stop();
      doubleClickDetector.handleSingleClick(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
        (handler as Function).apply(this, args);
      });
      return;
    } else if (eventName === "dblclick") {
      doubleClickDetector.handleDoubleClick();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    (handler as Function).apply(this, args);
  });

  useEffect(() => {
    if (map) {
      const listener = map.addListener(eventName, internalHandler);
      return () => {
        listener.remove();
      };
    }
  }, [eventName, internalHandler, map]);
};

export default useMapEventHandler;
