import React, { useCallback, useState } from "react";

import cn from "classnames";
import capitalize from "lodash-es/capitalize";
import { useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import SelectField from "@app/component/input/SelectField";
import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import useCountryListOptions from "@app/util/countries/useCountryListOptions";
import useRegionsListOptions from "@app/util/regions/useRegionsListOptions";

import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "../getHumanReadableDescriptionForOptionsFilter";

import styles from "./RegionCodeFilter.module.scss";

type STATE_VALUE = [countryCode: string | undefined, regionCodes: string[]];

export const REGION_CODE_FILTER_OPERATORS = [FilterOperator.IN_ANY, FilterOperator.NONE_OF];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.length === 2 &&
  value[1].every((item: unknown) => typeof item === "string") &&
  REGION_CODE_FILTER_OPERATORS.includes(operator);

interface RegionCodeFilterProps extends IFilterComponentProps {}

const RegionCodeFilter: IFilterInstance = {
  doesSupportValue,
  getComponent: (): React.FC<RegionCodeFilterProps> => {
    return ({ className, focus, onChange, value }) => {
      const intl = useIntl();

      const setRef = useCallback(
        (ref: null | SelectField<string>) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      const [[countryCode, regionCodes], setState] = useState<STATE_VALUE>(() =>
        value.value && Array.isArray(value.value) && value.value.length === 2
          ? (value.value as STATE_VALUE)
          : [undefined, []]
      );

      const countryListOptions = useCountryListOptions(intl);
      const handleCountryCodeChange = useCallback(
        (countryCode: string) =>
          setState((prevState) => {
            if (prevState[0] === countryCode) {
              return prevState;
            } else {
              onChange?.({ ...value, value: [countryCode, []] });
              return [countryCode, []];
            }
          }),
        [onChange, value]
      );

      const regions = useRegionsListOptions(countryCode);
      const handleRegionsChange = useCallback(
        (regions: string[]) =>
          setState(([countryCode]) => {
            onChange?.({ ...value, value: [countryCode, regions] });
            return [countryCode, regions];
          }),
        [onChange, value]
      );

      return (
        <div className={cn(styles.container, className)}>
          <SelectField<string>
            className={styles.countryDropdown}
            dropdownMatchSelectWidth={false}
            onChange={handleCountryCodeChange}
            optionFilterProp="text"
            options={countryListOptions}
            placeholder={intl.formatMessage({
              id: "regionField.filter.country",
              defaultMessage: "Country",
              description: "Country field on the Region Field's filter",
            })}
            ref={setRef}
            showSearch
            value={countryCode}
          />
          <SelectField<string[]>
            allowClear
            className={styles.stateDropdown}
            dropdownMatchSelectWidth={false}
            filterOption={defaultFilterOption}
            maxTagCount="responsive"
            mode="multiple"
            onChange={handleRegionsChange}
            options={regions}
            placeholder={intl.formatMessage({
              id: "regionField.filter.region",
              defaultMessage: "States/Regions",
              description: "State/Region field on the Region Field's filter",
            })}
            showSearch
            value={regionCodes}
          />
        </div>
      );
    };
  },
  getHumanReadableDescription: (value: SimpleCondition, field: IField) =>
    doesSupportValue(value.value, value.operator)
      ? getHumanReadableDescriptionForOptionsFilter(
          field,
          value.operator as OptionFilterOperator,
          value.value.map(capitalize)
        )
      : undefined,
};

export default RegionCodeFilter;
