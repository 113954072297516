import { createReducer } from "typesafe-actions";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { EntityTypesSupportedByIntegrations } from "@mapmycustomers/shared/types/entity";

import StatusOption from "@app/scene/settings/component/Integrations/enum/StatusOption";
import { ALL_TIME } from "@app/scene/settings/component/Integrations/utils/consts";
import Integration from "@app/types/integrations/Integration";
import IntegrationField from "@app/types/integrations/IntegrationField";
import IntegrationFieldResponse from "@app/types/integrations/IntegrationFieldResponse";
import { IntegrationSchedule } from "@app/types/integrations/IntegrationSchedule";
import IntegrationSync from "@app/types/integrations/IntegrationSync";
import IntegrationUser from "@app/types/integrations/IntegrationUser";
import IntegrationViewState from "@app/types/viewModel/IntegrationSyncsViewState";
import integrationFieldModel from "@app/util/fieldModel/IntegrationFieldModel";

import {
  Actions,
  changeFrequency,
  changeIntegrationField,
  changeIntegrationFieldGroup,
  changeIntegrationUser,
  changeIntegrationUserSync,
  changeOrgUrl,
  changeStartSyncDate,
  changeSyncOption,
  changeUnassignedRecordsOwner,
  hideMatchingFieldsWarning,
  initializeEditPage,
  initializeHomePage,
  initializeMappingStep,
  saveIntegration,
  setCurrentIntegration,
  setCustomFieldIntegrationResponse,
  setIntegrationFields,
  toggleEmailSyncState,
  toggleFileSyncing,
  toggleIntegrationStatus,
  toggleNoteSyncing,
  updateIntegrationSyncsViewState,
} from "./actions";
import getSchedule from "./getSchedule";

export type IntegrationFieldsMapping = {
  [key in EntityTypesSupportedByIntegrations]: IntegrationField[];
};

export const EmptyIntegrationFieldsMappingState: IntegrationFieldsMapping = Object.freeze({
  [EntityType.ACTIVITY]: [],
  [EntityType.COMPANY]: [],
  [EntityType.DEAL]: [],
  [EntityType.PERSON]: [],
});

export const CreateNewCustomField = "__mmc_create_new_field";

export type IntegrationsState = {
  currentIntegration?: Integration;
  customFieldIntegrationResponse: IntegrationFieldResponse[];
  defaultSchedule: IntegrationSchedule;
  error: undefined | unknown;
  integrationChanged: boolean;
  integrationFields: IntegrationFieldsMapping;
  integrationFieldsChanged: boolean;
  integrations: Integration[];
  integrationSyncs: IntegrationSync[];
  integrationSyncsTotal: number;
  integrationUsers: IntegrationUser[];
  integrationUsersChanged: boolean;
  lastIntegrationSync?: IntegrationSync;
  loading: boolean;
  loadingFields: boolean;
  matchingFieldsWarningVisibility: boolean;
  needToSelectDynamicsInstance: boolean;
  saving: boolean;
  syncsLoading: boolean;
  viewState: IntegrationViewState;
};

const initialState: IntegrationsState = {
  currentIntegration: undefined,
  customFieldIntegrationResponse: [],
  defaultSchedule: getSchedule(),
  error: undefined,
  integrationChanged: false,
  integrationFields: { ...EmptyIntegrationFieldsMappingState },
  integrationFieldsChanged: false,
  integrations: [],
  integrationSyncs: [],
  integrationSyncsTotal: 0,
  integrationUsers: [],
  integrationUsersChanged: false,
  loading: false,
  loadingFields: false,
  matchingFieldsWarningVisibility: false,
  needToSelectDynamicsInstance: false,
  saving: false,
  syncsLoading: false,
  viewState: {
    columns: integrationFieldModel.getDefaultListViewState().columns,
    dateRange: undefined,
    dateRangeOption: ALL_TIME,
    sort: "createdAt",
    sortOrder: SortOrder.DESC,
    statusOption: undefined,
  },
};

const integrations = createReducer<IntegrationsState, Actions>(initialState)
  .handleAction(setCurrentIntegration, (state, action) => ({
    ...state,
    currentIntegration: action.payload.currentIntegration,
  }))
  .handleAction(initializeEditPage.request, (state) => ({
    ...state,
    integrationChanged: false,
    integrationFieldsChanged: false,
    integrationUsersChanged: false,
    loading: true,
    matchingFieldsWarningVisibility: true,
    needToSelectDynamicsInstance: false,
  }))
  .handleAction(hideMatchingFieldsWarning, (state) => ({
    ...state,
    matchingFieldsWarningVisibility: false,
  }))
  .handleAction(initializeEditPage.failure, (state) => ({
    ...state,
    loading: false,
    needToSelectDynamicsInstance: false,
  }))
  .handleAction(
    initializeEditPage.success,
    (
      state,
      {
        payload: {
          defaultSchedule,
          integration,
          integrationFields,
          integrationSyncs,
          integrationUsers,
          needToSelectDynamicsInstance,
        },
      }
    ) => ({
      ...state,
      currentIntegration: integration,
      defaultSchedule,
      integrationFields: integrationFields ?? state.integrationFields,
      integrationSyncs: integrationSyncs ?? state.integrationSyncs,
      integrationUsers: integrationUsers ?? state.integrationUsers,
      loading: false,
      needToSelectDynamicsInstance: !!needToSelectDynamicsInstance,
    })
  )
  .handleAction(initializeMappingStep.request, (state) => ({
    ...state,
    loadingFields: true,
  }))
  .handleAction(initializeMappingStep.failure, (state) => ({
    ...state,
    loadingFields: false,
  }))
  .handleAction(initializeMappingStep.success, (state, action) => ({
    ...state,
    integrationFields: action.payload.integrationFields,
    loadingFields: false,
  }))
  .handleAction(saveIntegration.request, (state) => ({
    ...state,
    saving: true,
  }))
  .handleAction(saveIntegration.success, (state) => ({
    ...state,
    integrationChanged: false,
    integrationFieldsChanged: false,
    integrationUsersChanged: false,
    saving: false,
  }))
  .handleAction(saveIntegration.failure, (state) => ({
    ...state,
    saving: false,
  }))
  .handleAction(initializeHomePage.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(initializeHomePage.success, (state, action) => ({
    ...state,
    currentIntegration: action.payload.integration,
    lastIntegrationSync: action.payload.lastIntegrationSync,
    loading: false,
  }))
  .handleAction(initializeHomePage.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateIntegrationSyncsViewState.request, (state, action) => ({
    ...state,
    syncsLoading: true,
    viewState: {
      ...state.viewState,
      columns: action.payload.viewState?.columns ?? state.viewState.columns,
      dateRange:
        action.payload.viewState?.dateRangeOption === ALL_TIME
          ? action.payload.viewState?.dateRange
          : action.payload.viewState?.dateRange ?? state.viewState.dateRange,
      //specifically done to store undefined in case some specific date range is selected
      dateRangeOption: action.payload.viewState?.dateRangeOption ?? undefined,
      sort: action.payload.viewState?.sort,
      sortOrder: action.payload.viewState?.sortOrder,
      statusOption:
        action.payload.viewState?.statusOption === StatusOption.ANY
          ? undefined
          : action.payload.viewState?.statusOption ?? state.viewState.statusOption,
    },
  }))
  .handleAction(updateIntegrationSyncsViewState.success, (state, action) => ({
    ...state,
    integrationSyncs: action.payload.integrationSyncs,
    integrationSyncsTotal: action.payload.total,
    syncsLoading: false,
  }))
  .handleAction(updateIntegrationSyncsViewState.failure, (state) => ({
    ...state,
    syncsLoading: false,
  }))
  .handleAction(changeFrequency, (state, action) => {
    if (state.currentIntegration) {
      const schedule = {
        ...(state.currentIntegration.schedule || state.defaultSchedule),
        frequency: action.payload,
      };
      return {
        ...state,
        currentIntegration: { ...state.currentIntegration, schedule },
        integrationChanged: true,
      };
    }
    return state;
  })
  .handleAction(changeStartSyncDate, (state, action) => {
    if (state.currentIntegration) {
      const schedule = {
        ...(state.currentIntegration.schedule || state.defaultSchedule),
        nextRunAt: action.payload,
      };
      return {
        ...state,
        currentIntegration: { ...state.currentIntegration, schedule },
        integrationChanged: true,
      };
    }
    return state;
  })
  .handleAction(changeSyncOption, (state, { payload }) => {
    const { entityType, incoming, outgoing } = payload;
    if (state.currentIntegration) {
      const currentIntegration = { ...state.currentIntegration };
      currentIntegration!.syncOptions[entityType] = { incoming, outgoing };
      return { ...state, currentIntegration, integrationChanged: true };
    }
    return state;
  })
  .handleAction(setIntegrationFields, (state, action) => ({
    ...state,
    integrationFields: action.payload.integrationFields,
    integrationFieldsChanged: true,
  }))
  .handleAction(changeIntegrationUser, (state, { payload }) => {
    const { integrationUser, mmcUserId } = payload;

    const index = state.integrationUsers.findIndex(({ id }) => id === integrationUser.id);
    if (index < 0) {
      return state;
    }
    return {
      ...state,
      integrationChanged: true,
      integrationUsers: [
        ...state.integrationUsers.slice(0, index),
        {
          ...state.integrationUsers[index],
          syncing: !!mmcUserId,
          userId: mmcUserId ?? null,
        },
        ...state.integrationUsers.slice(index + 1),
      ],
      integrationUsersChanged: true,
    };
  })
  .handleAction(changeIntegrationUserSync, (state, { payload }) => {
    const { enabled, integrationUser } = payload;
    const index = state.integrationUsers.findIndex(({ id }) => id === integrationUser.id);
    if (index < 0) {
      return state;
    }
    return {
      ...state,
      integrationChanged: true,
      integrationUsers: [
        ...state.integrationUsers.slice(0, index),
        {
          ...state.integrationUsers[index],
          syncing: enabled,
        },
        ...state.integrationUsers.slice(index + 1),
      ],
      integrationUsersChanged: true,
    };
  })
  .handleAction(changeIntegrationField, (state, action) => {
    const { payload } = action;
    const { entityType, field, integrationField, isCustomField } = payload;
    const integrationFields = { ...state.integrationFields };

    const index = integrationFields[entityType].findIndex(({ id }) => id === integrationField.id);
    if (index < 0) {
      return state;
    }
    integrationFields[entityType] = [
      ...integrationFields[entityType].slice(0, index),
      {
        ...integrationFields[entityType][index],
        customField: isCustomField,
        mmcField: isCustomField ? CreateNewCustomField : field?.name ?? null,
      },
      ...integrationFields[entityType].slice(index + 1),
    ];

    return {
      ...state,
      integrationChanged: true,
      integrationFields,
      integrationFieldsChanged: true,
    };
  })
  .handleAction(changeIntegrationFieldGroup, (state, action) => {
    const { payload } = action;
    const { enabled, entityType, integrationField } = payload;
    const integrationFields = { ...state.integrationFields };

    const index = integrationFields[entityType].findIndex(({ id }) => id === integrationField.id);
    if (index < 0) {
      return state;
    }

    integrationFields[entityType] = [
      ...integrationFields[entityType].slice(0, index),
      {
        ...integrationFields[entityType][index],
        mmcGroupField: enabled || null,
      },
      ...integrationFields[entityType].slice(index + 1),
    ];

    return {
      ...state,
      integrationChanged: true,
      integrationFields,
      integrationFieldsChanged: true,
    };
  })
  .handleAction(toggleIntegrationStatus, (state, action) => {
    const { payload } = action;
    const { integrationId } = payload;
    const index = state.integrations.findIndex(({ id }) => id === integrationId);
    if (index < 0) {
      return state;
    }
    state.integrations[index] = {
      ...state.integrations[index],
      isLocked: !state.integrations[index].isLocked,
    };
    return {
      ...state,
      integrations: [
        ...state.integrations.slice(0, index),
        {
          ...state.integrations[index],
          isLocked: !state.integrations[index].isLocked,
        },
        ...state.integrations.slice(index + 1),
      ],
    };
  })
  .handleAction(setCustomFieldIntegrationResponse, (state, action) => ({
    ...state,
    customFieldIntegrationResponse: action.payload,
  }))
  .handleAction(toggleEmailSyncState, (state) => {
    if (state.currentIntegration) {
      const metadata = {
        ...state.currentIntegration.metadata,
        emailSyncStats: !state.currentIntegration.metadata?.emailSyncStats,
      };
      return {
        ...state,
        currentIntegration: { ...state.currentIntegration, metadata },
        integrationChanged: true,
      };
    }
    return state;
  })
  .handleAction(changeUnassignedRecordsOwner, (state, { payload: unassignedRecordsOwner }) => {
    if (state.currentIntegration) {
      return {
        ...state,
        currentIntegration: {
          ...state.currentIntegration,
          metadata: {
            ...state.currentIntegration.metadata,
            unassignedRecordsOwner,
          },
        },
        integrationChanged: true,
      };
    }
    return state;
  })
  .handleAction(changeOrgUrl.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction([changeOrgUrl.success, changeOrgUrl.failure], (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(toggleNoteSyncing, (state, { payload: entityType }) => {
    if (state.currentIntegration) {
      return {
        ...state,
        currentIntegration: {
          ...state.currentIntegration,
          metadata: {
            ...state.currentIntegration.metadata,
            noteSyncing: {
              ...(state.currentIntegration.metadata?.noteSyncing ?? {}),
              [entityType]: !state.currentIntegration.metadata?.noteSyncing?.[entityType],
            },
          },
        },
        integrationChanged: true,
      };
    }
    return state;
  })
  .handleAction(toggleFileSyncing, (state, { payload: entityType }) => {
    if (state.currentIntegration) {
      return {
        ...state,
        currentIntegration: {
          ...state.currentIntegration,
          metadata: {
            ...state.currentIntegration.metadata,
            fileSyncing: {
              ...(state.currentIntegration.metadata?.fileSyncing ?? {}),
              [entityType]: !state.currentIntegration.metadata?.fileSyncing?.[entityType],
            },
          },
        },
        integrationChanged: true,
      };
    }
    return state;
  });

export type IntegrationActions = Actions;
export default integrations;
