import { defineMessages } from "react-intl";

export const messages = defineMessages({
  nowRelated: {
    id: "relatedEntitiesMatching.nowRelated",
    defaultMessage: "The records you selected are now related to each other.",
    description:
      "Related message when the selected deal is not associated with the selected person",
  },
  recommend: {
    id: "relatedEntitiesMatching.recommend",
    defaultMessage: "We recommend relating the relationship records so your data is more accurate.",
    description: "Error message when the selected deal is not associated with the selected person",
  },
  relateRecords: {
    id: "relatedEntitiesMatching.relateRecords",
    defaultMessage: "Relate records",
    description: "Button label to relate records",
  },
  undo: {
    id: "relatedEntitiesMatching.undo",
    defaultMessage: "Undo",
    description: "Button label to Undo",
  },
});
