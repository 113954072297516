import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

import CountryFilter, { COUNTRY_FILTER_OPERATORS } from "@app/util/filters/CountryFilter";

import Field, { FieldProperties } from "./Field";
import { countryFormatter } from "./fieldUtil";

class CountryField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      csvExportProperties: { valueFormatter: countryFormatter },
      customFilterConfig: {
        defaultInstance: "country",
        filterInstances: {
          country: CountryFilter,
        },
        operators: [...COUNTRY_FILTER_OPERATORS],
      },
      customGridProperties: {
        cellRenderer: "countryCellRenderer",
      },
      exportColumnName: "country",
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.ADDRESS,
        FieldFeature.BULK_EDIT,
        FieldFeature.COUNTRY_FIELD,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      importProperties: { name: "country", required: true },
      type: FieldType.STRING,
      ...data,
    });
  }

  /**
   * @override
   */
  get integrationName(): string {
    return "country";
  }
}

export default CountryField;
