import React, { FC, ReactNode, useCallback } from "react";

import Tooltip, { TooltipProps } from "antd/es/tooltip";
import cn from "classnames";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";

import Chip, { ChipProps } from "@app/component/Chip";
import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";
import useAnalytics from "@app/util/contexts/useAnalytics";
import { getEntityTypeAnalyticsName } from "@app/util/ui/getEntityTypeDisplayName";

import styles from "./EntityChip.module.scss";

type SupportedEntityIds = (Company | Deal | Person)["id"];
type SupportedEntityTypes =
  | EntityType.COMPANY
  | EntityType.COMPANY_ROUTE
  | EntityType.DEAL
  | EntityType.PEOPLE_ROUTE
  | EntityType.PERSON
  | EntityType.ROUTE;

export interface EntityChipProps extends Pick<ChipProps, "inline"> {
  className?: string;
  entityId: SupportedEntityIds;
  entityType: SupportedEntityTypes;
  name: string;
  onClick?: (payload: { entityId: SupportedEntityIds; entityType: SupportedEntityTypes }) => void;
  suppressTooltip?: boolean;
  tooltip?: ReactNode;
  tooltipProps?: Omit<TooltipProps, "title">;
}

const EntityChip: FC<EntityChipProps> = ({
  className,
  entityId,
  entityType,
  name,
  onClick,
  suppressTooltip,
  tooltip,
  tooltipProps,
  ...props
}) => {
  const analytics = useAnalytics();

  const handleClick = useCallback(() => {
    analytics.clicked([getEntityTypeAnalyticsName(entityType, false, false)]);
    onClick?.({ entityId, entityType });
  }, [entityId, entityType, onClick, analytics]);

  return (
    <Tooltip title={tooltip ?? name} trigger={suppressTooltip ? [] : undefined} {...tooltipProps}>
      <Chip
        className={cn(styles.container, className)}
        icon={
          <EntityTypeShapedIcon
            containerClassName={styles.icon}
            entityType={entityType}
            onClick={handleClick}
            shape="square"
          />
        }
        onClick={handleClick}
        {...props}
      >
        <span className={styles.text}>{name}</span>
      </Chip>
    </Tooltip>
  );
};

export default EntityChip;
