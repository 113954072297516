import { createSelector } from "reselect";

import { getHiddenTerritoriesIds } from "@app/store/iam";

import { RootState } from "../rootReducer";

const territoriesState = (state: RootState) => state.territories;

export const areTerritoriesLoading = createSelector(territoriesState, (state) => state.loading);

export const haveAnyTerritories = createSelector(
  territoriesState,
  (state) => state.territories.length > 0
);

export const getTerritories = createSelector(
  territoriesState,
  getHiddenTerritoriesIds,
  ({ territories }, hiddenIds) =>
    territories.map((territory) => ({
      ...territory,
      territoryDetail: {
        ...territory.territoryDetail,
        hidden: hiddenIds.includes(territory.id),
      },
    }))
);

export const isLoading = createSelector(territoriesState, ({ loading }) => loading);
export const isCreating = createSelector(territoriesState, ({ creating }) => creating);
export const isDeleting = createSelector(territoriesState, ({ deleting }) => deleting);
export const getTotal = createSelector(territoriesState, ({ total }) => total);
