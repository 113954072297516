// DoubleClickDetector is used to correctly process double clicks with Google maps.
// GMaps don't have a native way to do that: every time you do a double-click,
// it will also trigger a click event.
// However, we need to distinguish them and we use this detector for that.
// All single click events are kept in "jail" for the TIMEOUT milliseconds.
// If double-click event comes within this TIMEOUT, we cancel all click handlers.
// If not, we proceed with them.
class DoubleClickDetector {
  private static TIMEOUT = 200;

  private singleClickHandlers: (() => void)[] = [];
  private singleClickTimeout: number | undefined;

  private callSingleClickHandlers() {
    this.singleClickHandlers.forEach((handler) => handler());
    this.singleClickHandlers = [];
    this.singleClickTimeout = undefined;
  }

  public handleSingleClick(handler: () => void) {
    this.singleClickHandlers.push(handler);
    if (!this.singleClickTimeout) {
      this.singleClickTimeout = window.setTimeout(
        this.callSingleClickHandlers.bind(this),
        DoubleClickDetector.TIMEOUT
      );
    }
  }

  public handleDoubleClick() {
    window.clearTimeout(this.singleClickTimeout);
    this.singleClickTimeout = undefined;
    this.singleClickHandlers = [];
  }
}

const doubleClickDetector = new DoubleClickDetector();

export default doubleClickDetector;
