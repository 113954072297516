import React, { useMemo } from "react";

import cn from "classnames";
import { useIntl } from "react-intl";

import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

import ActivityTypeIcon from "@app/component/activity/ActivityTypeIcon";

import styles from "./useActivityTypeOptions.module.scss";

const no = () => false;

export interface ActivityTypeOption {
  disabled: boolean;
  label: React.ReactNode;
  text: string;
  value: ActivityType["id"];
}

const useActivityTypeOptions = (
  activityTypes: ActivityType[],
  value?: ActivityType["id"] | Array<ActivityType["id"]>,
  isActivityTypeDisabled: (type: ActivityType | undefined) => boolean | undefined = no
): ActivityTypeOption[] => {
  const intl = useIntl();
  return useMemo(() => {
    const values = Array.isArray(value) ? value : [value];
    return activityTypes
      .filter(({ id, active }) => active || values?.includes(id))
      .sort((a, b) => {
        const aDisabled = isActivityTypeDisabled(a);
        const bDisabled = isActivityTypeDisabled(b);
        return aDisabled === bDisabled ? 0 : aDisabled ? 1 : -1;
      })
      .map((activityType) => {
        return {
          disabled: (isActivityTypeDisabled(activityType) || !activityType.active) ?? false,
          label: (
            <>
              <ActivityTypeIcon
                activityType={activityType}
                className={cn({
                  [styles.disabledIcon]:
                    !activityType.active || isActivityTypeDisabled(activityType),
                })}
                size="sm"
              />
              <span className={styles.textMargin}>
                <span>{activityType.name}</span>
                <span className={styles.disabledOption}>
                  {!activityType.active &&
                    intl.formatMessage({
                      id: "activityOptions.disabled",
                      defaultMessage: "(Disabled)",
                      description: "Text for disabled activity type",
                    })}
                </span>
              </span>
            </>
          ),
          text: activityType.name,
          value: activityType.id,
        };
      });
  }, [activityTypes, intl, isActivityTypeDisabled, value]);
};

export default useActivityTypeOptions;
