import { all, call, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { Action, isActionOf } from "typesafe-actions";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import Organization from "@mapmycustomers/shared/types/Organization";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";

import { callApi } from "@app/store/api/callApi";
import { getOrganizationId } from "@app/store/iam";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";

import { fetchAllSchemas, fetchSchemaForEntityType } from "./actions";

const successForEntityTypeActionMatcher =
  (entityType: EntityTypesSupportingFieldCustomization) =>
  (action: Action): boolean =>
    isActionOf(fetchSchemaForEntityType.success, action) &&
    action.payload.entityType === entityType;

export function* onFetchSchema({
  payload: entityType,
}: ReturnType<typeof fetchSchemaForEntityType.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const schema: SchemaField[] = yield callApi("getSchema", orgId, entityType);

    const fieldModel: IFieldModel = yield call(getFieldModelByEntityType, entityType);
    fieldModel.setSchema(schema);

    yield put(fetchSchemaForEntityType.success({ entityType, schema }));
  } catch (error) {
    yield put(fetchSchemaForEntityType.failure(error));
  }
}

export function* onFetchAllSchemas() {
  try {
    // call fetchCustomFields actions
    yield put(fetchSchemaForEntityType.request(EntityType.COMPANY));
    yield put(fetchSchemaForEntityType.request(EntityType.PERSON));
    yield put(fetchSchemaForEntityType.request(EntityType.DEAL));
    yield put(fetchSchemaForEntityType.request(EntityType.ACTIVITY));

    // now wait till all succeed or any fails, whichever happens first
    const { failure } = yield race({
      failure: take([fetchSchemaForEntityType.failure]),
      success: all([
        take(successForEntityTypeActionMatcher(EntityType.ACTIVITY)),
        take(successForEntityTypeActionMatcher(EntityType.COMPANY)),
        take(successForEntityTypeActionMatcher(EntityType.DEAL)),
        take(successForEntityTypeActionMatcher(EntityType.PERSON)),
      ]),
    });

    if (failure) {
      yield put(fetchAllSchemas.failure());
    } else {
      yield put(fetchAllSchemas.success());
    }
  } catch {
    yield put(fetchAllSchemas.failure());
    // error is handled in the onInitializeApp saga
  }
}

export function* schemaSaga() {
  yield takeLatest(fetchAllSchemas.request, onFetchAllSchemas);
  yield takeEvery(fetchSchemaForEntityType.request, onFetchSchema);
}
