import React, { useCallback } from "react";
import { connect } from "react-redux";

import { useFlows } from "@frigade/react";
import Badge from "antd/es/badge";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import UserRef from "@mapmycustomers/shared/types/User";
import { Avatar } from "@mapmycustomers/ui";

import { HOME_ONBOARDING_FLOWS } from "@app/component/frigade/utils/const";
import configService from "@app/config/ConfigService";
import Environment from "@app/enum/Environment";
import Path from "@app/enum/Path";
import { logout } from "@app/store/auth/actions";
import { getMe } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import colors from "@app/styles/_colors.module.scss";
import { navbarUserMenuClick } from "@app/util/analytic/navbarAnalytics";
import { getLegacyLogoutAppUrl } from "@app/util/appUrl";
import frigadeService from "@app/util/frigade/frigadeService";

import styles from "./UserMenu.module.scss";
import UserProfileInfo from "./UserProfileInfo";

interface Props {
  logout: typeof logout;
  me: UserRef;
}

const UserMenu: React.FC<Props> = ({ logout, me }) => {
  const intl = useIntl();
  const baseOldAppUrl = configService.getBaseOldAppUrl();

  // Logout wrapped in useCallback to avoid click event getting into its arguments
  const handleLogoutClick = useCallback(
    () => logout(getLegacyLogoutAppUrl(baseOldAppUrl)),
    [baseOldAppUrl, logout]
  );

  const { getFlowStatus, markFlowNotStarted } = useFlows();
  const homeFlowId = HOME_ONBOARDING_FLOWS[me.role.key];
  const flowStatus = homeFlowId ? getFlowStatus(homeFlowId) : undefined;
  const shouldShowHomeOnboarding = flowStatus && flowStatus !== "COMPLETED_FLOW";
  const shouldShowBlueDot =
    shouldShowHomeOnboarding && frigadeService.flowsVisibility[homeFlowId] === false; // must use === here

  const { pathname } = useLocation();
  const isOnDashboardPage = pathname.startsWith(Path.DASHBOARD);

  const handleCompleteSetupClick = useCallback(async () => {
    if (!homeFlowId) {
      return;
    }

    await markFlowNotStarted(homeFlowId);
    frigadeService.showFlow(homeFlowId);

    if (!isOnDashboardPage) {
      window.open(`${window.location.origin}${Path.DASHBOARD}`, "_blank");
    }
  }, [isOnDashboardPage, homeFlowId, markFlowNotStarted]);

  const userMenuOptions = (
    <Menu className={styles.userMenuItem}>
      <Menu.Item className={styles.userProfileInfo} key="userProfileInfo">
        <UserProfileInfo user={me} />
      </Menu.Item>
      {shouldShowHomeOnboarding && (
        <>
          <Menu.Divider />
          <Menu.Item key="completeSetup" onClick={handleCompleteSetupClick}>
            {intl.formatMessage(
              {
                id: "navbar.userMenu.completeSetup",
                defaultMessage: "Complete Setup {icon}",
                description:
                  "Complete Setup menu item in the User menu shown when Home Onboarding is not yet complete",
              },
              {
                icon: <Badge color={colors.primary100} dot />,
              }
            )}
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      {configService.getCurrentEnvironment() !== Environment.SANDBOX && (
        <Menu.Item key="manageSubscription">
          <Link onClick={() => navbarUserMenuClick("manageSubscription")} to={Path.BILLING}>
            <FormattedMessage
              id="navbar.userMenu.manageSubscription"
              defaultMessage="Manage Your Subscription"
              description="Title of manage subscription option"
            />
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="support">
        <a
          href="https://support.mapmycustomers.com/hc/en-us"
          onClick={() => navbarUserMenuClick("support")}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="navbar.userMenu.support"
            defaultMessage="Support"
            description="Title of support option"
          />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <div className={styles.logout} onClick={handleLogoutClick}>
          <FormattedMessage
            id="navbar.userMenu.logout"
            defaultMessage="Logout"
            description="Title of logout option"
          />
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={userMenuOptions} placement="bottomRight" trigger={["click"]}>
      <div className={styles.container}>
        <Badge
          className={cn(styles.avatarDot, { [styles.avatarDotVisible]: shouldShowBlueDot })}
          dot
        >
          <Avatar size={36} tooltip={false} user={me} />
        </Badge>
      </div>
    </Dropdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  me: getMe(state)!, // profile must be loaded beforehand
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
