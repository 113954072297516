import React from "react";

import AntDLayout from "antd/es/layout";

import styles from "./Layout.module.scss";

interface Props {
  children: React.ReactNode;
}

const SsoLayout: React.FC<Props> = ({ children }) => {
  return (
    <AntDLayout className={styles.container}>
      <AntDLayout.Content className={styles.content}>
        <div className={styles.contentWrapper}>{children}</div>
      </AntDLayout.Content>
    </AntDLayout>
  );
};

export default SsoLayout;
