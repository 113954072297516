import Size from "@app/types/map/Size";

import EffectProperty from "./types/EffectProperty";

const getResultingSvg = (
  shapeSvg: string,
  shapeSize: Size,
  shapeAnchor: Size,
  shapeEffect?: EffectProperty,
  iconSvg?: string,
  badgeSvg?: string,
  badgeAnchor?: Size,
  badgeShapeIncreaser?: Size
): {
  anchor: Size;
  size: Size;
  svg: string;
} => {
  const overflowX = badgeShapeIncreaser?.[0] ?? 0;
  const overflowY = badgeShapeIncreaser?.[1] ?? 0;
  const responseSize: Size = [shapeSize[0] + overflowX * 2, shapeSize[1] + overflowY * 2];

  return {
    anchor: [shapeAnchor[0] + overflowX, shapeAnchor[1] + overflowY],
    size: responseSize,
    svg: `<svg width="${responseSize[0]}" height="${
      responseSize[1]
    }" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(${overflowX}, ${overflowY})">
      ${shapeEffect && `<defs>${shapeEffect.filter}</defs>`}
      ${shapeEffect ? ` <g ${shapeEffect.attributes}>${shapeSvg}</g>` : shapeSvg}
      ${iconSvg ?? ""}
      ${
        badgeSvg &&
        badgeAnchor &&
        `<g transform="translate(${badgeAnchor[0]}, ${badgeAnchor[1]})">${badgeSvg}</g>`
      }
    </g>
  </svg>`,
  };
};

export default getResultingSvg;
