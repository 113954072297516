import React, { useMemo } from "react";

import { RegistryProps } from "../../utils/ComponentRegistry";

const ExternalUrlField: React.FC<RegistryProps> = ({ entity, field }) => {
  const href = useMemo(() => field.getValueFor(entity) as string, [field, entity]);
  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      {href}
    </a>
  );
};

export default ExternalUrlField;
