import { defineMessages } from "react-intl";

const messages = defineMessages({
  addActivity: {
    id: "postCreationModal.actions.scheduleActivity",
    defaultMessage: "Schedule Activity",
    description: "Label of the Schedule Activity action",
  },
  addChildCompany: {
    id: "postCreationModal.actions.addChildCompany",
    defaultMessage: "Add a Child Company",
    description: "Label of the Add Child company",
  },
  addCompanies: {
    id: "postCreationModal.actions.addCompanies",
    defaultMessage: "Add Companies",
    description: "Label of the Add companies",
  },
  addCompany: {
    id: "postCreationModal.actions.addCompany",
    defaultMessage: "Add a Company",
    description: "Label of the Add company",
  },
  addDeal: {
    id: "postCreationModal.actions.addDeal",
    defaultMessage: "Add a Deal",
    description: "Label of the Add a deal",
  },
  addGroup: {
    id: "postCreationModal.actions.addGroup",
    defaultMessage: "Add to a Group",
    description: "Label of the Add to a group",
  },
  addParentCompany: {
    id: "postCreationModal.actions.addParentCompany",
    defaultMessage: "Add a Parent Company",
    description: "Label of the Add a parent company",
  },
  addPerson: {
    id: "postCreationModal.actions.addPerson",
    defaultMessage: "Add a Person",
    description: "Label of the Add a person",
  },
  addRoute: {
    id: "postCreationModal.actions.addRoute",
    defaultMessage: "Add to a Route",
    description: "Label of the Add to a route",
  },
  associationTitle: {
    id: "postCreateModal.actions.associationTitle",
    defaultMessage: "Add an association?",
    description: "Label of Post creation associations modal",
  },
  changeCompanies: {
    id: "postCreationModal.actions.changeCompanies",
    defaultMessage: "Change Companies",
    description: "Label of the change companies",
  },
  changeCompany: {
    id: "postCreationModal.actions.changeCompany",
    defaultMessage: "Change Company",
    description: "Label of the change company",
  },
  changeParentCompany: {
    id: "postCreationModal.actions.changeParentCompany",
    defaultMessage: "Change Parent Company",
    description: "Label of the change parent company",
  },
  changePerson: {
    id: "postCreationModal.actions.changePerson",
    defaultMessage: "Change Person",
    description: "Label of the change person",
  },
  okText: {
    id: "postCreateModal.footer.okText",
    defaultMessage: "Done",
    description: "Label for completed button",
  },
  subTitle: {
    id: "postCreateModal.association.subTitle",
    defaultMessage: "You just created <link>{recordname}</link>",
    description: "Title of Post creation associations modal",
  },
  title: {
    id: "postCreateModal.association.title",
    defaultMessage: "Nice Work!",
    description: "Label of Post creation associations modal",
  },
});

export default messages;
