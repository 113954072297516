import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import ActivityCustomFieldValue from "@mapmycustomers/shared/types/customField/ActivityCustomFieldValue";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import User from "@mapmycustomers/shared/types/User";

import localSettings from "@app/config/LocalSettings";
import { parseApiDate } from "@app/util/parsers";

import RecurringIntervalType from "../enum/RecurringIntervalType";
import FormValues from "../types/FormValues";

import { parseActivityInterval, stringifyActivityInterval } from "./activityInterval";
import getCompletedValueBasedOnStartAt from "./getCompletedValueBasedOnStartAt";
import { predefinedIntervals } from "./useRecurIntervalOptions";

const getRecurIntervalType = (recurInterval: FormValues["recurInterval"]) => {
  if (!recurInterval) {
    return RecurringIntervalType.NEVER;
  }

  const recurIntervalString = stringifyActivityInterval(recurInterval);

  return (
    predefinedIntervals.find((intervalString) => intervalString === recurIntervalString) ??
    RecurringIntervalType.CUSTOM
  );
};

const getFormInitialValues = (
  activity: Partial<Omit<Activity, "id">> | undefined,
  currentUser: User,
  defaultActivityTypeId: ActivityType["id"] | undefined,
  defaultCompletion: boolean,
  defaultVisibility: ActivityVisibility,
  unavailableActivityType?: Pick<ActivityType, "id" | "name">
): FormValues => {
  const customFields: Record<CustomField["esKey"], ActivityCustomFieldValue> = {};
  activity?.customFields?.forEach(
    (customField) => (customFields[customField!.esKey] = customField)
  );

  const recurInterval = parseActivityInterval(activity?.recurInterval);
  let startAt: Date | undefined = new Date();
  if (activity) {
    if (activity.startAt) {
      startAt = parseApiDate(activity.startAt);
    } else {
      startAt = undefined;
    }
  }

  const calculatedCompleted = startAt && getCompletedValueBasedOnStartAt(startAt);

  return {
    activityTypeId: unavailableActivityType
      ? undefined
      : activity?.crmActivityType?.id ?? defaultActivityTypeId,
    allDay: activity?.allDay,
    assignee: activity?.assignee ?? currentUser,
    assigneeId: activity?.assignee?.id ?? currentUser.id,
    companyId: activity?.account?.id,
    completed:
      calculatedCompleted !== undefined
        ? calculatedCompleted
        : activity?.completed ?? defaultCompletion,
    customFields,
    dealId: activity?.deal?.id,
    endAt: activity?.endAt ? parseApiDate(activity.endAt) : undefined,
    files: activity?.files,
    isRecurrent: !!recurInterval,
    name: activity?.name ?? "",
    note: activity?.note,
    personId: activity?.contact?.id,
    recurInterval,
    recurIntervalType: getRecurIntervalType(recurInterval),
    reminders: activity?.reminders ?? localSettings.getActivityCreationNotifyPreferences() ?? [],
    startAt,
    teamIds: activity?.teamId,
    visibility: activity?.visibility ?? defaultVisibility,
  };
};

export default getFormInitialValues;
