import notification from "antd/es/notification";
import { defineMessages } from "react-intl";
import { all, call, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { Action, isActionOf } from "typesafe-actions";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { EntityTypeSupportingGroups, Group } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";

import i18nService from "@app/config/I18nService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import groupFieldModel from "@app/util/fieldModel/GroupFieldModel";
import { getEntityTypeDisplayName } from "@app/util/ui";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import {
  createGroup,
  deleteGroup,
  fetchAllGroups,
  fetchGroups,
  reloadGroups,
  updateEntities,
  updateGroup,
  updateGroupSharing,
} from "./actions";

const messages = defineMessages({
  created: {
    id: "groups.create.success",
    defaultMessage: "{name} Group Created.",
    description: "Group created successfully",
  },
  deleted: {
    id: "groups.delete.success",
    defaultMessage: "Group successfully deleted",
    description: "Group deleted successfully",
  },
  entitiesUpdated: {
    id: "groups.updateEntities.success",
    defaultMessage: "{entityName} Edited.",
    description: "Entities are updated successfully from the Manage Group Modal",
  },
  updated: {
    id: "groups.update.success",
    defaultMessage: "{name} Group Edited.",
    description: "Group updated successfully",
  },
});

const successForEntityTypeActionMatcher =
  (entityType: EntityTypeSupportingGroups) =>
  (action: Action): boolean =>
    isActionOf(fetchGroups.success, action) && action.payload.entityType === entityType;

export function* onFetchGroups({ payload }: ReturnType<typeof fetchGroups.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const requestPayload: Omit<PlatformListRequest, "$columns"> = {
      $filters: payload.request?.filter
        ? convertToPlatformFilterModel(payload.request.filter, [], groupFieldModel)
        : ({} as PlatformFilterModel),
      $limit: payload.request?.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : undefined,
      $offset: payload.request?.range ? payload.request.range.startRow : 0,
      $order: payload.request?.sort ? convertToPlatformSortModel(payload.request.sort) : undefined,
    };
    requestPayload.$filters!.includeAccessStatus = true;

    const response: ListResponse<Group> = yield callApi(
      "fetchGroups",
      organization.id,
      payload.entityType,
      requestPayload
    );

    yield put(fetchGroups.success({ entityType: payload.entityType, groups: response.data }));
  } catch (error) {
    yield put(fetchGroups.failure({ entityType: payload.entityType, error }));
  }
}

export function* onFetchAllGroups() {
  try {
    // call fetchCustomFields actions with a given request options
    const request: Partial<ListRequest> = {
      range: { endRow: 999999, startRow: 0 }, // fetch all
      sort: [{ field: groupFieldModel.getByName("name")!, order: SortOrder.ASC }],
    };
    yield put(fetchGroups.request({ entityType: EntityType.COMPANY, request }));
    yield put(fetchGroups.request({ entityType: EntityType.PERSON, request }));
    yield put(fetchGroups.request({ entityType: EntityType.DEAL, request }));

    // now wait till all succeed or any fails, whichever happens first
    const { failure } = yield race({
      failure: take([fetchGroups.failure]),
      success: all([
        take(successForEntityTypeActionMatcher(EntityType.COMPANY)),
        take(successForEntityTypeActionMatcher(EntityType.DEAL)),
        take(successForEntityTypeActionMatcher(EntityType.PERSON)),
      ]),
    });

    if (failure) {
      yield put(fetchAllGroups.failure());
    } else {
      yield put(fetchAllGroups.success());
    }
  } catch {
    yield put(fetchAllGroups.failure());
    // error is handled in the onInitializeApp saga
  }
}

export function* onCreateGroup({
  payload: {
    activityTypesIds,
    cadenceInterval,
    color,
    entityType,
    name,
    onSuccess,
    userIdsToShareWith,
  },
}: ReturnType<typeof createGroup.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const response: Group = yield callApi(
      "createGroup",
      org.id,
      entityType,
      name,
      activityTypesIds,
      color,
      cadenceInterval
    );

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.created, {
          name,
        }),
      });
    }

    yield put(
      createGroup.success({
        entityType,
        group: response,
      })
    );

    if (userIdsToShareWith?.length) {
      const payload = userIdsToShareWith.map((user) => ({
        groupId: response.id,
        userId: user,
      }));
      yield callApi("bulkCreateGroupShare", org.id, payload);
    }

    onSuccess(response);

    // reload groups
    const request: Partial<ListRequest> = {
      range: { endRow: 999999, startRow: 0 }, // fetch all
      sort: [{ field: groupFieldModel.getByName("name")!, order: SortOrder.ASC }],
    };
    yield put(fetchGroups.request({ entityType, request }));
  } catch (error) {
    yield put(createGroup.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateGroup({
  payload: { entityType, group, noNotification, onSuccess },
}: ReturnType<typeof updateGroup.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedGroup: Group = yield callApi("updateGroup", orgId, entityType, group);

    // temporary fix since backend doesn't return items in PUT's response
    updatedGroup.items = group.items;

    const intl = i18nService.getIntl();
    if (intl && !noNotification) {
      notification.success({
        message: intl.formatMessage(messages.updated, {
          name: group.name,
        }),
      });
    }

    yield put(updateGroup.success({ entityType, group: updatedGroup }));
    onSuccess?.(entityType, updatedGroup);
  } catch (error) {
    yield put(updateGroup.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDeleteGroup({
  payload: { entityType, groupId, onSuccess },
}: ReturnType<typeof deleteGroup.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deleteGroup", orgId, entityType, groupId);
    yield put(deleteGroup.success({ entityType, groupId }));
    if (onSuccess) {
      yield call(onSuccess, entityType, groupId);
    }
    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.deleted),
      });
    }
  } catch (error) {
    yield put(deleteGroup.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateGroupSharing({
  payload: { entityType, group, onSuccess, userIdsToShareWith },
}: ReturnType<typeof updateGroupSharing.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const usersToAdd = userIdsToShareWith.filter((id) => !group.userIds?.includes(id));
    const usersToDelete = (group.userIds ?? []).filter((id) => !userIdsToShareWith.includes(id));
    const createPayload = (userIds: User["id"][]) =>
      userIds.map((userId) => ({ groupId: group.id, userId }));

    yield all([
      usersToAdd.length
        ? callApi("bulkCreateGroupShare", orgId, createPayload(usersToAdd))
        : undefined,
      usersToDelete.length
        ? callApi("bulkDeleteGroupShare", orgId, createPayload(usersToDelete))
        : undefined,
    ]);

    const updatedGroup: Group = yield callApi("fetchGroup", orgId, entityType, group.id);
    // There's a timeout after shared-groups api requests and the moment group is actually updated
    // so we're using a workaround for now. For more info: https://mapmycustomers.slack.com/archives/C03PGD7BLBY/p1663156032349549
    updatedGroup.userIds = userIdsToShareWith;

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.updated, {
          name: updatedGroup.name,
        }),
      });
    }

    yield put(updateGroupSharing.success({ entityType, group: updatedGroup }));
    onSuccess?.(entityType, updatedGroup);
  } catch (error) {
    yield put(updateGroupSharing.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateEntities({
  payload: { entities, entityType, groupIdsToAdd, groupIdsToDelete, onSuccess },
}: ReturnType<typeof updateEntities.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const entitiesIds = entities.map(({ id }) => id);
    yield all([
      all(
        (groupIdsToAdd ?? []).map((groupId) =>
          callApi("addToGroup", org.id, groupId, entityType, entitiesIds)
        )
      ),
      all(
        (groupIdsToDelete ?? []).map((groupId) =>
          callApi("deleteFromGroup", org.id, groupId, entityType, entitiesIds)
        )
      ),
    ]);
    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.entitiesUpdated, {
          count: entities.length,
          entityName: getEntityTypeDisplayName(intl, entityType, {
            lowercase: false,
            plural: entities.length > 1,
          }),
        }),
      });
    }
    yield put(notifyAboutChanges({ entityType, updated: entities }));
    yield put(updateEntities.success());
    yield call(onSuccess);

    // reload groups to ensure their items count is updated
    const request: Partial<ListRequest> = {
      range: { endRow: 999999, startRow: 0 }, // fetch all
      sort: [{ field: groupFieldModel.getByName("name")!, order: SortOrder.ASC }],
    };
    yield put(fetchGroups.request({ entityType, request }));
  } catch (error) {
    yield put(updateEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onReloadGroups({ payload: { entityType } }: ReturnType<typeof reloadGroups>) {
  const request: Partial<ListRequest> = {
    range: { endRow: 999999, startRow: 0 }, // fetch all
    sort: [{ field: groupFieldModel.getByName("name")!, order: SortOrder.ASC }],
  };
  yield put(fetchGroups.request({ entityType, request }));
}

export function* groupsSaga() {
  yield takeEvery(fetchGroups.request, onFetchGroups); // must be takeEvery since actions might have different entityType in them
  yield takeLatest(fetchAllGroups.request, onFetchAllGroups);
  yield takeEvery(reloadGroups, onReloadGroups); // must be takeEvery since actions might have different entityType in them
  yield takeEvery(createGroup.request, onCreateGroup);
  yield takeEvery(updateGroup.request, onUpdateGroup);
  yield takeEvery(deleteGroup.request, onDeleteGroup);
  yield takeEvery(updateGroupSharing.request, onUpdateGroupSharing);
  yield takeEvery(updateEntities.request, onUpdateEntities);
}
