import omit from "lodash-es/omit";
import { Action } from "redux";
import { all, call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { isActionOf } from "typesafe-actions";

import { TerritoryLevel } from "@mapmycustomers/shared";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import Currency from "@mapmycustomers/shared/types/Currency";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { getBoundariesLayerData } from "@app/scene/map/store/selectors";
import DemographicData from "@app/scene/map/types/DemographicData";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { updateMetadata } from "@app/store/iam/actions";
import { fetchAllTerritories } from "@app/store/territories/actions";

import {
  calculateBuckets,
  ensureDemographicDataLoaded,
  initializeTerritories,
  setBoundaryType,
  setVisibleRegions,
  setZoomLevel,
  toggleLayerLegendExpandedState,
  toggleLayerVisibility,
  toggleVisualizeDemography,
  updateDemographicDataConfiguration,
} from "../actions";

import {
  getCountryCode,
  getDemographicData,
  getDemographicDataConfiguration,
  getDemographyDataCountryCode,
  getViewportRegions,
  getZoomLevel,
  shouldVisualizeDemography,
} from "./selectors";
import BoundariesLayerData, {
  DemographicDataConfiguration,
  RegionDemographicData,
} from "./types/BoundariesLayerData";
import Bucket from "./types/Bucket";
import DataSource from "./types/DataSource";
import calculateBucketsForMethod from "./util/calculateBucketsForMethod";

const MAXIMUM_RESPONSE_COUNT = 10000;

function* onPersistBoundariesSettings() {
  const layerData: BoundariesLayerData = yield select(getBoundariesLayerData);
  yield put(
    updateMetadata.request({
      boundariesSettings: omit(layerData, [
        "buckets",
        "bucketsLoading",
        "demographicData",
        "territoriesLoading",
        "viewportRegions",
      ]),
    })
  );
}

function* onInitializeTerritories() {
  try {
    yield put(fetchAllTerritories.request());
    const { failure } = yield race({
      failure: take([fetchAllTerritories.failure]),
      success: take([fetchAllTerritories.success]),
    });

    // fail app initialization if any of fetch actions failed, and succeed otherwise
    if (failure) {
      yield put(initializeTerritories.failure());
      return;
    }

    yield put(initializeTerritories.success());
  } catch (error) {
    yield put(initializeTerritories.failure());
    yield put(handleError({ error }));
  }
}

function* onEnsureDemographicDataLoaded() {
  try {
    const countryCode: CountryCode = yield select(getCountryCode);
    const demographyDataCountryCode: CountryCode = yield select(getDemographyDataCountryCode);

    const regionData: RegionDemographicData = yield select(getDemographicData);
    if (regionData && demographyDataCountryCode === countryCode) {
      yield put(ensureDemographicDataLoaded.success({}));
      return;
    }
    const demographicData: {
      fips: DemographicData[];
      state: DemographicData[];
      zip: DemographicData[];
    } = {
      fips: [],
      state: [],
      zip: [],
    };

    const requestPayload = {
      $limit: MAXIMUM_RESPONSE_COUNT,
      $offset: 0,
    };
    const data = [];
    const response: ListResponse<DemographicData> = yield callApi("fetchDemographicData", {
      ...requestPayload,
      $filters: { countryCode },
    });
    data.push(...response.data);
    const apiCalls = [];
    if (response.total > MAXIMUM_RESPONSE_COUNT) {
      for (let i = 1; i <= Math.floor(response.total / MAXIMUM_RESPONSE_COUNT); i += 1) {
        apiCalls.push(
          callApi("fetchDemographicData", {
            ...requestPayload,
            $filters: { countryCode },
            $offset: MAXIMUM_RESPONSE_COUNT * i,
          })
        );
      }
    }
    const remainingResponse: ListResponse<DemographicData>[] = yield all(apiCalls);
    remainingResponse.forEach((res) => {
      data.push(...res.data);
    });
    data.forEach((item) => {
      if (item.type === "zip") {
        demographicData.zip.push(item);
      } else if (item.type === "fips") {
        demographicData.fips.push(item);
      } else if (item.type === "state") {
        demographicData.state.push(item);
      }
    });
    const result = {
      fips: demographicData.fips.reduce<Record<DemographicData["code"], DemographicData>>(
        (result, { code, income, population, type }) => {
          result[code] = { code, income, population, type };
          return result;
        },
        {}
      ),
      state: demographicData.state.reduce<Record<DemographicData["code"], DemographicData>>(
        (result, { code, income, population, type }) => {
          result[code] = { code, income, population, type };
          return result;
        },
        {}
      ),
      zip: demographicData.zip.reduce<Record<DemographicData["code"], DemographicData>>(
        (result, { code, income, population, type }) => {
          result[code] = { code, income, population, type };
          return result;
        },
        {}
      ),
    };
    yield put(
      ensureDemographicDataLoaded.success({
        demographicData: result,
        demographyDataCountryCode: countryCode,
      })
    );
  } catch (error) {
    yield put(ensureDemographicDataLoaded.failure());
    yield put(handleError({ error }));
  }
}

function* onCalculateBuckets() {
  try {
    const visualizeDemography: boolean = yield select(shouldVisualizeDemography);
    const demographicData: RegionDemographicData | undefined = yield select(getDemographicData);
    const viewportRegions: DemographicData["code"][] = yield select(getViewportRegions);
    if (demographicData && visualizeDemography) {
      const zoomLevel: TerritoryLevel = yield select(getZoomLevel);
      const demographicDataConfiguration: DemographicDataConfiguration = yield select(
        getDemographicDataConfiguration
      );
      let currencyId: Currency["id"] | undefined;
      let { bucketCount, classificationMethod, dataSource, onlyVisibleRegions, theme } =
        demographicDataConfiguration;

      const regionData =
        zoomLevel === TerritoryLevel.ADM2
          ? demographicData.fips
          : zoomLevel === TerritoryLevel.ADM3
          ? demographicData.zip
          : demographicData.state;

      let regionCodes = Object.keys(regionData);
      if (viewportRegions.length >= bucketCount && onlyVisibleRegions) {
        regionCodes = regionCodes.filter((code) => viewportRegions.includes(code));
      }

      const data = regionCodes.map((code) => {
        if (dataSource === DataSource.MEDIAN_INCOME) {
          if (!currencyId) {
            currencyId = regionData[code].income.currencyId;
          }
          return regionData[code].income.value;
        } else {
          return regionData[code].population;
        }
      });

      bucketCount = Math.min(bucketCount, data.length);
      if (bucketCount > 0) {
        const result: Bucket[] = yield call(
          calculateBucketsForMethod,
          classificationMethod,
          bucketCount,
          currencyId,
          data,
          theme
        );
        yield put(calculateBuckets.success(result));
      } else {
        yield put(calculateBuckets.success([]));
      }
    } else {
      yield put(calculateBuckets.success([]));
    }
  } catch (error) {
    yield put(calculateBuckets.failure());
    yield put(handleError({ error }));
  }
}

function* onUpdateDemographicDataConfiguration({
  payload,
}: ReturnType<typeof updateDemographicDataConfiguration>) {
  if (payload.countryCode) {
    yield put(ensureDemographicDataLoaded.request());
  }
}

const isToggleVisibilityOrLegendAction = isActionOf([
  toggleLayerVisibility,
  toggleLayerLegendExpandedState,
]);

export function* boundariesLayerSagas() {
  yield takeLatest(initializeTerritories.request, onInitializeTerritories);
  yield takeLatest(ensureDemographicDataLoaded.request, onEnsureDemographicDataLoaded);
  yield takeLatest(
    [
      (action: Action) =>
        isToggleVisibilityOrLegendAction(action) && action.payload.name === "boundaries",
      setBoundaryType,
      toggleVisualizeDemography,
      updateDemographicDataConfiguration,
      ensureDemographicDataLoaded.success,
    ],
    onPersistBoundariesSettings
  );
  yield takeLatest(
    [
      calculateBuckets.request,
      ensureDemographicDataLoaded.success,
      setVisibleRegions,
      setZoomLevel,
      toggleVisualizeDemography,
      updateDemographicDataConfiguration,
    ],
    onCalculateBuckets
  );
  yield takeLatest(updateDemographicDataConfiguration, onUpdateDemographicDataConfiguration);
}
