import React, { ReactNode, useCallback } from "react";

import { bem } from "@react-md/utils";

import Color from "@mapmycustomers/shared/enum/Color";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";

import { useConfigProvider } from "../ConfigProvider";
import Labeled from "../input/Labeled";
import SliderWithNumberField from "../SliderWithNumberField";

import { ColorDropdown } from "./index";

const block = bem("mmc-color-and-opacity-field");

interface ColorAndOpacityFieldProps {
  className?: string;
  color?: AnyColor;
  label?: ReactNode;
  onColorChange?: (color: AnyColor) => void;
  onOpacityChange?: (opacity: number) => void;
  opacity?: number;
}

const ColorAndOpacityField: React.FC<ColorAndOpacityFieldProps> = ({
  className,
  color = Color.BLACK,
  label,
  onColorChange,
  onOpacityChange,
  opacity = 1,
}) => {
  const configProvider = useConfigProvider();

  const handleOpacityChange = useCallback(
    (value: number) => onOpacityChange?.(value / 100),
    [onOpacityChange]
  );

  const opacityValueFormatter = useCallback(
    (value?: number | string) =>
      configProvider.formatNumber(+(value ?? 0) / 100, {
        maximumFractionDigits: 0,
        style: "percent",
      }),
    [configProvider]
  );

  return (
    <Labeled className={className} label={label}>
      <div className={block()}>
        <div className={block("color")}>
          <ColorDropdown
            colorPanelProps={{
              showColorSpace: true,
              showInput: true,
              showNamedColors: true,
            }}
            onChange={onColorChange}
            value={color ?? Color.BLACK}
          />
        </div>
        <div className={block("opacity")}>
          <SliderWithNumberField
            max={100}
            min={0}
            onChange={handleOpacityChange}
            step={5}
            value={opacity * 100}
            valueFormatter={opacityValueFormatter}
          />
        </div>
      </div>
    </Labeled>
  );
};

export default ColorAndOpacityField;
