import { createReducer } from "typesafe-actions";

import {
  Actions,
  fetchActivityByRepData,
  fetchActivityByTypesData,
  fetchActivityOverview,
  fetchDailyChangeData,
  fetchDealsRotting,
  fetchEngagementRecordsList,
  fetchFunnelSummary,
  fetchOpenDealsData,
  fetchRecentCompanies,
  fetchRecordsBySourceForSelectedType,
  fetchRecordsBySourceSummary,
  fetchRecordsOutOfCadence,
  fetchSelectedActivityByTypesData,
  fetchTodayActivitiesData,
  fetchTodayActivitiesDataForReps,
  initialize,
  setDealsRottingFilter,
  setRecordSourceFilter,
  setRecordsOutOfCadenceFilter,
} from "./actions";
import { HomeState, initialState } from "./HomeState";

const home = createReducer<HomeState, Actions>(initialState)
  .handleAction(initialize.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(initialize.success, (state, action) => {
    const {
      dealsCount,
      dealsRottingFilter,
      hasActivities,
      hasCompanies,
      recordsOutOfCadenceFilter,
      recordsSourceFilter,
    } = action.payload;

    return {
      ...state,
      dealsCount,
      dealsRottingFilter,
      hasActivities,
      hasCompanies,
      loading: false,
      recordsOutOfCadenceFilter,
      recordsSourceFilter,
    };
  })
  .handleAction(initialize.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(fetchActivityByRepData.request, (state) => ({
    ...state,
    activityByRepDataError: undefined,
    activityByRepDataLoading: true,
  }))
  .handleAction(fetchActivityByRepData.success, (state, action) => ({
    ...state,
    activityByRepData: action.payload,
    activityByRepDataLoading: false,
  }))
  .handleAction(fetchActivityByRepData.failure, (state, action) => ({
    ...state,
    activityByRepDataError: action.payload,
    activityByRepDataLoading: false,
  }))
  .handleAction(fetchActivityByTypesData.request, (state) => ({
    ...state,
    activityByTypesDataError: undefined,
    activityByTypesDataLoading: true,
  }))
  .handleAction(fetchActivityByTypesData.success, (state, action) => ({
    ...state,
    actitivityTypesWithCount: action.payload,
    activityByTypesDataLoading: false,
  }))
  .handleAction(fetchActivityByTypesData.failure, (state, action) => ({
    ...state,
    activityByTypesDataError: action.payload,
    activityByTypesDataLoading: false,
  }))
  .handleAction(fetchSelectedActivityByTypesData.success, (state, action) => ({
    ...state,
    selectedTypeData: action.payload.activities,
    totalRecordsByActivityType: action.payload.totalRecords,
  }))
  .handleAction(fetchActivityOverview.request, (state) => ({
    ...state,
    activityOverviewError: undefined,
    activityOverviewLoading: true,
  }))
  .handleAction(fetchActivityOverview.success, (state, action) => ({
    ...state,
    activityOverview: action.payload,
    activityOverviewLoading: false,
  }))
  .handleAction(fetchActivityOverview.failure, (state, action) => ({
    ...state,
    activityOverviewError: action.payload,
    activityOverviewLoading: false,
  }))
  .handleAction(fetchDailyChangeData.request, (state) => ({
    ...state,
    dailyChangeDataError: undefined,
    dailyChangeDataLoading: true,
  }))
  .handleAction(fetchDailyChangeData.success, (state, action) => ({
    ...state,
    dailyChangeData: {
      ...state.dailyChangeData,
      ...action.payload,
    },
    dailyChangeDataLoading: false,
  }))
  .handleAction(fetchDailyChangeData.failure, (state, action) => ({
    ...state,
    dailyChangeDataError: action.payload,
    dailyChangeDataLoading: false,
  }))
  .handleAction(fetchFunnelSummary.request, (state) => ({
    ...state,
    funnelSummaryError: undefined,
    funnelSummaryLoading: true,
  }))
  .handleAction(fetchFunnelSummary.success, (state, action) => ({
    ...state,
    funnelSummary: action.payload,
    funnelSummaryLoading: false,
  }))
  .handleAction(fetchFunnelSummary.failure, (state, action) => ({
    ...state,
    funnelSummaryError: action.payload,
    funnelSummaryLoading: false,
  }))
  .handleAction(fetchRecordsBySourceSummary.request, (state) => ({
    ...state,
    recordsSourcesError: undefined,
    recordsSourcesLoading: true,
  }))
  .handleAction(fetchRecordsBySourceSummary.success, (state, action) => ({
    ...state,
    recordsBySourceSummary: action.payload,
    recordsSourcesLoading: false,
  }))
  .handleAction(fetchRecordsBySourceSummary.failure, (state, action) => ({
    ...state,
    recordsSourcesError: action.payload,
    recordsSourcesLoading: false,
  }))

  .handleAction(fetchRecentCompanies.request, (state) => ({
    ...state,
    recentCompaniesDataError: undefined,
    recentCompaniesDataLoading: true,
  }))
  .handleAction(fetchRecentCompanies.success, (state, action) => ({
    ...state,
    recentCompaniesData: action.payload,
    recentCompaniesDataLoading: false,
  }))
  .handleAction(fetchRecentCompanies.failure, (state, action) => ({
    ...state,
    recentCompaniesDataError: action.payload,
    recentCompaniesDataLoading: false,
  }))
  .handleAction(fetchOpenDealsData.request, (state) => ({
    ...state,
    openDealsError: undefined,
    openDealsLoading: true,
  }))
  .handleAction(fetchOpenDealsData.success, (state, action) => ({
    ...state,
    openDealsData: action.payload,
    openDealsLoading: false,
  }))
  .handleAction(fetchOpenDealsData.failure, (state, action) => ({
    ...state,
    openDealsError: action.payload,
    openDealsLoading: false,
  }))
  .handleAction(fetchTodayActivitiesData.request, (state) => ({
    ...state,
    todayActivitiesDataError: undefined,
    todayActivitiesDataLoading: true,
  }))
  .handleAction(fetchTodayActivitiesData.success, (state, action) => ({
    ...state,
    todayActivitiesData: action.payload,
    todayActivitiesDataLoading: false,
  }))
  .handleAction(fetchTodayActivitiesData.failure, (state, action) => ({
    ...state,
    todayActivitiesDataError: action.payload,
    todayActivitiesDataLoading: false,
  }))
  .handleAction(fetchTodayActivitiesDataForReps.request, (state) => ({
    ...state,
    todayActivitiesDataForRepsError: undefined,
    todayActivitiesDataForRepsLoading: true,
  }))
  .handleAction(fetchTodayActivitiesDataForReps.success, (state, action) => ({
    ...state,
    todayActivitiesDataForReps: action.payload,
    todayActivitiesDataForRepsLoading: false,
  }))
  .handleAction(fetchTodayActivitiesDataForReps.failure, (state, action) => ({
    ...state,
    todayActivitiesDataForRepsError: action.payload,
    todayActivitiesDataForRepsLoading: false,
  }))
  .handleAction(setRecordSourceFilter, (state, { payload: recordsSourceFilter }) => ({
    ...state,
    recordsSourceFilter,
  }))
  .handleAction(setRecordsOutOfCadenceFilter, (state, { payload: recordsOutOfCadenceFilter }) => ({
    ...state,
    recordsOutOfCadenceFilter,
  }))
  .handleAction(fetchRecordsOutOfCadence.request, (state) => ({
    ...state,
    recordsOutOfCadenceLoading: true,
  }))
  .handleAction(fetchRecordsOutOfCadence.success, (state, action) => ({
    ...state,
    recordsOutOfCadence: action.payload,
    recordsOutOfCadenceLoading: false,
  }))
  .handleAction(fetchRecordsOutOfCadence.failure, (state, action) => ({
    ...state,
    recordsOutOfCadenceError: action.payload,
    recordsOutOfCadenceLoading: false,
  }))
  .handleAction(setDealsRottingFilter, (state, { payload: dealsRottingFilter }) => ({
    ...state,
    dealsRottingFilter,
  }))
  .handleAction(fetchDealsRotting.request, (state) => ({
    ...state,
    dealsRottingLoading: true,
  }))
  .handleAction(fetchDealsRotting.success, (state, action) => ({
    ...state,
    dealsRotting: action.payload.dealsRottingStats,
    dealsRottingLoading: false,
    dealsRottingStagesStats: action.payload.stagesStats,
  }))
  .handleAction(fetchDealsRotting.failure, (state, action) => ({
    ...state,
    dealsRottingError: action.payload,
    dealsRottingLoading: false,
  }))
  .handleAction(fetchEngagementRecordsList.request, (state) => ({
    ...state,
    engagementRecordsLoading: true,
  }))
  .handleAction(fetchEngagementRecordsList.success, (state, action) => ({
    ...state,
    engagementRecordsList: action.payload.records,
    engagementRecordsLoading: false,
    totalRecordsByEngagementList: action.payload.totalRecords,
  }))
  .handleAction(fetchEngagementRecordsList.failure, (state) => ({
    ...state,
    engagementRecordsLoading: false,
  }))
  .handleAction(fetchRecordsBySourceForSelectedType.request, (state) => ({
    ...state,
    recordsBySourceListLoading: true,
  }))
  .handleAction(fetchRecordsBySourceForSelectedType.success, (state, action) => ({
    ...state,
    recordsBySourceList: action.payload.recordsBySourceList,
    recordsBySourceListLoading: false,
    totalRecordsBySourceList: action.payload.totalRecords,
  }))
  .handleAction(fetchRecordsBySourceForSelectedType.failure, (state) => ({
    ...state,
    engagementRecordsLoading: false,
  }));

export * from "./selectors";
export type HomeActions = Actions;
export default home;
