enum CardType {
  ACTIVITIES_LOGGED = "activitiesLogged",
  ACTIVITIES_OVERDUE = "activitiesOverdue",
  CHECK_INS = "checkIns",
  CUSTOMER_FACE_TIME = "customerFaceTime",
  NEW_RECORDS = "newRecords",
  NEW_ROUTES = "newRoutes",
  NO_CONTACT_IN = "noContactIn",
  NO_CONTACT_IN_COUNT = "noContactInCount",
  OUT_OF_FREQUENCY = "outOfFrequency",
  RECORDS_PAST_DUE = "recordsPastDue",
}

export default CardType;
