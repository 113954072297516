import { useCallback, useEffect, useMemo, useState } from "react";

import { FormProps } from "antd/es/form";

import Visibility from "@mapmycustomers/shared/enum/Visibility";
import { Activity } from "@mapmycustomers/shared/types/entity";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Person from "@mapmycustomers/shared/types/entity/Person";
import Team from "@mapmycustomers/shared/types/Team";
import User, { UserRef } from "@mapmycustomers/shared/types/User";

import Iam from "@app/types/Iam";
import getActivityAllowedUsers from "@app/util/activity/getActivityAllowedUsers";

import FormValues from "../types/FormValues";

const useMentionsValidation = (
  me: Iam,
  users: User[],
  associatedCompany?: Company,
  associatedDeal?: Deal,
  associatedPerson?: Person,
  activity?: Activity
): [boolean, User["username"][], NonNullable<FormProps<FormValues>["onValuesChange"]>] => {
  const [note, setNote] = useState<null | string | undefined>(activity?.note);
  const [visibility, setVisibility] = useState<Visibility>(
    activity?.visibility ?? Visibility.PRIVATE
  );
  const [teamIds, setTeamIds] = useState<Team["id"][]>(activity?.teamId ?? []);
  const [assignee, setAssignee] = useState<undefined | UserRef>(activity?.assignee);

  const allowedUserEmails = useMemo(() => {
    const allowedByAssociationsUserIds = [
      ...(associatedCompany?.userIds ?? []),
      ...(associatedDeal?.userIds ?? []),
      ...(associatedPerson?.userIds ?? []),
    ];

    return getActivityAllowedUsers(
      me.id,
      users,
      allowedByAssociationsUserIds,
      visibility,
      teamIds,
      assignee
    );
  }, [
    me,
    users,
    assignee,
    associatedCompany,
    associatedPerson,
    associatedDeal,
    visibility,
    teamIds,
  ]);

  const allMentionedUsersHaveAccessToActivity: boolean = useMemo(() => {
    let valid = true;

    if (Visibility.SHARED_WITH_ORGANIZATION !== visibility) {
      const allEmails = new Set(users.map(({ username }) => username));
      const words = (note ?? "").split(" ");
      if (Visibility.PRIVATE === visibility) {
        // if visibility is private and we have at least 1 mention then we mark checking as invalid
        valid = !words.some((value) => value[0] === "@" && allEmails.has(value.slice(1)));
      } else {
        valid =
          words.filter(
            (value) =>
              value[0] === "@" &&
              allEmails.has(value.slice(1)) &&
              !allowedUserEmails.includes(value.slice(1))
          ).length === 0;
      }
    }
    return valid;
  }, [allowedUserEmails, note, users, visibility]);

  useEffect(() => {
    if (activity) {
      setVisibility(activity.visibility);
      setNote(activity.note);
      setTeamIds(activity.teamId ?? []);
      setAssignee(activity.assignee);
    }
  }, [activity, setAssignee, setNote, setTeamIds, setVisibility]);

  const handleChange = useCallback<NonNullable<FormProps<FormValues>["onValuesChange"]>>(
    (_, fields) => {
      setNote(fields?.note);
      setVisibility(fields?.visibility ?? Visibility.PRIVATE);
      setAssignee(fields.assignee);
      setTeamIds((oldTeamIds) => {
        const newTeamIds = (fields?.teamIds ?? []) as Team["id"][];
        // condition to avoid unnecessary re-renders when field.teamIds are not defined
        // and hence we always use a new instance of an empty array.
        // So here we check if old and new team ids are an empty array, and if they
        // are, we use the old instance.
        if (!oldTeamIds.length && !newTeamIds.length) {
          return oldTeamIds;
        }
        return newTeamIds;
      });
    },
    [setAssignee, setNote, setTeamIds, setVisibility]
  );

  return [allMentionedUsersHaveAccessToActivity, allowedUserEmails, handleChange];
};

export default useMentionsValidation;
