import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { convertLongLatToLatLngLiteral } from "@mapmycustomers/shared/util/geo/GeoService";

import { fromLatLngToPixel, fromPixelToLatLng } from "@app/util/map/coordinateMapping";

type PixelBounds = {
  northEast: google.maps.Point;
  southWest: google.maps.Point;
};

/**
 * Converts a LatLng bound to pixels.
 *
 * @hidden
 */
const latLngBoundsToPixelBounds = (
  bounds: google.maps.LatLngBounds,
  fromLatLngToDivPixel: (
    latLng: google.maps.LatLng | google.maps.LatLngLiteral
  ) => google.maps.Point
): PixelBounds => {
  return {
    northEast: fromLatLngToDivPixel(bounds.getNorthEast()),
    southWest: fromLatLngToDivPixel(bounds.getSouthWest()),
  };
};

/**
 * Extends a pixel bounds by numPixels in all directions.
 *
 * @hidden
 */
export const extendPixelBounds = (
  { northEast, southWest }: PixelBounds,
  numPixels: number
): PixelBounds => {
  northEast.x += numPixels;
  northEast.y -= numPixels;

  southWest.x -= numPixels;
  southWest.y += numPixels;

  return { northEast, southWest };
};

/**
 * @hidden
 */
export const pixelBoundsToLatLngBounds = (
  { northEast, southWest }: PixelBounds,
  fromDivPixelToLatLng: (pixel: google.maps.Point) => google.maps.LatLng
): google.maps.LatLngBounds => {
  const sw = fromDivPixelToLatLng(southWest);
  const ne = fromDivPixelToLatLng(northEast);
  return new google.maps.LatLngBounds(sw, ne);
};

/**
 * Extends a bounds by a number of pixels in each direction
 */
export const extendBoundsToPaddedViewport = (
  map: google.maps.Map,
  bounds: google.maps.LatLngBounds,
  numPixels: number
): google.maps.LatLngBounds => {
  const fromLatLngToDivPixel = (
    latLng: google.maps.LatLng | google.maps.LatLngLiteral
  ): google.maps.Point =>
    fromLatLngToPixel(map.getZoom()!, map.getProjection()!, map.getBounds()!, latLng)!;
  const fromDivPixelToLatLng = (pixel: google.maps.Point) =>
    fromPixelToLatLng(map.getZoom()!, map.getProjection()!, map.getBounds()!, pixel)!;

  const { northEast, southWest } = latLngBoundsToPixelBounds(bounds, fromLatLngToDivPixel);
  const extendedPixelBounds = extendPixelBounds({ northEast, southWest }, numPixels);
  return pixelBoundsToLatLngBounds(extendedPixelBounds, fromDivPixelToLatLng);
};

/**
 * Returns the markers visible in a padded map viewport
 *
 * @param map
 * @param items The list of item to filter
 * @param coordinateGetter
 * @param viewportPaddingPixels The padding in pixel
 * @returns The list of markers in the padded viewport
 */
export const filterMarkersToPaddedViewport = <T>(
  map: google.maps.Map,
  items: Iterable<T>,
  coordinateGetter: (item: T) => LongLat,
  viewportPaddingPixels: number
): Iterable<T> => {
  const extendedMapBounds = extendBoundsToPaddedViewport(
    map,
    map.getBounds()!,
    viewportPaddingPixels
  );
  const result: T[] = [];
  for (const item of items) {
    if (extendedMapBounds.contains(convertLongLatToLatLngLiteral(coordinateGetter(item)))) {
      result.push(item);
    }
  }
  return result;
};

/**
 * Returns the distance between 2 positions.
 *
 * @hidden
 */
export const distanceBetweenPoints = ([lng1, lat1]: LongLat, [lng2, lat2]: LongLat): number => {
  const R = 6371; // Radius of the Earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lng2 - lng1) * Math.PI) / 180;
  const sinDLat = Math.sin(dLat / 2);
  const sinDLon = Math.sin(dLon / 2);
  const a =
    sinDLat * sinDLat +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * sinDLon * sinDLon;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};
