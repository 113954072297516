import bbox from "@turf/bbox";
import { lineString } from "@turf/helpers";

import LongLat from "../../types/base/LongLat";

import { bboxArea, pathArea } from "./areas";

export const removeHolesFromPolygon = (paths: LongLat[][]) => [paths[0]];
export const removeHolesFromMultiPolygon = (polygons: LongLat[][][]) =>
  polygons.map((paths) => removeHolesFromPolygon(paths));

// All holes smaller than this threshold are considered artifacts and are to be removed
const HOLE_AREA_THRESHOLD = 1_000_000; // sq. meters
// All holes smaller than this threshold might be artifacts and we need to check more details
// to understand if they really are
const SUSPICIOUS_HOLE_AREA_THRESHOLD = 5_000_000; // sq. meters
// Holes with area ratio (hole bbox area divided by hole area) bigger than this are considered artifacts
const AREA_RATIO_THRESHOLD = 5;
// Holes with aspect ratio difference abs(w/h - h/w) bigger than this are considered artifacts
// They're suspiciously loooooong :)
const ASPECT_RATIO_DIFFERENCE_THRESHOLD = 4;

const isArtifact = (path: LongLat[]) => {
  const box = bbox(lineString(path));
  const area = pathArea(path);
  if (area <= HOLE_AREA_THRESHOLD) {
    return true;
  } else if (area > SUSPICIOUS_HOLE_AREA_THRESHOLD) {
    return false;
  }

  const boxArea = bboxArea(box);
  const areaFillRatio = boxArea / area;
  if (areaFillRatio > AREA_RATIO_THRESHOLD) {
    return true;
  }

  const w = box[2] - box[0];
  const h = box[3] - box[1];
  const aspectRatioDifference = Math.abs(w / h - h / w);
  if (aspectRatioDifference > ASPECT_RATIO_DIFFERENCE_THRESHOLD) {
    return true;
  }

  return false;
};

export const removeSmallHolesFromPolygon = (paths: LongLat[][]) =>
  paths.filter((path, index) => index === 0 || !isArtifact(path));

export const removeSmallHolesFromMultiPolygon = (polygons: LongLat[][][]) =>
  polygons.map(removeSmallHolesFromPolygon);
