import CardType from "@app/enum/dashboard/CardType";

export const SUPPORTED_PREVIEW_CHART_TYPES = [
  CardType.ACTIVITIES_LOGGED,
  CardType.ACTIVITIES_OVERDUE,
  CardType.CHECK_INS,
  CardType.NEW_RECORDS,
  CardType.NO_CONTACT_IN_COUNT,
  CardType.RECORDS_PAST_DUE,
] as const;

export type UserPreviewCardType = (typeof SUPPORTED_PREVIEW_CHART_TYPES)[number];
