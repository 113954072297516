import React, { ReactNode, useMemo } from "react";

import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ButtonLink from "../../../ButtonLink";
import { useConfigProvider } from "../../../ConfigProvider";

import styles from "./useGoogleAutoCompleteOptions.module.scss";

export const NO_RESULT_VALUE = "no_result";
export const MANUAL_ADDRESS_VALUE = "manual_address";

const useGoogleAutoCompleteOptions = (
  suggestions: google.maps.places.AutocompletePrediction[],
  showManual?: boolean
) => {
  const configProvider = useConfigProvider();
  return useMemo(() => {
    const result: { disabled?: boolean; label: ReactNode; value: string }[] = suggestions.map(
      (item) => ({
        label: (
          <div className={styles.placeOption}>
            <FontAwesomeIcon className={styles.markerIcon} icon={faMapMarkerAlt} />
            <span className={styles.placeDescription}>{item.description}</span>
          </div>
        ),
        value: item.place_id,
      })
    );

    if (!suggestions.length) {
      result.push({
        disabled: true,
        label: (
          <div className={styles.noResults}>
            {configProvider.formatMessage("ui.autoComplete.address.noResult")}
          </div>
        ),
        value: NO_RESULT_VALUE,
      });
    }

    if (showManual) {
      result.push({
        label: (
          <ButtonLink className={styles.enterManuallyButton}>
            {configProvider.formatMessage("ui.autoComplete.address.manualButton")}
          </ButtonLink>
        ),
        value: MANUAL_ADDRESS_VALUE,
      });
    }

    return result;
  }, [configProvider, showManual, suggestions]);
};

export default useGoogleAutoCompleteOptions;
