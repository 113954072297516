import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import BaseMapStyle from "@mapmycustomers/shared/enum/map/BaseMapStyle";
import Located from "@mapmycustomers/shared/types/base/Located";
import { Cluster } from "@mapmycustomers/shared/types/map";
import PersistentMapSettings from "@mapmycustomers/shared/types/persistent/PersistentMapSettings";
import { ModifiedMapFilterModel } from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";

import MapMode from "@app/scene/map/enums/MapMode";
import { GroupModeActions } from "@app/scene/map/store/groupMode/actions";
import { LayersActions } from "@app/scene/map/store/layers/actions";
import { LegendsActions } from "@app/scene/map/store/legends/actions";
import { MapModeActions } from "@app/scene/map/store/mapMode/actions";
import MapToolPayload from "@app/scene/map/store/MapToolPayload";
import { PinLegendsActions } from "@app/scene/map/store/pinLegends/actions";
import { PinLocationActions } from "@app/scene/map/store/pinLocation/actions";
import { TerritoryModeActions } from "@app/scene/map/store/territoryMode/actions";
import GeoPath from "@app/types/GeoPath";
import MapPersistedViewportState from "@app/types/map/MapPersistedViewportState";
import MapViewportState from "@app/types/map/MapViewportState";

import { MapFetcherPayload } from "../types/MapFetcher";

import { LeadFinderActions } from "./leadFinder/actions";

export const initializeMapView = createAsyncAction(
  "map/initialize/request",
  "map/initialize/success",
  "map/initialize/failure"
)<(viewport: MapPersistedViewportState) => void, void, unknown>();

export const updateViewportState = createAction("map/updateViewportState")<MapViewportState>();

export const updateMapStyle = createAction("map/updateStyle")<BaseMapStyle>();

export const selectMapTool = createAction("map/selectTool")<MapToolPayload>();

export const setMapTool = createAction("map/setTool")<MapToolPayload>();

export const updatePinDropCoordinates = createAsyncAction(
  "map/pinDrop/updateCoordinates/request",
  "map/pinDrop/updateCoordinates/success",
  "map/pinDrop/updateCoordinates/failure"
)<GeolocationCoordinates | undefined, Located | undefined, unknown>();

export const appendLassoPath = createAction("map/lasso/appendPath")<GeoPath>();
export const cleanLassoPaths = createAction("map/lasso/cleanPaths")<void>();

export const updateLassoClusters = createAction("map/lasso/updateLassoClusters")<
  Cluster[] | undefined
>();
export const setLassoContainsFlags = createAction("map/lasso/setLassoContainsFlags")<{
  containsClusters?: boolean;
}>();

export const enterLassoMode = createAction("map/lasso/enterLassoMode")<void>();
export const exitLassoMode = createAction("map/lasso/exitLassoMode")<void>();
export const refreshLassoMode = createAction("map/lasso/refreshLassoMode")<void>();

export const enterMode = createAction("map/enterMode")<MapMode | undefined>();
export const exitMode = createAction("map/exitMode")<void>();

export const fetchLassoSelection = createAsyncAction(
  "map/lasso/fetchLassoSelection/request",
  "map/lasso/fetchLassoSelection/success",
  "map/lasso/fetchLassoSelection/failure"
)<MapFetcherPayload, void, unknown>();

export const enableEngagementFields = createAsyncAction(
  "map/lasso/enableEngagementFields/request",
  "map/lasso/enableEngagementFields/success",
  "map/lasso/enableEngagementFields/failure"
)<void, void, unknown>();

export const setOfferedFilters = createAction("map/filtering/setOfferedFilter")<
  ModifiedMapFilterModel | undefined
>();

export const setMapSettings = createAction("map/setMapSettings")<
  Partial<PersistentMapSettings> | undefined
>();

export const setLocationSearchVisibility = createAction("map/toggleLocationSearch")<boolean>();

export * from "./groupMode/actions";
export * from "./layers/actions";
export * from "./leadFinder/actions";
export * from "./mapMode/actions";
export * from "./pinLegends/actions";
export * from "./pinLocation/actions";
export * from "./territoryMode/actions";

export type Actions =
  | ActionType<
      | typeof appendLassoPath
      | typeof cleanLassoPaths
      | typeof enableEngagementFields
      | typeof enterLassoMode
      | typeof enterMode
      | typeof exitLassoMode
      | typeof exitMode
      | typeof fetchLassoSelection
      | typeof initializeMapView
      | typeof refreshLassoMode
      | typeof selectMapTool
      | typeof setLassoContainsFlags
      | typeof setLocationSearchVisibility
      | typeof setMapSettings
      | typeof setMapTool
      | typeof setOfferedFilters
      | typeof updateLassoClusters
      | typeof updateMapStyle
      | typeof updatePinDropCoordinates
      | typeof updateViewportState
    >
  | GroupModeActions
  | LayersActions
  | LeadFinderActions
  | LegendsActions
  | MapModeActions
  | PinLegendsActions
  | PinLocationActions
  | TerritoryModeActions;
