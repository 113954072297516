export const BASE_CLUSTER_FILL_OPACITY = 0.85;
export const OTHER_CLUSTER_FILL_OPACITY = 0.5;

export const MAX_MAP_PREVIEW_FIELDS_LIMIT = 5;
export const MAP_RECORDS_PAGE_SIZE = 50;

export const LEAD_FINDER_MAX_SEARCHES = 5;

export const LEAD_FINDER_QUERY_LIMIT_FIRST = 60;
export const LEAD_FINDER_QUERY_LIMIT_FOLLOWING = 20;

export const LEAD_FINDER_DISTANCES_KM = [1, 10, 25, 50, 100];
export const LEAD_FINDER_DISTANCES_MILES = [1, 5, 10, 30, 50];
export const LEAD_FINDER_CUSTOM_DISTANCES_MAX = 10;
export const LEAD_FINDER_DEFAULT_DISTANCE_KM = 50;
export const LEAD_FINDER_DEFAULT_DISTANCE_MILES = 30;
export const LEAD_FINDER_UPSELL_URL = "https://share.hsforms.com/195I7JIs9Qg69KxGekOA8wA3is1h";

export const LASSO_SELECTIONS_LIMIT = 1000;

export const MIN_LEGEND_HEIGHT = 360; // 22.5rem

export const MAP_RECORD_LIST_SCROLL_KEY = "MAP_ENTITIES";
