import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const checkInsState = (state: RootState) => state.scene.dashboard.checkInsState;

export const getCheckInsDrillDownViewState = createSelector(
  checkInsState,
  ({ viewState }) => viewState
);

export const getCheckInsDrillDownTotalFilteredRecords = createSelector(
  checkInsState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getCheckInsDrillDownTotalRecords = createSelector(
  checkInsState,
  ({ totalRecords }) => totalRecords
);

export const getCheckInsDrillDownCustomDateRange = createSelector(
  checkInsState,
  ({ drillDownCustomDateRange }) => drillDownCustomDateRange
);

export const getCheckInsDrillDownConfiguration = createSelector(
  checkInsState,
  ({ configuration }) => configuration
);

export const isCheckInsDrillDownLoading = createSelector(checkInsState, ({ loading }) => loading);
