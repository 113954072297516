import { ActionType, createAction } from "typesafe-actions";

import PinLegendType from "@mapmycustomers/shared/enum/PinLegendType";
import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";

export const showCreatePinLegendDrawer = createAction("map/pinLegends/create")<{
  entityType: EntityTypesSupportedByMapsPage;
  type: PinLegendType;
}>();

export const showPinSettings = createAction("map/pinLegends/pinSettings")<boolean>();

export const showUpdatePinLegendDrawer = createAction("map/pinLegends/update")<PinLegend>();

export const hidePinLegendDrawer = createAction("map/pinLegends/hide")<void>();

export type PinLegendsActions = ActionType<
  | typeof hidePinLegendDrawer
  | typeof showCreatePinLegendDrawer
  | typeof showPinSettings
  | typeof showUpdatePinLegendDrawer
>;
