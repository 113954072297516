import React from "react";

import Col from "antd/es/col";
import Row from "antd/es/row";
import { useIntl } from "react-intl";

import arrows from "@app/assets/globalSearch/arrows.svg";
import enterIconSolid from "@app/assets/globalSearch/enterIconSolid.svg";
import layout from "@app/styles/layout";

import styles from "./BottomInfo.module.scss";

interface Props {}

const BottomInfo: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <Row className={styles.container} gutter={layout.spacerS}>
      <Col>
        <img alt="arrows" src={arrows} />
      </Col>
      <Col className={styles.text}>
        {intl.formatMessage({
          id: "component.globalSearch.bottom.select",
          defaultMessage: "Select",
          description: "select text on global search modal",
        })}
      </Col>
      <Col>
        <img alt="enter icon" src={enterIconSolid} />
      </Col>
      <Col className={styles.text}>
        {intl.formatMessage({
          id: "component.globalSearch.bottom.open",
          defaultMessage: "Open",
          description: "open text on global search modal",
        })}
      </Col>
    </Row>
  );
};

export default BottomInfo;
