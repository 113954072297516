import { Descendant, Text } from "slate";

const convertToText = (value: Descendant[]): string =>
  value
    .map((element) =>
      "text" in element
        ? element.text
        : element.children
            .map((child: Descendant) => {
              if ("text" in child) {
                return (child as Text).text;
              }
              return "";
            })
            .join("")
    )
    .join("\n");

export default convertToText;
