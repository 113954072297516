import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import Input from "antd/es/input";
import Popover from "antd/es/popover";
import cn from "classnames";
import { useIntl } from "react-intl";

import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import { AreaFilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import { fetchCompany } from "@app/store/company/actions";
import { fetchPerson } from "@app/store/person/actions";
import { isEntityValue } from "@app/util/filters/Location/assert";
import { isAreaFilterCondition } from "@app/util/viewModel/assert";

import getLocationPreview from "./getLocationPreview";
import styles from "./LocationFilter.module.scss";
import LocationFilterPopover from "./LocationFilterPopover";
import LocationFilterProps from "./LocationFilterProps";

interface Props extends LocationFilterProps {
  onFetchCompany: typeof fetchCompany;
  onFetchPerson: typeof fetchPerson;
}

const LocationFilter: React.FC<Props> = ({
  className,
  focus,
  onChange,
  onFetchCompany,
  onFetchPerson,
  options,
  value,
}) => {
  const intl = useIntl();

  const location = isAreaFilterCondition(value) ? value.value : undefined;
  const [locationEntity, setLocationEntity] = useState<Company | Person | undefined>();
  useEffect(() => {
    if (!isEntityValue(location)) {
      return;
    }

    if (
      locationEntity?.id !== location.entity.id ||
      locationEntity?.entity !== location.entity.type
    ) {
      setLocationEntity(undefined); // reset until reloaded
      if (location.entity.type === EntityType.COMPANY) {
        onFetchCompany({ id: location.entity.id, callback: setLocationEntity });
      } else if (location.entity.type === EntityType.PERSON) {
        onFetchPerson({ id: location.entity.id, callback: setLocationEntity });
      }
    }
  }, [location, locationEntity, onFetchCompany, onFetchPerson]);

  const [popoverVisible, setPopoverVisibility] = useState(false);

  const handleChange = useCallback(
    (condition: AreaFilterCondition) => {
      onChange?.(condition);
      setPopoverVisibility(false);
    },
    [onChange, setPopoverVisibility]
  );

  useEffect(() => {
    if (focus) {
      setPopoverVisibility(true);
    }
  }, [focus]);

  return (
    <Popover
      content={
        <LocationFilterPopover
          mapCenter={options?.mapCenter as LongLat}
          onChange={handleChange}
          onHide={() => setPopoverVisibility(false)}
          userPosition={options?.userPosition as GeolocationPosition}
          value={value as AreaFilterCondition}
        />
      }
      destroyTooltipOnHide
      onVisibleChange={setPopoverVisibility}
      placement="rightBottom"
      title={null}
      trigger="click"
      visible={popoverVisible}
    >
      <Input
        className={cn(styles.previewInput, className)}
        readOnly
        value={
          location
            ? getLocationPreview(intl, location, locationEntity)
            : intl.formatMessage({
                id: "filters.location.placeholder",
                defaultMessage: "Click to select location",
                description: "Placeholder for LocationFilter when there's no selected location yet",
              })
        }
      />
    </Popover>
  );
};

const mapDispatchToProps = {
  onFetchCompany: fetchCompany,
  onFetchPerson: fetchPerson,
};

export default connect(null, mapDispatchToProps)(LocationFilter);
