import React from "react";

import Tooltip from "antd/es/tooltip";
import { get } from "lodash-es";
import { useIntl } from "react-intl";

import { Company, EntityType } from "@mapmycustomers/shared/types/entity";

import ButtonLink from "@app/component/ButtonLink";
import { RegistryProps } from "@app/component/FieldGrid/utils/ComponentRegistry";
import useEntityLinkGetter from "@app/util/contexts/useEntityLinkGetter";

const CompanyField: React.FC<RegistryProps> = ({ entity, field }) => {
  const intl = useIntl();
  const getEntityLink = useEntityLinkGetter();

  const companyName = field.getFormattedValueFor(entity);
  const company = get(entity, field.name) as Company;

  return (
    <Tooltip
      placement="bottom"
      title={intl.formatMessage({
        id: "fieldGrid.field.company.tooltip",
        defaultMessage: "Open company",
        description: "Open company tooltip",
      })}
    >
      <ButtonLink href={getEntityLink(EntityType.COMPANY, company.id)} target="_blank">
        {companyName}
      </ButtonLink>
    </Tooltip>
  );
};

export default CompanyField;
