import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useIntl } from "react-intl";

import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { FormItem, SelectField } from "@mapmycustomers/ui";

import stageIcon from "@app/assets/icons/deal-stage-dark.svg";
import EntityBadge from "@app/component/EntityBadge";
import { getFunnels, getFunnelStages } from "@app/store/deal";
import { RootState } from "@app/store/rootReducer";
import dealFieldModel, { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import defaultSelectOptionMapper from "@app/util/mappers/defaultSelectOptionMapper";

import styles from "./FunnelStageField.module.scss";

interface OwnProps {
  autoSelectFunnel?: boolean;
  defaultFunnelId?: Funnel["id"];
  defaultStageId?: Stage["id"];
  disabled?: boolean;
  required?: boolean;
}

interface Props extends OwnProps {
  funnels: Funnel[];
  funnelStages: Record<Funnel["id"], Stage[]>;
}

const FunnelStageField: React.FC<Props> = ({
  autoSelectFunnel = true,
  defaultFunnelId,
  defaultStageId,
  disabled,
  funnels,
  funnelStages,
  required,
}) => {
  const intl = useIntl();
  const form = useFormInstance();

  const { funnelLabel, stageLabel } = useMemo(
    () => ({
      funnelLabel: dealFieldModel.getByName(DealFieldName.FUNNEL)!.displayName,
      stageLabel: dealFieldModel.getByName(DealFieldName.STAGE)!.displayName,
    }),
    []
  );

  const funnelId: Funnel["id"] | undefined = useWatch("funnelId", form);
  const funnelOptions = useMemo(() => funnels.map(defaultSelectOptionMapper), [funnels]);
  const stageOptions = useMemo(
    () =>
      (funnelId ? funnelStages[funnelId] ?? [] : []).map((stage) => ({
        key: stage.id,
        label: <EntityBadge entity={stage} />,
        value: stage.id,
      })),
    [funnelStages, funnelId]
  );

  const handleFunnelChange = useCallback(
    (funnelId?: Funnel["id"]) => form.setFieldsValue({ funnelId, stageId: undefined }),
    [form]
  );

  const handleStageChange = useCallback(
    (stageId: Stage["id"]) => form.setFieldsValue({ stageId }),
    [form]
  );

  // Setting Default Funnel if given or the first funnel otherwise
  useEffect(() => {
    if (funnels.length && !funnelId && autoSelectFunnel) {
      form.setFieldsValue({
        funnelId: defaultFunnelId,
        // only set stage when both default funnel and stage are specified
        stageId: defaultFunnelId && defaultStageId ? defaultStageId : undefined,
      });
    }
  }, [autoSelectFunnel, defaultFunnelId, defaultStageId, form, funnelId, funnels]);

  return (
    <div className={styles.container} data-id="js-funnel-staging-field">
      <FormItem label={funnelLabel} name="funnelId" required={required} rules={[{ required }]}>
        <SelectField<Funnel["id"]>
          dropdownMatchSelectWidth={false}
          label={funnelLabel}
          locked={disabled}
          onChange={handleFunnelChange}
          options={funnelOptions}
          placeholder={intl.formatMessage({
            // TODO: fixme 2023-11-07 change message id
            id: "createDealModal.field.funnel.placeholder",
            defaultMessage: "Select",
            description: "Funnel field placeholder",
          })}
          prefixIcon={<FontAwesomeIcon icon={faFilter} />}
          required={required}
        />
      </FormItem>

      <FormItem label={stageLabel} name={"stageId"} required={required} rules={[{ required }]}>
        <SelectField<Stage["id"]>
          dropdownMatchSelectWidth={false}
          label={stageLabel}
          locked={disabled}
          onChange={handleStageChange}
          options={stageOptions}
          placeholder={intl.formatMessage({
            id: "createDealModal.field.stage.placeholder",
            defaultMessage: "Select",
            description: "Stage field placeholder",
          })}
          prefixIcon={<img alt="stage icon" className={styles.stageIcon} src={stageIcon} />}
          required={required}
        />
      </FormItem>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnels: getFunnels(state),
  funnelStages: getFunnelStages(state),
});

export default connect(mapStateToProps)(FunnelStageField);
