import React, { useMemo } from "react";

import Popover from "antd/es/popover";
import Tooltip from "antd/es/tooltip";

import { Company, Person } from "@mapmycustomers/shared/types/entity";

import RecordLocationMiniMap from "@app/component/field/LocationMiniMap/RecordLocationMiniMap";
import LongContentParagraph from "@app/component/typography/LongContentParagraph";
import { FULL_ADDRESS } from "@app/util/consts";
import { entityToAddressLines } from "@app/util/formatters";

import styles from "./AddressLine.module.scss";

interface Props {
  addressRecord?: Company | Person;
}

const AddressLine: React.FC<Props> = ({ addressRecord }) => {
  const addressLine = useMemo(() => {
    if (!addressRecord) {
      return undefined;
    }
    const { line1, line2, line3 } = entityToAddressLines(addressRecord, FULL_ADDRESS, {
      locationWhenNoAddress: true,
    });
    return [line1, line2, line3].join(" ");
  }, [addressRecord]);

  if (!addressLine) {
    return null;
  }

  return addressRecord?.geoPoint ? (
    <Popover
      content={
        <RecordLocationMiniMap
          before={<span className={styles.address}>{addressLine}</span>}
          className={styles.map}
          // we ensured that geoPoint is defined, so node.data is assignable to MappedEntity
          // @ts-ignore
          entity={addressRecord}
        />
      }
      placement="right"
      // transition name must be empty, otherwise map or map controls might be not displayed:
      // https://stackoverflow.com/a/64416167/5346779
      transitionName=""
    >
      <LongContentParagraph className={styles.address} expandable={false} rows={2}>
        {addressLine}
      </LongContentParagraph>
    </Popover>
  ) : (
    <Tooltip mouseEnterDelay={1} title={addressLine}>
      <LongContentParagraph className={styles.address} expandable={false} rows={2}>
        {addressLine}
      </LongContentParagraph>
    </Tooltip>
  );
};

export default AddressLine;
