export interface SvgCircleWithTextConfig {
  fillColor?: string;
  fontFamily?: string;
  fontSize?: string;
  size: number;
  strokeColor?: string;
  text: string;
  textColor?: string;
}

const getSvgForCircleWithText = ({
  fillColor,
  fontFamily = "Twemoji Country Flags, Roboto, sans-serif",
  fontSize = "12px",
  size,
  strokeColor,
  text,
  textColor,
}: SvgCircleWithTextConfig) => {
  const half = size / 2;
  return `<g>
    <circle cx="${half}" cy="${half}" r="${half}" fill="${fillColor}" stroke="${strokeColor}" stroke-width="2" />
    <text style="font-size: ${fontSize}" font-family="${fontFamily}" x="${half}" y="${
    half + 1
  }" fill="${textColor}" dominant-baseline="middle" text-anchor="middle">${text}</text>
  </g>`;
};

export default getSvgForCircleWithText;
