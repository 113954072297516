import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import { MultiPin } from "@mapmycustomers/shared/types/map";

import { Feature } from "@app/component/map/FeaturedMap";
import MarkerEffect from "@app/enum/MarkerEffect";
import MapTooltip from "@app/scene/map/components/MapTooltip";
import { getMapSettings } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import useAnalytics from "@app/util/contexts/useAnalytics";
import defaultIdGetter from "@app/util/defaultIdGetter";
import loggingService from "@app/util/logging";
import { PIN_ZINDEX } from "@app/util/map/consts";
import defaultMapEntryGeometryGetter from "@app/util/map/defaultMapEntryGeometryGetter";
import { multiPinIdGetter } from "@app/util/map/idGetters";
import isValidMapEntry from "@app/util/map/isValidMapEntry";
import typedIdGetter from "@app/util/typedIdGetter";
import { getEntityTypeAnalyticsName } from "@app/util/ui";

import defaultMultiPinStyleGetter from "./utils/defaultMultiPinStyleGetter";

const isDifferent = (a: MultiPin, b: MultiPin) =>
  a.id !== b.id ||
  a.count !== b.count ||
  a.sumAmount !== b.sumAmount ||
  a.data.color !== b.data.color ||
  a.data.shape !== b.data.shape ||
  Math.abs(a.region!.latitude - b.region!.latitude) > Number.EPSILON ||
  Math.abs(a.region!.longitude - b.region!.longitude) > Number.EPSILON;

interface Props {
  activeMultiPin?: string;
  mapSettings: {
    markerSize: MarkerSize;
    showEntityIcons: boolean;
  };
  multiPins: MultiPin[];
  onShowMultiPinRecords?: (multiPin: MultiPin) => void;
  styleGetter?: (item: MultiPin) => google.maps.Data.StyleOptions;
  type?: string;
  zIndex?: number;
}

const MultiPinFeature: React.FC<Props> = ({
  activeMultiPin,
  mapSettings,
  multiPins,
  onShowMultiPinRecords,
  styleGetter,
  type = "multiPins",
  zIndex = PIN_ZINDEX,
}) => {
  const analytics = useAnalytics();
  const intl = useIntl();

  const [hoveredPinId, setHoveredPinId] = useState<string | undefined>();

  const handleEntityClick = useCallback(
    (pin: MultiPin, event: google.maps.MapMouseEvent) => {
      loggingService.debug("multi pin clicked", { entity: pin, event });

      setHoveredPinId(undefined);
      onShowMultiPinRecords?.(pin);

      analytics.clicked([`${getEntityTypeAnalyticsName(pin.data.entity, false, true)} Pin`], {
        multiPin: true,
      });
    },
    [analytics, onShowMultiPinRecords]
  );

  const handleEntityMouseOver = useCallback(
    (pin: MultiPin) => setHoveredPinId(defaultIdGetter(pin)),
    []
  );
  const handleEntityMouseLeave = useCallback(() => setHoveredPinId(undefined), []);

  const multiPinEffectGetter = useMemo(
    () => (multiPin: MultiPin) =>
      activeMultiPin && activeMultiPin === multiPin.id ? MarkerEffect.OUTLINE : MarkerEffect.SHADOW,
    [activeMultiPin]
  );

  const multiPinStyleGetter = useMemo(
    () =>
      defaultMultiPinStyleGetter({
        effect: multiPinEffectGetter,
        markerSize: mapSettings.markerSize,
        shape: (multiPin: MultiPin) => multiPin.data.shape ?? MarkerShape.SQUARE,
        zIndex,
      }),
    [mapSettings.markerSize, multiPinEffectGetter, zIndex]
  );

  const tooltipPosition = useMemo(() => {
    const hoveredPin = hoveredPinId
      ? multiPins.find((pin) => multiPinIdGetter(pin) === hoveredPinId)
      : undefined;
    return hoveredPin?.region
      ? { lat: hoveredPin.region.latitude, lng: hoveredPin.region.longitude }
      : undefined;
  }, [hoveredPinId, multiPins]);

  return (
    <>
      <Feature<MultiPin>
        geometryGetter={defaultMapEntryGeometryGetter}
        idGetter={typedIdGetter}
        isDifferent={isDifferent}
        items={multiPins.filter(isValidMapEntry)}
        onClick={handleEntityClick}
        onMouseLeave={handleEntityMouseLeave}
        onMouseOver={handleEntityMouseOver}
        styleGetter={styleGetter ?? multiPinStyleGetter}
        type={type}
      />
      <MapTooltip
        position={tooltipPosition}
        tooltip={intl.formatMessage({
          id: "map.tooltip.entity.multiPin",
          defaultMessage: "Multiple Records",
          description: "Multiple Records - tooltip text",
        })}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  mapSettings: getMapSettings(state),
});

export default connect(mapStateToProps)(MultiPinFeature);
