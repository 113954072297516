import { UserRef } from "../../types/User";

const getInitials = (user: UserRef): string =>
  user.fullName
    ? user.fullName
        .split(/\s/)
        // Filter out empty strings which would otherwise cause word[0] to fail.
        // We may have empty strings because of unexpected leading/trailing spaces or
        // double spaces in the middle of the name.
        .filter((word) => word.length)
        .map((word) => word[0].toUpperCase())
        .slice(0, 2)
        .join("")
    : user.username.substring(0, 1).toUpperCase();

export default getInitials;
