import React, { useMemo } from "react";
import { connect } from "react-redux";

import AntDLayout from "antd/es/layout";

import defaultOrganizationLogo from "@app/assets/logo.svg";
import localSettings from "@app/config/LocalSettings";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import { getOrganizationName, getOrganizationSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import { complementFileUrl } from "@app/util/file";

import { showGlobalSearch } from "../../store/globalSearch/actions";

import EmailQueue from "./component/EmailQueue";
import Menu from "./component/Menu";
import OrganizationLogo from "./component/OrganizationLogo";
import RightMenu from "./component/RightMenu";
import SearchButton from "./component/SearchButton";
import UserMenu from "./component/UserMenu";
import styles from "./NavigationBar.module.scss";

interface Props {
  organizationLogoUrl?: string;
  organizationName?: string;
  showGlobalSearch: typeof showGlobalSearch;
}

export const NavigationBar: React.FC<Props> = ({
  organizationLogoUrl,
  organizationName,
  showGlobalSearch,
}) => {
  const logoUrl = useMemo(
    () => complementFileUrl(organizationLogoUrl) ?? defaultOrganizationLogo,
    [organizationLogoUrl]
  );

  const wasOpenedFromMobileApp = localSettings.wasOpenedFromMobileApp();

  return (
    <AntDLayout.Header className={styles.container}>
      <section className={styles.leftSection}>
        <OrganizationLogo logoUrl={logoUrl} organizationName={organizationName} />
        {wasOpenedFromMobileApp ? null : <Menu />}
      </section>
      {wasOpenedFromMobileApp ? null : (
        <section className={styles.rightSection}>
          <EmailQueue />
          <SearchButton onClick={showGlobalSearch} />
          <RightMenu />
          <UserMenu />
        </section>
      )}
    </AntDLayout.Header>
  );
};

const mapStateToProps = (state: RootState) => ({
  organizationLogoUrl: getOrganizationSettingValue(state)(OrganizationSetting.LOGO),
  organizationName: getOrganizationName(state),
});

const mapDispatchToProps = {
  showGlobalSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
