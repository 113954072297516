import React, { useCallback, useEffect, useState } from "react";

import { endOfDay, isValid, parseISO, startOfDay } from "date-fns/esm";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import DatePicker from "@app/component/input/DatePicker";

import { parseApiDate } from "../parsers";
import keepValidDateOrReturnUndefined from "../parsers/keepValidDateOrReturnUndefined";

export const DATE_FILTER_OPERATORS = [
  FilterOperator.ON,
  FilterOperator.NOT_ON,
  FilterOperator.AFTER,
  FilterOperator.ON_OR_AFTER,
  FilterOperator.BEFORE,
  FilterOperator.ON_OR_BEFORE,
];

interface DateFilterProps extends IFilterComponentProps {}

const DateFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    const date = typeof value === "string" ? parseISO(value) : undefined;
    return isValid(date) && DATE_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<DateFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const [date, setDate] = useState(
        value?.value ? keepValidDateOrReturnUndefined(parseApiDate(value.value)) : undefined
      );

      // using useEffect instead of "useCallback" for handleDateChange because we also
      // need to listen to operator changes and transform date accordingly
      useEffect(() => {
        const operator = value.operator;
        let dateTransformer = (date: Date): Date => date; // default behavior is to not change date
        if (operator === FilterOperator.BEFORE || operator === FilterOperator.ON_OR_AFTER) {
          dateTransformer = startOfDay;
        } else if (operator === FilterOperator.AFTER || operator === FilterOperator.ON_OR_BEFORE) {
          dateTransformer = endOfDay;
        }

        onChange?.({
          operator,
          value: date ? dateTransformer(date).toISOString() : undefined,
        });
      }, [date, onChange, value.operator]);

      const setRef = useCallback(
        (ref) => {
          if (ref && focus) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <DatePicker
          allowClear
          className={className}
          format="PPP"
          onChange={(date: Date | null) => setDate(date || undefined)}
          ref={setRef}
          value={date}
        />
      );
    },
};

export default DateFilter;
