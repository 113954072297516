@use "../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.5rem;
        column-gap: 1rem;
        flex-wrap: wrap;
      }

      .fileContainer {
        width: calc(50% - 1rem);
        background-color: colors.$mmc-soft-gray;
        border-radius: 0.5rem;
        padding: 0.5rem;
        color: colors.$mmc-soft-slate;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      .mainFileContainer {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .clicable {
        cursor: pointer;
      }

      .fileIcon {
        min-width: 0;
        width: 1.25rem;
        height: 1.25rem;
        line-height: 0.75rem;
        svg {
          height: 0.625rem;
        }
      }

      .downloadIcon {
        color: colors.$mmc-primary-100;
        cursor: pointer;
      }

      .deleteIcon {
        cursor: pointer;
      }

      .divider {
        border-color: colors.$mmc-slate-010;
        margin: 0;
      }
      .fileName {
        flex: 1;
        display: flex;
        align-items: center;
        min-width: 0;
      }
      .fileNamePure {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .fileNameExtra {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
      .actions {
        display: flex;
        min-width: 0;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}
