import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import ActivitiesLoggedCardData from "@app/scene/dashboard/store/cards/activitiesLogged/ActivitiesLoggedCardData";
import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import ActivitiesLoggedCardConfiguration from "@app/types/dashboard/cards/activitiesLogged/ActivitiesLoggedCardConfiguration";
import Scope from "@app/types/dashboard/Scope";

export const fetchActivitiesLoggedCardData = createAction(
  "@dashboard/fetchActivitiesLoggedCardData"
)<{
  callback: (data: ActivitiesLoggedCardData) => void;
  configuration: ActivitiesLoggedCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showActivitiesLoggedDrillDown = createAsyncAction(
  "@dashboard/showActivitiesLoggedCardDrillDownModal/request",
  "@dashboard/showActivitiesLoggedCardDrillDownModal/success",
  "@dashboard/showActivitiesLoggedCardDrillDownModal/failure"
)<
  {
    configuration: ActivitiesLoggedCardConfiguration;
    customDateRange?: DateRange;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideActivitiesLoggedDrillDown = createAction(
  "@dashboard/hideActivitiesLoggedCardDrillDownModal"
)<void>();

export const fetchActivitiesLoggedCardDrillDownData = createAsyncAction(
  "@dashboard/fetchActivitiesLoggedCardDrillDownData/request",
  "@dashboard/fetchActivitiesLoggedCardDrillDownData/success",
  "@dashboard/fetchActivitiesLoggedCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Activity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyActivitiesLoggedDrillDownListViewSettings = createAction(
  "@dashboard/applyActivitiesLoggedDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportActivitiesLoggedCardDrillDownData = createAction(
  "@dashboard/exportActivitiesLoggedCardData"
)<{
  configuration: ActivitiesLoggedCardConfiguration;
  scope: Scope;
}>();

export type ActivitiesLoggedCardActions = ActionType<
  | typeof applyActivitiesLoggedDrillDownListViewSettings
  | typeof exportActivitiesLoggedCardDrillDownData
  | typeof fetchActivitiesLoggedCardData
  | typeof fetchActivitiesLoggedCardDrillDownData
  | typeof hideActivitiesLoggedDrillDown
  | typeof showActivitiesLoggedDrillDown
>;
