import React from "react";

import { bem } from "@react-md/utils";

import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import getCountryFlag from "@mapmycustomers/shared/util/countries/getCountryFlag";

import { useConfigProvider } from "../ConfigProvider";

const block = bem("mmc-country-with-flag");

interface Props {
  countryCode: CountryCode;
}

const CountryWithFlag: React.FC<Props> = ({ countryCode }) => {
  const configProvider = useConfigProvider();
  return (
    <div className={block()}>
      <div className={block("flag")}>{getCountryFlag(countryCode)}</div>
      <div>{configProvider.formatCountryName(countryCode)}</div>
    </div>
  );
};

export default CountryWithFlag;
