import React from "react";

import IFrequencyContext from "../type/IFrequencyContext";

const FrequencyContext = React.createContext<IFrequencyContext>({
  entityDoesHaveFrequencyAlready: false,
  isEntityFrequencyUpdating: false,
  onUpdate: () => {},
});

export default FrequencyContext;
