import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import Colored from "@mapmycustomers/shared/types/base/Colored";
import Located from "@mapmycustomers/shared/types/base/Located";
import Shaped from "@mapmycustomers/shared/types/base/Shaped";
import { EntityWithLocation } from "@mapmycustomers/shared/types/entity";

export type EnterPinLocationModePayload = {
  entity: EntityWithLocation;
  entityWithoutLocation?: boolean;
};

export const enterPinLocationMode = createAction(
  "map/enterPinLocationMode"
)<EnterPinLocationModePayload>();

export const exitPinLocationMode = createAction("map/exitPinLocationMode")<void>();

export const setPinLocationColor = createAction("map/setPinLocationColor")<Colored["color"]>();
export const setPinLocationShape = createAction("map/setPinLocationShape")<Shaped["shape"]>();

export const geocodePinLocation = createAsyncAction(
  "map/geocodePinLocation/request",
  "map/geocodePinLocation/success",
  "map/geocodePinLocation/failure"
)<
  GeolocationCoordinates,
  {
    addressChanged?: boolean;
    located?: Located;
  },
  void
>();

export const updatePinLocation = createAsyncAction(
  "map/updatePinLocation/request",
  "map/updatePinLocation/success",
  "map/updatePinLocation/failure"
)<{ exitMode: boolean } | void, void, void>();

export type PinLocationActions = ActionType<
  | typeof enterPinLocationMode
  | typeof exitPinLocationMode
  | typeof geocodePinLocation
  | typeof setPinLocationColor
  | typeof setPinLocationShape
  | typeof updatePinLocation
>;
