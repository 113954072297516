import { useCallback } from "react";

import { Activity } from "@mapmycustomers/shared/types/entity";

import HideAction from "@app/types/HideCallback";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";

import { RecurringActionType } from "../enum/RecurringActionType";
import { deleteActivity } from "../store/actions";

const useDeleteCallback = (
  activity: Activity | undefined,
  deleteActivityRequest: typeof deleteActivity.request,
  onHide: HideAction<Activity>,
  analyticIssuer?: AnalyticsService
) =>
  useCallback(
    (deleteType?: RecurringActionType) => {
      if (!activity) {
        return;
      }

      deleteActivityRequest({
        activityId: activity.id,
        callback: () => {
          onHide(true, activity);
          analyticIssuer?.feature(["Activity Deleted"], { activity });
        },
        deleteType,
      });
    },
    [activity, analyticIssuer, deleteActivityRequest, onHide]
  );

export default useDeleteCallback;
