import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const newRecordsState = (state: RootState) => state.scene.dashboard.newRecordsState;

export const getNewRecordsDrillDownViewState = createSelector(
  newRecordsState,
  ({ viewState }) => viewState
);

export const getNewRecordsDrillDownTotalFilteredRecords = createSelector(
  newRecordsState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getNewRecordsDrillDownTotalRecords = createSelector(
  newRecordsState,
  ({ totalRecords }) => totalRecords
);

export const getNewRecordsDrillDownCustomDateRange = createSelector(
  newRecordsState,
  ({ drillDownCustomDateRange }) => drillDownCustomDateRange
);

export const getNewRecordsDrillDownConfiguration = createSelector(
  newRecordsState,
  ({ configuration }) => configuration
);

export const isNewRecordsDrillDownLoading = createSelector(
  newRecordsState,
  ({ loading }) => loading
);
