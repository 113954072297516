import { earthRadius } from "@turf/helpers";
import { BBox } from "geojson";

import LongLat from "../../types/base/LongLat";

function rad(num: number) {
  return (num * Math.PI) / 180;
}

export function pathArea(coords: LongLat[]) {
  let p1;
  let p2;
  let p3;
  let lowerIndex;
  let middleIndex;
  let upperIndex;
  let i;
  let total = 0;
  const coordsLength = coords.length;
  if (coordsLength > 2) {
    for (i = 0; i < coordsLength; i++) {
      if (i === coordsLength - 2) {
        // i = N-2
        lowerIndex = coordsLength - 2;
        middleIndex = coordsLength - 1;
        upperIndex = 0;
      } else if (i === coordsLength - 1) {
        // i = N-1
        lowerIndex = coordsLength - 1;
        middleIndex = 0;
        upperIndex = 1;
      } else {
        // i = 0 to N-3
        lowerIndex = i;
        middleIndex = i + 1;
        upperIndex = i + 2;
      }
      p1 = coords[lowerIndex];
      p2 = coords[middleIndex];
      p3 = coords[upperIndex];
      total += (rad(p3[0]) - rad(p1[0])) * Math.sin(rad(p2[1]));
    }
    total = (total * earthRadius * earthRadius) / 2;
  }
  return Math.abs(total);
}

export function bboxArea(bbox: BBox) {
  const [west, south, east, north] = bbox;
  // or a workaround:
  // return pathArea([
  //   [west, south],
  //   [east, south],
  //   [east, north],
  //   [west, north],
  //   [west, south],
  // ]);
  return (
    (earthRadius *
      earthRadius *
      Math.PI *
      Math.abs(Math.sin(rad(south)) - Math.sin(rad(north))) *
      Math.abs(east - west)) /
    180
  );
}
