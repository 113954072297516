import { all, fork } from "redux-saga/effects";

import { formLayoutsSaga } from "./component/FormLayouts/store/saga";
import { importsSaga } from "./component/Imports/store/saga";
import { manageFieldsSaga } from "./component/ManageFields/store/saga";
import { mergeDuplicatesSaga } from "./component/MergeDuplicates/store/saga";
import { sandboxSaga } from "./component/Sandbox/store/saga";

export function* settingsSaga() {
  yield all([fork(mergeDuplicatesSaga)]);
  yield all([fork(manageFieldsSaga)]);
  yield all([fork(formLayoutsSaga)]);
  yield all([fork(sandboxSaga)]);
  yield all([fork(importsSaga)]);
}
