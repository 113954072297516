import { defineMessages } from "react-intl";

const messages = defineMessages({
  okText: {
    id: "email.creationModal.addRecipients.okText",
    defaultMessage: "Select",
    description: "Add Recipients ok text in email creation modal",
  },
  recordWithEmail: {
    id: "email.creationModal.addRecipients.recordWithEmail",
    defaultMessage: "Record With Email ({count}/{total})",
    description: "Add Recipients ok text in email creation modal",
  },
});

export default messages;
