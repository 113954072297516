@use "../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-modal-header) {
          padding-bottom: 0;
          border: none;
        }
        :global(.ant-modal-body) {
          padding-top: 0.5rem;
          border: none;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
        }
        :global(.ant-modal-footer) {
          padding: 0 1rem 1rem;
          border: none;
        }
      }
      .preview {
        cursor: pointer;
        color: colors.$mmc-primary-100;
      }

      .label {
        color: colors.$mmc-soft-slate;
      }

      .link {
        width: 100%;
      }
    }
  }
}
