import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import FormLayout, { ChildLayout } from "@mapmycustomers/shared/types/layout/FormLayout";
import FormLayoutRoleAssociation, {
  FormLayoutRoleAssociationRecord,
} from "@mapmycustomers/shared/types/layout/FormLayoutRoleAssociation";

import { FormLayoutRoleMap } from "../type/FormLayoutRoleMap";

export const setActiveEntityType = createAction(
  "settings/formLayouts/setActiveEntityType"
)<EntityTypesSupportingFieldCustomization>();

export const initiateEditLayout = createAsyncAction(
  "settings/formLayouts/initiateEditLayout/request",
  "settings/formLayouts/initiateEditLayout/success",
  "settings/formLayouts/initiateEditLayout/failure"
)<
  {
    entityType: EntityTypesSupportingFieldCustomization;
    id: "create" | FormLayout["id"];
  },
  Partial<FormLayout>,
  unknown
>();
export const cancelEditLayout = createAction("settings/formLayouts/cancelEditLayout")<void>();

export const updateLayout = createAction("settings/formLayouts/updateLayout")<
  Partial<FormLayout>
>();

export const deleteLayout = createAction("settings/formLayouts/deleteLayout")<FormLayout["id"]>();

export const updateChildLayout = createAction("settings/formLayouts/updateChildLayout")<
  Partial<ChildLayout>
>();

export const selectChildLayout = createAction("settings/formLayouts/selectChildLayout")<
  ChildLayout["id"] | undefined
>();

export const createChildLayout = createAction("settings/formLayouts/createChildLayout")<{
  activityTypeId?: ActivityType["id"];
  stage?: Stage;
}>();

export const deleteChildLayout = createAction("settings/formLayouts/deleteChildLayout")<
  ChildLayout["id"]
>();

export const saveLayout = createAsyncAction(
  "settings/formLayouts/saveLayout/request",
  "settings/formLayouts/saveLayout/success",
  "settings/formLayouts/saveLayout/failure"
)<void, FormLayout, unknown>();

export const reloadLayouts = createAsyncAction(
  "settings/formLayouts/reloadLayouts/request",
  "settings/formLayouts/reloadLayouts/success",
  "settings/formLayouts/reloadLayouts/failure"
)<
  void,
  {
    layouts: FormLayout[];
    total: number;
  },
  unknown
>();

export const reloadLayoutRoles = createAsyncAction(
  "settings/formLayouts/reloadLayoutRoles/request",
  "settings/formLayouts/reloadLayoutRoles/success",
  "settings/formLayouts/reloadLayoutRoles/failure"
)<
  void,
  {
    data: Array<FormLayoutRoleAssociation>;
    map: FormLayoutRoleMap;
  },
  unknown
>();

export const saveLayoutsRoles = createAsyncAction(
  "settings/formLayouts/saveLayoutsRoles/request",
  "settings/formLayouts/saveLayoutsRoles/success",
  "settings/formLayouts/saveLayoutsRoles/failure"
)<Array<FormLayoutRoleAssociationRecord>, void, unknown>();

export const setAsDefault = createAsyncAction(
  "settings/formLayouts/setAsDefault/request",
  "settings/formLayouts/setAsDefault/success",
  "settings/formLayouts/setAsDefault/failure"
)<FormLayout, void, unknown>();

export const reloadLayoutsAndRoles = createAction(
  "settings/formLayouts/reloadLayoutsAndRoles"
)<void>();

export const showLayoutRolesAssociation = createAction(
  "settings/formLayouts/showLayoutRolesAssociation"
)<void>();

export const cancelLayoutRolesAssociation = createAction(
  "settings/formLayouts/cancelLayoutRolesAssociation"
)<void>();

export const dismissActivityTypePreviewWarning = createAction(
  "settings/formLayouts/dismissActivityTypePreviewWarning"
)<void>();

export const dismissDealStagePreviewWarning = createAction(
  "settings/formLayouts/dismissDealStagePreviewWarning"
)<void>();

export type Actions = ActionType<
  | typeof cancelEditLayout
  | typeof cancelLayoutRolesAssociation
  | typeof createChildLayout
  | typeof deleteChildLayout
  | typeof deleteLayout
  | typeof dismissActivityTypePreviewWarning
  | typeof dismissDealStagePreviewWarning
  | typeof initiateEditLayout
  | typeof reloadLayoutRoles
  | typeof reloadLayouts
  | typeof reloadLayoutsAndRoles
  | typeof saveLayout
  | typeof saveLayoutsRoles
  | typeof selectChildLayout
  | typeof setActiveEntityType
  | typeof setAsDefault
  | typeof showLayoutRolesAssociation
  | typeof updateChildLayout
  | typeof updateLayout
>;
