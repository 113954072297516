import React from "react";

import Tooltip, { TooltipPlacement } from "antd/es/tooltip";

import { isMacOS } from "@mapmycustomers/shared/util/browser";

import { useConfigProvider } from "../../ConfigProvider";

interface ActionTooltipWrapperProps {
  children: React.ReactNode;
  placement?: TooltipPlacement;
  preventSystemDetection?: boolean;
  translationString: string;
}

const ActionTooltipWrapper: React.FC<ActionTooltipWrapperProps> = ({
  children,
  placement = "top",
  preventSystemDetection,
  translationString,
}) => {
  const configProvider = useConfigProvider();

  return (
    <Tooltip
      placement={placement}
      title={
        preventSystemDetection
          ? configProvider.formatMessage(translationString)
          : isMacOS()
          ? configProvider.formatMessage(`${translationString}.mac`)
          : configProvider.formatMessage(`${translationString}.win`)
      }
    >
      {children}
    </Tooltip>
  );
};

export default ActionTooltipWrapper;
