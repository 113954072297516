import React, { memo, useEffect, useRef } from "react";

import cn from "classnames";

import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";

import styles from "@app/util/filters/Location/AreaFilterMap.module.scss";
import useMapRef from "@app/util/hook/map/useMapRef";

interface Props {
  className?: string;
  coordinates?: google.maps.LatLngLiteral;
  onChange?: (coordinates: google.maps.LatLng, radiusInMeters: number) => void;
  radiusInMeters: number;
}

const AreaFilterMap: React.FC<Props> = ({ className, coordinates, onChange, radiusInMeters }) => {
  const mapDivRef = useRef<HTMLDivElement>(null);
  const { map } = useMapRef(
    {
      center: coordinates ?? { lat: 0, lng: 0 },
      controlSize: 32,
      disableDefaultUI: true,
      gestureHandling: "cooperative",
      zoom: coordinates ? 12 : 2,
      zoomControl: true,
    },
    mapDivRef
  );

  const circle = useRef<google.maps.Circle | undefined>();

  // This is a dirty hack to skip handleCircleChange when the change was initiated by
  // ourselves (in the useEffect below). It works like this without this hack:
  // 1. AreaFilter renders AreaFilterMap
  // 2. AreaFilterMap calls useEffect and updates some circle details (center, radius)
  // 3. AgGrid calls setModel in AreaFilter giving saved filter details
  // 4. handleCircleChange is called and we call onChange with the coords and radius from step 1
  // 5. AreaFilter's handleMapChange sets center and radius overwriting data received on step 3
  // So we lose saved filter.
  //
  // Thus we need to distinguish user-initiated events and AreaFilter-initiated events.
  // If you know a better solution, please let me know. Also, see this question on
  // stackoverflow: https://stackoverflow.com/q/8950814/5346779
  const skipCircleChange = useRef(false);

  // must be a dynamic callback since we only subscribe to changes once
  const handleCircleChange = useDynamicCallback(() => {
    if (circle.current && !skipCircleChange.current) {
      onChange?.(circle.current.getCenter()!, circle.current.getRadius());
    }
  });

  useEffect(() => {
    skipCircleChange.current = true;

    if (map) {
      if (!circle.current) {
        circle.current = new google.maps.Circle({
          center: coordinates ?? { lat: 0, lng: 0 },
          editable: true,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map,
          radius: radiusInMeters,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        });
        circle.current.addListener("bounds_changed", handleCircleChange);
        if (coordinates) {
          const bounds = circle.current.getBounds();
          if (bounds) {
            // fit when just created
            map.fitBounds(bounds, 16);
          }
        }
      } else {
        circle.current.setMap(map); // needed to guarantee circle is assigned to the freshest map instance
        const bounds = circle.current.getBounds();
        if (bounds) {
          map.fitBounds(bounds);
        }
      }
      if (coordinates) {
        map.panTo(coordinates);
        circle.current.setCenter(coordinates);
      }
      circle.current.setRadius(radiusInMeters);

      // adjust map bounds if circle bounds are bigger
      const bounds = circle.current.getBounds();
      const mapBounds = map.getBounds();
      if (
        bounds &&
        mapBounds &&
        (!mapBounds.contains(bounds.getNorthEast()) || !mapBounds.contains(bounds.getSouthWest()))
      ) {
        map.fitBounds(bounds, 16);
      }
    } else {
      circle.current?.setMap(null);
      circle.current = undefined;
    }
    skipCircleChange.current = false;
  }, [coordinates, handleCircleChange, map, radiusInMeters]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.map} ref={mapDivRef} />
    </div>
  );
};

export default memo(AreaFilterMap);
