// type of the field after value getter
enum FieldType {
  NUMBER,
  STRING,
  DATE,
  DATE_TIME,
  LIST,
  OBJECT,
  BOOLEAN,
  ID, // almost same as number or string, but not interchangeable in filters. I.e. filter value from one id field is not suitable for another field (even if it is id too).
}

export default FieldType;
