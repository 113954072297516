import { AnyEntityId, EntityType } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import Organization from "@mapmycustomers/shared/types/Organization";

export const getFilesEndpoint = (
  orgId: Organization["id"],
  id?: RawFile["id"],
  entityType?: EntityType,
  entityId?: AnyEntityId,
  download?: boolean,
  preview?: boolean
): string => {
  let endpoint = `/organizations/${orgId}`;

  // we only need to entity-scope url when we're gonna preview or download file
  // otherwise, org-scoping is enough if need the file itself
  if (entityType && entityId && (preview || download)) {
    endpoint += `/${entityType}/${entityId}`;
  }

  endpoint += "/files";
  if (id) {
    endpoint += `/${id}`;
  }

  if (download) {
    endpoint += `/download`;
  } else if (preview) {
    endpoint += `/download-preview`;
  }
  return endpoint;
};
