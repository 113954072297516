import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

import { ActivityTypePayload } from "../api/ApiService";

export const fetchActivityTypes = createAsyncAction(
  "@activity/fetchActivityTypes/request",
  "@activity/fetchActivityTypes/success",
  "@activity/fetchActivityTypes/failure"
)<void, ActivityType[], void>();

export const createActivityType = createAsyncAction(
  "@activity/createActivityType/request",
  "@activity/createActivityType/success",
  "@activity/createActivityType/failure"
)<{ activityType: ActivityTypePayload; callback: () => void }, ActivityType, void>();

export const updateActivityType = createAsyncAction(
  "@activity/updateActivityType/request",
  "@activity/updateActivityType/success",
  "@activity/updateActivityType/failure"
)<OnlyRequiredFields<ActivityType, "id">, ActivityType, ActivityType["id"]>();

export const bulkUpdateActivityType = createAsyncAction(
  "@activity/bulkUpdateActivityType/request",
  "@activity/bulkUpdateActivityType/success",
  "@activity/bulkUpdateActivityType/failure"
)<OnlyRequiredFields<ActivityType, "id">[], ActivityType[], void>();

export const deleteActivityType = createAsyncAction(
  "@activity/deleteActivityType/request",
  "@activity/deleteActivityType/success",
  "@activity/deleteActivityType/failure"
)<ActivityType["id"], ActivityType["id"], ActivityType["id"]>();

export const notifyActivityRelatedEntities = createAction(
  "@activity/notifyActivityRelatedEntities"
)<Activity>();

export type Actions = ActionType<
  | typeof bulkUpdateActivityType
  | typeof createActivityType
  | typeof deleteActivityType
  | typeof fetchActivityTypes
  | typeof notifyActivityRelatedEntities
  | typeof updateActivityType
>;
