import { useCallback } from "react";

import { FormInstance } from "antd/es/form";

import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import localSettings from "@app/config/LocalSettings";
import { ActivityPayload } from "@app/store/api/ApiService";
import HideAction from "@app/types/HideCallback";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";

import { createActivity } from "../store/actions";
import FormValues from "../types/FormValues";

import getActivityPayloadFromCreationForm from "./getActivityPayloadFromCreationForm";

const useCreateCallback = (
  activityTypes: ActivityType[],
  createActivityRequest: typeof createActivity.request,
  form: FormInstance<FormValues>,
  formLayout: FormLayout | undefined,
  onHide: HideAction<Activity>,
  analyticIssuer?: AnalyticsService
) =>
  useCallback(() => {
    if (!formLayout) {
      return;
    }
    const values = form.getFieldsValue(true);

    const activity: ActivityPayload = getActivityPayloadFromCreationForm(values, activityTypes);

    const customFieldsValues = values.customFields
      ? Object.values<CustomFieldValue>(values.customFields).filter(isDefined)
      : [];

    localSettings.setActivityCreationNotifyPreferences(values.reminders);

    createActivityRequest({
      activity,
      callback: onHide,
      customFieldsValues,
      layoutId: formLayout.id,
    });
    analyticIssuer?.feature(["Activity Created"], { activity });
  }, [activityTypes, analyticIssuer, createActivityRequest, form, formLayout, onHide]);

export default useCreateCallback;
