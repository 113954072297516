import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";

import dateFnsLocaleService from "@app/config/DateFnsLocaleService";

// a hack to override locale name for russian locale. It is "ru_RU" in
// antd world, but "ru" in date-fns
// (other locale are most probably affected too, e.g. de_DE or da_DK)
const localeMapping: { [key: string]: string } = {
  es_ES: "es",
  pt_BR: "pt",
  ru_RU: "ru",
};

const wrapMethod = <T>(fn: (locale: string, ...args: any[]) => T) => {
  return (locale: string, ...args: any[]): T => {
    const localeCode = (dateFnsLocaleService.locale.code ?? "en-US").replace("-", "");
    // Completely ignoring locale argument provided by antd.
    // Because it has fewer locales than date-fns supports, hence using global
    // date-fns' locale, we use everywhere else in the app to format dates and other
    // stuff.
    // Since both these locales are synced, this should be safe.
    return fn(
      Object.hasOwn(localeMapping, localeCode) ? localeMapping[localeCode] : localeCode,
      ...args
    );
  };
};

const antdPickerLocaleConfig = (
  Object.keys(dateFnsGenerateConfig.locale) as Array<keyof typeof dateFnsGenerateConfig.locale>
).reduce(
  (result, methodName) => ({
    ...result,
    // @ts-ignore
    [methodName]: wrapMethod(dateFnsGenerateConfig.locale[methodName]),
  }),
  {}
) as typeof dateFnsGenerateConfig.locale;

export default antdPickerLocaleConfig;
