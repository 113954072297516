import React, { FC, ReactNode } from "react";

import { useIntl } from "react-intl";

import TextWithInfo from "@app/component/typography/TextWithInfo";
import { PREVIEW_MODAL_LIMIT } from "@app/util/consts";

import styles from "./DealListModal.module.scss";

interface Props {
  dealsTotal: number;
  stageName?: string;
  subTitle?: ReactNode;
  title?: string;
}

export const DealListModalTitle: FC<Props> = ({ dealsTotal, stageName, subTitle, title }) => {
  const intl = useIntl();

  return (
    <div>
      <h2 className={styles.modalTitle}>
        {title ??
          intl.formatMessage(
            {
              id: "dealListModalForCharts.title.stageName",
              defaultMessage: "{hasStageName, select, true {{stageName}} other {All stages}}",
              description: "Title of a deal list modal",
            },
            { hasStageName: !!stageName, stageName }
          )}{" "}
        {dealsTotal > PREVIEW_MODAL_LIMIT ? (
          <span className={styles.subtitleLimitExplanation}>
            <TextWithInfo
              info={intl.formatMessage(
                {
                  id: "dealListModalForCharts.title.limitHint",
                  defaultMessage:
                    "Only showing {listLimit} deals in this stage. To see all deals, click “View all Deals” below.",
                  description: "Limit explaining tooltip at deals list modal",
                },
                { listLimit: PREVIEW_MODAL_LIMIT }
              )}
            >
              ({PREVIEW_MODAL_LIMIT}/{dealsTotal})
            </TextWithInfo>
          </span>
        ) : (
          dealsTotal
        )}
      </h2>
      <h6 className={styles.modalSubtitle}>{subTitle}</h6>
    </div>
  );
};
