import omit from "lodash-es/omit";
import { createReducer } from "typesafe-actions";

import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import { stageComparator } from "@app/util/comparator";

import {
  Actions,
  createFunnel,
  createStage,
  deleteFunnel,
  deleteStage,
  ensureStagesFetched,
  fetchFunnels,
  updateDeal,
  updateFunnel,
  updateStages,
} from "./actions";

export interface DealState {
  createFunnelLoading: boolean;
  createStageLoading: boolean;
  deleteFunnelLoading: boolean;
  deleteStageLoading: boolean;
  funnels: Funnel[];
  funnelsError: ApiError | undefined;
  funnelsLoading: boolean;
  funnelStages: Record<Funnel["id"], Stage[]>;
  updateDealLoading: boolean;
  updateFunnelLoading: boolean;
  updateStageLoading: boolean;
}

const initialState: DealState = {
  createFunnelLoading: false,
  createStageLoading: false,
  deleteFunnelLoading: false,
  deleteStageLoading: false,
  funnels: [],
  funnelsError: undefined,
  funnelsLoading: false,
  funnelStages: {},
  updateDealLoading: false,
  updateFunnelLoading: false,
  updateStageLoading: false,
};

const deal = createReducer<DealState, Actions>(initialState)
  .handleAction(fetchFunnels.request, (state) => ({
    ...state,
    funnelsError: undefined,
    funnelsLoading: true,
  }))
  .handleAction(fetchFunnels.success, (state, action) => ({
    ...state,
    funnels: action.payload,
    funnelsLoading: false,
    funnelStages: action.payload.reduce(
      (result, funnel) => ({
        ...result,
        [funnel.id]: funnel.stages
          // We're adding a funnel field back.
          // When stages are fetched via /organizations/${orgId}/funnels/${funnelId}/stages, such field
          // is returned. But when we take stages returned by /organizations/${orgId}/funnels endpoint,
          // this field is missing. Hence, we're adding it back because app relies on it.
          .map((stage) => ({ ...stage, funnel }))
          .sort(stageComparator),
      }),
      {}
    ),
  }))
  .handleAction(fetchFunnels.failure, (state, action) => ({
    ...state,
    funnelsError: action.payload,
    funnelsLoading: false,
  }))
  .handleAction(createFunnel.request, (state) => ({
    ...state,
    createFunnelLoading: true,
  }))
  .handleAction(createFunnel.success, (state, { payload: funnel }) => ({
    ...state,
    createFunnelLoading: false,
    funnels: [...state.funnels, funnel].sort(nameComparator),
  }))
  .handleAction(createFunnel.failure, (state) => ({
    ...state,
    createFunnelLoading: false,
  }))
  .handleAction(updateFunnel.request, (state) => ({
    ...state,
    updateFunnelLoading: true,
  }))
  .handleAction(updateFunnel.success, (state, { payload }) => ({
    ...state,
    funnels: state.funnels.map((funnel) => (payload.id === funnel.id ? payload : funnel)),
    updateFunnelLoading: false,
  }))
  .handleAction(updateFunnel.failure, (state) => ({
    ...state,
    updateFunnelLoading: false,
  }))
  .handleAction(deleteFunnel.request, (state) => ({ ...state, deleteFunnelLoading: true }))
  .handleAction(deleteFunnel.success, (state, { payload }) => ({
    ...state,
    deleteFunnelLoading: false,
    funnels: state.funnels.filter(({ id }) => id !== payload.id),
    funnelStages: omit(state.funnelStages, payload.id),
  }))
  .handleAction(deleteFunnel.failure, (state) => ({
    ...state,
    deleteFunnelLoading: false,
  }))
  .handleAction(ensureStagesFetched.success, (state, action) => ({
    ...state,
    funnelStages: {
      ...state.funnelStages,
      ...action.payload,
    },
  }))
  .handleAction(createStage.request, (state) => ({
    ...state,
    createStageLoading: true,
  }))
  .handleAction(createStage.success, (state, { payload: stage }) => ({
    ...state,
    createStageLoading: false,
    funnelStages: {
      ...state.funnelStages,
      [stage.funnel.id]: [...(state.funnelStages[stage.funnel.id] ?? []), stage].sort(
        stageComparator
      ),
    },
  }))
  .handleAction(createStage.failure, (state) => ({
    ...state,
    createStageLoading: false,
  }))
  .handleAction(updateStages.request, (state) => ({
    ...state,
    updateStageLoading: true,
  }))
  .handleAction(updateStages.success, (state, { payload }) => {
    const updatedStagesMap = new Map(payload.stages.map((stage) => [stage.id, stage]));
    return {
      ...state,
      funnelStages: {
        ...state.funnelStages,
        [payload.funnelId]: (state.funnelStages[payload.funnelId] ?? []).map(
          (stage) => updatedStagesMap.get(stage.id) ?? stage
        ),
      },
      updateStageLoading: false,
    };
  })
  .handleAction(updateStages.failure, (state) => ({
    ...state,
    updateStageLoading: false,
  }))
  .handleAction(deleteStage.request, (state) => ({ ...state, deleteStageLoading: false }))
  .handleAction(deleteStage.success, (state, { payload: stage }) => ({
    ...state,
    deleteStageLoading: false,
    funnelStages: {
      ...state.funnelStages,
      [stage.funnel.id]: (state.funnelStages[stage.funnel.id] ?? []).filter(
        ({ id }) => id !== stage.id
      ),
    },
  }))
  .handleAction(deleteStage.failure, (state) => ({
    ...state,
    deleteStageLoading: false,
  }))
  .handleAction(updateDeal.request, (state) => ({ ...state, updateDealLoading: true }))
  .handleAction([updateDeal.success, updateDeal.failure], (state) => ({
    ...state,
    updateDealLoading: false,
  }));

export * from "./selectors";
export type DealActions = Actions;

export default deal;
