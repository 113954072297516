import { get } from "lodash-es";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import { csvDateTimeFormatter } from "@app/util/formatters";
import { parseApiDate } from "@app/util/parsers";

import Field, { FieldProperties } from "./Field";
import { dateTimeFormatter } from "./fieldUtil";

const dateParser = (entity: unknown, field: IField) =>
  parseApiDate(get(entity, field.name) as string);

class DateTimeField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      csvExportProperties: { valueFormatter: csvDateTimeFormatter },
      features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE],
      type: FieldType.DATE_TIME,
      valueFormatter: dateTimeFormatter,
      valueGetter: dateParser,
      ...data,
    });
  }
}

export default DateTimeField;
