import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons/faPenAlt";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "antd/es/skeleton";
import { differenceInHours } from "date-fns/esm";
import { useIntl } from "react-intl";

import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";
import { Modal } from "@mapmycustomers/ui";

import ActivityNotePreview from "@app/component/preview/components/ActivityNotePreview";
import { showEntityView } from "@app/store/entityView/actions";
import EntityViewPayload from "@app/store/entityView/EntityViewPayload";
import { addUserRecent } from "@app/store/globalSearch/actions";
import { getMe } from "@app/store/iam";
import { getUsersOfEntireOrganization } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import Iam from "@app/types/Iam";
import { ACTIVITY_TIME_DIFFERENCE_ALERT } from "@app/util/activity/const";
import getActivityStatus from "@app/util/activity/getActivityStatus";
import showActivityTimeUpdateAlert from "@app/util/activity/showActivityTimeUpdateAlert";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";
import { activityLayoutModel } from "@app/util/layout/impl";
import loggingService from "@app/util/logging";
import { parseApiDate } from "@app/util/parsers";

import AccessReasonExposer from "../../AccessReasonExposer";
import EditActivityFocusableField from "../type/EditActivityFocusableField";

import styles from "./ActivityAnnotation.module.scss";
import AttendeesSection from "./AttendeesSection";
import EmailSection from "./EmailSection";
import FilesSection from "./FilesSection";
import Footer from "./Footer";
import NoAccess from "./NoAccess";
import RelationShipsSection from "./RelationshipsSection";
import Section from "./Section";
import { getAnnotationData, hasNoAccess, isAnnotationDataLoading } from "./store";
import { loadAnnotationData, postponeActivity, toggleComplete } from "./store/actions";
import AnnotationData from "./store/AnnotationData";
import TopSection from "./TopSection";

const MODAL_WIDTH = 450;
const X_GAP = 20;
const FULL_HEIGHT_ADDON = 50; // we measure height of modal content. Full modal size is a bit bigger, by this number of pixels (or close to that).

interface Props {
  activityId: Activity["id"];
  addUserRecent: typeof addUserRecent;
  analyticIssuer?: AnalyticsService;
  annotationData?: AnnotationData;
  eventElement?: HTMLElement;
  hasNoAccess: boolean;
  initialize: typeof loadAnnotationData.request;
  loading?: boolean;
  me: Iam;
  onChange?: (activity: Activity) => void;
  onEdit: (payload: EntityViewPayload) => void;
  onHide: () => void;
  onPostponeActivity: typeof postponeActivity.request;
  onToggleComplete: typeof toggleComplete.request;
  users: User[];
}

export const ActivityAnnotation: React.FC<Props> = ({
  activityId,
  addUserRecent,
  analyticIssuer,
  annotationData,
  eventElement,
  hasNoAccess,
  initialize,
  loading,
  me,
  onChange,
  onEdit,
  onHide,
  onPostponeActivity,
  onToggleComplete,
  users,
}) => {
  const intl = useIntl();

  useEffect(() => {
    initialize(activityId);
  }, [initialize, activityId]);

  const hasMissingRequiredFields = useMemo(() => {
    return annotationData?.activity
      ? activityLayoutModel.hasMissingRequiredFieldsFor(annotationData.activity)
      : false;
  }, [annotationData?.activity]);

  const status = annotationData?.activity ? getActivityStatus(annotationData.activity) : undefined;

  // position modal so that it's on the left of the event block (if possible), otherwise position it to the right
  // also, vertically position so that modal and event centers are aligned. If not possible (is behind to screen
  // edges), position it to be slightly lower than screen top or slightly higher than screen bottom.
  const [contentElement, setContentElement] = useState<HTMLElement | null>(null);
  const [{ left, top }, setPosition] = useState<{ left: number; top: number }>(() => {
    if (eventElement) {
      const rect = eventElement.getBoundingClientRect();
      let left =
        rect.left - MODAL_WIDTH - X_GAP < 0 ? rect.right + X_GAP : rect.left - MODAL_WIDTH - X_GAP;
      if (left + MODAL_WIDTH > window.innerWidth) {
        left = window.innerWidth - MODAL_WIDTH - X_GAP;
      }
      if (left < 0) {
        left = 0;
      }
      return { left, top: rect.top };
    }
    return { left: 0, top: 0 };
  });

  useEffect(() => {
    if (!eventElement) {
      return;
    }
    try {
      const observer = new ResizeObserver(() => {
        const eventRect = eventElement.getBoundingClientRect();
        const contentRect = contentElement!.getBoundingClientRect();
        let top = Math.max(
          X_GAP,
          eventRect.top + eventRect.height / 2 - (contentRect.height + FULL_HEIGHT_ADDON) / 2
        );
        if (top + contentRect.height + FULL_HEIGHT_ADDON > window.innerHeight) {
          top = window.innerHeight - contentRect.height - FULL_HEIGHT_ADDON - X_GAP;
        }
        setPosition((position) => ({ ...position, top }));
      });

      if (contentElement) {
        observer.observe(contentElement);
      }

      return () => {
        observer.disconnect();
      };
    } catch (e) {
      loggingService.error("Error in ActivityAnnotation", e);
    }
  }, [contentElement, eventElement]);

  const handleEdit = useCallback(
    (focusedFieldName?: EditActivityFocusableField) => {
      onEdit({
        edit: true,
        entityId: activityId,
        entityType: EntityType.ACTIVITY,
        focusedFieldName,
      });
      analyticIssuer?.clicked(["Edit"]);
    },
    [activityId, analyticIssuer, onEdit]
  );

  const handleToggleComplete = useCallback(() => {
    if (annotationData?.activity) {
      const { activity } = annotationData;

      // If trying to mark activity as done and have some required fields
      // missing - then display dialog to fill-in missing fields
      if (!activity.completed && hasMissingRequiredFields) {
        onEdit({
          edit: true,
          editValues: { completed: true },
          entityId: activity.id,
          entityType: activity.entity,
          requiredFieldsOnly: true,
        });
      } else {
        const startAt = activity.startAt ? parseApiDate(activity.startAt) : undefined;
        const endAt = activity.endAt ? parseApiDate(activity.endAt) : undefined;

        if (
          !activity.completed &&
          startAt &&
          Math.abs(differenceInHours(Date.now(), endAt ?? startAt)) >=
            ACTIVITY_TIME_DIFFERENCE_ALERT
        ) {
          showActivityTimeUpdateAlert(intl, activity, (activity: Activity) => {
            onToggleComplete({ activity, callback: onChange });
          });
        } else {
          onToggleComplete({ activity, callback: onChange });
        }
      }
    }
  }, [annotationData, hasMissingRequiredFields, intl, onChange, onEdit, onToggleComplete]);

  const handlePostpone = useCallback(
    (activity: Activity) => {
      onPostponeActivity({ activity, callback: onChange });
    },
    [onChange, onPostponeActivity]
  );

  useEffect(() => {
    addUserRecent({ entity: { id: activityId, type: EntityType.ACTIVITY } });
  }, [addUserRecent, activityId]);

  return (
    <Modal
      centered={!eventElement}
      className={styles.modal}
      footer={null}
      mask={false}
      onCancel={onHide}
      style={eventElement ? { left, margin: "unset", top } : {}}
      title={null}
      type={status === ActivityStatus.OVERDUE || hasNoAccess ? "error" : "default"}
      visible
      width={MODAL_WIDTH}
      wrapClassName={styles.modalWrapper}
    >
      {loading || !annotationData?.activity ? (
        hasNoAccess ? (
          // must be two separate divs to make sure setContentElement is called again when the state changes
          <div ref={setContentElement}>
            <NoAccess />
          </div>
        ) : (
          <div ref={setContentElement}>
            <Skeleton active />
          </div>
        )
      ) : (
        <div ref={setContentElement}>
          <TopSection
            activity={annotationData.activity}
            addressRecord={annotationData.addressRecord}
            className={styles.topSection}
          />
          <div className={styles.details}>
            <EmailSection activity={annotationData.activity} />
            <RelationShipsSection activity={annotationData.activity} />
            {annotationData.activity.note && (
              <Section
                icon={faPenAlt}
                title={intl.formatMessage({
                  id: "component.activityAnnotation.notesSection.title",
                  defaultMessage: "Notes",
                  description: "Title for activity notes section",
                })}
              >
                <ActivityNotePreview
                  activity={annotationData.activity}
                  className={styles.paragraph}
                  currentUserId={me.id}
                  note={annotationData?.activity.note ?? ""}
                  users={users}
                />
              </Section>
            )}
            <FilesSection activity={annotationData.activity} files={annotationData?.files} />
            {annotationData.activity.crmLink && (
              <Section
                icon={faInfoCircle}
                title={intl.formatMessage({
                  id: "component.activityAnnotation.detailsSection.title",
                  defaultMessage: "Details",
                  description: "Title for activity details section",
                })}
              >
                <a href={annotationData.activity.crmLink} rel="noopener noreferrer" target="_blank">
                  {intl.formatMessage({
                    id: "activity.field.crmLink",
                    defaultMessage: "CRM Link",
                    description: "CRM link field of activity",
                  })}
                  &nbsp;
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </a>
              </Section>
            )}
            <AttendeesSection activity={annotationData.activity} />
          </div>
          <AccessReasonExposer entity={annotationData.activity} />
          {annotationData.activity.hasAccess && (
            <Footer
              activity={annotationData.activity}
              analyticIssuer={analyticIssuer}
              onEdit={handleEdit}
              onPostponeActivity={handlePostpone}
              onToggleComplete={handleToggleComplete}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export const mapStateToPropsActivityAnnotation = (state: RootState) => ({
  annotationData: getAnnotationData(state),
  hasNoAccess: hasNoAccess(state),
  loading: isAnnotationDataLoading(state),
  me: getMe(state)!,
  users: getUsersOfEntireOrganization(state),
});

export const mapDispatchToPropsActivityAnnotation = {
  addUserRecent,
  initialize: loadAnnotationData.request,
  onEdit: showEntityView,
  onPostponeActivity: postponeActivity.request,
  onToggleComplete: toggleComplete.request,
};

export default connect(
  mapStateToPropsActivityAnnotation,
  mapDispatchToPropsActivityAnnotation
)(ActivityAnnotation);
