import React from "react";

import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import Button from "antd/es/button";
import cn from "classnames";

interface Props {
  className?: string;
  onCancel?: React.MouseEventHandler<HTMLElement>;
  onSave?: React.MouseEventHandler<HTMLElement>;
  size?: "large" | "small";
}

const block = bem("mmc-inline-buttons");

const InlineButtons: React.FC<Props> = ({ className, onCancel, onSave, size = "small" }) => {
  return (
    <div className={cn(block({ [size]: true }), className)}>
      <Button
        className={cn(block("button"), "mmc-js-skip-save-on-blur")}
        icon={<FontAwesomeIcon icon={faTimes} />}
        onClick={onCancel}
        type="default"
      />
      <Button
        className={block("button")}
        icon={<FontAwesomeIcon icon={faCheck} />}
        onClick={onSave}
        type="primary"
      />
    </div>
  );
};

export default InlineButtons;
