import Color from "@mapmycustomers/shared/enum/Color";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";
import { Person } from "@mapmycustomers/shared/types/entity";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import User from "@mapmycustomers/shared/types/User";

const getInitialValues = (
  currentUserId: User["id"],
  initialValues?: Partial<Person>,
  fixedCompany?: CompanyRef
) => {
  const result = initialValues
    ? {
        color: Color.BLACK,
        firstName:
          initialValues?.firstName ??
          (initialValues.name ?? "").substring(0, (initialValues.name ?? "").indexOf(" ")),
        lastName:
          initialValues?.lastName ??
          (initialValues.name ?? "").substring((initialValues.name ?? "").indexOf(" ")),
        phone: initialValues.phone,
        user: currentUserId,
      }
    : { user: currentUserId };

  const fixedCompanyList = fixedCompany ? [fixedCompany] : [];
  return {
    ...(!initialValues
      ? result
      : initialValues.address || initialValues.city || !initialValues.geoPoint
      ? {
          ...result,
          addressDetails: {
            address: initialValues.address,
            city: initialValues.city,
            countryCode: initialValues.countryCode,
            geoManagementState: GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
            postalCode: initialValues.postalCode,
            region: initialValues.region,
            regionCode: initialValues.regionCode,
          },
        }
      : {
          ...result,
          latitude: initialValues.geoPoint.coordinates[1] ?? "",
          longitude: initialValues.geoPoint.coordinates[0] ?? "",
        }),
    accounts: initialValues?.accounts
      ? [...initialValues.accounts, ...fixedCompanyList]
      : [...fixedCompanyList],
  };
};

export default getInitialValues;
