import React from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";

import CadenceModal from "@app/component/activity/CadenceModal/CadenceModal";
import {
  getCadenceModalEntities,
  getCadenceModalNotification,
  isCadenceModalShowing,
  isNotificationCadenceDataLoading,
} from "@app/store/notification";
import { hideCadenceModal } from "@app/store/notification/actions";
import { RootState } from "@app/store/rootReducer";
import MmcNotification from "@app/types/MmcNotification";
import { parseApiDate } from "@app/util/parsers";

interface Props {
  entities: OutOfCadenceEntity[];
  hideCadenceModal: typeof hideCadenceModal;
  loading: boolean;
  notification?: MmcNotification;
  visible: boolean;
}

const NotificationCadenceModal: React.FC<Props> = ({
  entities,
  hideCadenceModal,
  loading,
  notification,
  visible,
}) => {
  const intl = useIntl();

  return visible ? (
    <CadenceModal
      cadencedAt={(notification ? parseApiDate(notification.updatedAt) : new Date()).toISOString()}
      entities={entities}
      loading={loading}
      onHide={hideCadenceModal}
      title={intl.formatMessage({
        id: "notifications.cadenceModal.title",
        defaultMessage: "Records due to be seen",
        description: "Navbar Notifications - cadence modal - title",
      })}
    />
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  entities: getCadenceModalEntities(state),
  loading: isNotificationCadenceDataLoading(state),
  notification: getCadenceModalNotification(state),
  visible: isCadenceModalShowing(state),
});

const mapDispatchToProps = {
  hideCadenceModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCadenceModal);
