import { defineMessages, IntlShape } from "react-intl";

import ImportStatus from "@mapmycustomers/shared/enum/imports/ImportStatus";

const messages = defineMessages({
  [ImportStatus.COMPLETED]: {
    id: "importStatus.completed",
    defaultMessage: "Completed",
    description: "COMPLETED import",
  },
  [ImportStatus.ERRORED]: {
    id: "importStatus.errored",
    defaultMessage: "Errored",
    description: "ERRORED import",
  },
  [ImportStatus.QUEUED]: {
    id: "importStatus.queued",
    defaultMessage: "Queued",
    description: "QUEUED import",
  },
  [ImportStatus.RUNNING]: {
    id: "importStatus.running",
    defaultMessage: "Running",
    description: "RUNNING import",
  },
});

const getImportStatusDisplayName = (intl: IntlShape, status: ImportStatus): string =>
  intl.formatMessage(messages[status]);

export default getImportStatusDisplayName;
