import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivitySuggestion, {
  ActivityOrSuggestion,
} from "@mapmycustomers/shared/types/entity/ActivitySuggestion";
import { isDefined } from "@mapmycustomers/shared/util/assert";

export const isSuggestion = (value: ActivityOrSuggestion): value is ActivitySuggestion =>
  !(value as Activity).id && isDefined((value as ActivitySuggestion).property);

export const isActivity = (value: ActivityOrSuggestion): value is Activity => !isSuggestion(value);
