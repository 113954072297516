import React, { useCallback } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import Path from "@app/enum/Path";
import { resetErrors } from "@app/store/auth/actions";

import AuthLayout from "../AuthLayout";

import styles from "./UserInvalidPage.module.scss";

interface Props {
  onResetErrors: typeof resetErrors;
}

export const UserInvalidPage: React.FC<Props> = ({ onResetErrors }) => {
  const intl = useIntl();

  const handleClick = useCallback(() => {
    onResetErrors();
  }, [onResetErrors]);

  return (
    <AuthLayout>
      <div className={styles.container}>
        <p className={styles.title}>
          {intl.formatMessage({
            id: "auth.userInvalid.title",
            defaultMessage: "User no longer exists",
            description: "Title of user invalid page",
          })}
        </p>
        <p className={styles.description}>
          {intl.formatMessage({
            id: "auth.userInvalid.description",
            defaultMessage: "The user associated with the password reset no longer exists.",
            description: "Title of user invalid page",
          })}
        </p>
        <Link to={Path.LOGIN}>
          <Button key="return" onClick={handleClick} type="primary">
            {intl.formatMessage({
              id: "auth.userInvalid.button.title",
              defaultMessage: "Log in with another account",
              description: "Title of login button",
            })}
          </Button>
        </Link>
      </div>
    </AuthLayout>
  );
};

const mapDispatchToProps = {
  onResetErrors: resetErrors,
};

export default connect(null, mapDispatchToProps)(UserInvalidPage);
