import React, { ComponentType, useEffect } from "react";
import { connect } from "react-redux";

import { Redirect, Route, RouteProps } from "react-router-dom";

import { LoadingSpinner } from "@mapmycustomers/ui";

import { RootState } from "@app/store/rootReducer";

import Path from "../../enum/Path";
import { isInitializing } from "../../store/app";
import { isSignedIn } from "../../store/auth";
import { loggedIn, login } from "../../store/auth/actions";
import authService from "../../store/auth/AuthService";
import { getError, getMe, isLoading } from "../../store/iam";
import Iam from "../../types/Iam";
import hasTrialExpired from "../../util/hasTrialExpired";
import useToggleBooleanAfterTimeout from "../../util/hook/useToggleBooleanAfterTimeout";
import Layout from "../Layout";

export interface LayoutProps {
  children: React.ReactNode;
}

type Props = RouteProps & {
  initializing: boolean;
  isProfileLoading: boolean;
  isProfileLoadingFailed: boolean;
  isSignedIn: boolean | undefined;
  layout?: boolean;
  layoutComponent?: ComponentType<LayoutProps>;
  loggedIn: typeof loggedIn;
  login: typeof login;
  me: Iam | undefined;
};

export const PrivateRoute: React.FC<Props> = (props) => {
  const {
    children,
    component,
    initializing,
    isProfileLoading,
    isProfileLoadingFailed,
    isSignedIn,
    layout = true,
    layoutComponent: LayoutComponent = Layout,
    loggedIn,
    login,
    me,
    render,
    ...rest
  } = props;

  const hasToken = authService.hasToken();

  useEffect(() => {
    if (!hasToken) {
      // no token at all, go sign in
      login();
    } else if (hasToken && isSignedIn === false) {
      // has token in AuthService, but not in the store, perform loggedIn procedure
      loggedIn({ keepLocation: true, token: authService.getToken() });
    } else if (!isProfileLoading && !isProfileLoadingFailed && isSignedIn && me === undefined) {
      // logged in, but has no profile info yet, perform loggedIn procedure
      loggedIn({ keepLocation: true });
    }
  }, [hasToken, isProfileLoading, isProfileLoadingFailed, isSignedIn, loggedIn, login, me]);

  const showLoader = useToggleBooleanAfterTimeout(2000); // show loader only if loading takes more than 2 seconds
  if (isSignedIn === undefined || me === undefined || initializing) {
    return showLoader ? <LoadingSpinner global /> : null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!hasToken) {
          return null; // will be redirected to the login page by the useEffect above
        }

        if (
          hasTrialExpired(me?.organization) &&
          !props.location.pathname.startsWith(Path.BILLING)
        ) {
          return <Redirect to={Path.BILLING} />;
        }

        let result = null;
        if (component) {
          // TODO: investigate a better types fix (appeared after migrating to CRA 4 & React 17)
          // @ts-ignore
          result = React.createElement(component, props);
        }

        if (render) {
          result = render(props);
        }

        if (children && React.Children.count(children)) {
          result = children;
        }

        return layout ? (
          <LayoutComponent>
            <React.Suspense fallback={<LoadingSpinner global />}>{result}</React.Suspense>
          </LayoutComponent>
        ) : (
          <React.Suspense fallback={<LoadingSpinner global />}>{result}</React.Suspense>
        );
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  initializing: isInitializing(state),
  isProfileLoading: isLoading(state),
  isProfileLoadingFailed: !!getError(state),
  isSignedIn: isSignedIn(state),
  me: getMe(state),
});

const mapDispatchToProps = {
  loggedIn,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
