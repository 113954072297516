import pick from "lodash-es/pick";

import Visibility from "../Visibility";

type ActivityVisibility =
  | Visibility.PRIVATE
  | Visibility.SHARED_WITH_ORGANIZATION
  | Visibility.SHARED_WITH_TEAM;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ActivityVisibility = pick(Visibility, [
  "SHARED_WITH_ORGANIZATION",
  "SHARED_WITH_TEAM",
  "PRIVATE",
]);

export default ActivityVisibility;
