import {
  addDays,
  endOfToday,
  endOfWeek,
  getISODay,
  isAfter,
  isBefore,
  isMonday,
  isSameWeek as sameWeek,
  startOfDay,
  startOfToday,
  startOfWeek,
} from "date-fns/esm";

import dateFnsLocaleService from "@app/config/DateFnsLocaleService";

export const injectLocale = <T extends {}>(options: T): T & { locale: Locale } => ({
  locale: dateFnsLocaleService.locale,
  ...options,
});

// returns IANA name of current timezone, e.g. Europe/Moscow
export const getLocalTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getLocalTimeZoneDisplayName = (locale: string) => {
  const today = new Date();
  const short = today.toLocaleDateString(locale);
  const full = today.toLocaleDateString(locale, { timeZoneName: "long" });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
};

export const getLocalTimeZoneFormattedOffset = () => {
  const offsetInMinutes = new Date().getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60)
    .toFixed(0)
    .padStart(2, "0");
  const offsetMinutes = (Math.abs(offsetInMinutes) % 60).toFixed(0).padStart(2, "0");
  return `${offsetInMinutes > 0 ? "-" : "+"}${offsetHours}:${offsetMinutes}`;
};

export const getOffset = (timeZone: string, referenceDate = new Date()) => {
  const utcDate = new Date(referenceDate.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(referenceDate.toLocaleString("en-US", { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const getUpcomingMonday = () => {
  const date = startOfDay(new Date());
  if (isMonday(date)) {
    return date;
  }
  const currentDayOfWeek = getISODay(date); // 1 = Monday, .., 7 = Sunday
  return addDays(date, 8 - currentDayOfWeek);
};

// same as startOfWeek, but injects locale and thus make function be aware of the first day of week (sunday/monday)
export const getWeekStart: typeof startOfWeek = (date, options) => {
  return startOfWeek(date, injectLocale(options ?? {}));
};

export const doesWeekStartFromMonday = () => getISODay(getWeekStart(Date.now())) === 1;

// same as endOfWeek, but injects locale and thus make function be aware of the last day of week (sunday/saturday)
export const getWeekEnd: typeof endOfWeek = (date, options) => {
  return endOfWeek(date, injectLocale(options ?? {}));
};

// same as isSameWeek, but injects locale and thus make function be aware of the last day of week (sunday/saturday)
export const isSameWeek: typeof sameWeek = (dateLeft, dateRight, options) => {
  return sameWeek(dateLeft, dateRight, injectLocale(options ?? {}));
};

export const roundISODateUpToMinutes = (isoDate: null | string | undefined) =>
  isoDate && isoDate.length > 10 ? `${isoDate.substring(0, 16)}:00Z` : isoDate;

export const roundISODateUpToHours = (isoDate: null | string | undefined) =>
  isoDate && isoDate.length > 10 ? `${isoDate.substring(0, 13)}:00:00Z` : isoDate;

export const isAfterStartOfToday = (date: Date) => isAfter(date, startOfToday());

export const isBeforeEndOfToday = (date: Date) => isBefore(date, endOfToday());
