import root from "window-or-global";

import Environment from "@app/enum/Environment";

import type { Config } from "./Config";

export const CONFIG = process.env.REACT_APP_CONFIG_VARIABLE;

// TODO: could probably use Proxy to not write every method manually. Solution must be typed properly.
class ConfigService {
  getEnv = (): Config => {
    // returning from root for unit testing
    // @ts-ignore
    return root[CONFIG];
  };

  getBaseApiUrl = (): string => this.getEnv().baseApiUrl;
  getBaseAppUrl = (): string => this.getEnv().baseAppUrl;
  getBaseBoundariesUrl = (): string => this.getEnv().baseBoundariesUrl;
  getBaseOldAppUrl = (): string => this.getEnv().baseOldAppUrl;
  getBaseReadonlyApiUrl = (): string => this.getEnv().baseReadonlyApiUrl;
  getBaseSandboxAppUrl = (): string => this.getEnv().baseSandboxAppUrl;
  getBoundaryServiceUrl = (): string => this.getEnv().boundaryServiceUrl;
  getCurrentEnvironment = (): Environment =>
    (process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.DEVELOPMENT;
  getDefaultApiTimeout = (): number => this.getEnv().defaultApiTimeout;
  getDynamicsAuthUrl = (): string => this.getEnv().dynamicsAuthUrl;
  getFrigadeApiKey = (): string => this.getEnv().frigadeApiKey;
  getGoogleClient = (): string => this.getEnv().googleClient;
  getGoogleKey = (): string => this.getEnv().googleKey;
  getHubspotAuthUrl = (): string => this.getEnv().hubspotAuthUrl;
  getLogoutUrl = (): string => this.getEnv().logoutUrl;
  getNylasId = (): string => this.getEnv().nylasId;
  getNylasMailId = (): string => this.getEnv().nylasMailId;
  getRecaptchaSiteKey = (): string => this.getEnv().recaptchaSiteKey;
  getSalesforceAuthUrl = (): string => this.getEnv().salesforceAuthUrl;
  getSalesforceSandBoxAuthUrl = (): string => this.getEnv().salesforceSandboxAuthUrl;
  getSentryDsn = (): string => this.getEnv().sentryDsn;
  getStripeCheckoutKey = (): string => this.getEnv().stripeCheckoutKey;
  getVersion = (): string => this.getEnv().version;
  getZohoAuthUrl = (): string => this.getEnv().zohoAuthUrl;
  getReadMeJWTSecret = (): string => this.getEnv().readMeJWTSecret;
}

const configService = new ConfigService();
export default configService;
