import React, { Fragment, useMemo } from "react";

import { IntlShape } from "react-intl";

import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";

import buildingIllustration from "@app/assets/associations/building-illustration.svg";
import companyVector from "@app/assets/associations/companyVector.svg";
import dealIllustration from "@app/assets/associations/deal-illustration.svg";
import dealVector from "@app/assets/associations/dealVector.svg";
import personIllustration from "@app/assets/associations/person-illustration.svg";
import personVector from "@app/assets/associations/personVector.svg";

import styles from "../PostCreationModal.module.scss";

import PostCreationAssociationTypes, {
  associationTypes,
  getCardsParams,
} from "./PostCreationAssociationTypes";

const usePostCreationModalState = (
  intl: IntlShape,
  entityType: EntityType.COMPANY | EntityType.DEAL | EntityType.PERSON,
  prefilledValues: Company | Deal | Person,
  handleVisibility: (type: PostCreationAssociationTypes) => void
) => {
  const cards = associationTypes.map((type) => {
    const { cardToShow, iconToShow, messageToShow } = getCardsParams(
      intl,
      type,
      entityType,
      prefilledValues
    );
    return (
      <Fragment key={type}>
        {cardToShow && (
          <div
            className={styles.card}
            onClick={() => {
              handleVisibility(type);
            }}
          >
            <div>{messageToShow}</div>
            <img alt="No Data" src={iconToShow} />
          </div>
        )}
      </Fragment>
    );
  });

  return useMemo(() => {
    const imageSource =
      entityType === EntityType.COMPANY
        ? companyVector
        : entityType === EntityType.PERSON
        ? personVector
        : dealVector;
    const imageIllustration =
      entityType === EntityType.COMPANY
        ? buildingIllustration
        : entityType === EntityType.PERSON
        ? personIllustration
        : dealIllustration;

    return { cards, imageIllustration, imageSource };
  }, [entityType, cards]);
};

export default usePostCreationModalState;
