import React, { useMemo } from "react";
import { connect } from "react-redux";

import { SizeType } from "antd/es/config-provider/SizeContext";
import Tag from "antd/es/tag";
import cn from "classnames";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import User from "@mapmycustomers/shared/types/User";

import { getUsers } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import {
  getUserColorClassNameGetter,
  UserColorClassNameGetter,
} from "@app/store/userColor/selectors";

import styles from "./UserTag.module.scss";

interface Props extends CustomTagProps {
  getUserColorClassName: UserColorClassNameGetter;
  size?: SizeType;
  user?: User;
}

const UserTag = ({ closable, getUserColorClassName, label, onClose, size, user }: Props) => {
  const userSelectedClass = useMemo(() => {
    const userColor = user ? getUserColorClassName(user) : undefined;
    return userColor ? styles[userColor] : "";
  }, [getUserColorClassName, user]);

  return (
    <Tag
      className={cn(styles.container, userSelectedClass, "ant-tag-custom-color", {
        [styles.largeField]: size === "large",
      })}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

const mapStateToProps = (state: RootState, { value }: CustomTagProps) => ({
  getUserColorClassName: getUserColorClassNameGetter(state),
  user: getUsers(state).find(({ id }) => id === value),
});

const ConnectedUserTag = connect(mapStateToProps)(UserTag);

export const getUserTag = (props: CustomTagProps) => {
  return <ConnectedUserTag size="large" {...props} />;
};
