import React, { useCallback, useMemo, useState } from "react";

import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import Button from "antd/es/button";
import cn from "classnames";

import User from "@mapmycustomers/shared/types/User";
import { userDisplayNameComparator } from "@mapmycustomers/shared/util/comparator";

import UserItem from "../common/UserItem";
import { useConfigProvider } from "../ConfigProvider";
import TextField from "../input/TextField";

const block = bem("mmc-user-picker");

export const messages = {
  "ui.userPicker.deselectAll": "Deselect All",
  "ui.userPicker.search.placeholder": "Search users",
  "ui.userPicker.selectAll": "Select All",
} as const;

export interface UserPickerProps {
  className?: string;
  onChange?: (selectedUserIds: User["id"][]) => void;
  showAvatar?: boolean;
  users: User[];
  value: User["id"][];
}

export const UserPicker: React.FC<UserPickerProps> = ({
  className,
  onChange,
  showAvatar,
  users,
  value,
}) => {
  const configProvider = useConfigProvider();
  const [query, setQuery] = useState("");

  const filteredUsers = useMemo(
    () =>
      users
        .filter(
          (user) =>
            !query || (user.fullName ?? user.username).toLowerCase().includes(query.toLowerCase())
        )
        .sort(userDisplayNameComparator),
    [query, users]
  );

  const handleToggle = useCallback(
    (userId: User["id"]) => {
      if (value.includes(userId)) {
        onChange?.(value.filter((id) => id !== userId));
      } else {
        onChange?.([...value, userId]);
      }
    },
    [onChange, value]
  );

  const handleSelectAll = useCallback(
    () => onChange?.(users.map(({ id }) => id)),
    [onChange, users]
  );
  const handleDeselectAll = useCallback(() => onChange?.([]), [onChange]);

  return (
    <div className={cn(block(), className)}>
      <TextField
        className={block("search-field")}
        onChange={setQuery}
        placeholder={configProvider.formatMessage("ui.userPicker.search.placeholder")}
        size="middle"
        suffix={<FontAwesomeIcon className={block("search-icon")} icon={faSearch} />}
        value={query}
      />
      <div className={block("user-list")}>
        {filteredUsers.map((user) => {
          return (
            <UserItem
              key={user.id}
              onClick={handleToggle}
              selected={value.includes(user.id)}
              showAvatar={showAvatar}
              user={user}
            />
          );
        })}
      </div>
      <div className={block("buttons")}>
        <Button className={block("deselect-button")} onClick={handleDeselectAll} size="small">
          {configProvider.formatMessage("ui.userPicker.deselectAll")}
        </Button>
        <Button
          className={block("select-button")}
          onClick={handleSelectAll}
          size="small"
          type="text"
        >
          {configProvider.formatMessage("ui.userPicker.selectAll")}
        </Button>
      </div>
    </div>
  );
};

export default UserPicker;
