import { createReducer } from "typesafe-actions";

import {
  fetchLocations,
  highlightLocationGroupId,
  initSideBar,
  initUserLocations,
  selectUser,
  setDisabledUserIds,
  setHiddenUserIds,
  setUserLocationsSettings,
  UserLocationActions,
} from "./actions";
import UserLocationsState from "./UserLocationsState";

export const userLocationInitialState: UserLocationsState = {
  disabledUserIds: [],
  hiddenUserIds: [],
  initializing: false,
  legendExpanded: false,
  loading: false,
  locationGroups: [],
  visible: false,
};

const userLocationsLayer = createReducer<UserLocationsState, UserLocationActions>(
  userLocationInitialState
)
  .handleAction(selectUser, (state, { payload }) => ({
    ...state,
    locationGroups: [],
    selectedUser: payload,
  }))
  .handleAction(fetchLocations.request, (state) => ({
    ...state,
    firstLocationId: undefined,
    loading: true,
    locationGroups: [],
  }))
  .handleAction(
    fetchLocations.success,
    (state, { payload: { firstLocationId, locationGroups } }) => ({
      ...state,
      firstLocationId,
      loading: false,
      locationGroups,
    })
  )
  .handleAction(fetchLocations.failure, (state) => ({
    ...state,
    firstLocationId: undefined,
    loading: false,
    locationGroups: [],
  }))
  .handleAction(initSideBar.request, (state) => ({
    ...state,
    initializing: true,
    lastLocationWithoutPeriod: undefined,
  }))
  .handleAction(initSideBar.success, (state, { payload: { lastLocationWithoutPeriod } }) => ({
    ...state,
    initializing: false,
    lastLocationWithoutPeriod,
  }))
  .handleAction(initSideBar.failure, (state) => ({
    ...state,
    initializing: false,
    lastLocationWithoutPeriod: undefined,
  }))
  .handleAction(initUserLocations, (state, { payload: { disabledUserIds, hiddenUserIds } }) => ({
    ...state,
    disabledUserIds,
    hiddenUserIds,
  }))
  .handleAction(highlightLocationGroupId, (state, { payload }) => ({
    ...state,
    highlightedLocationGroupId: payload,
  }))
  .handleAction(setDisabledUserIds, (state, { payload }) => ({
    ...state,
    disabledUserIds: payload,
  }))
  .handleAction(setHiddenUserIds, (state, { payload }) => ({
    ...state,
    hiddenUserIds: payload,
  }))
  .handleAction(setUserLocationsSettings, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

export default userLocationsLayer;
