import { BaseEditor, Editor, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import deserializeFromHTML from "./deserializeFromHTML";

const resetEditorValue = (editor: ReactEditor & BaseEditor & HistoryEditor, value: string) => {
  const point = { offset: 0, path: [0, 0] };
  editor.selection = { anchor: point, focus: point };
  editor.history = { redos: [], undos: [] };
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });
  Transforms.removeNodes(editor, {
    at: [0],
  });
  Transforms.insertNodes(editor, deserializeFromHTML(value));
};

export default resetEditorValue;
