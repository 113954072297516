import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faCircleInfo, faFloppyDisk, faMemoPad } from "@fortawesome/pro-solid-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Dropdown from "antd/es/dropdown";
import { MenuProps } from "antd/es/menu";
import Popconfirm from "antd/es/popconfirm";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import ButtonLink from "@app/component/ButtonLink";
import { getEmailTemplates, isEmailTemplateUpdating } from "@app/store/email";
import { fetchEmailTemplates, updateEmailTemplate } from "@app/store/email/actions";
import { RootState } from "@app/store/rootReducer";
import useAnalytics from "@app/util/contexts/useAnalytics";

import styles from "./EmailTemplateButton.module.scss";
import messages from "./messages";
import NewTemplateModal from "./NewTemplateModal";
import TemplateModal from "./TemplateModal";

const getMenuItem = (text: string, icon?: React.ReactNode, disabledText?: string) => (
  <Tooltip placement="right" title={disabledText} trigger={disabledText ? ["hover"] : []}>
    <span className={styles.menuItem}>
      <span className={styles.menuItemIcon}>{icon}</span>
      <span>{text}</span>
    </span>
  </Tooltip>
);
interface Props {
  emailBody: string;
  emailFilled: boolean;
  emailSubject: string;
  onClear: () => void;
  onFetchEmailTemplates: typeof fetchEmailTemplates.request;
  onSelect: (template: EmailTemplate) => void;
  onSelectRecipientId: (id?: EntitiesSupportedByEmailFeature["id"]) => void;
  onUpdateEmailTemplate: typeof updateEmailTemplate.request;
  recipients: EntitiesSupportedByEmailFeature[];
  selectedRecipientId?: EntitiesSupportedByEmailFeature["id"];
  templateChanged: boolean;
  templates: EmailTemplate[];
  templateUpdating: boolean;
}

const EmailTemplateButton: React.FC<Props> = ({
  emailBody,
  emailFilled,
  emailSubject,
  onClear,
  onFetchEmailTemplates,
  onSelect,
  onSelectRecipientId,
  onUpdateEmailTemplate,
  recipients,
  selectedRecipientId,
  templateChanged,
  templates,
  templateUpdating,
}) => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const [selectedTemplate, setSelectedTemplate] = React.useState<EmailTemplate | undefined>();
  const [newTemplateModalVisible, showNewTemplateModal, hideNewTemplateModal] = useBoolean();
  const [templateModalVisible, showTemplateModal, hideTemplateModal] = useBoolean();

  useEffect(() => {
    onFetchEmailTemplates();
  }, [onFetchEmailTemplates]);

  const handleSelectTemplate = useCallback(
    (template: EmailTemplate) => {
      setSelectedTemplate(template);
      onSelect(template);
    },
    [setSelectedTemplate, onSelect]
  );

  const handleClear = useCallback(() => {
    setSelectedTemplate(undefined);
    onClear();
  }, [onClear]);

  const menuItems: MenuProps["items"] = useMemo(() => {
    return [
      ...(templates.length > 0
        ? [
            {
              key: "mostRecent",
              label: (
                <div className={styles.firstItemContainer}>
                  <span className={styles.mostRecent}>
                    {intl.formatMessage(messages.mostRecent)}
                  </span>
                  <Popconfirm
                    cancelText={intl.formatMessage(messages.deleteConfirmationCancel)}
                    disabled={!templateChanged}
                    okText={intl.formatMessage(messages.deleteConfirmationOk)}
                    onConfirm={handleClear}
                    placement="right"
                    title={intl.formatMessage(messages.clearConfirmationText)}
                    zIndex={1051} // higher than the dropdown menu
                  >
                    <ButtonLink
                      disabled={!selectedTemplate}
                      onClick={templateChanged ? stopEvents : handleClear}
                    >
                      {intl.formatMessage(messages.clear)}
                    </ButtonLink>
                  </Popconfirm>
                </div>
              ),
            },
            ...templates.map((template) => ({
              className: template.id === selectedTemplate?.id ? styles.selected : undefined,
              key: `template-${template.id}`,
              label: getMenuItem(
                template.name,
                template.id === selectedTemplate?.id ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : undefined
              ),
              onClick: () => handleSelectTemplate(template),
            })),
            {
              key: "divider",
              type: "divider",
            },
            {
              key: "seeMore",
              label: (
                <Row align="middle" justify="space-between">
                  <Col>{getMenuItem(intl.formatMessage(messages.seeMore))}</Col>
                  <Col>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Col>
                </Row>
              ),
              onClick: showTemplateModal,
            },
            {
              key: "divider",
              type: "divider",
            },
          ]
        : []),
      {
        disabled: !emailFilled,
        key: "saveNew",
        label: getMenuItem(
          intl.formatMessage(messages.saveNew),
          <FontAwesomeIcon icon={faFloppyDisk} />,
          emailFilled ? undefined : intl.formatMessage(messages.saveNewDisabled)
        ),
        onClick: emailFilled ? showNewTemplateModal : undefined,
      },
    ];
  }, [
    emailFilled,
    handleClear,
    handleSelectTemplate,
    intl,
    selectedTemplate,
    showNewTemplateModal,
    showTemplateModal,
    templateChanged,
    templates,
  ]);

  const handleHideNewTemplateModal = useCallback(
    (template?: EmailTemplate) => {
      hideNewTemplateModal();
      if (template) {
        setSelectedTemplate(template);
        analytics.clicked(["Save New Template"], { name: template.name });
      }
    },
    [analytics, hideNewTemplateModal]
  );

  const handleUpdate = useCallback(() => {
    if (selectedTemplate) {
      onUpdateEmailTemplate({
        callback: handleSelectTemplate,
        template: { ...selectedTemplate, body: emailBody, subject: emailSubject },
      });
    }
  }, [handleSelectTemplate, selectedTemplate, emailBody, emailSubject, onUpdateEmailTemplate]);

  return (
    <div className={styles.container}>
      <Dropdown menu={{ items: menuItems }} overlayClassName={styles.dropdown} trigger={["click"]}>
        <div className={styles.buttonContainer}>
          <div
            className={cn(styles.button, {
              [styles.buttonWithTemplate]: !!selectedTemplate,
              [styles.buttonWithTemplateHasChanges]: templateChanged,
            })}
          >
            {templateChanged ? (
              <FontAwesomeIcon className={styles.templateHasChangedIcon} icon={faCircleInfo} />
            ) : (
              <FontAwesomeIcon icon={faMemoPad} />
            )}
            <span>{selectedTemplate?.name ?? intl.formatMessage(messages.selectTemplate)}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      </Dropdown>
      {templateChanged && (
        <Tooltip title={intl.formatMessage(messages.updateDescription)}>
          <Popconfirm
            cancelText={intl.formatMessage(messages.updateConfirmationCancel)}
            okText={intl.formatMessage(messages.updateConfirmationOk)}
            onConfirm={handleUpdate}
            overlayClassName={styles.updateConfirmation}
            placement="bottom"
            title={intl.formatMessage(messages.updateConfirmationText)}
          >
            <Button disabled={templateUpdating} loading={templateUpdating} type="link">
              {intl.formatMessage(messages.update)}
            </Button>
          </Popconfirm>
        </Tooltip>
      )}
      {newTemplateModalVisible && (
        <NewTemplateModal
          emailBody={emailBody}
          emailSubject={emailSubject}
          onHide={handleHideNewTemplateModal}
        />
      )}
      {templateModalVisible && (
        <TemplateModal
          onHide={hideTemplateModal}
          onSelect={handleSelectTemplate}
          onSelectRecipientId={onSelectRecipientId}
          recipients={recipients}
          selectedRecipientId={selectedRecipientId}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templates: getEmailTemplates(state),
  templateUpdating: isEmailTemplateUpdating(state),
});

const mapDispatchToProps = {
  onFetchEmailTemplates: fetchEmailTemplates.request,
  onUpdateEmailTemplate: updateEmailTemplate.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateButton);
