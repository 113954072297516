import isEqual from "lodash-es/isEqual";

import MmcNotificationAction from "@app/enum/MmcNotificationAction";
import NotificationSettingValue from "@app/types/notifications/NotificationSetting";

import switchOffNotificationSetting from "./switchOffNotificationSetting";

const isNotificationEnabled = (action: MmcNotificationAction, settings: NotificationSettingValue) =>
  !isEqual(switchOffNotificationSetting(settings, action), settings);

export default isNotificationEnabled;
