import { defineMessages } from "react-intl";

const addressFieldsMessages = defineMessages({
  companyCity: {
    id: "fieldModel.addressFields.companyCity",
    defaultMessage: "Company City",
    description: "Name field of common company City field",
  },
  companyCountry: {
    id: "fieldModel.addressFields.companyCountry",
    defaultMessage: "Company Country",
    description: "Name field of common company Country field",
  },
  companyPostalCode: {
    id: "fieldModel.addressFields.companyPostalCode",
    defaultMessage: "Company Postal Code",
    description: "Name field of common company Postal Code field",
  },
  companyState: {
    id: "fieldModel.addressFields.companyState",
    defaultMessage: "Company State/Region",
    description: "Name field of common company State/Region field",
  },
  companyStreet: {
    id: "fieldModel.addressFields.companyStreet",
    defaultMessage: "Company Street",
    description: "Name field of common company street field",
  },
  dealCity: {
    id: "fieldModel.addressFields.dealCity",
    defaultMessage: "Deal City",
    description: "Name field of common deal City field",
  },
  dealCountry: {
    id: "fieldModel.addressFields.dealCountry",
    defaultMessage: "Deal Country",
    description: "Name field of common deal Country field",
  },
  dealPostalCode: {
    id: "fieldModel.addressFields.dealPostalCode",
    defaultMessage: "Deal Postal Code",
    description: "Name field of common deal Postal Code field",
  },
  dealState: {
    id: "fieldModel.addressFields.dealState",
    defaultMessage: "Deal State/Region",
    description: "Name field of common deal State/Region field",
  },
  dealStreet: {
    id: "fieldModel.addressFields.dealStreet",
    defaultMessage: "Deal Street",
    description: "Name field of common deal street field",
  },
  parentCompanyCity: {
    id: "fieldModel.addressFields.parentCompanyCity",
    defaultMessage: "Parent Company City",
    description: "Name field of common parent company City field",
  },
  parentCompanyCountry: {
    id: "fieldModel.addressFields.parentCompanyCountry",
    defaultMessage: "Parent Company Country",
    description: "Name field of common parent company Country field",
  },
  parentCompanyPostalCode: {
    id: "fieldModel.addressFields.parentCompanyPostalCode",
    defaultMessage: "Parent Company Postal Code",
    description: "Name field of common parent company Postal Code field",
  },
  parentCompanyState: {
    id: "fieldModel.addressFields.parentCompanyState",
    defaultMessage: "Parent Company State/Region",
    description: "Name field of common parent company State/Region field",
  },
  parentCompanyStreet: {
    id: "fieldModel.addressFields.parentCompanyStreet",
    defaultMessage: "Parent Company Street",
    description: "Name field of common parent company street field",
  },
  peopleCity: {
    id: "fieldModel.addressFields.peopleCity",
    defaultMessage: "Person City",
    description: "Name field of common people City field",
  },
  peopleCountry: {
    id: "fieldModel.addressFields.peopleCountry",
    defaultMessage: "Person Country",
    description: "Name field of common people Country field",
  },
  peoplePostalCode: {
    id: "fieldModel.addressFields.peoplePostalCode",
    defaultMessage: "Person Postal Code",
    description: "Name field of common people Postal Code field",
  },
  peopleState: {
    id: "fieldModel.addressFields.peopleState",
    defaultMessage: "Person State/Region",
    description: "Name field of common people State/Region field",
  },
  peopleStreet: {
    id: "fieldModel.addressFields.peopleStreet",
    defaultMessage: "Person Street",
    description: "Name field of common people street field",
  },
});

export default addressFieldsMessages;
