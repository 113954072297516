import { all, put, takeLatest } from "redux-saga/effects";

import Currency from "@mapmycustomers/shared/types/Currency";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "../api/callApi";

import { fetchReferenceData } from "./actions";

export function* onFetchReferenceData() {
  try {
    const [currencyResponse]: [ListResponse<Currency>] = yield all([callApi("fetchCurrencies")]);
    yield put(fetchReferenceData.success({ currencies: currencyResponse.data }));
  } catch {
    yield put(fetchReferenceData.failure());
    // error is handled in the onInitializeApp saga
  }
}

export function* referenceDataSaga() {
  yield takeLatest(fetchReferenceData.request, onFetchReferenceData);
}
