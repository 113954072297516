@use "../../../styles/colors";
@use "../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .enterManuallyButton {
        display: block;
        width: 100%;
        text-align: center;
      }

      .noResults {
        width: 100%;
        text-align: center;
      }

      .markerIcon {
        color: colors.$mmc-primary-100;
      }

      .placeOption {
        line-height: 30px;

        &:hover {
          background: colors.$mmc-soft-gray;
        }
      }

      .placeDescription {
        margin-left: layout.$spacer-s;
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
