import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";

import StageFilter, { STAGE_FILTER_OPERATORS } from "@app/util/filters/Stage";
import { csvStageFormatter } from "@app/util/formatters";

import Field, { FieldProperties } from "./Field";

const stageNameFormatter = (entity: unknown, values: unknown) =>
  values ? (values as Stage).name : "";

class StageField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      csvExportProperties: { valueFormatter: csvStageFormatter },
      customFilterConfig: {
        defaultInstance: "stage",
        filterInstances: {
          stage: StageFilter,
        },
        operators: STAGE_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "stageCellRenderer",
      },
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FORM_RENDERED_WITH_OTHER_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.STAGE_FIELD,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.REQUIRED,
      ],
      importProperties: { required: true },
      platformFilterName: "stageId",
      type: FieldType.STRING,
      valueFormatter: stageNameFormatter,
      ...data,
    });
  }
}

export default StageField;
