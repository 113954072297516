import Scope from "@app/types/dashboard/Scope";

// we can only add/remove cards from the team board when touching a team member board
// that's what this function returns - alter a scope to point to the team scope if
// it was a team member scope
const getResultingScope = (scope: Scope) => {
  const isTeamUserScope = !!scope.teamId && !!scope.userId;
  return isTeamUserScope ? { ...scope, userId: undefined } : scope;
};

export default getResultingScope;
