import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

const convertFieldToLayoutSchemaField = (field: IField, displayOrder = 0) => ({
  displayOrder,
  field: field.platformName,
  fieldType: field.platformFieldType,
  required: !!field.isSystemRequired && !field.hasFeature(FieldFeature.RELATIONSHIPS),
});

export default convertFieldToLayoutSchemaField;
