import React from "react";

import { defineMessages, IntlShape } from "react-intl";

import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";

import addressImage from "@app/assets/customFields/cf-address.svg";
import dateImage from "@app/assets/customFields/cf-date.svg";
import monetaryImage from "@app/assets/customFields/cf-monetary.svg";
import multiImage from "@app/assets/customFields/cf-multi.svg";
import numericImage from "@app/assets/customFields/cf-numeric.svg";
import phoneImage from "@app/assets/customFields/cf-phone.svg";
import singleImage from "@app/assets/customFields/cf-single.svg";
import textImage from "@app/assets/customFields/cf-text.svg";
import timeImage from "@app/assets/customFields/cf-time.svg";
import addressDescriptionImage from "@app/assets/customFields/preview/address.jpg";
import dateDescriptionImage from "@app/assets/customFields/preview/date.jpg";
import largeTextDescriptionImage from "@app/assets/customFields/preview/large-text.jpg";
import monetaryDescriptionImage from "@app/assets/customFields/preview/monetary.jpg";
import multiDescriptionImage from "@app/assets/customFields/preview/multi-select.jpg";
import numericDescriptionImage from "@app/assets/customFields/preview/numeric.jpg";
import phoneDescriptionImage from "@app/assets/customFields/preview/phone.jpg";
import singleDescriptionImage from "@app/assets/customFields/preview/single-select.jpg";
import textDescriptionImage from "@app/assets/customFields/preview/text.jpg";
import timeDescriptionImage from "@app/assets/customFields/preview/time.jpg";

const customFieldDataTypeToImage: Record<CustomFieldDataType, string | undefined> = {
  [CustomFieldDataType.ADDRESS]: addressImage,
  [CustomFieldDataType.BOOLEAN]: undefined,
  [CustomFieldDataType.DATE]: dateImage,
  [CustomFieldDataType.DECIMAL]: numericImage,
  [CustomFieldDataType.INTEGER]: numericImage,
  [CustomFieldDataType.LARGE_TEXT]: textImage,
  [CustomFieldDataType.MONETARY]: monetaryImage,
  [CustomFieldDataType.MULTI_OPTION]: multiImage,
  [CustomFieldDataType.PHONE]: phoneImage,
  [CustomFieldDataType.SINGLE_OPTION]: singleImage,
  [CustomFieldDataType.TEXT]: textImage,
  [CustomFieldDataType.TIME]: timeImage,
};

const customFieldDataTypeToPreviewImage: Record<CustomFieldDataType, string | undefined> = {
  [CustomFieldDataType.ADDRESS]: addressDescriptionImage,
  [CustomFieldDataType.BOOLEAN]: undefined,
  [CustomFieldDataType.DATE]: dateDescriptionImage,
  [CustomFieldDataType.DECIMAL]: numericDescriptionImage,
  [CustomFieldDataType.INTEGER]: numericDescriptionImage,
  [CustomFieldDataType.LARGE_TEXT]: largeTextDescriptionImage,
  [CustomFieldDataType.MONETARY]: monetaryDescriptionImage,
  [CustomFieldDataType.MULTI_OPTION]: multiDescriptionImage,
  [CustomFieldDataType.PHONE]: phoneDescriptionImage,
  [CustomFieldDataType.SINGLE_OPTION]: singleDescriptionImage,
  [CustomFieldDataType.TEXT]: textDescriptionImage,
  [CustomFieldDataType.TIME]: timeDescriptionImage,
};

export const getCustomFieldDataTypeImage = (type: CustomFieldDataType): string | undefined =>
  customFieldDataTypeToImage[type];

export const getCustomFieldDataTypePreviewImage = (type: CustomFieldDataType): string | undefined =>
  customFieldDataTypeToPreviewImage[type];

const messages = defineMessages({
  [CustomFieldDataType.ADDRESS]: {
    id: "customFieldDataType.address",
    defaultMessage: "Address",
    description: "Name of custom field data type - address",
  },
  [CustomFieldDataType.BOOLEAN]: {
    id: "customFieldDataType.boolean",
    defaultMessage: "Boolean",
    description: "Name of custom field data type - boolean",
  },
  [CustomFieldDataType.DATE]: {
    id: "customFieldDataType.date",
    defaultMessage: "Date",
    description: "Name of custom field data type - date",
  },
  [CustomFieldDataType.DECIMAL]: {
    id: "customFieldDataType.decimal",
    defaultMessage: "Numeric",
    description: "Name of custom field data type - decimal",
  },
  [CustomFieldDataType.INTEGER]: {
    id: "customFieldDataType.integer",
    defaultMessage: "Integer",
    description: "Name of custom field data type - integer",
  },
  [CustomFieldDataType.LARGE_TEXT]: {
    id: "customFieldDataType.largeText",
    defaultMessage: "Large text",
    description: "Name of custom field data type - large text",
  },
  [CustomFieldDataType.MONETARY]: {
    id: "customFieldDataType.monetary",
    defaultMessage: "Monetary",
    description: "Name of custom field data type - monetary",
  },
  [CustomFieldDataType.MULTI_OPTION]: {
    id: "customFieldDataType.multiOption",
    defaultMessage: "Multi option",
    description: "Name of custom field data type - multi option",
  },
  [CustomFieldDataType.PHONE]: {
    id: "customFieldDataType.phone",
    defaultMessage: "Phone",
    description: "Name of custom field data type - phone",
  },
  [CustomFieldDataType.SINGLE_OPTION]: {
    id: "customFieldDataType.singleOption",
    defaultMessage: "Single option",
    description: "Name of custom field data type - single option",
  },
  [CustomFieldDataType.TEXT]: {
    id: "customFieldDataType.text",
    defaultMessage: "Text",
    description: "Name of custom field data type - text",
  },
  [CustomFieldDataType.TIME]: {
    id: "customFieldDataType.time",
    defaultMessage: "Time",
    description: "Name of custom field data type - time",
  },
});

const descriptionMessages = defineMessages({
  [CustomFieldDataType.ADDRESS]: {
    id: "customFieldDataType.address.description",
    defaultMessage:
      "Address fields allow you to <b>enter a location</b> using Google autocomplete, manual entry or via coordinate pairs",
    description: "Description of custom field data type - address",
  },
  [CustomFieldDataType.BOOLEAN]: {
    id: "customFieldDataType.boolean.description",
    defaultMessage: "Boolean",
    description: "Description of custom field data type - boolean",
  },
  [CustomFieldDataType.DATE]: {
    id: "customFieldDataType.date.description",
    defaultMessage: "Date fields allow you to <b>pick a single date</b> from a dropdown calendar",
    description: "Description of custom field data type - date",
  },
  [CustomFieldDataType.DECIMAL]: {
    id: "customFieldDataType.decimal.description",
    defaultMessage:
      "Numeric fields allow you to enter up to <b>255 numerals (decimals allowed)</b> in a fixed-height field",
    description: "Description of custom field data type - decimal",
  },
  [CustomFieldDataType.INTEGER]: {
    id: "customFieldDataType.integer.description",
    defaultMessage: "Decimal",
    description: "Description of custom field data type - integer",
  },
  [CustomFieldDataType.LARGE_TEXT]: {
    id: "customFieldDataType.largeText.description",
    defaultMessage:
      "Large text fields allow you to enter up to <b>4000 characters</b> in an expandable field",
    description: "Description of custom field data type - large text",
  },
  [CustomFieldDataType.MONETARY]: {
    id: "customFieldDataType.monetary.description",
    defaultMessage:
      "Monetary fields allow you to select the currency and the number amount in a fixed-height field",
    description: "Description of custom field data type - monetary",
  },
  [CustomFieldDataType.MULTI_OPTION]: {
    id: "customFieldDataType.multiOption.description",
    defaultMessage:
      "Multi-option fields allow you to create several options, and your team can <b>pick multiple from the list</b>",
    description: "Description of custom field data type - multi option",
  },
  [CustomFieldDataType.PHONE]: {
    id: "customFieldDataType.phone.description",
    defaultMessage:
      "Phone fields allow you to enter up to <b>255 characters</b> in a fixed-height field",
    description: "Description of custom field data type - phone",
  },
  [CustomFieldDataType.SINGLE_OPTION]: {
    id: "customFieldDataType.singleOption.description",
    defaultMessage:
      "Single-option fields allow you to create several options, but your team will only be able to <b>pick one</b>",
    description: "Description of custom field data type - single option",
  },
  [CustomFieldDataType.TEXT]: {
    id: "customFieldDataType.text.description",
    defaultMessage:
      "Text fields allow you to enter up to <b>255 characters</b> in a fixed-height field",
    description: "Description of custom field data type - text",
  },
  [CustomFieldDataType.TIME]: {
    id: "customFieldDataType.time.description",
    defaultMessage:
      "Time fields allow you to <b>pick a single time</b> from a dropdown time picker",
    description: "Description of custom field data type - time",
  },
});

export const getCustomFieldDataTypeDisplayName = (intl: IntlShape, type: CustomFieldDataType) =>
  intl.formatMessage(messages[type]);

export const getCustomFieldDataTypeDescription = (intl: IntlShape, type: CustomFieldDataType) =>
  intl.formatMessage(descriptionMessages[type], { b: (text) => <b>{text}</b> });
