import { useMemo } from "react";

import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import getRecipientDisplayName from "@app/util/email/getRecipientDisplayName";

const useRecipientOptions = (recipients: EntitiesSupportedByEmailFeature[]) =>
  useMemo(
    () =>
      recipients.map((recipient) => ({
        label: getRecipientDisplayName(recipient),
        value: recipient.id,
      })),
    [recipients]
  );

export default useRecipientOptions;
