import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { MapEntity } from "@mapmycustomers/shared/types/entity";
import {
  CategorizedMapEntries,
  EntityPin,
  MapRecordsResponse,
  MultiPin,
} from "@mapmycustomers/shared/types/map";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";

import MultiPinGroupMode from "@app/scene/map/enums/MultiPinGroupMode";
import { MapFetcherPayload } from "@app/scene/map/types/MapFetcher";
import DownloadEntitiesMapping from "@app/types/DownloadEntitiesMapping";
import { AreaSearchQuery } from "@app/types/filters/AreaSearchQuery";
import MapRecordsListState from "@app/types/viewModel/MapRecordsListState";

export const initializeMapMode = createAsyncAction(
  "map/map/initialize/request",
  "map/map/initialize/success",
  "map/map/initialize/failure"
)<void, void, void>();

export const fetchPins = createAsyncAction(
  "map/map/fetchPins/request",
  "map/map/fetchPins/success",
  "map/map/fetchPins/failure"
)<
  MapFetcherPayload,
  CategorizedMapEntries & {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const fetchRecords = createAsyncAction(
  "map/map/fetchRecords/request",
  "map/map/fetchRecords/success",
  "map/map/fetchRecords/failure"
)<
  MapFetcherPayload,
  {
    isFiltered: boolean;
    response: MapRecordsResponse;
  },
  unknown
>();
export const clearRecordsList = createAction("map/map/clearRecordsList")<void>();
export const resetRecordsListPagination = createAction(
  "map/map/resetRecordsListPagination"
)<void>();

export const applyMapViewSettings = createAction("map/map/applyMapViewSettings")<
  Partial<MapViewState>
>();

export const applyRecordsListSettings = createAction("map/map/applyRecordsListSettings")<
  Partial<MapRecordsListState>
>();

export const downloadMapRecords = createAction(
  "map/map/downloadListData"
)<DownloadEntitiesMapping>();

export const updateRecordsListCustomization = createAsyncAction(
  "map/map/updateRecordsCustomization/request",
  "map/map/updateRecordsCustomization/success",
  "map/map/updateRecordsCustomization/failure"
)<MapRecordsListState, void, void>();

export const hideMapEntityView = createAction("map/hideMapEntityView")<void>();

export const hideMultiPin = createAction("map/hideMultiPin")<void>();
export const setMultiPinGroupMode = createAction("map/setMultiPinGroupMode")<MultiPinGroupMode>();

export const showMultiPinRecords = createAsyncAction(
  "map/map/showMultiPinRecords/request",
  "map/map/showMultiPinRecords/success",
  "map/map/showMultiPinRecords/failure"
)<
  MultiPin,
  {
    address?: string;
  },
  unknown
>();

export const showSidebar = createAction("map/map/showSidebar")<{ skipLoading: true } | void>();
export const hideSidebar = createAction("map/map/hideSidebar")<void>();

export const showAnnotation = createAsyncAction(
  "map/map/showAnnotation/request",
  "map/map/showAnnotation/success",
  "map/map/showAnnotation/failure"
)<
  string,
  {
    defaultEntity?: MapEntity;
    entityId: MapEntity["id"];
    entityType: MapEntity["entity"];
  },
  void
>();
export const hideAnnotation = createAction("map/map/hideAnnotation")<void>();

export const setPinHover = createAction("map/map/setPinHover")<string | undefined>();

export const setHighlight = createAction("map/map/setHighlight")<Set<string>>();
export const clearHighlight = createAction("map/map/clearHighlight")<void>();
export const setSelection = createAction("map/map/setSelection")<Set<string>>();
export const setSelectionEntities = createAction("map/map/setSelectionEntities")<MapEntity[]>();
export const clearSelection = createAction("map/map/clearSelection")<void>();
export const restartSelection = createAction("map/map/restartSelection")<void>();
export const toggleSelectedItem = createAction("map/map/toggleSelectedItem")<{
  entity: string;
  value?: boolean;
}>();
export const selectItem = createAction("map/map/selectItem")<string>();
export const unselectItem = createAction("map/map/unselectItem")<string>();
export const selectMultiplyItems = createAction("map/map/selectMultiplyItems")<string[]>();

export const addToSelection = createAction("map/map/addToSelection")<Set<string> | string>();
export const removeFromSelection = createAction("map/map/removeFromSelection")<
  Set<string> | string
>();

export const setFocusedEntity = createAction("map/map/focusPin")<EntityPin | undefined>();
export const setSelectedPin = createAction("map/map/selectPin")<EntityPin | undefined>();

export const setListViewLocationSortQuery = createAction("map/map/setListViewLocationSortQuery")<
  AreaSearchQuery | undefined
>();

export const setChangesDiscarded = createAction("map/setChangesDiscarded")<boolean>();

export type MapModeActions = ActionType<
  | typeof addToSelection
  | typeof applyMapViewSettings
  | typeof applyRecordsListSettings
  | typeof clearHighlight
  | typeof clearRecordsList
  | typeof clearSelection
  | typeof downloadMapRecords
  | typeof downloadMapRecords
  | typeof fetchPins
  | typeof fetchRecords
  | typeof hideAnnotation
  | typeof hideMapEntityView
  | typeof hideMultiPin
  | typeof hideSidebar
  | typeof initializeMapMode
  | typeof removeFromSelection
  | typeof resetRecordsListPagination
  | typeof restartSelection
  | typeof selectItem
  | typeof selectMultiplyItems
  | typeof setChangesDiscarded
  | typeof setFocusedEntity
  | typeof setHighlight
  | typeof setListViewLocationSortQuery
  | typeof setMultiPinGroupMode
  | typeof setPinHover
  | typeof setSelectedPin
  | typeof setSelection
  | typeof setSelectionEntities
  | typeof showAnnotation
  | typeof showMultiPinRecords
  | typeof showSidebar
  | typeof toggleSelectedItem
  | typeof unselectItem
  | typeof updateRecordsListCustomization
>;
