enum LeadFinderSubscriptionPlan {
  NONE = "no-plan",
  BASIC = "standard",
  PRO = "pro",
}

export const LEAD_FINDER_ENABLED_PLANS = [
  LeadFinderSubscriptionPlan.BASIC,
  LeadFinderSubscriptionPlan.PRO,
];

export default LeadFinderSubscriptionPlan;
