const downloadFile = (fileName: string, blob: Blob) => {
  const hiddenElement = window.document.createElement("a");
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = fileName;
  document.body.appendChild(hiddenElement);
  hiddenElement.click();
  document.body.removeChild(hiddenElement);
};

export default downloadFile;
