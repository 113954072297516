import { defineMessages, IntlShape } from "react-intl";

import Role from "@mapmycustomers/shared/enum/Role";

const messages = defineMessages<Role>({
  [Role.MANAGER]: {
    id: "role.manager",
    defaultMessage: "Team manager",
    description: "Name of manager role",
  },
  [Role.MEMBER]: {
    id: "role.member",
    defaultMessage: "Team member",
    description: "Name of member role",
  },
  [Role.OWNER]: {
    id: "role.owner",
    defaultMessage: "Team Owner",
    description: "Name of owner role",
  },
  [Role.READ_ONLY]: {
    id: "role.readOnly",
    defaultMessage: "Read only",
    description: "Name of read only role",
  },
});

const altMessages = defineMessages<Role>({
  [Role.MANAGER]: {
    id: "role.alternate.manager",
    defaultMessage: "Managers",
    description: "Name of manager role (alternate)",
  },
  [Role.MEMBER]: {
    id: "role.alternate.member",
    defaultMessage: "Members",
    description: "Name of member role (alternate)",
  },
  [Role.OWNER]: {
    id: "role.alternate.owner",
    defaultMessage: "Owners",
    description: "Name of owner role (alternate)",
  },
  [Role.READ_ONLY]: {
    id: "role.alternate.readOnly",
    defaultMessage: "Read only members",
    description: "Name of read only role (alternate)",
  },
});

const getRoleDisplayName = (intl: IntlShape, role: Role) => intl.formatMessage(messages[role]);

// TODO: move to settings scene
export const getRoleDisplayNameAlt = (intl: IntlShape, role: Role) =>
  intl.formatMessage(altMessages[role]);

export default getRoleDisplayName;
