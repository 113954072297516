import { connect } from "react-redux";

import FileUploader from "@app/component/FileUploader";
import { RootState } from "@app/store/rootReducer";

import { clearAllUploadedPersonFiles, removePersonFile, uploadPersonFiles } from "../store/actions";
import { getUploadedFilesList, isFilesUploading } from "../store/selectors";

const mapStateToProps = (state: RootState) => ({
  fileUploading: isFilesUploading(state),
  uploadedFiles: getUploadedFilesList(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedPersonFiles,
  onFileUpload: uploadPersonFiles.request,
  onRemoveFile: removePersonFile.request,
};

const File = connect(mapStateToProps, mapDispatchToProps)(FileUploader);
export default File;
