import { createReducer } from "typesafe-actions";

import { EntityType } from "@mapmycustomers/shared/enum";

import LayoutMap from "@app/store/layout/LayoutMap";

import { Actions, fetchAllLayouts, fetchLayoutsForEntityType } from "./actions";

export interface LayoutState {
  error?: unknown;
  layouts: LayoutMap;
  loading: boolean;
}

const initialState: LayoutState = {
  error: undefined,
  layouts: {
    [EntityType.ACTIVITY]: [],
    [EntityType.COMPANY]: [],
    [EntityType.DEAL]: [],
    [EntityType.PERSON]: [],
  },
  loading: false,
};

const layout = createReducer<LayoutState, Actions>(initialState)
  .handleAction([fetchAllLayouts.request, fetchLayoutsForEntityType.request], (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchAllLayouts.success, (state, { payload }) => ({
    ...state,
    layouts: { ...state.layouts, ...payload },
    loading: false,
  }))
  .handleAction(fetchLayoutsForEntityType.success, (state, { payload }) => ({
    ...state,
    layouts: {
      ...state.layouts,
      [payload.entityType]: payload.layouts,
    },
    loading: false,
  }))
  .handleAction(
    [fetchAllLayouts.failure, fetchLayoutsForEntityType.failure],
    (state, { payload }) => ({ ...state, error: payload, loading: false })
  );

export * from "./selectors";

export type LayoutActions = Actions;

export default layout;
