import React, { ReactNode } from "react";
import { connect } from "react-redux";

import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";

import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";
import SignupField from "@app/scene/auth/component/Register/enum/SignupField";
import BaseStepProps from "@app/scene/auth/component/Register/types/BaseStepProps";
import {
  usePhoneValidationRules,
  useRequiredValidation,
  useUsernameValidationRules,
} from "@app/scene/auth/component/Register/utils/validation-hooks";
import { doesUserExist, isUserExistsLoading } from "@app/store/auth";
import { userExists } from "@app/store/auth/actions";
import { RootState } from "@app/store/rootReducer";

import styles from "../../Steps.module.scss";

const emailRegex = /\S+@\S+\.\S+/;

interface Props extends BaseStepProps {
  checkingIfUserExists: boolean;
  doesUserExist: boolean;
  title: ReactNode;
  userExists: typeof userExists.request;
}

const ContactInfoStep: React.FC<Props> = ({
  checkingIfUserExists,
  className,
  doesUserExist,
  isValid,
  onNextClick,
  title,
  userExists,
}) => {
  const intl = useIntl();

  const checkExistingUser = useDebouncedCallback(
    [
      (e) => {
        const value = e.target.value;
        if (emailRegex.test(value)) {
          userExists({ username: value });
        }
      },
      500,
    ],
    [userExists]
  );

  const usernameValidationRules = useUsernameValidationRules(
    intl,
    doesUserExist,
    checkingIfUserExists
  );
  const requiredValidation = useRequiredValidation(intl);
  const phoneValidationRules = usePhoneValidationRules(intl);

  return (
    <div className={cn(styles.stepContainer, className)}>
      <p className={commonStyles.description}>{title}</p>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.registerMember.contactInfoStep.fullname",
          defaultMessage: "Full Name",
          description: "Title of the full name field on the register form",
        })}
        name={SignupField.FULL_NAME}
        requiredMark="optional"
        rules={requiredValidation}
      >
        <Input autoComplete="name" size="large" />
      </Form.Item>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.registerMember.contactInfoStep.email",
          defaultMessage: "Email",
          description: "Title of the Email field on the register form",
        })}
        name={SignupField.EMAIL}
        requiredMark="optional"
        rules={usernameValidationRules}
      >
        <Input
          addonAfter={<FontAwesomeIcon icon={faLock} />}
          autoComplete="username"
          disabled
          onChange={checkExistingUser}
          size="large"
        />
      </Form.Item>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.registerMember.contactInfoStep.phone",
          defaultMessage: "Phone",
          description: "Title of the Phone number field on the register form",
        })}
        name={SignupField.PHONE}
        requiredMark="optional"
        rules={phoneValidationRules}
      >
        <Input autoComplete="tel" name="phone" size="large" type="tel" />
      </Form.Item>
      <Form.Item>
        <div className={styles.buttons}>
          <Button disabled={!isValid} htmlType="button" onClick={onNextClick} type="primary">
            <FormattedMessage
              id="auth.registerMember.nextStepButton"
              defaultMessage="Next Step"
              description="Title of the Next Step button for the Register Form"
            />
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  checkingIfUserExists: isUserExistsLoading(state),
  doesUserExist: doesUserExist(state),
});

const mapDispatchToProps = {
  userExists: userExists.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoStep);
