/* eslint-disable @typescript-eslint/no-explicit-any */
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";

// a hack to override locale name for russian locale. It is "ru_RU" in
// antd world, but "ru" in date-fns
// (other locale are most probably affected too, e.g. de_DE or da_DK)
const localeMapping: { [key: string]: string } = {
  es_ES: "es",
  pt_BR: "pt",
  ru_RU: "ru",
};

const wrapMethod = <T>(locale: Locale, fn: (locale: string, ...args: any[]) => T) => {
  return (antDocale: string, ...args: any[]): T => {
    const localeCode = (locale.code ?? "en-US").replace("-", "");
    return fn(
      Object.prototype.hasOwnProperty.call(localeMapping, localeCode)
        ? localeMapping[localeCode]
        : localeCode,
      ...args
    );
  };
};

const getAntdPickerLocaleConfig = (locale: Locale) => {
  return (
    Object.keys(dateFnsGenerateConfig.locale) as Array<keyof typeof dateFnsGenerateConfig.locale>
  ).reduce(
    (result, methodName) => ({
      ...result,
      [methodName]: wrapMethod(
        locale,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dateFnsGenerateConfig.locale[methodName]
      ),
    }),
    {}
  ) as typeof dateFnsGenerateConfig.locale;
};

export default getAntdPickerLocaleConfig;
