import { defineMessages } from "react-intl";

import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";

export const titleMessages = defineMessages({
  dueToday: {
    id: "nextActivityState.outSoon.dueToday",
    defaultMessage: "Activity due today",
    description: "frequencies - next activity state - due Today ",
  },
  [FrequencyStatus.PAST_DUE]: {
    id: "nextActivityState.overdue.title",
    defaultMessage: "Activity due {count} days ago",
    description: "frequencies - next activity state - overdue",
  },
  [FrequencyStatus.UNKNOWN]: {
    id: "nextActivityState.noActivity.title",
    defaultMessage: "Log activity to start frequency",
    description: "frequencies - next activity state - noActivity - title",
  },
  [FrequencyStatus.UP_TO_DATE]: {
    id: "nextActivityState.upToDate.title",
    defaultMessage: "Not due for {count} days",
    description: "frequencies - next activity state - up to date",
  },
  [FrequencyStatus.UPCOMING]: {
    id: "nextActivityState.outSoon.title",
    defaultMessage: "Activity due in {count} {count, plural, one {day} other {days}}",
    description: "frequencies - next activity state - outSoon ",
  },
  overdueToday: {
    id: "nextActivityState.overdueToday.title",
    defaultMessage: "Overdue today",
    description: "frequencies - next activity state - Overdue today",
  },
});

export const messages = defineMessages({
  subTitle: {
    id: "nextActivityState.subTitle",
    defaultMessage: "Every {count} {count, plural, one {Day} other {Days}}",
    description: "frequencies - next activity state - up to date - subtitle",
  },
});
