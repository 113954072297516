import { defineMessages } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";

const messages = defineMessages({
  addNewCompanyButton: {
    id: "associations.companies.addNewCompanyButton",
    defaultMessage: "+ Add New Company",
    description: "Add new company button on Company association modal",
  },
  alreadyAssociated: {
    id: "associations.companies.alreadyAssociated",
    defaultMessage:
      "This company is already associated with this record. To remove the association, click the “Remove” icon from the edit panel.",
    description: "A tooltip over the checkbox when company is already assigned to a target record",
  },
  cantAssociateWithSelf: {
    id: "associations.companies.cantAssociateWithSelf",
    defaultMessage: "Assigning company to itself is not allowed",
    description: "A tooltip over the checkbox when company is already a target company",
  },
  [EntityType.ACTIVITY]: {
    id: "associations.companies.addNewCompanyModal.activity",
    defaultMessage: "Activity",
    description: "Activity text",
  },
  [EntityType.COMPANY]: {
    id: "associations.companies.addNewCompanyModal.company",
    defaultMessage: "Company",
    description: "Company text",
  },
  [EntityType.DEAL]: {
    id: "associations.companies.addNewCompanyModal.deal",
    defaultMessage: "Deal",
    description: "Deal text",
  },
  [EntityType.PERSON]: {
    id: "associations.companies.addNewCompanyModal.person",
    defaultMessage: "Person",
    description: "Person text",
  },
  errorDescription: {
    id: "associations.companies.error.description",
    defaultMessage: "You can only relate up to {limit} Companies to an individual {entity}",
    description: "Title of limit reached error",
  },
  errorTitle: {
    id: "associations.companies.error.title",
    defaultMessage: "Limit of related companies reached",
    description: "Title of limit reached error",
  },
  footerOkButton: {
    id: "associations.companies.footer.footerOkButton",
    defaultMessage:
      "{addCompanies, select, true {Add} other {Update}} {multiselect, select, true {Companies} other {Company}}",
    description: "Add button title on Company Associations modal",
  },
  header: {
    id: "associations.companies.header",
    defaultMessage: "Select {multiselect, select, true {Companies} other {Company}}",
    description: "Header of Company Associations modal",
  },
  modalOkButton: {
    id: "associations.companies.addNewCompanyModal.okButton",
    defaultMessage: "Create and Add Company",
    description:
      "Create button title on Create Company modal when called from Select Companies modal",
  },
  placeholder: {
    id: "associations.companies.search.placeholder",
    defaultMessage: "Search for a company",
    description: "Search bar placeholder for company association modal",
  },
});

export default messages;
