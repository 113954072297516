import { all, call } from "redux-saga/effects";

export type SettleResult<T> = { error: false; result: T } | { error: true; result: unknown };

export const allSettled = (effects: any[]) =>
  all(
    effects.map((effect) =>
      call(function* settle() {
        try {
          // Ignoring because we don't know the exact value anyway,
          // it can be literally anything
          // @ts-ignore
          return { error: false, result: yield effect };
        } catch (err) {
          return { error: true, result: err };
        }
      })
    )
  );
