import React, { ReactNode } from "react";

import { bem } from "@react-md/utils";
import AntdModal, { ModalProps as AntdModalProps } from "antd/es/modal";
import cn from "classnames";

const block = bem("mmc-modal");

export interface ModalProps extends AntdModalProps {
  extra?: ReactNode;
  modalStyle?: "fancy";
  size?: "large" | "small";
  type?: "default" | "disabled" | "error" | "none" | "success" | "warning";
}

const Modal: React.FC<ModalProps> = ({
  className,
  extra,
  modalStyle,
  size = "large",
  title,
  type = "default",
  ...props
}) => (
  <AntdModal
    centered
    className={cn(
      block({
        bordered: type !== "none",
        disabled: type === "disabled",
        error: type === "error",
        fancyModalStyle: modalStyle === "fancy",
        small: size === "small",
        success: type === "success",
        warning: type === "warning",
      }),
      className
    )}
    title={
      title && (
        <div className={block("header")}>
          {title}
          {extra && <div>{extra}</div>}
        </div>
      )
    }
    {...props}
  />
);

export default Modal;
