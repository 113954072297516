import React from "react";

import { useIntl } from "react-intl";

import EmailPreview from "@mapmycustomers/shared/types/email/EmailPreview";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import getRecipientDisplayName from "@app/util/email/getRecipientDisplayName";

import messages from "../messages";

import styles from "./Preview.module.scss";

interface Props {
  preview: EmailPreview;
  recipient: EntitiesSupportedByEmailFeature;
}

const Preview: React.FC<Props> = ({ preview, recipient }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.previewText}>
        {intl.formatMessage(messages.emailPreviewRecipient, {
          recipient: getRecipientDisplayName(recipient),
        })}
      </div>
      <div className={styles.previewText}>
        {intl.formatMessage(messages.emailPreviewSubject, {
          subject: preview.html.subject,
        })}
      </div>
      <div className={styles.previewBody} dangerouslySetInnerHTML={{ __html: preview.html.body }} />
    </div>
  );
};

export default Preview;
