import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import { EntityPin } from "@mapmycustomers/shared/types/map";

import colors from "@app/styles/_colors.module.scss";
import anyColorToHex from "@app/util/colors/anyColorToHex";
import getContrastColor from "@app/util/colors/getContrastColor";

import ColorDefinition from "./types/ColorDefinition";

export const defaultColorBuilder = (color: AnyColor | null | undefined): ColorDefinition => {
  const fillColor = color ? anyColorToHex(color) : colors.slate;
  return {
    color: getContrastColor(fillColor) === "black" ? colors.slate : colors.white,
    fillColor,
  };
};

const defaultColorGetter = (entityPin: EntityPin): ColorDefinition =>
  defaultColorBuilder(entityPin.data.color);

export default defaultColorGetter;
