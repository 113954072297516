import React, { useEffect } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { LoadingSpinner, Modal } from "@mapmycustomers/ui";

import emailSent from "@app/assets/email/email-sent.svg";
import HelpText from "@app/component/typography/HelpText";
import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import {
  isEmailQuotaUpdating,
  isOrgReachEmailDayLimit,
  isOutOfEmailSendingHours,
} from "@app/store/email";
import { fetchEmailInfo, showEmailFeatures } from "@app/store/email/actions";
import { RootState } from "@app/store/rootReducer";

import EmailPeriod from "../../EmailPeriod";
import EmailSummaryInfo from "../../EmailSummaryInfo";
import messages from "../messages";

import styles from "./ConfirmationMessageModal.module.scss";

interface Props {
  emailQuotaUpdating: boolean;
  isOrgReachEmailDayLimit: boolean;
  isOutOfEmailSendingHours: boolean;
  onFetchEmailInfo: typeof fetchEmailInfo.request;
  onHide: () => void;
  onShowEmailFeatures: typeof showEmailFeatures;
  open: boolean;
}

const ConfirmationMessageModal: React.FC<Props> = ({
  emailQuotaUpdating,
  isOrgReachEmailDayLimit,
  isOutOfEmailSendingHours,
  onFetchEmailInfo,
  onHide,
  onShowEmailFeatures,
  open,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onFetchEmailInfo();
  }, [onFetchEmailInfo]);
  return (
    <Modal
      className={styles.modal}
      footer={null}
      onCancel={onHide}
      open={open}
      title={emailQuotaUpdating ? <LoadingSpinner mini /> : null}
      width="27rem"
    >
      <div className={styles.container}>
        <img alt="email-sent" className={styles.image} src={emailSent} />
        <div className={styles.title}>
          {isOrgReachEmailDayLimit || isOutOfEmailSendingHours
            ? intl.formatMessage(messages.emailIsQueued)
            : intl.formatMessage(messages.emailIsSent)}
        </div>
        <EmailPeriod onSettings={onHide} showIcon={isOutOfEmailSendingHours} />
        <div className={styles.subTitle}>
          {isOrgReachEmailDayLimit
            ? intl.formatMessage(messages.reachSendLimit)
            : isOutOfEmailSendingHours
            ? intl.formatMessage(messages.outSideSchedule, {
                a: (text) => (
                  <Link
                    onClick={onHide}
                    to={`${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`}
                  >
                    {text}
                  </Link>
                ),
              })
            : intl.formatMessage(messages.emailIsSentDescription)}
        </div>
        <EmailSummaryInfo />
        <div className={styles.bottom}>
          <HelpText
            onClick={onShowEmailFeatures}
            text={intl.formatMessage(messages.emailIsSentAboutEmails)}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailQuotaUpdating: isEmailQuotaUpdating(state),
  isOrgReachEmailDayLimit: isOrgReachEmailDayLimit(state),
  isOutOfEmailSendingHours: isOutOfEmailSendingHours(state),
});

const mapDispatchToProps = {
  onFetchEmailInfo: fetchEmailInfo.request,
  onShowEmailFeatures: showEmailFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationMessageModal);
