import FrequencyEntity from "../type/FrequencyEntity";

const getGradientColorBreakPoints = (cadenceInterval: FrequencyEntity["cadenceInterval"]) => {
  let lightGreenPercentage = 38;
  let yellowPercentage = 65;
  let redPercentage = 90;

  if (cadenceInterval) {
    if (cadenceInterval === 2) {
      yellowPercentage = 50;
    } else if (cadenceInterval > 70) {
      yellowPercentage = 100 - (14 / cadenceInterval) * 100;
    } else if (cadenceInterval > 25) {
      yellowPercentage = 80;
    } else if (cadenceInterval > 7) {
      yellowPercentage = 100 - (5 / cadenceInterval) * 100;
    } else {
      yellowPercentage = 100 - (2 / cadenceInterval) * 100;
    }
    lightGreenPercentage = yellowPercentage - 10;
    redPercentage = Math.min(yellowPercentage + 30, 100);
  }
  return [lightGreenPercentage, yellowPercentage, redPercentage];
};

export default getGradientColorBreakPoints;
