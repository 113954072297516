import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import {
  EntitiesSupportedByEmailFeature,
  EntityTypesSupportedByEmailFeature,
} from "@mapmycustomers/shared/types/map/types";

import CompanyAssociation from "@app/component/associations/CompanyAssociation";
import PeopleAssociation from "@app/component/associations/PeopleAssociation";
import { fetchSelectedEntities } from "@app/store/email/actions";

import Information from "./Information";
import messages from "./messages";

interface Props {
  entityType?: EntityTypesSupportedByEmailFeature;
  onFetchSelectedEntities: typeof fetchSelectedEntities.request;
  onHide: () => void;
  onSelect: (data: EntitiesSupportedByEmailFeature[]) => void;
  recipients: EntitiesSupportedByEmailFeature[];
  visible: boolean;
}

const AddRecipientsModal: React.FC<Props> = ({
  entityType,
  onFetchSelectedEntities,
  onHide,
  onSelect,
  recipients,
  visible,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelect = useCallback(
    (entityIds: EntitiesSupportedByEmailFeature["id"][]) => {
      if (entityType) {
        setLoading(true);
        onFetchSelectedEntities({
          callback: (data) => {
            setLoading(false);
            onHide();
            onSelect(data);
          },
          entityIds,
          entityType,
        });
      }
    },
    [entityType, onFetchSelectedEntities, onHide, onSelect]
  );

  const getListTitle = useCallback(
    (count: number, total: number) =>
      intl.formatMessage(messages.recordWithEmail, { count, total }),
    [intl]
  );

  return (
    <>
      {visible && entityType === EntityType.COMPANY && (
        <CompanyAssociation
          allowAdd={false}
          assignedCompanies={recipients as Company[]}
          entityType={entityType}
          getListTitle={getListTitle}
          hideOnSelect={false}
          info={<Information />}
          okLoading={loading}
          okText={intl.formatMessage(messages.okText)}
          onHide={onHide}
          onSelect={handleSelect}
          requestFilters={[{ email: { [PlatformFilterOperator.NOT_EQUAL]: null } }]}
        />
      )}
      {visible && entityType === EntityType.PERSON && (
        <PeopleAssociation
          allowAdd={false}
          assignedPeople={recipients as Person[]}
          getListTitle={getListTitle}
          hideOnSelect={false}
          info={<Information />}
          okLoading={loading}
          okText={intl.formatMessage(messages.okText)}
          onHide={onHide}
          onSelect={handleSelect}
          requestFilters={[{ email: { [PlatformFilterOperator.NOT_EQUAL]: null } }]}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  onFetchSelectedEntities: fetchSelectedEntities.request,
};

export default connect(null, mapDispatchToProps)(AddRecipientsModal);
