import { connect } from "react-redux";

import { RootState } from "@app/store/rootReducer";

import { getAssociatedFiles } from "../store";

import LockedFiles from "./LockedFormFields/LockedFiles";

const mapStateToPropsForLocked = (state: RootState) => ({
  files: getAssociatedFiles(state),
});

const LockedFile = connect(mapStateToPropsForLocked)(LockedFiles);

export default LockedFile;
