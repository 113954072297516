import { defineMessages } from "react-intl";

const messages = defineMessages({
  allRecords: {
    id: "associations.allRecords",
    defaultMessage: "All records ({count}/{total})",
    description: "Text for all records association list",
  },
  associated: {
    id: "associations.associated",
    defaultMessage: "Associated ({suggested}/{total})",
    description: "Text for associated entities list",
  },
  cantFind: {
    id: "associations.cantFind",
    defaultMessage: "Can’t find what you’re looking for?",
    description: "Can't find record text",
  },
  noRecords: {
    id: "associations.noRecords",
    defaultMessage: "No matching records found",
    description: "Text for no matching records found",
  },
  showAll: {
    id: "associations.showAll",
    defaultMessage: "Show all records",
    description: "Show all records text",
  },
});

export default messages;
