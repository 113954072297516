import { createReducer } from "typesafe-actions";

import { EntityType } from "@mapmycustomers/shared/enum";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";

import {
  applyGroupsMapViewSettings,
  enterGroupMode,
  exitGroupLassoMode,
  fetchDataViewEntities,
  fetchGroup,
  fetchGroupLassoSelection,
  fetchGroupOtherPins,
  fetchGroupPins,
  fetchGroupRecords,
  GroupModeActions,
  initializeGroupsMode,
  setGroupEditFormHasChanges,
  setGroupShowOtherPins,
  updateGroup,
  updateGroupSharing,
} from "@app/scene/map/store/groupMode/actions";
import GroupModeState from "@app/scene/map/store/groupMode/GroupModeState";
import { MAP_RECORDS_PAGE_SIZE } from "@app/scene/map/utils/consts";

export const groupsModeInitialState: GroupModeState = {
  categorizedMapEntries: {
    clusters: [],
    entities: [],
    multiPins: [],
  },
  categorizedOtherPins: {
    clusters: [],
    entities: [],
    multiPins: [],
  },
  dataViewEntities: [],
  dataViewLoading: false,
  editFormHasChanges: false,
  lassoRecords: [],
  lassoRecordsLoading: false,
  loading: false,
  pinsCount: 0, // used by group pins
  records: [],
  recordsCount: 0, // used by records list
  recordsLoading: false,
  showOtherPins: false,
  viewState: {
    columns: [],
    filter: {},
    range: { endRow: MAP_RECORDS_PAGE_SIZE, startRow: 0 }, // used by records list
    search: "", // used by records list
    sidebarVisible: false,
    sort: [], // used by records list
    tool: undefined,
    viewAs: undefined,
    visibleEntities: [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL], // doesn't mean anything in groups mode
  },
};

const groups = createReducer<GroupModeState, GroupModeActions>(groupsModeInitialState)
  .handleAction(enterGroupMode, (state, { payload }) => ({
    ...state,
    // use group from the enterGroupMode action if it is specified
    candidateGroup: payload.group,
  }))
  .handleAction(initializeGroupsMode.success, (state, { payload }) => ({
    ...state,
    categorizedMapEntries: groupsModeInitialState.categorizedMapEntries,
    categorizedOtherPins: groupsModeInitialState.categorizedOtherPins,
    editFormHasChanges: false,
    entityType: payload.entityType,
    group: payload.group,
    viewState: {
      ...state.viewState,
      filter: {
        ...state.viewState.filter,
        [payload.entityType]: {
          ...state.viewState.filter[payload.entityType],
          groups: {
            operator: FilterOperator.IN_ANY,
            value: [payload.group.id],
          },
        },
      },
      search: "",
      selectedSavedFilterId: undefined,
    },
  }))
  .handleAction(fetchGroupPins.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(applyGroupsMapViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      columns: payload.columns ?? state.viewState.columns,
      filter: payload.filter
        ? {
            ...state.viewState.filter,
            ...payload.filter,
          }
        : state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
      selectedSavedFilterId:
        "selectedSavedFilterId" in payload
          ? payload.selectedSavedFilterId
          : state.viewState.selectedSavedFilterId,
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
      visibleEntities: payload.visibleEntities ?? state.viewState.visibleEntities,
    },
  }))
  .handleAction(fetchGroupPins.success, (state, { payload: { pinsCount, ...mapEntries } }) => ({
    ...state,
    categorizedMapEntries: mapEntries,
    loading: false,
    pinsCount,
  }))
  .handleAction(fetchGroupPins.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchGroupOtherPins.success, (state, { payload }) => ({
    ...state,
    categorizedOtherPins: payload,
  }))
  .handleAction(fetchGroupRecords.request, (state, { payload }) => ({
    ...state,
    recordsLoading: true,
    viewState: {
      ...state.viewState,
      range: payload.range ?? state.viewState.range,
      search: payload.search !== undefined ? payload.search : state.viewState.search,
      sort: payload.sort ?? state.viewState.sort,
    },
  }))
  .handleAction(fetchGroupRecords.success, (state, { payload: { records, recordsCount } }) => ({
    ...state,
    records,
    recordsCount,
    recordsLoading: false,
  }))
  .handleAction(exitGroupLassoMode, (state) => ({
    ...state,
    lassoRecords: groupsModeInitialState.lassoRecords,
    lassoRecordsLoading: false,
  }))
  .handleAction(fetchGroupLassoSelection.request, (state) => ({
    ...state,
    lassoRecords: [],
    lassoRecordsLoading: true,
  }))
  .handleAction(fetchGroupLassoSelection.success, (state, { payload: { records } }) => ({
    ...state,
    lassoRecords: records,
    lassoRecordsLoading: false,
  }))
  .handleAction(fetchGroupLassoSelection.failure, (state) => ({
    ...state,
    lassoRecordsLoading: false,
  }))
  .handleAction([updateGroup.request, updateGroupSharing.request], (state) => ({
    ...state,
    updateLoading: true,
  }))
  .handleAction([updateGroup.success, updateGroupSharing.success], (state, { payload }) => ({
    ...state,
    editFormHasChanges: false,
    group: payload,
    updateLoading: false,
  }))
  .handleAction([updateGroup.failure, updateGroupSharing.failure], (state) => ({
    ...state,
    updateLoading: false,
  }))
  .handleAction(fetchGroup.success, (state, { payload }) => ({
    ...state,
    group: payload,
  }))
  .handleAction(fetchDataViewEntities.request, (state) => ({
    ...state,
    dataViewLoading: true,
  }))
  .handleAction(fetchDataViewEntities.success, (state, { payload }) => ({
    ...state,
    dataViewEntities: payload,
    dataViewLoading: false,
  }))
  .handleAction(fetchDataViewEntities.failure, (state) => ({
    ...state,
    dataViewEntities: [],
    dataViewLoading: false,
  }))
  .handleAction(setGroupShowOtherPins, (state, { payload }) => ({
    ...state,
    showOtherPins: payload.showOtherPins,
  }))
  .handleAction(setGroupEditFormHasChanges, (state, { payload }) => ({
    ...state,
    editFormHasChanges: payload,
  }));

export default groups;
