import { defineMessages, IntlShape } from "react-intl";

enum DistanceUnit {
  KM = "km",
  MILE = "mi", // it's "mi" because this is what platform returns
}

const messages = defineMessages({
  [DistanceUnit.KM]: {
    id: "distanceUnit.kilometer",
    defaultMessage: "Kilometers",
    description: "Kilometer distance unit",
  },
  [DistanceUnit.MILE]: {
    id: "distanceUnit.mile",
    defaultMessage: "Miles",
    description: "Mile distance unit",
  },
});

export const getDistanceUnitDisplayName = (intl: IntlShape, unit: DistanceUnit) =>
  intl.formatMessage(messages[unit]);

export default DistanceUnit;
