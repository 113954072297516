import React from "react";

import type { IntlCache, IntlConfig, IntlShape, MessageDescriptor } from "@formatjs/intl/src/types";
import enGB from "antd/es/locale/en_GB";
import enUS from "antd/es/locale/en_US";
import esES from "antd/es/locale/es_ES";
import hiIN from "antd/es/locale/hi_IN";
import ptBR from "antd/es/locale/pt_BR";
import ruRU from "antd/es/locale/ru_RU";
import type { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import { createIntl, createIntlCache } from "react-intl";

import loadLocaleData from "../locales";

export type Intl = IntlShape<React.ReactNode>;

const localeToAntDLocaleMap = {
  en: enUS,
  "en-AU": enGB,
  "en-GB": enGB,
  "en-NZ": enGB,
  es: esES,
  hi: hiIN,
  pt: ptBR,
  ru: ruRU,
} as const;

/**
 * I18nService is the place where current intl instance is created and stored.
 * We could store it right in the Redux store, but it's not the best place for
 * complex instances. Also see:
 * https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
 */
class I18nService {
  private readonly _intlCache: IntlCache;
  private _intl: Intl | undefined;
  private _locale: string;

  constructor() {
    this._intlCache = createIntlCache();
    this._locale = process.env.REACT_APP_DEFAULT_LOCALE as string;
  }

  getIntl = (): Intl | undefined => this._intl;

  formatMessage = (
    descriptor: MessageDescriptor,
    defaultMessage: string = "",
    values?: Record<string, FormatXMLElementFn<string, string> | PrimitiveType>
  ): string => {
    if (!this._intl) {
      return defaultMessage;
    }

    return this._intl.formatMessage(descriptor, values);
  };

  changeLocale = async (locale: string): Promise<Intl> => {
    const messages = await loadLocaleData(locale);
    return this.createIntl({ locale, messages });
  };

  getAntDLocale = (locale: string) =>
    localeToAntDLocaleMap[locale as keyof typeof localeToAntDLocaleMap] ||
    localeToAntDLocaleMap[
      process.env.REACT_APP_DEFAULT_LOCALE as keyof typeof localeToAntDLocaleMap
    ];

  private createIntl = (config: IntlConfig<React.ReactNode>): Intl => {
    this._intl = createIntl(config, this._intlCache);
    return this._intl;
  };
}

const i18nService = new I18nService();

export default i18nService;
