@use "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .modal {
        :global(.ant-modal-body) {
          padding-top: 2rem;
        }
      }
      .errorTitle {
        color: colors.$mmc-slate;
        margin-bottom: 0.5rem;
      }
      .errorDescription {
        color: colors.$mmc-slate;
        font-size: 0.875rem;
      }
    }
  }
}
