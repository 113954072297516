import React from "react";

import AntDLayout from "antd/es/layout";

import { FrigadeRouter } from "@app/component/frigade";
import GlobalSearch from "@app/component/GlobalSearch";
import NotificationCadenceModal from "@app/component/notification/NotificationCadenceModal";

import EmailCreation from "../email/creation";
import EmailFeaturesModal from "../email/EmailFeaturesModal";
import NavigationBar from "../NavigationBar";

import FrequencyModalWrapper from "./components/FrequencyModalWrapper";
import PreviewEditPanes from "./components/PreviewEditPanes";
import SandboxInfoBar from "./components/SandboxInfoBar";
import TrialExpiration from "./components/TrialExpiration";
import UserLocationRequest from "./components/UserLocationRequest";
import styles from "./Layout.module.scss";

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <AntDLayout className={styles.container}>
      <NavigationBar />
      <TrialExpiration />
      <UserLocationRequest />
      <SandboxInfoBar />
      <PreviewEditPanes />
      <NotificationCadenceModal />
      <GlobalSearch />
      <FrequencyModalWrapper />
      <FrigadeRouter />
      <EmailCreation />
      <EmailFeaturesModal />
      <AntDLayout.Content className={styles.content}>
        <div className={styles.contentWrapper}>{children}</div>
      </AntDLayout.Content>
    </AntDLayout>
  );
};

export default Layout;
