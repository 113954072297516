import { HexColor } from "@mapmycustomers/shared/types/AnyColor";

const colorPalette = [
  {
    endColor: "#005D8B" as HexColor,
    startColor: "#EFF6F9" as HexColor,
  },
  {
    endColor: "#006C31" as HexColor,
    startColor: "#EBF8FB" as HexColor,
  },
  {
    endColor: "#BF0011" as HexColor,
    startColor: "#FFEFDB" as HexColor,
  },
];

export default colorPalette;
