import { Group } from "@mapmycustomers/shared/types/entity";

import DefinedCadenced from "../FrequencyPanel/type/DefinedCadenced";
import FrequencyEntity from "../type/FrequencyEntity";

import hasFrequency from "./hasFrequency";

const getFrequencySequence = (entity: FrequencyEntity, groups?: Group[]): DefinedCadenced[] => [
  ...(hasFrequency(entity)
    ? [
        {
          cadenceDaysOut: entity.recordCadenceDaysOut ?? 0,
          cadenceInterval: entity.cadenceInterval ?? 0,
          crmActivityTypeId: entity.crmActivityTypeId ?? undefined,
        },
      ]
    : []),
  ...(groups
    ? groups.map(({ cadenceDaysOut, cadenceInterval, crmActivityTypeId }) => ({
        cadenceDaysOut: cadenceDaysOut ?? 0,
        cadenceInterval: cadenceInterval ?? 0,
        crmActivityTypeId: crmActivityTypeId ?? undefined,
      }))
    : []),
];

export default getFrequencySequence;
