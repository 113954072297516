import React, { FC, useCallback, useMemo } from "react";

import { LabeledValue, SelectProps } from "antd/es/select";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import { Company } from "@mapmycustomers/shared/types/entity";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { SelectField } from "@mapmycustomers/ui";

import EntityChip from "@app/component/EntityChip";
import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";

import styles from "./BaseCompanyFieldCommon.module.scss";

interface CompaniesSelectInputProps extends SelectProps {
  onChange?: (companies?: CompanyRef[]) => void;
  value?: CompanyRef[];
}

const CompaniesSelectInput: FC<CompaniesSelectInputProps> = ({
  disabled,
  onChange,
  value,
  ...props
}) => {
  const intl = useIntl();

  const tagRender = useCallback((props: CustomTagProps) => {
    const { label, value } = props;
    return (
      <EntityChip
        entityId={value}
        entityType={EntityType.COMPANY}
        name={label as string}
        tooltipProps={{ trigger: [] }}
      />
    );
  }, []);

  const options = useMemo<LabeledValue[]>(
    () => value?.map(({ id, name }) => ({ key: String(id), label: name, value: id })) ?? [],
    [value]
  );

  return (
    <SelectField<Company["id"][]>
      locked={disabled}
      maxTagCount="responsive"
      mode="multiple"
      options={options}
      placeholder={intl.formatMessage({
        id: "formFields.companiesSelectField.select",
        defaultMessage: "Select",
        description: "Title of the Select Parent Company modal",
      })}
      prefixIcon={
        <EntityTypeShapedIcon
          containerClassName={styles.entityIcon}
          entityType={EntityType.COMPANY}
        />
      }
      showArrow={false}
      tagRender={tagRender}
      value={value?.map(({ id }) => id)}
      {...props}
    />
  );
};

export default CompaniesSelectInput;
