import { diceCoefficient } from "dice-coefficient";

import ImportMapping from "@mapmycustomers/shared/types/imports/ImportMapping";

import FilePreview from "@app/types/FilePreview";

import ImportMappingOption from "../type/ImportMappingOption";

// Fixed threshold used to discard significantly "distant" strings.
// 0.4 is just an empirically picked number.
const SIMILARITY_THRESHOLD = 0.4;

const COLUMNS_WHITE_LIST: { [key: string]: string[] } = {
  postalcode: ["zip", "postco"],
};

// Iterates over given list of target strings and calculate similarity coefficient
// against given source string. Also applies sensitivity threshold (SIMILARITY_THRESHOLD).
// Returns index of most similar value in the target array. If not found - return -1.
function findBestMatchIndex(source: string, target: Array<string>): number {
  let i = 0;
  let bestMatch = 0;
  let bestMatchIndex = 0;

  // First do attempt to match 'white-listed' internal field name against list of external field names
  if (Object.hasOwn(COLUMNS_WHITE_LIST, source)) {
    const internalColumnName = COLUMNS_WHITE_LIST[source];
    do {
      const externalColumnName = target[i];
      if (internalColumnName.some((candidate) => externalColumnName.includes(candidate))) {
        return i;
      }
      i++;
    } while (i < target.length);
  }

  // If no match found - use regular heuristics using Dice algo
  do {
    const currentRating = diceCoefficient(source, target[i]);
    if (currentRating > bestMatch) {
      bestMatch = currentRating;
      bestMatchIndex = i;
    }
    i++;
  } while (i < target.length);

  if (bestMatch >= SIMILARITY_THRESHOLD) {
    return bestMatchIndex;
  }

  return -1;
}

const prepareColumnNameForMatching = (columnName: string): string =>
  columnName.replace(/[-\s]/gi, "").toLowerCase();

const prePopulateMapping = (
  options: ImportMappingOption[],
  filePreview?: FilePreview
): ImportMapping => {
  if (!filePreview) {
    return {};
  }

  const preparedExternalColumnNames = filePreview.headers.map(prepareColumnNameForMatching);

  return options
    .filter((option) => !(option.skip || option.value === "id" || option.separator))
    .reduce<ImportMapping>((result, option) => {
      const preparedInternalColumnName = prepareColumnNameForMatching(option.label || "");
      const matchingExternalColumnIndex = findBestMatchIndex(
        preparedInternalColumnName,
        preparedExternalColumnNames
      );
      return matchingExternalColumnIndex >= 0 &&
        !Object.values(result).includes(filePreview.headers[matchingExternalColumnIndex])
        ? Object.assign(result, {
            [option.value as string]: filePreview.headers[matchingExternalColumnIndex],
          })
        : result;
    }, {});
};

export default prePopulateMapping;
