import React from "react";

import { useIntl } from "react-intl";

import getDefaultStartDate from "@app/util/route/getDefaultStartDate";

import SeparatedDateTimePicker from "../SeparatedDateTimePicker";

const minDate = getDefaultStartDate();

interface Props {
  onChange: (value: Date) => void;
  value: Date;
}

const StartDateTime: React.FC<Props> = ({ onChange, value }) => {
  const intl = useIntl();

  return (
    <SeparatedDateTimePicker
      dateLabel={intl.formatMessage({
        id: "component.createRoute.startDate",
        defaultMessage: "* Start Date",
        description: "Start date field",
      })}
      minDate={minDate}
      onChange={onChange}
      timeLabel={intl.formatMessage({
        id: "component.createRoute.startTime",
        defaultMessage: "* Start Time",
        description: "Start time field",
      })}
      value={value}
    />
  );
};

export default StartDateTime;
