import { useMemo } from "react";

import { FormInstance } from "antd/es/form/hooks/useForm";
import { IntlShape } from "react-intl";

export const useAddressFieldValidationRules = (intl: IntlShape, form: FormInstance) =>
  useMemo(
    () => [
      {
        validator: () => {
          const { addressDetails } = form.getFieldsValue();

          const hasAddress =
            addressDetails &&
            (addressDetails.address?.trim() ||
              addressDetails.city?.trim() ||
              addressDetails.country?.trim() ||
              addressDetails.countryCode?.trim() ||
              addressDetails.region?.trim() ||
              addressDetails.regionCode?.trim() ||
              addressDetails.postalCode?.trim());

          if (hasAddress) {
            return Promise.resolve();
          }

          return Promise.reject(
            intl.formatMessage({
              id: "validation.address.message",
              defaultMessage: "Please enter valid address",
              description: "Validation message for coordinates fields - address",
            })
          );
        },
      },
    ],
    [intl, form]
  );
