import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

const mapActivityStatusOptionToFilter: Record<ActivityStatusOption, Partial<FilterModel>> = {
  [ActivityStatusOption.ALL]: {
    completed: undefined,
    startAt: undefined,
  },
  [ActivityStatusOption.COMPLETED]: {
    completed: { operator: FilterOperator.IS_TRUE, value: undefined },
    startAt: undefined,
  },
  [ActivityStatusOption.INCOMPLETE]: {
    completed: { operator: FilterOperator.IS_FALSE, value: undefined },
    startAt: undefined,
  },
  [ActivityStatusOption.NOT_SCHEDULED]: {
    completed: undefined,
    startAt: { operator: FilterOperator.EMPTY, value: null },
  },
  [ActivityStatusOption.OVERDUE]: {
    completed: { operator: FilterOperator.IS_FALSE, value: undefined },
    startAt: { operator: FilterOperator.BEFORE, value: new Date().toISOString() },
  },
  [ActivityStatusOption.UPCOMING]: {
    completed: { operator: FilterOperator.IS_FALSE, value: undefined },
    startAt: { operator: FilterOperator.AFTER, value: new Date().toISOString() },
  },
};

export default mapActivityStatusOptionToFilter;
