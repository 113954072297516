import React, { ReactNode, useCallback } from "react";

import { CardCollapse, CollapsePanel } from "@mapmycustomers/ui";

const PANEL_KEY = "frequency-collapse";

interface Props {
  children: ReactNode;
  className?: string;
  header: ReactNode;
  isOpenByDefault?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

const FrequencyCollapsePanel: React.FC<Props> = ({
  children,
  className,
  header,
  isOpenByDefault,
  onClose,
  onOpen,
}) => {
  const handleChange = useCallback(
    (key: string | string[]) => {
      if (Array.isArray(key) && key.length) {
        onOpen?.();
      } else {
        onClose?.();
      }
    },
    [onClose, onOpen]
  );
  return (
    <CardCollapse
      className={className}
      defaultActiveKey={isOpenByDefault ? PANEL_KEY : undefined}
      onChange={handleChange}
      size="small"
    >
      <CollapsePanel header={header} key={PANEL_KEY}>
        <div>{children}</div>
      </CollapsePanel>
    </CardCollapse>
  );
};

export default FrequencyCollapsePanel;
