import { ReactNode } from "react";

const defaultFilterOption = (
  inputValue: string,
  option?: { label?: ReactNode; text?: string }
) =>
  (option?.text ?? (option?.label as string))
    ?.toLowerCase()
    .includes(inputValue.toLowerCase().trim()) ?? false;

export default defaultFilterOption;
