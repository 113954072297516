import { createReducer } from "typesafe-actions";

import { Actions, createNote } from "./actions";

export interface AddNoteModalState {
  createLoading: boolean;
}

const initialState: AddNoteModalState = {
  createLoading: false,
};

const addNoteModal = createReducer<AddNoteModalState, Actions>(initialState)
  .handleAction(createNote.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction([createNote.success, createNote.failure], (state) => ({
    ...state,
    createLoading: false,
  }));

export * from "./selectors";
export type AddNoteModalActions = Actions;
export default addNoteModal;
