enum ActivityStatusOption {
  ALL = "all",
  OVERDUE = "overdue",
  COMPLETED = "Complete",
  INCOMPLETE = "Incomplete",
  UPCOMING = "upcoming",
  NOT_SCHEDULED = "notScheduled",
}

export default ActivityStatusOption;
