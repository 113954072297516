import { endOfMonth, endOfQuarter, isBefore, subMonths, subQuarters } from "date-fns/esm";

import { formatDate } from "@app/util/formatters";

import ForecastColumnType from "../enum/ForecastColumnType";
import ForecastViewMode from "../enum/ForecastViewMode";

const formatDateAsColumnKey = (viewMode: ForecastViewMode, date?: Date): string => {
  if (!date) {
    return ForecastColumnType.NO_CLOSING_DATE;
  } else if (
    viewMode === ForecastViewMode.MONTH
      ? isBefore(date, endOfMonth(subMonths(Date.now(), 1)))
      : isBefore(date, endOfQuarter(subQuarters(Date.now(), 1)))
  ) {
    return ForecastColumnType.PAST_CLOSING_DATE;
  }
  return formatDate(date, viewMode === ForecastViewMode.MONTH ? "M-yyyy" : "q-yyyy");
};

export default formatDateAsColumnKey;
