import React, { MouseEventHandler } from "react";

import { faCircleDownRight } from "@fortawesome/pro-duotone-svg-icons/faCircleDownRight";
import { faCircleMinus } from "@fortawesome/pro-duotone-svg-icons/faCircleMinus";
import { faCircleUpRight } from "@fortawesome/pro-duotone-svg-icons/faCircleUpRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useIntl } from "react-intl";

import { isDefined } from "@mapmycustomers/shared/util/assert";

import styles from "./Trend.module.scss";

interface Props {
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  trend?: number;
}

const Trend: React.FC<Props> = ({ onMouseEnter, onMouseLeave, trend }) => {
  const intl = useIntl();

  if (!isDefined(trend)) {
    return null;
  }

  const isZero = Math.abs(trend) < Number.EPSILON;

  return (
    <div
      className={cn(styles.container, {
        [styles.negative]: trend < 0,
        [styles.neutral]: isZero,
        [styles.positive]: trend > 0,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {intl.formatNumber(trend, {
        maximumFractionDigits: 0,
        signDisplay: "exceptZero",
        style: "percent",
      })}

      <FontAwesomeIcon
        icon={isZero ? faCircleMinus : trend > 0 ? faCircleUpRight : faCircleDownRight}
      />
    </div>
  );
};

export default Trend;
