@use "../../../styles/colors";

.container {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  color: colors.$mmc-slate;
  background-color: colors.$mmc-yellow-025;
}

.allEntitiesHaveValues {
  color: colors.$mmc-primary;
  background-color: colors.$mmc-primary-010;
}
