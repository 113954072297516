import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import Organization from "@mapmycustomers/shared/types/Organization";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization } from "@app/store/iam";
import { downloadFileByUrl } from "@app/util/file";

import { downloadFile, fetchPreview, removeFile } from "./actions";

export function* onFetchPreview({ payload }: ReturnType<typeof fetchPreview.request>) {
  try {
    const { entityId, entityType, fileId } = payload;
    const org: Organization = yield select(getOrganization);
    const fileData: Blob = yield callApi(
      "fetchFile",
      org.id,
      fileId,
      false,
      true,
      entityType,
      entityId,
      { responseType: "blob" }
    );
    yield put(fetchPreview.success(fileData));
  } catch (error) {
    yield put(fetchPreview.failure());
    yield put(handleError({ error }));
  }
}

export function* onDownloadFile({ payload }: ReturnType<typeof downloadFile>) {
  try {
    const { entityId, entityType, file } = payload;
    const org: Organization = yield select(getOrganization);
    const fileBlob: Blob = yield callApi(
      "fetchFile",
      org.id,
      file.id,
      true,
      false,
      entityType,
      entityId,
      { responseType: "blob" }
    );

    yield call(downloadFileByUrl, window.URL.createObjectURL(fileBlob), file.name);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onRemoveFile({ payload }: ReturnType<typeof removeFile.request>) {
  try {
    const { callback, entityId, entityType, file } = payload;
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteEntityFile", org.id, entityType, entityId, file.id);
    callback?.(file);
    yield put(removeFile.success(file.id));
  } catch (error) {
    yield put(removeFile.failure());
    yield put(handleError({ error }));
  }
}

export function* filePreviewSaga() {
  yield takeLatest(fetchPreview.request, onFetchPreview);
  yield takeEvery(downloadFile, onDownloadFile);
  yield takeEvery(removeFile.request, onRemoveFile);
}
