import React from "react";

import Alert from "antd/es/alert";
import { useIntl } from "react-intl";

import styles from "./Information.module.scss";

interface Props {}

const Information: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <Alert
      className={styles.container}
      message={intl.formatMessage({
        id: "email.creationModal.addRecipients.information",
        defaultMessage: "Only records with emails can be added",
        description: "Add Recipients information in email creation modal",
      })}
      showIcon
    />
  );
};

export default Information;
