import { differenceInMinutes } from "date-fns/esm";

const TIME_THRESHOLD_IN_MINUTES = 5;

const getCompletedValueBasedOnStartAt = (startAt: Date): boolean | undefined => {
  let result;
  const baseDate = new Date();
  // Date considered in the past, when it earlier than current time more than 5 minutes
  const isPastDate = differenceInMinutes(baseDate, startAt) >= TIME_THRESHOLD_IN_MINUTES;
  // Date considered in the future, when it later than current time more than 5 minutes
  const isFutureDate = differenceInMinutes(startAt, baseDate) >= TIME_THRESHOLD_IN_MINUTES;

  if (isPastDate || isFutureDate) {
    result = isPastDate && !isFutureDate;
  }
  return result;
};

export default getCompletedValueBasedOnStartAt;
