import React, { useMemo } from "react";
import { connect } from "react-redux";

import { RawFile } from "@mapmycustomers/shared/types/File";
import AttachedFile from "@mapmycustomers/ui/src/EmailBody/type/AttachedFile";

import LockedFiles from "@app/component/createEditEntity/Activity/component/LockedFormFields/LockedFiles";
import { getNewEmailAttachments } from "@app/store/email";
import { RootState } from "@app/store/rootReducer";

interface Props {
  attachments: AttachedFile[];
}

const Files: React.FC<Props> = ({ attachments }) => {
  const files = useMemo(
    () =>
      attachments.reduce<RawFile[]>((result, { file }) => [...result, ...(file ? [file] : [])], []),
    [attachments]
  );

  return <LockedFiles files={files} />;
};

const mapStateToProps = (state: RootState) => ({
  attachments: getNewEmailAttachments(state),
});

export default connect(mapStateToProps)(Files);
