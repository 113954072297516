import React, { useCallback, useRef, useState } from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import { InputNumberProps } from "antd/es/input-number";
import cn from "classnames";

import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { useConfigProvider } from "../../../ConfigProvider";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import NumberField from "../../NumberField";
import Footer from "../Footer";

export const messages = {
  "ui.inlineInput.numberField.unknown": "Unknown",
};

interface InlineNumberFieldProps
  extends Omit<InputNumberProps, "className" | "onChange">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  disabled?: boolean;
  onChange?: (value?: number) => void;
  value?: number;
}

const block = bem("mmc-inline-number-field");

const InlineNumberField: React.FC<InlineNumberFieldProps> = ({
  addonBefore,
  caption,
  className,
  disabled,
  label,
  labelClassName,
  labelPosition = "side",
  onChange,
  required,
  value,
  ...props
}) => {
  const configProvider = useConfigProvider();
  const [inlineValue, setInlineValue] = useState<number | undefined>(value);

  const [editing, startEditing, cancelEditing] = useBoolean();

  const handleChange = useCallback((value: number | undefined) => setInlineValue(value), []);

  const handleSave = useCallback(() => {
    cancelEditing();
    onChange?.(inlineValue);
  }, [inlineValue, cancelEditing, onChange]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape") {
        event.stopPropagation();
        cancelEditing();
      } else if (event.key === "Enter") {
        event.stopPropagation();
        handleSave();
      }
    },
    [cancelEditing, handleSave]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const handleStartEditing = useCallback(() => {
    if (disabled) {
      return;
    }
    setInlineValue(value);
    startEditing();
    // can't focus hidden field, need to wait until it will be displayed
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [disabled, setInlineValue, startEditing, value]);

  const handleBlur = useCallback(
    (e) => {
      // To prevent blur action when cancel button is clicked as we save text onblur
      if (e.relatedTarget && e.relatedTarget.classList.contains("mmc-js-skip-save-on-blur")) {
        e.preventDefault();
        cancelEditing();
        return;
      }
      handleSave();
    },
    [cancelEditing, handleSave]
  );

  return (
    <div className={cn(block({ disabled: disabled }), className)}>
      <Labeled
        extra={disabled ? <FontAwesomeIcon className={block("lock")} icon={faLock} /> : undefined}
        label={
          <div className={block("label-item")}>
            {addonBefore}
            {label}
          </div>
        }
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        required={required}
      >
        {editing && !disabled ? (
          <NumberField
            autoFocus
            controls={false}
            disabled={disabled}
            fullWidth
            inputClassName={block("input")}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required={required}
            size="large"
            value={inlineValue}
            {...props}
          />
        ) : (
          <div className={block("value")} onClick={handleStartEditing}>
            {value ?? (
              <span className={block("unknown")}>
                {configProvider.formatMessage("ui.inlineInput.unknown")}
              </span>
            )}
          </div>
        )}
      </Labeled>
      <Footer
        caption={caption}
        disabled={disabled}
        editing={editing}
        onCancel={cancelEditing}
        onSave={handleSave}
      />
    </div>
  );
};

export default InlineNumberField;
