import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import {
  convertLatLngToLongLat,
  convertLongLatToLatLngLiteral,
} from "@mapmycustomers/shared/util/geo/GeoService";

import Cluster from "@app/util/clustering/types/Cluster";

class ClusterImpl<T> implements Cluster<T> {
  public readonly items: T[];
  public readonly coordinateGetter: (item: T) => LongLat;
  protected readonly _position?: LongLat;

  constructor(items: Iterable<T>, coordinateGetter: (item: T) => LongLat, position?: LongLat) {
    this.items = [...items];
    this.coordinateGetter = coordinateGetter;
    this._position = position;
  }

  public get bounds(): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();
    for (const item of this.items) {
      bounds.extend(convertLongLatToLatLngLiteral(this.coordinateGetter(item)));
    }
    return bounds;
  }

  public get position(): LongLat {
    return (
      this._position ??
      (this.count === 1
        ? this.coordinateGetter(this.items[0])
        : convertLatLngToLongLat(this.bounds.getCenter()))
    );
  }

  public get count() {
    return this.items.length;
  }

  /**
   * Add a marker to the cluster.
   */
  public push(item: T): void {
    this.items.push(item);
  }
}

export default ClusterImpl;
