import { Editor, Element, NodeEntry } from "slate";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const findMatchedElementTypes = (editor: Editor, type?: ElementType): [boolean, NodeEntry[]] => {
  const { selection } = editor;
  if (!selection || !type) {
    return [false, []];
  }

  const elements = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === type,
    })
  );
  return [!!elements[0], elements];
};

export default findMatchedElementTypes;
