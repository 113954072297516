import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { CsvExportFormatter } from "@mapmycustomers/shared/types/fieldModel/IField";

import { csvBoolFormatter } from "@app/util/formatters";

const csvFormatterByType: Partial<Record<FieldType, CsvExportFormatter>> = {
  [FieldType.BOOLEAN]: csvBoolFormatter,
};

export default csvFormatterByType;
