import { EntityType } from "@mapmycustomers/shared";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import { EntityTypeMap } from "@mapmycustomers/shared/types/layout/ILayoutModel";

import { DealFieldName } from "@app/util/fieldModel/DealFieldModel";

import getChildLayout from "./getChildLayout";
import LayoutModel from "./LayoutModel";

class DealLayoutModel extends LayoutModel<EntityType.DEAL> {
  constructor() {
    super(EntityType.DEAL);
  }

  hasMissingRequiredFieldsFor(entity: EntityTypeMap[EntityType.DEAL]): boolean {
    const layout = this.getLayoutFor(entity);
    const childLayout = getChildLayout(layout, {
      funnelId: entity.funnel.id,
      stageId: entity.stage.id,
    });
    const isLostStage = entity.stage.type === DealStageType.LOST;
    let missingFields = this.getMissingRequiredFieldsFor(entity);

    // We don't consider there fields missing even if they're required when deal is not in the Lost stage.
    // But we do respect them if it's a variant schema.
    if (!isLostStage && !childLayout) {
      missingFields = missingFields.filter(
        (schemaField) =>
          schemaField.field !== "dealLossReasonId" &&
          schemaField.field !== DealFieldName.DEAL_LOSS_COMMENT
      );
    }

    return missingFields.length > 0;
  }
}

export default DealLayoutModel;
