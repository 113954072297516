import React from "react";

import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import PasswordValidation from "@app/component/auth/PasswordValidation";
import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";
import SignupField from "@app/scene/auth/component/Register/enum/SignupField";
import BaseStepProps from "@app/scene/auth/component/Register/types/BaseStepProps";
import {
  useConfirmPasswordFieldValidationRules,
  usePasswordFieldValidationRules,
} from "@app/scene/auth/component/Register/utils/validation-hooks";
import { PasswordValidationResult } from "@app/util/validatePasswords";

import styles from "../../Steps.module.scss";

interface Props extends BaseStepProps {
  passwordValidationResult: PasswordValidationResult;
}

const PasswordStep: React.FC<Props> = ({
  className,
  form,
  isValid,
  onBackClick,
  onNextClick,
  passwordValidationResult,
}) => {
  const intl = useIntl();

  const passwordFieldValidationRules = usePasswordFieldValidationRules(intl);
  const confirmPasswordFieldValidationRules = useConfirmPasswordFieldValidationRules(intl, form);

  return (
    <div className={cn(styles.stepContainer, className)}>
      <p className={commonStyles.description}>
        <FormattedMessage
          id="auth.register.passwordStep.description"
          defaultMessage="This is to protect your account and data"
          description="Brief description of use of setting password"
        />
      </p>
      <Form.Item
        label={intl.formatMessage({
          id: "auth.register.passwordStep.password",
          defaultMessage: "Set Password",
          description: "Title of the password field on the register form",
        })}
        name={SignupField.PASSWORD}
        requiredMark="optional"
        rules={passwordFieldValidationRules}
      >
        <Input.Password autoComplete="new-password" size="large" spellCheck="false" />
      </Form.Item>
      <Form.Item
        dependencies={[SignupField.PASSWORD]}
        label={intl.formatMessage({
          id: "auth.register.passwordStep.confirmPassword",
          defaultMessage: "Confirm Password",
          description: "Title of the confirm Password field on the register form",
        })}
        name={SignupField.PASSWORD_CONFIRMATION}
        requiredMark="optional"
        rules={confirmPasswordFieldValidationRules}
      >
        <Input.Password autoComplete="new-password" size="large" spellCheck="false" />
      </Form.Item>
      <Form.Item>
        <PasswordValidation validationResult={passwordValidationResult} />
      </Form.Item>
      <Form.Item>
        <div className={styles.buttons}>
          <Button htmlType="button" onClick={onBackClick} type="link">
            <FormattedMessage
              id="auth.register.previousStepButton"
              defaultMessage="Go back"
              description="Title of the Go Back button for the Register Form"
            />
          </Button>
          <Button disabled={!isValid} htmlType="button" onClick={onNextClick} type="primary">
            <FormattedMessage
              id="auth.register.nextStepButton"
              defaultMessage="Next Step"
              description="Title of the Next Step button for the Register Form"
            />
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};

export default PasswordStep;
