import { useCallback, useState } from "react";

import { FormProps } from "antd/es/form";

import { Activity } from "@mapmycustomers/shared/types/entity";

import RecurringIntervalType from "../enum/RecurringIntervalType";
import FormValues from "../types/FormValues";

import { stringifyActivityInterval } from "./activityInterval";

export const useRecurIntervalChangedWarningHandler = (
  activity?: Activity
): [boolean, NonNullable<FormProps<FormValues>["onValuesChange"]>] => {
  const [isWarningVisible, setWarningVisible] = useState(false);

  const handleChange = useCallback<NonNullable<FormProps<FormValues>["onValuesChange"]>>(
    (field, allFields) => {
      if (!activity?.recurInterval) {
        return;
      }
      if (
        "recurInterval" in field ||
        ("recurIntervalType" in field && field.recurIntervalType !== RecurringIntervalType.NEVER)
      ) {
        setWarningVisible(
          stringifyActivityInterval(allFields.recurInterval) !== activity?.recurInterval
        );
      }
    },
    [activity, setWarningVisible]
  );
  return [isWarningVisible, handleChange];
};
