import React from "react";

import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faRoute } from "@fortawesome/free-solid-svg-icons/faRoute";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

import { EntityType } from "@mapmycustomers/shared/enum";

import { ReactComponent as TerritoriesIcon } from "@app/assets/map/legends/territoriesDisabled.svg";

interface Props extends Omit<FontAwesomeIconProps, "icon"> {
  entityType: EntityType;
}

const EntityTypeIcon: React.FC<Props> = ({ entityType, ...fontAwesomeIconProps }) => {
  let icon: IconDefinition | undefined = undefined;

  switch (entityType) {
    case EntityType.ACTIVITY:
      icon = faCalendarAlt;
      break;

    case EntityType.COMPANY:
      icon = faBuilding;
      break;

    case EntityType.COMPANY_GROUP:
    case EntityType.DEAL_GROUP:
    case EntityType.PEOPLE_GROUP:
      icon = faLayerGroup;
      break;

    case EntityType.COMPANY_ROUTE:
    case EntityType.PEOPLE_ROUTE:
    case EntityType.ROUTE:
      icon = faRoute;
      break;

    case EntityType.DEAL:
      icon = faHandshake;
      break;
    case EntityType.PERSON:
      icon = faUser;
      break;
    case EntityType.TERRITORY:
      return <TerritoriesIcon className={fontAwesomeIconProps.className} />;
  }

  return icon ? <FontAwesomeIcon {...fontAwesomeIconProps} icon={icon} /> : null;
};

export default EntityTypeIcon;
