import { defineMessages, IntlShape } from "react-intl";

enum SsoErrorCodes {
  USER_NOT_FOUND_IN_SAML_RESPONSE = "user_not_found_in_saml_response",
  SSO_NOT_ENABLED = "sso_not_enabled",
  INVALID_REQUEST = "invalid_request",
  SSO_APPROVAL_PENDING = "sso_approval_pending",
  SSO_APPROVAL_REJECTED = "sso_approval_rejected",
  USER_NOT_FOUND_IN_MMC = "user_not_found_in_mmc",
  USER_LIMIT_EXCEEDED = "user_limit_exceeded",
}

const ssoErrorMessages = defineMessages({
  [SsoErrorCodes.INVALID_REQUEST]: {
    id: "auth.ssoLogin.form.email.invalidRequest",
    defaultMessage:
      "Something went wrong. Please try again. If problems persist, please contact support.",
    description: "Error message for invalid request",
  },
  [SsoErrorCodes.SSO_APPROVAL_PENDING]: {
    id: "auth.ssoLogin.form.email.ssoApprovalPending",
    defaultMessage:
      "Your SSO approval is still pending. Please contact your admin directly to approve your request.",
    description: "Warning message for sso request approval pending",
  },
  [SsoErrorCodes.SSO_APPROVAL_REJECTED]: {
    id: "auth.ssoLogin.form.email.ssoApprovalRejected",
    defaultMessage: "Please contact your admin directly.",
    description: "Error message for sso request denied",
  },
  [SsoErrorCodes.SSO_NOT_ENABLED]: {
    id: "auth.ssoLogin.form.email.ssoNotEnabled",
    defaultMessage:
      "There is an issue with SSO for your organization. Please contact contact support.",
    description: "Error message for sso config not found",
  },
  [SsoErrorCodes.USER_LIMIT_EXCEEDED]: {
    id: "auth.ssoLogin.form.email.userLimitExceeded",
    defaultMessage: "A request to add more seats so you can join has been sent to your admin.",
    description: "Error message for user limit exceed",
  },
  [SsoErrorCodes.USER_NOT_FOUND_IN_MMC]: {
    id: "auth.ssoLogin.form.email.userNotFoundInMmc",
    defaultMessage: "Your organization has SSO but has not yet created an account for this email.",
    description: "Error message for sso request denied",
  },
  [SsoErrorCodes.USER_NOT_FOUND_IN_SAML_RESPONSE]: {
    id: "auth.ssoLogin.form.email.userNotFoundInSamlResponse",
    defaultMessage:
      "We couldn’t find your account. Please try again. If problems persist, please reach out to your admin or contact support.",
    description: "Warning message for sso user not found",
  },
});

const ssoErrorMessageTitles = defineMessages({
  [SsoErrorCodes.INVALID_REQUEST]: {
    id: "auth.ssoLogin.form.email.invalidRequest.title",
    defaultMessage: "Login Failed",
    description: "Error message for invalid request",
  },
  [SsoErrorCodes.SSO_APPROVAL_PENDING]: {
    id: "auth.ssoLogin.form.email.ssoApprovalPending.title",
    defaultMessage: "SSO approval is pending",
    description: "Warning message for sso request approval pending",
  },
  [SsoErrorCodes.SSO_APPROVAL_REJECTED]: {
    id: "auth.ssoLogin.form.email.ssoApprovalRejected.title",
    defaultMessage: "Your SSO request was denied",
    description: "Error message for sso request denied",
  },
  [SsoErrorCodes.SSO_NOT_ENABLED]: {
    id: "auth.ssoLogin.form.email.ssoNotEnabled.title",
    defaultMessage: "There is an issue with SSO",
    description: "Error message for sso config not found",
  },
  [SsoErrorCodes.USER_LIMIT_EXCEEDED]: {
    id: "auth.ssoLogin.form.email.userLimitExceeded.title",
    defaultMessage: "No more seats available",
    description: "Error message title for user limit exceed",
  },
  [SsoErrorCodes.USER_NOT_FOUND_IN_SAML_RESPONSE]: {
    id: "auth.ssoLogin.form.email.userNotFoundInSamlResponse.title",
    defaultMessage: "We couldn’t find your account",
    description: "Warning message for sso user not found",
  },
});

export const getSsoErrorMessage = (intl: IntlShape, errorCode: SsoErrorCodes) =>
  intl.formatMessage(ssoErrorMessages[errorCode]);

export const getSsoErrorMessageTitle = (intl: IntlShape, errorCode: SsoErrorCodes) => {
  if (errorCode !== SsoErrorCodes.USER_NOT_FOUND_IN_MMC) {
    return intl.formatMessage(ssoErrorMessageTitles[errorCode]);
  }
};

export default SsoErrorCodes;
