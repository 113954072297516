import React, { useCallback } from "react";

import { faImage } from "@fortawesome/pro-solid-svg-icons/faImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSlate } from "slate-react";

import { stopEvents } from "@mapmycustomers/shared/util/browser";

import FileUploadCallback from "../../type/FileUploadCallback";
import selectImage from "../../util/selectImage";
import ActionTooltipWrapper from "../ActionTooltipWrapper";
import styles from "../Toolbar.module.scss";

interface Props {
  onLoadFile: FileUploadCallback;
}

const InsertImage: React.FC<Props> = ({ onLoadFile }) => {
  const editor = useSlate();

  const handleAction = useCallback(() => {
    selectImage(editor, onLoadFile);
  }, [editor, onLoadFile]);

  return (
    <ActionTooltipWrapper translationString={"ui.emailBody.toolbar.image"}>
      <span className={styles.togglerButton} onClick={handleAction} onMouseDown={stopEvents}>
        <FontAwesomeIcon icon={faImage} size="xs" />
      </span>
    </ActionTooltipWrapper>
  );
};

export default InsertImage;
