import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const customerFaceTimeState = (state: RootState) => state.scene.dashboard.customerFaceTimeState;

export const getCustomerFaceTimeDrillDownViewState = createSelector(
  customerFaceTimeState,
  ({ viewState }) => viewState
);

export const getCustomerFaceTimeDrillDownTotalFilteredRecords = createSelector(
  customerFaceTimeState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getCustomerFaceTimeDrillDownTotalRecords = createSelector(
  customerFaceTimeState,
  ({ totalRecords }) => totalRecords
);

export const getCustomerFaceTimeDrillDownCustomDateRange = createSelector(
  customerFaceTimeState,
  ({ drillDownCustomDateRange }) => drillDownCustomDateRange
);

export const getCustomerFaceTimeDrillDownConfiguration = createSelector(
  customerFaceTimeState,
  ({ configuration }) => configuration
);

export const isCustomerFaceTimeDrillDownLoading = createSelector(
  customerFaceTimeState,
  ({ loading }) => loading
);
