import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";

import FileListItem from "@app/types/FileListItem";

import AnnotationData from "./AnnotationData";

export const toggleComplete = createAsyncAction(
  "@component/activityAnnotation/toggleComplete/request",
  "@component/activityAnnotation/toggleComplete/success",
  "@component/activityAnnotation/toggleComplete/failure"
)<{ activity: Activity; callback?: (activity: Activity) => void }, Activity, void>();

export const postponeActivity = createAsyncAction(
  "@component/activityAnnotation/postpone/request",
  "@component/activityAnnotation/postpone/success",
  "@component/activityAnnotation/postpone/failure"
)<{ activity: Activity; callback?: (activity: Activity) => void }, Activity, void>();

export const loadAnnotationData = createAsyncAction(
  "@component/activityAnnotation/loadAnnotationData/request",
  "@component/activityAnnotation/loadAnnotationData/success",
  "@component/activityAnnotation/loadAnnotationData/failure"
)<Activity["id"], AnnotationData, boolean>();

export const uploadActivityFiles = createAsyncAction(
  "@component/activityAnnotation/uploadActivityFiles/request",
  "@component/activityAnnotation/uploadActivityFiles/success",
  "@component/activityAnnotation/uploadActivityFiles/failure"
)<
  {
    callback?: (filesList: FileListItem[]) => void;
    fileGroupId?: string;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const deleteActivityFile = createAsyncAction(
  "@component/activityAnnotation/deleteActivityFile/request",
  "@component/activityAnnotation/deleteActivityFile/success",
  "@component/activityAnnotation/deleteActivityFile/failure"
)<RawFile, RawFile, void>();

export const fetchThumbnail = createAction("@component/activityAnnotation/fetchThumbnail")<{
  callback: (data: Blob) => void;
  fileId: RawFile["id"];
}>();

export type Actions = ActionType<
  | typeof deleteActivityFile
  | typeof fetchThumbnail
  | typeof loadAnnotationData
  | typeof postponeActivity
  | typeof toggleComplete
  | typeof uploadActivityFiles
>;
