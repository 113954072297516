import { defineMessages, IntlShape } from "react-intl";

import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";

import activityIcon from "@app/assets/associations/activity.svg";
import childCompanyIcon from "@app/assets/associations/childCompany.svg";
import companyIcon from "@app/assets/associations/company.svg";
import dealIcon from "@app/assets/associations/deal.svg";
import groupIcon from "@app/assets/associations/group.svg";
import personIcon from "@app/assets/associations/person.svg";
import routeIcon from "@app/assets/associations/route.svg";

import messages from "../messages";

enum PostCreationAssociationTypes {
  ADD_ACTIVITY = "addActivity",
  ADD_CHILD_COMPANY = "childCompany",
  ADD_COMPANIES = "addCompanies",
  ADD_COMPANY = "addCompany",
  ADD_DEAL = "addDeal",
  ADD_GROUP = "addGroup",
  ADD_PARENT_COMPANY = "addParentCompany",
  ADD_PERSON = "addPerson",
  ADD_ROUTE = "addRoute",
  CHANGE_COMPANIES = "changeCompanies",
  CHANGE_COMPANY = "changeCompany",
  CHANGE_PARENT_COMPANY = "changeParentCompany",
  CHANGE_PERSON = "changePerson",
}

export const associationTypes = [
  PostCreationAssociationTypes.ADD_ACTIVITY,
  PostCreationAssociationTypes.ADD_CHILD_COMPANY,
  PostCreationAssociationTypes.ADD_COMPANY,
  PostCreationAssociationTypes.ADD_COMPANIES,
  PostCreationAssociationTypes.ADD_DEAL,
  PostCreationAssociationTypes.ADD_GROUP,
  PostCreationAssociationTypes.ADD_PARENT_COMPANY,
  PostCreationAssociationTypes.ADD_PERSON,
  PostCreationAssociationTypes.ADD_ROUTE,
  PostCreationAssociationTypes.CHANGE_COMPANY,
  PostCreationAssociationTypes.CHANGE_COMPANIES,
  PostCreationAssociationTypes.CHANGE_PERSON,
  PostCreationAssociationTypes.CHANGE_PARENT_COMPANY,
];

const icon = {
  [PostCreationAssociationTypes.ADD_ACTIVITY]: activityIcon,
  [PostCreationAssociationTypes.ADD_CHILD_COMPANY]: childCompanyIcon,
  [PostCreationAssociationTypes.ADD_COMPANIES]: companyIcon,
  [PostCreationAssociationTypes.ADD_COMPANY]: companyIcon,
  [PostCreationAssociationTypes.ADD_DEAL]: dealIcon,
  [PostCreationAssociationTypes.ADD_GROUP]: groupIcon,
  [PostCreationAssociationTypes.ADD_PARENT_COMPANY]: companyIcon,
  [PostCreationAssociationTypes.ADD_PERSON]: personIcon,
  [PostCreationAssociationTypes.ADD_ROUTE]: routeIcon,
  [PostCreationAssociationTypes.CHANGE_COMPANIES]: companyIcon,
  [PostCreationAssociationTypes.CHANGE_COMPANY]: companyIcon,
  [PostCreationAssociationTypes.CHANGE_PARENT_COMPANY]: companyIcon,
  [PostCreationAssociationTypes.CHANGE_PERSON]: personIcon,
};

const text = defineMessages({
  [PostCreationAssociationTypes.ADD_ACTIVITY]: messages.addActivity,
  [PostCreationAssociationTypes.ADD_CHILD_COMPANY]: messages.addChildCompany,
  [PostCreationAssociationTypes.ADD_COMPANIES]: messages.addCompanies,
  [PostCreationAssociationTypes.ADD_COMPANY]: messages.addCompany,
  [PostCreationAssociationTypes.ADD_DEAL]: messages.addDeal,
  [PostCreationAssociationTypes.ADD_GROUP]: messages.addGroup,
  [PostCreationAssociationTypes.ADD_PARENT_COMPANY]: messages.addParentCompany,
  [PostCreationAssociationTypes.ADD_PERSON]: messages.addPerson,
  [PostCreationAssociationTypes.ADD_ROUTE]: messages.addRoute,
  [PostCreationAssociationTypes.CHANGE_COMPANIES]: messages.changeCompanies,
  [PostCreationAssociationTypes.CHANGE_COMPANY]: messages.changeCompany,
  [PostCreationAssociationTypes.CHANGE_PARENT_COMPANY]: messages.changeParentCompany,
  [PostCreationAssociationTypes.CHANGE_PERSON]: messages.changePerson,
});

export const getCardsParams = (
  intl: IntlShape,
  type: PostCreationAssociationTypes,
  entityType: EntityType,
  prefilledValues: Company | Deal | Person
) => {
  let messageToShow;
  const prefilledPerson =
    entityType === EntityType.PERSON ? (prefilledValues as Person) : undefined;
  switch (type) {
    case PostCreationAssociationTypes.ADD_CHILD_COMPANY:
      messageToShow = entityType === EntityType.COMPANY && intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.ADD_COMPANIES:
      messageToShow =
        entityType === EntityType.PERSON &&
        (!prefilledPerson?.accounts || prefilledPerson?.accounts.length === 0) &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.ADD_COMPANY:
      messageToShow =
        entityType === EntityType.DEAL &&
        !(prefilledValues as Deal).account &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.ADD_DEAL:
      messageToShow = entityType !== EntityType.DEAL && intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.ADD_PARENT_COMPANY:
      messageToShow =
        entityType === EntityType.COMPANY &&
        !(prefilledValues as Company).parentAccount &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.ADD_PERSON:
      messageToShow =
        (entityType === EntityType.COMPANY ||
          (entityType === EntityType.DEAL && !(prefilledValues as Deal).contact)) &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.ADD_ROUTE:
      messageToShow =
        entityType !== EntityType.DEAL &&
        (prefilledValues as Company | Person).address &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_COMPANIES:
      messageToShow =
        entityType === EntityType.PERSON &&
        prefilledPerson?.accounts &&
        prefilledPerson.accounts.length > 0 &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_COMPANY:
      messageToShow =
        entityType === EntityType.DEAL &&
        (prefilledValues as Deal).account &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_PARENT_COMPANY:
      messageToShow =
        entityType === EntityType.COMPANY &&
        (prefilledValues as Company).parentAccount &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_PERSON:
      messageToShow =
        entityType === EntityType.DEAL &&
        (prefilledValues as Deal).contact &&
        intl.formatMessage(text[type]);
      break;
    default:
      messageToShow = intl.formatMessage(text[type]);
  }

  const iconToShow = icon[type];
  const cardToShow = !!messageToShow;
  return { cardToShow, iconToShow, messageToShow };
};
export default PostCreationAssociationTypes;
