import {
  Activity,
  AnyEntity,
  Company,
  Deal,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";

import { formatRawDate, userDisplayName, userEmailishName } from "@app/util/formatters";
import { parseApiDateWithUtcTz } from "@app/util/parsers";

import ExportType from "./ExportType";

const boolToText = (value?: boolean) => (value ? "Yes" : value === false ? "No" : "");

const getDataRowFromEntity = (
  exportType: ExportType,
  entity: AnyEntity,
  userTeamsById: Record<User["id"], string> = {}
): string[] | undefined => {
  switch (exportType) {
    case ExportType.ACTIVITIES: {
      const activity = entity as Activity;
      return [
        activity.name,
        activity.assignee ? activity.assignee.username : "",
        activity.assignee?.fullName ?? "",
        activity.assignee ? userTeamsById[activity.assignee.id] ?? "" : "",
        boolToText(activity.completed),
        formatRawDate(activity.completedAt, "Pp"),
        activity.crmActivityType.name,
        activity.note,
        formatRawDate(activity.startAt, "P").replaceAll(",", ""),
        formatRawDate(activity.startAt, "p"),
        formatRawDate(activity.endAt, "P").replaceAll(",", ""),
        formatRawDate(activity.endAt, "p"),
        userDisplayName(activity.user),
        activity.user.username,
        userTeamsById[activity.user.id] ?? "",
        activity.reliability != null ? boolToText(activity.reliability) : "",
        activity.account?.name ?? "",
        activity.contact?.name ?? "",
        activity.geoPointCheckin?.coordinates?.join(",") ?? "",
        activity.distanceBetweenCheckInAndEntity,
        activity.teams.map(({ name }) => name).join(", "),
      ].map((item) => (typeof item === "string" ? `"${item}"` : String(item ?? "")));
    }

    case ExportType.COMPANIES: {
      const company = entity as Company;
      return [
        company.name,
        company.annualRevenue,
        company.website,
        company.phone,
        company.numEmployees,
        company.address,
        company.city,
        company.region,
        company.country,
        userDisplayName(company.user),
        company.user.username,
        company.teams.map(({ name }) => name).join(", "),
      ].map((item) => (typeof item === "string" ? `"${item}"` : String(item ?? "")));
    }

    case ExportType.DEALS: {
      const deal = entity as Deal;
      return [
        deal.name,
        deal.amount,
        userDisplayName(deal.user),
        deal.user.username,
        deal.stage.name,
        deal.stage.color,
        formatRawDate(deal.closingDate, "P", "", parseApiDateWithUtcTz),
        formatRawDate(deal.createdAt, "Pp"),
        deal.account?.name,
        deal.account?.address,
        deal.account?.city,
        deal.account?.region,
        deal.account?.postalCode,
        deal.account?.id,
        deal.teams.map(({ name }) => name).join(", "),
      ].map((item) => (typeof item === "string" ? `"${item}"` : String(item ?? "")));
    }

    case ExportType.PEOPLE: {
      const person = entity as Person;
      return [
        person.firstName,
        person.lastName,
        person.phone,
        person.email,
        person.address,
        person.city,
        person.region,
        person.country,
        userDisplayName(person.user),
        person.user.username,
        person.teams.map(({ name }) => name).join(", "),
      ].map((item) => (typeof item === "string" ? `"${item}"` : String(item ?? "")));
    }

    case ExportType.ROUTES: {
      const route = entity as Route;
      return [
        route.name,
        route.routeDetail.startAddress,
        route.routeDetail.startAddress,
        formatRawDate(route.routeDetail.createdAt, "Pp"),
        userEmailishName(route.user),
      ].map((item) => (typeof item === "string" ? `"${item}"` : String(item ?? "")));
    }
  }
};

export default getDataRowFromEntity;
