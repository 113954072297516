import { MessageDescriptor } from "@formatjs/intl/src/types";
import type { FormItemProps } from "antd";

import { isDefined } from "@mapmycustomers/shared/util/assert";

import i18nService from "@app/config/I18nService";

import getValidationErrors from "./getValidationErrors";
import getErrorMessageByCode from "./platformErrorCodes";

export const getFieldValidationProps = (
  message?: string
): Partial<Pick<FormItemProps, "help" | "validateStatus">> => ({
  ...(message
    ? {
        help: message,
        validateStatus: "error",
      }
    : {}),
});

// returns a map of field name to an error message {[fieldName: string]: string}
const getFieldValidationMessages = (
  error: unknown,
  validationErrorMessages?: { [key: string]: MessageDescriptor }
): Record<string, string> => {
  const intl = i18nService.getIntl();
  const validationErrors = getValidationErrors(error);
  if (!validationErrors.length) {
    return {};
  }

  return validationErrors
    .filter(({ field }) => isDefined(field))
    .reduce(
      (result, error) => ({
        ...result,
        [error.field]: !intl
          ? error.message
          : (validationErrorMessages?.[error.code]
              ? intl.formatMessage(validationErrorMessages[error.code])
              : getErrorMessageByCode(intl, error.code)) ?? error.message,
      }),
      {}
    );
};

export default getFieldValidationMessages;
