import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import DateRangeType from "@mapmycustomers/shared/enum/DateRangeType";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { MapEntry } from "@mapmycustomers/shared/types/map";
import PersistentHeatMapLayerData from "@mapmycustomers/shared/types/persistent/PersistentHeatMapLayerData";
import DateRange, { StandardRange } from "@mapmycustomers/shared/types/range/DateRange";

import HeatMapLayerData from "@app/scene/map/store/layers/heatMap/HeatMapLayerData";

export const fetchHeatMapData = createAsyncAction(
  "map/heatmap/fetchData/request",
  "map/heatmap/fetchData/success",
  "map/heatmap/fetchData/failure"
)<void, MapEntry[], unknown>();

export const updateHeatMapDateRange = createAction("map/heatmap/updateHeatMapDateRange")<{
  dateRange: DateRange | undefined;
  dateRangeType: DateRangeType.ALL | DateRangeType.CUSTOM | StandardRange;
}>();

export const updateHeatMapType = createAction("map/heatmap/updateHeatMapType")<
  HeatMapLayerData["type"]
>();

export const updateHeatMapActivityTypes = createAction("map/heatmap/updateHeatMapActivityTypes")<
  ActivityType["id"][]
>();

export const updateHeatMapFunnels = createAction("map/heatmap/updateHeatMapFunnels")<
  Funnel["id"][]
>();

export const updateHeatMapStageTypes = createAction("map/heatmap/updateHeatMapStageTypes")<
  DealStageType[]
>();

export const updateHeatMapOpenStages = createAction("map/heatmap/updateHeatMapOpenStages")<
  Stage["id"][]
>();

export const updateHeatMapRange = createAction("map/heatmap/updateHeatMapRange")<{
  max: number;
  min: number;
}>();

export const setHeatMapSettings = createAction("map/heatmap/setHeatMapSettings")<
  PersistentHeatMapLayerData | undefined
>();

export type HeatMapActions = ActionType<
  | typeof fetchHeatMapData
  | typeof setHeatMapSettings
  | typeof updateHeatMapActivityTypes
  | typeof updateHeatMapDateRange
  | typeof updateHeatMapFunnels
  | typeof updateHeatMapOpenStages
  | typeof updateHeatMapRange
  | typeof updateHeatMapStageTypes
  | typeof updateHeatMapType
>;
