import React, { useMemo } from "react";

import partition from "lodash-es/partition";
import { defineMessages, useIntl } from "react-intl";

import AccessStatus from "@mapmycustomers/shared/types/entity/common/AccessStatus";
import { RawFile } from "@mapmycustomers/shared/types/File";

import isMediaFile from "@app/component/FilePreview/isMediaFile";
import Section from "@app/component/preview/components/Section";

import styles from "./FilesContent.module.scss";
import NonViewableFileItems from "./NonViewableFileItems";
import ViewableFileItems from "./ViewableFileItems";

const messages = defineMessages({
  mediaSectionTitle: {
    id: "filesContent.files.section.media",
    defaultMessage: "MEDIA",
    description: "Media files section on files on record pane",
  },
  minimumOneFileRequiredTooltip: {
    id: "filesContent.files.tooltip.minimumOneFileRequiredTooltip",
    defaultMessage: "At least one file is required",
    description: "Last required file delete disabled tooltip",
  },
  otherFilesSectionTitle: {
    id: "filesContent.files.section.otherFiles",
    defaultMessage: "OTHER FILES",
    description: "Other files section on files on record pane",
  },
});

interface Props {
  accessStatus?: AccessStatus;
  canDelete?: boolean;
  files: RawFile[];
  onDelete: (file: RawFile) => void;
  onDownload: (file: RawFile) => void;
  onFetchThumbnail?: (payload: { callback: (blob: Blob) => void; fileId: RawFile["id"] }) => void;
  required?: boolean;
  size?: "default" | "small";
  validateLastRequiredFileDelete?: boolean;
}

const FilesContent: React.FC<Props> = ({
  accessStatus,
  canDelete = true,
  files,
  onDelete,
  onDownload,
  onFetchThumbnail,
  required,
  size = "default",
}) => {
  const intl = useIntl();

  const [viewableFiles, nonViewableFiles] = useMemo(() => partition(files, isMediaFile), [files]);

  const viewableFilesDeleteLastFileDisabled =
    !canDelete || (required && !nonViewableFiles.length && viewableFiles.length === 1);
  const nonViewableFilesDeleteLastFileDisabled =
    !canDelete || (required && !viewableFiles.length && nonViewableFiles.length === 1);

  return (
    <>
      {viewableFiles.length > 0 && (
        <Section
          className={styles.section}
          headerClassName={styles.header}
          title={intl.formatMessage(messages.mediaSectionTitle)}
        >
          <ViewableFileItems
            accessStatus={accessStatus}
            canDelete={canDelete && !viewableFilesDeleteLastFileDisabled}
            deleteTooltip={
              viewableFilesDeleteLastFileDisabled
                ? intl.formatMessage(messages.minimumOneFileRequiredTooltip)
                : undefined
            }
            files={viewableFiles}
            onDelete={onDelete}
            onDownload={onDownload}
            onFetchThumbnail={onFetchThumbnail}
            size={size}
          />
        </Section>
      )}
      {nonViewableFiles.length > 0 && (
        <Section
          className={styles.section}
          headerClassName={styles.header}
          title={intl.formatMessage(messages.otherFilesSectionTitle)}
        >
          <NonViewableFileItems
            accessStatus={accessStatus}
            canDelete={canDelete && !nonViewableFilesDeleteLastFileDisabled}
            deleteTooltip={
              nonViewableFilesDeleteLastFileDisabled
                ? intl.formatMessage(messages.minimumOneFileRequiredTooltip)
                : undefined
            }
            nonViewableFiles={nonViewableFiles}
            onDelete={onDelete}
            onDownload={onDownload}
            size={size}
          />
        </Section>
      )}
    </>
  );
};

export default FilesContent;
