import { Descendant, Element, Text } from "slate";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import TextDecoration from "@mapmycustomers/shared/enum/textEditor/TextDecoration";
import TextSize from "@mapmycustomers/shared/enum/textEditor/TextSize";

import { cssAlignMap } from "../type/AlignElementType";

import parsePhoneNumber from "./parsePhoneNumber";

const serializeTextToHTML = (text: Text): string => {
  let result = `<span style="font-size: ${
    text.size === TextSize.LARGE ? "20px" : text.size === TextSize.SMALL ? "12px" : "14px"
  }">${text.text}</span>`;
  if (text.bold) {
    result = `<b>${result}</b>`;
  }
  if (text.italic) {
    result = `<em>${result}</em>`;
  }
  if (text.underline) {
    result = `<u>${result}</u>`;
  }
  if (text.strike) {
    result = `<span style="text-decoration: line-through">${result}</span>`;
  }
  if (text.color) {
    result = `<span style="color: ${text.color}">${result}</span>`;
  }
  if (text.decoration === TextDecoration.URL) {
    result = `<a href="${text.text}" target="_blank"><u>${result}</u></a>`;
  }
  if (text.link) {
    result = `<a href="${text.link}" target="_blank"><u>${result}</u></a>`;
  }
  if (text.decoration === TextDecoration.EMAIL) {
    result = `<a href="mailto:${text.text}" target="_blank"><u>${result}</u></a>`;
  }
  if (text.decoration === TextDecoration.PHONE) {
    const parsedPhoneNumber = parsePhoneNumber(text.text);
    const phoneNumberLink = parsedPhoneNumber?.link;

    result = `<a href="${phoneNumberLink!}" target="_blank"><u>${result}</u></a>`;
  }

  return result;
};

const serializeElementToHTML = (element: Element): string => {
  let content = element.children
    .map((child: Descendant) => {
      if (Text.isText(child)) {
        return serializeTextToHTML(child);
      } else if (Element.isElement(child)) {
        return serializeElementToHTML(child);
      }
      return "";
    })
    .join("");
  let styleSting = "";
  if (Element.isElement(element)) {
    if (element.align) {
      styleSting += `text-align: ${cssAlignMap[element.align]};`;
    }
    if (element.indent) {
      styleSting += `margin-left: ${element.indent}rem;`;
    }
    if (element.type === ElementType.BULLETED_LIST) {
      return `<ul style="${styleSting}">${content}</ul>`;
    }
    if (element.type === ElementType.LIST_ITEM) {
      return `<li style="${styleSting}">${content}</li>`;
    }
    if (element.type === ElementType.NUMBERED_LIST) {
      return `<ol style="${styleSting}">${content}</ol>`;
    }
    if (element.type === ElementType.DYNAMIC_VAR) {
      return `<span>${element.value}</span>`;
    }
    if (element.type === ElementType.IMAGE) {
      return `<img alt="${element.alt ?? ""}" src="${element.url}" ${
        element.width ? `width="${element.width}px"` : ""
      } />`;
    }
  }

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = content;
  if (tempDiv.innerText === "") {
    content = "<br/>";
  }
  return `<div style="${styleSting}">${content}</div>`;
};

const serializeToHTML = (descendants: Descendant[]): string => {
  const result = descendants
    .map((element) => {
      if (Text.isText(element)) {
        return serializeTextToHTML(element);
      } else if (Element.isElement(element)) {
        return serializeElementToHTML(element);
      }
      return "";
    })
    .join("");

  return result;
};

export default serializeToHTML;
