import React from "react";

import MmcNotification from "@app/types/MmcNotification";

import styles from "./Actions.module.scss";
import NotificationMenu from "./NotificationMenu";
import ReadController from "./ReadController";

interface Props {
  hovered: boolean;
  notification: MmcNotification;
  onGoToSettings: () => void;
}

const Actions: React.FC<Props> = ({ hovered, notification, onGoToSettings }) => (
  <div className={styles.container}>
    <ReadController hovered={hovered} notification={notification} />
    <NotificationMenu
      hovered={hovered}
      notification={notification}
      onGoToSettings={onGoToSettings}
    />
  </div>
);

export default Actions;
