import bbox from "@turf/bbox";
import { notification } from "antd";
import { push } from "connected-react-router";
import get from "lodash-es/get";
import { defineMessages } from "react-intl";
import { Action } from "redux";
import { all, call, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionType, isActionOf } from "typesafe-actions";

import Feature from "@mapmycustomers/shared/enum/Feature";
import MapTool from "@mapmycustomers/shared/enum/map/MapTool";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import TerritoryLevel from "@mapmycustomers/shared/enum/TerritoryLevel";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import {
  EntityType,
  EntityTypesSupportedByMapsPage,
  MapEntity,
  Territory,
} from "@mapmycustomers/shared/types/entity";
import {
  CategorizedMapEntries,
  MapEntry,
  MapRecordsResponse,
} from "@mapmycustomers/shared/types/map";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import Organization, { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import SmartTerritoryConfig from "@mapmycustomers/shared/types/territory/SmartTerritoryConfig";
import User from "@mapmycustomers/shared/types/User";
import MapFilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { parseAreaType } from "@mapmycustomers/shared/util/territory/areaTypes";

import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import Path from "@app/enum/Path";
import ReportType from "@app/enum/ReportType";
import MapMode from "@app/scene/map/enums/MapMode";
import {
  cleanLassoPaths,
  clearSelection,
  countPinsInTerritory,
  enterLassoMode,
  enterMode,
  exitMode,
  exitTerritoryLassoMode,
  fetchTerritoryLassoSelection,
  resetRecordsListPagination,
  selectMapTool,
  setDataViewData,
  setHighlight,
  setHighlightedTerritoryId,
  setSelection,
  setSelectionEntities,
  toggleDataViewVisibility,
  updateDataViewEntityTypeCount,
  updateLassoClusters,
  updateTerritory,
  updateTerritorySharing,
  updateViewportState,
} from "@app/scene/map/store/actions";
import {
  doesTerritoryEditFormHaveChanges,
  getExcludedRecords,
  getIncludedRecords,
  getMapLassoPaths,
  getMapMode,
  getMapViewport,
  getMapViewState,
  getMapViewTool,
  getSelectedRecords,
  getTerritoryCandidate,
  getTerritoryDataViewData,
  getTerritoryEntries,
  getTerritoryId,
  getTerritoryMode,
  isTerritoryDataViewVisible,
} from "@app/scene/map/store/selectors";
import {
  applyTerritoryMapViewSettings,
  downloadTerritoryRecords,
  enterTerritoryMode,
  exitTerritoryMode,
  fetchTerritoryPins,
  fetchTerritoryRecords,
  initializeTerritoryMode,
} from "@app/scene/map/store/territoryMode/actions";
import { getTerritoryMapViewState } from "@app/scene/map/store/territoryMode/selectors";
import TerritoryModeState from "@app/scene/map/store/territoryMode/TerritoryModeState";
import convertViewportToPersist from "@app/scene/map/utils/convertViewportToPersist";
import getErrorNotificationDescription from "@app/scene/map/utils/getErrorNotificationDescription";
import getPrecision from "@app/scene/map/utils/getPrecision";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { MAX_ITEMS_TO_DOWNLOAD_FILE } from "@app/store/exportEntities/const";
import { getCurrentUser, getFeatures, getOrganizationId, isBigOrganization } from "@app/store/iam";
import { getAllColorLegends, getAllShapeLegends } from "@app/store/pinLegends";
import convertMapFilterModelToFilterModelForEntity from "@app/store/savedFilters/convertMapFilterModelToFilterModel";
import {
  fetchAllTerritories,
  updateTerritory as updateTerritoryGlobal,
  updateTerritorySharing as updateTerritorySharingGlobal,
} from "@app/store/territories/actions";
import GeoPath from "@app/types/GeoPath";
import MapViewportState from "@app/types/map/MapViewportState";
import Report from "@app/types/Report";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import downloadEntitiesAsCsv from "@app/util/file/downloadEntitiesAsCsv";
import { formatDate } from "@app/util/formatters";
import categorizeMapEntries from "@app/util/map/categorizeMapEnties";
import { MAP_ENTITY_TYPES } from "@app/util/map/consts";
import { mapEntityIdGetter } from "@app/util/map/idGetters";
import { mapEntityIdParser } from "@app/util/map/idParsers";
import getColorShapeForEntity from "@app/util/map/markerStyles/getColorShapeForEntity";
import compareFloatingPointNumbers from "@app/util/number/compareFloatingPointNumbers";
import { getEntityTypeDisplayName } from "@app/util/ui";
import convertMapFilterToPlatformFilterModel from "@app/util/viewModel/convertMapFilterToPlatformFilterModel";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

const messages = defineMessages({
  dataFileTooLarge: {
    id: "map.territory.recordList.exportFiles.dataFileTooLarge.warning",
    defaultMessage:
      "Data {multiple, select, true {files} other {file}} too large to download right now",
    description: "Message shown when sample companies are added",
  },
  dataFileTooLargeDescription: {
    id: "map.territory.recordList.exportFiles.dataFileTooLarge.warning.description",
    defaultMessage:
      "Your export has been queued for processing and will be sent to your email once finished.",
    description: "Message shown when sample people are added",
  },
  error: {
    id: "map.territory.recordList.exportFiles.error.message",
    defaultMessage: "Download Error",
    description: "Message shown when download fail",
  },
  success: {
    id: "map.territory.recordList.exportFiles.success",
    defaultMessage: "{multiple, select, true {Files} other {File}} Downloaded",
    description: "Message shown when sample people are added",
  },
});

type PinLegendsParam = Record<
  EntityTypesSupportedByMapsPage,
  { color?: PinLegend["id"]; shape?: PinLegend["id"] }
>;

const isSamePoint = ([xa, ya]: LongLat, [xb, yb]: LongLat) => {
  return compareFloatingPointNumbers(xa, xb) === 0 && compareFloatingPointNumbers(ya, yb) === 0;
};

const isClosedPath = (path: LongLat[]) => isSamePoint(path[0], path[path.length - 1]);

type ListResponseTotal = Pick<ListResponse<MapEntry>, "total">;

function* onEnterTerritoryMode({ payload }: ReturnType<typeof enterTerritoryMode>) {
  const { areaType, territoryId } = payload;

  // copy map view state when creating new territory
  if (territoryId === "create") {
    const viewState: MapViewState = yield select(getMapViewState);
    yield put(applyTerritoryMapViewSettings(viewState));
  }

  yield put(
    push(`${Path.MAP}/territories/${territoryId}${areaType ? `?areaType=${areaType}` : ""}`)
  );
}

function* onExitTerritoryMode({
  payload: forceExit,
}: ReturnType<typeof exitTerritoryMode.request>) {
  yield put(push(Path.MAP));

  const hasChanges: boolean = yield select(doesTerritoryEditFormHaveChanges);
  // only exit from territory mode immediately if there are no changes in territory or
  // if we're forced to do that
  if (!hasChanges || forceExit) {
    yield put(exitTerritoryMode.success());
    yield put(exitMode());
    yield put(setHighlightedTerritoryId(undefined));
  }
}

function* onInitializeTerritoryMode({
  payload,
}: ReturnType<typeof initializeTerritoryMode.request>) {
  const { callback, territoryId } = payload;
  try {
    yield put(enterMode(MapMode.TERRITORIES));

    // initiate fetching, we don't care if it succeeds or fails
    yield put(fetchAllTerritories.request());

    if (!territoryId) {
      yield put(initializeTerritoryMode.success({}));
      return;
    }

    const territoryCandidate: Territory | undefined = yield select(getTerritoryCandidate);

    const orgId: Organization["id"] = yield select(getOrganizationId);

    // fetch territory
    const territory: Territory =
      territoryCandidate?.id === territoryId
        ? territoryCandidate
        : yield callApi("fetchTerritory", orgId, territoryId);

    let bounds = new google.maps.LatLngBounds();
    if (territory.territoryDetail.type === "points") {
      territory.territoryDetail.points.coordinates.forEach((polylineCoordinates) =>
        polylineCoordinates.forEach(([lng, lat]) => {
          bounds.extend({ lat, lng });
        })
      );
    } else if (territory.territoryDetail.shape) {
      // also detect bounds, but using territory shape file
      const [minLon, minLat, maxLon, maxLat] = bbox(territory.territoryDetail.shape);
      bounds = new google.maps.LatLngBounds(
        { lat: minLat, lng: minLon },
        { lat: maxLat, lng: maxLon }
      );
    }
    callback?.(bounds);

    yield put(initializeTerritoryMode.success({ territory }));
  } catch (e) {
    yield put(initializeTerritoryMode.failure());
    yield put(handleError({ error: e }));
  }
}

export function* onFetchTerritoryPins({ payload }: ReturnType<typeof fetchTerritoryPins.request>) {
  try {
    if (payload.viewport) {
      yield put(updateViewportState(payload.viewport));
      yield call(localSettings.updateViewportState, convertViewportToPersist(payload.viewport));
    }
    yield put(applyTerritoryMapViewSettings(payload.request));
    const viewport: MapViewportState = yield select(getMapViewport);
    const mapViewState: MapViewState = yield select(getTerritoryMapViewState);

    if (payload.updateOnly) {
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const bigOrganization: boolean = yield select(isBigOrganization);

    // we don't need to show any pins on map, but we still needed to perform a request
    // in order to get total count
    const hideResults = mapViewState.visibleEntities.length === 0;

    const mapFilter = convertMapFilterToPlatformFilterModel(
      mapViewState.filter,
      mapViewState.visibleEntities,
      mapViewState.viewAs
    );
    // we're pretty sure it exists in TerritoryMode
    const territoriesCondition = mapFilter.territories!;

    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

    const requestPayload = {
      $filters: {
        bounds: viewport.bounds,
        cadence: true,
        includeAccessStatus: true,
        pinLegends: MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: getColorShapeForEntity(
              mapViewState,
              colorPinLegends,
              shapePinLegends,
              entityType
            ),
          }),
          {} as PinLegendsParam
        ),
        precision: getPrecision(viewport.zoom ?? 1, bigOrganization),
        precisionThreshold: 1000,
        ...mapFilter,
      },
    };
    const response: ListResponse<MapEntry> = yield callApi("fetchMapPins", orgId, requestPayload);

    let externalPinsData: MapEntry[] = [];
    // we don't fetch other pins if they won't be visible anyway
    if (mapViewState.showOther && !hideResults) {
      const externalPinsPayload = { ...requestPayload };
      externalPinsPayload.$filters.territories = {
        // we're pretty sure that territoriesCondition uses $in operator
        // @ts-ignore
        $nin: territoriesCondition[PlatformFilterOperator.GROUP_IN_ANY],
      };
      const externalPinsResponse: ListResponse<MapEntry> = yield callApi(
        "fetchMapPins",
        orgId,
        externalPinsPayload
      );
      externalPinsData = externalPinsResponse.data;
    }

    yield put(
      fetchTerritoryPins.success({
        ...categorizeMapEntries(response.data, hideResults),
        externalEntries: categorizeMapEntries(externalPinsData, hideResults),
        pinsCount: response.total,
      })
    );

    // Also refresh records list, if not in create/edit mode
    const mode: TerritoryModeState["mode"] = yield select(getTerritoryMode);
    if (mode === "view") {
      yield put(fetchTerritoryRecords.request({}));
    }

    // Refresh status of clusters which are within lasso selection
    const pins: CategorizedMapEntries = yield select(getTerritoryEntries);
    yield put(updateLassoClusters(pins.clusters));
  } catch (error) {
    yield put(fetchTerritoryPins.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchTerritoryRecords() {
  try {
    const mapViewState: MapViewState = yield select(getTerritoryMapViewState);
    const searchFilter = mapViewState.search?.trim();

    if (!mapViewState.visibleEntities.length) {
      yield put(
        fetchTerritoryRecords.success({
          records: [],
          recordsCount: 0,
        })
      );
      return;
    }

    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const isLassoMode = activeTool === MapTool.LASSO;

    const selectedRecords: Set<string> = yield select(getSelectedRecords);
    const $offset = mapViewState.range.startRow;
    const $limit = mapViewState.range.endRow - mapViewState.range.startRow;

    const entityFilters: MapFilterModel = { universal: {}, ...mapViewState.filter };

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const mapFilters = convertMapFilterToPlatformFilterModel(
      entityFilters,
      mapViewState.visibleEntities,
      mapViewState.viewAs
    );

    if (searchFilter?.length) {
      mapFilters.name = { $in: searchFilter };
    }

    if (isLassoMode && selectedRecords.size === 0) {
      // If we're in lasso, but haven't selected any records for whatever reason - it makes no sense
      // to request detailed list of records from backend
      yield put(
        fetchTerritoryRecords.success({
          records: [],
          recordsCount: 0,
        })
      );
      return;
    }

    if (isLassoMode) {
      // Inject filtering by selected IDs
      if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
        const entityIdFilter: Record<EntityTypesSupportedByMapsPage, Array<Identified["id"]>> = {
          [EntityType.COMPANY]: [],
          [EntityType.DEAL]: [],
          [EntityType.PERSON]: [],
        };
        for (const mapEntityId of selectedRecords.values()) {
          const parsed = mapEntityIdParser(mapEntityId);
          if (parsed) {
            entityIdFilter[parsed.entity as EntityTypesSupportedByMapsPage].push(parsed.id);
          }
        }

        const entities = mapFilters.entities as Record<
          EntityTypesSupportedByMapsPage,
          PlatformFilterModel
        >;
        Object.keys(entities).forEach((key) => {
          const entityKey = key as EntityTypesSupportedByMapsPage;
          if (mapViewState.visibleEntities.includes(entityKey)) {
            const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
            if (Array.isArray(andCondition) && Array.isArray(entityIdFilter[entityKey])) {
              andCondition.push({
                id: {
                  $in: entityIdFilter[entityKey],
                },
              });
            }
          }
        });
      }
    }

    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

    const pinLegendFilter = {
      pinLegends: MAP_ENTITY_TYPES.reduce(
        (result, entityType) => ({
          ...result,
          [entityType]: getColorShapeForEntity(
            mapViewState,
            colorPinLegends,
            shapePinLegends,
            entityType
          ),
        }),
        {} as PinLegendsParam
      ),
    };

    const requestPayload = {
      $filters: { ...mapFilters, ...pinLegendFilter, cadence: true, includeAccessStatus: true },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(mapViewState.sort),
    };

    const response: ListResponse<MapEntity> = yield callApi("fetchPins", orgId, requestPayload);
    yield put(
      fetchTerritoryRecords.success({
        records: response.data,
        recordsCount: response.total,
      })
    );
  } catch (error) {
    yield put(fetchTerritoryRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateTerritory({ payload }: ReturnType<typeof updateTerritory.request>) {
  try {
    const { onSuccess, shape, territory } = payload;
    yield put(updateTerritoryGlobal.request({ onSuccess, shape, territory }));

    const result: {
      failure?: ActionType<typeof updateTerritoryGlobal.failure>;
      success?: ActionType<typeof updateTerritoryGlobal.success>;
    } = yield race({
      failure: take(updateTerritoryGlobal.failure),
      success: take(updateTerritoryGlobal.success),
    });

    if (result.failure) {
      yield put(updateTerritory.failure());
      return;
    }

    yield put(updateTerritory.success(result.success!.payload.territory));
  } catch (error) {
    yield put(updateTerritory.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateTerritorySharing({
  payload,
}: ReturnType<typeof updateTerritorySharing.request>) {
  try {
    const { onSuccess, territory, userIdsToShareWith } = payload;

    yield put(updateTerritorySharingGlobal.request({ onSuccess, territory, userIdsToShareWith }));

    const result: {
      failure?: ActionType<typeof updateTerritorySharingGlobal.failure>;
      success?: ActionType<typeof updateTerritorySharingGlobal.success>;
    } = yield race({
      failure: take(updateTerritorySharingGlobal.failure),
      success: take(updateTerritorySharingGlobal.success),
    });

    if (result.failure) {
      yield put(updateTerritorySharing.failure());
      return;
    }

    yield put(updateTerritorySharing.success(result.success!.payload.territory));
  } catch (error) {
    yield put(updateTerritorySharing.failure());
    yield put(handleError({ error }));
  }
}

export function* onCountPinsInTerritory({
  payload,
}: ReturnType<typeof countPinsInTerritory.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const mapViewState: MapViewState = yield select(getTerritoryMapViewState);

    if (!mapViewState.visibleEntities.length) {
      yield put(countPinsInTerritory.success(0));
      return;
    }

    if (Array.isArray(payload)) {
      const polygon = [...(payload as LongLat[][])]; // clone since we're editing it below
      if (!polygon.length) {
        yield put(countPinsInTerritory.success(0));
        return;
      }

      // Close path if not closed already. Note: we only expect a single path here
      if (!isClosedPath(polygon[0])) {
        polygon[0] = [...polygon[0], polygon[0][0]];
      }

      const requestPayload = {
        $filters: {
          entities: mapViewState.visibleEntities.reduce(
            (result, entityType) => ({
              ...result,
              [entityType]: {},
            }),
            {}
          ),
          multipolygon: polygon,
        },
        $limit: 0,
      };
      const response: ListResponse<MapEntry> = yield callApi("fetchMapPins", orgId, requestPayload);
      yield put(countPinsInTerritory.success(response.total));
    } else {
      if (!payload.regionIds.length) {
        yield put(countPinsInTerritory.success(0));
        return;
      }

      const parsedAreaType = parseAreaType(payload.areaType);
      if (!parsedAreaType) {
        yield put(countPinsInTerritory.failure());
        return;
      }
      const { countryCode, level } = parsedAreaType;
      const fieldToFilterBy =
        level === TerritoryLevel.ADM1
          ? "geoAddress.regionCode"
          : level === TerritoryLevel.ADM2
          ? "fipsCode"
          : "geoAddress.postalCode";
      const requestPayload = {
        $filters: {
          $and: [
            {
              [fieldToFilterBy]: { $in: payload.regionIds },
              "geoAddress.countryCode": countryCode,
            },
          ],
          entities: mapViewState.visibleEntities.reduce(
            (result, entityType) => ({ ...result, [entityType]: {} }),
            {}
          ),
        },
        $limit: 0,
      };
      const response: ListResponse<MapEntry> = yield callApi("fetchMapPins", orgId, requestPayload);
      yield put(countPinsInTerritory.success(response.total));
    }
  } catch (error) {
    yield put(countPinsInTerritory.failure());
    yield put(handleError({ error }));
  }
}

export function* onEnterTerritoryLassoMode() {
  try {
    const mode: MapMode = yield select(getMapMode);
    if (mode === MapMode.TERRITORIES) {
      yield put(
        fetchTerritoryRecords.success({
          records: [],
          recordsCount: 0,
        })
      );
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onExitTerritoryLassoMode() {
  try {
    yield put(setHighlight(new Set()));
    yield put(cleanLassoPaths());
    yield put(selectMapTool(undefined));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onFetchTerritoryLassoSelection({
  payload,
}: ReturnType<typeof fetchTerritoryLassoSelection.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const mapViewState: MapViewState = yield select(getTerritoryMapViewState);

    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const lassoPaths: Array<GeoPath> = yield select(getMapLassoPaths);

    const isLassoMode = activeTool === MapTool.LASSO && lassoPaths.length > 0;

    if (isLassoMode) {
      const entityFilters: MapFilterModel = { universal: {}, ...mapViewState.filter };

      const multipolygon: LongLat[][] = payload.request.lasso
        ? payload.request.lasso
        : lassoPaths.map((path) =>
            path.map((item: google.maps.LatLng): LongLat => [item.lng(), item.lat()])
          );

      const mapFilter = convertMapFilterToPlatformFilterModel(
        entityFilters,
        mapViewState.visibleEntities,
        mapViewState.viewAs
      );

      const requestPayload = {
        $filters: {
          ...mapFilter,
          cadence: true,
          includeAccessStatus: true,
          multipolygon,
        },
        $limit: 10000,
        $offset: 0,
        $order: convertToPlatformSortModel(mapViewState.sort),
      };

      const response: MapRecordsResponse = yield callApi("fetchMapPins", orgId, requestPayload);

      const excludedRecords: Set<string> = yield select(getExcludedRecords);
      const includedRecords: Set<string> = yield select(getIncludedRecords);

      const responseItems = response.data ?? [];
      const itemsWithoutExcluded = new Set(
        responseItems
          .map((entity) => mapEntityIdGetter(entity))
          .filter((value) => !excludedRecords.has(value))
      );
      const itemsWithIncluded = new Set([
        ...Array.from(includedRecords),
        ...Array.from(itemsWithoutExcluded),
      ]);
      yield put(setSelection(itemsWithIncluded));

      const selectionEntities = responseItems.filter(
        (entity) => !excludedRecords.has(mapEntityIdGetter(entity))
      );
      yield put(setSelectionEntities(selectionEntities));

      yield put(fetchTerritoryLassoSelection.success({ records: response.data }));
    } else {
      yield put(clearSelection());
      yield put(fetchTerritoryLassoSelection.success({ records: [] }));
    }

    yield put(resetRecordsListPagination());
    yield put(fetchTerritoryRecords.request({}));
  } catch (error) {
    yield put(fetchTerritoryLassoSelection.failure({ error }));
    yield put(handleError({ error }));
  }
}

export function* onDataView() {
  yield put(updateDataViewEntityTypeCount.request());
}

export function* onUpdateEntityTypeCount() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const viewState: MapViewState = yield select(getTerritoryMapViewState);
    const dataViewVisible: boolean = yield select(isTerritoryDataViewVisible);
    const mode: string = yield select(getTerritoryMode);
    const isViewMode = mode === "view";
    const territoryId: Territory["id"] | undefined = yield select(getTerritoryId);
    const data: LongLat[][] | SmartTerritoryConfig | undefined = yield select(
      getTerritoryDataViewData
    );
    if (!dataViewVisible || !data) {
      yield put(
        updateDataViewEntityTypeCount.success({
          entityTypeCount: {},
        })
      );
      return;
    }

    let $filters = {};

    if (isViewMode) {
      if (!territoryId) {
        yield put(updateDataViewEntityTypeCount.failure());
        return;
      }
      $filters = {
        territories: { $any: [territoryId] },
      };
    } else {
      if (Array.isArray(data)) {
        const polygon = [...(data as LongLat[][])]; // clone since we're editing it below
        if (!polygon.length) {
          yield put(updateDataViewEntityTypeCount.success({ entityTypeCount: {} }));
          return;
        }
        polygon[0] = [...polygon[0], polygon[0][0]];
        $filters = { multipolygon: polygon };
      } else {
        const parsedAreaType = parseAreaType((data as SmartTerritoryConfig).areaType);
        if (!parsedAreaType) {
          yield put(updateDataViewEntityTypeCount.failure());
          return;
        }
        const { countryCode, level } = parsedAreaType;
        const fieldToFilterBy =
          level === TerritoryLevel.ADM1
            ? "geoAddress.regionCode"
            : level === TerritoryLevel.ADM2
            ? "fipsCode"
            : "geoAddress.postalCode";

        $filters = {
          $and: [
            {
              [fieldToFilterBy]: { $in: (data as SmartTerritoryConfig).regionIds },
              "geoAddress.countryCode": countryCode,
            },
          ],
        };
      }
    }

    const requestPayloads = [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL].map(
      (type) => ({
        payload: {
          $filters: {
            ...$filters,
            ...convertMapFilterToPlatformFilterModel(
              viewState.filter,
              [type as EntityTypesSupportedByMapsPage],
              viewState.viewAs
            ),
          },
        },
        type,
      })
    );

    const [companyResponse, peopleResponse, dealResponse]: ListResponseTotal[] = yield all(
      requestPayloads.map(({ payload, type }) =>
        viewState.visibleEntities.includes(type as EntityTypesSupportedByMapsPage)
          ? callApi("fetchMapPins", orgId, payload)
          : { total: 0 }
      )
    );

    yield put(
      updateDataViewEntityTypeCount.success({
        entityTypeCount: {
          [EntityType.COMPANY]: companyResponse.total,
          [EntityType.DEAL]: dealResponse.total,
          [EntityType.PERSON]: peopleResponse.total,
        },
      })
    );
  } catch (error) {
    yield put(updateDataViewEntityTypeCount.failure());
    yield put(handleError({ error }));
  }
}

export function* onDownloadTerritoryRecords({
  payload,
}: ReturnType<typeof downloadTerritoryRecords>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const currentUser: User = yield select(getCurrentUser);

    const viewport: MapViewportState = yield select(getMapViewport);
    const mapViewState: MapViewState = yield select(getTerritoryMapViewState);
    const searchFilter = mapViewState.search?.trim();

    const features: OrganizationMetaData["features"] = yield select(getFeatures);
    const disallowColor = features?.[Feature.DISALLOW_COLOR]?.enabled;

    const visibleEntityTypes = mapViewState.visibleEntities;

    const mapFilters = convertMapFilterToPlatformFilterModel(
      mapViewState.filter,
      visibleEntityTypes,
      mapViewState.viewAs
    );

    const entities = mapFilters.entities as Record<
      EntityTypesSupportedByMapsPage,
      PlatformFilterModel
    >;
    Object.keys(entities).forEach((entityKey) => {
      const entity = get(mapFilters.entities, entityKey);
      entity.includeGroups = true;
    });

    if (searchFilter?.length) {
      // Inject filtering by name in records list
      if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
        Object.keys(entities).forEach((entityKey) => {
          const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
          if (Array.isArray(andCondition)) {
            andCondition.push({
              name: {
                $in: searchFilter,
              },
            });
          }
        });
      }
    }

    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

    const bounds = viewport.bounds;
    const pinLegendFilter = disallowColor
      ? {
          pinLegends: MAP_ENTITY_TYPES.reduce(
            (result, entityType) => ({
              ...result,
              [entityType]: getColorShapeForEntity(
                mapViewState,
                colorPinLegends,
                shapePinLegends,
                entityType
              ),
            }),
            {} as Record<
              EntityTypesSupportedByMapsPage,
              { color?: PinLegend["id"]; shape?: PinLegend["id"] }
            >
          ),
        }
      : {};

    const intl = i18nService.getIntl();
    let entitiesWithResponseLimitCount = 0;
    let downloadedEntities = 0;
    for (const entityType of visibleEntityTypes) {
      const requiredEntity =
        mapFilters.entities &&
        (
          mapFilters.entities as Partial<
            Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>
          >
        )[entityType];
      const checkFilters = { ...mapFilters, entities: { [entityType]: requiredEntity } };
      const requestPayload = {
        $filters: {
          ...checkFilters,
          ...pinLegendFilter,
          bounds,
          cadence: true,
          includeCustomFields: true,
        },
        $limit: 0,
      };
      const columns =
        payload
          .get(entityType)
          ?.filter(({ visible }) => visible)
          .map(({ field }) => field) ?? [];
      const response: MapRecordsResponse = yield callApi("fetchPins", orgId, requestPayload);
      if (response.total > MAX_ITEMS_TO_DOWNLOAD_FILE) {
        const $filters = convertToPlatformFilterModel(
          convertMapFilterModelToFilterModelForEntity(entityType, mapViewState.filter),
          getFieldModelByEntityType(entityType).fields.map((field) => ({ field, visible: true })),
          getFieldModelByEntityType(entityType),
          true
        );
        const selectedColumns = columns.map(({ name }) => name);
        const payload = {
          description: "",
          name: `${
            intl
              ? getEntityTypeDisplayName(intl, entityType, {
                  lowercase: false,
                  plural: false,
                })
              : entityType
          } ${formatDate(new Date(), "Pp")}`,
          selectedColumns,
          selectedFilters: {
            ...$filters,
            ...pinLegendFilter,
            bounds,
            cadence: true,
            includeCustomFields: true,
          },
          tableName: entityType,
        };

        try {
          const report: Report = yield callApi("createReport", orgId, payload);
          yield callApi("generateReport", orgId, report.id, currentUser.username);
          entitiesWithResponseLimitCount += 1;
        } catch {
          notification.error({
            description: getErrorNotificationDescription(intl, payload.tableName),
            message: intl?.formatMessage(messages.error),
          });
        }
      } else {
        const response: MapRecordsResponse = yield callApi("fetchPins", orgId, {
          ...requestPayload,
          $limit: MAX_ITEMS_TO_DOWNLOAD_FILE,
        });
        let reportEntityType;
        switch (entityType) {
          case EntityType.COMPANY:
            reportEntityType = ReportType.COMPANIES;
            break;
          case EntityType.DEAL:
            reportEntityType = ReportType.DEALS;
            break;
          case EntityType.PERSON:
            reportEntityType = ReportType.PEOPLE;
            break;
        }
        if (response.data.length > 0) {
          downloadEntitiesAsCsv(
            `${reportEntityType}_preview.csv`,
            entityType,
            response.data,
            columns
          );
          downloadedEntities += 1;
        }
      }
    }
    if (intl) {
      if (downloadedEntities > 0) {
        notification.success({
          message: intl.formatMessage(messages.success, {
            multiple: downloadedEntities > 1,
          }),
        });
      }
      if (entitiesWithResponseLimitCount > 0) {
        notification.warning({
          description: intl.formatMessage(messages.dataFileTooLargeDescription),
          message: intl.formatMessage(messages.dataFileTooLarge, {
            multiple: entitiesWithResponseLimitCount > 1,
          }),
        });
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* territoryModeSagas() {
  yield takeLatest(enterTerritoryMode, onEnterTerritoryMode);
  yield takeLatest(exitTerritoryMode.request, onExitTerritoryMode);
  yield takeLatest(initializeTerritoryMode.request, onInitializeTerritoryMode);
  yield takeLatest(
    (action: Action) =>
      isActionOf(fetchTerritoryPins.request)(action) && !action.payload.updateOnly,
    onFetchTerritoryPins
  );
  yield takeEvery(
    (action: Action) =>
      isActionOf(fetchTerritoryPins.request)(action) && !!action.payload.updateOnly,
    onFetchTerritoryPins
  );
  yield takeLatest(fetchTerritoryRecords.request, onFetchTerritoryRecords);
  yield takeLatest(updateTerritory.request, onUpdateTerritory);
  yield takeLatest(updateTerritorySharing.request, onUpdateTerritorySharing);
  yield takeLatest(countPinsInTerritory.request, onCountPinsInTerritory);
  yield takeLatest([toggleDataViewVisibility, setDataViewData], onDataView);
  yield takeLatest(updateDataViewEntityTypeCount.request, onUpdateEntityTypeCount);
  yield takeLatest(enterLassoMode, onEnterTerritoryLassoMode);
  yield takeLatest(fetchTerritoryLassoSelection.request, onFetchTerritoryLassoSelection);
  yield takeLatest(exitTerritoryLassoMode, onExitTerritoryLassoMode);
  yield takeLatest(downloadTerritoryRecords, onDownloadTerritoryRecords);
}
