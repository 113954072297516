// using strings instead of numbers to simplify usage as object keys, to avoid
// casting a string (after Object.keys for example) to a number
enum FieldCategory {
  ADDRESS = "address",
  RELATIONSHIPS = "relationships",
  REQUIRED = "required",
  NON_REQUIRED = "nonRequired",
  DETAILS = "details",
  PARENT_COMPANY_ADDRESS_FIELDS = "parentCompanyAddressFields",
  COMPANY_ADDRESS_FIELDS = "companyAddressFields",
  DEAL_ADDRESS_FIELDS = "dealAddressFields",
  PEOPLE_ADDRESS_FIELDS = "peopleAddressFields",
}

export default FieldCategory;
