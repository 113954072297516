import React, { useCallback } from "react";

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import Dropdown from "antd/es/dropdown";
import cn from "classnames";
import { useSlate } from "slate-react";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { ElementControl } from "../../enum/Control";
import findMatchedElementTypes from "../../util/findMatchedElementTypes";
import toggleBlock from "../../util/toggleBlock";
import ActionTooltipWrapper from "../ActionTooltipWrapper";
import styles from "../Toolbar.module.scss";

interface Props {
  dropdownElement?: React.ReactNode;
  tooltipTranslationString: string;
  value: ElementControl | ElementControl[];
}

const ElementToggler: React.FC<Props> = ({ dropdownElement, tooltipTranslationString, value }) => {
  const editor = useSlate();

  const handleToggleBlock = useCallback(
    (type: ElementType) => {
      toggleBlock(editor, type);
    },
    [editor]
  );

  if (Array.isArray(value)) {
    return (
      <Dropdown
        menu={{
          items: value.map(({ label, value }) => ({
            key: value,
            label,
          })),
          onClick: (e) => handleToggleBlock(e.key as ElementType),
          onMouseDown: stopEvents,
        }}
        placement="top"
        trigger={["click"]}
      >
        <ActionTooltipWrapper translationString={tooltipTranslationString}>
          <span className={styles.togglerButton} onMouseDown={stopEvents}>
            <Row align="middle" gutter={4}>
              <Col>{dropdownElement}</Col>
              <Col>
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              </Col>
            </Row>
          </span>
        </ActionTooltipWrapper>
      </Dropdown>
    );
  }
  return (
    <ActionTooltipWrapper translationString={tooltipTranslationString}>
      <span
        className={cn(styles.togglerButton, {
          [styles.active]: findMatchedElementTypes(editor, value.value)[0],
        })}
        onClick={() => handleToggleBlock(value.value)}
        onMouseDown={stopEvents}
      >
        {value.label}
      </span>
    </ActionTooltipWrapper>
  );
};

export default ElementToggler;
