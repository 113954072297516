import { defineMessages } from "react-intl";

const messages = defineMessages({
  clearQueue: {
    id: "navbar.emailQueue.modal.clear",
    defaultMessage: "Clear Queue",
    description: "Clear queue button in the email queue modal.",
  },
  popconfirmCancel: {
    id: "navbar.emailQueue.modal.clear.confirm.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button in the clear queue confirmation in the email queue modal.",
  },
  popconfirmOk: {
    id: "navbar.emailQueue.modal.clear.confirm.ok",
    defaultMessage: "Clear Queue",
    description: "Clear button in the clear queue confirmation in the email queue modal.",
  },
  popconfirmTitle: {
    id: "navbar.emailQueue.modal.clear.confirm.title",
    defaultMessage:
      "<b>This action cannot be undone.</b>{br}Clearing the queue cancels all unsent emails, leaving the queue empty and canceling all activities that are scheduled to be created.",
    description: "Title of the clear queue confirmation in the email queue modal.",
  },
  queueCount: {
    id: "navbar.emailQueue.queueCount",
    defaultMessage:
      "{count, select, 0 {Queue is empty} other {{count} {count, plural, one {email} other {emails}} queued}}",
    description: "Queue count in the email queue modal.",
  },
  queueEmpty: {
    id: "navbar.emailQueue.queueEmpty",
    defaultMessage: "Queue is empty",
    description: "Queue empty message in the email queue modal.",
  },
  title: {
    id: "navbar.emailQueue.modal.title",
    defaultMessage: "Email Queue",
    description: "Title of the email queue modal.",
  },
});

export default messages;
