import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import { TabbedDrillDownListFetcherPayload } from "@app/scene/dashboard/types/TabbedDrillDownListFetcher";
import EntityTypesSupportedInNewRoutes from "@app/types/dashboard/cards/newRoutes/EntityTypesSupportedInNewRoutes";
import NewRoutesCardConfiguration from "@app/types/dashboard/cards/newRoutes/NewRoutesCardConfiguration";
import Scope from "@app/types/dashboard/Scope";

import NewRoutesCardData from "./NewRoutesCardData";

export const fetchNewRoutesCardData = createAction("@dashboard/fetchNewRoutesCardData")<{
  callback: (data: NewRoutesCardData) => void;
  configuration: NewRoutesCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showNewRoutesDrillDown = createAsyncAction(
  "@dashboard/showNewRoutesCardDrillDownModal/request",
  "@dashboard/showNewRoutesCardDrillDownModal/success",
  "@dashboard/showNewRoutesCardDrillDownModal/failure"
)<
  {
    configuration: NewRoutesCardConfiguration;
    customDateRange?: DateRange;
    scope: Scope;
  },
  { viewStates: Record<EntityTypesSupportedInNewRoutes, DrillDownViewState> },
  unknown
>();

export const hideNewRoutesDrillDown = createAction(
  "@dashboard/hideNewRoutesCardDrillDownModal"
)<void>();

export const fetchNewRoutesCardDrillDownData = createAsyncAction(
  "@dashboard/fetchNewRoutesCardDrillDownData/request",
  "@dashboard/fetchNewRoutesCardDrillDownData/success",
  "@dashboard/fetchNewRoutesCardDrillDownData/failure"
)<
  TabbedDrillDownListFetcherPayload<AnyEntity, EntityTypesSupportedInNewRoutes>,
  {
    entityType: EntityTypesSupportedInNewRoutes;
    totalFilteredRoutes: number;
    totalRoutes: number;
  },
  unknown
>();

export const applyNewRoutesDrillDownListViewSettings = createAction(
  "@dashboard/applyNewRoutesDrillDownListViewSettings"
)<Partial<DrillDownViewState> & { entityType: EntityTypesSupportedInNewRoutes }>();

export type NewRoutesCardActions = ActionType<
  | typeof applyNewRoutesDrillDownListViewSettings
  | typeof fetchNewRoutesCardData
  | typeof fetchNewRoutesCardDrillDownData
  | typeof hideNewRoutesDrillDown
  | typeof showNewRoutesDrillDown
>;
