import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const mergeDuplicatesState = (state: RootState) => state.scene.settings.mergeDuplicates;

export const getEntityType = createSelector(mergeDuplicatesState, ({ entityType }) => entityType);

export const getDuplicates = createSelector(mergeDuplicatesState, ({ duplicates }) => duplicates);

export const isSelectedEntitiesLoading = createSelector(
  mergeDuplicatesState,
  ({ selectedEntitiesLoading }) => selectedEntitiesLoading
);

export const getSelectedPair = createSelector(
  mergeDuplicatesState,
  ({ selectedPair }) => selectedPair
);

export const getSelectedPairIndex = createSelector(
  mergeDuplicatesState,
  ({ selectedPairIndex }) => selectedPairIndex
);

export const getSelectedEntities = createSelector(
  mergeDuplicatesState,
  ({ selectedDuplicatePrimaryEntity, selectedDuplicateSecondaryEntity }) => ({
    primary: selectedDuplicatePrimaryEntity,
    secondary: selectedDuplicateSecondaryEntity,
  })
);

export const getDuplicatesLoading = createSelector(
  mergeDuplicatesState,
  ({ duplicatesLoading }) => duplicatesLoading
);

export const isDismissedShowed = createSelector(
  mergeDuplicatesState,
  ({ showDismissed }) => showDismissed
);

export const getSelectedAsPrioritizedEntity = createSelector(
  mergeDuplicatesState,
  ({ selectedEntityAsPrioritized }) => selectedEntityAsPrioritized
);

export const getLastCalculatedDate = createSelector(
  mergeDuplicatesState,
  ({ lastCalculatedDate }) => lastCalculatedDate
);

export const getLastDupeRunStatus = createSelector(
  mergeDuplicatesState,
  ({ lastDupeRunStatus }) => lastDupeRunStatus
);

export const getSearchText = createSelector(mergeDuplicatesState, ({ searchText }) => searchText);

export const getDuplicatesTotal = createSelector(mergeDuplicatesState, ({ total }) => total);

export const getPage = createSelector(mergeDuplicatesState, ({ page }) => page);
