import EntityType from "@mapmycustomers/shared/enum/EntityType";
import IntegrationService from "@mapmycustomers/shared/enum/integrations/IntegrationService";
import { EntityTypesSupportedByIntegrations } from "@mapmycustomers/shared/types/entity";

import configService from "@app/config/ConfigService";
import {
  dynamicsLogo,
  hubspotLogo,
  salesforceLogo,
  zohoLogo,
} from "@app/scene/settings/component/Integrations/logo";

import styles from "./services.module.scss";

export type ServiceDescription = {
  className?: string;
  enabled: boolean;
  logo: string;
  name: string;
  sandboxUrl?: string;
  shortName?: string;
  supportedEntityTypes: EntityTypesSupportedByIntegrations[];
  type: IntegrationService;
  url?: string;
};

const SUPPORTED_SERVICES: ServiceDescription[] = [
  {
    className: styles.hubspot,
    enabled: true,
    logo: hubspotLogo,
    name: "HubSpot",
    supportedEntityTypes: [
      EntityType.COMPANY,
      EntityType.PERSON,
      EntityType.DEAL,
      EntityType.ACTIVITY,
    ],
    type: IntegrationService.HUBSPOT,
    url: configService.getHubspotAuthUrl(),
  },
  {
    className: styles.salesforce,
    enabled: true,
    logo: salesforceLogo,
    name: "Salesforce",
    sandboxUrl: configService.getSalesforceSandBoxAuthUrl(),
    supportedEntityTypes: [
      EntityType.COMPANY,
      EntityType.PERSON,
      EntityType.DEAL,
      EntityType.ACTIVITY,
    ],
    type: IntegrationService.SALESFORCE,
    url: configService.getSalesforceAuthUrl(),
  },
  {
    className: styles.zoho,
    enabled: true,
    logo: zohoLogo,
    name: "Zoho",
    supportedEntityTypes: [
      EntityType.COMPANY,
      EntityType.PERSON,
      EntityType.ACTIVITY,
      EntityType.DEAL,
    ],
    type: IntegrationService.ZOHO,
    url: configService.getZohoAuthUrl(),
  },
  {
    className: styles.dynamics,
    enabled: true,
    logo: dynamicsLogo,
    name: "Microsoft Dynamics",
    shortName: "Dynamics",
    supportedEntityTypes: [
      EntityType.COMPANY,
      EntityType.PERSON,
      EntityType.ACTIVITY,
      EntityType.DEAL,
    ],
    type: IntegrationService.DYNAMICS,
    url: configService.getDynamicsAuthUrl(),
  },
];

export default SUPPORTED_SERVICES;
