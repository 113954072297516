import React from "react";

import { useIntl } from "react-intl";

import styles from "./EmptyValue.module.scss";

interface Props {}

const EmptyValue: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <span className={styles.unknown}>
      {intl.formatMessage({
        id: "map.annotation.entity.field.unknownValue",
        defaultMessage: "Unknown",
        description: "Unknown value in field",
      })}
    </span>
  );
};

export default EmptyValue;
