import { createSelector } from "reselect";

import BoundaryType from "@mapmycustomers/shared/enum/BoundaryType";

import {
  doesOrganizationSupportDemographicData,
  doesOrganizationSupportTerritories,
} from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

export const boundariesState = (state: RootState) => state.scene.map.layers.boundaries;

export const getBoundaryType = createSelector(
  doesOrganizationSupportTerritories,
  boundariesState,
  (territoriesEnabled, { type }) => (territoriesEnabled ? type : BoundaryType.DEMOGRAPHY)
);

export const getHighlightedTerritoryId = createSelector(
  boundariesState,
  ({ highlightedTerritoryId }) => highlightedTerritoryId
);

export const areBoundariesVisible = createSelector(boundariesState, ({ visible }) => visible);

export const getDemographicData = createSelector(
  boundariesState,
  ({ demographicData }) => demographicData
);

export const getDemographicDataConfiguration = createSelector(
  boundariesState,
  ({ demographicDataConfiguration }) => demographicDataConfiguration
);

export const getCountryCode = createSelector(
  getDemographicDataConfiguration,
  ({ countryCode }) => countryCode
);

export const getBuckets = createSelector(boundariesState, ({ buckets }) => buckets);
export const isBucketsLoading = createSelector(
  boundariesState,
  ({ bucketsLoading }) => bucketsLoading
);

export const getZoomLevel = createSelector(boundariesState, ({ zoomLevel }) => zoomLevel);
export const getDataSource = createSelector(
  getDemographicDataConfiguration,
  ({ dataSource }) => dataSource
);

export const getViewportRegions = createSelector(
  boundariesState,
  ({ viewportRegions }) => viewportRegions
);

export const shouldVisualizeDemography = createSelector(
  doesOrganizationSupportDemographicData,
  boundariesState,
  (demographicDataEnabled, { visualizeDemography }) => visualizeDemography && demographicDataEnabled
);

export const getDemographyDataCountryCode = createSelector(
  boundariesState,
  ({ demographyDataCountryCode }) => demographyDataCountryCode
);

export const isDemographicDataLoading = createSelector(
  boundariesState,
  ({ demographicDataLoading }) => demographicDataLoading
);
