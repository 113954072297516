import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";
import { CustomFieldPayload } from "@mapmycustomers/shared/types/customField/CustomField";

export const NEW_CUSTOM_FIELD: CustomFieldPayload = {
  crmPropertyKey: "",
  currencyId: null,
  dataType: CustomFieldDataType.TEXT,
  displayName: "",
  displayOrder: 1,
  displayType: null,
  esKey: "",
  options: [{ displayName: "", displayOrder: 1, value: 1 }],
  placeholder: "",
};

export const MERGE_DUPLICATES_TABLE_PAGE_SIZE = 10;
