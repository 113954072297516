import React, { useCallback } from "react";

import Select, { RefSelectProps } from "antd/es/select";
import cn from "classnames";
import capitalize from "lodash-es/capitalize";
import { useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { ClientType, RecordSource } from "@mapmycustomers/shared/enum/RecordSourceType";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import { getRecordSourceTypeDisplayName } from "@app/util/ui";

import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "../getHumanReadableDescriptionForOptionsFilter";

import styles from "./SourceFilter.module.scss";

export const SOURCE_FILTER_OPERATORS = [FilterOperator.IN_ANY, FilterOperator.NONE_OF];

const AllRecordSourceType = Object.values(ClientType);

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every(
    (item) => typeof item === "string" && AllRecordSourceType.includes(item as ClientType)
  ) &&
  SOURCE_FILTER_OPERATORS.includes(operator);

interface SourceFilterProps extends IFilterComponentProps {}

const SourceFilter: IFilterInstance = {
  doesSupportValue,
  getComponent:
    (): React.FC<SourceFilterProps> =>
    ({ className, focus, onChange, options, value }) => {
      const intl = useIntl();
      const availableOptions = (options?.availableSources ?? [
        ...AllRecordSourceType,
        RecordSource.LEAD_GEN,
      ]) as (ClientType | RecordSource)[];

      const setRef = useCallback(
        (ref: null | RefSelectProps) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <Select<ClientType[]>
          className={cn(styles.container, className)}
          filterOption={(inputValue, option) =>
            ((option?.label ?? option?.value) as string) // children because display names are different than value
              ?.toLowerCase()
              .includes(inputValue.toLowerCase().trim()) ?? false
          }
          mode="multiple"
          onChange={(types: (ClientType | RecordSource)[]) =>
            onChange?.({ ...value, value: types })
          }
          options={availableOptions.map((type) => ({
            label: getRecordSourceTypeDisplayName(intl, type),
            value: type,
          }))}
          placeholder={intl.formatMessage({
            id: "filters.source.select.placeholder",
            defaultMessage: "Click or type to select source",
            description: "Placeholder displayed in SourceFilter's select field",
          })}
          ref={setRef}
          showSearch
          value={Array.isArray(value.value) ? value.value : []}
        />
      );
    },
  getHumanReadableDescription: (value: SimpleCondition, field: IField) =>
    doesSupportValue(value.value, value.operator)
      ? getHumanReadableDescriptionForOptionsFilter(
          field,
          value.operator as OptionFilterOperator,
          value.value.map(capitalize)
        )
      : undefined,
};

export default SourceFilter;
