import React, { useCallback } from "react";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { NumberField, NumberFieldComponent } from "@mapmycustomers/ui";

export const NUMBER_FILTER_OPERATORS = [
  FilterOperator.EQUALS,
  FilterOperator.NOT_EQUAL,
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_THAN_OR_EQUAL,
  FilterOperator.LESS_THAN,
  FilterOperator.LESS_THAN_OR_EQUAL,
];

interface NumberFilterProps extends IFilterComponentProps {}

const NumberFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return typeof value === "number" && NUMBER_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<NumberFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const setRef = useCallback(
        (ref: null | NumberFieldComponent) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );
      return (
        <NumberField
          inputClassName={className}
          onChange={(number) => onChange?.({ ...value, value: number })}
          ref={setRef}
          size="middle"
          value={value?.value}
        />
      );
    },
};

export default NumberFilter;
