import type { LocalSettings } from "@app/config/LocalSettings";
import localSettings from "@app/config/LocalSettings";
import TimelineEventType from "@app/scene/dashboard/enum/TimelineEventType";

class DashboardSettings {
  settings: LocalSettings;

  private readonly VISIBLE_EVENT_TYPES = "__DASHBOARD_VISIBLE_EVENT_TYPES__";

  constructor(settings: LocalSettings) {
    this.settings = settings;
  }

  getVisibleEventTypes = (): TimelineEventType[] => {
    const json = this.settings.getValue(this.VISIBLE_EVENT_TYPES);
    const allTypes = Object.values(TimelineEventType);
    try {
      let types = json ? JSON.parse(json as string) : allTypes;
      types = Array.isArray(types) ? types.filter((type) => allTypes.includes(type)) : types;
      return !Array.isArray(types) || !types.length ? allTypes : types;
    } catch {
      return allTypes;
    }
  };

  setVisibleEventTypes = (eventTypes: TimelineEventType[]) => {
    this.settings.setValue(this.VISIBLE_EVENT_TYPES, JSON.stringify(eventTypes));
  };
}

const dashboardSettings = new DashboardSettings(localSettings);

export default dashboardSettings;
