import { ActionType, createAsyncAction } from "typesafe-actions";

import CustomField, {
  CustomFieldPayload,
} from "@mapmycustomers/shared/types/customField/CustomField";
import { EntityTypeSupportingCustomFields } from "@mapmycustomers/shared/types/entity";

// used on app loading to fetch all custom fields
export const fetchAllCustomFields = createAsyncAction(
  "@customFields/fetchAll/request",
  "@customFields/fetchAll/success",
  "@customFields/fetchAll/failure"
)<void, void, void>();

export const fetchCustomFields = createAsyncAction(
  "@customFields/fetch/request",
  "@customFields/fetch/success",
  "@customFields/fetch/failure"
)<
  { entityType: EntityTypeSupportingCustomFields },
  { entityType: EntityTypeSupportingCustomFields; fields: CustomField[] },
  { entityType: EntityTypeSupportingCustomFields; error: unknown }
>();

export const createCustomField = createAsyncAction(
  "@customFields/createCustomField/request",
  "@customFields/createCustomField/success",
  "@customFields/createCustomField/failure"
)<
  {
    callback?: () => void;
    customField: CustomFieldPayload;
    entityType: EntityTypeSupportingCustomFields;
  },
  void,
  void
>();

export const updateCustomField = createAsyncAction(
  "@customFields/updateCustomField/request",
  "@customFields/updateCustomField/success",
  "@customFields/updateCustomField/failure"
)<
  { callback?: () => void; customField: CustomField; entityType: EntityTypeSupportingCustomFields },
  void,
  void
>();

export const updateCustomFieldBulk = createAsyncAction(
  "@customFields/updateCustomFieldBulk/request",
  "@customFields/updateCustomFieldBulk/success",
  "@customFields/updateCustomFieldBulk/failure"
)<{ entityType: EntityTypeSupportingCustomFields; fields: CustomField[] }, void, void>();

export const deleteCustomField = createAsyncAction(
  "@customFields/deleteCustomField/request",
  "@customFields/deleteCustomField/success",
  "@customFields/deleteCustomField/failure"
)<
  {
    callback?: () => void;
    entityType: EntityTypeSupportingCustomFields;
    id: CustomField["id"];
    sendReport: boolean;
  },
  { entityType: EntityTypeSupportingCustomFields; id: CustomField["id"] },
  void
>();

export type Actions = ActionType<
  | typeof createCustomField
  | typeof deleteCustomField
  | typeof fetchAllCustomFields
  | typeof fetchCustomFields
  | typeof updateCustomField
  | typeof updateCustomFieldBulk
>;
