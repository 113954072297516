import React, { ChangeEvent, useCallback, useRef, useState } from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import Input, { InputProps, InputRef } from "antd/es/input/Input";
import cn from "classnames";

import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { useConfigProvider } from "../../../ConfigProvider";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import Footer from "../Footer";

export const messages = {
  "ui.inlineInput.singleLineTextField.unknown": "Unknown",
};

interface InlineSingleLineTextProps
  extends Omit<InputProps, "onChange">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  disabled?: boolean;
  onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  showFooterButtons?: boolean;
  value?: string;
}

const block = bem("mmc-single-line-text-field");

const SingleLineTextField: React.FC<InlineSingleLineTextProps> = ({
  addonBefore,
  caption,
  className,
  disabled,
  label,
  labelClassName,
  labelPosition = "side",
  onChange,
  placeholder,
  required,
  showFooterButtons = true,
  suffix,
  value,
  ...props
}) => {
  const configProvider = useConfigProvider();
  const [inlineValue, setInlineValue] = useState<string | undefined>(value);

  const [editing, startEditing, cancelEditing] = useBoolean();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setInlineValue(event.target.value),
    []
  );

  const handleSave = useCallback(() => {
    cancelEditing();
    onChange?.(inlineValue ?? "");
  }, [inlineValue, cancelEditing, onChange]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape") {
        event.stopPropagation();
        cancelEditing();
      } else if (event.key === "Enter") {
        event.stopPropagation();
        handleSave();
      }
    },
    [cancelEditing, handleSave]
  );

  const inputRef = useRef<InputRef>(null);
  const handleStartEditing = useCallback(() => {
    if (disabled) {
      return;
    }
    setInlineValue(value);
    startEditing();
    // can't focus hidden field, need to wait until it will be displayed
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [disabled, setInlineValue, startEditing, value]);

  const handleBlur = useCallback(
    (e) => {
      // To prevent blur action when cancel button is clicked as we save text onblur
      if (e.relatedTarget && e.relatedTarget.classList.contains("mmc-js-skip-save-on-blur")) {
        e.preventDefault();
        cancelEditing();
        return;
      }
      handleSave();
    },
    [cancelEditing, handleSave]
  );

  const content = editing ? (
    <Input
      allowClear
      autoFocus
      className={block("input")}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      ref={inputRef}
      required={required}
      size="large"
      suffix={suffix}
      value={inlineValue}
      {...props}
    />
  ) : (
    <div className={block("value")} onClick={handleStartEditing}>
      {value ?? (
        <span className={block("placeholder")}>
          {placeholder ?? configProvider.formatMessage("ui.inlineInput.unknown")}
        </span>
      )}
    </div>
  );

  return (
    <div className={cn(block({ disabled: !!disabled }), className)}>
      {label ? (
        <Labeled
          extra={disabled ? <FontAwesomeIcon className={block("lock")} icon={faLock} /> : undefined}
          label={
            <div className={block("label-item")}>
              {addonBefore}
              {label}
            </div>
          }
          labelClassName={cn(block("label"), labelClassName)}
          labelPosition={labelPosition}
          required={required}
        >
          {content}
        </Labeled>
      ) : (
        content
      )}
      {showFooterButtons && (
        <Footer
          caption={caption}
          disabled={disabled}
          editing={editing}
          onCancel={cancelEditing}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default SingleLineTextField;
