import { MessageDescriptor } from "@formatjs/intl/src/types";
import { defineMessage } from "react-intl";

import ApiError from "@mapmycustomers/shared/util/api/ApiError";

import i18nService from "@app/config/I18nService";

import getErrorMessages from "./getErrorMessages";
import getValidationErrors from "./getValidationErrors";
import getErrorMessageByCode from "./platformErrorCodes";

const defaultErrorMessage = defineMessage({
  id: "errors.generic",
  defaultMessage: "Unknown error. Please try again later.",
  description: "Error when we have no other message",
});

const getErrorMessage = (
  error: Error | undefined,
  validationErrorMessages?: { [key: string]: MessageDescriptor },
  defaultMessage: MessageDescriptor = defaultErrorMessage
): string => {
  const intl = i18nService.getIntl();
  const defaultMessageString =
    // this message is not localized because it's displayed when intl is not available,
    // so we can't localize it anyway
    intl?.formatMessage(defaultMessage) ?? "Unknown error. Please try again later.";

  if (!error || !(error instanceof ApiError)) {
    return defaultMessageString;
  }

  const validationErrors = getValidationErrors(error);
  if (!validationErrors.length) {
    const errorMessages = getErrorMessages(error);
    return errorMessages.length ? errorMessages[0] : defaultMessageString;
  }

  // take first validation error
  const validationError = validationErrors[0];

  // try to get message by error's code
  let messageByCode;
  // first try provided map
  if (validationErrorMessages?.[validationError.code]) {
    messageByCode = intl
      ? intl.formatMessage(validationErrorMessages[validationError.code])
      : undefined;
  }
  // else try a default platform error message map
  if (!messageByCode) {
    messageByCode = intl ? getErrorMessageByCode(intl, validationError.code) : undefined;
  }
  // finally, display one or another message
  return messageByCode ?? validationError.message ?? defaultMessageString;
};

export default getErrorMessage;
