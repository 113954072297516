import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const entityHistoryState = (state: RootState) => state.component.entityHistory;

export const isEntityHistoryLoading = createSelector(entityHistoryState, ({ loading }) => loading);

export const getEntityHistory = createSelector(entityHistoryState, ({ history }) => history);

export const getEntityHistoryPageCount = createSelector(
  entityHistoryState,
  ({ pageCount }) => pageCount
);
