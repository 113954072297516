import { isSameDay } from "date-fns/esm";
import { defineMessages, IntlShape } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";

import { formatDate, getRelativeMessagePrefix } from "@app/util/formatters";
import { parseApiDate } from "@app/util/parsers";

const messages = defineMessages({
  allDayString: {
    id: "activityDate.allDayString",
    defaultMessage: "{prefix}",
  },
  onlyCompletedAt: {
    id: "activityDate.onlyCompletedAt",
    defaultMessage: "p",
  },
  onlyCompletedString: {
    id: "activityDate.onlyComplete",
    defaultMessage: "{prefix} · {complete}",
  },
  onlyStartString: {
    id: "activityDate.onlyStart",
    defaultMessage: "{prefix} · {start}",
  },
  onlyStartTime: {
    id: "activityDate.onlyStartTime",
    defaultMessage: "p",
  },
  otherEnd: {
    id: "activityDate.otherEnd",
    defaultMessage: "Pp",
  },
  otherStart: {
    id: "activityDate.otherStart",
    defaultMessage: "Pp",
  },
  otherString: {
    id: "activityDate.otherString",
    defaultMessage: "{start} — {end}",
  },
  relativeDefault: {
    id: "activityDate.regular",
    defaultMessage: "eeee, MMMM do",
  },
  sameDayString: {
    id: "activityDate.sameDay",
    defaultMessage: "{prefix} · {start} – {end}",
  },
  sameDayTimeEnd: {
    id: "activityDate.sameDay.timeEnd",
    defaultMessage: "p",
  },
  sameDayTimeStart: {
    id: "activityDate.sameDay.timeStart",
    defaultMessage: "p",
  },
});

const formatAllDay = (intl: IntlShape, startAt: Date): string => {
  const prefixFormat = getRelativeMessagePrefix(startAt, messages.relativeDefault);
  return intl.formatMessage(messages.allDayString, {
    prefix: formatDate(startAt, intl.formatMessage(prefixFormat)),
  });
};

const formatOnlyStart = (intl: IntlShape, startAt: Date): string => {
  const start = formatDate(startAt, intl.formatMessage(messages.onlyStartTime));

  const prefixFormat = getRelativeMessagePrefix(startAt, messages.relativeDefault);
  return intl.formatMessage(messages.onlyStartString, {
    prefix: formatDate(startAt, intl.formatMessage(prefixFormat)),
    start,
  });
};

const formatSameDay = (intl: IntlShape, startAt: Date, endAt: Date): string => {
  const start = formatDate(startAt, intl.formatMessage(messages.sameDayTimeStart));
  const end = formatDate(endAt, intl.formatMessage(messages.sameDayTimeEnd));

  const prefixFormat = getRelativeMessagePrefix(startAt, messages.relativeDefault);
  return intl.formatMessage(messages.sameDayString, {
    end,
    prefix: formatDate(startAt, intl.formatMessage(prefixFormat)),
    start,
  });
};

const formatOnlyCompletedAt = (intl: IntlShape, completedAt: Date): string => {
  const complete = formatDate(completedAt, intl.formatMessage(messages.onlyCompletedAt));

  const prefixFormat = getRelativeMessagePrefix(completedAt, messages.relativeDefault);
  return intl.formatMessage(messages.onlyCompletedString, {
    complete,
    prefix: formatDate(completedAt, intl.formatMessage(prefixFormat)),
  });
};

const getFormattedDate = (intl: IntlShape, activity?: Activity): string => {
  if (activity?.completed && activity.completedAt) {
    const completedAt = parseApiDate(activity.completedAt);
    return formatOnlyCompletedAt(intl, completedAt);
  }

  if (!activity?.startAt) {
    return "";
  }

  const startAt = parseApiDate(activity.startAt);
  const endAt = activity.endAt ? parseApiDate(activity.endAt) : undefined;

  if (!endAt) {
    return activity.allDay ? formatAllDay(intl, startAt) : formatOnlyStart(intl, startAt);
  }

  if (isSameDay(startAt, endAt)) {
    return formatSameDay(intl, startAt, endAt);
  }

  return intl.formatMessage(messages.otherString, {
    end: formatDate(endAt, intl.formatMessage(messages.otherEnd)),
    start: formatDate(startAt, intl.formatMessage(messages.otherStart)),
  });
};

export default getFormattedDate;
