import React, { MouseEvent, useCallback } from "react";
import { connect } from "react-redux";

import { faArrowAltCircleRight as faArrowAltCircleRightSolid } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import { faArrowAltCircleRight } from "@fortawesome/pro-regular-svg-icons/faArrowAltCircleRight";
import { faArrowTurnDownLeft } from "@fortawesome/pro-solid-svg-icons/faArrowTurnDownLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";

import LeadFinderSubscriptionPlan from "@mapmycustomers/shared/enum/LeadFinderSubscriptionPlan";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { getLeadGenPlan, isCurrentUserOwner } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import showLeadFinderUpsell from "@app/util/leadFinder/showLeadFinderUpsell";

import styles from "./LeadFinderSearch.module.scss";

interface Props {
  isOwner: boolean;
  isSelected: boolean;
  leadGenPlan?: LeadFinderSubscriptionPlan;
  onClick: () => void;
  onMouseMove: () => void;
}

const LeadFinderSearch: React.FC<Props> = ({
  isOwner,
  isSelected,
  leadGenPlan,
  onClick,
  onMouseMove,
}) => {
  const intl = useIntl();

  const handleUpgradeClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      stopEvents(e);
      showLeadFinderUpsell(leadGenPlan, intl, isOwner);
    },
    [intl, isOwner, leadGenPlan]
  );

  return (
    <div className={styles.container} onClick={onClick} onMouseMove={onMouseMove}>
      <div className={styles.info}>
        <div className={styles.title}>
          {intl.formatMessage({
            id: "component.globalSearch.leadFinder.text",
            defaultMessage: "Find new leads based on this search",
            description: "Lead finder on global search item",
          })}
          <FontAwesomeIcon icon={isSelected ? faArrowAltCircleRightSolid : faArrowAltCircleRight} />
        </div>
        {leadGenPlan === LeadFinderSubscriptionPlan.BASIC && (
          <div className={styles.upgrade} onClick={handleUpgradeClick}>
            {intl.formatMessage({
              id: "component.globalSearch.leadFinder.upgrade",
              defaultMessage: "Upgrade for 3x results per search",
            })}
          </div>
        )}
      </div>
      {isSelected && (
        <div>
          <FontAwesomeIcon className={styles.enterIcon} icon={faArrowTurnDownLeft} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOwner: isCurrentUserOwner(state),
  leadGenPlan: getLeadGenPlan(state),
});

export default connect(mapStateToProps)(LeadFinderSearch);
