import { defineMessages, IntlShape } from "react-intl";

import CardType from "@app/enum/dashboard/CardType";
import { UserPreviewCard } from "@app/types/dashboard/DashboardCard";

import { UserPreviewCardType } from "./const";

const messages = defineMessages<UserPreviewCardType>({
  [CardType.ACTIVITIES_LOGGED]: {
    id: "userPreviewCard.chartSelectionModal.charts.activitiesLogged.title",
    defaultMessage:
      "Activities Logged {dateRange, select, day {{dateSubRange, select, this {today} last {yesterday} other {}}} week {{dateSubRange, select, this {this week} last {last week} other {}}} month {{dateSubRange, select, this {this month} last {last month} other {}}} quarter {{dateSubRange, select, this {this quarter} last {last quarter} other {}}} year {{dateSubRange, select, this {this year} last {last year} other {}}} other{}}",
    description: "Title of the Activities Logged card",
  },
  [CardType.ACTIVITIES_OVERDUE]: {
    id: "userPreviewCard.chartSelectionModal.charts.activitiesOverdue.title",
    defaultMessage: "Activities Overdue",
    description: "Title of the Activities Overdue card",
  },
  [CardType.CHECK_INS]: {
    id: "userPreviewCard.chartSelectionModal.charts.checkIns.title",
    defaultMessage:
      "Check-Ins {dateRange, select, day {{dateSubRange, select, this {today} last {yesterday} other {}}} week {{dateSubRange, select, this {this week} last {last week} other {}}} month {{dateSubRange, select, this {this month} last {last month} other {}}} quarter {{dateSubRange, select, this {this quarter} last {last quarter} other {}}} year {{dateSubRange, select, this {this year} last {last year} other {}}} other{}}",
    description: "Title of the Check-Ins card",
  },
  [CardType.NEW_RECORDS]: {
    id: "userPreviewCard.chartSelectionModal.charts.newRecords.title",
    defaultMessage:
      "New {entityType, select, accounts {Companies} contacts {People} deals {Deals} other {Records}} {dateRange, select, day {{dateSubRange, select, this {today} last {yesterday} other {}}} week {{dateSubRange, select, this {this week} last {last week} other {}}} month {{dateSubRange, select, this {this month} last {last month} other {}}} quarter {{dateSubRange, select, this {this quarter} last {last quarter} other {}}} year {{dateSubRange, select, this {this year} last {last year} other {}}} other{}}",
    description: "Title of the New Records card",
  },
  [CardType.NO_CONTACT_IN_COUNT]: {
    id: "userPreviewCard.chartSelectionModal.charts.noContactInCount.title",
    defaultMessage:
      "{neverContacted, select, true {No contact yet} other {No contact in over {days} {days, plural, one {day} other {days}}}}",
    description: "Title of the No Contact In (Count) card",
  },
  [CardType.RECORDS_PAST_DUE]: {
    id: "userPreviewCard.chartSelectionModal.charts.recordsPastDue.title",
    defaultMessage:
      "{entityType, select, accounts {Companies} contacts {People} deals {Deals} other {Records}} Past Due",
    description: "Title of the Records Past Due card",
  },
});

const getChartTitle = (intl: IntlShape, card: UserPreviewCard) => {
  return (
    card.configuration.customTitle?.trim() ||
    intl.formatMessage(messages[card.type], {
      dateRange:
        card.configuration.dateRange &&
        card.configuration.dateRange !== null &&
        "range" in card.configuration.dateRange
          ? card.configuration.dateRange?.range
          : undefined,
      dateSubRange:
        card.configuration.dateRange &&
        card.configuration.dateRange !== null &&
        "subRange" in card.configuration.dateRange
          ? card.configuration.dateRange?.subRange
          : undefined,
      days:
        card.configuration.dateRange &&
        card.configuration.dateRange !== null &&
        "days" in card.configuration.dateRange
          ? card.configuration.dateRange?.days
          : undefined,
      entityType:
        card.configuration.criteria && "entityType" in card.configuration.criteria
          ? card.configuration.criteria.entityType
          : undefined,
      neverContacted:
        card.configuration.dateRange &&
        card.configuration.dateRange !== null &&
        "days" in card.configuration.dateRange
          ? card.configuration.dateRange?.days === null
          : undefined,
    })
  );
};

export default getChartTitle;
