import React from "react";

import { FormattedMessage } from "react-intl";

import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";

interface Props {}

const InvalidCodeError: React.FC<Props> = () => {
  return (
    <div>
      <p className={commonStyles.title}>
        <FormattedMessage
          id="auth.registerMember.error.invalidCode.title"
          defaultMessage="Something went wrong"
          description="Title of the error state in the member register form"
        />
      </p>
      <p className={commonStyles.description}>
        <FormattedMessage
          id="auth.registerMember.error.invalidCode.description"
          defaultMessage="It seems you’ve used a bad invitation link. Please click directly on the link in the email."
          description="Description of the error state in the member register form"
        />
      </p>
    </div>
  );
};

export default InvalidCodeError;
