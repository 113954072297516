import React from "react";

import Tooltip from "antd/es/tooltip";

import { Territory } from "@mapmycustomers/shared/types/entity";

import EntityTag from "@app/component/EntityTag";
import { RegistryProps } from "@app/component/FieldGrid/utils/ComponentRegistry";

const TerritoryField: React.FC<RegistryProps> = ({ entity, field }) => {
  const territories =
    (field.getValueFor(entity) as Pick<Territory, "color" | "id" | "name">[]) ?? [];

  return (
    <div>
      <Tooltip title={field.getFormattedValueFor(entity)}>
        {territories.map((territory) => (
          <EntityTag entity={territory} key={territory.id} />
        ))}
      </Tooltip>
    </div>
  );
};

export default TerritoryField;
