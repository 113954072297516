import GeoAddress from "../types/GeoAddress";
import User from "../types/User";

import countryListOptions from "./country-options.json";

export const userDisplayName = (user: Pick<User, "fullName" | "username">) =>
  user.fullName || user.username;

export const formatFileSize = (size: number) => {
  if (size < 1024) {
    return `${size}B`;
  } else if (size < 1024 * 1024) {
    return `${Math.round(size / 1024)}KB`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${Math.round(size / (1024 * 1024))}MB`;
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(1)}GB`;
  }
};
export const getFormattedAddressForUi = (address?: GeoAddress): string => {
  if (!address) {
    return "";
  }
  const country =
    countryListOptions.find(({ value }) => value === address.countryCode)?.label ??
    address.countryCode;

  return [
    address.address,
    address.city,
    // Do not put region if it matches the city. Improves readability
    address.city === address.region ? "" : address.region,
    address.fullPostalCode,
    country,
  ]
    .filter((x) => !!x)
    .join(", ");
};
