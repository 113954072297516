import GeoAddress from "@mapmycustomers/shared/types/GeoAddress";
import { isDefined, isNotEmpty } from "@mapmycustomers/shared/util/assert";

const getShortAddress = (geoAddress?: GeoAddress): string => {
  if (!geoAddress) {
    return "";
  }
  return [
    geoAddress.streetName ?? geoAddress.address,
    geoAddress.city,
    geoAddress.city === geoAddress.regionCode ? undefined : geoAddress.regionCode,
  ]
    .filter((item) => isDefined(item) && isNotEmpty(item) && item.length)
    .join(", ");
};

export default getShortAddress;
