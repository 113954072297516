import React from "react";

import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import TextArea from "antd/es/input/TextArea";
import Select from "antd/es/select";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import { LARGE_TEXT_LENGTH_LIMIT } from "@mapmycustomers/shared/util/consts";

import Checkbox from "@app/component/input/Checkbox";
import commonStyles from "@app/scene/auth/component/AuthCommon.module.scss";
import SignupField from "@app/scene/auth/component/Register/enum/SignupField";
import BaseStepProps from "@app/scene/auth/component/Register/types/BaseStepProps";
import {
  useAcceptAgreementValidation,
  useRequiredValidation,
} from "@app/scene/auth/component/Register/utils/validation-hooks";

import styles from "../../Steps.module.scss";

interface Props extends BaseStepProps {
  isLoading: boolean;
}

const OrgDetailsStep: React.FC<Props> = ({ className, isLoading, isValid, onBackClick }) => {
  const intl = useIntl();

  const requiredValidation = useRequiredValidation(intl);
  const tncValidation = useAcceptAgreementValidation(intl);

  return (
    <div className={cn(styles.stepContainer, className)}>
      <p className={commonStyles.description}>
        <FormattedMessage
          id="auth.register.orgDetailsStep.description"
          defaultMessage="Getting setup is easy. It only takes a minute!"
          description="Brief description of easy sign up process"
        />
      </p>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.register.orgDetailsStep.companyName",
          defaultMessage: "Company Name",
          description: "Title of the Company name field on the register form",
        })}
        name={SignupField.ORGANIZATION_NAME}
        requiredMark="optional"
        rules={requiredValidation}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        hasFeedback
        label={intl.formatMessage({
          id: "auth.register.orgDetailsStep.sales",
          defaultMessage: "How many outside sales reps are on your team?",
          description: "Title of the sales reps field on the register form",
        })}
        name={SignupField.NUM_FIELDS_REPS}
        requiredMark="optional"
        rules={requiredValidation}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.register.orgDetailsStep.referralSource",
          defaultMessage: "How did you hear about us?",
          description: "Title of the referral source field on the register form",
        })}
        name={SignupField.REFERRAL_SOURCE}
        requiredMark="optional"
        rules={requiredValidation}
      >
        <TextArea maxLength={LARGE_TEXT_LENGTH_LIMIT} />
      </Form.Item>
      <Form.Item
        hasFeedback
        label={intl.formatMessage({
          id: "auth.register.orgDetailsStep.crm",
          defaultMessage: "Which CRM do you use?",
          description: "Title of the crm field on the register form",
        })}
        name={SignupField.CRM}
        requiredMark="optional"
        rules={requiredValidation}
      >
        <Select size="large">
          <Select.Option value="salesforce">Salesforce</Select.Option>
          <Select.Option value="hubspot">Hubspot</Select.Option>
          <Select.Option value="dynamics">Dynamics</Select.Option>
          <Select.Option value="zoho">Zoho</Select.Option>
          <Select.Option value="other">
            {intl.formatMessage({
              id: "auth.register.crmOptions.other",
              defaultMessage: "Other",
              description: "`Other` option at CRM select field on organization registration page",
            })}
          </Select.Option>
          <Select.Option value="na">
            {intl.formatMessage({
              id: "auth.register.crmOptions.none",
              defaultMessage: "None",
              description: "`None` option at CRM select field on organization registration page",
            })}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.tncField}
        name={SignupField.AGREE_WITH_TNC}
        rules={tncValidation}
        valuePropName="checked"
      >
        <Checkbox className={styles.checkboxWrapper}>
          <FormattedMessage
            id="auth.register.orgDetailsStep.tncLinkTitle"
            defaultMessage="I agree to the <link>Terms & Conditions</link>"
            description="Title of the tnc Link"
            values={{
              link: (linkText: string) => (
                <a
                  href="https://mapmycustomers.com/terms/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {linkText}
                </a>
              ),
            }}
          />
        </Checkbox>
      </Form.Item>
      <Form.Item name={SignupField.PROMO_EMAILS} valuePropName="checked">
        <Checkbox className={styles.checkboxWrapper}>
          <FormattedMessage
            id="auth.register.orgDetailsStep.promoEmails"
            defaultMessage="Please send me training emails, tips for using the app,{br}new feature emails, and other promotion emails"
            description="Title of the promotional email checkbox on the 3rd step of Registration page"
            values={{
              br: <br />,
            }}
          />
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <div className={cn(styles.buttons, styles.verticalButtons)}>
          <Button
            className={styles.submitButton}
            disabled={!isValid || isLoading}
            htmlType="submit"
            loading={isLoading}
            type="primary"
          >
            {isLoading
              ? intl.formatMessage({
                  id: "auth.register.submitButton.loading",
                  defaultMessage: "Signing up...",
                  description:
                    "Title of the submiting current details of first step of the register form",
                })
              : intl.formatMessage({
                  id: "auth.register.submitButton.register",
                  defaultMessage: "Let's dive in!",
                  description: "Title of the register button for third step of the register form",
                })}
          </Button>
          <Button htmlType="button" onClick={onBackClick} type="link">
            <FormattedMessage
              id="auth.register.previousStepButton"
              defaultMessage="Go back"
              description="Title of the Go Back button for the Register Form"
            />
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};

export default OrgDetailsStep;
