import { isPast } from "date-fns/esm";

import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import { Activity } from "@mapmycustomers/shared/types/entity";

import isValidDate from "@app/util/isValidDate";
import { parseApiDate } from "@app/util/parsers";

const getActivityStatus = (activity: Activity): ActivityStatus => {
  if (activity.completed) {
    return ActivityStatus.COMPLETED;
  }
  const startAt = parseApiDate(activity.startAt ?? "");
  if (!isValidDate(startAt)) {
    return ActivityStatus.UNKNOWN;
  }
  return isPast(startAt) ? ActivityStatus.OVERDUE : ActivityStatus.UPCOMING;
};

export default getActivityStatus;
