@use "../../../styles/colors";

.popconfirmContainer {
  width: 16rem;
}
.popconfirmTitle {
  color: colors.$mmc-slate;
  margin-bottom: 0.5rem;
  font-weight: 700;
}
