import { createReducer } from "typesafe-actions";

import { setTrafficSettings, TrafficActions } from "./actions";
import TrafficLayerData from "./TrafficLayerData";

export const trafficInitialState: TrafficLayerData = {
  legendExpanded: false,
  visible: false,
};

const trafficLayer = createReducer<TrafficLayerData, TrafficActions>(
  trafficInitialState
).handleAction(setTrafficSettings, (state, { payload }) => ({
  ...state,
  ...payload,
}));

export default trafficLayer;
