const messages = {
  "ui.emailBody.attachedFiles.download": "Download",
  "ui.emailBody.attachedFiles.fileSize": "{size, number, ::K}MB",
  "ui.emailBody.attachedFiles.largeFilesWarning.description":
    "The size of your attempted upload exceeds 24 MB. Please compress the file and try again.",
  "ui.emailBody.attachedFiles.largeFilesWarning.ok": "Okay",
  "ui.emailBody.attachedFiles.largeFilesWarning.title": "Attachment size limit exceeded",
  "ui.emailBody.attachedFiles.remove": "Remove attachment",
} as const;

export default messages;
