import { IntlShape } from "react-intl";

import {
  EntitiesSupportedByEmailFeature,
  EntityTypesSupportedByEmailFeature,
} from "@mapmycustomers/shared/types/entity";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { EmailRecipientMissedFields } from "@mapmycustomers/ui";
import { DynamicVarVariant } from "@mapmycustomers/ui/src";

import getFieldDynamicVariableName from "@app/util/email/getFieldDynamicVariableName";

import getFieldModelByEntityType from "../getByEntityType";
import { isCustomField } from "../impl/assert";

const getDynamicVarVariants = (
  intl: IntlShape,
  entityType: EntityTypesSupportedByEmailFeature,
  entities: EntitiesSupportedByEmailFeature[],
  allowedFieldNames?: string[]
): [DynamicVarVariant[], EmailRecipientMissedFields] => {
  const variants: DynamicVarVariant[] = [];
  const missedFields: EmailRecipientMissedFields = {};
  getFieldModelByEntityType(entityType).emailDynamicVarFields.forEach((field) => {
    const entityHasValue = entities.find((entity) => isDefined(field.getFormattedValueFor(entity)));

    if (!allowedFieldNames || allowedFieldNames.includes(field.name)) {
      let allEntitiesHaveValues = true;
      entities.forEach((entity) => {
        const entityHasValue = !!field.getValueFor(entity);
        allEntitiesHaveValues = allEntitiesHaveValues && entityHasValue;
        if (!entityHasValue) {
          if (!missedFields[entity.id]) {
            missedFields[entity.id] = [];
          }
          missedFields[entity.id].push(field.displayName);
        }
      });
    }

    variants.push({
      allEntitiesHaveValues: entities.every((entity) => !!field.getValueFor(entity)),
      displayName: getFieldDynamicVariableName(intl, entityType, field),
      displayValue: entityHasValue ? field.getFormattedValueFor(entityHasValue) : "",
      name: field.name,
      prefix: isCustomField(field) ? "cf" : "std",
    });
  });
  return [variants, missedFields];
};

export default getDynamicVarVariants;
