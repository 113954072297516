import React, { ReactNode, useCallback, useRef, useState } from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import cn from "classnames";

import { OptionalFields } from "@mapmycustomers/shared";
import Currency from "@mapmycustomers/shared/types/Currency";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { useConfigProvider } from "../../../ConfigProvider";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import NumberField, { NumberFieldProps } from "../../NumberField";
import Footer from "../Footer";

export interface InlineMonetaryFieldProps
  extends Omit<LabeledFieldProps, "children" | "suffix">,
    Pick<NumberFieldProps, "max" | "min" | "precision"> {
  allowEmpty?: boolean;
  caption?: string;
  className?: string;
  currencies?: Currency[];
  currencyId?: Currency["id"];
  disabled?: boolean;
  error?: ReactNode;
  onChange?: (
    // the value is optional only when allowEmpty prop is true,
    // otherwise, a fully defined MonetaryValue will be sent
    value: MonetaryValue | OptionalFields<MonetaryValue, "value">
  ) => void;
  value?: MonetaryValue;
}

const block = bem("mmc-inline-monetary-field");

const MonetaryField: React.FC<InlineMonetaryFieldProps> = ({
  allowEmpty,
  caption,
  className,
  currencies,
  currencyId,
  disabled,
  label,
  labelClassName,
  labelPosition = "side",
  onChange,
  required,
  value,
  ...props
}) => {
  const configProvider = useConfigProvider();
  const [inlineValue, setInlineValue] = useState<number | undefined>(value?.value);

  const [editing, startEditing, cancelEditing] = useBoolean();

  const handleChange = useCallback((value: number | undefined) => setInlineValue(value), []);

  const handleSave = useCallback(() => {
    cancelEditing();
    if (currencyId && onChange && (allowEmpty || isDefined(inlineValue))) {
      onChange({ currencyId, value: inlineValue });
    }
  }, [allowEmpty, currencyId, inlineValue, cancelEditing, onChange]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape") {
        event.stopPropagation();
        cancelEditing();
      } else if (event.key === "Enter") {
        event.stopPropagation();
        handleSave();
      }
    },
    [cancelEditing, handleSave]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const handleStartEditing = useCallback(() => {
    if (disabled) {
      return;
    }
    setInlineValue(value?.value);
    startEditing();
    // can't focus hidden field, need to wait until it will be displayed
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [disabled, setInlineValue, startEditing, value]);

  const handleBlur = useCallback(
    (e) => {
      // To prevent blur action when cancel button is clicked as we save text onblur
      if (e.relatedTarget && e.relatedTarget.classList.contains("mmc-js-skip-save-on-blur")) {
        e.preventDefault();
        cancelEditing();
        return;
      }
      handleSave();
    },
    [cancelEditing, handleSave]
  );

  const currency = currencies?.find((currency) => currency.id === currencyId);

  return (
    <div className={cn(block({ disabled }), className)}>
      <Labeled
        extra={disabled ? <FontAwesomeIcon className={block("lock")} icon={faLock} /> : undefined}
        label={<div className={block("label-item")}>{label}</div>}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        required={required}
      >
        {editing && !disabled ? (
          <NumberField
            addonAfter={<span className={block("symbol")}>{currency?.code}</span>}
            controls={false}
            disabled={disabled}
            fullWidth
            inputClassName={block("input")}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            precision={2}
            required={required}
            size="large"
            value={inlineValue}
            {...props}
          />
        ) : (
          <div className={block("value")} onClick={handleStartEditing}>
            {value?.value ? (
              <>
                {configProvider.formatNumber(value.value, {
                  currency: currency?.code,
                  minimumFractionDigits: 0,
                  style: "currency",
                })}
                <span className={block("symbol")}>{currency?.code}</span>
              </>
            ) : (
              <span className={block("unknown")}>
                {configProvider.formatMessage("ui.inlineInput.unknown")}
              </span>
            )}
          </div>
        )}
      </Labeled>
      <Footer
        caption={caption}
        disabled={disabled}
        editing={editing}
        onCancel={cancelEditing}
        onSave={handleSave}
      />
    </div>
  );
};

export default MonetaryField;
