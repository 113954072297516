import React from "react";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import LossReasonComponent from "./LossReasonComponent";

export const LOSS_REASON_FILTER_OPERATORS = [FilterOperator.IN_ANY, FilterOperator.NONE_OF];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  [FilterOperator.EMPTY, FilterOperator.NOT_EMPTY].includes(operator);

interface LossReasonFilterProps extends IFilterComponentProps {}

const LossReasonFilter: IFilterInstance = {
  doesSupportValue,
  getComponent:
    (): React.FC<LossReasonFilterProps> =>
    ({ className, onChange, value }) => {
      return (
        <LossReasonComponent
          className={className}
          onChange={(lossReasonIds: DealLossReason["id"][]) =>
            onChange?.({ ...value, value: lossReasonIds })
          }
          value={Array.isArray(value.value) ? value.value : undefined}
        />
      );
    },
};

export default LossReasonFilter;
