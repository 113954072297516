import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfToday,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfToday,
} from "date-fns/esm";

import DateRangeType from "@mapmycustomers/shared/enum/DateRangeType";
import DateRange, { StandardRange } from "@mapmycustomers/shared/types/range/DateRange";

import { getWeekEnd, getWeekStart } from "@app/util/dates";

import parseRange from "./parseRange";

const standardRangeToDateRange = (range: StandardRange): DateRange | undefined => {
  const defaultRange = {
    endDate: endOfToday(),
    startDate: startOfToday(),
  };

  const result = parseRange(range);
  if (!result) {
    return undefined;
  }

  switch (result.dateRangeType) {
    case DateRangeType.DAY:
      return {
        endDate: endOfDay(addDays(defaultRange.endDate, result.offset)),
        startDate: startOfDay(addDays(defaultRange.startDate, result.offset)),
      };
    case DateRangeType.WEEK:
      return {
        endDate: getWeekEnd(addWeeks(defaultRange.endDate, result.offset)),
        startDate: getWeekStart(addWeeks(defaultRange.startDate, result.offset)),
      };
    case DateRangeType.MONTH:
      return {
        endDate: endOfMonth(addMonths(defaultRange.endDate, result.offset)),
        startDate: startOfMonth(addMonths(defaultRange.startDate, result.offset)),
      };
    case DateRangeType.QUARTER:
      return {
        endDate: endOfQuarter(addQuarters(defaultRange.endDate, result.offset)),
        startDate: startOfQuarter(addQuarters(defaultRange.startDate, result.offset)),
      };
    default:
      return undefined;
  }
};

export default standardRangeToDateRange;
