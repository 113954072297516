import React from "react";

import { bem } from "@react-md/utils";
import AntdCollapse, { CollapseProps } from "antd/es/collapse";
import cn from "classnames";

import Collapse from "../Collapse";

const block = bem("mmc-card-collapse");

export interface CardCollapseProps
  extends Omit<CollapseProps, "expandIcon" | "expandIconPosition" | "ghost"> {
  size?: "default" | "small";
}

const CardCollapse: React.FC<CardCollapseProps> = ({
  bordered = true,
  children,
  className,
  size,
  ...restProps
}) => {
  return (
    <Collapse
      className={cn(block({ bordered, small: size === "small" }), className)}
      expandIconPosition="end"
      ghost
      {...restProps}
    >
      {children}
    </Collapse>
  );
};

export const CollapsePanel = AntdCollapse.Panel;

export default CardCollapse;
