import React, { SyntheticEvent, useMemo } from "react";

import Menu from "antd/es/menu";
import { IntlShape } from "react-intl";

import Setting from "@mapmycustomers/shared/types/Setting";

import MmcNotificationAction from "@app/enum/MmcNotificationAction";

import isNotificationEnabled from "./isNotificationEnabled";

const useNotificationMenu = (
  intl: IntlShape,
  setting: Setting,
  action: MmcNotificationAction,
  onOptOut: (item: { domEvent: SyntheticEvent<HTMLElement> }) => void,
  onGoToSettings: (item: { domEvent: SyntheticEvent<HTMLElement> }) => void
) =>
  useMemo(
    () => (
      <Menu>
        {setting.value.enabled && isNotificationEnabled(action, setting.value) && (
          <Menu.Item key="optOutNotifications" onClick={onOptOut}>
            {intl.formatMessage({
              id: "navbar.notifications.list.notification.optOutNotifications",
              defaultMessage: "Opt out of notifications like these",
              description:
                "Navbar Notifications - list - notification - Opt out of notifications like these",
            })}
          </Menu.Item>
        )}
        <Menu.Item key="manageAllNotifications" onClick={onGoToSettings}>
          {intl.formatMessage({
            id: "navbar.notifications.list.notification.manageAllNotifications",
            defaultMessage: "Manage All Notifications",
            description: "Navbar Notifications - list - notification - Manage All Notifications",
          })}
        </Menu.Item>
      </Menu>
    ),
    [intl, onGoToSettings, onOptOut, action, setting.value]
  );

export default useNotificationMenu;
