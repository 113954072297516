enum MmcNotificationAction {
  ACTIVITY_ASSIGNED = "activity assigned",
  ACTIVITY_LOGGED = "activity logged",
  ACTIVITY_STARTING = "activity starting",
  ACTIVITY_TIME_CHANGED = "activity time changed",
  COMPANY_GROUP_SHARED = "company group shared",
  COMPANY_ROUTE_SHARED = "company route shared",
  DEAL_GROUP_SHARED = "deal group shared",
  DEALS_MARKED_LOST = "deals marked lost",
  DEALS_MARKED_WON = "deals marked won",
  DEALS_ROTTING = "deals rotting",
  DEALS_STAGE_CHANGED = "deals stage changed",
  INCOMPLETE_ACTIVITY = "incomplete activity",
  MARK_ACTIVITY_AS_DONE = "mark activity as done",
  MENTION = "mention",
  NOTE_ADDED = "note added",
  OUT_OF_CADENCE = "out of cadence",
  OVERDUE_ACTIVITIES = "overdue activities",
  OWNED_DEALS_CLOSING = "owned deals closing",
  OWNERSHIP_CHANGED = "ownership changed",
  PEOPLE_GROUP_SHARED = "people group shared",
  PEOPLE_ROUTE_SHARED = "people route shared",
  SHARED_DEALS_CLOSING = "shared deals closing",
}

export default MmcNotificationAction;
