import color from "color";

import AnyColor from "@mapmycustomers/shared/types/AnyColor";

import anyColorToHex from "@app/util/colors/anyColorToHex";

const getSemiTransparentColor = (value: AnyColor, fadeValue: number = 0.9) =>
  color(anyColorToHex(value)).fade(fadeValue).rgb().string();

export default getSemiTransparentColor;
