import { useMemo } from "react";

import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import FormLayout, {
  ChildLayout,
  LayoutSchemaField,
} from "@mapmycustomers/shared/types/layout/FormLayout";

import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import useActualLayoutSchema from "@app/util/useActualLayoutSchema";

const useSchema = (
  entityType: EntityTypesSupportingFieldCustomization,
  layout: FormLayout,
  filterFields: (schemaField: LayoutSchemaField) => boolean,
  isCreateForm?: boolean
): [
  actualSchema: LayoutSchemaField[],
  childLayout: ChildLayout | undefined,
  isVariant: boolean
] => {
  const form = useFormInstance();
  const stageId = useWatch("stageId", form);
  const crmActivityTypeId = useWatch("activityTypeId", form);
  const [fieldsSchema, childLayout, isVariant] = useActualLayoutSchema({
    crmActivityTypeId,
    entityType,
    layout,
    stageId,
  });
  const fieldModel = useMemo(() => getFieldModelByEntityType(entityType), [entityType]);

  const schema = useMemo(
    () =>
      fieldsSchema
        .filter((schemaField) => {
          const field = fieldModel.getByPlatformName(schemaField.field);
          return (
            // interestingly, it is possible to have field in the layout, but archived
            !field?.isArchived &&
            (schemaField.fieldType === SchemaFieldType.DIVIDER ||
              // show only editable fields in create form (or non-editable too if they're required)
              // show all fields in create form respecting the layout (including no access fields if in layout)
              // show all fields in edit form
              (isCreateForm && (field?.isEditable || schemaField.required)) ||
              isCreateForm ||
              (!isCreateForm && field?.isReadable))
          );
        })
        .filter(filterFields),
    [fieldModel, filterFields, isCreateForm, fieldsSchema]
  );

  return [schema, childLayout, isVariant];
};

export default useSchema;
