import React, { useMemo } from "react";
import { connect } from "react-redux";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import Setting from "@mapmycustomers/shared/types/Setting";

import OrganizationSetting from "@app/enum/OrganizationSetting";
import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import TimeZones from "@app/enum/TimeZones";
import { getOrganizationSettingValue, getUserSettings } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import layout from "@app/styles/layout";
import TimeZone from "@app/types/TimeZone";

import ButtonLink from "../../ButtonLink";

import styles from "./EmailPeriod.module.scss";
import messages from "./messages";

interface Props {
  onSettings?: () => void;
  settings: Setting[];
  showIcon?: boolean;
  timezoneSettingValue?: string;
}

const EmailPeriod: React.FC<Props> = ({ onSettings, settings, showIcon, timezoneSettingValue }) => {
  const intl = useIntl();

  const bulkEmail: Setting = useMemo(
    () => settings.find(({ key }) => key === "bulkEmail")!,
    [settings]
  );

  return (
    <div>
      <Row align="middle" gutter={layout.spacerM}>
        {showIcon ? <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} /> : null}
        <Col className={styles.container}>
          {intl.formatMessage(messages.period, {
            endTime: bulkEmail.value.endTime ?? "",
            startTime: bulkEmail.value.startTime ?? "",
            timezone: intl.formatMessage(TimeZones[timezoneSettingValue as TimeZone]) ?? "",
          })}
        </Col>
        <Col>
          <Link to={`${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`}>
            <ButtonLink onClick={onSettings}>
              {intl.formatMessage(messages.changePeriod)}
            </ButtonLink>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  settings: getUserSettings(state)!,
  timezoneSettingValue: getOrganizationSettingValue(state)(OrganizationSetting.TIMEZONE),
});

export default connect(mapStateToProps)(EmailPeriod);
