import { createSelector } from "reselect";

import Identified from "@mapmycustomers/shared/types/base/Identified";
import { EntityTypeSupportingGroups } from "@mapmycustomers/shared/types/entity";

import { GroupByType } from "@app/scene/reports/components/Overview/components/Engagement/enum/GroupByType";
import { GroupByEntity } from "@app/scene/reports/components/Overview/components/Engagement/types/GroupByEntity";
import { getFunnels } from "@app/store/deal";
import { stateFieldBaseName } from "@app/store/groups/util";
import { getTeams, getUsers } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";

import { DealsGroupByType } from "../enum/DealsGroupByType";
import { DealsGroupBy } from "../types/DealGroupBy";
import { RecordsStats } from "../types/RecordsStats";

const homeState = (state: RootState) => state.scene.home;

export const isLoading = createSelector(homeState, ({ loading }) => loading);
export const getError = createSelector(homeState, ({ error }) => error);
export const isTodayActivitiesDataLoading = createSelector(
  homeState,
  ({ todayActivitiesDataLoading }) => todayActivitiesDataLoading
);
export const isTodayActivitiesDataForRepsLoading = createSelector(
  homeState,
  ({ todayActivitiesDataForRepsLoading }) => todayActivitiesDataForRepsLoading
);
export const isActivityByRepDataLoading = createSelector(
  homeState,
  ({ activityByRepDataLoading }) => activityByRepDataLoading
);
export const isActivityByTypesDataLoading = createSelector(
  homeState,
  ({ activityByTypesDataLoading }) => activityByTypesDataLoading
);
export const isFunnelSummaryLoading = createSelector(
  homeState,
  ({ funnelSummaryLoading }) => funnelSummaryLoading
);
export const getTodayActivitiesData = createSelector(
  homeState,
  ({ todayActivitiesData }) => todayActivitiesData
);
export const getTodayActivitiesDataForReps = createSelector(
  homeState,
  ({ todayActivitiesDataForReps }) => todayActivitiesDataForReps
);
export const getActivityByRepData = createSelector(
  homeState,
  ({ activityByRepData }) => activityByRepData
);
export const getActitivityTypesWithCount = createSelector(
  homeState,
  ({ actitivityTypesWithCount }) => actitivityTypesWithCount
);
export const getSelectedTypeData = createSelector(
  homeState,
  ({ selectedTypeData }) => selectedTypeData
);
export const getRecentCompaniesData = createSelector(
  homeState,
  ({ recentCompaniesData }) => recentCompaniesData
);
export const isRecentCompaniesDataLoading = createSelector(
  homeState,
  ({ recentCompaniesDataLoading }) => recentCompaniesDataLoading
);
export const getRecentCompaniesDataError = createSelector(
  homeState,
  ({ recentCompaniesDataError }) => recentCompaniesDataError
);

export const getAllFunnelSummary = createSelector(homeState, ({ funnelSummary }) => funnelSummary);
export const getRecordsBySourceSummary = createSelector(
  homeState,
  ({ recordsBySourceSummary }) => recordsBySourceSummary
);
export const getRecordsBySourceList = createSelector(
  homeState,
  ({ recordsBySourceList }) => recordsBySourceList
);
export const getDealsCount = createSelector(homeState, ({ dealsCount }) => dealsCount);
export const getHasCompanies = createSelector(homeState, ({ hasCompanies }) => hasCompanies);
export const getHasActivities = createSelector(homeState, ({ hasActivities }) => hasActivities);
export const getRottingDealsList = createSelector(
  homeState,
  ({ rottingDealsList }) => rottingDealsList
);
export const getEngagementRecordsList = createSelector(
  homeState,
  ({ engagementRecordsList }) => engagementRecordsList
);
export const getRottingDealsTotal = createSelector(
  homeState,
  ({ rottingDealsTotal }) => rottingDealsTotal
);
export const getRottingDealsListLoading = createSelector(
  homeState,
  ({ rottingDealsLoading }) => rottingDealsLoading
);

export const getActivityOverview = createSelector(
  homeState,
  ({ activityOverview }) => activityOverview
);
export const isActivityOverviewLoading = createSelector(
  homeState,
  ({ activityOverviewLoading }) => activityOverviewLoading
);
export const getOpenDealsData = createSelector(homeState, ({ openDealsData }) => openDealsData);
export const isOpenDealsDataLoading = createSelector(
  homeState,
  ({ openDealsDataLoading }) => openDealsDataLoading
);
export const getDailyChangeData = createSelector(
  homeState,
  ({ dailyChangeData }) => dailyChangeData
);
export const isDailyChangeDataLoading = createSelector(
  homeState,
  ({ dailyChangeDataLoading }) => dailyChangeDataLoading
);

export const getRecordsOutOfCadence = createSelector(
  homeState,
  ({ recordsOutOfCadence }) => recordsOutOfCadence
);

export const isRecordsOutOfCadenceLoading = createSelector(
  homeState,
  ({ recordsOutOfCadenceLoading }) => recordsOutOfCadenceLoading
);

export const getRecordsOutOfCadenceFilter = createSelector(
  homeState,
  ({ recordsOutOfCadenceFilter }) => recordsOutOfCadenceFilter
);

export const getRecordsSourceFilter = createSelector(
  homeState,
  ({ recordsSourceFilter }) => recordsSourceFilter
);
export const getDealsRotting = createSelector(homeState, ({ dealsRotting }) => dealsRotting);

export const getDealRottingStages = createSelector(
  homeState,
  ({ dealsRottingStagesStats }) => dealsRottingStagesStats
);

export const isDealsRottingLoading = createSelector(
  homeState,
  ({ dealsRottingLoading }) => dealsRottingLoading
);

export const getDealsRottingFilter = createSelector(
  homeState,
  ({ dealsRottingFilter }) => dealsRottingFilter
);

export const getTotalrecordsBySourceList = createSelector(
  homeState,
  ({ totalRecordsBySourceList }) => totalRecordsBySourceList
);

export const getTotalrecordsByActivityType = createSelector(
  homeState,
  ({ totalRecordsByActivityType }) => totalRecordsByActivityType
);

export const getTotalrecordsByEngagement = createSelector(
  homeState,
  ({ totalRecordsByEngagementList }) => totalRecordsByEngagementList
);

export const groupByEntitiesSelectors: Record<
  GroupByType,
  (recordTypes: EntityTypeSupportingGroups[]) => (state: RootState) => GroupByEntity[]
> = {
  [GroupByType.GROUP]: (recordTypes) => (state: RootState) => {
    return recordTypes.flatMap((type) => state.groups[stateFieldBaseName[type]]);
  },
  [GroupByType.TEAM]: () => getTeams,
  [GroupByType.USER]: () => getUsers,
};

export const groupByEntitiesSelector = createSelector(
  getRecordsOutOfCadenceFilter,
  (state: RootState) => state,
  ({ groupByType, recordTypes }, state) => groupByEntitiesSelectors[groupByType](recordTypes)(state)
);

const accumulateRecords = (
  record: RecordsStats = { relevant: 0, total: 0 },
  addRecord: RecordsStats
): RecordsStats => ({
  relevant: record.relevant + addRecord.relevant,
  total: record.total + addRecord.total,
});

const recordsOutOfCadenceReducer = (
  acc: Record<Identified["id"], RecordsStats>,
  item: Record<Identified["id"], RecordsStats>
) => {
  Object.entries<RecordsStats>(item).forEach(([id, record]) => {
    acc[+id] = accumulateRecords(acc[+id], record);
  });
  return acc;
};

export const availableGroupByEntitiesSelector = createSelector(
  groupByEntitiesSelector,
  getRecordsOutOfCadence,
  (groupByEntities, recordsOutOfCadenceGrouped) => {
    const recordsOutOfCadence = Object.values<Record<Identified["id"], RecordsStats>>(
      recordsOutOfCadenceGrouped
    ).reduce<Record<Identified["id"], RecordsStats>>(recordsOutOfCadenceReducer, {});
    return groupByEntities.filter((entity) => recordsOutOfCadence[entity.id]);
  }
);

export const dealsGroupByEntitiesSelectors: Record<
  DealsGroupByType,
  (state: RootState) => DealsGroupBy[]
> = {
  [DealsGroupByType.FUNNEL]: getFunnels,
  [DealsGroupByType.TEAM]: getTeams,
  [DealsGroupByType.USER]: getUsers,
};

export const dealsGroupByEntitiesSelector = createSelector(
  getDealsRottingFilter,
  (state: RootState) => state,
  ({ groupByType }, state) => dealsGroupByEntitiesSelectors[groupByType](state)
);

export const availableDealsGroupByEntitiesSelector = createSelector(
  dealsGroupByEntitiesSelector,
  getDealsRotting,
  (groupByEntities, dealsStats) => {
    return groupByEntities.filter((entity) => dealsStats[entity.id]);
  }
);
