import { defineMessages, IntlShape } from "react-intl";

import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";

export const visibilityNotes = defineMessages<ActivityVisibility>({
  [ActivityVisibility.PRIVATE]: {
    id: "activityVisibility.private.note",
    defaultMessage:
      "You, the assignee, team owner(s), and those with access to associated records will see this activity.",
    description: "Visibility note for Private VISIBILITY TYPE option",
  },
  [ActivityVisibility.SHARED_WITH_ORGANIZATION]: {
    id: "activityVisibility.sharedWithOrganization.note",
    defaultMessage: "Users on any team, regardless of role, will see this activity.",
    description: "Visibility note for Shared with ORGANIZATION VISIBILITY TYPE option",
  },
  [ActivityVisibility.SHARED_WITH_TEAM]: {
    id: "activityVisibility.sharedWithTeam.note",
    defaultMessage:
      "Only you, the assignee, users on the team(s) above and those with access to associated records will see this activity.",
    description: "Visibility note for Shared with team VISIBILITY TYPE option",
  },
});

const getActivityVisibilityNote = (intl: IntlShape, activityVisibility: ActivityVisibility) =>
  intl.formatMessage(visibilityNotes[activityVisibility]);

export default getActivityVisibilityNote;
