import React from "react";

import { bem } from "@react-md/utils";
import Col, { ColProps } from "antd/es/col";
import Row, { RowProps } from "antd/es/row";
import cn from "classnames";

import TestProps from "../../type/TestProps";

const block = bem("mmc-label");

export interface LabeledFieldProps extends TestProps {
  children: React.ReactNode;
  className?: string;
  colProps?: ColProps;
  extra?: React.ReactNode;
  label?: React.ReactNode;
  labelClassName?: string;
  labelPosition?: "side" | "top";
  required?: boolean;
  rowProps?: RowProps;
  sideLabelSpan?: number;
}

const Labeled: React.FC<LabeledFieldProps> = ({
  children,
  className,
  colProps,
  extra,
  label,
  labelClassName,
  labelPosition = "top",
  required,
  rowProps,
  sideLabelSpan = 6,
}) => {
  if (labelPosition === "top") {
    return (
      <Col className={className} {...colProps}>
        <Row className={cn(block(), labelClassName)} justify="space-between">
          <div className={block({ required })}>{label}</div>
          {extra && <span>{extra}</span>}
        </Row>
        <Row>{children}</Row>
      </Col>
    );
  }

  return (
    <Row className={className} {...rowProps}>
      <Col className={cn(block(), labelClassName)} span={sideLabelSpan}>
        <div className={block({ required })}>{label}</div>
        {extra && <span>{extra}</span>}
      </Col>
      <Col span={24 - sideLabelSpan}>{children}</Col>
    </Row>
  );
};

export default Labeled;
