import { EventInput } from "@fullcalendar/common";

import Activity from "@mapmycustomers/shared/types/entity/Activity";

import getActivityTypeColors from "@app/util/activity/getActivityTypeColors";
import { formatDate } from "@app/util/formatters";
import isValidDate from "@app/util/isValidDate";
import { parseApiDate } from "@app/util/parsers";

import EventObject from "../type/EventObject";

const activityToEvent = (activity: Activity): EventInput => {
  const [textColor, backgroundColor, borderColor] = getActivityTypeColors(
    activity.crmActivityType,
    true
  );

  const result: EventInput = {
    id: String(activity.id),
    allDay: activity.allDay,
    backgroundColor,
    borderColor,
    className: `act_${activity.id}`,
    end: activity.endAt ?? undefined,
    extendedProps: {
      activity,
    } as EventObject,
    resourceId: String(activity.assignee?.id),
    start: activity.startAt ?? undefined,
    textColor,
    title: activity.name ?? activity.note,
  };

  // for allDay activity, let's ensure that end date is not defined and that start date
  // will be of a correct date, taking user's TZ into account
  if (activity.allDay) {
    const startAt = activity.startAt ? parseApiDate(activity.startAt) : undefined;
    result.start = isValidDate(startAt) ? formatDate(startAt, "yyyy-MM-dd") : undefined;
    result.end = undefined;
  }

  return result;
};

export default activityToEvent;
