import React from "react";

import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

type RegistryComponent = React.FC<React.ComponentProps<any>>;

export interface RegistryProps {
  entity: AnyEntity;
  field: IField;
}

type ComponentRegistryEntry = {
  component: RegistryComponent;
  condition: ConditionsChecker;
};

type ConditionsChecker = (field: IField, entity: AnyEntity) => boolean;

export default class ComponentRegistry {
  private static instance: ComponentRegistry;
  private registry: Map<ComponentRegistryEntry["component"], ComponentRegistryEntry["condition"]>;

  public static getInstance() {
    if (!ComponentRegistry.instance) {
      ComponentRegistry.instance = new ComponentRegistry();
    }
    return ComponentRegistry.instance;
  }

  public static register(component: RegistryComponent, condition: ConditionsChecker) {
    if (typeof component === "object" || typeof component === "function") {
      this.getInstance().add(component, condition);
    }
  }

  public static getComponent(field: IField, entity: AnyEntity): RegistryComponent | undefined {
    let result;

    for (const [component, conditionsChecker] of this.getInstance().registry) {
      if (typeof conditionsChecker === "function") {
        if (conditionsChecker(field, entity) === true) {
          result = component;
        }
      }
    }

    return result;
  }

  constructor() {
    this.registry = new Map();
  }

  add(component: RegistryComponent, condition: ConditionsChecker) {
    this.registry.set(component, condition);
  }
}
