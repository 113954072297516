import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  Condition,
  PlatformCombinedCondition,
  PlatformFilterCondition,
  PlatformSimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

const createRegionCondition = (
  field: IField,
  platformCondition: PlatformFilterCondition
): PlatformCombinedCondition => {
  const simpleCondition = platformCondition as PlatformSimpleCondition;

  // do nothing if it's not found
  if (!(field.platformFilterName in simpleCondition)) {
    return platformCondition;
  }

  const condition = simpleCondition[field.platformFilterName] as Condition;

  if (
    Object.hasOwn(condition, PlatformFilterOperator.CONTAINS) ||
    Object.hasOwn(condition, PlatformFilterOperator.NOT_CONTAINS)
  ) {
    // do not convert
    return platformCondition;
  }

  // FIXME: move this logic (all below this comment) to RegionField.convertToPlatformCondition method

  const isNegativeCondition =
    Object.hasOwn(condition, PlatformFilterOperator.NOT_CONTAINS) ||
    Object.hasOwn(condition, PlatformFilterOperator.NOT_EQUAL) ||
    // or "is unknown" operator
    (Object.hasOwn(condition, PlatformFilterOperator.EQUALS) &&
      condition[PlatformFilterOperator.EQUALS] === null);

  return {
    [isNegativeCondition ? "$and" : "$or"]: [
      {
        region: condition,
      },
      {
        regionCode: condition,
      },
    ],
  };
};

export default createRegionCondition;
