import React, { SyntheticEvent, useCallback } from "react";
import { connect } from "react-redux";

import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { updateNotificationReadStatus } from "@app/store/notification/actions";
import MmcNotification from "@app/types/MmcNotification";

import styles from "./Actions.module.scss";

interface Props {
  hovered: boolean;
  notification: MmcNotification;
  updateNotificationReadStatus: typeof updateNotificationReadStatus.request;
}

const ReadController: React.FC<Props> = ({
  hovered,
  notification,
  updateNotificationReadStatus,
}) => {
  const intl = useIntl();

  const handleChangeReadStatus = useCallback(
    (event: SyntheticEvent<HTMLElement>) => {
      stopEvents(event);
      updateNotificationReadStatus({
        id: notification.id,
        readStatus: !notification.readStatus,
      });
    },
    [notification, updateNotificationReadStatus]
  );

  return (
    <Tooltip
      title={
        notification.readStatus
          ? intl.formatMessage({
              id: "navbar.notifications.list.notification.markAsUnread",
              defaultMessage: "Mark as unread",
              description: "Navbar Notifications - list - notification - Mark as unread",
            })
          : intl.formatMessage({
              id: "navbar.notifications.list.notification.markAsRead",
              defaultMessage: "Mark as read",
              description: "Navbar Notifications - list - notification - Mark as read",
            })
      }
    >
      <div
        className={cn(styles.readController, {
          [styles.readControllerHovered]: hovered,
          [styles.unread]: !notification.readStatus,
        })}
        onClick={handleChangeReadStatus}
      />
    </Tooltip>
  );
};

const mapDispatchToProps = {
  updateNotificationReadStatus: updateNotificationReadStatus.request,
};

export default connect(null, mapDispatchToProps)(ReadController);
