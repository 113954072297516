import { ActionType, createAction } from "typesafe-actions";

import { Person } from "@mapmycustomers/shared/types/entity";

import ApiService from "@app/store/api/ApiService";

// WARNING! Only use this action when you can't get person from the store
export const fetchPerson = createAction("@person/fetchPerson")<{
  callback: (person: Person) => void;
  failureCallback?: () => void;
  id: Person["id"];
  options?: Parameters<ApiService["fetchPerson"]>[2];
}>();

export type Actions = ActionType<typeof fetchPerson>;
