import * as Sentry from "@sentry/react";
import type { SeverityLevel } from "@sentry/types/types/severity";

import ILogger from "./ILogger";
import LogLevel from "./LogLevel";

const logLevelToSentrySeverityLevel: Record<LogLevel, SeverityLevel> = {
  [LogLevel.DEBUG]: "debug",
  [LogLevel.ERROR]: "error",
  [LogLevel.INFO]: "info",
  [LogLevel.WARNING]: "warning",
};

export default class SentryLogger implements ILogger {
  log(logLevel: LogLevel, message: string, payload?: any) {
    Sentry.captureMessage(message, {
      extra: !payload ? undefined : typeof payload === "object" ? payload : { payload },
      level: logLevelToSentrySeverityLevel[logLevel],
    });
  }
}
