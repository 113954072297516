import PinLegendType from "@mapmycustomers/shared/enum/PinLegendType";
import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";

import { UniversalFieldName } from "@app/util/fieldModel/universalFieldsFieldModel";

const getDefaultColorPinLegend = (
  pinLegends: PinLegend[],
  entityType: EntityTypesSupportedByMapsPage
): PinLegend | undefined =>
  pinLegends.find(
    (legend) =>
      legend.entity === entityType &&
      legend.type === PinLegendType.COLOR &&
      legend.default &&
      legend.fieldName === UniversalFieldName.NO_CONTACT_IN
  );

export default getDefaultColorPinLegend;
