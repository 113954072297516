import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Select from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";

import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import EntityBadge from "@app/component/EntityBadge";
import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import { getFunnels, getFunnelStages } from "@app/store/deal";
import { ensureStagesFetched } from "@app/store/deal/actions";
import { RootState } from "@app/store/rootReducer";

import styles from "./StageSelectBox.module.scss";

interface Props {
  allowClear?: boolean;
  className?: string;
  ensureStagesFetched: typeof ensureStagesFetched.request;
  funnels: Funnel[];
  funnelStages: Record<Funnel["id"], Stage[]>;
  onChange?: (stagesIds: Stage["id"][]) => void;
  placeholder?: string;
  selectedFunnelsIds?: Funnel["id"][];
  selectedStagesIds?: Stage["id"][];
  showArrow?: boolean;
  showSearch?: boolean;
}

const StageSelectBox: React.FC<Props> = ({
  allowClear,
  className,
  ensureStagesFetched,
  funnels,
  funnelStages,
  onChange,
  placeholder,
  selectedFunnelsIds,
  selectedStagesIds,
  showArrow,
  showSearch,
}) => {
  const intl = useIntl();

  useEffect(() => {
    ensureStagesFetched(undefined);
  }, [ensureStagesFetched]);

  const allFunnelIds = useMemo(() => funnels.map(({ id }) => id), [funnels]);

  // only allow selecting stages for these funnels
  // when selectedFunnelIds is given and is not empty, use it, otherwise allow stages for all funnels
  const funnelIds = new Set(selectedFunnelsIds?.length ? selectedFunnelsIds : allFunnelIds);

  // only show stages which it is possible to select. Solving a bug for this scenario:
  // select stage X from funnel A
  // select funnel B
  // now we should hide stage X from options (even though technically it is still applied)
  const selectableStagesIds = new Set(
    Array.from(funnelIds)
      .flatMap((funnelId) => funnelStages[funnelId])
      .filter(isDefined)
      .map(({ id }) => id)
  );

  const handleChange = useCallback((stagesIds: Stage["id"][]) => onChange?.(stagesIds), [onChange]);

  return (
    <Select<Array<Stage["id"]>>
      allowClear={allowClear}
      className={cn(styles.container, className)}
      filterOption={defaultFilterOption}
      maxTagCount="responsive"
      mode="multiple"
      onChange={handleChange}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: "stageField.placeholder",
          defaultMessage: "Select Stage",
          description: "Select Stage field's placeholder",
        })
      }
      showArrow={showArrow}
      showSearch={showSearch}
      value={(selectedStagesIds ?? []).filter((id) => selectableStagesIds.has(id))}
    >
      {funnels
        .filter(({ id }) => funnelIds.has(id))
        .map((funnel) => (
          <Select.OptGroup key={funnel.id} label={funnel.name}>
            {(funnelStages[funnel.id] ?? []).map((stage) => (
              <Select.Option key={stage.id} label={stage.name} value={stage.id}>
                <EntityBadge entity={stage} />
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
    </Select>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnels: getFunnels(state),
  funnelStages: getFunnelStages(state),
});

const mapDispatchToProps = {
  ensureStagesFetched: ensureStagesFetched.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(StageSelectBox);
