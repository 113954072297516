import { createReducer } from "typesafe-actions";

import PinLegendsState from "@app/scene/map/store/pinLegends/PinLegendsState";

import {
  hidePinLegendDrawer,
  PinLegendsActions,
  showCreatePinLegendDrawer,
  showPinSettings,
  showUpdatePinLegendDrawer,
} from "./actions";

export const pinLegendsInitialState: PinLegendsState = {};

const pinLegends = createReducer<PinLegendsState, PinLegendsActions>(pinLegendsInitialState)
  .handleAction(showCreatePinLegendDrawer, (state, { payload }) => ({
    ...state,
    entityType: payload.entityType,
    pinLegend: undefined,
    type: payload.type,
  }))
  .handleAction(showUpdatePinLegendDrawer, (state, { payload }) => ({
    ...state,
    entityType: payload.entity,
    pinLegend: payload,
    type: payload.type,
  }))
  .handleAction(showPinSettings, (state, { payload }) => ({
    ...state,
    pinLegendSettingsVisibility: payload,
  }))
  .handleAction(hidePinLegendDrawer, (state) => ({
    ...state,
    entityType: undefined,
    pinLegend: undefined,
    type: undefined,
  }));

export default pinLegends;
