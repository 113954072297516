import type Calendar from "nylas/lib/models/calendar";
import { ActionType, createAsyncAction } from "typesafe-actions";

import { Deal } from "@mapmycustomers/shared/types/entity";

import NylasInfo, {
  NylasInfoCreatePayload,
  NylasInfoUpdatePayload,
} from "@app/types/nylas/NylasInfo";

export const initializeNylas = createAsyncAction(
  "@nylas/initialize/request",
  "@nylas/initialize/success",
  "@nylas/initialize/failure"
)<
  void,
  { nylasCalendarInfo: NylasInfo | undefined; nylasEmailInfo: NylasInfo | undefined },
  void
>();

export const createNylas = createAsyncAction(
  "@nylas/createNylas/request",
  "@nylas/createNylas/success",
  "@nylas/createNylas/failure"
)<{ callback?: (nylas: NylasInfo) => void; nylas: NylasInfoCreatePayload }, void, void>();

export const updateNylas = createAsyncAction(
  "@nylas/updateNylas/request",
  "@nylas/updateNylas/success",
  "@nylas/updateNylas/failure"
)<{ callback?: (nylas: NylasInfo) => void; nylas: NylasInfoUpdatePayload }, NylasInfo, void>();

export const deleteNylas = createAsyncAction(
  "@nylas/deleteNylas/request",
  "@nylas/deleteNylas/success",
  "@nylas/deleteNylas/failure"
)<
  { callback?: () => void; nylasId: NylasInfo["id"]; type: NylasInfo["type"] },
  NylasInfo["type"],
  void
>();

export const fetchCalendars = createAsyncAction(
  "@nylas/fetchCalendars/request",
  "@nylas/fetchCalendars/success",
  "@nylas/fetchCalendars/failure"
)<void, Calendar[], void>();

export const createCalendarEventForDealClosingDate = createAsyncAction(
  "@nylas/createCalendarEventForDealClosingDate/request",
  "@nylas/createCalendarEventForDealClosingDate/success",
  "@nylas/createCalendarEventForDealClosingDate/failure"
)<{ callback?: () => void; deal: Deal; eventDateTime: Date }, void, void>();

export type Actions = ActionType<
  | typeof createCalendarEventForDealClosingDate
  | typeof createNylas
  | typeof deleteNylas
  | typeof fetchCalendars
  | typeof initializeNylas
  | typeof updateNylas
>;
