import React, { useCallback, useMemo } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import camelCase from "lodash-es/camelCase";
import { useIntl } from "react-intl";

import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FormLayout, { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { SelectField } from "@mapmycustomers/ui";

import Divider from "@app/component/FormFields/components/Divider";
import convertFieldToLayoutSchemaField from "@app/component/FormFields/utils/convertFieldToLayoutSchemaField";
import getAddableFields from "@app/component/FormFields/utils/getAddableFields";
import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";
import labelComparator from "@app/util/comparator/labelComparator";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";

import styles from "./AddFieldButton.module.scss";

const yes = () => true;

interface Props {
  canAddField?: (field: IField) => boolean;
  entityType: EntityTypesSupportingFieldCustomization;
  isCreateForm?: boolean;
  layout: FormLayout;
  onAddField?: (newField: LayoutSchemaField) => void;
  schema: Array<LayoutSchemaField>;
}

const AddFieldButton: React.FC<Props> = ({
  canAddField = yes,
  entityType,
  isCreateForm,
  layout,
  onAddField,
  schema,
}) => {
  const intl = useIntl();

  const [isSelecting, startSelecting, stopSelecting] = useBoolean(false);

  const handleChange = useCallback(
    (fieldName) => {
      const field = getFieldModelByEntityType(entityType).getByName(fieldName);
      if (field) {
        onAddField?.(convertFieldToLayoutSchemaField(field));
      }

      stopSelecting();
    },
    [entityType, onAddField, stopSelecting]
  );

  const options = useMemo(() => {
    return getAddableFields(entityType, schema, isCreateForm ?? false, canAddField)
      .map((field) => ({ label: field.displayName, value: field.name }))
      .sort(labelComparator);
  }, [canAddField, entityType, isCreateForm, schema]);

  if (!layout.addFields || !options.length) {
    return null;
  }

  return (
    <>
      <Divider />
      <div className={styles.bar}>
        <div className={styles.control}>
          {isSelecting ? (
            <SelectField<IField["name"]>
              autoFocus
              className={styles.select}
              filterOption={defaultFilterOption}
              onChange={handleChange}
              options={options}
              placeholder={intl.formatMessage(
                {
                  id: "settings.formLayouts.canvas.addField.placeholder",
                  defaultMessage:
                    "Search for {entityType, select, accounts {a company} contacts {a person} deals {a deal} crmActivities {an activity} other {a}} Field",
                  description: "Search for a Field placeholder",
                },
                { entityType: camelCase(entityType) }
              )}
              showAction={["focus", "click"]}
              showSearch
            />
          ) : (
            <Button onClick={startSelecting}>
              {intl.formatMessage({
                id: "settings.formLayouts.canvas.addField",
                defaultMessage: "+ Add Field",
                description: "Add Field button title",
              })}
            </Button>
          )}
        </div>

        {isSelecting && (
          <Button
            className={styles.cancel}
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={stopSelecting}
            type="text"
          />
        )}
      </div>
    </>
  );
};

export default AddFieldButton;
