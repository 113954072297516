import React from "react";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { AnyEntity, EntityTypesSupportingFilteringBy } from "@mapmycustomers/shared/types/entity";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import EntityMultiSelectField from "@app/component/input/EntityMultiSelectField";

export const ENTITY_FILTER_OPERATORS = [
  FilterOperator.IN_ANY,
  FilterOperator.NONE_OF,
  FilterOperator.IN_ALL,
];

interface EntityFilterProps extends IFilterComponentProps {}

const getEntityFilter = (
  entityType: EntityTypesSupportingFilteringBy,
  supportedOperators: FilterOperator[] = ENTITY_FILTER_OPERATORS
): IFilterInstance => {
  return {
    doesSupportValue: (value: any, operator: FilterOperator) =>
      Array.isArray(value) &&
      value.every((item) => typeof item === "number") &&
      supportedOperators.includes(operator),
    getComponent:
      (): React.FC<EntityFilterProps> =>
      ({ className, onChange, value }) => {
        return (
          <EntityMultiSelectField
            className={className}
            entityType={entityType}
            onChange={(entityIds: AnyEntity["id"][]) => onChange?.({ ...value, value: entityIds })}
            value={Array.isArray(value.value) ? value.value : []}
          />
        );
      },
  };
};

export default getEntityFilter;
