import React, { useCallback } from "react";

import { faIndent, faOutdent } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSlate } from "slate-react";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import toggleBlock from "../../util/toggleBlock";
import ActionTooltipWrapper from "../ActionTooltipWrapper";
import styles from "../Toolbar.module.scss";

interface Props {
  offset: -1 | 1;
}

const IndentControl: React.FC<Props> = ({ offset }) => {
  const editor = useSlate();

  const handleAction = useCallback(() => {
    toggleBlock(editor, ElementType.INDENT, {
      indentIncrement: offset,
    });
  }, [editor, offset]);

  return (
    <ActionTooltipWrapper
      translationString={
        offset === 1 ? "ui.emailBody.toolbar.indentMore" : "ui.emailBody.toolbar.indentLess"
      }
    >
      <span className={styles.togglerButton} onClick={handleAction} onMouseDown={stopEvents}>
        <FontAwesomeIcon icon={offset === 1 ? faIndent : faOutdent} size="xs" />
      </span>
    </ActionTooltipWrapper>
  );
};

export default IndentControl;
