import React, { MouseEvent, useCallback } from "react";

import Button from "antd/es/button";
import Paragraph, { ParagraphProps } from "antd/es/typography/Paragraph";
import cn from "classnames";
import { useIntl } from "react-intl";

import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import styles from "./LongContentParagraph.module.scss";

interface Props extends Omit<ParagraphProps, "className" | "ellipsis"> {
  className?: string;
  expandable?: boolean;
  // no margin is useful when this component is used for a list of tags or other things which do
  // not require a margin after it
  noMargin?: boolean;
  paragraphClassName?: string;
  rows?: number;
}

const LongContentParagraph: React.FC<Props> = ({
  children,
  className,
  expandable = true,
  noMargin,
  paragraphClassName,
  rows = 3,
  ...props
}) => {
  const intl = useIntl();

  // Changing key toggles Paragraph node recreation and it's collapsed by default
  // So calling toggleKey collapses the paragraph
  // This is definitely a hacky way, but AntD doesn't support collapsing yet.
  const [key, , , toggleKey] = useBoolean();
  const [expanded, expand, collapse] = useBoolean();
  const handleExpand = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      expand();
    },
    [expand]
  );
  const handleCollapse = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      collapse();
      toggleKey();
    },
    [collapse, toggleKey]
  );

  const collapseLabel = expanded
    ? intl.formatMessage({
        id: "longContentParagraph.collapse",
        defaultMessage: "Collapse",
        description: "Collapse action in the Long Content Paragraph component",
      })
    : "";

  return (
    <div className={cn(styles.container, { [styles.noMargin]: noMargin }, className)}>
      <Paragraph
        className={cn(styles.paragraph, paragraphClassName)}
        ellipsis={{
          expandable,
          onExpand: expandable ? handleExpand : undefined,
          rows,
          symbol: expandable
            ? intl.formatMessage({
                id: "longContentParagraph.expand",
                defaultMessage: "Expand",
                description: "Expand action in the Long Content Paragraph component",
              })
            : undefined,
        }}
        key={key ? 1 : 0}
        {...props}
      >
        {children}
      </Paragraph>
      {expanded && (
        <Button
          aria-label={collapseLabel}
          className={styles.collapseButton}
          onClick={handleCollapse}
          type="link"
        >
          {collapseLabel}
        </Button>
      )}
    </div>
  );
};

export default LongContentParagraph;
