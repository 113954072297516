import { useEffect, useRef } from "react";

const useInterval = (
  callback: () => void,
  active: boolean,
  timeoutMs: number,
  runImmediately: boolean = false,
  delayMs: number = 0
) => {
  const delayId = useRef<number | undefined>();
  const timerId = useRef<number | undefined>();

  useEffect(() => {
    if (active) {
      delayId.current = window.setTimeout(() => {
        timerId.current = window.setInterval(callback, timeoutMs);
      }, delayMs);
    }
    if (runImmediately) {
      callback();
    }
    return () => {
      if (delayId.current) {
        clearTimeout(delayId.current);
        delayId.current = undefined;
      }
      if (timerId.current) {
        window.clearInterval(timerId.current);
        timerId.current = undefined;
      }
    };
  }, [active, callback, delayMs, timeoutMs, runImmediately]);
};

export default useInterval;
