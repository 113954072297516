import { createReducer } from "typesafe-actions";

import DuplicateRunStatus from "@mapmycustomers/shared/enum/DuplicateRunStatus";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { EntityTypeSupportingDataMerging } from "@mapmycustomers/shared/types/entity";
import { DuplicableEntity, DuplicatePair } from "@mapmycustomers/shared/types/entity/Duplicate";

import EntityPriority from "../enum/EntityPriority";

import {
  Actions,
  checkDupeRunStatus,
  fetchDuplicates,
  hideDismissed,
  prioritizePrimaryEntity,
  prioritizeSecondaryEntity,
  recalculateDuplicates,
  selectDuplicatePair,
  selectEntityType,
  setLoading,
  setSearchText,
  showDismissed,
} from "./actions";

export interface MergeDuplicatesState {
  duplicates: DuplicatePair[];
  duplicatesLoading: boolean;
  entityType: EntityTypeSupportingDataMerging;
  lastCalculatedDate?: string;
  lastDupeRunStatus?: DuplicateRunStatus;
  page: number;
  searchText: string;
  selectedDuplicatePrimaryEntity?: DuplicableEntity;
  selectedDuplicateSecondaryEntity?: DuplicableEntity;
  selectedEntitiesLoading: boolean;
  selectedEntityAsPrioritized: EntityPriority;
  selectedPair?: DuplicatePair;
  selectedPairIndex?: number;
  showDismissed: boolean;
  total: number;
}

const initialState: MergeDuplicatesState = {
  duplicates: [],
  duplicatesLoading: false,
  entityType: EntityType.COMPANY,
  page: 0,
  searchText: "",
  selectedEntitiesLoading: false,
  selectedEntityAsPrioritized: EntityPriority.NONE,
  showDismissed: false,
  total: 0,
};

const mergeDuplicatesStore = createReducer<MergeDuplicatesState, Actions>(initialState)
  .handleAction(selectEntityType, (state, { payload }) => ({
    ...state,
    entityType: payload,
    searchText: "",
  }))
  .handleAction(showDismissed, (state) => ({
    ...state,
    page: 0,
    showDismissed: true,
  }))
  .handleAction(hideDismissed, (state) => ({
    ...state,
    page: 0,
    showDismissed: false,
  }))
  .handleAction(setLoading, (state, { payload }) => ({
    ...state,
    duplicatesLoading: payload,
  }))
  .handleAction(selectDuplicatePair.request, (state, { payload }) => {
    const stateToUpdate = {
      ...state,
      selectedEntityAsPrioritized: EntityPriority.NONE,
      selectedPairIndex: payload,
    };
    if (payload === undefined) {
      stateToUpdate.selectedPair = undefined;
    } else {
      stateToUpdate.selectedPair = state.duplicates[payload];
      stateToUpdate.selectedEntitiesLoading = true;
    }
    return stateToUpdate;
  })
  .handleAction(selectDuplicatePair.success, (state, { payload }) => ({
    ...state,
    selectedDuplicatePrimaryEntity: payload.primary,
    selectedDuplicateSecondaryEntity: payload.secondary,
    selectedEntitiesLoading: false,
  }))
  .handleAction(selectDuplicatePair.failure, (state) => ({
    ...state,
    selectedDuplicatePair: undefined,
    selectedEntitiesLoading: false,
  }))
  .handleAction(fetchDuplicates.request, (state, { payload: { page } }) => ({
    ...state,
    page: page ?? state.page,
  }))
  .handleAction(
    fetchDuplicates.success,
    (state, { payload: { duplicates, lastCalculatedDate, total } }) => ({
      ...state,
      duplicates,
      duplicatesLoading: false,
      lastCalculatedDate,
      total,
    })
  )
  .handleAction(prioritizePrimaryEntity, (state) => ({
    ...state,
    selectedEntityAsPrioritized: EntityPriority.PRIMARY,
  }))
  .handleAction(prioritizeSecondaryEntity, (state) => ({
    ...state,
    selectedEntityAsPrioritized: EntityPriority.SECONDARY,
  }))
  .handleAction(
    recalculateDuplicates.success,
    (state, { payload: { lastCalculatedDate, lastDupeRunStatus } }) => ({
      ...state,
      lastCalculatedDate,
      lastDupeRunStatus,
    })
  )
  .handleAction(
    checkDupeRunStatus.success,
    (state, { payload: { lastCalculatedDate, lastDupeRunStatus } }) => ({
      ...state,
      lastCalculatedDate,
      lastDupeRunStatus:
        // do not set lastDupeRunStatus to FINISHED if it wasn't ONGOING before
        lastDupeRunStatus === DuplicateRunStatus.FINISHED
          ? state.lastDupeRunStatus === DuplicateRunStatus.ONGOING
            ? DuplicateRunStatus.FINISHED
            : state.lastDupeRunStatus
          : lastDupeRunStatus,
    })
  )
  .handleAction(setSearchText, (state, { payload }) => ({
    ...state,
    page: 0,
    searchText: payload,
  }));

export * from "./selectors";
export type MergeDuplicatesActions = Actions;
export default mergeDuplicatesStore;
