import React, { SyntheticEvent, useCallback, useState } from "react";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import smallOrganizationLogo from "@app/assets/logoCircle.svg";
import localSettings from "@app/config/LocalSettings";
import Path from "@app/enum/Path";
import navbarAnalytics from "@app/util/analytic/navbarAnalytics";

import styles from "./OrganizationLogo.module.scss";

interface Props {
  logoUrl?: string;
  organizationName?: string;
}

const OrganizationLogo: React.FC<Props> = ({ logoUrl, organizationName }) => {
  const intl = useIntl();

  // This code is needed to guarantee reserving horizontal space for the logo image,
  // but also reducing this space if image turned to be smaller.
  // Without this code menu will be "jumping". With this code it can jump too, but
  // within a smaller distances :)
  // Also using CSS transitions to reduce jumping effect.
  const [width, setWidth] = useState<number>(styles.maxWidth); // default logo image width
  const handleOnLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      setWidth(Math.min(e.currentTarget.naturalWidth, styles.maxWidth));
    },
    [setWidth]
  );

  const handleClickLogo = useCallback(() => navbarAnalytics.clicked(["Logo Click"]), []);

  return (
    <Link className={styles.container} onClick={handleClickLogo} to={Path.DASHBOARD}>
      <picture>
        {localSettings.wasOpenedFromMobileApp() ? null : (
          <source
            className={styles.smallLogo}
            media={`(max-width: ${styles.breakdownWidth}px)`}
            srcSet={smallOrganizationLogo}
          />
        )}
        <img
          alt={intl.formatMessage(
            {
              id: "navbar.logo.alt",
              defaultMessage: "{organizationName} logo",
              description: "Alt text for the organization logo",
            },
            {
              organizationName: organizationName ?? "Map My Customers",
            }
          )}
          className={styles.logo}
          onLoad={handleOnLoad}
          src={logoUrl}
          style={{ width }}
        />
      </picture>
    </Link>
  );
};

export default OrganizationLogo;
