import { Editor } from "slate";

import TextType from "@mapmycustomers/shared/enum/textEditor/TextType";

// detect does current text snippet have provided type
const isTextTypeActive = (editor: Editor, type: TextType) => {
  const marks = Editor.marks(editor);
  return marks ? marks[type] === true : false;
};

export default isTextTypeActive;
