import React, { ComponentType, ReactNode, useCallback } from "react";

import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faBrowser } from "@fortawesome/pro-solid-svg-icons/faBrowser";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import { IntlShape, useIntl } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import {
  EntityType,
  EntityTypesSupportingCompanyAssociation,
  EntityTypesSupportingFieldCustomization,
  EntityTypeSupportingGroups,
} from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import { DatePicker, FormItem, NumberField, TextField } from "@mapmycustomers/ui";

import CircleColorPicker from "@app/component/CircleColorPicker";
import CustomFieldComponent from "@app/component/CustomField";
import AddressField from "@app/component/FormFields/components/AddressField";
import GroupsField from "@app/component/FormFields/components/GroupsField";
import { ParentCompanyField } from "@app/component/FormFields/components/ParentCompanyField";
import PersonNameField from "@app/component/FormFields/components/PersonNameField";
import styles from "@app/component/FormFields/FormFields.module.scss";
import getFieldMatcher from "@app/component/FormFields/utils/getFieldMatcher";
import CurrencyInput from "@app/component/input/CurrencyInput";
import getCustomFieldsValidationRules from "@app/component/input/utils/getCustomFieldsValidationRules";
import layout from "@app/styles/layout";
import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";
import { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { isCustomField } from "@app/util/fieldModel/impl/assert";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import { formatDate } from "@app/util/formatters";
import loggingService from "@app/util/logging";
import { getEntityTypeDisplayName } from "@app/util/ui";

import CalculatedFieldInfoIcon from "../../CalculatedFieldInfoIcon";
import OwnerField from "../components/OwnerField";

import messages from "./messages";

export const createFormCustomFieldNamePathGetter = (customField: CustomField) => [
  "customFields",
  String(customField.id),
];
export const editFormCustomFieldNamePathGetter = (customField: CustomField) => [
  "customFields",
  customField.esKey,
];

const fileFieldValidator = (intl: IntlShape) => (_: any, value: unknown) => {
  if (Array.isArray(value) && value.length) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(intl.formatMessage(messages.fileRequired)));
};

const getCustomFieldLabel = (field: CustomField) => {
  if (field.isCalculated) {
    return (
      <Row align="middle" gutter={layout.spacerXS}>
        <Col>{field.displayName}</Col>
        <Col>
          <CalculatedFieldInfoIcon customField={field} />
        </Col>
      </Row>
    );
  }
  return field.displayName;
};

interface Options {
  customFieldNamePathGetter?: (customField: CustomField) => string | string[];
  fileComponent?: ComponentType<{
    disabled?: boolean;
    onChange?: (uploadedFiles: RawFile[]) => void;
    required?: boolean;
  }>;
  ignoreRequired?: boolean;
  isCreateForm?: boolean;
  isFieldDisabled?: (field: IField) => boolean;
}

const useFormFieldRender = (
  entityType: EntityTypesSupportingFieldCustomization,
  children: ReactNode | ReactNode[],
  actualSchema: LayoutSchemaField[],
  options?: Options
) => {
  const intl = useIntl();
  return useCallback(
    (
      field: IField,
      schemaField: LayoutSchemaField,
      readOnly: boolean = false,
      isVariant: boolean = false
    ) => {
      const disabled = readOnly || options?.isFieldDisabled?.(field);

      const required =
        (schemaField.required ||
          // we assume field is required via isSystemRequired only if it's not a relationship field
          (field.isSystemRequired && !field.hasFeature(FieldFeature.RELATIONSHIPS))) &&
        !readOnly &&
        options?.ignoreRequired !== true;

      const conditionalField = React.Children.toArray(children).find(getFieldMatcher(field));
      if (conditionalField) {
        return React.cloneElement(conditionalField, {
          ...conditionalField.props,
          actualSchema,
          disabled,
          label: field.displayName,
          required,
          variant: isVariant,
        });
      }

      // we assume that these fields are always conditional fields
      if (
        schemaField.field === "accountId" ||
        schemaField.field === "contactId" ||
        schemaField.field === "dealId"
      ) {
        loggingService.error(
          "FormFields doesn't process relationship fields, add them as a ConditionalFormField",
          { field: schemaField.field }
        );
        return null;
      }

      if (field.hasFeature(FieldFeature.OWNER_FIELD)) {
        return (
          <Form.Item
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <OwnerField disabled={readOnly} />
          </Form.Item>
        );
      }

      // if (field.hasFeature(FieldFeature.FREQUENCY_INTERVAL) && !options?.isCreateForm) {
      if (field.hasFeature(FieldFeature.FREQUENCY_INTERVAL)) {
        // normally, users can edit frequency interval at the top of the preview pane
        // so we don't need to display in the form itself
        // UPD: until TART-15669 is done, removing the isCreateForm condition
        return null;
      }

      if (schemaField.field === "parentAccountId") {
        return (
          <ParentCompanyField
            disabled={disabled}
            entityType={entityType as EntityTypesSupportingCompanyAssociation}
            required={required}
          />
        );
      }

      // lastName is a connected field, so they both are rendered here
      if (schemaField.field === "firstName") {
        return <PersonNameField actualSchema={actualSchema} disabled={disabled} />;
      }

      if (schemaField.field === "files") {
        const FileComponent = options?.fileComponent;
        return FileComponent ? (
          <Form.Item
            label={undefined}
            name={field.name}
            required={required}
            rules={required ? [{ validator: fileFieldValidator(intl) }] : []}
          >
            <FileComponent disabled={disabled} required={required} />
          </Form.Item>
        ) : null;
      }

      if (field.hasFeature(FieldFeature.ADDRESS)) {
        return <AddressField disabled={disabled} required={required} />;
      }

      if (isCustomField(field)) {
        const definition = field.customFieldData;
        const namePathGetter =
          options?.customFieldNamePathGetter ?? editFormCustomFieldNamePathGetter;
        return (
          <FormItem
            label={getCustomFieldLabel(definition)}
            name={namePathGetter(definition)}
            required={required}
            rules={required ? getCustomFieldsValidationRules(intl, definition) : undefined}
          >
            <CustomFieldComponent
              definition={definition}
              disabled={disabled}
              entityType={entityType}
              label={getCustomFieldLabel(definition)}
              required={required}
            />
          </FormItem>
        );
      }

      switch (field.name) {
        case ActivityFieldName.NAME:
        case CompanyFieldName.NAME:
        case DealFieldName.NAME:
        case PersonFieldName.NAME: {
          const nameField = getFieldModelByEntityType(entityType)?.getByName(
            entityType === EntityType.PERSON ? PersonFieldName.FIRST_NAME : "name"
          );
          const nameReadable = nameField && nameField.isReadable;
          return (
            <Tooltip
              title={intl.formatMessage(messages.maskedTooltip)}
              trigger={nameReadable ? [] : ["hover"]}
            >
              <FormItem
                label={field.displayName}
                name={field.name}
                required={required}
                rules={[{ required }]}
              >
                <TextField
                  label={field.displayName}
                  locked={disabled || !nameReadable}
                  placeholder={intl.formatMessage(messages.namePlaceholder, {
                    entityName: getEntityTypeDisplayName(intl, entityType, { lowercase: false }),
                  })}
                  required={required}
                  suffix={nameReadable ? undefined : <FontAwesomeIcon icon={faLock} />}
                />
              </FormItem>
            </Tooltip>
          );
        }
        case CompanyFieldName.WEBSITE:
        case CompanyFieldName.EMAIL:
        case PersonFieldName.EMAIL: {
          const isEmail =
            field.name === CompanyFieldName.EMAIL || field.name === PersonFieldName.EMAIL;
          const icon = isEmail ? faEnvelope : faBrowser;
          const placeholder = intl.formatMessage(
            isEmail ? messages.emailPlaceholder : messages.websitePlaceholder
          );
          return (
            <FormItem
              label={field.displayName}
              name={field.name}
              required={required}
              rules={[{ required }]}
            >
              <TextField
                addonBefore={<FontAwesomeIcon icon={icon} />}
                label={field.displayName}
                locked={disabled}
                placeholder={placeholder}
                required={required}
              />
            </FormItem>
          );
        }
      }

      if (field.hasFeature(FieldFeature.COLOR_FIELD)) {
        return (
          <Form.Item
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <CircleColorPicker disabled={disabled} />
          </Form.Item>
        );
      }

      if (field.hasFeature(FieldFeature.GROUP_FIELD)) {
        return (
          <GroupsField
            disabled={disabled}
            // we know that if GROUP_FIELD is available for some entityType, it must be EntityTypeSupportingGroups
            entityType={entityType as EntityTypeSupportingGroups}
            field={field}
            required={required}
          />
        );
      }

      const isReadOnlyDateField = ["createdAt", "updatedAt"].includes(field.name);
      if (isReadOnlyDateField) {
        return <Tag>{formatDate(Date.now(), "PPPpp")}</Tag>;
      }

      if ([FieldType.DATE, FieldType.DATE_TIME].includes(field.type)) {
        const message =
          field.type === FieldType.DATE_TIME ? messages.timePlaceholder : messages.datePlaceholder;
        return (
          <Form.Item
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <DatePicker
              allowClear
              className={styles.full}
              locked={disabled}
              placeholder={intl.formatMessage(message)}
              size="large"
            />
          </Form.Item>
        );
      }

      if (field.hasFeature(FieldFeature.MONETARY_VALUE)) {
        // Platform limit
        // Note: the actual limit is -9223372036854775808..9223372036854775807, but since these
        // numbers can't be represented precisely in JS without using a BigInt, we use the
        // closest smaller number, which is exactly 9223372036854775000.
        let min = -9223372036854775000;
        let max = 9223372036854775000;
        const isAnnualRevenue = field.name === CompanyFieldName.ANNUAL_REVENUE;
        const isDealAmount = field.name === DealFieldName.AMOUNT;

        // special limits for company's annual revenue and deal's amount fields
        if (isAnnualRevenue) {
          min = 0;
        } else if (isDealAmount) {
          min = 0;
          // eslint-disable-next-line no-loss-of-precision
          max = 99999999999999.99;
        }

        return (
          <FormItem
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <CurrencyInput
              allowEmpty={isAnnualRevenue || isDealAmount}
              disabled={disabled}
              label={field.displayName}
              max={max}
              min={min}
              required={required}
            />
          </FormItem>
        );
      }

      if (field.type === FieldType.NUMBER) {
        const numericOptions = field.formProperties?.numericOptions;
        return (
          <FormItem
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <NumberField
              inputClassName={styles.full}
              label={field.displayName}
              locked={disabled}
              placeholder={field.displayName}
              required={required}
              {...numericOptions}
            />
          </FormItem>
        );
      }

      // Not specifically covered field rendered as generic text input
      return (
        <FormItem
          label={field.displayName}
          name={field.name}
          required={required}
          rules={[{ required }]}
        >
          <TextField
            label={field.displayName}
            locked={disabled}
            placeholder={field.displayName}
            required={required}
          />
        </FormItem>
      );
    },
    [options, children, entityType, actualSchema, intl]
  );
};

export default useFormFieldRender;
