import React from "react";

import { faPen } from "@fortawesome/pro-solid-svg-icons/faPen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import { Tooltip } from "antd";
import cn from "classnames";

import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import anyColorToHex from "@mapmycustomers/shared/util/color/anyColorToHex";
import getContrastColor from "@mapmycustomers/shared/util/color/getContrastColor";

import { useConfigProvider } from "../ConfigProvider";
import colors from "../styles/_colors.module.scss";

const block = bem("mmc-color-circle");

interface ColorCircleProps {
  className?: string;
  disabled?: boolean;
  value?: AnyColor;
}

const ColorCircle: React.FC<ColorCircleProps> = ({ className, disabled, value }) => {
  const configProvider = useConfigProvider();

  const bgColor = value ? anyColorToHex(value) : "inherit";
  const fgColor = value
    ? getContrastColor(bgColor) === "white"
      ? colors.white
      : colors.slate
    : "inherit";

  return (
    <div
      className={cn(block(), className)}
      style={{
        backgroundColor: bgColor,
        borderColor: fgColor,
      }}
    >
      {!disabled ? (
        <Tooltip title={configProvider.formatMessage("ui.colorComponents.changeColor")}>
          <FontAwesomeIcon
            className={block("icon")}
            icon={faPen}
            style={{
              color: fgColor,
            }}
          />
        </Tooltip>
      ) : undefined}
    </div>
  );
};

export default ColorCircle;
