import notification from "antd/es/notification";
import { defineMessage } from "react-intl";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { v4 } from "uuid";

import { Deal, EntityType } from "@mapmycustomers/shared/types/entity";
import Person from "@mapmycustomers/shared/types/entity/Person";
import File, { RawFile } from "@mapmycustomers/shared/types/File";
import Organization from "@mapmycustomers/shared/types/Organization";

import getSuccessNotificationNode from "@app/component/createEditEntity/util/getSuccessNotificationNode";
import i18nService from "@app/config/I18nService";
import { CreateDealPayload } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import getNextAssociationsState, {
  NextAssociationsState,
} from "@app/store/associations/getNextAssociationsState";
import { showEntityView } from "@app/store/entityView/actions";
import { showEntityChannel } from "@app/store/entityView/sagas";
import { handleError } from "@app/store/errors/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import { extendNavbarAnalyticWithMenuKey } from "@app/util/analytic/navbarAnalytics";
import { NOTIFICATION_DURATION_WITH_ACTION } from "@app/util/consts";
import { allSettled, SettleResult } from "@app/util/effects";
import getFieldValidationMessages from "@app/util/errorHandling/getFieldValidationMessages";

import { getFileGroupId, getUploadedFileListIds } from ".";
import {
  changeAssociatedEntities,
  clearAllUploadedDealFiles,
  createDeal,
  initialize,
  relateUnrelateEntities,
  removeDealFile,
  uploadDealFiles,
} from "./actions";

const successMessage = defineMessage({
  id: "createDealModal.success",
  defaultMessage: "Deal added successfully",
  description: "Deal add success message",
});

export function* onInitialize({ payload }: ReturnType<typeof initialize.request>) {
  try {
    const associationsState: NextAssociationsState = yield call(getNextAssociationsState, {
      company: payload.fixedCompany,
      entityType: EntityType.DEAL,
      person: payload.fixedPerson,
    });

    yield put(initialize.success({ associationsState, fileGroupId: v4() }));
  } catch (error) {
    yield put(initialize.failure());
    yield put(handleError({ error }));
  }
}

export function* onChangeAssociatedEntity({
  payload,
}: ReturnType<typeof changeAssociatedEntities.request>) {
  try {
    const associationsState: NextAssociationsState = yield call(getNextAssociationsState, {
      ...payload,
      entityType: EntityType.DEAL,
    });

    yield put(changeAssociatedEntities.success(associationsState));
  } catch (error) {
    yield put(changeAssociatedEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateDeal({ payload }: ReturnType<typeof createDeal.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const { callback, customFieldsValues, deal, groupsIdsToAdd, layoutId } = payload;
    const fileIds: File["id"][] = yield select(getUploadedFileListIds);

    const dealToSend: CreateDealPayload = {
      ...deal,
      files: fileIds.length > 0 ? [...fileIds] : undefined,
    };
    const newDeal: Deal = yield callApi("createDeal", org.id, layoutId, dealToSend);

    const dealIds: Deal["id"][] = [newDeal.id];
    yield all([
      ...groupsIdsToAdd.map((groupId) =>
        callApi("addToGroup", org.id, groupId, EntityType.DEAL, dealIds)
      ),
    ]);

    if (customFieldsValues.length) {
      yield callApi(
        "upsertCustomFieldsValues",
        false,
        org.id,
        layoutId,
        EntityType.DEAL,
        newDeal.id,
        customFieldsValues
      );
    }

    callback?.(newDeal);

    yield put(notifyAboutChanges({ added: [newDeal], entityType: EntityType.DEAL }));
    const notificationKey = "create-deal-notification";
    extendNavbarAnalyticWithMenuKey("addDeal").clicked(["Create Deal"]);

    notification.success({
      duration: NOTIFICATION_DURATION_WITH_ACTION,
      key: notificationKey,
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(successMessage, "Deal added successfully"),
        () => {
          extendNavbarAnalyticWithMenuKey("addCompany").clicked(["Create Deal", "View Record"]);
          showEntityChannel.put(
            showEntityView({ entityId: newDeal.id, entityType: EntityType.DEAL })
          );
          notification.close(notificationKey);
        }
      ),
    });

    yield put(clearAllUploadedDealFiles());

    yield put(createDeal.success(newDeal));
  } catch (error) {
    yield put(createDeal.failure(getFieldValidationMessages(error)));
    yield put(handleError({ error }));
  }
}

export function* onUploadDealFiles({ payload }: ReturnType<typeof uploadDealFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const fileGroupId: string = yield select(getFileGroupId);
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", org.id, file, undefined, undefined, undefined, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        })
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadDealFiles.success(fileList));
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ errored: true, file, uploading: false })));
    yield put(uploadDealFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onRemoveDealFile({ payload }: ReturnType<typeof removeDealFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteFile", org.id, payload.id);
    yield put(removeDealFile.success(payload.id));
  } catch (error) {
    yield put(removeDealFile.failure());
    yield put(handleError({ error }));
  }
}

export function* onRelateUnrelateEntities({
  payload: {
    associatedCompany,
    associatedPerson,
    failureCallback,
    isPersonCorrectlyRelatedToCompany,
    successCallback,
  },
}: ReturnType<typeof relateUnrelateEntities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    let person: Person | undefined;
    if (!isPersonCorrectlyRelatedToCompany && associatedPerson && associatedCompany) {
      person = yield callApi(
        "updatePerson",
        orgId,
        undefined,
        {
          id: associatedPerson.id,
          accounts: [...associatedPerson.accounts, { id: associatedCompany.id }],
        },
        {
          includeUsersWithAccess: true,
        }
      );
    } else if (isPersonCorrectlyRelatedToCompany && associatedPerson && associatedCompany) {
      person = yield callApi(
        "updatePerson",
        orgId,
        undefined,
        {
          id: associatedPerson.id,
          accounts: associatedPerson.accounts.filter(({ id }) => id !== associatedCompany.id),
        },
        {
          includeUsersWithAccess: true,
        }
      );
    }
    successCallback?.();
    yield put(
      relateUnrelateEntities.success({ person, unrelate: isPersonCorrectlyRelatedToCompany })
    );
  } catch (error) {
    failureCallback?.();
    yield put(relateUnrelateEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* createDealModalSaga() {
  yield takeLatest(initialize.request, onInitialize);
  yield takeLatest(changeAssociatedEntities.request, onChangeAssociatedEntity);
  yield takeLatest(createDeal.request, onCreateDeal);
  yield takeEvery(uploadDealFiles.request, onUploadDealFiles);
  yield takeEvery(removeDealFile.request, onRemoveDealFile);
  yield takeEvery(relateUnrelateEntities.request, onRelateUnrelateEntities);
}
