import IntegrationRaw from "@mapmycustomers/shared/types/integrations/IntegrationRaw";

import Integration from "@app/types/integrations/Integration";
import { parseApiDate } from "@app/util/parsers";

const parseIntegrationRecord = (apiRow: IntegrationRaw): Integration => ({
  ...apiRow,
  schedule: apiRow.schedule
    ? { ...apiRow.schedule, nextRunAt: parseApiDate(apiRow.schedule.nextRunAt) }
    : null,
  updatedAt: parseApiDate(apiRow.updatedAt),
});

export default parseIntegrationRecord;
