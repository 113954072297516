import React from "react";

import { useIntl } from "react-intl";

import RoundButton from "@app/component/RoundButton";

import GlobalSearchFilter, {
  getGlobalSearchFilterDisplayName,
} from "../../enum/GlobalSearchFilter";

import styles from "./TypeSelector.module.scss";

interface Props {
  onSelect: (filter: GlobalSearchFilter) => void;
  value: GlobalSearchFilter;
}

const TypeSelector: React.FC<Props> = ({ onSelect, value }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      {[
        GlobalSearchFilter.ALL,
        GlobalSearchFilter.COMPANIES,
        GlobalSearchFilter.PEOPLE,
        GlobalSearchFilter.DEALS,
        GlobalSearchFilter.GROUPS,
        GlobalSearchFilter.ACTIVITIES,
        GlobalSearchFilter.ROUTES,
        GlobalSearchFilter.TERRITORIES,
      ].map((filter) => (
        <RoundButton
          active={filter === value}
          className={styles.button}
          key={filter}
          onClick={() => onSelect(filter)}
        >
          {getGlobalSearchFilterDisplayName(intl, filter)}
        </RoundButton>
      ))}
    </div>
  );
};

export default TypeSelector;
