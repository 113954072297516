import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import ActivityTypeWithCount from "@mapmycustomers/shared/types/entity/activities/ActivityTypeWithCount";

import ListResponseAggregation from "@app/types/viewModel/ListResponseAggregation";

const getActivityTypeWithCount = (
  activityTypes: ActivityType[],
  activities: ListResponseAggregation<Activity>[]
): ActivityTypeWithCount[] => {
  const activityCountById = new Map(activities.map(({ doc_count, key }) => [key, doc_count ?? 0]));
  return activityTypes.map((activityType) => ({
    ...activityType,
    count: activityCountById.get(activityType.id) ?? 0,
  }));
};

export default getActivityTypeWithCount;
