import React, { useMemo } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import { Alert } from "@mapmycustomers/ui";

import {
  getGeocodeOrgLimit,
  isCurrentUserOwner,
  isGeocodeMmcLimitReached,
  isGeocodeOrgLimitReached,
} from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

import styles from "./GeocodeLimitField.module.scss";

interface Props {
  isOwner: boolean;
  mmcLimitReached: boolean;
  orgLimit: number;
  showInfo: boolean;
}

const GeocodeLimitField: React.FC<Props> = ({ isOwner, mmcLimitReached, orgLimit, showInfo }) => {
  const intl = useIntl();
  const getDescription = useMemo(() => {
    if (mmcLimitReached) {
      return intl.formatMessage({
        id: "createEditEntity.geocodeLimit.description.mmcLimitReached",
        defaultMessage: "Once saved, this record will be queued for geocoding once available.",
        description: "Create / Edit entity message of geocode limit",
      });
    } else {
      if (isOwner) {
        return intl.formatMessage(
          {
            id: "createEditEntity.geocodeLimit.description.owner",
            defaultMessage:
              "Please note that your organization has met its daily geocoding limit of {limit} records. We’re unable to display this record on the map until it’s geocoded.  Once saved, this record will be queued for geocoding once your organization’s daily limit resets.",
            description: "Create / Edit entity description of geocode limit for owners",
          },
          { limit: intl.formatNumber(orgLimit) }
        );
      } else {
        return intl.formatMessage({
          id: "createEditEntity.geocodeLimit.description",
          defaultMessage:
            "We’re unable to display this record on the map until it’s geocoded.  Once saved, this record will be queued for geocoding once your organization’s daily limit resets.",
          description: "Create / Edit entity description of geocode limit",
        });
      }
    }
  }, [mmcLimitReached, isOwner, orgLimit, intl]);

  if (!showInfo) {
    return null;
  }

  return (
    <Alert
      className={styles.alert}
      description={getDescription}
      message={
        mmcLimitReached
          ? intl.formatMessage({
              id: "createEditEntity.geocodeLimit.message.mmcLimitReached",
              defaultMessage:
                "We’re experiencing heavy geocoding usage and are unable to map this record. ",
              description: "Create / Edit entity message of geocode limit",
            })
          : intl.formatMessage({
              id: "createEditEntity.geocodeLimit.message",
              defaultMessage: "Your organization has reached its daily geocoding limit",
              description: "Create / Edit entity message of geocode limit",
            })
      }
      showIcon
      type="warning"
    />
  );
};
const mapStateToProps = (state: RootState) => ({
  isOwner: isCurrentUserOwner(state),
  mmcLimitReached: isGeocodeMmcLimitReached(state),
  orgLimit: getGeocodeOrgLimit(state),
  showInfo: isGeocodeMmcLimitReached(state) || isGeocodeOrgLimitReached(state),
});

export default connect(mapStateToProps)(GeocodeLimitField);
