import React from "react";

import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";

import { Route } from "@mapmycustomers/shared/types/entity";

import { RegistryProps } from "@app/component/FieldGrid/utils/ComponentRegistry";

const RoutesField: React.FC<RegistryProps> = ({ entity, field }) => {
  const routes = (field.getValueFor(entity) as Pick<Route, "id" | "name">[]) ?? [];

  return (
    <div>
      <Tooltip title={field.getFormattedValueFor(entity)}>
        {routes.map((route) => (
          <Tag key={route.id}>{route.name}</Tag>
        ))}
      </Tooltip>
    </div>
  );
};

export default RoutesField;
