import React, { useCallback, useMemo } from "react";

import { Activity } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";

import Lexer from "@app/component/Lexer";
import LongContentParagraph from "@app/component/typography/LongContentParagraph";
import MultilineText from "@app/component/typography/MultilineText";
import getActivityAllowedUsers from "@app/util/activity/getActivityAllowedUsers";

import styles from "./ActivityNotePreview.module.scss";

interface Props {
  activity: Activity;
  className?: string;
  currentUserId: User["id"];
  note: string;
  users: User[];
}

const ActivityNotePreview: React.FC<Props> = ({
  activity,
  className,
  currentUserId,
  note,
  users,
}) => {
  const allowedEmails: User["username"][] = useMemo(
    () =>
      getActivityAllowedUsers(
        currentUserId,
        users,
        activity.userIds ?? [],
        activity.visibility,
        activity.teamId ?? [],
        activity.assignee
      ),
    [
      activity.assignee,
      activity.teamId,
      activity.userIds,
      activity.visibility,
      currentUserId,
      users,
    ]
  );

  const handleIsMentionDisabled = useCallback(
    (user: User) => {
      return !allowedEmails.includes(user.username);
    },
    [allowedEmails]
  );

  return (
    <LongContentParagraph
      className={className}
      expandable={false}
      noMargin
      paragraphClassName={styles.note}
      rows={15}
    >
      <MultilineText
        wrapLinesWith={Lexer}
        wrapperProps={{ isMentionDisabled: handleIsMentionDisabled, users }}
      >
        {note}
      </MultilineText>
    </LongContentParagraph>
  );
};

export default ActivityNotePreview;
