import { DependencyList, EffectCallback, useEffect, useRef } from "react";

// This effect is almost the same as useEffect hook but skips first render of component
// Therefore, it let us to track real changes of component state
const useChangeTracking = (callback: EffectCallback, deps?: DependencyList) => {
  const firstCallSkipped = useRef(false);

  useEffect(
    () => {
      if (!firstCallSkipped.current) {
        firstCallSkipped.current = true;
        return;
      }
      callback();
    },
    // callback is not a part of deps, that correct.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
};

export default useChangeTracking;
