import { createReducer } from "typesafe-actions";

import { EntityType } from "@mapmycustomers/shared/enum";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import EntityTypesSupportedInNoContactIn from "@app/types/dashboard/cards/noContactIn/EntityTypesSupportedInNoContactIn";
import NoContactInCardConfiguration from "@app/types/dashboard/cards/noContactIn/NoContactInCardConfiguration";
import NoContactInCountCardConfiguration from "@app/types/dashboard/cards/noContactInCount/NoContactInCountCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";

import {
  applyNoContactInDrillDownListViewSettings,
  fetchNoContactInCardDrillDownData,
  hideNoContactInDrillDown,
  NoContactInCardActions,
  showNoContactInDrillDown,
} from "./actions";

export interface NoContactInCardState {
  configuration?: NoContactInCardConfiguration | NoContactInCountCardConfiguration; // also works as a "visible" trigger, visible if set
  loading?: boolean;
  totalFilteredRecords: Record<EntityTypesSupportedInNoContactIn, number>;
  totalRecords: Record<EntityTypesSupportedInNoContactIn, number>;
  viewStates: Record<EntityTypesSupportedInNoContactIn, DrillDownViewState>;
}

export const noContactInInitialState: NoContactInCardState = {
  totalFilteredRecords: ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as const).reduce(
    (result, entityType) => ({ ...result, [entityType]: 0 }),
    {} as Record<EntityTypesSupportedInNoContactIn, number>
  ),
  totalRecords: ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as const).reduce(
    (result, entityType) => ({ ...result, [entityType]: 0 }),
    {} as Record<EntityTypesSupportedInNoContactIn, number>
  ),
  viewStates: ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as const).reduce(
    (result, entityType) => {
      result[entityType] = {
        columns: [],
        filter: {},
        range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
        sort: [],
        viewAs: undefined,
      };
      return result;
    },
    {} as Record<EntityTypesSupportedInNoContactIn, DrillDownViewState>
  ),
};

const noContactInState = createReducer<NoContactInCardState, NoContactInCardActions>(
  noContactInInitialState
)
  .handleAction(showNoContactInDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
  }))
  .handleAction(showNoContactInDrillDown.success, (state, { payload }) => ({
    ...state,
    viewStates: payload.viewStates,
  }))
  .handleAction(hideNoContactInDrillDown, (state) => ({ ...state, configuration: undefined }))
  .handleAction(fetchNoContactInCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchNoContactInCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: {
      ...state.totalFilteredRecords,
      [payload.entityType]: payload.totalFilteredRecords,
    },
    totalRecords: {
      ...state.totalRecords,
      [payload.entityType]: payload.totalRecords,
    },
  }))
  .handleAction(fetchNoContactInCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyNoContactInDrillDownListViewSettings, (state, { payload }) => {
    const viewState = state.viewStates[payload.entityType];
    return {
      ...state,
      viewStates: {
        ...state.viewStates,
        [payload.entityType]: {
          ...viewState,
          columns: payload.columns ?? viewState.columns,
          filter: payload.filter ?? viewState.filter,
          range: payload.range ?? viewState.range,
          sort: payload.sort ?? viewState.sort,
          // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
          viewAs: "viewAs" in payload ? payload.viewAs : viewState.viewAs,
        },
      },
    };
  });

export default noContactInState;
