import React, { useCallback, useState } from "react";

import Address from "@mapmycustomers/shared/types/Address";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { AutoCompleteAddress, ManualAddress } from "@mapmycustomers/ui";
import { AutoCompleteAddressProps } from "@mapmycustomers/ui/src/input/Address/AutoCompleteAddress";

import { getFormattedAddressForUi } from "@app/util/formatters";

type Props = Pick<AutoCompleteAddressProps, "error" | "label" | "onReverseGeocodeAddress"> & {
  onChange: (address: string) => void;
  value: string;
};

const Location: React.FC<Props> = ({ error, label, onChange, onReverseGeocodeAddress, value }) => {
  const [manualAddress, setManualAddress] = useState<Address>();
  const [isManualAddress, switchToManualAddress, , toggleManualAddress] = useBoolean();

  const handleAddressChange = useCallback(
    (address?: Address) => {
      setManualAddress(address);
      onChange?.(getFormattedAddressForUi(address));
    },
    [onChange]
  );

  return isManualAddress ? (
    <ManualAddress
      error={error}
      label={label}
      onChange={handleAddressChange}
      onReverseGeocodeAddress={onReverseGeocodeAddress}
      onToggleManualAddress={toggleManualAddress}
      value={manualAddress}
    />
  ) : (
    <AutoCompleteAddress
      allowClear
      error={error}
      label={label}
      onChange={handleAddressChange}
      onEnterManually={switchToManualAddress}
      onReverseGeocodeAddress={onReverseGeocodeAddress}
      value={{ address: value }}
    />
  );
};

export default Location;
