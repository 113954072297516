import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import Currency from "@mapmycustomers/shared/types/Currency";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { OptionalFields } from "@mapmycustomers/shared/util/ts";
import { LabeledFieldProps, MonetaryField } from "@mapmycustomers/ui";
import { MonetaryFieldProps } from "@mapmycustomers/ui/src/input/MonetaryField";

import OrganizationSetting from "@app/enum/OrganizationSetting";
import { getOrganizationSettingValue } from "@app/store/iam";
import { getCurrencies } from "@app/store/referenceData";
import { RootState } from "@app/store/rootReducer";

interface Props
  extends Omit<LabeledFieldProps, "children">,
    Pick<MonetaryFieldProps, "max" | "min"> {
  allowEmpty?: boolean;
  currencies: Currency[];
  defaultCurrencyCode?: string;
  disabled?: boolean;
  onChange?: (value: number | undefined) => void;
  placeholder?: string;
  value?: number;
}

const CurrencyInput: React.FC<Props> = ({
  allowEmpty,
  className,
  currencies,
  defaultCurrencyCode,
  disabled,
  max,
  min,
  onChange,
  placeholder,
  value,
  ...labeledProps
}) => {
  const intl = useIntl();

  const defaultCurrency = useMemo(
    () => currencies.find((currency) => currency.code === defaultCurrencyCode),
    [currencies, defaultCurrencyCode]
  );

  const [amount, setAmount] = useState<number | undefined>(value);
  useEffect(() => {
    setAmount(value);
  }, [value, defaultCurrency]);

  const handleChange = useCallback(
    (amount?: number) => {
      if (allowEmpty || isDefined(amount)) {
        onChange?.(amount);
      }
    },
    [allowEmpty, onChange]
  );

  const handleAmountChange = useCallback(
    (value: OptionalFields<MonetaryValue, "value">) => {
      setAmount(value.value);
      handleChange(value.value);
    },
    [handleChange]
  );

  return (
    <MonetaryField
      {...labeledProps}
      allowEmpty={allowEmpty}
      disabled={disabled}
      max={max}
      min={min}
      onChange={handleAmountChange}
      placeholder={intl.formatMessage({
        id: "component.currencyInput.placeholder",
        defaultMessage: "Enter amount",
        description: "Placeholder for currency field",
      })}
      value={
        !defaultCurrency || amount === undefined
          ? undefined
          : { currencyId: defaultCurrency.id, value: amount }
      }
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  currencies: getCurrencies(state),
  defaultCurrencyCode: getOrganizationSettingValue(state)(OrganizationSetting.CURRENCY),
});

export default connect(mapStateToProps)(memo(CurrencyInput));
