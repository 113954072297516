import { useContext } from "react";

import type IConfigProviderContext from "../types/IConfigProviderContext";

import ConfigProviderContext from "./ConfigProviderContext";

const useConfigProvider = (): IConfigProviderContext => {
  return useContext(ConfigProviderContext) as unknown as IConfigProviderContext;
};

export default useConfigProvider;
