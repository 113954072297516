import { createReducer } from "typesafe-actions";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { Activity } from "@mapmycustomers/shared/types/entity";

import { CreateEditActivityModalActions } from "@app/component/createEditEntity/Activity/store";
import { updateActivity } from "@app/component/createEditEntity/Activity/store/actions";
import SchedulerViewState from "@app/component/view/SchedulerView/types/SchedulerViewState";
import localSettings from "@app/config/LocalSettings";
import CalendarViewMode from "@app/enum/CalendarViewMode";
import { Actions as AppActions, initializeApp } from "@app/store/app/actions";
import { getWeekStart } from "@app/util/dates";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";

import {
  Actions,
  applySchedulerViewSettings,
  fetchSchedulerActivities,
  initializeSchedulerView,
  moveResizeActivity,
  updateActivityInList,
} from "./actions";
import { SCHEDULER_VIEW_STATE } from "./saga";

const DEFAULT_SCHEDULER_VIEW_STATE: SchedulerViewState = {
  columns: [],
  filter: {
    startAt: {
      operator: FilterOperator.ON,
      value: getWeekStart(Date.now()),
    },
  },
  range: { endRow: 9999, startRow: 0 },
  selectedSavedFilterId: undefined,
  sort: [{ field: activityFieldModel.getByName("startAt")!, order: SortOrder.ASC }],
  viewAs: undefined,
  viewMode: CalendarViewMode.WEEK,
};

export interface SchedulerState {
  activities: Activity[];
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: SchedulerViewState;
}

const initialState: SchedulerState = {
  activities: [],
  loading: false,
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: DEFAULT_SCHEDULER_VIEW_STATE,
};

const activityScheduler = createReducer<
  SchedulerState,
  Actions | AppActions | CreateEditActivityModalActions
>(initialState)
  .handleAction(initializeApp.success, (state) => ({
    ...state,
    viewState: {
      ...state.viewState,
      ...DEFAULT_SCHEDULER_VIEW_STATE,
      ...localSettings.getViewSettings(
        SCHEDULER_VIEW_STATE,
        activityFieldModel,
        DEFAULT_SCHEDULER_VIEW_STATE
      ),
    },
  }))
  .handleAction(initializeSchedulerView.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applySchedulerViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      filter: payload.filter ?? state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
      selectedSavedFilterId:
        "selectedSavedFilterId" in payload
          ? payload.selectedSavedFilterId
          : state.viewState.selectedSavedFilterId,
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
      viewMode: payload.viewMode ?? state.viewState.viewMode,
    },
  }))
  .handleAction(fetchSchedulerActivities.request, (state, { payload: { updateOnly } }) => ({
    ...state,
    loading: !updateOnly,
  }))
  .handleAction(fetchSchedulerActivities.success, (state, { payload }) => ({
    ...state,
    activities: payload.activities,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchSchedulerActivities.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateActivity.success, (state, { payload: activity }) => {
    const activities = state.activities;
    const updatedActivityIndex = activities.findIndex(({ id }) => id === activity.id);
    if (updatedActivityIndex < 0) {
      return state;
    }
    return {
      ...state,
      activities: [
        ...activities.slice(0, updatedActivityIndex),
        activity,
        ...activities.slice(updatedActivityIndex + 1),
      ],
    };
  })
  .handleAction(updateActivityInList, (state, { payload }) => ({
    ...state,
    activities: state.activities.map((activity) =>
      activity.id === payload.id ? { ...activity, ...payload } : activity
    ),
  }))
  .handleAction(moveResizeActivity.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(moveResizeActivity.success, (state, { payload }) => ({
    ...state,
    activities: state.activities.map((activity) =>
      activity.id === payload.id ? { ...activity, ...payload } : activity
    ),
    loading: false,
  }))
  .handleAction(moveResizeActivity.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./selectors";
export type SchedulerActions = Actions;
export default activityScheduler;
