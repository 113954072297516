import { Action } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { isActionOf } from "typesafe-actions";

import localSettings from "@app/config/LocalSettings";
import BaseLayerData from "@app/scene/map/store/layers/BaseLayerData";
import { getAlertsData } from "@app/scene/map/store/selectors";
import { updateMetadata } from "@app/store/iam/actions";

import {
  setAlertDismissed,
  toggleLayerLegendExpandedState,
  toggleLayerVisibility,
} from "../actions";

export function* onPersistAlertsSettings({
  payload,
}: ReturnType<typeof toggleLayerLegendExpandedState | typeof toggleLayerVisibility>) {
  const layerData: BaseLayerData = yield select(getAlertsData);
  yield put(updateMetadata.request({ alertsSettings: layerData }));
  if (layerData.visible && "visible" in payload) {
    yield put(setAlertDismissed({ alertDismissed: false }));
  }
}

export function* onAlertDismissed({ payload }: ReturnType<typeof setAlertDismissed>) {
  yield call(localSettings.setEngagementInfoAlertDismissed, payload.alertDismissed);
}

const isToggleVisibilityOrLegendAction = isActionOf([
  toggleLayerVisibility,
  toggleLayerLegendExpandedState,
]);

export function* alertsSagas() {
  yield takeLatest(
    (action: Action) =>
      isToggleVisibilityOrLegendAction(action) && action.payload.name === "alerts",
    onPersistAlertsSettings
  );
  yield takeLatest(setAlertDismissed, onAlertDismissed);
}
