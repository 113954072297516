import React from "react";

import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

interface TextFilterProps extends IFilterComponentProps {}

const EmptyFilter: IFilterInstance = {
  getComponent: (): React.FC<TextFilterProps> => () => null, // nothing to render
};

export default EmptyFilter;
