import React, { FC, useMemo } from "react";

import Select from "antd/es/select";
import { useIntl } from "react-intl";

import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import SelectField from "@app/component/input/SelectField";

import UpcomingVariant from "./UpcomingVariant";

const Option = Select.Option;

const getFrequencyStatusFromNotYetOverdueValue = (
  value: UpcomingVariant
): FrequencyStatus | undefined => {
  if (value === UpcomingVariant.UP_TO_DATE_AND_UPCOMING) {
    return undefined;
  }
  if (value === UpcomingVariant.UPCOMING) {
    return FrequencyStatus.UPCOMING;
  }
  return FrequencyStatus.UP_TO_DATE;
};

export interface UpcomingVariantProps {
  className?: string;
  onChange?: (value: SimpleCondition) => void;
  value: SimpleCondition;
}

const UpcomingVariantValue: FC<UpcomingVariantProps> = ({ className, onChange, value }) => {
  const intl = useIntl();

  const notYetOverdueValue = useMemo(() => {
    let result = UpcomingVariant.UP_TO_DATE_AND_UPCOMING;
    if (value.value === FrequencyStatus.UPCOMING) {
      result = UpcomingVariant.UPCOMING;
    } else if (value.value === FrequencyStatus.UP_TO_DATE) {
      result = UpcomingVariant.UP_TO_DATE;
    }

    return result;
  }, [value.value]);

  return (
    <SelectField<UpcomingVariant>
      className={className}
      dropdownMatchSelectWidth={false}
      onChange={(selectedValue) =>
        onChange?.({
          ...value,
          value: getFrequencyStatusFromNotYetOverdueValue(selectedValue),
        })
      }
      showSearch
      value={notYetOverdueValue}
    >
      <Option value={UpcomingVariant.UP_TO_DATE_AND_UPCOMING}>
        {intl.formatMessage({
          id: "filters.cadenceStatus.notYetOverdue.both",
          defaultMessage: "Show both upcoming and up to date",
          description: "Frequency status filter - not yet overdue - both",
        })}
      </Option>
      <Option value={UpcomingVariant.UPCOMING}>
        {intl.formatMessage({
          id: "filters.cadenceStatus.notYetOverdue.upcoming",
          defaultMessage: "Show only upcoming",
          description: "Frequency status filter - not yet overdue - upcoming",
        })}
      </Option>
      <Option value={UpcomingVariant.UP_TO_DATE}>
        {intl.formatMessage({
          id: "filters.cadenceStatus.notYetOverdue.upToDate",
          defaultMessage: "Show only up to date",
          description: "Frequency status filter - not yet overdue - up To Date",
        })}
      </Option>
    </SelectField>
  );
};

export default UpcomingVariantValue;
