import React from "react";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import { useIntl } from "react-intl";

import { EntityTypeSupportingGroups } from "@mapmycustomers/shared/types/entity";

import styles from "./Footer.module.scss";

interface Props {
  creating?: boolean;
  entityType: EntityTypeSupportingGroups;
  onCancel?: () => void;
  onCreate?: () => void;
  onCreateAndAdd?: () => void;
  showCreateOptions?: boolean;
}

const Footer: React.FC<Props> = ({
  creating,
  entityType,
  onCancel,
  onCreate,
  onCreateAndAdd,
  showCreateOptions,
}) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <Button disabled={creating} onClick={onCancel} type="default">
        {intl.formatMessage({
          id: "createGroupModal.footer.cancel",
          defaultMessage: "Cancel",
          description: "Cancel button in Create Group modal",
        })}
      </Button>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={onCreate}>
              {intl.formatMessage({
                id: "createGroupModal.footer.create.withoutAddingItems",
                defaultMessage: "Create group only",
                description:
                  "Just create option of Create button in Create Group modal when used on map",
              })}
            </Menu.Item>

            <Menu.Item className="js-mmc-create-group-create-and-select" onClick={onCreateAndAdd}>
              {intl.formatMessage({
                id: "createGroupModal.footer.create.createAndAdd",
                defaultMessage: "Create and select pins",
                description:
                  "Create and add option of Create button in Create Group modal when used on map",
              })}
            </Menu.Item>
          </Menu>
        }
        trigger={showCreateOptions ? ["click"] : []}
      >
        <Button
          disabled={creating}
          loading={creating}
          onClick={showCreateOptions ? undefined : onCreate}
          type="primary"
        >
          {intl.formatMessage(
            {
              id: "createGroupModal.footer.create",
              defaultMessage:
                "Create{entityType, select, accounts { Company} contacts { Person} deals { Deal} other {}} Group",
              description: "Create button in Create Group modal",
            },
            { entityType }
          )}
          {showCreateOptions && <FontAwesomeIcon icon={faAngleDown} />}
        </Button>
      </Dropdown>
    </div>
  );
};

export default Footer;
