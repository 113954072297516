import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { AnyEntity } from "@mapmycustomers/shared/types/entity";

import ViewMode from "@app/enum/dashboard/ViewMode";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import { TabbedDrillDownListFetcherPayload } from "@app/scene/dashboard/types/TabbedDrillDownListFetcher";
import EntityTypesSupportedInNoContactIn from "@app/types/dashboard/cards/noContactIn/EntityTypesSupportedInNoContactIn";
import NoContactInCardConfiguration from "@app/types/dashboard/cards/noContactIn/NoContactInCardConfiguration";
import NoContactInCountCardConfiguration from "@app/types/dashboard/cards/noContactInCount/NoContactInCountCardConfiguration";
import Scope from "@app/types/dashboard/Scope";
import MapViewportState from "@app/types/map/MapViewportState";

import NoContactInCardData from "./NoContactInCardData";

export const fetchNoContactInCardData = createAction("@dashboard/fetchNoContactInCardData")<{
  callback: (data: NoContactInCardData) => void;
  configuration: NoContactInCardConfiguration;
  failureCallback?: () => void;
  offset?: number; // used with List view mode when we need to load more items
  scope?: Scope;
  viewMode: ViewMode;
  viewport?: MapViewportState; // used with Map view mode when user pans/zooms
}>();

export const fetchNoContactInCountCardData = createAction(
  "@dashboard/fetchNoContactInCountCardData"
)<{
  callback: (data: NoContactInCardData) => void;
  configuration: NoContactInCountCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showNoContactInDrillDown = createAsyncAction(
  "@dashboard/showNoContactInCardDrillDownModal/request",
  "@dashboard/showNoContactInCardDrillDownModal/success",
  "@dashboard/showNoContactInCardDrillDownModal/failure"
)<
  {
    configuration: NoContactInCardConfiguration | NoContactInCountCardConfiguration;
    scope: Scope;
  },
  { viewStates: Record<EntityTypesSupportedInNoContactIn, DrillDownViewState> },
  unknown
>();

export const hideNoContactInDrillDown = createAction(
  "@dashboard/hideNoContactInCardDrillDownModal"
)<void>();

export const fetchNoContactInCardDrillDownData = createAsyncAction(
  "@dashboard/fetchNoContactInCardDrillDownData/request",
  "@dashboard/fetchNoContactInCardDrillDownData/success",
  "@dashboard/fetchNoContactInCardDrillDownData/failure"
)<
  TabbedDrillDownListFetcherPayload<AnyEntity, EntityTypesSupportedInNoContactIn>,
  {
    entityType: EntityTypesSupportedInNoContactIn;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyNoContactInDrillDownListViewSettings = createAction(
  "@dashboard/applyNoContactInDrillDownListViewSettings"
)<Partial<DrillDownViewState> & { entityType: EntityTypesSupportedInNoContactIn }>();

export const exportNoContactInCardDrillDownData = createAction(
  "@dashboard/exportNoContactInCardData"
)<{
  configuration: NoContactInCardConfiguration | NoContactInCountCardConfiguration;
  entityType: EntityTypesSupportedInNoContactIn;
  scope: Scope;
}>();

export type NoContactInCardActions = ActionType<
  | typeof applyNoContactInDrillDownListViewSettings
  | typeof exportNoContactInCardDrillDownData
  | typeof fetchNoContactInCardData
  | typeof fetchNoContactInCardDrillDownData
  | typeof fetchNoContactInCountCardData
  | typeof hideNoContactInDrillDown
  | typeof showNoContactInDrillDown
>;
