import { createReducer, getType } from "typesafe-actions";

import { mapEntityIdGetter } from "@app/util/map/idGetters";

import {
  enterPinLocationMode,
  exitPinLocationMode,
  geocodePinLocation,
  PinLocationActions,
  setPinLocationColor,
  setPinLocationShape,
} from "./actions";
import PinLocationState from "./PinLocationState";

export const pinLocationInitialState: PinLocationState = {
  address: "",
  addressChanged: false,
  coordinatesChanged: false,
  loading: false,
};

const pinLocation = createReducer<PinLocationState, PinLocationActions>(pinLocationInitialState)
  .handleType(
    getType(enterPinLocationMode),
    (state, { payload: { entity, entityWithoutLocation } }) => ({
      ...state,
      address: entity.address ?? "",
      changed: false,
      coordinates: entity.geoPoint?.coordinates,
      entity: entity,
      entityId: mapEntityIdGetter(entity),
      entityWithoutLocation,
      loading: false,
    })
  )
  .handleType(getType(exitPinLocationMode), () => ({
    ...pinLocationInitialState,
  }))
  .handleType(getType(geocodePinLocation.request), (state) => ({
    ...state,
    loading: true,
  }))
  .handleType(getType(geocodePinLocation.success), (state, { payload }) => ({
    ...state,
    addressChanged: payload.addressChanged ?? state.addressChanged,
    coordinatesChanged: true, //almost impossible for user to put the pin at same location
    loading: false,
    located: payload.located,
  }))
  .handleType(getType(geocodePinLocation.failure), (state) => ({
    ...state,
    loading: false,
  }))
  .handleType(getType(setPinLocationColor), (state, { payload }) => ({
    ...state,
    entity: state.entity ? { ...state.entity, color: payload } : undefined,
  }))
  .handleType(getType(setPinLocationShape), (state, { payload }) => ({
    ...state,
    entity: state.entity ? { ...state.entity, shape: payload } : undefined,
  }));

export default pinLocation;
