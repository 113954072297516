import React, { useMemo } from "react";

import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import Activity from "@mapmycustomers/shared/types/entity/Activity";
import Participant from "@mapmycustomers/shared/types/Participant";

import Section from "@app/component/activity/ActivityAnnotation/Section";

import AttendeeItem from "./AttendeeItem";
import styles from "./AttendeesSection.module.scss";

interface Props {
  activity: Activity;
  className?: string;
}

const AttendeesSection: React.FC<Props> = ({ activity, className }) => {
  const intl = useIntl();
  const participants: Participant[] | undefined = activity.participants;
  const ownerParticipant: Participant | undefined = useMemo(
    () => (participants ? participants.find((participant) => participant.syncedEmail) : undefined),
    [participants]
  );

  if (!participants?.length) {
    return null;
  }

  return (
    <Section
      className={className}
      icon={faUsers}
      title={
        <>
          {intl.formatMessage({
            id: "component.activityAnnotation.attendeesSection.title",
            defaultMessage: "Attendees",
            description: "Title for activity attendees section",
          })}
          <Tooltip
            overlayClassName={styles.tooltipOverlay}
            placement="right"
            title={intl.formatMessage(
              {
                id: "component.activityAnnotation.attendeesSection.descriptionTooltip",
                defaultMessage: "Synced from {ownerEmail}",
                description: "Description tooltip for activity attendees section",
              },
              {
                ownerEmail: ownerParticipant?.email,
              }
            )}
            trigger={ownerParticipant?.email ? ["hover"] : []}
          >
            <span className={cn(styles.description, ownerParticipant?.email && styles.canHover)}>
              {intl.formatMessage({
                id: "component.activityAnnotation.attendeesSection.description",
                defaultMessage: "via Calendar Sync",
                description: "Description for activity attendees section",
              })}
            </span>
          </Tooltip>
        </>
      }
    >
      {participants.map((participant, key) => (
        <AttendeeItem className={styles.attendee} key={key} participant={participant} />
      ))}
    </Section>
  );
};

export default AttendeesSection;
