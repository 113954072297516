import { useMemo } from "react";

import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/es/form/hooks/useForm";

import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

import FrequencyCriteria from "@app/types/frequncy/FrequencyCriteria";
import doesActivityMeetFrequencyCriteria from "@app/util/frequency/doesActivityMeetFrequencyCriteria";

import FormValues from "../types/FormValues";

const useFrequencyCriteria = (
  startAt: Date,
  form: FormInstance<FormValues>,
  initialActivityTypeId?: ActivityType["id"],
  frequencyCriteria?: FrequencyCriteria
): boolean => {
  const date: Date = useWatch("startAt", form) ?? startAt;
  const activityTypeId: ActivityType["id"] | undefined =
    useWatch("activityTypeId", form) ?? initialActivityTypeId;

  return useMemo(
    () => doesActivityMeetFrequencyCriteria(date, activityTypeId, frequencyCriteria),
    [date, activityTypeId, frequencyCriteria]
  );
};

export default useFrequencyCriteria;
