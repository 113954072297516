import ILogger from "./ILogger";
import LogLevel from "./LogLevel";

export default class ConsoleLogger implements ILogger {
  private debug = console.debug.bind(window.console);
  private info = console.info.bind(window.console);
  private warn = console.warn.bind(window.console);
  private error = console.error.bind(window.console);

  log(logLevel: LogLevel, message: string, payload?: any) {
    switch (logLevel) {
      case LogLevel.DEBUG:
        if (payload) {
          this.debug(message, payload);
        } else {
          this.debug(message);
        }
        break;
      case LogLevel.ERROR:
        if (payload) {
          this.error(message, payload);
        } else {
          this.error(message);
        }
        break;
      case LogLevel.INFO:
        if (payload) {
          this.info(message, payload);
        } else {
          this.info(message);
        }
        break;
      case LogLevel.WARNING:
        if (payload) {
          this.warn(message, payload);
        } else {
          this.warn(message);
        }
        break;
    }
  }
}
