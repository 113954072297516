import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import Col from "antd/es/col";
import Row from "antd/es/row";
import { differenceInDays } from "date-fns/esm";
import { useIntl } from "react-intl";

import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { updateEntityFrequency } from "@app/store/frequency/actions";
import { updateMetadata } from "@app/store/iam/actions";
import useAnalytics from "@app/util/contexts/useAnalytics";
import { parseApiDate } from "@app/util/parsers";

import useFrequencyContext from "../FrequencyPanel/util/useFrequencyContext";
import FrequencyEntity from "../type/FrequencyEntity";

import ActivityTypes from "./ActivityTypes";
import Footer from "./Footer";
import styles from "./FrequencyPreview.module.scss";
import Interval from "./Interval";
import messages from "./messages";

interface Props {
  entity?: FrequencyEntity;
  header?: ReactNode;
  isGroup?: boolean;
  lastCompletedActivity?: Activity;
  loading?: boolean;
  onCancel?: () => void;
  onSetFrequency?: (data: Partial<Cadenced>) => void;
  onUpdateMetadata: typeof updateMetadata.request;
  readonly?: boolean;
  saveButtonText?: string;
  showSaveButtonsOnlyAfterChanges?: boolean;
  updateEntityFrequency: typeof updateEntityFrequency.request;
}

const FrequencyPreview: React.FC<Props> = ({
  entity,
  header,
  isGroup,
  lastCompletedActivity,
  loading,
  onCancel,
  onSetFrequency,
  onUpdateMetadata,
  readonly,
  saveButtonText,
  showSaveButtonsOnlyAfterChanges,
  updateEntityFrequency,
}) => {
  const intl = useIntl();
  const { onUpdate } = useFrequencyContext();
  const analytics = useAnalytics();

  const [cadence, setCadence] = useState<Cadenced["cadenceInterval"]>(entity?.cadenceInterval ?? 0);
  const [selectedActivityTypeIds, setSelectedActivityTypeIds] = useState<ActivityType["id"][]>(
    entity?.crmActivityTypeId ?? []
  );
  const [allActivityTypes, setAllActivityTypes] = useState<boolean>(
    (entity?.crmActivityTypeId ?? []).length === 0
  );
  const [hasChanges, setChanges, unsetChanges] = useBoolean();

  const handleSetCadence = useCallback(
    (cadence: Cadenced["cadenceInterval"]) => {
      setCadence(cadence);
      setChanges();
      analytics.clicked(["Change Interval"], { value: cadence });
    },
    [analytics, setCadence, setChanges]
  );

  const handleSetSelectedActivityTypeIds = useCallback(
    (types: ActivityType["id"][]) => {
      setSelectedActivityTypeIds(types);
      setChanges();
      analytics.clicked(["Toggle Accepted Activity Types"], { types });
    },
    [analytics, setSelectedActivityTypeIds, setChanges]
  );

  const handleSetAllActivityTypes = useCallback(
    (allActivityTypes: boolean) => {
      setAllActivityTypes(allActivityTypes);
      setChanges();
    },
    [setAllActivityTypes, setChanges]
  );

  const handleApply = useCallback(() => {
    if (entity) {
      updateEntityFrequency({
        callback: () => {
          onUpdate?.();
          unsetChanges();
        },
        entity: {
          ...entity,
          cadenceInterval: cadence,
          crmActivityTypeId: allActivityTypes ? [] : selectedActivityTypeIds,
        },
      });
    }
    onUpdateMetadata({ frequencyCustomInterval: cadence });
    onSetFrequency?.({
      cadenceInterval: cadence,
      crmActivityTypeId: allActivityTypes ? [] : selectedActivityTypeIds,
    });
    analytics.clicked(["Set Frequency"]);
  }, [
    allActivityTypes,
    analytics,
    cadence,
    entity,
    onSetFrequency,
    onUpdate,
    onUpdateMetadata,
    selectedActivityTypeIds,
    unsetChanges,
    updateEntityFrequency,
  ]);

  const handleDelete = useCallback(() => {
    if (entity) {
      updateEntityFrequency({
        callback: onUpdate,
        entity: {
          ...entity,
          cadenceInterval: null,
          crmActivityTypeId: null,
        },
      });
      analytics.clicked(["Remove"]);
    }
  }, [analytics, entity, onUpdate, updateEntityFrequency]);

  const handleCancel = useCallback(() => {
    onCancel?.();
    setCadence(entity?.cadenceInterval);
    setSelectedActivityTypeIds(entity?.crmActivityTypeId ?? []);
    setAllActivityTypes((entity?.crmActivityTypeId ?? []).length === 0);
    unsetChanges();
    analytics.clicked(["Cancel"]);
  }, [
    analytics,
    entity,
    onCancel,
    setCadence,
    setSelectedActivityTypeIds,
    setAllActivityTypes,
    unsetChanges,
  ]);

  const lastActivityDaysAgo = useMemo(
    () =>
      lastCompletedActivity?.completedAt
        ? differenceInDays(new Date(), parseApiDate(lastCompletedActivity.completedAt))
        : 0,
    [lastCompletedActivity?.completedAt]
  );

  return (
    <div className={styles.container}>
      {header}
      <Interval cadence={cadence} onChange={handleSetCadence} readonly={readonly} />
      {!isGroup && lastCompletedActivity?.completedAt && (
        <Row align="middle" className={styles.line} justify="space-between">
          <Col>{intl.formatMessage(messages.lastActivityLogged)}</Col>
          <Col className={styles.staticValue}>
            {lastActivityDaysAgo > 0
              ? intl.formatMessage(messages.lastActivityLoggedValue, {
                  count: lastActivityDaysAgo,
                })
              : intl.formatMessage(messages.today)}
          </Col>
        </Row>
      )}
      <ActivityTypes
        allActivityTypes={allActivityTypes}
        onSetActivityTypes={handleSetSelectedActivityTypeIds}
        onSetAllActivityTypes={handleSetAllActivityTypes}
        readonly={readonly}
        selectedActivityTypeIds={selectedActivityTypeIds}
      />
      <Footer
        applyDisabled={!cadence || (!allActivityTypes && selectedActivityTypeIds.length === 0)}
        entity={entity}
        hasChanges={hasChanges}
        isGroup={isGroup}
        loading={loading}
        onApply={handleApply}
        onCancel={handleCancel}
        onDelete={handleDelete}
        readonly={readonly}
        saveButtonText={saveButtonText}
        showSaveButtonsOnlyAfterChanges={showSaveButtonsOnlyAfterChanges}
      />
    </div>
  );
};

const mapDispatchToProps = {
  onUpdateMetadata: updateMetadata.request,
  updateEntityFrequency: updateEntityFrequency.request,
};

export default connect(null, mapDispatchToProps)(FrequencyPreview);
