import {
  addMonths,
  addQuarters,
  endOfMonth,
  endOfQuarter,
  startOfMonth,
  startOfQuarter,
} from "date-fns/esm";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import { FORECAST_COLUMNS_PER_PAGE } from "@app/scene/deal/util/consts";

import ForecastViewMode from "../enum/ForecastViewMode";

const startOf: Record<ForecastViewMode, (date: Date | number) => Date> = {
  [ForecastViewMode.MONTH]: startOfMonth,
  [ForecastViewMode.QUARTER]: startOfQuarter,
};

const endOf: Record<ForecastViewMode, (date: Date | number) => Date> = {
  [ForecastViewMode.MONTH]: endOfMonth,
  [ForecastViewMode.QUARTER]: endOfQuarter,
};

const add: Record<ForecastViewMode, (date: Date | number, amount: number) => Date> = {
  [ForecastViewMode.MONTH]: addMonths,
  [ForecastViewMode.QUARTER]: addQuarters,
};

const getClosingDateFilterByModeAndColumns = (
  viewMode: ForecastViewMode,
  startColumn: number = 0
): SimpleCondition => ({
  operator: FilterOperator.IN_RANGE,
  value: [
    startOf[viewMode](add[viewMode](Date.now(), startColumn)).toISOString(),
    endOf[viewMode](
      add[viewMode](Date.now(), startColumn + FORECAST_COLUMNS_PER_PAGE - 1)
    ).toISOString(),
  ],
});

export default getClosingDateFilterByModeAndColumns;
