export const NO_TEAM_ID = -1;

export const COLUMN_BAR_HEIGHT = 40;

export const PIE_VIEW_LIMIT = 5;

export const TEAM_COUNT_TO_SHOW = 10;

export const MAXIMUM_ENTITY_COUNT = 10000;

export const MINIMUM_COMPAQ_NUMBER_VALUE = 100000;
