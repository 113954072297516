import React from "react";

import cn from "classnames";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import {
  AnyEntity,
  EntityTypesSupportingFieldCustomization,
  TypedEntity,
} from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import Field from "./components/Field";
import registerAllComponents from "./components/registerAll";
import styles from "./FieldGrid.module.scss";

interface Props {
  className?: string;
  columns: IField[];
  entity: TypedEntity<AnyEntity, EntityTypesSupportingFieldCustomization>;
  showEmpty?: boolean;
}

const FieldGrid: React.FC<Props> = ({ className, columns, entity, showEmpty }) => {
  return columns.length ? (
    <div className={cn(styles.container, className)}>
      {columns
        .filter((field) => !field.hasFeature(FieldFeature.MAP_PINNED_FIELD) && field.isReadable)
        .map((field) => (
          <Field entity={entity} field={field} key={field.name} showEmpty={showEmpty} />
        ))}
    </div>
  ) : null;
};

registerAllComponents();

export default FieldGrid;
