import React, { useCallback, useState } from "react";

import Button from "antd/es/button";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { isThisYear, startOfDay } from "date-fns/esm";
import { defineMessages, useIntl } from "react-intl";

import ButtonLink from "@app/component/ButtonLink";
import { ClearableDatePicker } from "@app/component/input/DatePicker";
import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import { formatDate } from "@app/util/formatters";
import isValidDate from "@app/util/isValidDate";

import FormValues from "../../types/FormValues";

import styles from "./ActivityEndDate.module.scss";

interface Props {
  disabledTooltip?: string;
  form: FormInstance<FormValues>;
  onChange?: () => void;
}

const messages = defineMessages({
  changeDate: {
    id: "createActivityModal.footer.scheduleActivity.changeEndDate",
    defaultMessage: "Change End Date",
    description: "Change End Date",
  },
  removeDateTime: {
    id: "createActivityModal.footer.scheduleActivity.removeEndDateTime",
    defaultMessage: "Remove End Date and Time",
    description: "Remove End Date and Time",
  },
});

const ActivityEndDate: React.FC<Props> = ({ disabledTooltip, form, onChange }) => {
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const startDateValue = startOfDay(form.getFieldValue(ActivityFieldName.START_AT));
  const dateValue = form.getFieldValue(ActivityFieldName.END_AT);
  const currentDate = isValidDate(dateValue) ? dateValue : undefined;
  const isCurrentYear = currentDate && isThisYear(currentDate);
  const dateFormat = intl.formatMessage(
    {
      id: "createEditEntity.activity.endDate.format",
      defaultMessage: "{currentYear, select, true {eeee, MMM d} other {eeee, MMM d, yyyy}}",
      description: "Date format for current date",
    },
    {
      currentYear: isCurrentYear,
    }
  );

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        setTooltipVisible(false);
      }
      setOpen(open);
    },
    [setOpen, setTooltipVisible]
  );

  const handleClearDateClick = useCallback(() => {
    form.setFieldsValue({ endAt: undefined });

    setTooltipVisible(false);
    setOpen(false);

    onChange?.();
  }, [form, onChange, setOpen, setTooltipVisible]);

  const handleDisabledDate = useCallback(
    (date: Date) => {
      return startOfDay(date) < startDateValue;
    },
    [startDateValue]
  );

  const handleClick = useCallback(() => {
    setTooltipVisible(false);
    setOpen(true);
  }, [setTooltipVisible, setOpen]);

  return (
    <div className={styles.container}>
      <Tooltip
        destroyTooltipOnHide
        mouseLeaveDelay={0}
        onOpenChange={setTooltipVisible}
        open={tooltipVisible}
        overlayClassName={styles.tooltip}
        placement="top"
        title={disabledTooltip ?? intl.formatMessage(messages.changeDate)}
        trigger={open ? [] : ["hover"]}
      >
        <Form.Item name={ActivityFieldName.END_AT} noStyle>
          <ClearableDatePicker
            allowClear={false}
            bordered={false}
            disabled={!!disabledTooltip}
            disabledDate={handleDisabledDate}
            dropdownClassName={styles.panel}
            inputReadOnly
            onOpenChange={handleOpenChange}
            open={open}
            renderExtraFooter={() => (
              <ButtonLink className={styles.clear} onClick={handleClearDateClick}>
                {intl.formatMessage(messages.removeDateTime)}
              </ButtonLink>
            )}
            showTime={false}
            suffixIcon={<></>}
          />
        </Form.Item>

        <Button className={cn(styles.btn, { [styles.active]: open })} onClick={handleClick}>
          {currentDate && formatDate(currentDate, dateFormat)}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ActivityEndDate;
