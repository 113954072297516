import React, { useCallback } from "react";

import { bem } from "@react-md/utils";

import SelectableTeam from "@mapmycustomers/shared/types/ui/SelectableTeam";

import { useConfigProvider } from "../ConfigProvider";

import SelectableItem from "./SelectableItem";

const block = bem("mmc-team-item");

interface Props {
  onClick?: (team: SelectableTeam) => void;
  selected?: boolean;
  team: SelectableTeam;
}

const TeamItem: React.FC<Props> = ({ onClick, selected, team }) => {
  const configProvider = useConfigProvider();

  const handleClick = useCallback(() => onClick?.(team), [onClick, team]);

  return (
    <SelectableItem
      className={block()}
      label={
        team === "owners"
          ? configProvider.formatMessage("ui.common.teamItem.owners")
          : team === "noTeam"
          ? configProvider.formatMessage("ui.common.teamItem.noTeam")
          : team.name
      }
      onClick={handleClick}
      selected={selected}
      selectedClassName={block({ selected })}
    />
  );
};

export default TeamItem;
