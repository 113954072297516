import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";

import MmcNotification from "@app/types/MmcNotification";

export const fetchNotifications = createAsyncAction(
  "@notification/fetchNotifications/request",
  "@notification/fetchNotifications/success",
  "@notification/fetchNotifications/failure"
)<
  { onlyMentions?: boolean; reload?: boolean; updateTotalsOnly?: boolean },
  {
    notifications: MmcNotification[];
    total: number;
    unreadTotal: number;
  },
  unknown
>();

export const fetchNotification = createAsyncAction(
  "@notification/fetchNotification/request",
  "@notification/fetchNotification/success",
  "@notification/fetchNotification/failure"
)<
  { callback?: (notification: MmcNotification) => void; id: MmcNotification["id"] },
  MmcNotification,
  unknown
>();

export const fetchNotificationsUnreadTotal = createAsyncAction(
  "@notification/fetchNotificationsUnreadTotal/request",
  "@notification/fetchNotificationsUnreadTotal/success",
  "@notification/fetchNotificationsUnreadTotal/failure"
)<void, number, unknown>();

export const clearNotifications = createAction("@notification/clearNotifications")<void>();

export const clickOnNotification = createAction("@notification/clickOnNotification")<{
  elementId?: string;
  notification: MmcNotification;
}>();

export const updateNotificationReadStatus = createAsyncAction(
  "@notification/updateNotificationReadStatus/request",
  "@notification/updateNotificationReadStatus/success",
  "@notification/updateNotificationReadStatus/failure"
)<{ id: MmcNotification["id"]; readStatus: boolean }, void, unknown>();

export const markNotificationsAsRead = createAsyncAction(
  "@notification/markNotificationsAsRead/request",
  "@notification/markNotificationsAsRead/success",
  "@notification/markNotificationsAsRead/failure"
)<void, void, unknown>();

export const showCadenceModal = createAsyncAction(
  "@notification/showCadenceModal/request",
  "@notification/showCadenceModal/success",
  "@notification/showCadenceModal/failure"
)<MmcNotification, { entities: OutOfCadenceEntity[]; notification: MmcNotification }, unknown>();

export const hideCadenceModal = createAction("@notification/hideCadenceModal")<void>();

export const showNotifications = createAction("@notification/showNotifications")<void>();

export const hideNotifications = createAction("@notification/hideNotifications")<void>();

export type Actions = ActionType<
  | typeof clearNotifications
  | typeof clickOnNotification
  | typeof fetchNotification
  | typeof fetchNotifications
  | typeof fetchNotificationsUnreadTotal
  | typeof hideCadenceModal
  | typeof hideNotifications
  | typeof markNotificationsAsRead
  | typeof showCadenceModal
  | typeof showNotifications
  | typeof updateNotificationReadStatus
>;
