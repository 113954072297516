import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { AnyEntity } from "@mapmycustomers/shared/types/entity";

import ViewMode from "@app/enum/dashboard/ViewMode";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import { TabbedDrillDownListFetcherPayload } from "@app/scene/dashboard/types/TabbedDrillDownListFetcher";
import EntityTypesSupportedInOutOfFrequency from "@app/types/dashboard/cards/outOfFrequency/EntityTypesSupportedInOutOfFrequency";
import OutOfFrequencyCardConfiguration from "@app/types/dashboard/cards/outOfFrequency/OutOfFrequencyCardConfiguration";
import Scope from "@app/types/dashboard/Scope";
import MapViewportState from "@app/types/map/MapViewportState";

import OutOfFrequencyCardData from "./OutOfFrequencyCardData";

export const fetchOutOfFrequencyCardData = createAction("@dashboard/fetchOutOfFrequencyCardData")<{
  callback: (data: OutOfFrequencyCardData) => void;
  configuration: OutOfFrequencyCardConfiguration;
  failureCallback?: () => void;
  offset?: number; // used with List view mode when we need to load more items
  scope?: Scope;
  viewMode: ViewMode;
  viewport?: MapViewportState; // used with Map view mode when user pans/zooms
}>();

export const showOutOfFrequencyDrillDown = createAsyncAction(
  "@dashboard/showOutOfFrequencyCardDrillDownModal/request",
  "@dashboard/showOutOfFrequencyCardDrillDownModal/success",
  "@dashboard/showOutOfFrequencyCardDrillDownModal/failure"
)<
  {
    configuration: OutOfFrequencyCardConfiguration;
    scope: Scope;
  },
  { viewStates: Record<EntityTypesSupportedInOutOfFrequency, DrillDownViewState> },
  unknown
>();

export const hideOutOfFrequencyDrillDown = createAction(
  "@dashboard/hideOutOfFrequencyCardDrillDownModal"
)<void>();

export const fetchOutOfFrequencyCardDrillDownData = createAsyncAction(
  "@dashboard/fetchOutOfFrequencyCardDrillDownData/request",
  "@dashboard/fetchOutOfFrequencyCardDrillDownData/success",
  "@dashboard/fetchOutOfFrequencyCardDrillDownData/failure"
)<
  TabbedDrillDownListFetcherPayload<AnyEntity, EntityTypesSupportedInOutOfFrequency>,
  {
    entityType: EntityTypesSupportedInOutOfFrequency;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyOutOfFrequencyDrillDownListViewSettings = createAction(
  "@dashboard/applyOutOfFrequencyDrillDownListViewSettings"
)<Partial<DrillDownViewState> & { entityType: EntityTypesSupportedInOutOfFrequency }>();

export const exportOutOfFrequencyCardDrillDownData = createAction(
  "@dashboard/exportOutOfFrequencyCardData"
)<{
  configuration: OutOfFrequencyCardConfiguration;
  entityType: EntityTypesSupportedInOutOfFrequency;
  scope: Scope;
}>();

export type OutOfFrequencyCardActions = ActionType<
  | typeof applyOutOfFrequencyDrillDownListViewSettings
  | typeof exportOutOfFrequencyCardDrillDownData
  | typeof fetchOutOfFrequencyCardData
  | typeof fetchOutOfFrequencyCardDrillDownData
  | typeof hideOutOfFrequencyDrillDown
  | typeof showOutOfFrequencyDrillDown
>;
