import memoize from "fast-memoize";
import { createSelector } from "reselect";

import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import { EntityTypeSupportingCustomFields } from "@mapmycustomers/shared/types/entity";

import { RootState } from "../rootReducer";

import { stateFieldBaseName } from "./util";

const customFieldsState = (state: RootState) => state.customFields;

// same as selectors above, but accepts the entityType argument
export const getCustomFieldsForEntity = createSelector(customFieldsState, (state) =>
  memoize((entityType: EntityTypeSupportingCustomFields): CustomField[] => {
    return state[stateFieldBaseName[entityType]];
  })
);

export const isCustomFieldDeleting = createSelector(
  customFieldsState,
  ({ customFieldDeleting }) => customFieldDeleting
);

export const isCustomFieldCreating = createSelector(
  customFieldsState,
  ({ customFieldCreating }) => customFieldCreating
);

export const isCustomFieldUpdating = createSelector(
  customFieldsState,
  ({ customFieldUpdating }) => customFieldUpdating
);
