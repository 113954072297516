import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import FormLayout, { ChildLayout } from "@mapmycustomers/shared/types/layout/FormLayout";

export type Variant =
  | {
      activityTypeId: ActivityType["id"];
    }
  | {
      childLayoutId: ChildLayout["id"];
    }
  | {
      funnelId: Funnel["id"];
      stageId: Stage["id"];
    };

const getChildLayout = (layout: FormLayout, variant?: Variant): ChildLayout | undefined =>
  layout.childLayouts?.length && variant
    ? "childLayoutId" in variant
      ? layout.childLayouts.find((child) => child.id === variant.childLayoutId)
      : "activityTypeId" in variant
      ? layout.childLayouts.find((child) => child.crmActivityType?.id === variant.activityTypeId)
      : "funnelId" in variant && "stageId" in variant
      ? layout.childLayouts.find(
          (child) => child.stage?.id === variant.stageId && child.funnel?.id === variant.funnelId
        )
      : undefined
    : undefined;

export default getChildLayout;
