import { createReducer } from "typesafe-actions";

import User from "@mapmycustomers/shared/types/User";

import { Actions, setSelectedUserIds } from "./actions";

interface UserColor {
  selectedUserIds: User["id"][];
}

const initialState: UserColor = {
  selectedUserIds: [],
};

const userColor = createReducer<UserColor, Actions>(initialState).handleAction(
  setSelectedUserIds,
  (state, { payload }) => ({
    ...state,
    selectedUserIds: payload,
  })
);

export type UserColorActions = Actions;
export default userColor;
