import { defineMessages } from "react-intl";

const messages = defineMessages({
  add: {
    id: "createEditActivityModal.header.notifications.add",
    defaultMessage: "+ Another Notification",
    description: "Another Notification button title",
  },
  before: {
    id: "createEditActivityModal.header.notifications.before",
    defaultMessage: "before",
    description: "Before label",
  },
  days: {
    id: "createEditActivityModal.header.notifications.days",
    defaultMessage: "{num} {num, plural, one {day} other {days}} before",
    description: "Days label",
  },
  disabled: {
    id: "createEditActivityModal.header.notifications.disabled",
    defaultMessage: "Notifications Disabled",
    description: "Notifications Disabled label",
  },
  email: {
    id: "createEditActivityModal.header.notifications.email",
    defaultMessage: "Email",
    description: "Email label",
  },
  hours: {
    id: "createEditActivityModal.header.notifications.hours",
    defaultMessage: "{num} {num, plural, one {hour} other {hours}} before",
    description: "Hours label",
  },
  minutes: {
    id: "createEditActivityModal.header.notifications.minutes",
    defaultMessage: "{num} {num, plural, one {minute} other {minutes}} before",
    description: "Minutes label",
  },
  multiple: {
    id: "createEditActivityModal.header.notifications.multiple",
    defaultMessage: "Multiple",
    description: "Multiple notifications label",
  },
  pastDate: {
    id: "createEditActivityModal.header.notifications.pastDate",
    defaultMessage: "Notifications disabled for activities in past",
    description: "Notifications disabled for activities in past button title",
  },
  push: {
    id: "createEditActivityModal.header.notifications.push",
    defaultMessage: "Push",
    description: "Push label",
  },
  remove: {
    id: "createEditActivityModal.header.notifications.remove",
    defaultMessage: "Remove",
    description: "Remove button tooltip",
  },
  title: {
    id: "createEditActivityModal.header.notifications.title",
    defaultMessage: "Notify Me Via",
    description: "Notify Me Via popover title",
  },
  tooltip: {
    id: "createEditActivityModal.header.notifications.tooltip",
    defaultMessage: "Manage Notifications",
    description: "Manage Notifications tooltip",
  },
  weeks: {
    id: "createEditActivityModal.header.notifications.weeks",
    defaultMessage: "{num} {num, plural, one {week} other {weeks}} before",
    description: "Weeks label",
  },
});

export default messages;
