import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { bem } from "@react-md/utils";
import { Editor, Range } from "slate";
import { ReactEditor } from "slate-react";

import User from "@mapmycustomers/shared/types/User";

import AvatarWithName from "../../../../../Avatar/AvatarWithName";
import { useConfigProvider } from "../../../../../ConfigProvider";
import { MENTION_LETTER_OFFSET } from "../util/const";

const block = bem("mmc-mentions-dropdown");

export const messages = {
  "ui.input.richtext.mentionsDropdown.noResult": "No results",
};

interface PortalProps {}

const Portal: React.FC<PortalProps> = ({ children }) => {
  return typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null;
};

interface Props {
  activeIndex: number;
  editor: Editor;
  emptyTextForWithSkippedUsers?: string;
  onHover: (index: number) => void;
  onSelect: (index: number) => void;
  target: Range;
  users: User[];
}

const MentionsDropdown: React.FC<Props> = ({
  activeIndex,
  editor,
  emptyTextForWithSkippedUsers,
  onHover,
  onSelect,
  target,
  users,
}) => {
  const configProvider = useConfigProvider();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (target) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      if (el) {
        let top = rect.top + window.pageYOffset + MENTION_LETTER_OFFSET;
        if (top + el.clientHeight > window.innerHeight) {
          top = rect.top + window.pageYOffset - el.clientHeight;
        }
        let left = rect.left + window.pageXOffset;
        if (left + el.clientWidth > window.innerWidth) {
          left = window.innerWidth - el.clientWidth;
        }
        el.style.top = `${top}px`;
        el.style.left = `${left}px`;
      }
    }
  }, [editor, target, users.length]);

  return (
    <Portal>
      <div className={block("suggestions")} data-cy="mentions-portal" ref={ref}>
        {users.map((user, i) => (
          <div
            className={block("suggestion", { highlighted: i === activeIndex })}
            key={user.id}
            onClick={() => onSelect(i)}
            onMouseEnter={() => onHover(i)}
          >
            <AvatarWithName className={block("userNameWrapper")} user={user} />
          </div>
        ))}
        {users.length === 0 && (
          <div className={block("suggestion", { noResult: true })}>
            {emptyTextForWithSkippedUsers
              ? emptyTextForWithSkippedUsers
              : configProvider.formatMessage("ui.input.richtext.mentionsDropdown.noResult")}
          </div>
        )}
      </div>
    </Portal>
  );
};

export default MentionsDropdown;
