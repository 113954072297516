import React from "react";
import { connect } from "react-redux";

import Col from "antd/es/col";
import Row from "antd/es/row";
import { useIntl } from "react-intl";

import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";

import { getEmailQuota } from "@app/store/email";
import { RootState } from "@app/store/rootReducer";

import TextWithInfo from "../typography/TextWithInfo";

import styles from "./EmailSummaryInfo.module.scss";

interface Props {
  emailQuota?: EmailQuota;
}

const TotalSendLeft: React.FC<Props> = ({ emailQuota }) => {
  const intl = useIntl();

  const total = emailQuota?.total ?? 0;

  const left = total - (emailQuota?.sent ?? 0);

  return (
    <Row align="middle" justify="space-between">
      <Col className={styles.infoText}>
        <TextWithInfo
          info={intl.formatMessage(
            {
              id: "component.email.summaryInfo.point2.tooltip",
              defaultMessage: "You can send up to {count} emails per day",
              description: "Third point tooltip in the email summary info.",
            },
            { count: emailQuota?.total ?? 0 }
          )}
          placement="top"
        >
          {intl.formatMessage({
            id: "component.email.summaryInfo.point2",
            defaultMessage: "Total sends left today",
            description: "Third point in the email summary info.",
          })}
        </TextWithInfo>
      </Col>
      <Col className={styles.infoValue}>
        {left} / {total}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailQuota: getEmailQuota(state),
});

export default connect(mapStateToProps)(TotalSendLeft);
