import { defineMessages, IntlShape } from "react-intl";

import SchemaFieldCategory from "@mapmycustomers/shared/enum/SchemaFieldCategory";

export const schemaFieldCategoryMessages = defineMessages({
  custom: {
    id: "schemaFieldCategory.custom",
    defaultMessage: "Custom",
    description: "Schema field category - Custom",
  },
  relationship: {
    id: "schemaFieldCategory.relationship",
    defaultMessage: "Relationship",
    description: "Schema field category - Relationship",
  },
  system: {
    id: "schemaFieldCategory.system",
    defaultMessage: "System",
    description: "Schema field category - System",
  },
});

const getSchemaFieldCategoryDisplayName = (
  intl: IntlShape,
  schemaFieldCategory: SchemaFieldCategory
) => {
  switch (schemaFieldCategory) {
    case SchemaFieldCategory.ADDRESS:
    case SchemaFieldCategory.FILE:
    case SchemaFieldCategory.GROUP:
    case SchemaFieldCategory.NOTE:
    case SchemaFieldCategory.STANDARD:
    case SchemaFieldCategory.SYSTEM_DEPENDENT:
    case SchemaFieldCategory.SYSTEM_REQUIRED:
    case SchemaFieldCategory.VARIANT:
      return intl.formatMessage(schemaFieldCategoryMessages.system);
    case SchemaFieldCategory.CUSTOM:
      return intl.formatMessage(schemaFieldCategoryMessages.custom);
    case SchemaFieldCategory.RELATIONSHIP:
      return intl.formatMessage(schemaFieldCategoryMessages.relationship);
  }
};

export default getSchemaFieldCategoryDisplayName;
