import React, { ReactNode, useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "antd/es/badge";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Popover from "antd/es/popover";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import Color from "@mapmycustomers/shared/enum/Color";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import Located from "@mapmycustomers/shared/types/base/Located";
import TypedEntity from "@mapmycustomers/shared/types/base/TypedEntity";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import { UserRef } from "@mapmycustomers/shared/types/User";
import { isNotEmptyString } from "@mapmycustomers/shared/util/assert";
import { getFormattedAddressForUi } from "@mapmycustomers/shared/util/formatters";
import { Avatar } from "@mapmycustomers/ui";

import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";
import FieldGrid from "@app/component/FieldGrid";
import Checkbox from "@app/component/input/Checkbox";
import RadioButton from "@app/component/input/RadioButton";
import EntityColumnsConfiguration from "@app/store/recordPreview/EntityColumnsConfiguration";
import { getRecordPreviewConfigurationColumns } from "@app/store/recordPreview/selectors";
import { RootState } from "@app/store/rootReducer";
import anyColorToHex from "@app/util/colors/anyColorToHex";
import { TOPMOST_MODAL_ZINDEX } from "@app/util/consts";
import locatedToAddress from "@app/util/locatedToAddress";
import loggingService from "@app/util/logging";

import styles from "./AssociationRow.module.scss";

interface Props {
  columns: EntityColumnsConfiguration;
  disabled?: boolean;
  entity: Company | Deal | Person;
  icon?: ReactNode;
  multiselect?: boolean;
  onChange?: (entityId: Identified["id"], value: boolean) => void;
  selected: boolean;
  tooltipMessage?: ReactNode;
}

const AssociationRow: React.FC<Props> = ({
  columns,
  disabled,
  entity,
  icon,
  multiselect,
  onChange,
  selected = false,
  tooltipMessage,
}) => {
  const intl = useIntl();

  const entityColumns = useMemo(() => {
    return (columns?.[entity.entity] ?? []).filter(
      (field) =>
        !field.hasFeature(FieldFeature.MAP_PINNED_FIELD) &&
        field.isReadable &&
        field.hasNonEmptyValueFor(entity)
    );
  }, [columns, entity]);

  const labelId = `association_${entity.entity}:${entity.id}`;

  const style = useMemo(
    () => ({
      borderColor: anyColorToHex(
        (entity.entity === EntityType.DEAL ? entity.stage.color : entity.color) ?? Color.BLACK
      ),
    }),
    [entity]
  );

  const handleChange = useCallback(
    (checked: boolean, event: CheckboxChangeEvent) => {
      event.stopPropagation();
      onChange?.(entity.id, checked);
    },
    [entity, onChange]
  );

  const handleClick = useCallback(() => {
    onChange?.(entity.id, !selected);
  }, [entity, onChange, selected]);

  const info = useMemo(() => {
    if ([EntityType.COMPANY, EntityType.PERSON].includes(entity.entity)) {
      const companyOrPerson = entity as Company | Person;
      return [
        getFormattedAddressForUi(locatedToAddress(companyOrPerson as Located)),
        companyOrPerson.phone,
        companyOrPerson.email,
      ]
        .filter(isNotEmptyString)
        .join(" • ");
    } else if (entity.entity === EntityType.DEAL) {
      const deal = entity as Deal;
      return (
        <>
          <Badge color={deal.stage.color} text={deal.stage.name} />
          {` • ${[deal.funnel.name, deal.account?.name, deal.contact?.name]
            .filter(isNotEmptyString)
            .join(" • ")}`}
        </>
      );
    } else {
      loggingService.error(`Unexpected entity type in AssociationRow's entity: ${entity.entity}`, {
        entity,
      });
      return null;
    }
  }, [entity]);

  return (
    <div
      className={cn(styles.item, { [styles.disabled]: disabled })}
      onClick={handleClick}
      style={style}
    >
      {multiselect !== undefined && (
        <div className={styles.control}>
          <Tooltip title={tooltipMessage} trigger={disabled && tooltipMessage ? ["hover"] : []}>
            {multiselect ? (
              <Checkbox
                checked={selected}
                disabled={disabled}
                id={labelId}
                onChange={handleChange}
              />
            ) : (
              <RadioButton
                checked={selected}
                disabled={disabled}
                id={labelId}
                onChange={handleChange}
              />
            )}
          </Tooltip>
        </div>
      )}

      <div className={cn(styles.details, { [styles.disabled]: disabled })}>
        <div className={styles.name}>
          <span>{entity.name}</span>

          {entityColumns.length > 0 && (
            <span className={styles.info}>
              <Popover
                arrowPointAtCenter
                content={
                  <div className={styles.content}>
                    <div className={styles.title}>
                      {intl.formatMessage({
                        id: "associations.pinnedFields.title",
                        defaultMessage: "Pinned Fields",
                        description: "Pinned Fields popover title",
                      })}
                    </div>

                    <div className={styles.body}>
                      <FieldGrid
                        className={styles.grid}
                        columns={entityColumns}
                        entity={entity}
                        showEmpty={false}
                      />
                    </div>
                  </div>
                }
                destroyTooltipOnHide
                mouseLeaveDelay={0}
                placement="rightTop"
                // modal has TOPMOST_MODAL_ZINDEX, so we need to override popover's z-index to make it appear over the modal
                zIndex={TOPMOST_MODAL_ZINDEX}
              >
                <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
              </Popover>
            </span>
          )}
        </div>
        {<span className={styles.infoText}>{info}</span>}
      </div>

      <div className={styles.icon}>
        {icon ? (
          <>{icon}</>
        ) : "entity" in entity ? (
          <>
            {"user" in entity ? (
              <Avatar user={(entity as unknown as { user: UserRef }).user} />
            ) : "entity" in entity ? (
              <EntityTypeShapedIcon
                containerClassName={styles.typeIcon}
                entityType={(entity as unknown as TypedEntity<EntityType>).entity}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  columns: getRecordPreviewConfigurationColumns(state),
});

export default connect(mapStateToProps)(AssociationRow);
