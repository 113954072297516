import { ActionType, createAsyncAction } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";

export const updateActivity = createAsyncAction(
  "@component/activityInfoModal/changeCompletion/request",
  "@component/activityInfoModal/changeCompletion/success",
  "@component/activityInfoModal/changeCompletion/failure"
)<{ activity: Activity; callback: (updatedActivity: Activity) => void }, void, void>();

export type Actions = ActionType<typeof updateActivity>;
