enum DateRangeType {
  YESTERDAY = "yesterday",
  THIS_WEEK = "week",
  THIS_MONTH = "month",
  LAST_WEEK = "lastWeek",
  CUSTOM = "custom",
  PREVIOUS_MONTH = "lastMonth",
}

export default DateRangeType;
