import { defineMessage } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import FilterModel, {
  FilterCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import i18nService from "@app/config/I18nService";
import { listFormatter } from "@app/util/fieldModel/impl/fieldUtil";
import TeamsFilter, { TEAMS_FILTER_OPERATORS } from "@app/util/filters/TeamsFilter";
import { getRegularFieldConditionValue } from "@app/util/viewModel/convertFromPlatformFilterModel";

import Field, { FieldProperties } from "./Field";

const incompatibleFilterMessage = defineMessage({
  id: "teamField.incompatibleFilter",
  defaultMessage: "Cannot filter by {field} when {incompatibleField} filter applied",
  description:
    "Tooltip shown when team field filter is disabled because there's some incompatible filter",
});

class TeamField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "team",
        filterInstances: {
          team: TeamsFilter,
        },
        operators: TEAMS_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "teamsCellRenderer",
        headerComponent: "teamsHeader",
      },
      exportColumnName: "teams",
      features: [
        FieldFeature.DATA_VIEW,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.TEAM_FIELD,
      ],
      filterType: FieldType.ID,
      type: FieldType.OBJECT,
      valueFormatter: listFormatter("name"),
      valueGetter: "teams", // let's get the value from teams field
      ...data,
    });
  }

  isFilterEnabled(currentFilters: Partial<FilterModel>, fieldModel: IFieldModel) {
    const fieldFilterNames = Object.keys(currentFilters).filter(
      (fieldFilterName) => currentFilters[fieldFilterName] !== undefined
    );
    const incompatibleField = fieldFilterNames
      .map((name) => fieldModel.getByFilterName(name))
      .filter(isDefined)
      .find((field) => field.hasFeature(FieldFeature.OWNER_FIELD));

    if (!incompatibleField) {
      return true;
    }

    return i18nService.formatMessage(
      incompatibleFilterMessage,
      `Cannot filter by ${this.displayName} when ${incompatibleField.displayName} filter applied`,
      {
        field: this.displayName,
        incompatibleField: incompatibleField.displayName,
      }
    );
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    // team field filter is applied as a meta-filter, thus it doesn't have a name
    // so we're adding a field name back for it.
    return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
  }
}

export default TeamField;
