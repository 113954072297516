enum Environment {
  DEVELOPMENT = "development",
  STAGING = "staging",
  SANDBOX = "sandbox",
  PRODUCTION = "production",
}

export const environmentColorMap: {
  [key in Environment]: string;
} = Object.freeze({
  [Environment.DEVELOPMENT]: "#87d068",
  [Environment.PRODUCTION]: "#f50",
  [Environment.SANDBOX]: "#fa16b2",
  [Environment.STAGING]: "#fa8c16",
});

export default Environment;
