import notification from "antd/es/notification";
import { all, call, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { Action, isActionOf } from "typesafe-actions";

import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import { EntityTypeSupportingCustomFields } from "@mapmycustomers/shared/types/entity";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { getCustomFieldsForEntity } from "@app/store/customFields/selectors";
import { getOrganization } from "@app/store/iam";
import { getAllColorLegends, getAllShapeLegends } from "@app/store/pinLegends";
import { fetchPinLegends } from "@app/store/pinLegends/actions";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";

import i18nService from "../../config/I18nService";
import { callApi } from "../api/callApi";
import { handleError } from "../errors/actions";

import {
  createCustomField,
  deleteCustomField,
  fetchAllCustomFields,
  fetchCustomFields,
  updateCustomField,
  updateCustomFieldBulk,
} from "./actions";

const successForEntityTypeActionMatcher =
  (entityType: EntityTypeSupportingCustomFields) =>
  (action: Action): boolean =>
    isActionOf(fetchCustomFields.success, action) && action.payload.entityType === entityType;

export function* onFetchCustomFields({ payload }: ReturnType<typeof fetchCustomFields.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<CustomField> = yield callApi(
      "fetchCustomFields",
      organization.id,
      payload.entityType
    );

    // update corresponding field model with a fresh custom fields data :)
    const fieldModel = getFieldModelByEntityType(payload.entityType);
    if (fieldModel) {
      yield call(fieldModel.setCustomFields, response.data);
    }

    yield put(fetchCustomFields.success({ entityType: payload.entityType, fields: response.data }));
  } catch (error) {
    yield put(fetchCustomFields.failure({ entityType: payload.entityType, error }));
  }
}

export function* onFetchAllCustomFields() {
  try {
    // call fetchCustomFields actions
    yield put(fetchCustomFields.request({ entityType: EntityType.COMPANY }));
    yield put(fetchCustomFields.request({ entityType: EntityType.PERSON }));
    yield put(fetchCustomFields.request({ entityType: EntityType.DEAL }));
    yield put(fetchCustomFields.request({ entityType: EntityType.ACTIVITY }));

    // now wait till all succeed or any fails, whichever happens first
    const { failure } = yield race({
      failure: take([fetchCustomFields.failure]),
      success: all([
        take(successForEntityTypeActionMatcher(EntityType.ACTIVITY)),
        take(successForEntityTypeActionMatcher(EntityType.COMPANY)),
        take(successForEntityTypeActionMatcher(EntityType.DEAL)),
        take(successForEntityTypeActionMatcher(EntityType.PERSON)),
      ]),
    });

    if (failure) {
      yield put(fetchAllCustomFields.failure());
    } else {
      yield put(fetchAllCustomFields.success());
    }
  } catch {
    yield put(fetchAllCustomFields.failure());
    // error is handled in the onInitializeApp saga
  }
}

export function* onDeleteCustomField({
  payload: { id, callback, entityType, sendReport },
}: ReturnType<typeof deleteCustomField.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteCustomField", org.id, entityType, id, sendReport);
    callback?.();
    yield put(deleteCustomField.success({ id, entityType }));

    const colorLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapeLegends: PinLegend[] = yield select(getAllShapeLegends);
    if (
      colorLegends.some((legend) => legend.customFieldId === id) ||
      shapeLegends.some((legend) => legend.customFieldId === id)
    ) {
      // re-fetch pin legends since there will be less now
      yield put(fetchPinLegends.request());
    }

    // refresh field model
    const customFieldsGetter: (entityType: EntityTypeSupportingCustomFields) => CustomField[] =
      yield select(getCustomFieldsForEntity);
    const fieldModel: IFieldModel = yield call(getFieldModelByEntityType, entityType);
    yield call(
      [fieldModel, fieldModel.setCustomFields],
      customFieldsGetter(entityType).filter((customField) => customField.id !== id)
    );

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage({
          id: "customFields.delete.success",
          defaultMessage: "Custom field deleted successfully",
          description: "Custom field deleted successfully",
        }),
      });
    }
  } catch (error) {
    yield put(deleteCustomField.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateCustomField({
  payload: { callback, customField, entityType },
}: ReturnType<typeof createCustomField.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("createCustomField", org.id, entityType, customField);
    callback?.();
    yield put(fetchCustomFields.request({ entityType }));
    yield put(createCustomField.success());
  } catch (error) {
    yield put(createCustomField.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateCustomField({
  payload: { callback, customField, entityType },
}: ReturnType<typeof updateCustomField.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("updateCustomField", org.id, entityType, customField);
    callback?.();
    yield put(fetchCustomFields.request({ entityType }));
    yield put(updateCustomField.success());

    if (
      customField.dataType === CustomFieldDataType.MULTI_OPTION ||
      customField.dataType === CustomFieldDataType.SINGLE_OPTION
    ) {
      // re-fetch pin legends in case any options were changed
      yield put(fetchPinLegends.request());
    }
  } catch (error) {
    yield put(updateCustomField.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateCustomFieldBulk({
  payload: { entityType, fields },
}: ReturnType<typeof updateCustomFieldBulk.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("updateCustomFieldBulk", org.id, entityType, fields);
    yield put(updateCustomFieldBulk.success());
  } catch (error) {
    yield put(updateCustomFieldBulk.failure());
    yield put(handleError({ error }));
  }
}

export function* customFieldsSaga() {
  yield takeEvery(fetchCustomFields.request, onFetchCustomFields);
  yield takeLatest(fetchAllCustomFields.request, onFetchAllCustomFields);
  yield takeLatest(deleteCustomField.request, onDeleteCustomField);
  yield takeLatest(createCustomField.request, onCreateCustomField);
  yield takeLatest(updateCustomField.request, onUpdateCustomField);
  yield takeEvery(updateCustomFieldBulk.request, onUpdateCustomFieldBulk);
}
