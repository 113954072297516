import pick from "lodash-es/pick";

import Visibility from "./Visibility";

type PinLegendVisibility =
  | Visibility.PRIVATE
  | Visibility.SHARED_WITH_ORGANIZATION
  | Visibility.SHARED_WITH_TEAM;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const PinLegendVisibility = pick(Visibility, [
  "PRIVATE",
  "SHARED_WITH_TEAM",
  "SHARED_WITH_ORGANIZATION",
]);

export default PinLegendVisibility;
