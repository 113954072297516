import compact from "lodash-es/compact";
import { createReducer } from "typesafe-actions";

import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { Activity, Company, Deal, Route } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import { RawFile } from "@mapmycustomers/shared/types/File";
import User from "@mapmycustomers/shared/types/User";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";

import { fileCreatedAtDescComparator } from "@app/component/preview/util/comparators";
import localSettings from "@app/config/LocalSettings";
import RecapRange from "@app/enum/preview/RecapRange";
import RecordPaneTab from "@app/enum/preview/RecordPaneTab";
import FileListItem from "@app/types/FileListItem";
import personFieldModel from "@app/util/fieldModel/PersonFieldModel";

import {
  Actions,
  addPersonCompanies,
  addPersonDeals,
  changeActivitiesAssignees,
  changeActivitiesFilter,
  changeActivitiesOrder,
  changeActivitiesRecapRange,
  changeActivitiesSearchQuery,
  changeActivitiesSelectedActivityTypes,
  clearAllUploadedPersonFiles,
  createPersonNote,
  deletePerson,
  deletePersonFile,
  deletePersonNote,
  fetchMoreCompanies,
  fetchMoreDeals,
  fetchPerson,
  fetchPersonActivities,
  fetchPersonActivitiesCompletedByType,
  fetchPersonActivitiesTotal,
  fetchPersonRoutes,
  fetchPreviewData,
  postponeActivity,
  removePersonCompany,
  removePersonDeal,
  removePersonFromRoute,
  setActiveTab,
  setActivityAutoScrolling,
  setHasChangesFlag,
  setPersonColor,
  setPersonShape,
  toggleCompleteActivity,
  updateActivityNote,
  updatePerson,
  updatePersonFrequency,
  updatePersonNote,
  uploadPersonFiles,
} from "./actions";
import PersonRecordData from "./PersonRecordData";

const INITIAL_RECORD_DATA: Readonly<PersonRecordData> = {
  activities: [],
  activitiesCompletedByType: [],
  activitiesTotal: 0,
  companies: [],
  deals: [],
  files: [],
  notes: [],
  person: undefined,
  routes: [],
  totalCompanies: 0,
  totalDeals: 0,
  uncompletedActivities: [],
};

export interface PersonRecordState {
  activeTab?: RecordPaneTab;
  activitiesCompletedByTypeLoading: boolean;
  activitiesFilter: ActivityStatusOption;
  activitiesLoading: boolean;
  activitiesOrder: SortModel;
  activitiesRecapRange: RecapRange;
  activitiesSearchQuery: string;
  activitiesSelectedActivityTypes: ActivityType[];
  activitiesSelectedAssignees: User["id"][];
  activitiesTotalLoading: boolean;
  activityAutoScrolling?: boolean;
  addPersonDealsLoading: boolean;
  addPersonParentCompanyLoading: boolean;
  deleteLoading?: boolean;
  filesAdded: FileListItem[];
  filesToDeletedIds: RawFile["id"][];
  filesUploading: boolean;
  hasChanges: boolean;
  loading: boolean;
  personLoading: boolean;
  personNoteCreateLoading: boolean;
  personNoteDeleteLoadingIds: Note["id"][];
  personNoteUpdateLoadingIds: Note["id"][];
  personRoutesLoading: boolean;
  personUpdateLoading: boolean;
  postponeActivityLoadingIds: Activity["id"][];
  recordData: PersonRecordData;
  removePersonDealLoadingIds: Deal["id"][];
  removePersonParentCompanyLoadingIds: Company["id"][];
  removePersonRouteLoadingsIds: Route["id"][];
  toggleCompleteActivityLoadingIds: Activity["id"][];
  updateActivityNoteLoadingIds: Activity["id"][];
}

const initialState: PersonRecordState = {
  activeTab: undefined,
  activitiesCompletedByTypeLoading: false,
  activitiesFilter: ActivityStatusOption.ALL,
  activitiesLoading: false,
  activitiesOrder: [{ field: personFieldModel.getByName("createdAt")!, order: SortOrder.DESC }],
  activitiesRecapRange: localSettings.getRecapChartRange() ?? RecapRange.THIS_YEAR,
  activitiesSearchQuery: "",
  activitiesSelectedActivityTypes: [],
  activitiesSelectedAssignees: [],
  activitiesTotalLoading: false,
  addPersonDealsLoading: false,
  addPersonParentCompanyLoading: false,
  filesAdded: [],
  filesToDeletedIds: [],
  filesUploading: false,
  hasChanges: false,
  loading: false,
  personLoading: false,
  personNoteCreateLoading: false,
  personNoteDeleteLoadingIds: [],
  personNoteUpdateLoadingIds: [],
  personRoutesLoading: false,
  personUpdateLoading: false,
  postponeActivityLoadingIds: [],
  recordData: INITIAL_RECORD_DATA,
  removePersonDealLoadingIds: [],
  removePersonParentCompanyLoadingIds: [],
  removePersonRouteLoadingsIds: [],
  toggleCompleteActivityLoadingIds: [],
  updateActivityNoteLoadingIds: [],
};

const updateRecordData = (state: PersonRecordState, payload: Partial<Activity>) => {
  const updateActivities = (activities: Activity[]) =>
    activities.map((item) => {
      if (payload.id === item.id) {
        return {
          ...item,
          ...payload,
        };
      }
      return item;
    });

  return {
    ...state.recordData,
    activities: updateActivities(state.recordData.activities),
    activitiesCompletedByType: updateActivities(state.recordData.activitiesCompletedByType),
  };
};

const personRecord = createReducer<PersonRecordState, Actions>(initialState)
  .handleAction(fetchPreviewData.request, (state) => ({
    ...state,
    hasChanges: false,
    loading: true,
    recordData: INITIAL_RECORD_DATA,
  }))
  .handleAction(fetchPreviewData.success, (state, action) => ({
    ...state,
    activitiesSelectedActivityTypes: action.payload.selectedActivityTypes,
    hasChanges: false,
    loading: false,
    recordData: action.payload.recordData,
  }))
  .handleAction(fetchPreviewData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchPerson.request, (state) => ({
    ...state,
    personLoading: true,
  }))
  .handleAction(fetchPerson.success, (state, action) => ({
    ...state,
    personLoading: false,
    recordData: {
      ...state.recordData,
      person: Object.assign({}, state.recordData.person, action.payload),
    },
  }))
  .handleAction(fetchPerson.failure, (state) => ({
    ...state,
    personLoading: false,
  }))
  .handleAction(fetchPersonActivities.request, (state) => ({
    ...state,
    activitiesLoading: true,
  }))
  .handleAction(fetchPersonActivities.success, (state, action) => ({
    ...state,
    activitiesLoading: false,
    recordData: {
      ...state.recordData,
      activities: action.payload,
    },
  }))
  .handleAction(fetchPersonActivities.failure, (state) => ({
    ...state,
    activitiesLoading: false,
  }))
  .handleAction(fetchPersonActivitiesCompletedByType.request, (state) => ({
    ...state,
    activitiesCompletedByTypeLoading: true,
  }))
  .handleAction(fetchPersonActivitiesCompletedByType.success, (state, action) => ({
    ...state,
    activitiesCompletedByTypeLoading: false,
    recordData: {
      ...state.recordData,
      activitiesCompletedByType: action.payload,
    },
  }))
  .handleAction(fetchPersonActivitiesCompletedByType.failure, (state) => ({
    ...state,
    activitiesCompletedByTypeLoading: false,
  }))
  .handleAction(fetchPersonActivitiesTotal.request, (state) => ({
    ...state,
    activitiesTotalLoading: true,
  }))
  .handleAction(fetchPersonActivitiesTotal.success, (state, action) => ({
    ...state,
    activitiesTotalLoading: false,
    recordData: {
      ...state.recordData,
      activitiesTotal: action.payload,
    },
  }))
  .handleAction(fetchPersonActivitiesTotal.failure, (state) => ({
    ...state,
    activitiesTotalLoading: false,
  }))
  .handleAction(fetchPersonRoutes.request, (state) => ({
    ...state,
    personRoutesLoading: true,
  }))
  .handleAction(fetchPersonRoutes.success, (state, action) => ({
    ...state,
    personRoutesLoading: false,
    recordData: {
      ...state.recordData,
      routes: action.payload,
    },
  }))
  .handleAction(fetchPersonRoutes.failure, (state) => ({
    ...state,
    personRoutesLoading: false,
  }))
  .handleAction(updatePerson.request, (state) => ({
    ...state,
    personUpdateLoading: true,
  }))
  .handleAction(updatePerson.success, (state, action) => ({
    ...state,
    personUpdateLoading: false,
    recordData: {
      ...state.recordData,
      person: { ...state.recordData.person, ...action.payload },
    },
  }))
  .handleAction(updatePerson.failure, (state) => ({
    ...state,
    personUpdateLoading: false,
  }))
  .handleAction(createPersonNote.request, (state) => ({
    ...state,
    personNoteCreateLoading: true,
  }))
  .handleAction(createPersonNote.success, (state, action) => ({
    ...state,
    personNoteCreateLoading: false,
    recordData: { ...state.recordData, notes: [action.payload, ...state.recordData.notes] },
  }))
  .handleAction(createPersonNote.failure, (state) => ({
    ...state,
    personNoteCreateLoading: false,
  }))
  .handleAction(updatePersonNote.request, (state, action) => ({
    ...state,
    personNoteUpdateLoadingIds: state.personNoteUpdateLoadingIds.concat(action.payload.id),
  }))
  .handleAction(updatePersonNote.success, (state, action) => ({
    ...state,
    personNoteUpdateLoadingIds: state.personNoteUpdateLoadingIds.filter(
      (id) => id !== action.payload.id
    ),
    recordData: {
      ...state.recordData,
      notes: [
        action.payload,
        ...state.recordData.notes.filter((note) => note.id !== action.payload.id),
      ],
    },
  }))
  .handleAction(updatePersonNote.failure, (state, action) => ({
    ...state,
    personNoteUpdateLoadingIds: state.personNoteUpdateLoadingIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(deletePersonNote.request, (state, action) => ({
    ...state,
    personNoteDeleteLoadingIds: state.personNoteDeleteLoadingIds.concat(action.payload.id),
  }))
  .handleAction(deletePersonNote.success, (state, action) => ({
    ...state,
    personNoteDeleteLoadingIds: state.personNoteDeleteLoadingIds.filter(
      (id) => id !== action.payload
    ),
    recordData: {
      ...state.recordData,
      notes: state.recordData.notes.filter((note) => note.id !== action.payload),
    },
  }))
  .handleAction(deletePersonNote.failure, (state, action) => ({
    ...state,
    personNoteDeleteLoadingIds: state.personNoteDeleteLoadingIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(uploadPersonFiles.request, (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadPersonFiles.success, (state, action) => {
    const filesUploaded = compact(action.payload.map(({ uploadedFile }) => uploadedFile));
    return {
      ...state,
      filesAdded: [...state.filesAdded, ...action.payload],
      filesUploading: false,
      recordData: {
        ...state.recordData,
        files: [...state.recordData.files, ...filesUploaded].sort(fileCreatedAtDescComparator),
      },
    };
  })
  .handleAction(uploadPersonFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(deletePersonFile.request, (state, { payload }) => ({
    ...state,
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== payload.id),
    filesToDeletedIds: state.filesToDeletedIds.concat(payload.id),
    recordData: {
      ...state.recordData,
      files: state.recordData.files.filter((file) => file.id !== payload.id),
    },
  }))
  .handleAction(deletePersonFile.success, (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.file.id),
    recordData: {
      ...state.recordData,
      files: [...state.recordData.files, payload.file].sort(fileCreatedAtDescComparator),
    },
  }))
  .handleAction(deletePersonFile.failure, (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.id),
    recordData: {
      ...state.recordData,
      files: [...state.recordData.files, payload].sort(fileCreatedAtDescComparator),
    },
  }))
  .handleAction(clearAllUploadedPersonFiles, (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }))
  .handleAction(addPersonCompanies.request, (state) => ({
    ...state,
    addPersonParentCompanyLoading: true,
  }))
  .handleAction(addPersonCompanies.success, (state, { payload }) => ({
    ...state,
    addPersonParentCompanyLoading: false,
    recordData: {
      ...state.recordData,
      companies: payload.companies,
      totalCompanies: payload.total,
    },
  }))
  .handleAction(addPersonCompanies.failure, (state) => ({
    ...state,
    addPersonParentCompanyLoading: false,
  }))
  .handleAction(removePersonCompany.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      companies: payload,
      totalCompanies: state.recordData.totalCompanies > 0 ? state.recordData.totalCompanies - 1 : 0,
    },
  }))
  .handleAction(addPersonDeals.request, (state) => ({
    ...state,
    addPersonDealsLoading: true,
  }))
  .handleAction(addPersonDeals.success, (state, { payload }) => ({
    ...state,
    addPersonDealsLoading: false,
    recordData: {
      ...state.recordData,
      deals: payload.deals,
      totalDeals: payload.total,
    },
  }))
  .handleAction(addPersonDeals.failure, (state) => ({
    ...state,
    addPersonDealsLoading: false,
  }))
  .handleAction(removePersonDeal.request, (state, action) => ({
    ...state,
    removePersonDealLoadingIds: state.removePersonDealLoadingIds.concat(action.payload),
  }))
  .handleAction(removePersonDeal.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      deals: state.recordData.deals?.filter((c) => c.id !== action.payload),
      totalDeals: state.recordData.totalDeals > 0 ? state.recordData.totalDeals - 1 : 0,
    },
    removePersonDealLoadingIds: state.removePersonDealLoadingIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(removePersonDeal.failure, (state, action) => ({
    ...state,
    removePersonDealLoadingIds: state.removePersonDealLoadingIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(removePersonFromRoute.request, (state, action) => ({
    ...state,
    removePersonRouteLoadingsIds: state.removePersonRouteLoadingsIds.concat(action.payload.routeId),
  }))
  .handleAction(removePersonFromRoute.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      routes: state.recordData.routes?.filter((c) => c.id !== action.payload),
    },
    removePersonRouteLoadingsIds: state.removePersonRouteLoadingsIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(removePersonFromRoute.failure, (state, action) => ({
    ...state,
    removePersonRouteLoadingsIds: state.removePersonRouteLoadingsIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(postponeActivity.request, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.concat(payload.id),
  }))
  .handleAction(postponeActivity.success, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
    recordData: updateRecordData(state, payload),
  }))
  .handleAction(postponeActivity.failure, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))
  .handleAction(toggleCompleteActivity.request, (state, { payload }) => ({
    ...state,
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.concat(payload.id),
  }))
  .handleAction(toggleCompleteActivity.success, (state, { payload }) => ({
    ...state,
    recordData: updateRecordData(state, payload),
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
  }))
  .handleAction(toggleCompleteActivity.failure, (state, { payload }) => ({
    ...state,
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))
  .handleAction(updateActivityNote.request, (state, { payload }) => ({
    ...state,
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.concat(payload.activity.id),
  }))
  .handleAction(updateActivityNote.success, (state, { payload }) => ({
    ...state,
    recordData: updateRecordData(state, payload),
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
  }))
  .handleAction(updateActivityNote.failure, (state, { payload }) => ({
    ...state,
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))
  .handleAction(changeActivitiesFilter, (state, action) => ({
    ...state,
    activitiesFilter: action.payload,
  }))
  .handleAction(changeActivitiesSearchQuery, (state, action) => ({
    ...state,
    activitiesSearchQuery: action.payload,
  }))
  .handleAction(changeActivitiesRecapRange, (state, action) => ({
    ...state,
    activitiesRecapRange: action.payload,
  }))
  .handleAction(changeActivitiesSelectedActivityTypes, (state, { payload }) => ({
    ...state,
    activitiesSelectedActivityTypes: payload,
  }))
  .handleAction(deletePerson.request, (state) => ({
    ...state,
    deleteLoading: true,
    hasChanges: false,
  }))
  .handleAction([deletePerson.success, deletePerson.failure], (state) => ({
    ...state,
    deleteLoading: false,
  }))
  .handleAction(setActiveTab, (state, { payload }) => ({
    ...state,
    activeTab: payload,
  }))
  .handleAction(setActivityAutoScrolling, (state, { payload }) => ({
    ...state,
    activityAutoScrolling: payload,
  }))
  .handleAction(setHasChangesFlag, (state, { payload }) => ({
    ...state,
    hasChanges: payload,
  }))
  .handleAction(setPersonColor, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      person: state.recordData.person ? { ...state.recordData.person, color: payload } : undefined,
    },
  }))
  .handleAction(setPersonShape, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      person: state.recordData.person ? { ...state.recordData.person, shape: payload } : undefined,
    },
  }))
  .handleAction(changeActivitiesOrder, (state, action) => ({
    ...state,
    activitiesOrder: action.payload,
  }))
  .handleAction(changeActivitiesAssignees, (state, { payload }) => ({
    ...state,
    activitiesSelectedAssignees: payload,
  }))
  .handleAction(updatePersonFrequency.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      person: { ...state.recordData.person, ...payload },
    },
  }))
  .handleAction(fetchMoreDeals.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      deals: [...state.recordData.deals, ...payload],
    },
  }))
  .handleAction(fetchMoreCompanies.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      companies: [...state.recordData.companies, ...payload],
    },
  }));

export type PersonRecordActions = Actions;
export default personRecord;
