import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { RenderElementProps } from "slate-react/dist/components/editable";

import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";

import ButtonLink from "../../../ButtonLink";
import { useConfigProvider } from "../../../ConfigProvider";
import TextField from "../../../input/TextField";
import Modal from "../../../Modal";
import { ImageElement } from "../../../type/slate-types";

import styles from "./Image.module.scss";

type ResizeMode = "bottomLeft" | "bottomRight" | "topLeft" | "topRight";
type ResizeParams = [number, number, number, number];

const RESIZER_OFFSET = 3;
const resizeModes: ResizeMode[] = ["bottomLeft", "bottomRight", "topLeft", "topRight"];

const getResizerStyle = (
  mode: ResizeMode,
  [resizerTop, resizerLeft, resizerRight, resizerBottom]: ResizeParams
): CSSProperties => {
  let result: CSSProperties;
  switch (mode) {
    case "bottomLeft":
      result = {
        bottom: resizerBottom - RESIZER_OFFSET,
        cursor: "nesw-resize",
        left: resizerLeft - RESIZER_OFFSET,
      };
      break;
    case "bottomRight":
      result = {
        bottom: resizerBottom - RESIZER_OFFSET,
        cursor: "nwse-resize",
        right: resizerRight - RESIZER_OFFSET,
      };
      break;
    case "topLeft":
      result = {
        cursor: "nwse-resize",
        left: resizerLeft - RESIZER_OFFSET,
        top: resizerTop - RESIZER_OFFSET,
      };
      break;
    case "topRight":
      result = {
        cursor: "nesw-resize",
        right: resizerRight - RESIZER_OFFSET,
        top: resizerTop - RESIZER_OFFSET,
      };
      break;
  }
  return result;
};

const Image: React.FC<RenderElementProps> = ({ attributes, children, element }) => {
  const configProvider = useConfigProvider();

  const editor = useSlate();
  const { selection } = editor;

  const path = ReactEditor.findPath(editor, element);

  const isFocused = useMemo(
    () => path.every((value, index) => selection?.focus.path[index] === value),
    [path, selection]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [[resizerTop, resizerLeft, resizerRight, resizerBottom], setResizerParams] =
    useState<ResizeParams>([0, 0, 0, 0]);

  const [[originalWidth, originalHeight], setOriginalSize] = useState<[number, number]>([0, 0]);
  const [[width, height], setSize] = useState<[number, number]>([0, 0]);
  const [ratio, setRatio] = useState<number>(1);
  const [altText, setAltText] = useState<string>((element as ImageElement).alt ?? "");
  const [altEditText, setAltEditText] = useState<string>((element as ImageElement).alt ?? "");
  const [altModalVisible, showAltModal, hideAltModal] = useBoolean();
  const [resizeMode, setResizeMode] = useState<
    "bottomLeft" | "bottomRight" | "topLeft" | "topRight" | undefined
  >();

  const handleApplyNewSize = useCallback(
    (size: [number, number]) => {
      setSize(size);
      Transforms.setNodes(editor, { width: size[0] }, { at: path });
    },
    [editor, path]
  );

  const [bestFitWidth, bestFitHeight] = useMemo(() => {
    let width = originalWidth;
    let height = originalHeight;
    if (height >= 350) {
      height = 350;
      width = Math.round(height * ratio);
    }
    if (width >= 600) {
      width = 600;
      height = Math.round(width / ratio);
    }
    return [width, height];
  }, [originalWidth, originalHeight, ratio]);

  const [smallWidth, smallHeight] = useMemo(() => {
    return [Math.round(bestFitWidth / 2), Math.round(bestFitHeight / 2)];
  }, [bestFitWidth, bestFitHeight]);

  const isBestFitSizeApplied = height === bestFitHeight && width === bestFitWidth;
  const isSmallSizeApplied = height === smallHeight && width === smallWidth;
  const isOriginalSizeApplied = height === originalHeight && width === originalWidth;

  const applyOriginalSize = useCallback(
    () => handleApplyNewSize([originalWidth, originalHeight]),
    [handleApplyNewSize, originalWidth, originalHeight]
  );

  const applyBestFitSize = useCallback(
    () => handleApplyNewSize([bestFitWidth, bestFitHeight]),
    [handleApplyNewSize, bestFitWidth, bestFitHeight]
  );

  const applySmallSize = useCallback(
    () => handleApplyNewSize([smallWidth, smallHeight]),
    [handleApplyNewSize, smallWidth, smallHeight]
  );

  const handleAltConfirm = useCallback(() => {
    setAltText(altEditText);
    Transforms.setNodes(editor, { alt: altEditText }, { at: path });
    hideAltModal();
  }, [altEditText, editor, hideAltModal, path, setAltText]);

  const handleChangeAlt = useCallback(() => {
    setAltEditText(altText);
    showAltModal();
  }, [altText, setAltEditText, showAltModal]);

  const handleLoad = useCallback(
    ({ target: { naturalHeight, naturalWidth } }) => {
      const ratio = naturalWidth / naturalHeight;
      const width = (element as ImageElement).width ?? naturalWidth;
      const height = Math.round(width / ratio);
      setSize([width, height]);
      setOriginalSize([naturalWidth, naturalHeight]);
      setRatio(ratio);
    },
    [element, setRatio, setSize, setOriginalSize]
  );

  const handleMouseMove = useDynamicCallback(({ x, y }: MouseEvent) => {
    if (containerRef.current && resizeMode && height !== 0) {
      const rect = containerRef.current.getBoundingClientRect();
      let newWidth = ["bottomRight", "topRight"].includes(resizeMode)
        ? x - rect.left
        : rect.left + width - x;
      let newHeight = ["bottomLeft", "bottomRight"].includes(resizeMode)
        ? y - rect.top
        : rect.top + height - y;

      if (newWidth / newHeight > ratio) {
        newWidth = newHeight * ratio;
      } else {
        newHeight = newWidth / ratio;
      }
      const widthDiff = width - newWidth;
      const heightDiff = height - newHeight;
      const top = ["topLeft", "topRight"].includes(resizeMode) ? heightDiff : 0;
      const bottom = ["bottomLeft", "bottomRight"].includes(resizeMode) ? heightDiff : 0;
      const left = ["bottomLeft", "topLeft"].includes(resizeMode) ? widthDiff : 0;
      const right = ["bottomRight", "topRight"].includes(resizeMode) ? widthDiff : 0;

      setResizerParams([top, left, right, bottom]);
    }
  });

  const handleMouseUp = useDynamicCallback(() => {
    if (resizeMode) {
      handleApplyNewSize([width - resizerLeft - resizerRight, height - resizerTop - resizerBottom]);
      setResizerParams([0, 0, 0, 0]);
      setResizeMode(undefined);
    }
  });

  const handleKeyDown = useDynamicCallback((event: KeyboardEvent) => {
    if (event.key === "Delete") {
      handleDelete();
    }
  });

  const handleDelete = useDynamicCallback(() => {
    Transforms.removeNodes(editor, { at: path });
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mouseup", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div contentEditable={false} {...attributes}>
      {children}
      <div className={styles.container} contentEditable={false} ref={containerRef}>
        <img
          alt={(element as ImageElement).alt}
          className={styles.image}
          onLoad={handleLoad}
          src={(element as ImageElement).url}
          style={{ height, width }}
        />
        {isFocused && (
          <React.Fragment>
            <div
              className={styles.resizer}
              style={{
                bottom: resizerBottom,
                left: resizerLeft,
                right: resizerRight,
                top: resizerTop,
              }}
            />
            {resizeModes.map((mode) => (
              <div
                className={styles.resizeControl}
                key={mode}
                onMouseDown={() => setResizeMode(mode)}
                style={getResizerStyle(mode, [
                  resizerTop,
                  resizerLeft,
                  resizerRight,
                  resizerBottom,
                ])}
              />
            ))}
          </React.Fragment>
        )}
      </div>
      {isFocused && (
        <div className={styles.toolbar}>
          <ButtonLink
            className={styles.control}
            disabled={isSmallSizeApplied}
            onClick={applySmallSize}
          >
            {configProvider.formatMessage("ui.emailBody.imageModal.size.small")}
          </ButtonLink>
          <div className={styles.divider} />
          <ButtonLink
            className={styles.control}
            disabled={isBestFitSizeApplied}
            onClick={applyBestFitSize}
          >
            {configProvider.formatMessage("ui.emailBody.imageModal.size.bestFit")}
          </ButtonLink>
          <div className={styles.divider} />
          <ButtonLink
            className={styles.control}
            disabled={isOriginalSizeApplied}
            onClick={applyOriginalSize}
          >
            {configProvider.formatMessage("ui.emailBody.imageModal.size.original")}
          </ButtonLink>
          <div className={styles.divider} />
          <ButtonLink className={styles.control} onClick={handleChangeAlt}>
            {configProvider.formatMessage("ui.emailBody.imageModal.editAlt")}
          </ButtonLink>
          <div className={styles.divider} />
          <ButtonLink className={styles.removeControl} onClick={handleDelete}>
            {configProvider.formatMessage("ui.emailBody.imageModal.remove")}
          </ButtonLink>
        </div>
      )}
      <Modal
        cancelButtonProps={{ hidden: true }}
        okText={configProvider.formatMessage("ui.emailBody.imageModal.ok")}
        onCancel={hideAltModal}
        onOk={handleAltConfirm}
        open={altModalVisible}
        title={configProvider.formatMessage("ui.emailBody.imageModal.title")}
      >
        <div className={styles.altModalContent}>
          <p className={styles.altModalCaption}>
            {configProvider.formatMessage("ui.emailBody.imageModal.description")}
          </p>
          <TextField
            label={configProvider.formatMessage("ui.emailBody.imageModal.input")}
            onChange={setAltEditText}
            value={altEditText}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Image;
