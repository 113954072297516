const countryCode = "(?<countryCode>(?:\\+\\d+[\\s.-]*)|(?:\\d+[.-]+))"; // optional "+" symbol and country code
const digitWithMaybeSeparator = "\\d[\\s.)-]*"; // a digit with space/dot/dash separators or a closing parenthesis (closing city code because we don't know how long it will be)
const phone = `(?<phone>${countryCode}?[(]?(?:${digitWithMaybeSeparator}){9}\\d+)`; // phone regexp - optional country code + at least 10 digits

// This regex is NOT about strict phone number verification, it's more for
// phone number extraction from a string.
// Also captures line start and end (text before and after the phone number).
const beforePhone = "(?<start>^[^\\d+(]*)"; // everything before phone number - any non-numeric garbage
const afterPhone = "(?<end>.*$)"; // everything after the phone number
const parsePhoneFieldRegex = new RegExp(`${beforePhone}${phone}${afterPhone}`);

// Should correctly parse all these strings:
//   "5558263400 call #123" -> {"label":"5558263400","link":"tel:5558263400","lineStart":"","lineEnd":" call #123"}
//   "(555) 826-3400 call #123" -> {"label":"(555) 826-3400","link":"tel:5558263400","lineStart":"","lineEnd":" call #123"}
//   "1-555-826-3400 call #123" -> {"label":"1-555-826-3400","link":"tel:+15558263400","lineStart":"","lineEnd":" call #123"}
//   "+1 (555) 826.3400 call #123" -> {"label":"+1 (555) 826.3400","link":"tel:+15558263400","lineStart":"","lineEnd":" call #123"}
//   "dial 5558263400" -> {"label":"5558263400","link":"tel:5558263400","lineStart":"dial ","lineEnd":""}
const parsePhoneNumber = (
  phoneNumberString?: string
): { label: string; lineEnd: string; lineStart: string; link: string } | undefined => {
  if (!phoneNumberString) {
    return undefined;
  }
  const match = parsePhoneFieldRegex.exec(phoneNumberString);
  if (!match || !match.groups) {
    return undefined;
  }

  const phone = match.groups.phone;

  return {
    label: match.groups.phone,
    lineEnd: match.groups.end ?? "",
    lineStart: match.groups.start ?? "",
    link: `tel:${phone.startsWith("+") ? "+" : ""}${phone.replace(/\D/g, "")}`,
  };
};

export default parsePhoneNumber;
