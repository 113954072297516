import { defineMessages, IntlShape } from "react-intl";

enum FrequencyStatusType {
  UNKNOWN = "Unknown",
  KNOWN = "Known",
}

const messages = defineMessages<FrequencyStatusType>({
  [FrequencyStatusType.KNOWN]: {
    id: "dashboard.editModal.criteria.frequencyStatusType.known",
    defaultMessage: "Known",
    description: "No contact in card frequency known filter",
  },
  [FrequencyStatusType.UNKNOWN]: {
    id: "dashboard.editModal.criteria.frequencyStatusType.unknown",
    defaultMessage: "Unknown",
    description: "No contact in card frequency unknown filter",
  },
});

export const getFrequencyStatusTypeDisplayName = (
  intl: IntlShape,
  frequencyStatusType: FrequencyStatusType
) => intl.formatMessage(messages[frequencyStatusType]);

export default FrequencyStatusType;
