type Projection = google.maps.Projection;
type LatLngBounds = google.maps.LatLngBounds;
type LatLngLiteral = google.maps.LatLngLiteral;
type LatLng = google.maps.LatLng;
type Point = google.maps.Point;

export const fromLatLngToPixel = (
  zoom: number,
  projection: Projection,
  bounds: LatLngBounds,
  latLng: LatLng | LatLngLiteral
): Point | undefined => {
  const scale = Math.pow(2, zoom);

  const nwPoint = projection.fromLatLngToPoint({
    lat: bounds.getNorthEast().lat(),
    lng: bounds.getSouthWest().lng(),
  });
  const latLngPoint = projection.fromLatLngToPoint(latLng);

  if (!nwPoint || !latLngPoint) {
    return undefined;
  }

  return new google.maps.Point(
    Math.floor((latLngPoint.x - nwPoint.x) * scale),
    Math.floor((latLngPoint.y - nwPoint.y) * scale)
  );
};

export const fromPixelToLatLng = function (
  zoom: number,
  projection: Projection,
  bounds: LatLngBounds,
  pixel: Point
): LatLng | undefined {
  const scale = Math.pow(2, zoom);
  const nwPoint = projection.fromLatLngToPoint({
    lat: bounds.getNorthEast().lat(),
    lng: bounds.getSouthWest().lng(),
  });
  return nwPoint
    ? projection.fromPointToLatLng(
        new google.maps.Point(pixel.x / scale + nwPoint.x, pixel.y / scale + nwPoint.y)
      ) || undefined
    : undefined;
};
