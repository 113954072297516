import isFunction from "lodash-es/isFunction";

import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import { EntityPin } from "@mapmycustomers/shared/types/map";

import AlertBadgesType from "@app/enum/map/AlertBadgesType";
import MarkerEffect from "@app/enum/MarkerEffect";
import Size from "@app/types/map/Size";
import getSvgForBadge from "@app/util/badge/getSvgForBadge";
import { PIN_ZINDEX } from "@app/util/map/consts";
import svgToDataUrl from "@app/util/map/svgToDataUrl";

import defaultColorGetter from "./defaultColorGetter";
import getResultingSvg from "./getResultingSvg";
import { getScaledSizeByMarker } from "./getScaledSize";
import getSvgEffect from "./getSvgEffect";
import { AdditionalMarkerIcons, default as getSvgForIcon } from "./getSvgForIcon";
import getSvgForShape from "./getSvgForShape";
import ColorDefinition from "./types/ColorDefinition";

interface StyleArguments {
  areBadgesVisible?: boolean;
  colorGetter?: (entityPin: EntityPin) => ColorDefinition;
  draggable?: ((entityPin: EntityPin) => boolean) | boolean;
  effect?: ((entityPin: EntityPin) => MarkerEffect) | MarkerEffect;
  icon?: (
    entityPin: EntityPin
  ) => AdditionalMarkerIcons | EntityTypesSupportedByMapsPage | undefined;
  markerSize: MarkerSize;
  shape: ((entityPin: EntityPin) => MarkerShape) | MarkerShape;
  showEntityIcon?: boolean;
  showMultiPinIcon?: boolean;
  strokeColorGetter?: (entityPin: EntityPin) => string | undefined;
  visibleBadges?: AlertBadgesType[];
  zIndex?: number;
}

const defaultEntityStyleGetter = ({
  areBadgesVisible,
  colorGetter = defaultColorGetter,
  draggable,
  effect = MarkerEffect.SHADOW,
  icon,
  markerSize,
  shape,
  showEntityIcon,
  showMultiPinIcon,
  strokeColorGetter,
  visibleBadges,
  zIndex = PIN_ZINDEX,
}: StyleArguments): ((item: EntityPin) => google.maps.Data.StyleOptions) => {
  return (entityPin: EntityPin) => {
    const colorDef = colorGetter(entityPin);

    const isDraggabble = isFunction(draggable) ? draggable(entityPin) : draggable;
    const markerEffect = isFunction(effect) ? effect(entityPin) : effect;
    const markerShape = isFunction(shape) ? shape(entityPin) : shape;
    const shapeStrokeColor = strokeColorGetter
      ? strokeColorGetter(entityPin) ?? colorDef.color
      : colorDef.color;

    const defaultIcon = showMultiPinIcon
      ? "multipin"
      : showEntityIcon
      ? entityPin.data.entity
      : undefined;
    const iconSvg = getSvgForIcon(
      markerShape,
      isFunction(icon) ? icon(entityPin) : defaultIcon,
      colorDef.color
    );
    const [badgeSvg] = areBadgesVisible
      ? getSvgForBadge(entityPin, visibleBadges)
      : ["", [0, 0] as Size];

    const { badgeAnchor, badgeShapeIncreaser, shapeAnchor, shapeSize, shapeSvg } = getSvgForShape(
      markerShape,
      colorDef.fillColor,
      shapeStrokeColor
    );
    const shapeEffect = getSvgEffect(markerEffect, shapeSize);

    const { anchor, size, svg } = getResultingSvg(
      shapeSvg,
      shapeSize,
      shapeAnchor,
      shapeEffect,
      iconSvg,
      badgeSvg,
      badgeAnchor,
      badgeShapeIncreaser
    );

    return {
      draggable: isDraggabble,
      icon: {
        anchor: new google.maps.Point(...getScaledSizeByMarker(anchor, markerSize)),
        scaledSize: new google.maps.Size(...getScaledSizeByMarker(size, markerSize)),
        url: svgToDataUrl(svg),
      },
      zIndex,
    };
  };
};

export default defaultEntityStyleGetter;
