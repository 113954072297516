import { RefObject, useEffect, useState } from "react";

type MapOptions = google.maps.MapOptions;

const useMapRef = (opts?: MapOptions, element?: RefObject<HTMLElement>) => {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  useEffect(
    () => {
      if (element?.current) {
        // only create new map if not created already
        setMap((oldMap) => oldMap || new google.maps.Map(element?.current!, opts));
      }
      return () => {
        setMap(undefined);
      };
    },
    // disable deps check, we don't want to rebuild the map when the `opts` arg change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element?.current]
  );
  return { map };
};

export default useMapRef;
