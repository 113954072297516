import { useCallback } from "react";

import { FormInstance, FormProps } from "antd/es/form";

import RecurringIntervalType from "../enum/RecurringIntervalType";
import FormValues from "../types/FormValues";

import { parseActivityInterval } from "./activityInterval";

export const useIsRecurrentValueChangeHandler = (form: FormInstance<FormValues>) =>
  useCallback<NonNullable<FormProps<FormValues>["onValuesChange"]>>(
    (field, allFields) => {
      if ("isRecurrent" in field) {
        const recurInterval = field.isRecurrent
          ? parseActivityInterval(RecurringIntervalType.MONTHLY)
          : undefined;
        const recurIntervalType = field.isRecurrent
          ? RecurringIntervalType.MONTHLY
          : RecurringIntervalType.NEVER;
        form.setFieldsValue({ recurInterval, recurIntervalType });
        allFields.recurInterval = recurInterval;
        allFields.recurIntervalType = recurIntervalType;
      }
    },
    [form]
  );
