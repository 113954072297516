import React, { MouseEvent, useCallback } from "react";

import Button, { ButtonProps } from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import styles from "./AddOrCreateButton.module.scss";

interface Props extends Omit<ButtonProps, "onClick" | "type"> {
  label: string;
  onClick?: () => void;
  tooltip?: string;
}

const AddOrCreateButton: React.FC<Props> = ({
  className,
  disabled,
  label,
  onClick,
  tooltip,
  ...buttonProps
}) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation(); // needed to prevent panel collapse
      onClick?.();
    },
    [onClick]
  );

  return (
    <Tooltip arrowPointAtCenter placement={disabled ? "topRight" : "top"} title={tooltip}>
      <Button
        {...buttonProps}
        className={cn(styles.btn, className)}
        disabled={disabled}
        onClick={handleClick}
        type="link"
      >
        {"+ "}
        {label}
      </Button>
    </Tooltip>
  );
};

export default AddOrCreateButton;
