import { CSSProperties } from "react";

import ElementAlignment from "@mapmycustomers/shared/enum/textEditor/ElementAlignment";

type AlignElementType =
  | ElementAlignment.ALIGN_CENTER
  | ElementAlignment.ALIGN_JUSTIFY
  | ElementAlignment.ALIGN_LEFT
  | ElementAlignment.ALIGN_RIGHT;

export const cssAlignMap: Record<AlignElementType, CSSProperties["textAlign"]> = {
  [ElementAlignment.ALIGN_CENTER]: "center",
  [ElementAlignment.ALIGN_JUSTIFY]: "justify",
  [ElementAlignment.ALIGN_LEFT]: "left",
  [ElementAlignment.ALIGN_RIGHT]: "right",
};

export default AlignElementType;
