import invariant from "tiny-invariant";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import PlatformSortModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformSortModel";

import AgGridSortModel from "../../types/viewModel/agGridModel/AgGridSortModel";

const agGridSortToSortOrder: { [key in "asc" | "desc"]: SortOrder } = {
  asc: SortOrder.ASC,
  desc: SortOrder.DESC,
} as const;

const sortOrderToAgGridSort: { [key in SortOrder]: "asc" | "desc" } = {
  [SortOrder.ASC]: "asc",
  [SortOrder.DESC]: "desc",
} as const;

export const convertFromAgGridSortModel = (
  sortModel: AgGridSortModel,
  fieldModel: IFieldModel
): SortModel => {
  return sortModel.map((model) => {
    const field = fieldModel.getByName(model.colId);
    invariant(field, `Unknown colId used in sorting: "${model.colId}"`);
    const order = agGridSortToSortOrder[model.sort];
    invariant(order, `Unknown AgGrid sort order: "${model.sort}"`);
    return { field, order };
  });
};

export const convertToAgGridSortModel = (sortModel: SortModel): AgGridSortModel => {
  return sortModel.map((model) => {
    const sort = sortOrderToAgGridSort[model.order];
    invariant(sort, `Unknown sort order: "${model.order}"`);
    return { colId: model.field.filterName, sort };
  });
};

export const convertToPlatformSortModel = (sortModel: SortModel): PlatformSortModel => {
  // FIXME: replace invariant with some "warn" function
  // invariant(sortModel.length <= 1, `Platform doesn't support multi-column sorting.`);
  if (sortModel.length < 1) {
    return undefined;
  }
  return (sortModel[0].order === SortOrder.DESC ? "-" : "") + sortModel[0].field.sortName;
};
