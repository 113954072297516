import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

const parseEmptyCondition = (platformCondition: Condition) => ({
  operator:
    Object.keys(platformCondition)[0] === PlatformFilterOperator.EQUALS
      ? FilterOperator.EMPTY
      : FilterOperator.NOT_EMPTY,
  value: undefined,
});

export default parseEmptyCondition;
