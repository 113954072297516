import React from "react";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import TerritoriesSelectComponent from "@app/util/filters/Territories/TerritoriesFilter";

export const TERRITORIES_FILTER_OPERATORS = [
  FilterOperator.IN_ANY,
  FilterOperator.IN_ALL,
  FilterOperator.NOT_IN,
];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  TERRITORIES_FILTER_OPERATORS.includes(operator);

interface TerritoryFilterProps extends IFilterComponentProps {}

const TerritoriesFilter: IFilterInstance = {
  doesSupportValue,
  getComponent:
    (): React.FC<TerritoryFilterProps> =>
    ({ className, focus, onChange, value }) => {
      return (
        <TerritoriesSelectComponent
          className={className}
          focus={focus}
          onChange={onChange}
          value={value}
        />
      );
    },
};

export default TerritoriesFilter;
