import { defineMessages, IntlShape } from "react-intl";

import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";

const messages = defineMessages({
  [ActivityStatus.COMPLETED]: {
    id: "activityStatus.completed",
    defaultMessage: "Done",
    description: "Done status of the activity",
  },
  [ActivityStatus.OVERDUE]: {
    id: "activityStatus.overdue",
    defaultMessage: "Overdue",
    description: "Overdue status of the activity",
  },
  [ActivityStatus.UNKNOWN]: {
    id: "activityStatus.unknown",
    defaultMessage: "Unknown",
    description: "Unknown status of the activity",
  },
  [ActivityStatus.UPCOMING]: {
    id: "activityStatus.upcoming",
    defaultMessage: "Upcoming",
    description: "Upcoming status of the activity",
  },
});

const getActivityStatusDisplayName = (intl: IntlShape, activityStatus: ActivityStatus) =>
  intl.formatMessage(messages[activityStatus]);

export default getActivityStatusDisplayName;
