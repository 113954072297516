import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const componentState = (state: RootState) => state.component.filePreview;

export const isFilePreviewLoading = createSelector(
  componentState,
  ({ filePreviewLoading }) => filePreviewLoading
);
export const getFilePreview = createSelector(componentState, ({ filePreview }) => filePreview);
