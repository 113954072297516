import { defineMessages } from "react-intl";
import { IntlShape } from "react-intl/src/types";

import { ClientType } from "@mapmycustomers/shared/enum/RecordSourceType";

// order is make sense there, because it is using to display options in the same order
/* eslint-disable perfectionist/sort-objects */
const SourceType = {
  WEB: ClientType.WEB,
  MOBILE: ClientType.MOBILE,
  IMPORTS: ClientType.IMPORTS,
  INTEGRATIONS: ClientType.INTEGRATIONS,
  // should actually be this and not RecordSource.LEAD_GEN because this is how platform
  // returns this in aggregations by sourceCreated.clientType
  LEAD_GENERATION: "Lead Gen",
  PUBLIC_API: ClientType.PUBLIC_API,
};
/* eslint-enable perfectionist/sort-objects */

export type SupportedSources = (typeof SourceType)[keyof typeof SourceType];

const recordTypeMessages = defineMessages({
  [SourceType.IMPORTS]: {
    id: "home.cards.recordsBySource.menu.sourceType.imports",
    defaultMessage: "Imports",
    description: "Source Card select popup imports option",
  },
  [SourceType.INTEGRATIONS]: {
    id: "home.cards.recordsBySource.menu.sourceType.integrations",
    defaultMessage: "Integrations",
    description: "Source Card select popup integrations option",
  },
  [SourceType.LEAD_GENERATION]: {
    id: "home.cards.recordsBySource.menu.sourceType.leadGeneration",
    defaultMessage: "Lead Finder",
    description: "Source Card select popup lead generation option",
  },
  [SourceType.MOBILE]: {
    id: "home.cards.recordsBySource.menu.sourceType.mobile",
    defaultMessage: "Mobile",
    description: "Source Card select popup mobile option",
  },
  [SourceType.PUBLIC_API]: {
    id: "home.cards.recordsBySource.menu.sourceType.publicApi",
    defaultMessage: "Public API",
    description: "Source Card select popup public api option",
  },
  [SourceType.WEB]: {
    id: "home.cards.recordsBySource.menu.sourceType.web",
    defaultMessage: "Web",
    description: "Source Card select popup Web option",
  },
});

export const getSourceTypeDisplayName = (intl: IntlShape, sourceType: SupportedSources) =>
  intl.formatMessage(recordTypeMessages[sourceType]);

export default SourceType;
