import { IntegrationSyncMetadata } from "@app/types/integrations/IntegrationSync";

const getIntegrationActionValue = (record: IntegrationSyncMetadata, action: string) => {
  const value = record
    ? Object.values(record).reduce((count, entityRow) => count + entityRow[action], 0)
    : 0;
  return value;
};

export default getIntegrationActionValue;
