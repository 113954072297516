import React from "react";

import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";

import ChildCompanies from "./components/ChildCompanies";
import Deals from "./components/Deals";
import Groups from "./components/Groups";
import ParentCompany from "./components/ParentCompany";
import People from "./components/People";
import Routes from "./components/Routes";
import PostCreationAssociationTypes from "./util/PostCreationAssociationTypes";

interface Props {
  createdRecord: Company | Deal | Person;
  entityType: EntityTypeSupportingPostCreationModal;
  handleHideModal: (updated?: boolean) => void;
  selectedModal?: PostCreationAssociationTypes;
}

const PostCreationModalSelection: React.FC<Props> = ({
  createdRecord,
  entityType,
  handleHideModal,
  selectedModal,
}) => {
  return (
    <>
      {(selectedModal === PostCreationAssociationTypes.ADD_PARENT_COMPANY ||
        selectedModal === PostCreationAssociationTypes.ADD_COMPANY ||
        selectedModal === PostCreationAssociationTypes.ADD_COMPANIES ||
        selectedModal === PostCreationAssociationTypes.CHANGE_PARENT_COMPANY ||
        selectedModal === PostCreationAssociationTypes.CHANGE_COMPANY ||
        selectedModal === PostCreationAssociationTypes.CHANGE_COMPANIES) && (
        <ParentCompany
          createdRecord={createdRecord}
          entityType={entityType}
          onHide={handleHideModal}
        />
      )}
      {selectedModal === PostCreationAssociationTypes.ADD_CHILD_COMPANY && (
        <ChildCompanies createdRecord={createdRecord} onHide={handleHideModal} />
      )}
      {(selectedModal === PostCreationAssociationTypes.ADD_PERSON ||
        selectedModal === PostCreationAssociationTypes.CHANGE_PERSON) && (
        <People createdRecord={createdRecord} entityType={entityType} onHide={handleHideModal} />
      )}
      {selectedModal === PostCreationAssociationTypes.ADD_DEAL && (
        <Deals createdRecord={createdRecord} entityType={entityType} onHide={handleHideModal} />
      )}
      {selectedModal === PostCreationAssociationTypes.ADD_ROUTE && (
        <Routes
          createdRecord={createdRecord as Company | Person}
          entityType={entityType as EntityType.COMPANY | EntityType.PERSON}
          onHide={handleHideModal}
        />
      )}
      {selectedModal === PostCreationAssociationTypes.ADD_GROUP && (
        <Groups entity={createdRecord} entityType={entityType} onHide={handleHideModal} />
      )}
    </>
  );
};

export default PostCreationModalSelection;
