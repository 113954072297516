import React, { ReactNode } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Section.module.scss";
import SectionLayout from "./SectionLayout";

interface Props {
  children: ReactNode;
  className?: string;
  icon: IconProp;
  title: ReactNode;
}

const Section: React.FC<Props> = ({ children, className, icon, title }) => {
  return (
    <SectionLayout
      className={className}
      left={<FontAwesomeIcon className={styles.icon} icon={icon} />}
    >
      <span className={styles.title}>{title}</span>
      {children}
    </SectionLayout>
  );
};

export default Section;
