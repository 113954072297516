import type Calendar from "nylas/lib/models/calendar";
import { createReducer } from "typesafe-actions";

import NylasType from "../../enum/NylasType";
import NylasInfo from "../../types/nylas/NylasInfo";

import {
  Actions,
  createCalendarEventForDealClosingDate,
  createNylas,
  deleteNylas,
  fetchCalendars,
  initializeNylas,
  updateNylas,
} from "./actions";

export type NylasState = {
  calendars: Calendar[];
  calendarsLoading: boolean;
  createCalendarEventForDealClosingDateLoading: boolean;
  creating: boolean;
  deleting: boolean;
  initializing: boolean;
  nylasCalendarInfo: NylasInfo | undefined;
  nylasEmailInfo: NylasInfo | undefined;
  updating: boolean;
};

const initialState: NylasState = {
  calendars: [],
  calendarsLoading: false,
  createCalendarEventForDealClosingDateLoading: false,
  creating: false,
  deleting: false,
  initializing: false,
  nylasCalendarInfo: undefined,
  nylasEmailInfo: undefined,
  updating: false,
};

const nylas = createReducer<NylasState, Actions>(initialState)
  .handleAction(initializeNylas.request, (state) => ({
    ...state,
    initializing: true,
  }))
  .handleAction(
    initializeNylas.success,
    (state, { payload: { nylasCalendarInfo, nylasEmailInfo } }) => ({
      ...state,
      initializing: false,
      nylasCalendarInfo,
      nylasEmailInfo,
    })
  )
  .handleAction(initializeNylas.failure, (state) => ({
    ...state,
    initializing: false,
  }))
  .handleAction(fetchCalendars.request, (state) => ({
    ...state,
    calendarsLoading: true,
  }))
  .handleAction(fetchCalendars.success, (state, action) => ({
    ...state,
    calendars: action.payload,
    calendarsLoading: false,
  }))
  .handleAction(fetchCalendars.failure, (state) => ({
    ...state,
    calendarsLoading: false,
  }))
  .handleAction(createCalendarEventForDealClosingDate.request, (state) => ({
    ...state,
    createCalendarEventForDealClosingDateLoading: true,
  }))
  .handleAction(createCalendarEventForDealClosingDate.success, (state) => ({
    ...state,
    createCalendarEventForDealClosingDateLoading: false,
  }))
  .handleAction(createCalendarEventForDealClosingDate.failure, (state) => ({
    ...state,
    createCalendarEventForDealClosingDateLoading: false,
  }))
  .handleAction(createNylas.request, (state) => ({
    ...state,
    creating: true,
  }))
  .handleAction(createNylas.success, (state) => ({
    ...state,
    creating: false,
  }))
  .handleAction(createNylas.failure, (state) => ({
    ...state,
    creating: false,
  }))
  .handleAction(deleteNylas.request, (state) => ({
    ...state,
    deleting: true,
  }))
  .handleAction(deleteNylas.success, (state, { payload }) => ({
    ...state,
    deleting: false,
    nylasCalendarInfo: payload === NylasType.CALENDAR ? undefined : state.nylasCalendarInfo,
    nylasEmailInfo: payload === NylasType.EMAIL ? undefined : state.nylasEmailInfo,
  }))
  .handleAction(deleteNylas.failure, (state) => ({
    ...state,
    deleting: false,
  }))
  .handleAction(updateNylas.request, (state) => ({
    ...state,
    updating: true,
  }))
  .handleAction(updateNylas.success, (state, { payload }) => ({
    ...state,
    nylasCalendarInfo: payload,
    updating: false,
  }))
  .handleAction(updateNylas.failure, (state) => ({
    ...state,
    updating: false,
  }));

export * from "./selectors";
export type NylasActions = Actions;
export default nylas;
