enum EntityHistoryAction {
  ADDED_TO_GROUP = "added to group",
  ADDED_TO_ROUTE = "added to route",
  ADDED_TO_TERRITORIES = "added to territories",
  CUSTOM_FIELD_VALUE_ADDED = "custom field value added",
  CUSTOM_FIELD_VALUE_DELETED = "custom field value deleted",
  CUSTOM_FIELD_VALUE_UPDATED = "custom field value updated",
  FILE_ADDED = "file added",
  FILE_REMOVED = "file removed",
  LOCATION_CHANGED = "location changed",
  NOTE_ADDED = "note added",
  NOTE_DELETED = "note deleted",
  NOTE_UPDATED = "note updated",
  OWNERSHIP_CHANGED = "ownership changed",
  RECORD_ADDED = "record added",
  RECORD_UPDATED = "record updated",
  REMOVED_FROM_GROUP = "removed from group",
  REMOVED_FROM_ROUTE = "removed from route",
  REMOVED_FROM_TERRITORIES = "removed from territories",
}

export default EntityHistoryAction;
