import React, { SyntheticEvent, useCallback, useRef } from "react";
import { connect } from "react-redux";

import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared/enum";
import { getPreviewEntityUniqueElementId, stopEvents } from "@mapmycustomers/shared/util/browser";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import MmcNotificationAction from "@app/enum/MmcNotificationAction";
import { showEntityView } from "@app/store/entityView/actions";
import { getUserSettingValue } from "@app/store/iam";
import { isOnlyMentions } from "@app/store/notification";
import { clickOnNotification, hideNotifications } from "@app/store/notification/actions";
import { RootState } from "@app/store/rootReducer";
import MmcNotification, { MmcNotificationEntity } from "@app/types/MmcNotification";
import useAnalytics from "@app/util/contexts/useAnalytics";
import { formatFriendlyRelativeTime } from "@app/util/formatters";
import getFormattedDate from "@app/util/notifications/getFormattedDate";

import Actions from "./component/Actions/Actions";
import Description from "./component/Description";
import Icon from "./component/Icon";
import Title from "./component/Title";
import styles from "./NotificationCommon.module.scss";
import getNotificationActionAnalyticGroup from "./util/getNotificationActionAnalyticGroup";

interface Props {
  clickOnNotification: typeof clickOnNotification;
  hideNotifications: typeof hideNotifications;
  isOnlyMentions: boolean;
  notification: MmcNotification;
  onGoToSettings: () => void;
  showEntityView: typeof showEntityView;
  tabIndex: number;
}

const Notification: React.FC<Props> = ({
  clickOnNotification,
  hideNotifications,
  isOnlyMentions,
  notification,
  onGoToSettings,
  showEntityView,
  tabIndex,
}) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [hovered, setHoveredAsTrue, setHoveredAsFalse] = useBoolean();
  const analyticsIssuer = useAnalytics();

  const handleEntityClick = useCallback(
    (entity: MmcNotificationEntity, event?: SyntheticEvent<HTMLElement>) => {
      if (notification.action !== MmcNotificationAction.MENTION) {
        stopEvents(event);
        showEntityView({
          elementId: event?.currentTarget
            ? getPreviewEntityUniqueElementId(event.currentTarget)
            : undefined,
          entityId: entity.id,
          entityType: entity.type,
        });
        if (entity.type !== EntityType.ACTIVITY) {
          hideNotifications();
        }
      }
    },
    [hideNotifications, notification, showEntityView]
  );

  const handleClick = useCallback(() => {
    clickOnNotification({
      elementId: getPreviewEntityUniqueElementId(ref.current as HTMLElement),
      notification,
    });
    analyticsIssuer.clicked([...(isOnlyMentions ? ["Mentions Tab"] : []), "Notification"], {
      group: getNotificationActionAnalyticGroup(notification.action),
    });
  }, [analyticsIssuer, clickOnNotification, isOnlyMentions, notification, ref]);

  return (
    <div
      className={cn(styles.container, { [styles.unread]: !notification.readStatus })}
      onClick={handleClick}
      onMouseEnter={setHoveredAsTrue}
      onMouseLeave={setHoveredAsFalse}
      ref={ref}
      tabIndex={tabIndex}
    >
      <div className={styles.iconWrapper}>
        <Icon notification={notification} />
      </div>
      <div className={styles.mainPartWrapper}>
        <Description notification={notification} onEntityClick={handleEntityClick} />
        <Title notification={notification} />
        <Tooltip placement="bottom" title={getFormattedDate(intl, notification)}>
          <span className={styles.date}>
            {formatFriendlyRelativeTime(intl, notification.updatedAt)}
          </span>
        </Tooltip>
      </div>
      <div>
        <Actions hovered={hovered} notification={notification} onGoToSettings={onGoToSettings} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOnlyMentions: isOnlyMentions(state),
  settingsExists: !!getUserSettingValue(state)("emailNotificationSettings"),
});

const mapDispatchToProps = {
  clickOnNotification,
  hideNotifications,
  showEntityView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
