import React, { ReactNode, useMemo } from "react";

import { AnalyticsService } from "@app/util/analytic/AnalyticsService";
import AnalyticsContext from "@app/util/contexts/AnalyticsContext";
import useAnalytics from "@app/util/contexts/useAnalytics";

interface Props {
  children: ReactNode;
  keyPath: string | string[];
}

const ExtendedAnalytics: React.FC<Props> = ({ children, keyPath }) => {
  const analytics = useAnalytics();
  const extendedContext: AnalyticsService = useMemo(
    () => analytics.extend(keyPath),
    // This useMemo only depends on analytics object and the _content_ of keyPath array, not the
    // instance of that array, that's why we use spread operator
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analytics, ...(Array.isArray(keyPath) ? keyPath : [keyPath])]
  );
  return <AnalyticsContext.Provider value={extendedContext}>{children}</AnalyticsContext.Provider>;
};

export default ExtendedAnalytics;
