enum MarkerShape {
  PIN = "pin",
  CIRCLE = "circle",
  SQUARE = "square",
  FLAG = "flag",
  STAR = "star",
  TRIANGLE = "triangle",
  HEXAGON = "hexagon",
  HEART = "heart",
  BOOKMARK = "bookmark",

  // special ones:
  LEAD = "lead",
  LEAD_CLICKED = "leadClicked",
  LEAD_DUPLICATE = "leadDuplicate",
  LEAD_DUPLICATE_CLICKED = "leadDuplicateClicked",
  LEAD_SELECTED = "leadSelected",
  LEAD_HIDDEN = "leadHidden",
  LEAD_HIDDEN_CLICKED = "leadHiddenClicked",
}

export const knownShapes: MarkerShape[] = [
  MarkerShape.PIN,
  MarkerShape.CIRCLE,
  MarkerShape.SQUARE,
  MarkerShape.FLAG,
  MarkerShape.STAR,
  MarkerShape.TRIANGLE,
  MarkerShape.HEXAGON,
  MarkerShape.HEART,
  MarkerShape.BOOKMARK,
];

export default MarkerShape;
