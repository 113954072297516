import { defineMessages, IntlShape } from "react-intl";

import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

const messages = defineMessages({
  [CountryCode["AFGHANISTAN"]]: {
    id: "countryName.af",
    defaultMessage: "Afghanistan",
    description: "Country name of Afghanistan",
  },
  [CountryCode["ÅLAND ISLANDS"]]: {
    id: "countryName.ax",
    defaultMessage: "Åland Islands",
    description: "Country name of Åland Islands",
  },
  [CountryCode["ALBANIA"]]: {
    id: "countryName.al",
    defaultMessage: "Albania",
    description: "Country name of Albania",
  },
  [CountryCode["ALGERIA"]]: {
    id: "countryName.dz",
    defaultMessage: "Algeria",
    description: "Country name of Algeria",
  },
  [CountryCode["AMERICAN SAMOA"]]: {
    id: "countryName.as",
    defaultMessage: "American Samoa",
    description: "Country name of American Samoa",
  },
  [CountryCode["ANDORRA"]]: {
    id: "countryName.ad",
    defaultMessage: "Andorra",
    description: "Country name of Andorra",
  },
  [CountryCode["ANGOLA"]]: {
    id: "countryName.ao",
    defaultMessage: "Angola",
    description: "Country name of Angola",
  },
  [CountryCode["ANGUILLA"]]: {
    id: "countryName.ai",
    defaultMessage: "Anguilla",
    description: "Country name of Anguilla",
  },
  [CountryCode["ANTARCTICA"]]: {
    id: "countryName.aq",
    defaultMessage: "Antarctica",
    description: "Country name of Antarctica",
  },
  [CountryCode["ANTIGUA AND BARBUDA"]]: {
    id: "countryName.ag",
    defaultMessage: "Antigua and Barbuda",
    description: "Country name of Antigua and Barbuda",
  },
  [CountryCode["ARGENTINA"]]: {
    id: "countryName.ar",
    defaultMessage: "Argentina",
    description: "Country name of Argentina",
  },
  [CountryCode["ARMENIA"]]: {
    id: "countryName.am",
    defaultMessage: "Armenia",
    description: "Country name of Armenia",
  },
  [CountryCode["ARUBA"]]: {
    id: "countryName.aw",
    defaultMessage: "Aruba",
    description: "Country name of Aruba",
  },
  [CountryCode["AUSTRALIA"]]: {
    id: "countryName.au",
    defaultMessage: "Australia",
    description: "Country name of Australia",
  },
  [CountryCode["AUSTRIA"]]: {
    id: "countryName.at",
    defaultMessage: "Austria",
    description: "Country name of Austria",
  },
  [CountryCode["AZERBAIJAN"]]: {
    id: "countryName.az",
    defaultMessage: "Azerbaijan",
    description: "Country name of Azerbaijan",
  },
  [CountryCode["BAHAMAS"]]: {
    id: "countryName.bs",
    defaultMessage: "Bahamas",
    description: "Country name of Bahamas",
  },
  [CountryCode["BAHRAIN"]]: {
    id: "countryName.bh",
    defaultMessage: "Bahrain",
    description: "Country name of Bahrain",
  },
  [CountryCode["BANGLADESH"]]: {
    id: "countryName.bd",
    defaultMessage: "Bangladesh",
    description: "Country name of Bangladesh",
  },
  [CountryCode["BARBADOS"]]: {
    id: "countryName.bb",
    defaultMessage: "Barbados",
    description: "Country name of Barbados",
  },
  [CountryCode["BELARUS"]]: {
    id: "countryName.by",
    defaultMessage: "Belarus",
    description: "Country name of Belarus",
  },
  [CountryCode["BELGIUM"]]: {
    id: "countryName.be",
    defaultMessage: "Belgium",
    description: "Country name of Belgium",
  },
  [CountryCode["BELIZE"]]: {
    id: "countryName.bz",
    defaultMessage: "Belize",
    description: "Country name of Belize",
  },
  [CountryCode["BENIN"]]: {
    id: "countryName.bj",
    defaultMessage: "Benin",
    description: "Country name of Benin",
  },
  [CountryCode["BERMUDA"]]: {
    id: "countryName.bm",
    defaultMessage: "Bermuda",
    description: "Country name of Bermuda",
  },
  [CountryCode["BHUTAN"]]: {
    id: "countryName.bt",
    defaultMessage: "Bhutan",
    description: "Country name of Bhutan",
  },
  [CountryCode["BOLIVIA (PLURINATIONAL STATE OF)"]]: {
    id: "countryName.bo",
    defaultMessage: "Bolivia (Plurinational State of)",
    description: "Country name of Bolivia (Plurinational State of)",
  },
  [CountryCode["BONAIRE, SINT EUSTATIUS AND SABA"]]: {
    id: "countryName.bq",
    defaultMessage: "Bonaire, Sint Eustatius and Saba",
    description: "Country name of Bonaire, Sint Eustatius and Saba",
  },
  [CountryCode["BOSNIA AND HERZEGOVINA"]]: {
    id: "countryName.ba",
    defaultMessage: "Bosnia and Herzegovina",
    description: "Country name of Bosnia and Herzegovina",
  },
  [CountryCode["BOTSWANA"]]: {
    id: "countryName.bw",
    defaultMessage: "Botswana",
    description: "Country name of Botswana",
  },
  [CountryCode["BOUVET ISLAND"]]: {
    id: "countryName.bv",
    defaultMessage: "Bouvet Island",
    description: "Country name of Bouvet Island",
  },
  [CountryCode["BRAZIL"]]: {
    id: "countryName.br",
    defaultMessage: "Brazil",
    description: "Country name of Brazil",
  },
  [CountryCode["BRITISH INDIAN OCEAN TERRITORY"]]: {
    id: "countryName.io",
    defaultMessage: "British Indian Ocean Territory",
    description: "Country name of British Indian Ocean Territory",
  },
  [CountryCode["BRUNEI DARUSSALAM"]]: {
    id: "countryName.bn",
    defaultMessage: "Brunei Darussalam",
    description: "Country name of Brunei Darussalam",
  },
  [CountryCode["BULGARIA"]]: {
    id: "countryName.bg",
    defaultMessage: "Bulgaria",
    description: "Country name of Bulgaria",
  },
  [CountryCode["BURKINA FASO"]]: {
    id: "countryName.bf",
    defaultMessage: "Burkina Faso",
    description: "Country name of Burkina Faso",
  },
  [CountryCode["BURUNDI"]]: {
    id: "countryName.bi",
    defaultMessage: "Burundi",
    description: "Country name of Burundi",
  },
  [CountryCode["CABO VERDE"]]: {
    id: "countryName.cv",
    defaultMessage: "Cabo Verde",
    description: "Country name of Cabo Verde",
  },
  [CountryCode["CAMBODIA"]]: {
    id: "countryName.kh",
    defaultMessage: "Cambodia",
    description: "Country name of Cambodia",
  },
  [CountryCode["CAMEROON"]]: {
    id: "countryName.cm",
    defaultMessage: "Cameroon",
    description: "Country name of Cameroon",
  },
  [CountryCode["CANADA"]]: {
    id: "countryName.ca",
    defaultMessage: "Canada",
    description: "Country name of Canada",
  },
  [CountryCode["CAYMAN ISLANDS"]]: {
    id: "countryName.ky",
    defaultMessage: "Cayman Islands",
    description: "Country name of Cayman Islands",
  },
  [CountryCode["CENTRAL AFRICAN REPUBLIC"]]: {
    id: "countryName.cf",
    defaultMessage: "Central African Republic",
    description: "Country name of Central African Republic",
  },
  [CountryCode["CHAD"]]: {
    id: "countryName.td",
    defaultMessage: "Chad",
    description: "Country name of Chad",
  },
  [CountryCode["CHILE"]]: {
    id: "countryName.cl",
    defaultMessage: "Chile",
    description: "Country name of Chile",
  },
  [CountryCode["CHINA"]]: {
    id: "countryName.cn",
    defaultMessage: "China",
    description: "Country name of China",
  },
  [CountryCode["CHRISTMAS ISLAND"]]: {
    id: "countryName.cx",
    defaultMessage: "Christmas Island",
    description: "Country name of Christmas Island",
  },
  [CountryCode["COCOS (KEELING) ISLANDS"]]: {
    id: "countryName.cc",
    defaultMessage: "Cocos (Keeling) Islands",
    description: "Country name of Cocos (Keeling) Islands",
  },
  [CountryCode["COLOMBIA"]]: {
    id: "countryName.co",
    defaultMessage: "Colombia",
    description: "Country name of Colombia",
  },
  [CountryCode["COMOROS"]]: {
    id: "countryName.km",
    defaultMessage: "Comoros",
    description: "Country name of Comoros",
  },
  [CountryCode["CONGO, DEMOCRATIC REPUBLIC OF THE"]]: {
    id: "countryName.cd",
    defaultMessage: "Congo, Democratic Republic of the",
    description: "Country name of Congo, Democratic Republic of the",
  },
  [CountryCode["CONGO"]]: {
    id: "countryName.cg",
    defaultMessage: "Congo",
    description: "Country name of Congo",
  },
  [CountryCode["COOK ISLANDS"]]: {
    id: "countryName.ck",
    defaultMessage: "Cook Islands",
    description: "Country name of Cook Islands",
  },
  [CountryCode["COSTA RICA"]]: {
    id: "countryName.cr",
    defaultMessage: "Costa Rica",
    description: "Country name of Costa Rica",
  },
  [CountryCode["CÔTE D'IVOIRE"]]: {
    id: "countryName.ci",
    defaultMessage: "Côte d'Ivoire",
    description: "Country name of Côte d'Ivoire",
  },
  [CountryCode["CROATIA"]]: {
    id: "countryName.hr",
    defaultMessage: "Croatia",
    description: "Country name of Croatia",
  },
  [CountryCode["CUBA"]]: {
    id: "countryName.cu",
    defaultMessage: "Cuba",
    description: "Country name of Cuba",
  },
  [CountryCode["CURAÇAO"]]: {
    id: "countryName.cw",
    defaultMessage: "Curaçao",
    description: "Country name of Curaçao",
  },
  [CountryCode["CYPRUS"]]: {
    id: "countryName.cy",
    defaultMessage: "Cyprus",
    description: "Country name of Cyprus",
  },
  [CountryCode["CZECHIA"]]: {
    id: "countryName.cz",
    defaultMessage: "Czechia",
    description: "Country name of Czechia",
  },
  [CountryCode["DENMARK"]]: {
    id: "countryName.dk",
    defaultMessage: "Denmark",
    description: "Country name of Denmark",
  },
  [CountryCode["DJIBOUTI"]]: {
    id: "countryName.dj",
    defaultMessage: "Djibouti",
    description: "Country name of Djibouti",
  },
  [CountryCode["DOMINICA"]]: {
    id: "countryName.dm",
    defaultMessage: "Dominica",
    description: "Country name of Dominica",
  },
  [CountryCode["DOMINICAN REPUBLIC"]]: {
    id: "countryName.do",
    defaultMessage: "Dominican Republic",
    description: "Country name of Dominican Republic",
  },
  [CountryCode["ECUADOR"]]: {
    id: "countryName.ec",
    defaultMessage: "Ecuador",
    description: "Country name of Ecuador",
  },
  [CountryCode["EGYPT"]]: {
    id: "countryName.eg",
    defaultMessage: "Egypt",
    description: "Country name of Egypt",
  },
  [CountryCode["EL SALVADOR"]]: {
    id: "countryName.sv",
    defaultMessage: "El Salvador",
    description: "Country name of El Salvador",
  },
  [CountryCode["EQUATORIAL GUINEA"]]: {
    id: "countryName.gq",
    defaultMessage: "Equatorial Guinea",
    description: "Country name of Equatorial Guinea",
  },
  [CountryCode["ERITREA"]]: {
    id: "countryName.er",
    defaultMessage: "Eritrea",
    description: "Country name of Eritrea",
  },
  [CountryCode["ESTONIA"]]: {
    id: "countryName.ee",
    defaultMessage: "Estonia",
    description: "Country name of Estonia",
  },
  [CountryCode["ESWATINI"]]: {
    id: "countryName.sz",
    defaultMessage: "Eswatini",
    description: "Country name of Eswatini",
  },
  [CountryCode["ETHIOPIA"]]: {
    id: "countryName.et",
    defaultMessage: "Ethiopia",
    description: "Country name of Ethiopia",
  },
  [CountryCode["FALKLAND ISLANDS (MALVINAS)"]]: {
    id: "countryName.fk",
    defaultMessage: "Falkland Islands (Malvinas)",
    description: "Country name of Falkland Islands (Malvinas)",
  },
  [CountryCode["FAROE ISLANDS"]]: {
    id: "countryName.fo",
    defaultMessage: "Faroe Islands",
    description: "Country name of Faroe Islands",
  },
  [CountryCode["FIJI"]]: {
    id: "countryName.fj",
    defaultMessage: "Fiji",
    description: "Country name of Fiji",
  },
  [CountryCode["FINLAND"]]: {
    id: "countryName.fi",
    defaultMessage: "Finland",
    description: "Country name of Finland",
  },
  [CountryCode["FRANCE"]]: {
    id: "countryName.fr",
    defaultMessage: "France",
    description: "Country name of France",
  },
  [CountryCode["FRENCH GUIANA"]]: {
    id: "countryName.gf",
    defaultMessage: "French Guiana",
    description: "Country name of French Guiana",
  },
  [CountryCode["FRENCH POLYNESIA"]]: {
    id: "countryName.pf",
    defaultMessage: "French Polynesia",
    description: "Country name of French Polynesia",
  },
  [CountryCode["FRENCH SOUTHERN TERRITORIES"]]: {
    id: "countryName.tf",
    defaultMessage: "French Southern Territories",
    description: "Country name of French Southern Territories",
  },
  [CountryCode["GABON"]]: {
    id: "countryName.ga",
    defaultMessage: "Gabon",
    description: "Country name of Gabon",
  },
  [CountryCode["GAMBIA"]]: {
    id: "countryName.gm",
    defaultMessage: "Gambia",
    description: "Country name of Gambia",
  },
  [CountryCode["GEORGIA"]]: {
    id: "countryName.ge",
    defaultMessage: "Georgia",
    description: "Country name of Georgia",
  },
  [CountryCode["GERMANY"]]: {
    id: "countryName.de",
    defaultMessage: "Germany",
    description: "Country name of Germany",
  },
  [CountryCode["GHANA"]]: {
    id: "countryName.gh",
    defaultMessage: "Ghana",
    description: "Country name of Ghana",
  },
  [CountryCode["GIBRALTAR"]]: {
    id: "countryName.gi",
    defaultMessage: "Gibraltar",
    description: "Country name of Gibraltar",
  },
  [CountryCode["GREECE"]]: {
    id: "countryName.gr",
    defaultMessage: "Greece",
    description: "Country name of Greece",
  },
  [CountryCode["GREENLAND"]]: {
    id: "countryName.gl",
    defaultMessage: "Greenland",
    description: "Country name of Greenland",
  },
  [CountryCode["GRENADA"]]: {
    id: "countryName.gd",
    defaultMessage: "Grenada",
    description: "Country name of Grenada",
  },
  [CountryCode["GUADELOUPE"]]: {
    id: "countryName.gp",
    defaultMessage: "Guadeloupe",
    description: "Country name of Guadeloupe",
  },
  [CountryCode["GUAM"]]: {
    id: "countryName.gu",
    defaultMessage: "Guam",
    description: "Country name of Guam",
  },
  [CountryCode["GUATEMALA"]]: {
    id: "countryName.gt",
    defaultMessage: "Guatemala",
    description: "Country name of Guatemala",
  },
  [CountryCode["GUERNSEY"]]: {
    id: "countryName.gg",
    defaultMessage: "Guernsey",
    description: "Country name of Guernsey",
  },
  [CountryCode["GUINEA-BISSAU"]]: {
    id: "countryName.gw",
    defaultMessage: "Guinea-Bissau",
    description: "Country name of Guinea-Bissau",
  },
  [CountryCode["GUINEA"]]: {
    id: "countryName.gn",
    defaultMessage: "Guinea",
    description: "Country name of Guinea",
  },
  [CountryCode["GUYANA"]]: {
    id: "countryName.gy",
    defaultMessage: "Guyana",
    description: "Country name of Guyana",
  },
  [CountryCode["HAITI"]]: {
    id: "countryName.ht",
    defaultMessage: "Haiti",
    description: "Country name of Haiti",
  },
  [CountryCode["HEARD ISLAND AND MCDONALD ISLANDS"]]: {
    id: "countryName.hm",
    defaultMessage: "Heard Island and McDonald Islands",
    description: "Country name of Heard Island and McDonald Islands",
  },
  [CountryCode["HOLY SEE"]]: {
    id: "countryName.va",
    defaultMessage: "Holy See",
    description: "Country name of Holy See",
  },
  [CountryCode["HONDURAS"]]: {
    id: "countryName.hn",
    defaultMessage: "Honduras",
    description: "Country name of Honduras",
  },
  [CountryCode["HONG KONG"]]: {
    id: "countryName.hk",
    defaultMessage: "Hong Kong",
    description: "Country name of Hong Kong",
  },
  [CountryCode["HUNGARY"]]: {
    id: "countryName.hu",
    defaultMessage: "Hungary",
    description: "Country name of Hungary",
  },
  [CountryCode["ICELAND"]]: {
    id: "countryName.is",
    defaultMessage: "Iceland",
    description: "Country name of Iceland",
  },
  [CountryCode["INDIA"]]: {
    id: "countryName.in",
    defaultMessage: "India",
    description: "Country name of India",
  },
  [CountryCode["INDONESIA"]]: {
    id: "countryName.id",
    defaultMessage: "Indonesia",
    description: "Country name of Indonesia",
  },
  [CountryCode["IRAN (ISLAMIC REPUBLIC OF)"]]: {
    id: "countryName.ir",
    defaultMessage: "Iran (Islamic Republic of)",
    description: "Country name of Iran (Islamic Republic of)",
  },
  [CountryCode["IRAQ"]]: {
    id: "countryName.iq",
    defaultMessage: "Iraq",
    description: "Country name of Iraq",
  },
  [CountryCode["IRELAND"]]: {
    id: "countryName.ie",
    defaultMessage: "Ireland",
    description: "Country name of Ireland",
  },
  [CountryCode["ISLE OF MAN"]]: {
    id: "countryName.im",
    defaultMessage: "Isle of Man",
    description: "Country name of Isle of Man",
  },
  [CountryCode["ISRAEL"]]: {
    id: "countryName.il",
    defaultMessage: "Israel",
    description: "Country name of Israel",
  },
  [CountryCode["ITALY"]]: {
    id: "countryName.it",
    defaultMessage: "Italy",
    description: "Country name of Italy",
  },
  [CountryCode["JAMAICA"]]: {
    id: "countryName.jm",
    defaultMessage: "Jamaica",
    description: "Country name of Jamaica",
  },
  [CountryCode["JAPAN"]]: {
    id: "countryName.jp",
    defaultMessage: "Japan",
    description: "Country name of Japan",
  },
  [CountryCode["JERSEY"]]: {
    id: "countryName.je",
    defaultMessage: "Jersey",
    description: "Country name of Jersey",
  },
  [CountryCode["JORDAN"]]: {
    id: "countryName.jo",
    defaultMessage: "Jordan",
    description: "Country name of Jordan",
  },
  [CountryCode["KAZAKHSTAN"]]: {
    id: "countryName.kz",
    defaultMessage: "Kazakhstan",
    description: "Country name of Kazakhstan",
  },
  [CountryCode["KENYA"]]: {
    id: "countryName.ke",
    defaultMessage: "Kenya",
    description: "Country name of Kenya",
  },
  [CountryCode["KIRIBATI"]]: {
    id: "countryName.ki",
    defaultMessage: "Kiribati",
    description: "Country name of Kiribati",
  },
  [CountryCode["KOREA, REPUBLIC OF"]]: {
    id: "countryName.kr",
    defaultMessage: "Korea, Republic of",
    description: "Country name of Korea, Republic of",
  },
  [CountryCode["KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)"]]: {
    id: "countryName.kp",
    defaultMessage: "Korea (Democratic People's Republic of)",
    description: "Country name of Korea (Democratic People's Republic of)",
  },
  [CountryCode["KUWAIT"]]: {
    id: "countryName.kw",
    defaultMessage: "Kuwait",
    description: "Country name of Kuwait",
  },
  [CountryCode["KYRGYZSTAN"]]: {
    id: "countryName.kg",
    defaultMessage: "Kyrgyzstan",
    description: "Country name of Kyrgyzstan",
  },
  [CountryCode["LAO PEOPLE'S DEMOCRATIC REPUBLIC"]]: {
    id: "countryName.la",
    defaultMessage: "Lao People's Democratic Republic",
    description: "Country name of Lao People's Democratic Republic",
  },
  [CountryCode["LATVIA"]]: {
    id: "countryName.lv",
    defaultMessage: "Latvia",
    description: "Country name of Latvia",
  },
  [CountryCode["LEBANON"]]: {
    id: "countryName.lb",
    defaultMessage: "Lebanon",
    description: "Country name of Lebanon",
  },
  [CountryCode["LESOTHO"]]: {
    id: "countryName.ls",
    defaultMessage: "Lesotho",
    description: "Country name of Lesotho",
  },
  [CountryCode["LIBERIA"]]: {
    id: "countryName.lr",
    defaultMessage: "Liberia",
    description: "Country name of Liberia",
  },
  [CountryCode["LIBYA"]]: {
    id: "countryName.ly",
    defaultMessage: "Libya",
    description: "Country name of Libya",
  },
  [CountryCode["LIECHTENSTEIN"]]: {
    id: "countryName.li",
    defaultMessage: "Liechtenstein",
    description: "Country name of Liechtenstein",
  },
  [CountryCode["LITHUANIA"]]: {
    id: "countryName.lt",
    defaultMessage: "Lithuania",
    description: "Country name of Lithuania",
  },
  [CountryCode["LUXEMBOURG"]]: {
    id: "countryName.lu",
    defaultMessage: "Luxembourg",
    description: "Country name of Luxembourg",
  },
  [CountryCode["MACAO"]]: {
    id: "countryName.mo",
    defaultMessage: "Macao",
    description: "Country name of Macao",
  },
  [CountryCode["MADAGASCAR"]]: {
    id: "countryName.mg",
    defaultMessage: "Madagascar",
    description: "Country name of Madagascar",
  },
  [CountryCode["MALAWI"]]: {
    id: "countryName.mw",
    defaultMessage: "Malawi",
    description: "Country name of Malawi",
  },
  [CountryCode["MALAYSIA"]]: {
    id: "countryName.my",
    defaultMessage: "Malaysia",
    description: "Country name of Malaysia",
  },
  [CountryCode["MALDIVES"]]: {
    id: "countryName.mv",
    defaultMessage: "Maldives",
    description: "Country name of Maldives",
  },
  [CountryCode["MALI"]]: {
    id: "countryName.ml",
    defaultMessage: "Mali",
    description: "Country name of Mali",
  },
  [CountryCode["MALTA"]]: {
    id: "countryName.mt",
    defaultMessage: "Malta",
    description: "Country name of Malta",
  },
  [CountryCode["MARSHALL ISLANDS"]]: {
    id: "countryName.mh",
    defaultMessage: "Marshall Islands",
    description: "Country name of Marshall Islands",
  },
  [CountryCode["MARTINIQUE"]]: {
    id: "countryName.mq",
    defaultMessage: "Martinique",
    description: "Country name of Martinique",
  },
  [CountryCode["MAURITANIA"]]: {
    id: "countryName.mr",
    defaultMessage: "Mauritania",
    description: "Country name of Mauritania",
  },
  [CountryCode["MAURITIUS"]]: {
    id: "countryName.mu",
    defaultMessage: "Mauritius",
    description: "Country name of Mauritius",
  },
  [CountryCode["MAYOTTE"]]: {
    id: "countryName.yt",
    defaultMessage: "Mayotte",
    description: "Country name of Mayotte",
  },
  [CountryCode["MEXICO"]]: {
    id: "countryName.mx",
    defaultMessage: "Mexico",
    description: "Country name of Mexico",
  },
  [CountryCode["MICRONESIA (FEDERATED STATES OF)"]]: {
    id: "countryName.fm",
    defaultMessage: "Micronesia (Federated States of)",
    description: "Country name of Micronesia (Federated States of)",
  },
  [CountryCode["MOLDOVA, REPUBLIC OF"]]: {
    id: "countryName.md",
    defaultMessage: "Moldova, Republic of",
    description: "Country name of Moldova, Republic of",
  },
  [CountryCode["MONACO"]]: {
    id: "countryName.mc",
    defaultMessage: "Monaco",
    description: "Country name of Monaco",
  },
  [CountryCode["MONGOLIA"]]: {
    id: "countryName.mn",
    defaultMessage: "Mongolia",
    description: "Country name of Mongolia",
  },
  [CountryCode["MONTENEGRO"]]: {
    id: "countryName.me",
    defaultMessage: "Montenegro",
    description: "Country name of Montenegro",
  },
  [CountryCode["MONTSERRAT"]]: {
    id: "countryName.ms",
    defaultMessage: "Montserrat",
    description: "Country name of Montserrat",
  },
  [CountryCode["MOROCCO"]]: {
    id: "countryName.ma",
    defaultMessage: "Morocco",
    description: "Country name of Morocco",
  },
  [CountryCode["MOZAMBIQUE"]]: {
    id: "countryName.mz",
    defaultMessage: "Mozambique",
    description: "Country name of Mozambique",
  },
  [CountryCode["MYANMAR"]]: {
    id: "countryName.mm",
    defaultMessage: "Myanmar",
    description: "Country name of Myanmar",
  },
  [CountryCode["NAMIBIA"]]: {
    id: "countryName.na",
    defaultMessage: "Namibia",
    description: "Country name of Namibia",
  },
  [CountryCode["NAURU"]]: {
    id: "countryName.nr",
    defaultMessage: "Nauru",
    description: "Country name of Nauru",
  },
  [CountryCode["NEPAL"]]: {
    id: "countryName.np",
    defaultMessage: "Nepal",
    description: "Country name of Nepal",
  },
  [CountryCode["NETHERLANDS"]]: {
    id: "countryName.nl",
    defaultMessage: "Netherlands",
    description: "Country name of Netherlands",
  },
  [CountryCode["NEW CALEDONIA"]]: {
    id: "countryName.nc",
    defaultMessage: "New Caledonia",
    description: "Country name of New Caledonia",
  },
  [CountryCode["NEW ZEALAND"]]: {
    id: "countryName.nz",
    defaultMessage: "New Zealand",
    description: "Country name of New Zealand",
  },
  [CountryCode["NICARAGUA"]]: {
    id: "countryName.ni",
    defaultMessage: "Nicaragua",
    description: "Country name of Nicaragua",
  },
  [CountryCode["NIGER"]]: {
    id: "countryName.ne",
    defaultMessage: "Niger",
    description: "Country name of Niger",
  },
  [CountryCode["NIGERIA"]]: {
    id: "countryName.ng",
    defaultMessage: "Nigeria",
    description: "Country name of Nigeria",
  },
  [CountryCode["NIUE"]]: {
    id: "countryName.nu",
    defaultMessage: "Niue",
    description: "Country name of Niue",
  },
  [CountryCode["NORFOLK ISLAND"]]: {
    id: "countryName.nf",
    defaultMessage: "Norfolk Island",
    description: "Country name of Norfolk Island",
  },
  [CountryCode["NORTHERN MARIANA ISLANDS"]]: {
    id: "countryName.mp",
    defaultMessage: "Northern Mariana Islands",
    description: "Country name of Northern Mariana Islands",
  },
  [CountryCode["NORTH MACEDONIA"]]: {
    id: "countryName.mk",
    defaultMessage: "North Macedonia",
    description: "Country name of North Macedonia",
  },
  [CountryCode["NORWAY"]]: {
    id: "countryName.no",
    defaultMessage: "Norway",
    description: "Country name of Norway",
  },
  [CountryCode["OMAN"]]: {
    id: "countryName.om",
    defaultMessage: "Oman",
    description: "Country name of Oman",
  },
  [CountryCode["PAKISTAN"]]: {
    id: "countryName.pk",
    defaultMessage: "Pakistan",
    description: "Country name of Pakistan",
  },
  [CountryCode["PALAU"]]: {
    id: "countryName.pw",
    defaultMessage: "Palau",
    description: "Country name of Palau",
  },
  [CountryCode["PALESTINE, STATE OF"]]: {
    id: "countryName.ps",
    defaultMessage: "Palestine, State of",
    description: "Country name of Palestine, State of",
  },
  [CountryCode["PANAMA"]]: {
    id: "countryName.pa",
    defaultMessage: "Panama",
    description: "Country name of Panama",
  },
  [CountryCode["PAPUA NEW GUINEA"]]: {
    id: "countryName.pg",
    defaultMessage: "Papua New Guinea",
    description: "Country name of Papua New Guinea",
  },
  [CountryCode["PARAGUAY"]]: {
    id: "countryName.py",
    defaultMessage: "Paraguay",
    description: "Country name of Paraguay",
  },
  [CountryCode["PERU"]]: {
    id: "countryName.pe",
    defaultMessage: "Peru",
    description: "Country name of Peru",
  },
  [CountryCode["PHILIPPINES"]]: {
    id: "countryName.ph",
    defaultMessage: "Philippines",
    description: "Country name of Philippines",
  },
  [CountryCode["PITCAIRN"]]: {
    id: "countryName.pn",
    defaultMessage: "Pitcairn",
    description: "Country name of Pitcairn",
  },
  [CountryCode["POLAND"]]: {
    id: "countryName.pl",
    defaultMessage: "Poland",
    description: "Country name of Poland",
  },
  [CountryCode["PORTUGAL"]]: {
    id: "countryName.pt",
    defaultMessage: "Portugal",
    description: "Country name of Portugal",
  },
  [CountryCode["PUERTO RICO"]]: {
    id: "countryName.pr",
    defaultMessage: "Puerto Rico",
    description: "Country name of Puerto Rico",
  },
  [CountryCode["QATAR"]]: {
    id: "countryName.qa",
    defaultMessage: "Qatar",
    description: "Country name of Qatar",
  },
  [CountryCode["RÉUNION"]]: {
    id: "countryName.re",
    defaultMessage: "Réunion",
    description: "Country name of Réunion",
  },
  [CountryCode["ROMANIA"]]: {
    id: "countryName.ro",
    defaultMessage: "Romania",
    description: "Country name of Romania",
  },
  [CountryCode["RUSSIAN FEDERATION"]]: {
    id: "countryName.ru",
    defaultMessage: "Russian Federation",
    description: "Country name of Russian Federation",
  },
  [CountryCode["RWANDA"]]: {
    id: "countryName.rw",
    defaultMessage: "Rwanda",
    description: "Country name of Rwanda",
  },
  [CountryCode["SAINT BARTHÉLEMY"]]: {
    id: "countryName.bl",
    defaultMessage: "Saint Barthélemy",
    description: "Country name of Saint Barthélemy",
  },
  [CountryCode["SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"]]: {
    id: "countryName.sh",
    defaultMessage: "Saint Helena, Ascension and Tristan da Cunha",
    description: "Country name of Saint Helena, Ascension and Tristan da Cunha",
  },
  [CountryCode["SAINT KITTS AND NEVIS"]]: {
    id: "countryName.kn",
    defaultMessage: "Saint Kitts and Nevis",
    description: "Country name of Saint Kitts and Nevis",
  },
  [CountryCode["SAINT LUCIA"]]: {
    id: "countryName.lc",
    defaultMessage: "Saint Lucia",
    description: "Country name of Saint Lucia",
  },
  [CountryCode["SAINT MARTIN (FRENCH PART)"]]: {
    id: "countryName.mf",
    defaultMessage: "Saint Martin (French part)",
    description: "Country name of Saint Martin (French part)",
  },
  [CountryCode["SAINT PIERRE AND MIQUELON"]]: {
    id: "countryName.pm",
    defaultMessage: "Saint Pierre and Miquelon",
    description: "Country name of Saint Pierre and Miquelon",
  },
  [CountryCode["SAINT VINCENT AND THE GRENADINES"]]: {
    id: "countryName.vc",
    defaultMessage: "Saint Vincent and the Grenadines",
    description: "Country name of Saint Vincent and the Grenadines",
  },
  [CountryCode["SAMOA"]]: {
    id: "countryName.ws",
    defaultMessage: "Samoa",
    description: "Country name of Samoa",
  },
  [CountryCode["SAN MARINO"]]: {
    id: "countryName.sm",
    defaultMessage: "San Marino",
    description: "Country name of San Marino",
  },
  [CountryCode["SAO TOME AND PRINCIPE"]]: {
    id: "countryName.st",
    defaultMessage: "Sao Tome and Principe",
    description: "Country name of Sao Tome and Principe",
  },
  [CountryCode["SAUDI ARABIA"]]: {
    id: "countryName.sa",
    defaultMessage: "Saudi Arabia",
    description: "Country name of Saudi Arabia",
  },
  [CountryCode["SENEGAL"]]: {
    id: "countryName.sn",
    defaultMessage: "Senegal",
    description: "Country name of Senegal",
  },
  [CountryCode["SERBIA"]]: {
    id: "countryName.rs",
    defaultMessage: "Serbia",
    description: "Country name of Serbia",
  },
  [CountryCode["SEYCHELLES"]]: {
    id: "countryName.sc",
    defaultMessage: "Seychelles",
    description: "Country name of Seychelles",
  },
  [CountryCode["SIERRA LEONE"]]: {
    id: "countryName.sl",
    defaultMessage: "Sierra Leone",
    description: "Country name of Sierra Leone",
  },
  [CountryCode["SINGAPORE"]]: {
    id: "countryName.sg",
    defaultMessage: "Singapore",
    description: "Country name of Singapore",
  },
  [CountryCode["SINT MAARTEN (DUTCH PART)"]]: {
    id: "countryName.sx",
    defaultMessage: "Sint Maarten (Dutch part)",
    description: "Country name of Sint Maarten (Dutch part)",
  },
  [CountryCode["SLOVAKIA"]]: {
    id: "countryName.sk",
    defaultMessage: "Slovakia",
    description: "Country name of Slovakia",
  },
  [CountryCode["SLOVENIA"]]: {
    id: "countryName.si",
    defaultMessage: "Slovenia",
    description: "Country name of Slovenia",
  },
  [CountryCode["SOLOMON ISLANDS"]]: {
    id: "countryName.sb",
    defaultMessage: "Solomon Islands",
    description: "Country name of Solomon Islands",
  },
  [CountryCode["SOMALIA"]]: {
    id: "countryName.so",
    defaultMessage: "Somalia",
    description: "Country name of Somalia",
  },
  [CountryCode["SOUTH AFRICA"]]: {
    id: "countryName.za",
    defaultMessage: "South Africa",
    description: "Country name of South Africa",
  },
  [CountryCode["SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"]]: {
    id: "countryName.gs",
    defaultMessage: "South Georgia and the South Sandwich Islands",
    description: "Country name of South Georgia and the South Sandwich Islands",
  },
  [CountryCode["SOUTH SUDAN"]]: {
    id: "countryName.ss",
    defaultMessage: "South Sudan",
    description: "Country name of South Sudan",
  },
  [CountryCode["SPAIN"]]: {
    id: "countryName.es",
    defaultMessage: "Spain",
    description: "Country name of Spain",
  },
  [CountryCode["SRI LANKA"]]: {
    id: "countryName.lk",
    defaultMessage: "Sri Lanka",
    description: "Country name of Sri Lanka",
  },
  [CountryCode["SUDAN"]]: {
    id: "countryName.sd",
    defaultMessage: "Sudan",
    description: "Country name of Sudan",
  },
  [CountryCode["SURINAME"]]: {
    id: "countryName.sr",
    defaultMessage: "Suriname",
    description: "Country name of Suriname",
  },
  [CountryCode["SVALBARD AND JAN MAYEN"]]: {
    id: "countryName.sj",
    defaultMessage: "Svalbard and Jan Mayen",
    description: "Country name of Svalbard and Jan Mayen",
  },
  [CountryCode["SWEDEN"]]: {
    id: "countryName.se",
    defaultMessage: "Sweden",
    description: "Country name of Sweden",
  },
  [CountryCode["SWITZERLAND"]]: {
    id: "countryName.ch",
    defaultMessage: "Switzerland",
    description: "Country name of Switzerland",
  },
  [CountryCode["SYRIAN ARAB REPUBLIC"]]: {
    id: "countryName.sy",
    defaultMessage: "Syrian Arab Republic",
    description: "Country name of Syrian Arab Republic",
  },
  [CountryCode["TAIWAN, PROVINCE OF CHINA"]]: {
    id: "countryName.tw",
    defaultMessage: "Taiwan, Province of China",
    description: "Country name of Taiwan, Province of China",
  },
  [CountryCode["TAJIKISTAN"]]: {
    id: "countryName.tj",
    defaultMessage: "Tajikistan",
    description: "Country name of Tajikistan",
  },
  [CountryCode["TANZANIA, UNITED REPUBLIC OF"]]: {
    id: "countryName.tz",
    defaultMessage: "Tanzania, United Republic of",
    description: "Country name of Tanzania, United Republic of",
  },
  [CountryCode["THAILAND"]]: {
    id: "countryName.th",
    defaultMessage: "Thailand",
    description: "Country name of Thailand",
  },
  [CountryCode["TIMOR-LESTE"]]: {
    id: "countryName.tl",
    defaultMessage: "Timor-Leste",
    description: "Country name of Timor-Leste",
  },
  [CountryCode["TOGO"]]: {
    id: "countryName.tg",
    defaultMessage: "Togo",
    description: "Country name of Togo",
  },
  [CountryCode["TOKELAU"]]: {
    id: "countryName.tk",
    defaultMessage: "Tokelau",
    description: "Country name of Tokelau",
  },
  [CountryCode["TONGA"]]: {
    id: "countryName.to",
    defaultMessage: "Tonga",
    description: "Country name of Tonga",
  },
  [CountryCode["TRINIDAD AND TOBAGO"]]: {
    id: "countryName.tt",
    defaultMessage: "Trinidad and Tobago",
    description: "Country name of Trinidad and Tobago",
  },
  [CountryCode["TUNISIA"]]: {
    id: "countryName.tn",
    defaultMessage: "Tunisia",
    description: "Country name of Tunisia",
  },
  [CountryCode["TURKEY"]]: {
    id: "countryName.tr",
    defaultMessage: "Turkey",
    description: "Country name of Turkey",
  },
  [CountryCode["TURKMENISTAN"]]: {
    id: "countryName.tm",
    defaultMessage: "Turkmenistan",
    description: "Country name of Turkmenistan",
  },
  [CountryCode["TURKS AND CAICOS ISLANDS"]]: {
    id: "countryName.tc",
    defaultMessage: "Turks and Caicos Islands",
    description: "Country name of Turks and Caicos Islands",
  },
  [CountryCode["TUVALU"]]: {
    id: "countryName.tv",
    defaultMessage: "Tuvalu",
    description: "Country name of Tuvalu",
  },
  [CountryCode["UGANDA"]]: {
    id: "countryName.ug",
    defaultMessage: "Uganda",
    description: "Country name of Uganda",
  },
  [CountryCode["UKRAINE"]]: {
    id: "countryName.ua",
    defaultMessage: "Ukraine",
    description: "Country name of Ukraine",
  },
  [CountryCode["UNITED ARAB EMIRATES"]]: {
    id: "countryName.ae",
    defaultMessage: "United Arab Emirates",
    description: "Country name of United Arab Emirates",
  },
  [CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]]: {
    id: "countryName.gb",
    defaultMessage: "United Kingdom of Great Britain and Northern Ireland",
    description: "Country name of United Kingdom of Great Britain and Northern Ireland",
  },
  [CountryCode["UNITED STATES MINOR OUTLYING ISLANDS"]]: {
    id: "countryName.um",
    defaultMessage: "United States Minor Outlying Islands",
    description: "Country name of United States Minor Outlying Islands",
  },
  [CountryCode["UNITED STATES OF AMERICA"]]: {
    id: "countryName.us",
    defaultMessage: "United States",
    description: "Country name of United States of America",
  },
  [CountryCode["URUGUAY"]]: {
    id: "countryName.uy",
    defaultMessage: "Uruguay",
    description: "Country name of Uruguay",
  },
  [CountryCode["UZBEKISTAN"]]: {
    id: "countryName.uz",
    defaultMessage: "Uzbekistan",
    description: "Country name of Uzbekistan",
  },
  [CountryCode["VANUATU"]]: {
    id: "countryName.vu",
    defaultMessage: "Vanuatu",
    description: "Country name of Vanuatu",
  },
  [CountryCode["VENEZUELA (BOLIVARIAN REPUBLIC OF)"]]: {
    id: "countryName.ve",
    defaultMessage: "Venezuela (Bolivarian Republic of)",
    description: "Country name of Venezuela (Bolivarian Republic of)",
  },
  [CountryCode["VIET NAM"]]: {
    id: "countryName.vn",
    defaultMessage: "Viet Nam",
    description: "Country name of Viet Nam",
  },
  [CountryCode["VIRGIN ISLANDS (BRITISH)"]]: {
    id: "countryName.vg",
    defaultMessage: "Virgin Islands (British)",
    description: "Country name of Virgin Islands (British)",
  },
  [CountryCode["VIRGIN ISLANDS (U.S.)"]]: {
    id: "countryName.vi",
    defaultMessage: "Virgin Islands (U.S.)",
    description: "Country name of Virgin Islands (U.S.)",
  },
  [CountryCode["WALLIS AND FUTUNA"]]: {
    id: "countryName.wf",
    defaultMessage: "Wallis and Futuna",
    description: "Country name of Wallis and Futuna",
  },
  [CountryCode["WESTERN SAHARA"]]: {
    id: "countryName.eh",
    defaultMessage: "Western Sahara",
    description: "Country name of Western Sahara",
  },
  [CountryCode["YEMEN"]]: {
    id: "countryName.ye",
    defaultMessage: "Yemen",
    description: "Country name of Yemen",
  },
  [CountryCode["ZAMBIA"]]: {
    id: "countryName.zm",
    defaultMessage: "Zambia",
    description: "Country name of Zambia",
  },
  [CountryCode["ZIMBABWE"]]: {
    id: "countryName.zw",
    defaultMessage: "Zimbabwe",
    description: "Country name of Zimbabwe",
  },
});

const formatCountryName = (intl: IntlShape, countryCode: CountryCode): string =>
  // this tertiary is used because sometimes we call this function with country code returned
  // by google, hence it may not be present in our CountryCode enum
  messages[countryCode] ? intl.formatMessage(messages[countryCode]) : "";

export default formatCountryName;
