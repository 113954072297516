import { useContext } from "react";

import IFrequencyContext from "../type/IFrequencyContext";

import FrequencyContext from "./FrequencyContext";

const useFrequencyContext = (): IFrequencyContext =>
  useContext<IFrequencyContext>(FrequencyContext);

export default useFrequencyContext;
