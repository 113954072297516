import React, { ReactNode, useCallback } from "react";

import Tooltip from "antd/es/tooltip";

import Checkbox from "@app/component/input/Checkbox";

interface Props<T> {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  displayName: string;
  entity: T;
  onToggle?: (entity: T, checked: boolean) => void;
  tooltip?: ReactNode;
}

const ToggleEntityCheckbox = <T,>({
  checked,
  className,
  disabled,
  displayName,
  entity,
  onToggle,
  tooltip,
}: Props<T>) => {
  const handleCheckboxChange = useCallback(
    (checked) => onToggle && onToggle(entity, checked),
    [entity, onToggle]
  );
  return (
    <div className={className}>
      <Tooltip title={tooltip}>
        <Checkbox checked={checked} disabled={disabled} onChange={handleCheckboxChange}>
          {displayName}
        </Checkbox>
      </Tooltip>
    </div>
  );
};

export default ToggleEntityCheckbox;
