import AreaSearchMode from "@app/enum/AreaSearchMode";
import {
  AddressAreaSearchQuery,
  AreaSearchQuery,
  CoordinatesAreaSearchQuery,
  EntityAreaSearchQuery,
} from "@app/types/filters/AreaSearchQuery";

export const isEntityAreaSearchQuery = (value?: AreaSearchQuery): value is EntityAreaSearchQuery =>
  value?.mode === AreaSearchMode.ENTITY;

export const isAddressAreaSearchQuery = (
  value?: AreaSearchQuery
): value is AddressAreaSearchQuery => value?.mode === AreaSearchMode.ADDRESS;

export const isCoordinatesAreaSearchQuery = (
  value?: AreaSearchQuery
): value is CoordinatesAreaSearchQuery =>
  value?.mode === AreaSearchMode.CURRENT_MAP_VIEW_CENTER ||
  value?.mode === AreaSearchMode.CURRENT_USER_LOCATION;
