import React from "react";

import { isObject } from "lodash-es";
import { useIntl } from "react-intl";

import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import { FormItem, TextField } from "@mapmycustomers/ui";

import useNameRequiredValidationRules from "@app/util/form/useNameRequiredValidationRules";
import useFirstLastNameRequirement from "@app/util/hook/entityForm/useFirstLastNameRequirement";

import messages from "./messages";
import styles from "./NameField.module.scss";

interface Props {
  actualSchema: LayoutSchemaField[];
  caption?: { firstName?: string; lastName?: string };
  disabled?: { firstName?: boolean; lastName?: boolean } | boolean;
  placeholder?: { firstName?: string; lastName?: string };
}

const PersonNameField: React.FC<Props> = ({ actualSchema, caption, disabled, placeholder }) => {
  const intl = useIntl();

  const [firstNameRequired, lastNameRequired] = useFirstLastNameRequirement(actualSchema);

  const firstNameDisabled = isObject(disabled) ? disabled.firstName : disabled;
  const lastNameDisabled = isObject(disabled) ? disabled.lastName : disabled;

  const someRuleAreRequired = firstNameRequired || lastNameRequired;

  const nameRules = useNameRequiredValidationRules(intl);

  return (
    <div className={styles.container}>
      <FormItem
        className={styles.fullWidth}
        label={intl.formatMessage(messages.firstName)}
        name="firstName"
        rules={
          firstNameDisabled
            ? undefined
            : someRuleAreRequired
            ? [{ required: firstNameRequired }]
            : nameRules
        }
      >
        <TextField
          caption={caption?.firstName}
          label={intl.formatMessage(messages.firstName)}
          locked={firstNameDisabled}
          placeholder={placeholder?.firstName ?? intl.formatMessage(messages.firstName)}
          required={firstNameRequired}
        />
      </FormItem>
      <FormItem
        className={styles.fullWidth}
        label={intl.formatMessage(messages.lastName)}
        name="lastName"
        rules={
          lastNameDisabled
            ? undefined
            : someRuleAreRequired
            ? [{ required: lastNameRequired }]
            : nameRules
        }
      >
        <TextField
          caption={caption?.lastName}
          label={intl.formatMessage(messages.lastName)}
          locked={lastNameDisabled}
          placeholder={placeholder?.lastName ?? intl.formatMessage(messages.lastName)}
          required={lastNameRequired}
        />
      </FormItem>
    </div>
  );
};

export default PersonNameField;
