import { EventInput } from "@fullcalendar/common";
import { addMinutes, addSeconds } from "date-fns/esm";

import ActivitySuggestion from "@mapmycustomers/shared/types/entity/ActivitySuggestion";

import CalendarViewMode from "@app/enum/CalendarViewMode";
import isValidDate from "@app/util/isValidDate";
import { parseApiDate } from "@app/util/parsers";

import EventObject from "../type/EventObject";

const suggestionToEvent = (
  suggestion: ActivitySuggestion,
  viewMode: CalendarViewMode
): EventInput | undefined => {
  const result: EventInput = {
    editable: false,
    extendedProps: { suggestion } as EventObject,
  };

  // gap suggestions are only displayed on week and day views, but not on month
  if (suggestion.property === "gap" && viewMode !== CalendarViewMode.MONTH) {
    const startAt = parseApiDate(suggestion.startAt);
    if (!isValidDate(startAt) || !suggestion.duration) {
      return undefined;
    }
    result.start = addSeconds(startAt, 1); // a dirty hack to avoid overlapping with preceding event
    result.end = addMinutes(startAt, suggestion.duration);
    return result;
  }

  if (suggestion.property === "cadence" && viewMode !== CalendarViewMode.MONTH) {
    result.start = suggestion.startAt.substring(0, 10);
    result.allDay = true;
    return result;
  }

  return undefined;
};

export default suggestionToEvent;
