import React from "react";

import type { PresetStatusColorType } from "antd/es/_util/colors";
import Badge from "antd/es/badge";
import Tooltip, { TooltipProps } from "antd/es/tooltip";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import User from "@mapmycustomers/shared/types/User";

import Chip from "@app/component/Chip";
import { formatDate } from "@app/util/formatters";
import getRelativeTimeValueAndUnit from "@app/util/getRelativeTimeValueAndUnit";
import { parseApiDate } from "@app/util/parsers";

import styles from "./LastActivityChip.module.scss";

interface Props extends Pick<TooltipProps, "placement"> {
  clickable?: boolean;
  lastActivity: User["lastActivity"];
  userId: User["id"];
}

const LastActivityChip: React.FC<Props> = ({ clickable, lastActivity, placement, userId }) => {
  const intl = useIntl();

  const lastActivityDate = lastActivity ? parseApiDate(lastActivity) : undefined;
  const lastActivityRelative = getRelativeTimeValueAndUnit(lastActivityDate);
  let lastActivityStatus: PresetStatusColorType = "default";
  if (lastActivityRelative?.[1] === "minutes") {
    lastActivityStatus = lastActivityRelative[0] <= 30 ? "success" : "warning";
  }

  if (!lastActivityDate) {
    return null;
  }

  const content = (
    <Chip className={styles.chip} clickable={clickable}>
      <Tooltip
        placement={placement}
        title={lastActivity ? formatDate(lastActivityDate, "PPPp") : undefined}
      >
        <Badge
          status={lastActivityStatus}
          text={
            lastActivityRelative![0] === 0
              ? intl.formatMessage({
                  id: "dashboard.header.member.lastActivity.now",
                  defaultMessage: "online",
                  description: "A text to show when user is active right now",
                })
              : intl.formatRelativeTime(lastActivityRelative![0], lastActivityRelative![1], {
                  numeric: "auto",
                  style: "long",
                })
          }
        />
      </Tooltip>
    </Chip>
  );

  return clickable ? (
    <Link target="_blank" to={`/map?previewUserLocations=${userId}`}>
      {content}
    </Link>
  ) : (
    content
  );
};

export default LastActivityChip;
