import React from "react";

import { defineMessage } from "react-intl";

import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { IFilterInstance } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import i18nService from "@app/config/I18nService";
import { isAreaFilterCondition } from "@app/util/viewModel/assert";

import getLocationPreview from "./getLocationPreview";
import LocationFilterComponent from "./LocationFilter";
import LocationFilterProps from "./LocationFilterProps";

export const LOCATION_FILTER_OPERATORS = [FilterOperator.IN_AREA];

const message = defineMessage({
  id: "filters.location.description",
  defaultMessage: "{field} is within {preview}",
  description:
    "Human-readable description for LocationFilter. For preview placeholder format, see filters.location.previewMessage.* messages",
});

const doesSupportValue = (value: any, operator: FilterOperator) =>
  isAreaFilterCondition({ operator, value });

const LocationFilter: IFilterInstance = {
  doesSupportOption: (option: FilterOption) => option === FilterOption.USER_POSITION,
  doesSupportValue,
  getComponent: (): React.FC<LocationFilterProps> => LocationFilterComponent,
  getHumanReadableDescription: (value: SimpleCondition, field: IField) => {
    if (!isAreaFilterCondition(value)) {
      return undefined;
    }
    const intl = i18nService.getIntl();
    return !intl
      ? `${field.displayName} is within ${getLocationPreview(intl, value.value)}`
      : intl.formatMessage(message, {
          field: field.displayName,
          preview: getLocationPreview(i18nService.getIntl(), value.value),
        });
  },
};

export default LocationFilter;
