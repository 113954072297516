import { defineMessages, IntlShape } from "react-intl";

import DealStageType from "@mapmycustomers/shared/enum/DealStageType";

const messages = defineMessages({
  [DealStageType.DEFAULT]: {
    id: "dealStageType.default",
    defaultMessage: "Gross Open Revenue",
    description: "Default Deal Stage type display message",
  },
  [DealStageType.LOST]: {
    id: "dealStageType.lost",
    defaultMessage: "Closed Lost",
    description: "Default Deal Stage type display message",
  },
  [DealStageType.WON]: {
    id: "dealStageType.won",
    defaultMessage: "Closed Won",
    description: "Won Deal Stage type display message",
  },
});

const getDealStageTypeDisplayMessage = (intl: IntlShape, type: DealStageType) =>
  intl.formatMessage(messages[type]);

export default getDealStageTypeDisplayMessage;
