import groupBy from "lodash-es/groupBy";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getMe, getOrganizationId } from "@app/store/iam";
import LayoutMap from "@app/store/layout/LayoutMap";
import Iam from "@app/types/Iam";
import getLayoutModelByEntityType from "@app/util/layout/impl";
import LayoutModel from "@app/util/layout/LayoutModel";

import { fetchAllLayouts, fetchLayoutsForEntityType } from "./actions";

function updateLayoutModels(layoutMap: LayoutMap) {
  (Object.keys(layoutMap) as EntityTypesSupportingFieldCustomization[]).forEach((entityType) => {
    const layoutModel: LayoutModel<typeof entityType> = getLayoutModelByEntityType(entityType);
    layoutModel.setLayouts(layoutMap[entityType]);
  });
}

export function* onFetchAllLayouts() {
  // Here's a trick: we don't really load layouts for the current user,
  // we take them from /users/me response.
  // Per Platform, this is a more correct behavior since for some users
  // iam layouts are the ones which are 100% guaranteed to be related to
  // a current user, whereas /layouts layouts might also include
  // some other layouts. E.g. for managers, /layouts layouts may include
  // some which are related to their reps.
  const iam: Iam = yield select(getMe);
  const layoutMap = groupBy(iam.layouts ?? [], "entity") as LayoutMap;
  yield call(updateLayoutModels, layoutMap);
  yield put(fetchAllLayouts.success(layoutMap));
}

export function* onFetchLayoutsForEntityType({
  payload: entityType,
}: ReturnType<typeof fetchLayoutsForEntityType.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<FormLayout> = yield callApi("getLayouts", orgId, {
      $filters: { $and: [{ entity: entityType }] },
      // Assuming that each entity will have max 100 layouts.
      // Also, according to the DB even largest number of layouts per org is 11 now.
      // Even if somebody will create a layout per team, there's max 41 teams per org,
      // hence 41 layouts. Which is less than 100.
      $limit: 100,
    });
    const layoutModel: LayoutModel<typeof entityType> = yield call(
      getLayoutModelByEntityType,
      entityType
    );
    layoutModel.setLayouts(response.data);
    yield put(fetchLayoutsForEntityType.success({ entityType, layouts: response.data }));
  } catch (error) {
    yield put(fetchLayoutsForEntityType.failure(error));
    yield put(handleError({ error }));
  }
}

export function* layoutSaga() {
  yield takeLatest(fetchAllLayouts.request, onFetchAllLayouts);
  yield takeEvery(fetchLayoutsForEntityType.request, onFetchLayoutsForEntityType);
}
