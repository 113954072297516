import React from "react";

import { RenderElementProps } from "slate-react";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

import Mention from "../Mention";

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case ElementType.MENTION:
      return <Mention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
