import React from "react";

import Button from "antd/es/button";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import Path from "@app/enum/Path";

import commonStyles from "../AuthCommon.module.scss";

import styles from "./SuccessScreenCommon.module.scss";

interface Props {}

const ResetPasswordSuccessScreen: React.FC<Props> = () => {
  return (
    <div className={commonStyles.container}>
      <p className={commonStyles.title}>
        <FormattedMessage
          id="auth.resetPassword.form.success.title"
          defaultMessage="Your password was reset"
          description="Title on the successfully password reset"
        />
      </p>
      <p className={commonStyles.description}>
        <FormattedMessage
          id="auth.resetPassword.form.success.description"
          defaultMessage="Please log in again using your new password"
          description="Description on the successfully password reset"
        />
      </p>
      <Link className={styles.returnLink} to={Path.LOGIN}>
        <Button className={styles.returnButton} type="primary">
          <FormattedMessage
            id="auth.resetPassword.form.loginLink"
            defaultMessage="Return to Login"
            description="Title of the Back to Login Link"
          />
        </Button>
      </Link>
    </div>
  );
};

export default ResetPasswordSuccessScreen;
