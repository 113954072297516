import React from "react";

import { useIntl } from "react-intl";

import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import SelectField from "@app/component/input/SelectField";

import messages from "../messages";
import useRecipientOptions from "../util/useRecipientOptions";

import styles from "./RecipientSelector.module.scss";

interface Props {
  disabled?: boolean;
  onSelectRecipientId?: (id?: EntitiesSupportedByEmailFeature["id"]) => void;
  recipients: EntitiesSupportedByEmailFeature[];
  selectedRecipientId?: EntitiesSupportedByEmailFeature["id"];
}

const RecipientSelector: React.FC<Props> = ({
  disabled,
  onSelectRecipientId,
  recipients,
  selectedRecipientId,
}) => {
  const intl = useIntl();
  const recipientOptions = useRecipientOptions(recipients);

  return (
    <SelectField<EntitiesSupportedByEmailFeature["id"]>
      className={styles.container}
      disabled={disabled}
      dropdownMatchSelectWidth={false}
      onChange={onSelectRecipientId}
      options={recipientOptions}
      placeholder={intl.formatMessage(messages.selectRecipient)}
      value={selectedRecipientId}
    />
  );
};

export default RecipientSelector;
