import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

const parseRangeCondition = (platformCondition: Condition) => ({
  operator: FilterOperator.IN_RANGE,
  value: [
    platformCondition[PlatformFilterOperator.GREATER_THAN_OR_EQUAL] as number,
    platformCondition[PlatformFilterOperator.LESS_THAN_OR_EQUAL] as number,
  ] as const,
});

export default parseRangeCondition;
