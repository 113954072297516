import { defineMessages } from "react-intl";

import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

import DateTimeField from "./impl/DateTimeField";
import FieldModel from "./impl/FieldModel";
import { createField } from "./impl/fieldModelUtil";
import NameField from "./impl/NameField";
import UserField from "./impl/UserField";

export enum ReportFieldName {
  LAST_GENERATED_AT = "lastGeneratedAt",
  NAME = "name",
  TABLE_NAME = "tableName",
  USER = "user",
}

const displayNames = defineMessages<ReportFieldName>({
  [ReportFieldName.LAST_GENERATED_AT]: {
    id: "report.field.lastGeneratedAt",
    defaultMessage: "Last Generated At",
    description: "Last Generated At field of report",
  },
  [ReportFieldName.NAME]: {
    id: "report.field.name",
    defaultMessage: "Name",
    description: "Name field of report",
  },
  [ReportFieldName.TABLE_NAME]: {
    id: "report.field.tableName",
    defaultMessage: "Table Name",
    description: "Table Name field of report",
  },
  [ReportFieldName.USER]: {
    id: "report.field.user",
    defaultMessage: "Creator",
    description: "Creator field of report",
  },
});

const reportFieldModel = new FieldModel(
  [
    new DateTimeField({
      displayName: displayNames[ReportFieldName.LAST_GENERATED_AT],
      name: ReportFieldName.LAST_GENERATED_AT,
    }),
    new NameField({
      displayName: displayNames[ReportFieldName.NAME],
      name: ReportFieldName.NAME,
    }),
    {
      displayName: displayNames[ReportFieldName.TABLE_NAME],
      name: ReportFieldName.TABLE_NAME,
      type: FieldType.STRING,
    },
    new UserField({
      displayName: displayNames[ReportFieldName.USER],
      name: ReportFieldName.USER,
      platformFilterName: "userId",
    }),
  ].map(createField)
);

export default reportFieldModel;
