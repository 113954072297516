import React from "react";

import cn from "classnames";

import styles from "./Section.module.scss";

interface Props {
  action?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  title?: string;
  titleClassName?: string;
}

const Section: React.FC<Props> = ({
  action,
  children,
  className,
  headerClassName,
  title,
  titleClassName,
}) => (
  <div className={cn(styles.container, className)}>
    {(title || action) && (
      <div className={cn(styles.header, headerClassName)}>
        {title && <div className={cn(styles.title, titleClassName)}>{title}</div>}
        {action}
      </div>
    )}
    {children}
  </div>
);

export default Section;
