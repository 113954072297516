import { Editor } from "slate";

import FileUploadCallback from "../type/FileUploadCallback";

import { insertImageFiles } from "./withImages";

const selectImage = (editor: Editor, onLoadFile: FileUploadCallback) => {
  const input = document.createElement("input");
  input.type = "file";
  input.multiple = false;
  input.onchange = () => {
    if (input.files) {
      insertImageFiles(editor, onLoadFile, input.files);
    }
  };
  input.click();
};

export default selectImage;
