import React, { ReactNode, useCallback } from "react";

import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Input from "antd/es/input";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Alert } from "@mapmycustomers/ui";

import Path from "@app/enum/Path";
import analyticsService from "@app/util/analytic/AnalyticsService";

import commonStyles from "../AuthCommon.module.scss";

import styles from "./LoginForm.module.scss";
import messages from "./messages";

const SSO_INQUIRY_LINK = "https://share.hsforms.com/1aoERY4lrQ0GOQeRlXahNAQ3is1h";

interface Props {
  className?: string;
  errorMessage?: ReactNode;
  isLoading: boolean;
  onResetError?: () => void;
  onSubmit: (values: { password: string; username: string }) => void;
  withRegistrationLink?: boolean;
  withRestorePasswordLink?: boolean;
  withWelcomeText?: boolean;
}

const LoginForm: React.FC<Props> = ({
  className,
  errorMessage,
  isLoading,
  onResetError,
  onSubmit,
  withRegistrationLink = false,
  withRestorePasswordLink = true,
  withWelcomeText = true,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleOnForgotPassword = useCallback(() => {
    onResetError?.();
    analyticsService.clicked(["Forgot password"]);
  }, [onResetError]);

  const handleSsoLogin = useCallback(() => analyticsService.clicked(["SSO"]), []);

  return (
    <section className={cn(commonStyles.container, className)}>
      <div className={styles.loginDiv}>
        <div className={styles.loginPage}>
          <div>
            {errorMessage && (
              <Alert className={styles.errorMessage} message={errorMessage} type="error" />
            )}
            <Form form={form} id="login" layout="vertical" name="login" onFinish={onSubmit}>
              {withWelcomeText && (
                <h1 className={commonStyles.title}>{intl.formatMessage(messages.title)}</h1>
              )}
              {withRegistrationLink && (
                <p className={commonStyles.description}>
                  <FormattedMessage
                    id="auth.login.form.signUp"
                    defaultMessage="Not yet registered? <link>Sign up</link>"
                    description="Sign up link for Login page"
                    values={{
                      link: (linkText: string) => <Link to={Path.REGISTER}>{linkText}</Link>,
                    }}
                  />
                </p>
              )}
              <Form.Item
                colon={false}
                label={intl.formatMessage(messages.email)}
                name="username"
                required
                requiredMark="optional"
                rules={[
                  {
                    message: intl.formatMessage(messages.emailRequired),
                    required: true,
                  },
                ]}
              >
                <Input autoComplete="username" name="username" size="large" type="email" />
              </Form.Item>
              <Form.Item
                className={styles.passwordField}
                colon={false}
                label={intl.formatMessage(messages.password)}
                name="password"
                required
                requiredMark="optional"
                rules={[
                  {
                    message: intl.formatMessage(messages.passwordRequired),
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  autoComplete="current-password"
                  name="current-password"
                  size="large"
                  spellCheck="false"
                />
              </Form.Item>
              {withRestorePasswordLink && (
                <Link
                  className={styles.forgotPasswordLink}
                  onClick={handleOnForgotPassword}
                  to={Path.FORGOT_PASSWORD}
                >
                  {intl.formatMessage(messages.forgotPasswordLink)}
                </Link>
              )}
              <Form.Item>
                <Button
                  block
                  className={styles.loginButton}
                  htmlType="submit"
                  loading={isLoading}
                  type="primary"
                >
                  {isLoading
                    ? intl.formatMessage(messages.loading)
                    : intl.formatMessage(messages.submit)}
                </Button>
              </Form.Item>
              <Divider className={styles.divider}>{intl.formatMessage(messages.divider)}</Divider>
              <div className={styles.container}>
                <Link to={Path.SSO_LOGIN}>
                  <Button
                    block
                    icon={<FontAwesomeIcon icon={faKey} />}
                    onClick={handleSsoLogin}
                    type="dashed"
                  >
                    {intl.formatMessage(messages.ssoLogin)}
                  </Button>
                </Link>
                <Button
                  className={styles.inquire}
                  href={SSO_INQUIRY_LINK}
                  target="_blank"
                  type="link"
                >
                  {intl.formatMessage(messages.inquire)}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
