import React from "react";
import { connect } from "react-redux";

import Col from "antd/es/col";
import Row from "antd/es/row";
import { useIntl } from "react-intl";

import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";

import { getEmailQuota } from "@app/store/email";
import { RootState } from "@app/store/rootReducer";

import TextWithInfo from "../typography/TextWithInfo";

import styles from "./EmailSummaryInfo.module.scss";
import TotalSendLeft from "./TotalSendLeft";

interface Props {
  emailQuota?: EmailQuota;
  footer?: React.ReactNode;
}

const EmailSummaryInfo: React.FC<Props> = ({ emailQuota, footer }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <Row align="middle" justify="space-between">
        <Col className={styles.infoText}>
          <TextWithInfo
            info={intl.formatMessage({
              id: "component.email.summaryInfo.point.tooltip",
              defaultMessage:
                "Activities will be logged when each email is sent. You can edit individual activities after they're created.",
              description: "Second point tooltip in the email summary info.",
            })}
            placement="top"
          >
            {intl.formatMessage({
              id: "component.email.summaryInfo.point",
              defaultMessage: "Pending sends",
              description: "Second point in the email summary info.",
            })}
          </TextWithInfo>
        </Col>
        <Col className={styles.infoValue}>{emailQuota?.submitted ?? 0}</Col>
      </Row>
      <TotalSendLeft />
      {footer}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailQuota: getEmailQuota(state),
});

export default connect(mapStateToProps)(EmailSummaryInfo);
