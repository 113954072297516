import { createReducer } from "typesafe-actions";

import GlobalSearchItem from "../../types/globalSearch/GlobalSearchItem";

import {
  Actions,
  fetchUserRecents,
  hideGlobalSearch,
  performSearch,
  showGlobalSearch,
} from "./actions";

export interface GlobalSearchState {
  loading: boolean;
  searchResult: GlobalSearchItem[];
  userRecents: GlobalSearchItem[];
  visible: boolean;
}

const initialState: GlobalSearchState = {
  loading: false,
  searchResult: [],
  userRecents: [],
  visible: false,
};

const globalSearch = createReducer<GlobalSearchState, Actions>(initialState)
  .handleAction(fetchUserRecents.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchUserRecents.success, (state, { payload }) => ({
    ...state,
    loading: false,
    userRecents: payload,
  }))
  .handleAction(fetchUserRecents.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(showGlobalSearch, (state) => ({
    ...state,
    visible: true,
  }))
  .handleAction(hideGlobalSearch, (state) => ({
    ...state,
    visible: false,
  }))
  .handleAction(performSearch.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(performSearch.success, (state, { payload }) => ({
    ...state,
    loading: false,
    searchResult: payload,
  }))
  .handleAction(performSearch.failure, (state) => ({
    ...state,
    loading: false,
  }));

export type GlobalSearchActions = Actions;
export default globalSearch;
