import React, { useCallback } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { useIntl } from "react-intl";

import Organization from "@mapmycustomers/shared/types/Organization";
import Sandbox from "@mapmycustomers/shared/types/Sandbox";

import sandboxImage from "@app/assets/sandbox/sandbox.svg";
import configService from "@app/config/ConfigService";
import Environment from "@app/enum/Environment";
import { fetchSandboxAccessToken } from "@app/store/app/actions";
import { getOrganization } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import layout from "@app/styles/layout";
import { getAppUrl } from "@app/util/appUrl";

import styles from "./SandboxInfoBar.module.scss";

interface Props {
  fetchSandboxAccessToken: typeof fetchSandboxAccessToken.request;
  organization?: Organization;
}

const SandboxInfoBar: React.FC<Props> = ({ fetchSandboxAccessToken, organization }) => {
  const intl = useIntl();
  const isOnSandbox = configService.getCurrentEnvironment() === Environment.SANDBOX;
  const currentSandbox: Sandbox | undefined = organization?.metaData?.connectedOrg;

  const handleSwitchToMainApp = useCallback(() => {
    fetchSandboxAccessToken({
      callback: (token) => {
        window.location.href = getAppUrl(
          configService.getBaseAppUrl(),
          token,
          "/settings/organization/sandbox"
        );
      },
    });
  }, [fetchSandboxAccessToken]);

  if (!isOnSandbox || !currentSandbox) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Row align="middle" gutter={layout.spacerM}>
        <Col>
          <img alt="sandbox" src={sandboxImage} />
        </Col>
        <Col>
          {intl.formatMessage(
            {
              id: "sandbox.infoText",
              defaultMessage: "You’re currently working in a sandbox environment called “{name}”",
              description: "Sandbox info text",
            },
            { name: currentSandbox.name }
          )}
        </Col>
      </Row>
      <Button onClick={handleSwitchToMainApp}>
        {intl.formatMessage({
          id: "sandbox.backToMainApp",
          defaultMessage: "Back to main account",
          description: "Sandbox back to production",
        })}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  organization: getOrganization(state),
});

const mapDispatchToProps = {
  fetchSandboxAccessToken: fetchSandboxAccessToken.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SandboxInfoBar);
