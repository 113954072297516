import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { ClientType, RecordSource } from "@mapmycustomers/shared/enum/RecordSourceType";
import { EntitySupportingSourceTracking } from "@mapmycustomers/shared/types/entity";

import i18nService from "@app/config/I18nService";
import { getRecordSourceType, getRecordSourceTypeDisplayName } from "@app/util/ui";

import SourceFilter, { SOURCE_FILTER_OPERATORS } from "../../filters/SourceFilter";

import Field, { FieldProperties } from "./Field";

class SourceField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "source",
        filterInstances: {
          source: SourceFilter,
        },
        operators: SOURCE_FILTER_OPERATORS,
      },
      features: [
        FieldFeature.DATA_VIEW,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.SORTABLE,
        FieldFeature.SOURCE_FIELD,
        FieldFeature.SUPPORTS_SET_FILTER,
      ],
      filterType: FieldType.ID,
      platformFilterName: "source",
      type: FieldType.STRING,
      valueFormatter: (entity: unknown, value: unknown) => {
        const recordSource = value as ClientType | RecordSource;
        const intl = i18nService.getIntl();
        return intl ? getRecordSourceTypeDisplayName(intl, recordSource) : recordSource;
      },
      valueGetter: (entity: unknown) =>
        getRecordSourceType(entity as EntitySupportingSourceTracking),
      ...data,
    });
  }
}

export default SourceField;
