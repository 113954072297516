import { useCallback, useState } from "react";

import { FormInstance } from "antd/es/form/hooks/useForm";

const useFormChangeTracker = (form: FormInstance): [boolean, () => void] => {
  const [isFormChanged, setFormChanged] = useState<boolean>(form.isFieldsTouched());
  const handleFormChange = useCallback(() => setFormChanged(form.isFieldsTouched()), [form]);
  return [isFormChanged, handleFormChange];
};

export default useFormChangeTracker;
