import React from "react";
import { connect } from "react-redux";

import { isGlobalSearchVisible } from "@app/store/globalSearch/selectors";
import { RootState } from "@app/store/rootReducer";

import GlobalSearchModal from "./GlobalSearchModal";

interface Props {
  visible: boolean;
}

const GlobalSearch: React.FC<Props> = ({ visible }) => (visible ? <GlobalSearchModal /> : null);

const mapStateToProps = (state: RootState) => ({
  visible: isGlobalSearchVisible(state),
});

export default connect(mapStateToProps)(GlobalSearch);
