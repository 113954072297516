import { Editor, NodeEntry, Transforms } from "slate";

const withSingleLine = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]: NodeEntry) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

export default withSingleLine;
