import React, { useEffect, useRef } from "react";

import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { formatFriendlyRelativeTime } from "@app/util/formatters";

import FrequencyEntity from "../../type/FrequencyEntity";

import styles from "./Point.module.scss";

interface Props {
  cadenceDaysOut: FrequencyEntity["cadenceDaysOut"];
  date: Date;
  isToday: boolean;
  pointPercentSize: number;
  position: number;
  sequenceLength: number;
  status: FrequencyStatus;
}

const Point: React.FC<Props> = ({
  cadenceDaysOut,
  date,
  isToday,
  pointPercentSize,
  position,
  sequenceLength,
  status,
}) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);
  const [hovered, setHovered, unsetHovered] = useBoolean();

  useEffect(() => {
    containerRef.current?.style.setProperty("--point-pos", `${pointPercentSize * position}%`);
    containerRef.current?.style.setProperty("--point-width", `${pointPercentSize}%`);
  }, [date, position, pointPercentSize]);

  const dueDaysCount = sequenceLength - position - 1;
  return (
    <div
      className={styles.container}
      onMouseEnter={setHovered}
      onMouseLeave={unsetHovered}
      ref={containerRef}
    >
      <Tooltip
        title={
          <div className={styles.tooltip}>
            <div className={styles.tooltipTitle}>
              {isToday
                ? intl.formatMessage({
                    id: "frequency.nextActivityState.point.today",
                    defaultMessage: "Today",
                    description: "frequency component - next activity state - Today",
                  })
                : formatFriendlyRelativeTime(intl, date.toISOString())}
            </div>
            <div className={styles.tooltipSubTitle}>
              {isToday && (cadenceDaysOut ?? 0) > 0
                ? intl.formatMessage(
                    {
                      id: "frequency.nextActivityState.point.pastDueCount",
                      defaultMessage: "{count} {count, plural, one {day} other {days}} past due",
                      description: "frequency component - next activity state - past due count",
                    },
                    { count: cadenceDaysOut ?? 0 }
                  )
                : dueDaysCount === 0
                ? intl.formatMessage({
                    id: "frequency.nextActivityState.point.activityDue",
                    defaultMessage: "Activity due",
                    description: "frequency component - next activity state - Activity due",
                  })
                : intl.formatMessage(
                    {
                      id: "frequency.nextActivityState.point.subtitle",
                      defaultMessage: "Due in {count} {count, plural, one {day} other {days}}",
                      description: "frequency component - next activity state - due days count",
                    },
                    { count: dueDaysCount }
                  )}
            </div>
          </div>
        }
        visible={hovered}
      >
        <div
          className={cn(styles.point, {
            [styles.outSoon]: status === FrequencyStatus.UPCOMING,
            [styles.overdue]: status === FrequencyStatus.PAST_DUE,
            [styles.upToDate]: status === FrequencyStatus.UP_TO_DATE,
          })}
        />
      </Tooltip>
    </div>
  );
};

export default Point;
