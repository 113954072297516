import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

export const alertBadgesState = (state: RootState) => state.scene.map.layers.alerts;

export const getVisibleBadges = createSelector(
  alertBadgesState,
  ({ visibleBadges }) => visibleBadges
);

export const getAlertDismissed = createSelector(
  alertBadgesState,
  ({ alertDismissed }) => alertDismissed
);
