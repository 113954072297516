import React, { useCallback } from "react";
import { connect } from "react-redux";

import cn from "classnames";

import Identified from "@mapmycustomers/shared/types/base/Identified";
import Named from "@mapmycustomers/shared/types/base/Named";
import { EntityTypesSupportedByActivities } from "@mapmycustomers/shared/types/entity";

import ButtonLink from "@app/component/ButtonLink";
import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";
import { showEntityView } from "@app/store/entityView/actions";

import styles from "./EntityLink.module.scss";

interface Props {
  className?: string;
  entity?: Identified & Named;
  entityType: EntityTypesSupportedByActivities;
  showEntityView: typeof showEntityView;
}

const EntityLink: React.FC<Props> = ({ className, entity, entityType, showEntityView }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      showEntityView({ entityId: entity!.id, entityType });
    },
    [entity, entityType, showEntityView]
  );

  if (!entity) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <EntityTypeShapedIcon
        className={styles.icon}
        containerClassName={styles.iconContainer}
        entityType={entityType}
      />
      <ButtonLink className={styles.link} onClick={handleClick}>
        {entity.name}
      </ButtonLink>
    </div>
  );
};

const mapDispatchToProps = {
  showEntityView,
};

export default connect(null, mapDispatchToProps)(EntityLink);
