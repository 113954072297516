import React from "react";

import { Col, Row } from "antd/es/grid";

import { Group } from "@mapmycustomers/shared/types/entity";

import EntityBadge from "@app/component/EntityBadge";
import layout from "@app/styles/layout";

import styles from "./GroupBadge.module.scss";

interface Props {
  group: Group;
}

const GroupBadge: React.FC<Props> = ({ group }) => {
  return (
    <Row align="middle" gutter={layout.spacerXS}>
      <Col>
        <div className={styles.groupBadgeContainer}>
          <EntityBadge entity={group} hideName />
        </div>
      </Col>
      <Col className={styles.groupName}>{group.name}</Col>
    </Row>
  );
};

export default GroupBadge;
