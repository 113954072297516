import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import {
  EntitiesSupportingGroups,
  EntityTypeSupportingGroups,
  Group,
} from "@mapmycustomers/shared/types/entity";
import { Modal } from "@mapmycustomers/ui";

import GroupsSelection from "@app/component/GroupsSelection";
import { updateEntities } from "@app/store/groups/actions";
import { getGroupsForEntity, isUpdating } from "@app/store/groups/selectors";
import { RootState } from "@app/store/rootReducer";

interface Props {
  entity: EntitiesSupportingGroups;
  entityType: EntityTypeSupportingGroups;
  groups: Group[];
  onHide?: (updated?: boolean) => void;
  onReload?: () => void;
  title?: ReactNode;
  updateEntities: typeof updateEntities.request;
  updateLoading: boolean;
}

const GroupsModal: React.FC<Props> = ({
  entity,
  entityType,
  groups,
  onHide,
  onReload,
  title,
  updateEntities,
  updateLoading,
}) => {
  const intl = useIntl();

  const groupIds = useMemo(
    () => new Set(entity.groups ? entity.groups.map((g) => g.id) : []),
    [entity.groups]
  );
  const [selectedGroupIds, setSelectedGroupIds] = useState<Set<Group["id"]>>(groupIds);

  useEffect(() => {
    setSelectedGroupIds(groupIds);
  }, [groupIds]);

  const groupIdsToDelete = Array.from(groupIds).filter((id) => !selectedGroupIds.has(id));
  const groupIdsToAdd = Array.from(selectedGroupIds).filter((id) => !groupIds.has(id));
  const hasChanges = groupIdsToAdd.length > 0 || groupIdsToDelete.length > 0;

  const handleSave = useCallback(() => {
    updateEntities({
      entities: [entity],
      entityType,
      groupIdsToAdd,
      groupIdsToDelete,
      onSuccess: () => {
        onReload?.();
        onHide?.(true);
      },
    });
  }, [entity, entityType, groupIdsToAdd, groupIdsToDelete, onHide, onReload, updateEntities]);

  const handleChange = useCallback((checkedValues: Set<Group["id"]>) => {
    setSelectedGroupIds(checkedValues);
  }, []);

  return (
    <Modal
      maskClosable={false}
      okButtonProps={{
        disabled: !hasChanges || updateLoading,
        loading: updateLoading,
      }}
      okText={intl.formatMessage({
        id: "actions.manageGroups.modal.apply",
        defaultMessage: "Apply",
        description: "Manage Groups modal - Apply button text",
      })}
      onCancel={() => onHide?.()}
      onOk={handleSave}
      open
      title={
        title ??
        intl.formatMessage({
          id: "actions.manageGroups.modal.title",
          defaultMessage: "Manage Groups",
          description: "Manage Groups modal title",
        })
      }
    >
      <GroupsSelection
        entityType={entityType}
        groups={groups}
        onChange={handleChange}
        selectedGroupIds={selectedGroupIds}
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState, props: { entityType: EntityTypeSupportingGroups }) => ({
  groups: getGroupsForEntity(state)(props.entityType),
  updateLoading: isUpdating(state),
});

const mapDispatchToProps = {
  updateEntities: updateEntities.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsModal);
