import browserLocale from "browser-locale";
import { Task } from "redux-saga";
import { all, call, fork, join, put, race, select, take, takeLeading } from "redux-saga/effects";

import Feature from "@mapmycustomers/shared/enum/Feature";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { EntityType, MapEntity } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import Organization, { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import PersistentLastUsedLayoutData from "@mapmycustomers/shared/types/persistent/PersistentLastUsedLayoutData";
import { SandboxAccessTokenData } from "@mapmycustomers/shared/types/Sandbox";
import Setting from "@mapmycustomers/shared/types/Setting";
import User from "@mapmycustomers/shared/types/User";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import localSettings from "@app/config/LocalSettings";
import DistanceUnit from "@app/enum/DistanceUnit";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import { updateStackRankViewState } from "@app/scene/dashboard/store/stackRank/actions";
import { DEFAULT_DATE_RANGE_TYPE } from "@app/scene/dashboard/store/stackRank/reducers";
import { setUserIds } from "@app/scene/reports/store/actions";
import { getUserIds } from "@app/scene/reports/store/selectors";
import { initializeCumul } from "@app/store/cumul/actions";
import { fetchAllCustomFields } from "@app/store/customFields/actions";
import { handleError } from "@app/store/errors/actions";
import { fetchAllGroups } from "@app/store/groups/actions";
import {
  doesOrganizationSupportTerritories,
  getDefaultColumns,
  getFeatures,
  getLastUsedLayout,
  getMe,
  getOrganizationId,
  getOrganizationSettingValue,
  getUserSetting,
  isCumulEnabled,
  isCurrentUserMember,
} from "@app/store/iam";
import {
  fetchGeocodingLimit,
  fetchMe,
  findMe,
  setBigOrganization,
  updateSetting,
} from "@app/store/iam/actions";
import { fetchAllLayouts } from "@app/store/layout/actions";
import { changeLocale } from "@app/store/locale/actions";
import { getUsers } from "@app/store/members";
import { fetchRoles, fetchTeams, fetchUsers } from "@app/store/members/actions";
import { fetchNotificationsUnreadTotal } from "@app/store/notification/actions";
import { initializeNylas } from "@app/store/nylas/actions";
import { fetchPinLegends } from "@app/store/pinLegends/actions";
import { fetchReferenceData } from "@app/store/referenceData/actions";
import { fetchAllSavedFilters } from "@app/store/savedFilters/actions";
import { fetchAllSchemas } from "@app/store/schema/actions";
import Iam from "@app/types/Iam";
import analyticsService from "@app/util/analytic/AnalyticsService";
import { getLocalTimeZone } from "@app/util/dates";
import companyFieldModel from "@app/util/fieldModel/CompanyFieldModel";
import dealFieldModel from "@app/util/fieldModel/DealFieldModel";
import getFieldModelByEntityType, {
  EntityTypeWithFieldModel,
} from "@app/util/fieldModel/getByEntityType";
import personFieldModel from "@app/util/fieldModel/PersonFieldModel";
import frigadeService from "@app/util/frigade/frigadeService";
import {
  activityLayoutModel,
  companyLayoutModel,
  dealLayoutModel,
  personLayoutModel,
} from "@app/util/layout/impl";
import loggingService from "@app/util/logging";
import { ensureGoogleMapsApiIsLoaded } from "@app/util/mapping";
import standardRangeToDateRange from "@app/util/range/standardRangeToDateRange";

import { fetchActivityTypes } from "../activity/actions";
import { callApi } from "../api/callApi";
import { fetchFunnels } from "../deal/actions";
import { buildRecordsPreviewConfiguration } from "../recordPreview/sagas";
import { initializeUserColors } from "../userColor/actions";

import { fetchSandboxAccessToken, initializeApp } from "./actions";

const colorFieldFilter = (field: IField): boolean => !field.hasFeature(FieldFeature.COLOR_FIELD);
const territoryFieldFilter = (field: IField): boolean =>
  !field.hasFeature(FieldFeature.TERRITORY_FIELD);

const languageToRegion: Record<string, string> = {
  en: "en-US",
  es: "es-ES",
};

export function* onInitialize() {
  try {
    // since we added code which requires google api into the navbar (starting from
    // create company modal), we need to load google apis from the beginning.
    // We only wait for the result of this action in the very end of this saga.
    const googleMapsLoadingTask: Task = yield fork(ensureGoogleMapsApiIsLoaded);

    // call fetch* actions
    yield put(fetchMe.request());
    yield put(findMe.request()); // not waiting for this one to complete or fail

    // now wait till all succeed or any fails, whichever happens first
    const { failure } = yield race({
      failure: take([fetchMe.failure]),
      success: all([take(fetchMe.success)]),
    });

    // fail app initialization if any of fetch actions failed, and succeed otherwise
    if (failure) {
      yield put(initializeApp.failure());
      yield put(handleError({ error: failure.payload }));
      return;
    }

    const me: Iam = yield select(getMe);

    let features: OrganizationMetaData["features"] = yield select(getFeatures);
    // patch features metadata if local overrides are specified
    // overrides are useful for dev testing because you can adjust available features
    // without actually modifying org data
    const hasFeaturesOverride: boolean = yield call(localSettings.hasFeaturesOverride);
    if (hasFeaturesOverride) {
      const overriddenFeatures: OrganizationMetaData["features"] = yield call(
        localSettings.getFeaturesOverride
      );
      features = { ...features, ...overriddenFeatures };
      yield put(
        fetchMe.success({
          ...me,
          organization: {
            ...me.organization,
            metaData: { ...me.organization.metaData, features },
          },
        })
      );
    }

    if (features?.[Feature.DISALLOW_COLOR]?.enabled) {
      companyFieldModel.addFieldFilter(colorFieldFilter);
      personFieldModel.addFieldFilter(colorFieldFilter);
    }

    const territoriesEnabled: boolean = yield select(doesOrganizationSupportTerritories);
    if (!territoriesEnabled) {
      companyFieldModel.addFieldFilter(territoryFieldFilter);
      personFieldModel.addFieldFilter(territoryFieldFilter);
      dealFieldModel.addFieldFilter(territoryFieldFilter);
    }

    const getSetting: (settingName: string) => Setting | undefined = yield select(getUserSetting);
    const userSettingLanguage: Setting | undefined = yield call(getSetting, "language");
    const languageSettingValue: null | string = userSettingLanguage?.value ?? null;
    const locale: string = languageSettingValue || (yield call(browserLocale));
    yield put(changeLocale.request(locale));
    yield race([take(changeLocale.success), take(changeLocale.failure)]);
    yield put(
      updateStackRankViewState({ dateRange: standardRangeToDateRange(DEFAULT_DATE_RANGE_TYPE)! })
    );

    // Update "region" user setting to keep it in sync with the actual region user uses
    // Especially useful when Language is set to "auto-detect"
    const userSettingRegion: Setting | undefined = yield call(getSetting, "region");
    if (userSettingRegion) {
      const region =
        (languageSettingValue ? languageToRegion[languageSettingValue] : undefined) ??
        (yield call(browserLocale));
      if (region !== userSettingRegion.value) {
        loggingService.debug(
          `Update region setting to "${region}" (previous value: "${userSettingRegion.value}")`
        );
        const setting = { ...userSettingRegion, value: region };
        yield put(updateSetting.request({ orgId: me.organization.id, setting, userId: me.id }));
      }
    }

    const userSettingTimezone: Setting | undefined = yield call(getSetting, "timezone");
    if (userSettingTimezone) {
      const timezone = getLocalTimeZone();
      if (timezone && timezone !== userSettingTimezone.value) {
        const setting = { ...userSettingTimezone, value: timezone };
        yield put(updateSetting.request({ orgId: me.organization.id, setting, userId: me.id }));
      }
    }

    yield put(initializeUserColors());

    yield call(analyticsService.identify, {
      ...me,
      "sign-upSource": localSettings.getSignupCompleted() ? "Web" : undefined,
    });
    yield call(analyticsService.group, me);
    yield call(frigadeService.initialize, me);
    localSettings.setSignupCompleted(false);

    const getOrgSetting: <T = any>(settingName: string, defaultValue?: T) => T = yield select(
      getOrganizationSettingValue
    );
    const distanceUnit = getOrgSetting<DistanceUnit>(
      OrganizationSetting.DISTANCE_UNIT,
      DistanceUnit.MILE
    );
    const currency = getOrgSetting(OrganizationSetting.CURRENCY, "USD");
    localSettings.setSiUnitsUsage(distanceUnit === DistanceUnit.KM);
    localSettings.setCurrencyCode(currency);

    const organizationId = me?.organization.id;
    const prevOrganizationId = localSettings.getOrganizationId();
    // means that user logged under another org, from this, we should clear view settings
    if (organizationId !== prevOrganizationId) {
      localSettings.resetViewSettings();
      localSettings.setOrganizationId(organizationId);
    }

    // call fetch* actions which required having IAM data in the store
    yield put(fetchAllCustomFields.request());
    yield put(fetchAllGroups.request());
    yield put(fetchAllLayouts.request());
    yield put(fetchAllSchemas.request());
    yield put(fetchRoles.request());
    yield put(fetchTeams.request());
    yield put(fetchUsers.request());
    yield put(fetchFunnels.request());
    yield put(fetchActivityTypes.request());
    // these two don't really require IAM data, but would fail if token is outdated, so let's call them
    // after having IAM to guarantee we use a fresh token
    yield put(fetchReferenceData.request());
    yield put(initializeNylas.request());
    yield put(fetchGeocodingLimit.request());
    yield put(fetchPinLegends.request());

    // now wait till all succeed or any fails, whichever happens first
    const { failure2 } = yield race({
      failure2: take([
        fetchAllCustomFields.failure,
        fetchAllGroups.failure,
        fetchAllSchemas.failure,
        fetchRoles.failure,
        fetchTeams.failure,
        fetchUsers.failure,
        fetchFunnels.failure,
        fetchActivityTypes.failure,
        fetchReferenceData.failure,
        initializeNylas.failure,
        fetchGeocodingLimit.failure,
        fetchPinLegends.failure,
      ]),
      success2: all([
        take(fetchAllCustomFields.success),
        take(fetchAllGroups.success),
        take(fetchAllSchemas.success),
        take(fetchRoles.success),
        take(fetchTeams.success),
        take(fetchUsers.success),
        take(fetchFunnels.success),
        take(fetchActivityTypes.success),
        take(fetchReferenceData.success),
        take(initializeNylas.success),
        take(fetchGeocodingLimit.success),
        take(fetchPinLegends.success),
      ]),
    });

    // fail app initialization if any of fetch actions failed, and succeed otherwise
    if (failure2) {
      yield put(initializeApp.failure());
      yield put(handleError({ error: failure2.payload }));
      return;
    }

    // initialize reports pages with users selection
    const allUsers: User[] = yield select(getUsers);
    const reportUserIds: undefined | User["id"][] = yield select(getUserIds);
    const currentUserMember: boolean = yield select(isCurrentUserMember);
    if (!reportUserIds?.length) {
      if (currentUserMember) {
        yield put(setUserIds([me.id]));
      } else {
        yield put(setUserIds(allUsers.map(({ id }) => id)));
      }
    }

    // initialize layouts
    const recentLayouts: PersistentLastUsedLayoutData | undefined = yield select(getLastUsedLayout);
    if (recentLayouts?.[EntityType.ACTIVITY]) {
      activityLayoutModel.setRecentLayoutId(recentLayouts[EntityType.ACTIVITY]!);
    }
    if (recentLayouts?.[EntityType.COMPANY]) {
      companyLayoutModel.setRecentLayoutId(recentLayouts[EntityType.COMPANY]!);
    }
    if (recentLayouts?.[EntityType.DEAL]) {
      dealLayoutModel.setRecentLayoutId(recentLayouts[EntityType.DEAL]!);
    }
    if (recentLayouts?.[EntityType.PERSON]) {
      personLayoutModel.setRecentLayoutId(recentLayouts[EntityType.PERSON]!);
    }

    // setting default columns, should only be done after CFs are fetched
    const defaultColumns: Exclude<OrganizationMetaData["defaultColumns"], undefined> = yield select(
      getDefaultColumns
    );
    Object.keys(defaultColumns).forEach((entityType) => {
      const fieldModel = getFieldModelByEntityType(entityType as EntityTypeWithFieldModel);
      if (!fieldModel) {
        loggingService.debug("Invalid entity type in default columns", entityType);
        return;
      }

      const defaultFields =
        defaultColumns[
          entityType as keyof Exclude<OrganizationMetaData["defaultColumns"], undefined>
        ]
          ?.map((platformFieldName) => fieldModel.getByPlatformName(platformFieldName))
          .filter(isDefined)
          .map((field) => field.name) ?? [];

      if (defaultFields.length) {
        fieldModel.fields.forEach((field) => {
          field.toggleFeature(
            FieldFeature.VISIBLE_BY_DEFAULT,
            field.hasFeature(FieldFeature.ALWAYS_VISIBLE) || defaultFields.includes(field.name)
          );
        });
      }
    });

    // Only fetch saved filters when schemas and custom fields are fetched
    yield put(fetchAllSavedFilters.request());

    // wait till all succeed or any fails, whichever happens first
    const { failure3 } = yield race({
      failure3: take([fetchAllSavedFilters.failure]),
      success3: all([take(fetchAllSavedFilters.success)]),
    });

    // fail app initialization if any of fetch actions failed, and succeed otherwise
    if (failure3) {
      yield put(initializeApp.failure());
      yield put(handleError({ error: failure3.payload }));
      return;
    }

    const pinsResponse: ListResponse<MapEntity> = yield callApi("fetchPins", me.organization.id, {
      $filters: { entities: { accounts: {}, contacts: {}, deals: {} } },
      $limit: 0,
    });
    // a threshold after which we consider organization big
    if (pinsResponse.accessible > 12000) {
      yield put(setBigOrganization(true));
    }

    const isCumulEnabledForOrg: boolean = yield select(isCumulEnabled);

    // Initializing cumul here in order to get cumul auth response and
    // dashboard list in advance to redirect reports directly to first pinned dashboard
    // and to show pinned dashboards name in menu which will be fetched from dashboard's list
    if (isCumulEnabledForOrg) {
      yield put(initializeCumul.request());
    }

    // now let's ensure google maps loading task (which was executed in parallel) is also complete
    yield join(googleMapsLoadingTask);

    yield put(initializeApp.success());

    // not waiting for this request to complete
    yield put(fetchNotificationsUnreadTotal.request());

    // initialize record preview configuration
    yield call(buildRecordsPreviewConfiguration);
  } catch (error) {
    yield put(initializeApp.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchSandboxAccessToken({
  payload: { callback },
}: ReturnType<typeof fetchSandboxAccessToken.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const { accessToken }: SandboxAccessTokenData = yield callApi("getSandboxAccessToken", orgId);
    callback?.(accessToken);
    yield put(fetchSandboxAccessToken.success(accessToken));
  } catch (error) {
    yield put(fetchSandboxAccessToken.failure());
    yield put(handleError({ error }));
  }
}

export function* appSaga() {
  yield takeLeading(initializeApp.request, onInitialize);
  yield takeLeading(fetchSandboxAccessToken.request, onFetchSandboxAccessToken);
}
