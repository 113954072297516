import { ActionType, createAction } from "typesafe-actions";

import { AnyEntity, AnyEntityId, EntityType } from "@mapmycustomers/shared/types/entity";

export interface UiSyncListener<T extends AnyEntity = AnyEntity> {
  onAdd?: (entityType: EntityType, entities: T[]) => void;
  onDelete?: (entityType: EntityType, entitiesIds: T["id"][]) => void;
  onUpdate?: (entityType: EntityType, entities: T[]) => void;
}

export const subscribe = createAction("@uiSync/subscribe")<{
  entityTypes: EntityType[];
  listener: UiSyncListener;
}>();

export const unsubscribe = createAction("@uiSync/unsubscribe")<{
  entityTypes: EntityType[];
  listener: UiSyncListener;
}>();

export const notifyAboutChanges = createAction("@uiSync/notifyAboutChanges")<{
  added?: AnyEntity[];
  deletedIds?: AnyEntityId[];
  entityType: EntityType;
  updated?: AnyEntity[];
  updatedIds?: AnyEntity["id"][];
}>();

export type Actions = ActionType<typeof notifyAboutChanges | typeof subscribe | typeof unsubscribe>;
