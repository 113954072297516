import LongLat from "../../types/base/LongLat";
import { GeoMultiPolygon, GeoPolygon } from "../../types/shapes";

const geoTypeSize = 1; // Uint8, 0 = Polygon 1 = Multipolygon
const coordinateSize = 8; // Float64
const pointSize = coordinateSize << 1; // two coordinates - long & lat
const arrayLengthSize = 4; // Uint32

const getPolygonBufferSize = (coordinates: GeoPolygon["coordinates"]) =>
  coordinates.reduce(
    (result, path) => result + path.length * pointSize + arrayLengthSize,
    arrayLengthSize
  );

const getMultiPolygonBufferSize = (coordinates: GeoMultiPolygon["coordinates"]) =>
  coordinates.reduce((result, polygon) => result + getPolygonBufferSize(polygon), arrayLengthSize);

export const geometryToBinary = (geometry: GeoMultiPolygon | GeoPolygon): ArrayBuffer => {
  const writePath = (dataView: DataView, offset: number, path: LongLat[]) => {
    dataView.setUint32(offset, path.length);
    offset += arrayLengthSize;
    path.forEach(([long, lat]) => {
      dataView.setFloat64(offset, long);
      offset += coordinateSize;
      dataView.setFloat64(offset, lat);
      offset += coordinateSize;
    });
    return offset;
  };

  const writePolygon = (dataView: DataView, offset: number, polygon: LongLat[][]) => {
    dataView.setUint32(offset, polygon.length);
    offset += arrayLengthSize;
    polygon.forEach((path) => {
      offset = writePath(dataView, offset, path);
    });
    return offset;
  };

  const writeMultiPolygon = (dataView: DataView, offset: number, multiPolygon: LongLat[][][]) => {
    dataView.setUint32(offset, multiPolygon.length);
    offset += arrayLengthSize;
    multiPolygon.forEach((polygon) => {
      offset = writePolygon(dataView, offset, polygon);
    });
    return offset;
  };

  const estimatedSizeInBytes =
    geoTypeSize +
    (geometry.type === "Polygon"
      ? getPolygonBufferSize(geometry.coordinates)
      : getMultiPolygonBufferSize(geometry.coordinates));

  const buffer = new ArrayBuffer(estimatedSizeInBytes);
  const dataView = new DataView(buffer);
  if (geometry.type === "Polygon") {
    dataView.setUint8(0, 0);
    writePolygon(dataView, geoTypeSize, geometry.coordinates);
  } else {
    dataView.setUint8(0, 1);
    writeMultiPolygon(dataView, geoTypeSize, geometry.coordinates);
  }

  return buffer;
};

export const geometryFromBinary = (buffer: ArrayBuffer): GeoMultiPolygon | GeoPolygon => {
  const readPath = (dataView: DataView, offset: number) => {
    const path = new Array(dataView.getUint32(offset));
    let currentOffset = offset + arrayLengthSize;
    for (let i = 0; i < path.length; i++) {
      const long = dataView.getFloat64(currentOffset);
      currentOffset += coordinateSize;
      const lat = dataView.getFloat64(currentOffset);
      currentOffset += coordinateSize;
      path[i] = [long, lat];
    }
    return { offset: currentOffset, path };
  };

  const readPolygon = (dataView: DataView, offset: number) => {
    const polygon = new Array(dataView.getUint32(offset));
    let currentOffset = offset + arrayLengthSize;
    for (let i = 0; i < polygon.length; i++) {
      const { offset, path } = readPath(dataView, currentOffset);
      currentOffset = offset;
      polygon[i] = path;
    }
    return { offset: currentOffset, polygon };
  };

  const readMultiPolygon = (dataView: DataView, offset: number) => {
    const multiPolygon = new Array(dataView.getUint32(offset));
    let currentOffset = offset + arrayLengthSize;
    for (let i = 0; i < multiPolygon.length; i++) {
      const { offset, polygon } = readPolygon(dataView, currentOffset);
      currentOffset = offset;
      multiPolygon[i] = polygon;
    }
    return { multiPolygon, offset: currentOffset };
  };

  const dataView = new DataView(buffer);
  const geoType = dataView.getUint8(0);

  if (geoType === 0) {
    const { polygon } = readPolygon(dataView, geoTypeSize);
    return { coordinates: polygon, type: "Polygon" };
  } else {
    const { multiPolygon } = readMultiPolygon(dataView, geoTypeSize);
    return { coordinates: multiPolygon, type: "MultiPolygon" };
  }
};
