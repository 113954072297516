import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import Address from "@mapmycustomers/shared/types/Address";
import geoService, {
  convertCoordinatesToLongLat,
} from "@mapmycustomers/shared/util/geo/GeoService";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import { AutoCompleteAddress, LabeledFieldProps, ManualAddress } from "@mapmycustomers/ui";

import poweredByGoogle from "@app/assets/google/poweredByGoogle.png";
import { reverseGeocodeAddress } from "@app/store/location/actions";

import usePlaceDetailsGetter from "./utils/usePlacesService";

interface Props extends Omit<LabeledFieldProps, "children" | "className"> {
  allowAutoCompleteClear?: boolean;
  caption?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (address?: Address) => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  placeholder?: string;
  value?: Address;
}

const AddressField: React.FC<Props> = ({
  allowAutoCompleteClear,
  caption,
  className,
  disabled,
  onChange,
  onReverseGeocodeAddress,
  placeholder,
  value,
  ...labeledProps
}) => {
  const [isManualAddress, switchToManualAddress, , toggleManualAddress] = useBoolean();

  const alreadyDetectedCountryCode = useRef(false);
  const [placeDetailsGetter] = usePlaceDetailsGetter();

  const updateCountry = useDynamicCallback((countryCode: string) =>
    onChange?.({ ...value, countryCode })
  );

  const handleEnterManual = useDynamicCallback((address: string) => {
    onChange?.({ ...value, address });
    switchToManualAddress();
  });

  useEffect(() => {
    if (!value?.countryCode && isManualAddress && !alreadyDetectedCountryCode.current) {
      alreadyDetectedCountryCode.current = true;
      // no catches here because we don't care if anything goes wrong

      geoService.getCurrentPosition().then((position) => {
        onReverseGeocodeAddress({
          callback: (result) => {
            if (result.address.countryCode) {
              updateCountry(result.address.countryCode);
            }
          },
          coordinates: convertCoordinatesToLongLat(position.coords),
        });
      });
    }
  }, [
    isManualAddress,
    onChange,
    placeDetailsGetter,
    updateCountry,
    onReverseGeocodeAddress,
    value,
  ]);

  return isManualAddress ? (
    <ManualAddress
      disabled={disabled}
      onChange={onChange}
      onReverseGeocodeAddress={onReverseGeocodeAddress}
      onToggleManualAddress={toggleManualAddress}
      value={value}
      {...labeledProps}
    />
  ) : (
    <AutoCompleteAddress
      {...labeledProps}
      allowClear={allowAutoCompleteClear}
      caption={caption}
      disabled={disabled}
      extra={<img alt="powered by Google" src={poweredByGoogle} />}
      onChange={onChange}
      onEnterManually={handleEnterManual}
      onReverseGeocodeAddress={onReverseGeocodeAddress}
      placeholder={placeholder}
      value={value}
    />
  );
};

const mapDispatchToProps = {
  onReverseGeocodeAddress: reverseGeocodeAddress,
};

export default connect(null, mapDispatchToProps)(AddressField);
