import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";

import { updateLastUsedLayout } from "@app/store/iam/actions";
import { getLayoutsForEntity } from "@app/store/layout";
import { RootState } from "@app/store/rootReducer";

import styles from "./FormLayoutSelector.module.scss";

interface OwnProps {
  disabled?: boolean;
  entityType: EntityTypesSupportingFieldCustomization;
  layout?: FormLayout;
  onChange?: (layout: FormLayout) => void;
  scrollable?: boolean;
}

interface Props extends OwnProps {
  layouts: FormLayout[];
  onUpdateLastUsedLayout: typeof updateLastUsedLayout;
}

const FormLayoutSelector: React.FC<Props> = ({
  disabled,
  entityType,
  layout,
  layouts,
  onChange,
  onUpdateLastUsedLayout,
  scrollable = true,
}) => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMenuClick = useCallback(() => {
    setVisible(false);
    setTooltipVisible(false);
  }, [setTooltipVisible, setVisible]);

  const handleLayoutChange = useCallback(
    (layout: FormLayout) => {
      setVisible(false);
      setTooltipVisible(false);

      onChange?.(layout);
      onUpdateLastUsedLayout({ entityType, layoutId: layout.id });
    },
    [entityType, onChange, onUpdateLastUsedLayout, setTooltipVisible, setVisible]
  );

  const handleDropdownVisibleChange = useCallback(
    (value) => {
      setVisible(value);
      if (!value) {
        setTooltipVisible(false);
      }
    },
    [setTooltipVisible, setVisible]
  );

  const handleTooltipVisibleChange = useCallback(
    (value) => setTooltipVisible(value),
    [setTooltipVisible]
  );

  if (layouts.length < 2) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <Dropdown
          disabled={disabled}
          menu={{
            className: cn(styles.menu, { [styles.scrollable]: scrollable }),
            items: layouts.map((layout) => ({
              key: layout.id,
              label: layout.name,
              onClick: () => handleLayoutChange(layout),
            })),
            onClick: handleMenuClick,
            selectedKeys: layout ? [String(layout.id)] : [],
          }}
          onOpenChange={handleDropdownVisibleChange}
          open={visible}
          trigger={disabled ? [] : ["click"]}
        >
          <Tooltip
            destroyTooltipOnHide
            onOpenChange={handleTooltipVisibleChange}
            open={!disabled && tooltipVisible && !visible}
            overlayClassName={styles.tooltip}
            placement="top"
            title={intl.formatMessage({
              id: "formLayoutSelector.tooltip",
              defaultMessage: "Switch Layout",
              description: "Switch Layout",
            })}
            trigger={disabled || visible ? [] : ["hover"]}
          >
            <Button className={styles.btn} disabled={disabled} type="text">
              {layout?.name ??
                intl.formatMessage({
                  id: "formLayoutSelector.select",
                  defaultMessage: "(Select layout)",
                  description: "Select Layout",
                })}
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, { entityType }: OwnProps) => ({
  layouts: getLayoutsForEntity(state)(entityType),
});

const mapDispatchToProps = {
  onUpdateLastUsedLayout: updateLastUsedLayout,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormLayoutSelector);
