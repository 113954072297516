import { differenceInDays, startOfDay, startOfToday, subMonths, subYears } from "date-fns/esm";
import invariant from "tiny-invariant";

import SubscriptionInterval from "@mapmycustomers/shared/enum/SubscriptionInterval";
import CurrentPlan from "@mapmycustomers/shared/types/plans/CurrentPlan";
import Price from "@mapmycustomers/shared/types/plans/Price";
import Tier from "@mapmycustomers/shared/types/plans/Tier";

export interface PriceInfo {
  adjustedPrice: Price;
  dueTodayPrice: Price;
  proratedPrice: Price;
  subscriptionPrice: Price;
  switchingToAnnual: boolean;
}

export const ensureSameCurrency = (a: Price, b: Price) => {
  if (a.currency.toLowerCase() !== b.currency.toLowerCase()) {
    invariant(false, `Not matching currencies found: ${JSON.stringify(a)}, ${JSON.stringify(b)}`);
  }
};

/**
 * calculates prorate ratio which is (number of remaining days in billing cycle) / (billing cycle total days)
 * @param currentPlan
 */
export const getProrateRatioDetails = (currentPlan: CurrentPlan) => {
  if (currentPlan.tier.isTrial) {
    return { daysInCurrentBillingCycle: 365, ratio: 1, remainingDays: 365 };
  }
  const nexPaymentDate = startOfDay(currentPlan.nextPaymentDate);
  const isMonthly = currentPlan.tier.interval === SubscriptionInterval.MONTHLY;
  const daysInCurrentBillingCycle = differenceInDays(
    nexPaymentDate,
    startOfDay(
      isMonthly
        ? subMonths(currentPlan.nextPaymentDate, 1)
        : subYears(currentPlan.nextPaymentDate, 1)
    )
  ); // 365|366 for annual or 28-31 for monthly plans
  const remainingDays = differenceInDays(nexPaymentDate, startOfToday());
  return {
    daysInCurrentBillingCycle,
    ratio: remainingDays / daysInCurrentBillingCycle,
    remainingDays,
  };
};

export const calculateSubscriptionPrice = (tier: Tier, licenses: number) => {
  // price for licenses
  return { ...tier.price, value: tier.price.value * licenses };
};

export const calculatePrices = (
  currentPlan: CurrentPlan,
  tier: Tier,
  licenses: number
): PriceInfo => {
  const { ratio: prorateRatio } = getProrateRatioDetails(currentPlan);
  const subscriptionPrice = calculateSubscriptionPrice(tier, licenses);

  const isTrial = currentPlan.tier.isTrial;
  const switchingToAnnual =
    !isTrial &&
    currentPlan.tier.interval === SubscriptionInterval.MONTHLY &&
    tier.interval === SubscriptionInterval.ANNUAL;

  // only calculate adjustedPrice when there's a reason to adjust (i.e. new licenses added)
  const adjustedPrice = { ...tier.price, value: 0 };
  if (switchingToAnnual) {
    adjustedPrice.value = -currentPlan.tier.price.value * currentPlan.licenses * prorateRatio;
  } else if (
    !isTrial &&
    licenses > currentPlan.licenses &&
    currentPlan.tier.price.value !== tier.price.value
  ) {
    adjustedPrice.value =
      (tier.price.value - currentPlan.tier.price.value) * currentPlan.licenses * prorateRatio;
  }

  const proratedPrice = {
    ...tier.price,
    value: switchingToAnnual
      ? tier.price.value * licenses
      : tier.price.value * (licenses - currentPlan.licenses) * prorateRatio,
  };
  const dueTodayPrice = { ...adjustedPrice, value: adjustedPrice.value + proratedPrice.value };

  return { adjustedPrice, dueTodayPrice, proratedPrice, subscriptionPrice, switchingToAnnual };
};
