import React, { useCallback } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";

import CompanyAssociation from "@app/component/associations/CompanyAssociation";

import { addCompanyChildCompanies } from "../store/actions";

interface Props {
  addCompanyChildCompanies: typeof addCompanyChildCompanies.request;
  createdRecord: Company | Deal | Person;
  onHide: (updated?: boolean) => void;
}

const ChildCompanies: React.FC<Props> = ({ addCompanyChildCompanies, createdRecord, onHide }) => {
  const intl = useIntl();

  const handleSelectChildrenCompanies = useCallback(
    (companiesIds: Company["id"][]) => {
      addCompanyChildCompanies({ id: createdRecord.id, companiesIds });
      onHide();
    },
    [addCompanyChildCompanies, createdRecord, onHide]
  );

  return (
    <CompanyAssociation
      associateWith={createdRecord.id}
      childSelect
      entityType={EntityType.COMPANY}
      multiselect
      onHide={onHide}
      onSelect={handleSelectChildrenCompanies}
      title={intl.formatMessage({
        id: "companyRecordPane.relationships.childCompanies.modalTitle",
        defaultMessage: "Select Child Company",
        description: "Title of Select Child modal in Company Record pane",
      })}
    />
  );
};

const mapDispatchToProps = {
  addCompanyChildCompanies: addCompanyChildCompanies.request,
};

export default connect(null, mapDispatchToProps)(ChildCompanies);
