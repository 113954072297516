import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const componentState = (state: RootState) => state.component.entityAssociations;

export const isLoading = createSelector(componentState, ({ loading }) => loading);
export const getCompanies = createSelector(componentState, ({ companies }) => companies);
export const getDeals = createSelector(componentState, ({ deals }) => deals);
export const getPeople = createSelector(componentState, ({ people }) => people);
export const getLocatedEntities = createSelector(componentState, ({ located }) => located);

export const getTotalDeals = createSelector(componentState, ({ totalDeals }) => totalDeals);
export const getFilteredDealsCount = createSelector(
  componentState,
  ({ filteredDeals }) => filteredDeals
);
export const getTotalCompanies = createSelector(
  componentState,
  ({ totalCompanies }) => totalCompanies
);
export const getFilteredCompaniesCount = createSelector(
  componentState,
  ({ filteredCompanies }) => filteredCompanies
);
export const getTotalPeople = createSelector(componentState, ({ totalPeople }) => totalPeople);
export const getFilteredPeopleCount = createSelector(
  componentState,
  ({ filteredPeople }) => filteredPeople
);
