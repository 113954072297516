import { defineMessages, IntlShape } from "react-intl";

import NumberFormatType from "@mapmycustomers/shared/enum/NumberFormatType";

const messages = defineMessages({
  [NumberFormatType.NUMERIC]: {
    id: "numberFormatType.number",
    defaultMessage: "Number",
    description: "Name of the numeric type number on the Manage Fields",
  },
  [NumberFormatType.PERCENTAGE]: {
    id: "numberFormatType.percent",
    defaultMessage: "Percent",
    description: "Name of the numeric type percent on the Manage Fields",
  },
});

const getNumberFormatTypeDisplayName = (intl: IntlShape, formatType: NumberFormatType): string =>
  intl.formatMessage(messages[formatType]);

export default getNumberFormatTypeDisplayName;
