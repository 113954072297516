import { defineMessages } from "react-intl";

const messages = defineMessages({
  address: {
    id: "createEntity.details.address",
    defaultMessage: "Address",
    description: "Company details address title",
  },
  alert: {
    id: "createEntity.details.address.alert",
    defaultMessage: "Manual changes to coordinates might update the existing street address.",
    description: "Change coordinates alert",
  },
  cancel: {
    id: "createEntity.details.address.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button text",
  },
  latitude: {
    id: "createEntity.details.address.latitude",
    defaultMessage: "Latitude",
    description: "Latitude field label at Company Edit Pane",
  },
  longitude: {
    id: "createEntity.details.address.longitude",
    defaultMessage: "Longitude",
    description: "Longitude field label at Company Edit Pane",
  },
  ok: {
    id: "createEntity.details.address.ok",
    defaultMessage: "Okay",
    description: "Okay button text",
  },
  useCoordinates: {
    id: "createEntity.details.address.useCoordinates",
    defaultMessage: "Set via coordinates",
    description: "`Set location via coordinates` button at Create Modal",
  },
});

export default messages;
