import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import { AnyEntity, EntityTypeSupportingGroups } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Group from "@mapmycustomers/shared/types/entity/Group";
import User from "@mapmycustomers/shared/types/User";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";

// used on app loading to fetch all groups
export const fetchAllGroups = createAsyncAction(
  "@groups/fetchAll/request",
  "@groups/fetchAll/success",
  "@groups/fetchAll/failure"
)<void, void, void>();

export const fetchGroups = createAsyncAction(
  "@groups/fetch/request",
  "@groups/fetch/success",
  "@groups/fetch/failure"
)<
  { entityType: EntityTypeSupportingGroups; request?: Partial<ListRequest> },
  { entityType: EntityTypeSupportingGroups; groups: Group[] },
  { entityType: EntityTypeSupportingGroups; error: unknown }
>();

export const reloadGroups = createAction("@groups/reloadGroups")<{
  entityType: EntityTypeSupportingGroups;
}>();

export const createGroup = createAsyncAction(
  "@groups/create/request",
  "@groups/create/success",
  "@groups/create/failure"
)<
  {
    activityTypesIds: ActivityType["id"][];
    cadenceInterval?: number;
    color?: AnyColor;
    entityType: EntityTypeSupportingGroups;
    name: string;
    onSuccess: (newGroup: Group) => void;
    userIdsToShareWith?: User["id"][];
  },
  { entityType: EntityTypeSupportingGroups; group: Group },
  unknown
>();

export const updateGroup = createAsyncAction(
  "@groups/update/request",
  "@groups/update/success",
  "@groups/update/failure"
)<
  {
    entityType: EntityTypeSupportingGroups;
    group: Group;
    noNotification?: boolean;
    onSuccess?: (entityType: EntityTypeSupportingGroups, updatedGroup: Group) => void;
  },
  { entityType: EntityTypeSupportingGroups; group: Group },
  unknown
>();

export const deleteGroup = createAsyncAction(
  "@groups/delete/request",
  "@groups/delete/success",
  "@groups/delete/failure"
)<
  {
    entityType: EntityTypeSupportingGroups;
    groupId: Group["id"];
    onSuccess?: (entityType: EntityTypeSupportingGroups, groupId: Group["id"]) => void;
  },
  { entityType: EntityTypeSupportingGroups; groupId: Group["id"] },
  unknown
>();

export const updateGroupSharing = createAsyncAction(
  "@groups/updateSharing/request",
  "@groups/updateSharing/success",
  "@groups/updateSharing/failure"
)<
  {
    entityType: EntityTypeSupportingGroups;
    group: Pick<Group, "id" | "userIds">;
    onSuccess?: (entityType: EntityTypeSupportingGroups, updatedGroup: Group) => void;
    userIdsToShareWith: User["id"][];
  },
  { entityType: EntityTypeSupportingGroups; group: Group },
  unknown
>();

export const updateEntities = createAsyncAction(
  "@groups/updateEntities/request",
  "@groups/updateEntities/success",
  "@groups/updateEntities/failure"
)<
  {
    entities: AnyEntity[];
    entityType: EntityTypeSupportingGroups;
    groupIdsToAdd?: Array<Group["id"]>;
    groupIdsToDelete?: Array<Group["id"]>;
    onSuccess: () => void;
  },
  void,
  void
>();

export type Actions = ActionType<
  | typeof createGroup
  | typeof deleteGroup
  | typeof fetchAllGroups
  | typeof fetchGroups
  | typeof reloadGroups
  | typeof updateEntities
  | typeof updateGroup
  | typeof updateGroupSharing
>;
