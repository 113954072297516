@use "../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.5rem;
        min-width: 0;
        overflow: auto;
        padding: 0.25rem 0;
        width: 100%;
      }

      .recipients {
        display: flex;
        flex: 1;
        overflow: hidden;
        min-width: 0;
      }

      .extendedContainer {
        width: 100%;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        min-width: 0;
        gap: 0.5rem;
      }

      .email {
        display: flex;
        white-space: nowrap;
        gap: 0.25rem;
        align-items: center;
        //width: fit-content;
        //overflow: hidden;
        //text-overflow: ellipsis;
      }

      .inlineEmail {
        border-bottom: 1px colors.$mmc-primary-100 solid;
        font-size: 0.75rem;
        font-weight: 400;
        margin-right: 0.5rem;
        padding: 0.25rem 0.4rem;
      }

      .inlineEmailDuplicate {
        background: colors.$mmc-yellow-010;
        border-bottom: 1px colors.$mmc-yellow-100 solid;
      }

      .normalEmail {
        font-size: 0.75rem;
        background: colors.$mmc-soft-gray;
        border-radius: 0.25rem;
        border: 1px solid colors.$mmc-accent-gray;
        padding: 0 0.25rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .normalEmailDuplicate {
        border: 1px solid colors.$mmc-yellow-050;
        background: colors.$mmc-yellow-010;
      }

      .more {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        justify-content: center;
        border-radius: 0.25rem;
        border: 1px solid colors.$mmc-primary;
        background-color: colors.$mmc-primary-010;
        padding: 0 0.4rem;
      }

      .warningIcon {
        color: colors.$mmc-yellow-100;
      }

      .moreWithDuplicates {
        border: 1px solid colors.$mmc-yellow-050;
        background-color: colors.$mmc-yellow-010;
      }

      .deleteIcon {
        cursor: pointer;
      }

      .issuePopconfirm {
        width: 16rem;
      }

      .recipientName {
        flex: 1;
      }
      .warningPoints {
        padding-left: 2rem;
      }
    }
  }
}
