import { useSelector } from "react-redux";

import Feature from "@mapmycustomers/shared/enum/Feature";
import { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";

import { getFeatures } from "@app/store/iam";

const useFeatures = () => {
  const features: OrganizationMetaData["features"] = useSelector(getFeatures);
  return {
    features,
    hasAllFeatures: (...query: Feature[]) => query.every((feature) => features?.[feature]?.enabled),
    hasFeature: (feature: Feature) => !!features?.[feature]?.enabled,
  };
};

export default useFeatures;
