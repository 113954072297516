import { loadStripe, Stripe } from "@stripe/stripe-js";
import { all, put, select, takeEvery } from "redux-saga/effects";

import Organization from "@mapmycustomers/shared/types/Organization";
import Plan from "@mapmycustomers/shared/types/Plan";
import User from "@mapmycustomers/shared/types/User";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import configService from "@app/config/ConfigService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getMe, getOrganization } from "@app/store/iam";
import { fetchMe } from "@app/store/iam/actions";
import { getUsers } from "@app/store/members";

import billingAnalytics from "../billingAnalytics";
import buildCurrentPlan from "../util/buildCurrentPlan";
import { convertToTiers } from "../util/tier";

import { initialize, upgrade } from "./actions";

export function* onInitialize() {
  try {
    const [stripe, plansResponse]: [Stripe, ListResponse<Plan>] = yield all([
      loadStripe(configService.getStripeCheckoutKey()),
      callApi("fetchPlans"),
    ]);
    const tiers = convertToTiers(plansResponse.data);

    const currentPlan = buildCurrentPlan(yield select(getMe));

    // offer +1 license by default
    let licenses = currentPlan.licenses + 1;
    // for trial plans offer a number of licenses not less than the number of their users
    if (currentPlan.tier.isTrial) {
      const users: User[] = yield select(getUsers);
      licenses = users.length;
    }

    yield put(initialize.success({ currentPlan, licenses, stripe, tiers }));
  } catch (error) {
    yield put(initialize.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpgrade({ payload }: ReturnType<typeof upgrade.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { licenses, planId, token } = payload;
    yield callApi("upgradeSubscription", org.id, token.id, planId, licenses);
    yield put(fetchMe.request()); // update current user's data
    yield put(upgrade.success({}));
    billingAnalytics.completed("Purchase");
  } catch (error) {
    yield put(upgrade.failure(error));
    yield put(handleError({ error }));
    billingAnalytics.failed("Purchase");
  }
}

export function* billingSaga() {
  yield takeEvery(initialize.request, onInitialize);
  yield takeEvery(upgrade.request, onUpgrade);
}
