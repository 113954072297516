import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import cn from "classnames";

import { getFrequencyPanelConfig } from "@app/store/frequency";
import { RootState } from "@app/store/rootReducer";

import FrequencyPreviewConfig from "../type/FrequencyPreviewConfig";
import getGradientColorBreakPoints from "../util/getGradientColorBreakPoints";

import styles from "./FrequencyBox.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  config: FrequencyPreviewConfig;
}

const FrequencyBox: React.FC<Props> = ({
  children,
  className,
  config: {
    actualFrequencyCriteria: { cadenceInterval },
  },
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const [lightGreenPercentage, yellowPercentage, redPercentage] =
      getGradientColorBreakPoints(cadenceInterval);
    containerRef.current?.style.setProperty("--gradient-red-percentage", `${redPercentage}%`);
    containerRef.current?.style.setProperty("--gradient-yellow-percentage", `${yellowPercentage}%`);
    containerRef.current?.style.setProperty(
      "--gradient-lightGreen-percentage",
      `${lightGreenPercentage}%`
    );
  }, [cadenceInterval]);

  return (
    <div
      className={cn(styles.container, styles.normalGradient, {
        [styles.twoDaysGradient]: (cadenceInterval ?? 0) === 1,
      })}
      ref={containerRef}
    >
      <div className={cn(styles.contentWrapper, className)}>{children}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  config: getFrequencyPanelConfig(state),
});

export default connect(mapStateToProps)(FrequencyBox);
