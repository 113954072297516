import React from "react";

import Button from "antd/es/button";
import { IntlShape } from "react-intl";

import styles from "./getReportCloneSuccessNotificationNode.module.scss";

const getReportCloneSuccessNotificationNode = (
  intl: IntlShape | undefined,
  message: string,
  onViewClick: () => void
) => (
  <div>
    <span>{message}</span>
    {onViewClick && (
      <Button className={styles.action} onClick={onViewClick}>
        {intl?.formatMessage({
          id: "reports.exports.clone.success.action",
          defaultMessage: "View",
          description: "Link for any creating entity modal to view result",
        }) ?? "View"}
      </Button>
    )}
  </div>
);

export default getReportCloneSuccessNotificationNode;
