import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

const getCountryFlag = (countryCode: CountryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export default getCountryFlag;
