import { ActionType, createAction } from "typesafe-actions";

import Address from "@mapmycustomers/shared/types/Address";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";

export const geocodeAddress = createAction("@location/geocodeAddress")<{
  address: Address;
  callback: (result: GeocodeResult) => void;
  failureCallback?: () => void;
}>();

export const reverseGeocodeAddress = createAction("@location/reverseGeocodeAddress")<{
  callback: (result: GeocodeResult) => void;
  coordinates: LongLat;
  failureCallback?: () => void;
}>();

export type Actions = ActionType<typeof geocodeAddress | typeof reverseGeocodeAddress>;
