import React, { memo } from "react";

import { Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";

import AddToRouteModal from "@app/component/AddToRouteModal";

interface Props {
  createdRecord: Company | Person;
  entityType?: EntityType.COMPANY | EntityType.PERSON;
  onHide: (added: boolean) => void;
}

const Routes: React.FC<Props> = ({ createdRecord, entityType, onHide }) => {
  return (
    <AddToRouteModal
      autoAssignOnCreate
      entities={
        entityType === EntityType.COMPANY ? [createdRecord as Company] : [createdRecord as Person]
      }
      entityType={entityType!}
      onHide={onHide}
      visible
    />
  );
};

export default memo(Routes);
