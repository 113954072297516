import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import Divider from "antd/es/divider";
import { useIntl } from "react-intl";

import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";
import { Modal } from "@mapmycustomers/ui";

import { showGlobalCreateActivityModal } from "@app/component/createEditEntity/Activity/store/actions";
import RoundButton from "@app/component/RoundButton";
import { showEntityView } from "@app/store/entityView/actions";
import { RootState } from "@app/store/rootReducer";

import messages from "./messages";
import styles from "./PostCreationModal.module.scss";
import PostCreationModalSelection from "./PostCreationModalSelection";
import { getEntityType, getPrefilledValues } from "./store";
import PostCreationAssociationTypes from "./util/PostCreationAssociationTypes";
import usePostCreationModalState from "./util/usePostCreationModalState";

interface Props {
  entityType?: EntityTypeSupportingPostCreationModal;
  onCreateActivity: typeof showGlobalCreateActivityModal;
  onHide: () => void;
  prefilledValues?: Company | Deal | Person;
  showEntityView: typeof showEntityView;
}

const PostCreationModal: React.FC<Props> = ({
  entityType,
  onCreateActivity,
  onHide,
  prefilledValues,
  showEntityView,
}) => {
  const intl = useIntl();
  const [selectedModal, setSelectedModal] = useState<PostCreationAssociationTypes | undefined>(
    undefined
  );

  const handleHideModal = useCallback(
    (updated?: boolean) => {
      if (updated) {
        onHide();
      } else {
        setSelectedModal(undefined);
      }
    },
    [onHide, setSelectedModal]
  );

  const handleVisibility = useCallback(
    (type: PostCreationAssociationTypes) => {
      if (type === PostCreationAssociationTypes.ADD_ACTIVITY) {
        const activity = {
          startAt: new Date().toISOString(),
          ...(entityType === EntityType.COMPANY ? { account: prefilledValues as Company } : {}),
          ...(entityType === EntityType.PERSON ? { contact: prefilledValues as Person } : {}),
          ...(entityType === EntityType.DEAL ? { deal: prefilledValues as Deal } : {}),
        };
        if (activity) {
          onCreateActivity({ activity });
        }
      }
      setSelectedModal(type);
    },
    [entityType, onCreateActivity, prefilledValues, setSelectedModal]
  );

  const { cards, imageIllustration, imageSource } = usePostCreationModalState(
    intl,
    // EntityType do exist here as this modal will open only on some record creation of some entity type
    entityType!,
    prefilledValues!,
    handleVisibility
  );

  const createdRecord: Company | Deal | Person = useMemo(() => {
    if (entityType === EntityType.COMPANY) {
      return prefilledValues as Company;
    } else if (entityType === EntityType.PERSON) {
      return prefilledValues as Person;
    } else if (entityType === EntityType.DEAL) {
      return prefilledValues as Deal;
    } else {
      return prefilledValues as Company;
    }
  }, [entityType, prefilledValues]);

  const handleEdit = useCallback(() => {
    if (prefilledValues && entityType) {
      showEntityView({
        entityId: prefilledValues?.id,
        entityType: entityType,
      });
    }
  }, [prefilledValues, entityType, showEntityView]);

  return (
    <div>
      <Modal
        className={styles.modal}
        closable={false}
        footer={null}
        visible={true}
        width="clamp(750px, 50vw, 1024px)"
      >
        <>
          <section className={styles.top}>
            <img alt="background" className={styles.backgroundImage} src={imageSource} />
            <div>
              <h3>{intl.formatMessage(messages.title)}</h3>
              <div>
                {intl.formatMessage(messages.subTitle, {
                  link: (text: string) => (
                    <Button className={styles.recordName} onClick={handleEdit} type="link">
                      {text}
                    </Button>
                  ),
                  recordname: prefilledValues?.name,
                })}
              </div>
            </div>
            <div className={styles.image}>
              <img alt="No Data" src={imageIllustration} />
            </div>
          </section>
          <Divider className={styles.divider} />
          <div className={styles.cards}></div>
          <section>
            <h3>{intl.formatMessage(messages.associationTitle)}</h3>
            <div className={styles.cards}>
              {cards.map((card) => {
                return card;
              })}
            </div>
          </section>
          <section className={styles.footer}>
            <RoundButton className={styles.okButton} onClick={onHide}>
              {intl.formatMessage(messages.okText)}
            </RoundButton>
          </section>
        </>
      </Modal>
      {entityType && (
        <PostCreationModalSelection
          createdRecord={createdRecord}
          entityType={entityType}
          handleHideModal={handleHideModal}
          selectedModal={selectedModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  entityType: getEntityType(state),
  prefilledValues: getPrefilledValues(state),
});

const mapDispatchToProps = {
  onCreateActivity: showGlobalCreateActivityModal,
  showEntityView,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCreationModal);
