import Modal from "antd/es/modal";
import { defineMessages, IntlShape } from "react-intl";

import styles from "./GeocodeLimitAlertModal.module.scss";

const messages = defineMessages({
  mmcLimitDescription: {
    id: "geocodeLimitAlertModal.mmcLimitReached.description",
    defaultMessage:
      "We’re experiencing heavy geocoding usage and are unable to build new routes. We apologize for this inconvenience - please try again later.",
    description: "Warning description for mmc limit reached",
  },
  mmcLimitTitle: {
    id: "geocodeLimitAlertModal.mmcLimitReached.title",
    defaultMessage: "Building routes temporarily unavailable",
    description: "Warning title for mmc limit reached",
  },
  orgLimitDescription: {
    id: "geocodeLimitAlertModal.orgLimitReached.description",
    defaultMessage:
      "We’re unable to build a new route until the starting and ending locations on the map can be geocoded. Please try again once your organization’s daily limit resets. ",
    description: "Warning description for org limit reached",
  },
  orgLimitTitle: {
    id: "geocodeLimitAlertModal.orgLimitReached.title",
    defaultMessage: "Your organization has reached its daily geocoding limit",
    description: "Warning title for org limit reached",
  },
});

const showGeocodeLimitAlertModal = (intl: IntlShape, mmcLimitReached: boolean) => {
  Modal.warning({
    className: styles.alert,
    content: intl.formatMessage(
      mmcLimitReached ? messages.mmcLimitDescription : messages.orgLimitDescription
    ),
    title: intl.formatMessage(mmcLimitReached ? messages.mmcLimitTitle : messages.orgLimitTitle),
  });
};

export default showGeocodeLimitAlertModal;
