import React, { useCallback } from "react";

import cn from "classnames";
import { defineMessage, useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { NumberField, NumberFieldComponent } from "@mapmycustomers/ui";

import i18nService from "@app/config/I18nService";

import styles from "./DurationRangeFilter.module.scss";

const doesSupportValue = (value: any, operator: FilterOperator) => {
  return (
    Array.isArray(value) &&
    value.length === 2 &&
    typeof value[0] === "number" &&
    typeof value[1] === "number" &&
    operator === FilterOperator.IN_RANGE
  );
};

const descriptionMessage = defineMessage({
  id: "filters.numberRange.description",
  defaultMessage: "{fieldName} is between {from} and {to}",
  description: "NumberRangeFilter human-readable description",
});

interface DurationRangeFilterProps extends IFilterComponentProps {}

const DurationFilter: IFilterInstance = {
  doesSupportValue,
  getComponent:
    (): React.FC<DurationRangeFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const intl = useIntl();

      const rangeValue =
        Array.isArray(value.value) && value.value.length === 2
          ? value.value
          : [undefined, undefined];

      const setRef = useCallback(
        (ref: null | NumberFieldComponent) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <div className={cn(styles.container, className)}>
          <NumberField
            onChange={(number) => onChange?.({ ...value, value: [number, rangeValue[1]] })}
            ref={setRef}
            size="middle"
            value={rangeValue[0] ?? ""}
          />
          <span className={styles.conjunction}>
            {intl.formatMessage({
              id: "filters.numberRange.conjunction.and",
              defaultMessage: "and",
              description: "Conjunction between from and to fields",
            })}
          </span>
          <NumberField
            onChange={(number) => onChange?.({ ...value, value: [rangeValue[0], number] })}
            size="middle"
            value={rangeValue[1] ?? ""}
          />
          <span className={styles.conjunction}>
            {intl.formatMessage({
              id: "filters.duration.suffix",
              defaultMessage: "mins",
              description: "Mins suffix in duration field filter",
            })}
          </span>
        </div>
      );
    },
  getHumanReadableDescription: (value: SimpleCondition, field: IField) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }
    const [from, to] = value.value;
    return i18nService.formatMessage(
      descriptionMessage,
      `${field.displayName} is between ${from} and ${to}`,
      {
        fieldName: field.displayName,
        from,
        to,
      }
    );
  },
};

export default DurationFilter;
