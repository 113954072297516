import type { Territory } from "@mapmycustomers/shared/types/entity";
import type { TerritoryDetail } from "@mapmycustomers/shared/types/entity/Territory";

// ================================================================================
// We have these functions because Platform requires each path in Polygon shape to
// be closed. Specifically, the first and the last points must be equal.
// In UI it is not needed. Moreover, it makes it harder to edit territory, because
// google maps don't have such requirement and hence there will be two vertices at
// the same point. And if you drag one, the other will stay unchanged.
//
// All methods to fetch/create/update territory must use these methods to apply
// and then revert this change.
// ===============================================================================

export const removeExtraCoordinate = (territory: Territory): Territory => {
  if (territory.territoryDetail.type !== "points") {
    return territory;
  }
  return {
    ...territory,
    territoryDetail: {
      ...territory.territoryDetail,
      points: {
        ...territory.territoryDetail.points,
        // remove last coordinate in each path as it matches the first coordinate
        coordinates: territory.territoryDetail.points.coordinates.map((path) =>
          path.length >= 2 ? path.slice(0, -1) : path
        ),
      },
    },
  };
};

export const addExtraCoordinateToTerritoryDetail = <
  T extends Pick<TerritoryDetail, "points" | "type">
>(
  territoryDetail: T
): T => {
  if (territoryDetail.type !== "points") {
    return territoryDetail;
  }
  return {
    ...territoryDetail,
    points: {
      ...territoryDetail.points,
      // append coordinate in each path to be the same as the first coordinate
      coordinates: territoryDetail.points.coordinates.map((path) =>
        path.length >= 1 ? [...path, path[0]] : path
      ),
    },
  };
};

export const addExtraCoordinate = (territory: Territory): Territory => {
  if (territory.territoryDetail.type !== "points") {
    return territory;
  }
  return {
    ...territory,
    territoryDetail: addExtraCoordinateToTerritoryDetail(territory.territoryDetail),
  };
};
