import { Editor, Element, Transforms } from "slate";

import ElementAlignment from "@mapmycustomers/shared/enum/textEditor/ElementAlignment";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

import { INDENT_MAXIMUM, LIST_TYPES } from "./const";
import findMatchedElementTypes from "./findMatchedElementTypes";

// toggle slate element (paragraph) property.
// type: as replaceable type of block like numeric/bulleted text etc.
// align property: to align paragraph bt one of 4 variants
const toggleBlock = (
  editor: Editor,
  type?: ElementType,
  params?: { align?: ElementAlignment; indentIncrement?: -1 | 1 }
) => {
  const [isActive, elements] = findMatchedElementTypes(editor, type);
  const isList = type && LIST_TYPES.includes(type);

  if (type) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !!(!Editor.isEditor(n) && Element.isElement(n) && n.type && LIST_TYPES.includes(n.type)),
      split: true,
    });
  }

  let newProperties: Partial<Element> = {};
  if (params?.align) {
    newProperties = {
      align: params.align,
    };
  } else if (ElementType.INDENT === type) {
    // we need set new indent value based on maximum of indent values of selected elements
    let indent =
      (elements
        ? Math.max(1, ...elements.flat().map((element) => (element as Element)?.indent ?? 0)) ?? 0
        : 0) + (params?.indentIncrement ?? 1);
    indent = indent < 0 ? 0 : indent > INDENT_MAXIMUM ? INDENT_MAXIMUM : indent;
    newProperties = {
      align: undefined,
      indent,
    };
  } else {
    newProperties = {
      type: isActive ? ElementType.PARAGRAPH : isList ? ElementType.LIST_ITEM : type,
    };
  }

  Transforms.setNodes<Element>(editor, newProperties);
  if (!isActive && isList) {
    const block = { children: [], type: type };
    Transforms.wrapNodes(editor, block as Element);
  }
};

export default toggleBlock;
