const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
const EMAIL_REGEX = /\S+@\S+\.\S+/g;
const PHONE_REGEX = /(\+?\d{1,2}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/g;
export const DYNAMIC_VAR_REGEX =
  /{{(std|cf):(?<entityType>(accounts|contacts)):(?<fieldName>[a-zA-Z.\-0-9]+)}}/g;
const TOTAL_REGEX = new RegExp(
  `(?<url>${URL_REGEX.source})|(?<email>${EMAIL_REGEX.source})|(?<phone>${PHONE_REGEX.source})|(?<dynamic_var>${DYNAMIC_VAR_REGEX.source})`,
  "g"
);

const findThingsInText = (text: string) => {
  return Array.from(text.matchAll(TOTAL_REGEX)).map((match) => ({
    index: match.index,
    length: match[0].length,
    text: match[0],
    type: Object.keys(match.groups!).find((k) => !!match.groups![k]),
  }));
};

export default findThingsInText;
