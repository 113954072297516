import React from "react";

import PopConfirm from "antd/es/popconfirm";
import { useIntl } from "react-intl";

interface Props {
  onConfirm: () => void;
}

export const DownloadConfirmation: React.FC<Props> = ({ children, onConfirm }) => {
  const intl = useIntl();

  return (
    <PopConfirm
      cancelText={intl.formatMessage({
        id: "dealListModalForCharts.downloadDeals.no",
        defaultMessage: "No",
        description: "Download Cancel button on the list modal",
      })}
      okText={intl.formatMessage({
        id: "dealListModalForCharts.downloadDeals.yes",
        defaultMessage: "Yes, download",
        description: "Download Confirm button on the list modal",
      })}
      onConfirm={onConfirm}
      placement="topRight"
      title={intl.formatMessage({
        id: "dealListModalForCharts.downloadDeals.confirm",
        defaultMessage: "Are you sure you want to download this data as a .csv?",
        description: "Download Deals confirm on the list modal",
      })}
    >
      {children}
    </PopConfirm>
  );
};
