interface CommonError {
  message: string;
}

export interface ValidationError extends CommonError {
  code: string;
  field: string;
  message: string;
}

interface BackendError extends CommonError {
  code: string;
  message: string;
  stacktrace?: string;
  validationErrors?: ValidationError[];
}

type Error = BackendError | BackendError[];

export default class ApiError extends Error {
  status: number | undefined;
  errors: BackendError[];

  constructor(message: string, errors: Error = []) {
    super(message);
    this.errors = Array.isArray(errors) ? errors : [errors];
  }
}
