import React from "react";

import cn from "classnames";
import { useIntl } from "react-intl";

import Email from "@mapmycustomers/shared/types/email/Email";

import styles from "./EmailSubject.module.scss";

interface Props {
  className?: string;
  subject: Email["subject"];
}

const EmailSubject: React.FC<Props> = ({ className, subject }) => {
  const intl = useIntl();

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.subject}>
        {intl.formatMessage({
          id: "email.parts.subject",
          defaultMessage: "Subject",
          description: "Email subject",
        })}
      </div>
      <div>{subject}</div>
    </div>
  );
};

export default EmailSubject;
