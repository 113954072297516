import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Deal } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";

import ChangeAssociationsPayload from "@app/store/associations/ChangeAssociationsPayload";
import { NextAssociationsState } from "@app/store/associations/getNextAssociationsState";
import FileListItem from "@app/types/FileListItem";

import UpdateDealPayload from "../types/UpdateDealPayload";

export const initializeEditStageModal = createAction(
  "@component/editStageModal/initialize"
)<Deal>();

export const updateDeal = createAsyncAction(
  "@component/editStageModal/updateDeal/request",
  "@component/editStageModal/updateDeal/success",
  "@component/editStageModal/updateDeal/failure"
)<UpdateDealPayload, Deal, void>();

export const changeAssociatedEntities = createAsyncAction(
  "@component/editStageModal/changeAssociatedEntities/request",
  "@component/editStageModal/changeAssociatedEntities/success",
  "@component/editStageModal/changeAssociatedEntities/failure"
)<
  Pick<ChangeAssociationsPayload, "company" | "person">,
  Pick<NextAssociationsState, "availableCompanies" | "availablePeople">,
  void
>();

export const uploadDealFiles = createAsyncAction(
  "@component/editStageModal/uploadDealFiles/request",
  "@component/editStageModal/uploadDealFiles/success",
  "@component/editStageModal/uploadDealFiles/failure"
)<
  {
    callback?: (filesList: FileListItem[]) => void;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const removeDealFile = createAsyncAction(
  "@component/editStageModal/removeDealFile/request",
  "@component/editStageModal/removeDealFile/success",
  "@component/editStageModal/removeDealFile/failure"
)<RawFile, RawFile["id"], void>();

export const clearAllUploadedDealFiles = createAction(
  "@component/editStageModal/clearAllUploadedDealFiles"
)<void>();

export type Actions = ActionType<
  | typeof changeAssociatedEntities
  | typeof clearAllUploadedDealFiles
  | typeof initializeEditStageModal
  | typeof removeDealFile
  | typeof updateDeal
  | typeof uploadDealFiles
>;
