const messages = {
  "ui.emailBody.imageModal.description":
    "Alt text is a brief image description that helps visually impaired users via screen readers and appears if images don't load.",
  "ui.emailBody.imageModal.editAlt": "Edit alt text",
  "ui.emailBody.imageModal.input": "Description",
  "ui.emailBody.imageModal.ok": "Okay",
  "ui.emailBody.imageModal.remove": "Remove",
  "ui.emailBody.imageModal.size.bestFit": "Best Fit",
  "ui.emailBody.imageModal.size.original": "Original Size",
  "ui.emailBody.imageModal.size.small": "Small",
  "ui.emailBody.imageModal.title": "Alt Text",
  "ui.emailBody.toolbar.align": "Align",
  "ui.emailBody.toolbar.align.center.mac": "Align Center (⌘⇧C)",
  "ui.emailBody.toolbar.align.center.win": "Align Center (Ctrl+Shift+C)",
  "ui.emailBody.toolbar.align.justify.mac": "Align Justify (⌘⇧J)",
  "ui.emailBody.toolbar.align.justify.win": "Align Justify (Ctrl+Shift+J)",
  "ui.emailBody.toolbar.align.left.mac": "Align left (⌘⇧L)",
  "ui.emailBody.toolbar.align.left.win": "Align left (Ctrl+Shift+L)",
  "ui.emailBody.toolbar.align.right.mac": "Align Right (⌘⇧R)",
  "ui.emailBody.toolbar.align.right.win": "Align Right (Ctrl+Shift+R)",
  "ui.emailBody.toolbar.bold.mac": "Bold (⌘B)",
  "ui.emailBody.toolbar.bold.win": "Bold (Ctrl+B)",
  "ui.emailBody.toolbar.bulleted.mac": "Bulleted (⌘⇧8)",
  "ui.emailBody.toolbar.bulleted.win": "Bulleted (Ctrl+Shift+8)",
  "ui.emailBody.toolbar.image.mac": "Insert Image (⌘⇧I)",
  "ui.emailBody.toolbar.image.win": "Insert Image (Ctrl+Shift+I)",
  "ui.emailBody.toolbar.indentLess.mac": "Indent less (⌘[)",
  "ui.emailBody.toolbar.indentLess.win": "Indent less (Ctrl+[)",
  "ui.emailBody.toolbar.indentMore.mac": "Indent more (⌘])",
  "ui.emailBody.toolbar.indentMore.win": "Indent more (Ctrl+])",
  "ui.emailBody.toolbar.italic.mac": "Italic (⌘I)",
  "ui.emailBody.toolbar.italic.win": "Italic (Ctrl+I)",
  "ui.emailBody.toolbar.numbered.mac": "Numbered (⌘⇧7)",
  "ui.emailBody.toolbar.numbered.win": "Numbered (Ctrl+Shift+7)",
  "ui.emailBody.toolbar.redo.mac": "Redo (⌘⇧Z)",
  "ui.emailBody.toolbar.redo.win": "Redo (Ctrl+Shift+X)",
  "ui.emailBody.toolbar.removeFormatting.mac": "Remove formatting  (⌘\\)",
  "ui.emailBody.toolbar.removeFormatting.win": "Remove formatting  (Ctrl+\\)",
  "ui.emailBody.toolbar.size.mac": "Size (⌘⇧-, ⌘⇧+)",
  "ui.emailBody.toolbar.size.win": "Size (Ctrl+Shift+(+), Ctrl+Shift+(-))",
  "ui.emailBody.toolbar.strikethrough.mac": "Strikethrough (⌘⇧X)",
  "ui.emailBody.toolbar.strikethrough.win": "Strikethrough (Ctrl+Shift+X)",
  "ui.emailBody.toolbar.textColor": "Text color",
  "ui.emailBody.toolbar.underline.mac": "Underline (⌘U)",
  "ui.emailBody.toolbar.underline.win": "Underline (Ctrl+U)",
  "ui.emailBody.toolbar.undo.mac": "Undo (⌘Z)",
  "ui.emailBody.toolbar.undo.win": "Undo (Ctrl+X)",
} as const;

export default messages;
