import React, { CSSProperties } from "react";

import { RenderElementProps } from "slate-react/dist/components/editable";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import { DynamicVarElement } from "../../type/slate-types";
import { cssAlignMap } from "../type/AlignElementType";
import DynamicVarVariant from "../type/DynamicVarVariant";

import DynamicVar from "./DynamicVars/DynamicVar";
import Image from "./Image/Image";

interface EditorElementProps extends RenderElementProps {
  dynamicVarVariants?: DynamicVarVariant[];
  entityType?: EntityTypesSupportedByEmailFeature;
}

const EditorElement: React.FC<EditorElementProps> = ({
  attributes,
  children,
  dynamicVarVariants,
  element,
  entityType,
}) => {
  const style = {
    ...(element.align ? { textAlign: cssAlignMap[element.align]! } : {}),
    ...(element.indent ? { marginLeft: `${element.indent}rem` } : {}),
  } as CSSProperties;

  switch (element.type) {
    case ElementType.IMAGE:
      return (
        <Image attributes={attributes} element={element}>
          {children}
        </Image>
      );
    case ElementType.BULLETED_LIST:
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );

    case ElementType.LIST_ITEM:
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case ElementType.NUMBERED_LIST:
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case ElementType.DYNAMIC_VAR:
      return (
        <DynamicVar
          dynamicVarVariants={dynamicVarVariants}
          entityType={entityType}
          text={(element as DynamicVarElement).value}
        >
          {children}
        </DynamicVar>
      );
  }

  return (
    <p style={style} {...attributes}>
      {children}
    </p>
  );
};

export default EditorElement;
