import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import { addDays, isToday } from "date-fns/esm";
import { useIntl } from "react-intl";

import FrequencyActivityAction from "@mapmycustomers/shared/enum/frequency/FrequencyActivityAction";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import { Activity, Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import CreateActivityModal from "@app/component/createEditEntity/Activity/CreateActivityModal";
import DropdownButton from "@app/component/DropdownButton";
import { getFrequencyActivityAction } from "@app/scene/map/store/selectors";
import { updateMetadata } from "@app/store/iam/actions";
import { RootState } from "@app/store/rootReducer";
import FrequencyCriteria from "@app/types/frequncy/FrequencyCriteria";
import HideAction from "@app/types/HideCallback";
import useAnalytics from "@app/util/contexts/useAnalytics";
import doesActivityMeetFrequencyCriteria from "@app/util/frequency/doesActivityMeetFrequencyCriteria";
import { parseApiDate } from "@app/util/parsers";
import { getFrequencyActivityActionDisplayName } from "@app/util/ui";

import AddToRouteModal from "../../AddToRouteModal";
import ActivityScheduled from "../ActivityScheduled";
import useFrequencyContext from "../FrequencyPanel/util/useFrequencyContext";
import FrequencyEntity from "../type/FrequencyEntity";

interface Props {
  criteria: FrequencyCriteria;
  entity: FrequencyEntity;
  onUpdateMetadata: typeof updateMetadata.request;
  savedAction: FrequencyActivityAction;
}

const ActivityActions: React.FC<Props> = ({ criteria, entity, onUpdateMetadata, savedAction }) => {
  const intl = useIntl();
  const [routeModalVisible, showRouteModal, hideRouteModal] = useBoolean();
  const [activityScheduledVisible, setActivityScheduledVisibility] = useState<boolean>(false);
  const [createActivityModalVisible, showCreateActivityModal, hideCreateActivityModal] =
    useBoolean();
  const { onViewActivityHistory } = useFrequencyContext();

  const analytics = useAnalytics();

  const isDeal = entity.entity === EntityType.DEAL;

  const filteredSavedAction =
    isDeal && savedAction === FrequencyActivityAction.ADD_TO_ROUTE
      ? FrequencyActivityAction.SCHEDULE_ACTIVITY
      : savedAction;

  const modifiedActualFrequencyCriteria = useMemo(
    () => ({
      ...criteria,
      dueDate: addDays(criteria.dueDate, isToday(criteria.dueDate) ? 1 : 0),
    }),
    [criteria]
  );

  const handleClickOnAction = useCallback(
    (action: FrequencyActivityAction, saveChoice: boolean = true) => {
      analytics.clicked([
        action === FrequencyActivityAction.SCHEDULE_ACTIVITY
          ? "Schedule Activity"
          : action === FrequencyActivityAction.VIEW_ACTIVITY_HISTORY
          ? "View Activity History"
          : "Add To Route",
      ]);
      if (saveChoice) {
        onUpdateMetadata({ lastFrequencyActivityAction: action });
      }
      (action === FrequencyActivityAction.SCHEDULE_ACTIVITY
        ? showCreateActivityModal
        : action === FrequencyActivityAction.VIEW_ACTIVITY_HISTORY
        ? onViewActivityHistory
        : showRouteModal)?.();
    },
    [analytics, onUpdateMetadata, onViewActivityHistory, showCreateActivityModal, showRouteModal]
  );

  const menu = useMemo(
    () => ({
      items: [
        ...(isDeal ? [] : [FrequencyActivityAction.ADD_TO_ROUTE]),
        FrequencyActivityAction.VIEW_ACTIVITY_HISTORY,
        FrequencyActivityAction.SCHEDULE_ACTIVITY,
      ]
        .filter((action) => action !== filteredSavedAction)
        .map((action) => ({
          key: action,
          label: getFrequencyActivityActionDisplayName(intl, action),
          onClick: () => handleClickOnAction(action),
        })),
    }),
    [handleClickOnAction, intl, isDeal, filteredSavedAction]
  );

  const handleHideActivityCreation: HideAction<Activity> = useCallback(
    (updated?: boolean, activity?: Activity) => {
      hideCreateActivityModal();
      if (activity?.startAt) {
        setActivityScheduledVisibility(
          doesActivityMeetFrequencyCriteria(
            parseApiDate(activity.startAt),
            activity.crmActivityType?.id,
            modifiedActualFrequencyCriteria
          )
        );
      }
    },
    [hideCreateActivityModal, modifiedActualFrequencyCriteria, setActivityScheduledVisibility]
  );

  if (activityScheduledVisible) {
    return <ActivityScheduled />;
  }

  return (
    <>
      <DropdownButton
        menu={menu}
        onClick={() => handleClickOnAction(filteredSavedAction, false)}
        type={entity.cadenceStatus === FrequencyStatus.UP_TO_DATE ? "default" : "primary"}
      >
        {getFrequencyActivityActionDisplayName(intl, filteredSavedAction)}
      </DropdownButton>
      {routeModalVisible && (
        <AddToRouteModal
          autoAssignOnCreate
          entities={[entity] as Company[] | Person[]}
          entityType={(entity as Company | Person).entity}
          onHide={hideRouteModal}
          visible
        />
      )}
      {createActivityModalVisible && (
        <CreateActivityModal
          defaultStartAt={criteria.dueDate}
          fixedCompany={
            entity.entity === EntityType.COMPANY
              ? (entity as Company)
              : entity.entity === EntityType.DEAL
              ? (entity as Deal).account
              : entity.entity === EntityType.PERSON
              ? ((entity as Person).accounts[0] as Company | undefined)
              : undefined
          }
          fixedDeal={entity.entity === EntityType.DEAL ? (entity as Deal) : undefined}
          fixedPerson={
            entity.entity === EntityType.PERSON
              ? (entity as Person)
              : entity.entity === EntityType.DEAL
              ? (entity as Deal).contact
              : undefined
          }
          frequencyCriteria={modifiedActualFrequencyCriteria}
          onHide={handleHideActivityCreation}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  savedAction: getFrequencyActivityAction(state) ?? FrequencyActivityAction.SCHEDULE_ACTIVITY,
});

const mapDispatchToProps = {
  onUpdateMetadata: updateMetadata.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityActions);
