import { addDays, addMonths, addWeeks } from "date-fns/esm";
import { IntlShape } from "react-intl";

import PostponedDateGetter from "@mapmycustomers/shared/types/entity/activities/PostponedDateGetter";

import Period from "../enum/Period";

const postponeConfig: { amount: number; unit: Period }[] = [
  { amount: 1, unit: Period.DAYS },
  { amount: 2, unit: Period.DAYS },
  { amount: 3, unit: Period.DAYS },
  { amount: 1, unit: Period.WEEKS },
  { amount: 2, unit: Period.WEEKS },
  { amount: 1, unit: Period.MONTHS },
  { amount: 2, unit: Period.MONTHS },
];

const getPostponeValue = (dateToStart: Date | number, periodKey: Period, value: number): Date => {
  switch (periodKey) {
    case Period.DAYS:
      return addDays(dateToStart, value);
    case Period.WEEKS:
      return addWeeks(dateToStart, value);
    default:
      return addMonths(dateToStart, value);
  }
};

export const getPostponeMenuItems = (
  intl: IntlShape,
  isOverdue: boolean,
  onClick: (getPostponedDate: PostponedDateGetter, period: string) => void
) =>
  postponeConfig.map(({ amount, unit }) => {
    const label = intl.formatMessage(
      {
        id: "activity.postpone.period",
        defaultMessage:
          "{amount} {unit, select, days {{amount, plural, one {day} other {days}}} weeks {{amount, plural, one {week} other {weeks}}} months {{amount, plural, one {month} other {months}}} other {}} {isOverdue, select, true {from now} other {}}",
        description: "A label for postpone options",
      },
      { amount, isOverdue, unit }
    );

    return {
      label,
      onClick: () =>
        onClick((dateToStart: Date | number) => getPostponeValue(dateToStart, unit, amount), label),
    };
  });
