import {
  AlgorithmInput,
  AlgorithmOptions,
  AlgorithmOutput,
} from "@app/util/clustering/types/algorithm";
import { filterMarkersToPaddedViewport } from "@app/util/clustering/utils";

import Cluster from "../types/Cluster";

import AbstractAlgorithm from "./AbstractAlgorithm";

export interface ViewportAlgorithmOptions extends AlgorithmOptions {
  /**
   * The number of pixels to extend beyond the viewport bounds when filtering
   * markers prior to clustering.
   */
  viewportPadding?: number;
}

/**
 * Abstract viewport algorithm proves a class to filter markers by a padded
 * viewport. This is a common optimization.
 *
 * @hidden
 */
export abstract class AbstractViewportAlgorithm<T> extends AbstractAlgorithm<T> {
  protected viewportPadding = 60;

  constructor({ viewportPadding = 60, ...options }: ViewportAlgorithmOptions) {
    super(options);
    this.viewportPadding = viewportPadding;
  }
  public calculate({ coordinateGetter, items, map }: AlgorithmInput<T>): AlgorithmOutput<T> {
    if ((map.getZoom() ?? 0) >= this.maxZoom) {
      return {
        changed: false,
        clusters: this.noop({ coordinateGetter, items }),
      };
    }

    return {
      clusters: this.cluster({
        coordinateGetter,
        items: filterMarkersToPaddedViewport(map, items, coordinateGetter, this.viewportPadding),
        map,
      }),
    };
  }
  protected abstract cluster({ items, map }: AlgorithmInput<T>): Cluster<T>[];
}

export default AbstractViewportAlgorithm;
