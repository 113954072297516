import React, { useMemo } from "react";

import Button from "antd/es/button";
import { ColumnsType } from "antd/es/table/interface";
import { IntlShape } from "react-intl";

import { EntityType, Group } from "@mapmycustomers/shared/types/entity";
import { HasActivities } from "@mapmycustomers/shared/types/entity/activities/HasActivities";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";
import LongContentParagraph from "@app/component/typography/LongContentParagraph";
import { formatRawDate } from "@app/util/formatters";
import { getEntityTypeDisplayName } from "@app/util/ui";

import FrequencyInterval from "../../frequency/FrequencyInterval";

import styles from "./EntitiesTable.module.scss";

const useColumns = (
  intl: IntlShape,
  onAddActivity: (entity: OutOfCadenceEntity) => void
): ColumnsType<OutOfCadenceEntity> =>
  useMemo(() => {
    return [
      {
        dataIndex: "name",
        key: "name",
        title: intl.formatMessage({
          id: "activities.calendarView.suggestions.cadence.modal.columns.name",
          defaultMessage: "Name",
          description: "Name column label in Cadence modal in Activities Calendar view",
        }),
      },
      {
        dataIndex: "entityType",
        key: "entityType",
        render: (entityType: EntityType) => (
          <div className={styles.recordType}>
            <EntityTypeShapedIcon
              containerClassName={styles.recordTypeIcon}
              entityType={entityType}
            />
            {getEntityTypeDisplayName(intl, entityType, { lowercase: false })}
          </div>
        ),
        title: intl.formatMessage({
          id: "activities.calendarView.suggestions.cadence.modal.columns.type",
          defaultMessage: "Record Type",
          description: "Type column label in Cadence modal in Activities Calendar view",
        }),
        width: "20rem",
      },
      {
        dataIndex: "groups",
        key: "cadenceInterval",
        render: (groups: Group[]) => (
          <div className={styles.groups}>
            <LongContentParagraph rows={2}>
              {groups
                .filter((g) => !!g.cadenceInterval)
                .sort(nameComparator)
                .map((group) => (
                  <FrequencyInterval
                    frequencyInterval={group.cadenceInterval}
                    groupColor={group.color}
                    isGroup
                    key={group.id}
                  />
                ))}
            </LongContentParagraph>
          </div>
        ),
        title: intl.formatMessage({
          id: "activities.calendarView.suggestions.cadence.modal.columns.interval",
          defaultMessage: "Frequency Interval",
          description: "Cadence Interval column label in Cadence modal in Activities Calendar view",
        }),
        // width: "10%",
      },
      {
        dataIndex: "lastCompletedActivity",
        key: "lastCompletedActivity",
        render: (lastCompletedActivity: HasActivities["lastCompletedActivity"]) => (
          <div className={styles.lastContact}>
            {lastCompletedActivity && Object.keys(lastCompletedActivity).length
              ? formatRawDate(lastCompletedActivity.completedAt, "PPp")
              : intl.formatMessage({
                  id: "activities.calendarView.suggestions.cadence.modal.columns.lastContact.never",
                  defaultMessage: "No activity yet",
                  description: "Last Contact column content when there's no last activity",
                })}
          </div>
        ),
        title: intl.formatMessage({
          id: "activities.calendarView.suggestions.cadence.modal.columns.lastContact",
          defaultMessage: "Last Contact",
          description: "Last Contact column label in Cadence modal in Activities Calendar view",
        }),
        // width: "10%",
      },
      {
        dataIndex: "actions",
        key: "actions",
        render: (_: any, entity: OutOfCadenceEntity) => (
          <div className={styles.actions}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onAddActivity(entity);
              }}
              type="primary"
            >
              {intl.formatMessage({
                id: "activities.calendarView.suggestions.cadence.modal.columns.actions.add",
                defaultMessage: "Add activity",
                description:
                  "Last Contact column label in Cadence modal in Activities Calendar view",
              })}
            </Button>
          </div>
        ),
      },
    ];
  }, [intl, onAddActivity]);

export default useColumns;
