const toggleItemInSet = <T>(set: Set<T>, item: T, selected?: boolean): Set<T> => {
  // toggle item if selected is undefined
  if (selected === undefined) {
    selected = !set.has(item);
  }
  if (selected ? set.has(item) : !set.has(item)) {
    return set; // no changes needed
  }
  const result = new Set(set);
  if (selected) {
    result.add(item);
  } else {
    result.delete(item);
  }
  return result;
};

export const toggleMultiplyItemsInSet = <T>(
  set: Set<T>,
  items: T[],
  selected?: boolean
): Set<T> => {
  const result = new Set(set);
  items.forEach((item) => {
    if (selected === undefined) {
      selected = !set.has(item);
    }

    if (selected) {
      result.add(item);
    } else {
      result.delete(item);
    }
  });
  return result;
};

export default toggleItemInSet;
