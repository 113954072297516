import { defineMessages, IntlShape } from "react-intl";

import { GeoStatus } from "@mapmycustomers/shared/types/base/Located";

const messages = defineMessages<string>({
  [GeoStatus.GEOCODING_FUNCTIONS_NOT_CALLED]: {
    id: "createEntity.geocodeError.notGeocoded",
    defaultMessage:
      "Address invalid as both the street address and city are missing. Please update the address below or manually drop the pin on the map.",
    description: "Preview entity pane - geocode info - Geocoding functions not called",
  },
  [GeoStatus.NO_PROVIDER]: {
    id: "createEntity.geocodeError.noProvider",
    defaultMessage:
      "We were unable to place on map due to issues with our mapping provider. Please check back later.",
    description: "Entity creation - geocode error - no Provider",
  },
  [GeoStatus.ZERO_RESULTS]: {
    id: "createEntity.geocodeError.zeroResults",
    defaultMessage:
      "Address invalid. Please update the record with a valid address to see this record as a pin on the map.",
    description: "Entity creation - geocode error - zero results",
  },
});

const getCreationGeocodeErrorMessage = (
  status: GeoStatus | null,
  intl?: IntlShape
): string | undefined => {
  if (status && messages[status]) {
    return intl?.formatMessage(messages[status]);
  }
  return undefined;
};

export default getCreationGeocodeErrorMessage;
