import { connect } from "react-redux";

import FileUploader from "@app/component/FileUploader";
import { RootState } from "@app/store/rootReducer";

import {
  getAssociatedFiles,
  getFilePreview,
  getFilePreviewId,
  isFilePreviewLoading,
  isFilesUploading,
} from "../store";
import {
  clearAllUploadedCreateEditActivityFiles,
  downloadActivityFile,
  fetchFilePreview,
  removeCreateEditActivityFile,
  uploadCreateEditActivityFiles,
} from "../store/actions";

const mapStateToProps = (state: RootState) => ({
  associatedFiles: getAssociatedFiles(state),
  filePreview: getFilePreview(state),
  filePreviewId: getFilePreviewId(state),
  filePreviewLoading: isFilePreviewLoading(state),
  fileUploading: isFilesUploading(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedCreateEditActivityFiles,
  onDownload: downloadActivityFile,
  onFetchFilePreview: fetchFilePreview.request,
  onFileUpload: uploadCreateEditActivityFiles.request,
  onRemoveFile: removeCreateEditActivityFile.request,
};

const File = connect(mapStateToProps, mapDispatchToProps)(FileUploader);
export default File;
