import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const manageFieldsState = (state: RootState) => state.scene.settings.manageFields;

export const isLoading = createSelector(manageFieldsState, ({ loading }) => loading);
export const isArchiveMode = createSelector(manageFieldsState, ({ archive }) => archive);
export const isEditedFormVisible = createSelector(
  manageFieldsState,
  ({ editedField }) => !!editedField
);
export const isEditedFieldLoading = createSelector(
  manageFieldsState,
  ({ editedField }) => !!editedField?.loading
);
export const isEditedCustomField = createSelector(
  manageFieldsState,
  ({ editedField }) => !!editedField?.customField
);

export const getViewState = createSelector(manageFieldsState, ({ viewState }) => viewState);

export const getActiveEntityType = createSelector(
  manageFieldsState,
  ({ activeEntityType }) => activeEntityType
);

export const getEditedField = createSelector(
  manageFieldsState,
  ({ editedField }) => editedField?.field
);

export const getEditedCustomField = createSelector(
  manageFieldsState,
  ({ editedField }) => editedField?.customField
);

export const getFields = createSelector(manageFieldsState, ({ fields }) => fields);
