import { EntityType } from "@mapmycustomers/shared/enum";

import EntityTypesSupportedInNewRoutes from "@app/types/dashboard/cards/newRoutes/EntityTypesSupportedInNewRoutes";

export const NEW_ROUTES_ENTITY_TYPES: EntityTypesSupportedInNewRoutes[] = [
  EntityType.COMPANY_ROUTE,
  EntityType.PEOPLE_ROUTE,
] as const;

export const routeTypeToEntityTypeMap: Record<EntityTypesSupportedInNewRoutes, EntityType> = {
  [EntityType.COMPANY_ROUTE]: EntityType.COMPANY,
  [EntityType.PEOPLE_ROUTE]: EntityType.PERSON,
};
