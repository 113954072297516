import { defineMessages } from "react-intl";

const messages = defineMessages({
  autoFilledWithRecipient: {
    id: "email.creationModal.activityCreationModal.autoFilledWithRecipient",
    defaultMessage: "Auto filled with recipient",
    description: "Auto-filled with recipient label in activity creation modal",
  },
  files: {
    id: "email.creationModal.activityCreationModal.files",
    defaultMessage: "Files",
    description: "Files label in activity creation modal",
  },
  fileUploadRestricted: {
    id: "email.creationModal.activityCreationModal.fileUploadRestricted",
    defaultMessage: "Files that were added to the email will be attached to all activities",
    description: "File upload restricted label in activity creation modal",
  },
  selectCompany: {
    id: "email.creationModal.activityCreationModal.selectCompany",
    defaultMessage: "Select Company",
    description: "Select company label in activity creation modal",
  },
  selectPerson: {
    id: "email.creationModal.activityCreationModal.selectPerson",
    defaultMessage: "Select Person",
    description: "Select person label in activity creation modal",
  },
});

export default messages;
