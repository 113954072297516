import React, { useCallback } from "react";

import { faTextSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "slate";
import { useSlate } from "slate-react";

import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { REMOVABLE_TEXT_TYPES } from "../../util/const";
import ActionTooltipWrapper from "../ActionTooltipWrapper";
import styles from "../Toolbar.module.scss";

interface Props {}

const ClearStyles: React.FC<Props> = () => {
  const editor = useSlate();

  const handleDropStyles = useCallback(() => {
    REMOVABLE_TEXT_TYPES.forEach((type) => {
      Editor.removeMark(editor, type);
    });
  }, [editor]);

  return (
    <ActionTooltipWrapper translationString={"ui.emailBody.toolbar.removeFormatting"}>
      <span className={styles.togglerButton} onClick={handleDropStyles} onMouseDown={stopEvents}>
        <FontAwesomeIcon icon={faTextSlash} size="xs" />
      </span>
    </ActionTooltipWrapper>
  );
};

export default ClearStyles;
