import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";

import Size from "@app/types/map/Size";

const scaleBySize: Record<MarkerSize, number> = {
  [MarkerSize.LARGE]: 1,
  [MarkerSize.SMALL]: 0.625,
};

const getScaledSize = (size: Size, scale: number) => size.map((value) => value * scale) as Size;

export const getScaledSizeByMarker = (size: Size, markerSize: MarkerSize) =>
  getScaledSize(size, scaleBySize[markerSize]);

export default getScaledSize;
