import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import cn from "classnames";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import DuplicateReason from "@mapmycustomers/shared/enum/DuplicateReason";
import { EntityType, EntityTypeSupportingDataMerging } from "@mapmycustomers/shared/types/entity";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";

import DuplicateCell from "@app/component/DuplicateCell";
import { showEntityView } from "@app/store/entityView/actions";
import { useExtendedAnalytics } from "@app/util/analytic/AnalyticsService";
import useEntityLinkGetter from "@app/util/contexts/useEntityLinkGetter";
import { getDuplicateReasonDisplayName } from "@app/util/ui";

import styles from "./DuplicatesTable.module.scss";

interface Props {
  duplicates: DuplicateEntity[];
  // it is expected that DuplicateEntity has `entity: EntityType` field when this prop is not defined. But takes precedence when defined
  entityType?: EntityTypeSupportingDataMerging;
  onCreate?: () => void;
  showAsLinks?: boolean;
  showEntityView: typeof showEntityView;
  title?: React.ReactNode;
}

const DuplicatesTable: React.FC<Props> = ({
  duplicates,
  entityType,
  onCreate,
  showAsLinks,
  showEntityView,
  title,
}) => {
  const intl = useIntl();
  const getEntityLink = useEntityLinkGetter();

  const mergeWarningText =
    entityType === EntityType.COMPANY
      ? "Merge Warning on Create Company"
      : "Merge Warning on Create Person";
  const mergeWarningAnalyticIssuer = useExtendedAnalytics(mergeWarningText);

  const handlePreview = useCallback(
    (entity: DuplicateEntity) => {
      showEntityView({ entityId: entity.id, entityType: entityType ?? entity.entity });
    },
    [entityType, showEntityView]
  );

  return (
    <div className={styles.container}>
      <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} size="4x" />
      <span className={styles.title}>
        {title ??
          intl.formatMessage({
            id: "createEditEntity.duplicates.title",
            defaultMessage: "This record may already exist!",
            description: "Title of duplicates field",
          })}
      </span>
      <span className={styles.subTitle}>
        {intl.formatMessage(
          {
            id: "createEditEntity.duplicates.subTitle",
            defaultMessage: "{count} records have been identified as potential duplicates",
            description: "SubTitle of duplicates field",
          },
          { count: duplicates.length }
        )}
      </span>
      <div className={styles.duplicateContainer}>
        <Row align="middle" className={styles.row} justify="space-between">
          <Col xs={{ span: 8 }} md={{ span: 10 }} xl={{ span: 12 }} xxl={{ span: 14 }}>
            {intl.formatMessage({
              id: "createEditEntity.duplicates.table.column.record",
              defaultMessage: "Record",
              description: "Title of Record column",
            })}
          </Col>
          <Col xs={{ span: 8 }} md={{ span: 7 }} xl={{ span: 6 }} xxl={{ span: 5 }}>
            {intl.formatMessage({
              id: "createEditEntity.duplicates.table.column.matchingFields",
              defaultMessage: "Potential duplicate values",
              description: "Title of Potential duplicate values column",
            })}
          </Col>
          <Col xs={{ span: 8 }} md={{ span: 7 }} xl={{ span: 6 }} xxl={{ span: 5 }} />
        </Row>

        {duplicates.slice(0, 2).map((duplicate) => (
          <Row
            align="middle"
            className={cn(styles.row, styles.duplicateRow)}
            justify="space-between"
            key={duplicate.id}
          >
            <Col xs={{ span: 8 }} md={{ span: 10 }} xl={{ span: 12 }} xxl={{ span: 14 }}>
              <DuplicateCell duplicate={duplicate} showAsLinks={showAsLinks} />
            </Col>
            <Col xs={{ span: 8 }} md={{ span: 7 }} xl={{ span: 6 }} xxl={{ span: 5 }}>
              <div className={styles.fields}>
                {duplicate.matchingFields
                  ?.map((field: DuplicateReason) => getDuplicateReasonDisplayName(intl, field))
                  .join(", ")}
              </div>
            </Col>
            <Col xs={{ span: 8 }} md={{ span: 7 }} xl={{ span: 6 }} xxl={{ span: 5 }}>
              {duplicate.hasAccess ?? true ? (
                <>
                  {showAsLinks ? (
                    <Link
                      target="_blank"
                      to={getEntityLink(entityType ?? duplicate.entity, duplicate.id)}
                    >
                      <Button>
                        {intl.formatMessage({
                          id: "createEditEntity.duplicates.table.open",
                          defaultMessage: "Open Record",
                          description: "Title of Open Record action",
                        })}
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      onClick={() => {
                        mergeWarningAnalyticIssuer?.clicked(["Open record"]);
                        handlePreview(duplicate);
                      }}
                    >
                      {intl.formatMessage({
                        id: "createEditEntity.duplicates.table.open",
                        defaultMessage: "Open Record",
                        description: "Title of Open Record action",
                      })}
                    </Button>
                  )}
                </>
              ) : null}
            </Col>
          </Row>
        ))}
      </div>
      {duplicates.length > 2 && (
        <Row className={styles.more} justify="end">
          <Col>
            {intl.formatMessage(
              {
                id: "createEditEntity.duplicates.more",
                defaultMessage: "... and {count} more",
                description: "More snippet of duplicates field",
              },
              { count: duplicates.length - 2 }
            )}
          </Col>
        </Row>
      )}

      {onCreate && (
        <Button
          onClick={() => {
            mergeWarningAnalyticIssuer?.clicked(["Create record anyway"]);
            onCreate?.();
          }}
          type="link"
        >
          {intl.formatMessage({
            id: "createEditEntity.duplicates.createAnyway",
            defaultMessage: "Create record anyway",
            description: "Title of Create record anyway action on duplicates",
          })}
        </Button>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  showEntityView,
};

export default connect(null, mapDispatchToProps)(DuplicatesTable);
