import { defineMessages, IntlShape } from "react-intl";

import { EntityType } from "@mapmycustomers/shared/enum";

enum MapFilter {
  AUTO_ACTIVITIES = "autoActivities",
  COMPANIES_OVER_CADENCE = "companiesOverCadence",
  COMPANIES_30_DAYS_NO_CONTACT = "companies30DaysNoContact",
  COMPANIES_60_DAYS_NO_CONTACT = "companies60DaysNoContact",
  PEOPLE_OVER_CADENCE = "peopleOverCadence",
  PEOPLE_30_DAYS_NO_CONTACT = "people30DaysNoContact",
  PEOPLE_60_DAYS_NO_CONTACT = "people60DaysNoContact",
  DEALS_30_DAYS_NO_CONTACT = "deals30DaysNoContact",
  OVERDUE_ACTIVITIES = "overdueActivities",
  UPCOMING_ACTIVITIES = "upcomingActivities",
  CLOSED_WON_REVENUE = "closedWonRevenue",
  CLOSED_LOST_REVENUE = "closedLostRevenue",
  GROSS_OPEN_REVENUE = "grossOpenRevenue",
  TOP_10_DEALS = "top10Deals",
  RECENTLY_CLOSED_DEALS = "recentlyClosedDeals",
  NUMBER_OF_CLOSED_WON_DEALS = "numDealsClosedWon",
  NUMBER_OF_CLOSED_LOST_DEALS = "numDealsClosedLost",
  COMPLETED_ACTIVITIES = "completedActivities",
  ALL_DEALS = "allDeals",
  OPEN_DEALS = "openDeals",
  LAST_KNOWN_LOCATION = "lastKnownLocation",
  DEALS_OVER_CADENCE = "dealsOverCadence",
  DEALS_ROTTING = "dealsRotting",
}

const messages = defineMessages({
  [MapFilter.ALL_DEALS]: {
    id: "mapFilter.allDeals",
    defaultMessage: "All Deals in Stage",
    description: "All Deals In Stage map filter",
  },
  [MapFilter.AUTO_ACTIVITIES]: {
    id: "mapFilter.checkIns",
    defaultMessage: "Check-Ins",
    description: "Check-Ins Activities map filter",
  },
  [MapFilter.CLOSED_LOST_REVENUE]: {
    id: "mapFilter.closedLostRevenue",
    defaultMessage: "Revenue Closed Lost",
    description: "Closed Lost Revenue map filter",
  },
  [MapFilter.CLOSED_WON_REVENUE]: {
    id: "mapFilter.closedWonRevenue",
    defaultMessage: "Revenue Closed Won",
    description: "Closed Won Revenue map filter",
  },
  [MapFilter.COMPANIES_30_DAYS_NO_CONTACT]: {
    id: "mapFilter.companies30DaysNoContact",
    defaultMessage: "Companies > 30 Days No Contact",
    description: "Companies > 30 Days No Contact map filter",
  },
  [MapFilter.COMPANIES_60_DAYS_NO_CONTACT]: {
    id: "mapFilter.companies60DaysNoContact",
    defaultMessage: "Companies > 60 Days No Contact",
    description: "Companies > 60 Days No Contact map filter",
  },
  [MapFilter.COMPANIES_OVER_CADENCE]: {
    id: "mapFilter.companiesOverCadence",
    defaultMessage: "Companies Past Due",
    description: "Companies Over Cadence map filter",
  },
  [MapFilter.COMPLETED_ACTIVITIES]: {
    id: "mapFilter.completedActivities",
    defaultMessage: "Activities Done",
    description: "Done Activities map filter",
  },
  [MapFilter.DEALS_30_DAYS_NO_CONTACT]: {
    id: "mapFilter.deals30DaysNoContact",
    defaultMessage: "Deals > 30 Days No Contact",
    description: "Deals > 30 Days No Contact map filter",
  },
  [MapFilter.DEALS_OVER_CADENCE]: {
    id: "mapFilter.dealOverCadence",
    defaultMessage: "Deal Past Due",
    description: "Deal Past Due map filter",
  },
  [MapFilter.DEALS_ROTTING]: {
    id: "mapFilter.dealsRotting",
    defaultMessage: "Deals rotting",
    description: "Deal Rotting map filter",
  },
  [MapFilter.GROSS_OPEN_REVENUE]: {
    id: "mapFilter.grossOpenRevenue",
    defaultMessage: "Gross Open Revenue",
    description: "Gross Open Revenue map filter",
  },
  [MapFilter.LAST_KNOWN_LOCATION]: {
    id: "mapFilter.lastKnownLocation",
    defaultMessage: "Last Known Location",
    description: "Last Known Location map filter",
  },
  [MapFilter.NUMBER_OF_CLOSED_LOST_DEALS]: {
    id: "mapFilter.numDealsClosedLost",
    defaultMessage: "# Deals Closed Lost",
    description: "# Deals Closed Lost map filter",
  },
  [MapFilter.NUMBER_OF_CLOSED_WON_DEALS]: {
    id: "mapFilter.numDealsClosedWon",
    defaultMessage: "# Deals Closed Won",
    description: "# Deals Closed Won map filter",
  },
  [MapFilter.OPEN_DEALS]: {
    id: "mapFilter.openDeals",
    defaultMessage: "Open Deals",
    description: "Open Deals map filter",
  },
  [MapFilter.OVERDUE_ACTIVITIES]: {
    id: "mapFilter.overdueActivities",
    defaultMessage: "Overdue Activities",
    description: "Overdue Activities map filter",
  },
  [MapFilter.PEOPLE_30_DAYS_NO_CONTACT]: {
    id: "mapFilter.people30DaysNoContact",
    defaultMessage: "People > 30 Days No Contact",
    description: "People > 30 Days No Contact map filter",
  },
  [MapFilter.PEOPLE_60_DAYS_NO_CONTACT]: {
    id: "mapFilter.people60DaysNoContact",
    defaultMessage: "People > 60 Days No Contact",
    description: "People > 60 Days No Contact map filter",
  },
  [MapFilter.PEOPLE_OVER_CADENCE]: {
    id: "mapFilter.peopleOverCadence",
    defaultMessage: "People Past Due",
    description: "People Past Due map filter",
  },
  [MapFilter.RECENTLY_CLOSED_DEALS]: {
    id: "mapFilter.recentlyClosedDeals",
    defaultMessage: "Recently Closed Won/Lost",
    description: "Recently Closed Won/Lost map filter",
  },
  [MapFilter.TOP_10_DEALS]: {
    id: "mapFilter.top10Deals",
    defaultMessage: "Top 10 Deals By Revenue",
    description: "top10Deals map filter",
  },
  [MapFilter.UPCOMING_ACTIVITIES]: {
    id: "mapFilter.upcomingActivities",
    defaultMessage: "Upcoming Activities",
    description: "Upcoming Activities map filter",
  },
});

const mapFilterToEntityType: Record<MapFilter, EntityType | undefined> = {
  [MapFilter.ALL_DEALS]: EntityType.DEAL,
  [MapFilter.AUTO_ACTIVITIES]: EntityType.ACTIVITY,
  [MapFilter.CLOSED_LOST_REVENUE]: undefined,
  [MapFilter.CLOSED_WON_REVENUE]: undefined,
  [MapFilter.COMPANIES_30_DAYS_NO_CONTACT]: EntityType.COMPANY,
  [MapFilter.COMPANIES_60_DAYS_NO_CONTACT]: EntityType.COMPANY,
  [MapFilter.COMPANIES_OVER_CADENCE]: EntityType.COMPANY,
  [MapFilter.COMPLETED_ACTIVITIES]: EntityType.ACTIVITY,
  [MapFilter.DEALS_30_DAYS_NO_CONTACT]: EntityType.DEAL,
  [MapFilter.DEALS_OVER_CADENCE]: EntityType.DEAL,
  [MapFilter.DEALS_ROTTING]: EntityType.DEAL,
  [MapFilter.GROSS_OPEN_REVENUE]: undefined,
  [MapFilter.LAST_KNOWN_LOCATION]: undefined,
  [MapFilter.NUMBER_OF_CLOSED_LOST_DEALS]: undefined,
  [MapFilter.NUMBER_OF_CLOSED_WON_DEALS]: undefined,
  [MapFilter.OPEN_DEALS]: EntityType.DEAL,
  [MapFilter.OVERDUE_ACTIVITIES]: EntityType.ACTIVITY,
  [MapFilter.PEOPLE_30_DAYS_NO_CONTACT]: EntityType.PERSON,
  [MapFilter.PEOPLE_60_DAYS_NO_CONTACT]: EntityType.PERSON,
  [MapFilter.PEOPLE_OVER_CADENCE]: EntityType.PERSON,
  [MapFilter.RECENTLY_CLOSED_DEALS]: EntityType.DEAL,
  [MapFilter.TOP_10_DEALS]: EntityType.DEAL,
  [MapFilter.UPCOMING_ACTIVITIES]: EntityType.ACTIVITY,
};

export const getMapFilterDisplayName = (intl: IntlShape, filter: MapFilter): string =>
  intl.formatMessage(messages[filter]);

export const getEntityTypeByMapFilter = (filter: MapFilter): EntityType | undefined =>
  mapFilterToEntityType[filter];

export default MapFilter;
