import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";

// Expands columns which cannot be otherwise represented in an export
// like the PrimaryAssociation or shouldn't be, like the Assignee, which
// should be two fields - name & email
const expandColumns = (viewState: ViewState): ViewState => ({
  ...viewState,
  columns: viewState.columns.flatMap((column) => {
    if (column.field.name === ActivityFieldName.PRIMARY_ASSOCIATION) {
      return [ActivityFieldName.COMPANY, ActivityFieldName.PERSON, ActivityFieldName.DEAL]
        .map((fieldName) => activityFieldModel.getByName(fieldName))
        .filter(isDefined)
        .map((field) => ({ ...column, field }));
    } else if (column.field.name === ActivityFieldName.ASSIGNEE) {
      return [ActivityFieldName.ASSIGNEE_NAME, ActivityFieldName.ASSIGNEE_EMAIL]
        .map((fieldName) => activityFieldModel.getByName(fieldName))
        .filter(isDefined)
        .map((field) => ({ ...column, field }));
    }
    return column;
  }),
});

export default expandColumns;
