import React from "react";

import { useIntl } from "react-intl";

import styles from "./AddRecipientsButton.module.scss";

interface Props {
  onClick?: () => void;
}

const AddRecipientsButton: React.FC<Props> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <div className={styles.addButton} onClick={onClick}>
      {intl.formatMessage({
        id: "email.creationModal.addRecipients",
        defaultMessage: "+ Add Email",
        description: "Add Recipients button in email creation modal",
      })}
    </div>
  );
};

export default AddRecipientsButton;
