import { addDays, endOfMonth, startOfDay, startOfMonth, startOfToday, subDays } from "date-fns/esm";

import { getWeekEnd, getWeekStart } from "@app/util/dates";
import { formatDate } from "@app/util/formatters";

export type SimpleRangeType = {
  dateFormatter: (date: Date | number) => string;
  dateRounder: typeof getWeekStart | typeof startOfDay | typeof startOfMonth;
  getStartDate: () => Date | undefined;
  granularity: "day" | "month" | "week";
  label: string;
};
const today = startOfToday();
export const minusDays = (days: number) => () => subDays(today, days);
export const plusDays = (days: number) => () => addDays(today, days);
export const dayGranularityDateFormatter = (date: Date | number) => formatDate(date, "P");
export const weekGranularityDateFormatter = (date: Date | number) =>
  `${dayGranularityDateFormatter(getWeekStart(date))} - ${dayGranularityDateFormatter(
    getWeekEnd(date)
  )}`;
export const monthGranularityDateFormatter = (date: Date | number) =>
  `${dayGranularityDateFormatter(startOfMonth(date))} - ${dayGranularityDateFormatter(
    endOfMonth(date)
  )}`;
