import { ActionType, createAsyncAction } from "typesafe-actions";

import { AnyEntityId, EntityTypeSupportingNotes } from "@mapmycustomers/shared/types/entity";

export const createNote = createAsyncAction(
  "@component/addNoteModal/createNote/request",
  "@component/addNoteModal/createNote/success",
  "@component/addNoteModal/createNote/failure"
)<
  {
    callback: () => void;
    entityId: AnyEntityId;
    entityType: EntityTypeSupportingNotes;
    note: string;
  },
  void,
  void
>();

export type Actions = ActionType<typeof createNote>;
