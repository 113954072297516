const downloadFileByUrl = (url: string, name?: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", name || "");
  const clickEvent = document.createEvent("MouseEvents");
  clickEvent.initEvent("click", false, true);
  a.dispatchEvent(clickEvent);
};

export default downloadFileByUrl;
