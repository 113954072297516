import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import type IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { MapPersistedRecordListConfiguration } from "@mapmycustomers/shared/types/map/types";

import type MapRecordsListState from "@app/types/viewModel/MapRecordsListState";
import universalFieldsFieldModel, {
  UniversalFieldName,
} from "@app/util/fieldModel/universalFieldsFieldModel";

const unmarshallRecordsListViewState = (
  config: MapPersistedRecordListConfiguration
): MapRecordsListState => {
  const sortFieldName = config.sort.field;
  const defaultSortField = universalFieldsFieldModel.getByName(UniversalFieldName.UPDATED_AT)!;
  const field: IField = sortFieldName
    ? universalFieldsFieldModel.getByName(sortFieldName) ?? defaultSortField
    : defaultSortField;

  return {
    sort: [
      {
        field,
        order: config.sort.order ?? SortOrder.DESC,
      },
    ],
  };
};

export default unmarshallRecordsListViewState;
