import { Flow } from "@frigade/react";

import { Role } from "@mapmycustomers/shared/enum";

export const HOME_ONBOARDING_FLOWS: Record<Role, Flow["slug"]> = {
  [Role.MANAGER]: "flow_UGu1y8NC",
  [Role.MEMBER]: "flow_mzQOClwF",
  [Role.OWNER]: "flow_cKrTqPEh",
  [Role.READ_ONLY]: "flow_mzQOClwF", // same as member, I guess
};
