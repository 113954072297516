import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import {
  Activity,
  AnyEntityId,
  EntityTypeSupportingRoutes,
  Route,
} from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import RouteDetail from "@mapmycustomers/shared/types/entity/RouteDetail";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

import DateRangeType from "../enum/DateRangeType";

export const initializeRouteBuilder = createAsyncAction(
  "activitiesCalendar/routeBuilder/initialize/request",
  "activitiesCalendar/routeBuilder/initialize/success",
  "activitiesCalendar/routeBuilder/initialize/failure"
)<void, ActivityType[], void>();

export const setEntityType = createAction(
  "activitiesCalendar/routeBuilder/setEntityType"
)<EntityTypeSupportingRoutes>();

export const setDateRange = createAction("activitiesCalendar/routeBuilder/setDateRange")<{
  range?: { end: Date; start: Date };
  rangeType: DateRangeType;
}>();

export const setActivityTypes = createAction("activitiesCalendar/routeBuilder/setActivityTypes")<
  ActivityType[]
>();

export const setActivities = createAction("activitiesCalendar/routeBuilder/setActivities")<
  | {
      activities: Activity[];
      total: number;
    }
  | undefined
>();

export const updateActivityAssociation = createAsyncAction(
  "activitiesCalendar/routeBuilder/updateActivityAssociation/request",
  "activitiesCalendar/routeBuilder/updateActivityAssociation/success",
  "activitiesCalendar/routeBuilder/updateActivityAssociation/failure"
)<
  { activity: Activity; entityId: AnyEntityId; entityType: EntityTypeSupportingRoutes },
  void,
  void
>();

export const removeActivity = createAction(
  "activitiesCalendar/routeBuilder/removeActivity"
)<Activity>();

export const createRoute = createAsyncAction(
  "activitiesCalendar/routeBuilder/createRoute/request",
  "activitiesCalendar/routeBuilder/createRoute/success",
  "activitiesCalendar/routeBuilder/createRoute/failure"
)<
  {
    callback?: () => void;
    route: {
      entityType: EntityTypeSupportingRoutes;
      name: string;
      routeDetail: OnlyRequiredFields<
        RouteDetail,
        "allottedTime" | "startAddress" | "startAt" | "type"
      >;
    };
  },
  Route,
  void
>();

export type Actions = ActionType<
  | typeof createRoute
  | typeof initializeRouteBuilder
  | typeof removeActivity
  | typeof setActivities
  | typeof setActivityTypes
  | typeof setDateRange
  | typeof setEntityType
  | typeof updateActivityAssociation
>;
