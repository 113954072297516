@use "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .overlayContainer {
        position: absolute;
        bottom: 0;
        left: 1rem;
        width: 28rem;
        height: 19rem;
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        background-color: colors.$mmc-soft-gray;
        padding: 0 0.5rem;
      }

      .overlaySearchContainer {
        padding: 0.75rem 0;
      }

      .overlayList {
        flex: 1;
        min-height: 0;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 1px;
      }

      .overlayItem {
        background-color: colors.$mmc-white;
        padding: 0.5rem 0;
        cursor: pointer;
        &:hover {
          background-color: colors.$mmc-soft-gray;
        }
      }
      .overlayItemValue {
        color: colors.$mmc-soft-slate;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .noResult {
        background-color: colors.$mmc-white;
        height: 100%;
        min-height: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
      }

      .noResultTitle {
        color: colors.$mmc-slate;
        font-size: 1rem;
        font-weight: 500;
      }

      .noResultSubTitle {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
      }
    }
  }
}
