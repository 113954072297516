enum MapTool {
  DROP_PIN = "dropPin",
  FIND_LEADS = "findLeads",
  LASSO = "lasso",
  PIN_LOCATION = "pinLocation",
  TAKE_SNAPSHOT = "takeSnapshot",
  GO_TO_LOCATION = "goToLocation",
}

export const MapToolAvailability = new Map<MapTool, MapTool[]>([
  [
    MapTool.LASSO,
    [MapTool.LASSO, MapTool.TAKE_SNAPSHOT, MapTool.GO_TO_LOCATION],
  ],
]);

export default MapTool;
