import React from "react";

import cn from "classnames";

import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";

import defaultIcon from "@app/assets/frequency/status/default.svg";
import noActivity from "@app/assets/frequency/status/noActivity.svg";
import outSoon from "@app/assets/frequency/status/outSoon.svg";
import overdue from "@app/assets/frequency/status/overdue.svg";
import upToDate from "@app/assets/frequency/status/upToDate.svg";

import styles from "./FrequencyIcon.module.scss";

interface Props {
  size?: "big" | "small" | "tiny" | "xl";
  status?: FrequencyStatus;
}

const FrequencyIcon: React.FC<Props> = ({ size = "small", status }) => (
  <img
    alt="frequency-status"
    className={cn(styles.container, {
      [styles.l]: size === "big",
      [styles.s]: size === "tiny",
      [styles.xl]: size === "xl",
    })}
    src={
      status === FrequencyStatus.UPCOMING
        ? outSoon
        : status === FrequencyStatus.PAST_DUE
        ? overdue
        : status === FrequencyStatus.UP_TO_DATE
        ? upToDate
        : status === FrequencyStatus.UNKNOWN
        ? noActivity
        : defaultIcon
    }
  />
);

export default FrequencyIcon;
