import React from "react";

import { faCircleQuestion } from "@fortawesome/pro-duotone-svg-icons/faCircleQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "antd/es/popover";

import styles from "./HelpText.module.scss";

interface Props {
  info?: React.ReactNode;
  onClick?: () => void;
  text?: string;
}

const HelpText: React.FC<Props> = ({ info, onClick, text }) => (
  <Popover content={info} destroyTooltipOnHide placement="bottom">
    <div className={styles.container} onClick={onClick}>
      <FontAwesomeIcon className={styles.icon} icon={faCircleQuestion} />
      {text && <span className={styles.text}>{text}</span>}
    </div>
  </Popover>
);

export default HelpText;
