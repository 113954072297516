import enAU from "date-fns/esm/locale/en-AU";
import enGB from "date-fns/esm/locale/en-GB";
import enNZ from "date-fns/esm/locale/en-NZ";
import enUS from "date-fns/esm/locale/en-US";
import es from "date-fns/esm/locale/es";
import hi from "date-fns/esm/locale/hi";
import ptBR from "date-fns/esm/locale/pt-BR";
import ru from "date-fns/esm/locale/ru";

// We need date locales exactly in this order.
// If target locale only has language but no region, e.g. "en", then the first
// locale with the same language will be picked. And we wanna enUS be the one.
// eslint-disable-next-line perfectionist/sort-objects
export const dateFnsLocales = { enUS, enGB, enAU, enNZ, es, hi, ru, ptBR } as const;

export type DateFnsLocale = (typeof dateFnsLocales)[keyof typeof dateFnsLocales];

export type Listener = (locale: DateFnsLocale) => void;

// unlike momentjs, date-fns has no global locale, so we use this class to store its value
export class DateFnsLocaleService {
  private _locale: DateFnsLocale = enUS;
  private _listeners: Listener[] = [];

  get locale(): DateFnsLocale {
    return this._locale;
  }

  set locale(locale: DateFnsLocale) {
    this._locale = locale;
    this._listeners.forEach((listener) => listener(locale));
  }

  addChangeListener(listener: Listener) {
    this._listeners.push(listener);
  }

  removeChangeListener(listener: Listener) {
    this._listeners = this._listeners.filter((l) => l !== listener);
  }
}

const dateFnsLocaleService = new DateFnsLocaleService();

export default dateFnsLocaleService;
