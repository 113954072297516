import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

import ColorFilter, { COLOR_FILTER_OPERATORS } from "@app/util/filters/ColorFilter";

import Field, { FieldProperties } from "./Field";

class ColorField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "color",
        filterInstances: {
          color: ColorFilter,
        },
        operators: [...COLOR_FILTER_OPERATORS],
      },
      customGridProperties: {
        cellRenderer: "colorCellRenderer",
      },
      features: [
        FieldFeature.COLOR_FIELD,
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.USES_COLORS,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
      ],
      filterType: FieldType.ID,
      type: FieldType.STRING,
      ...data,
    });
  }
}

export default ColorField;
