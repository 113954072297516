import Modal from "antd/es/modal";
import { defineMessages, IntlShape } from "react-intl";

import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import styles from "./ShowRelateEntitiesAlert.module.scss";

const messages = defineMessages({
  description: {
    id: "showRelateEntitiesAlert.description",
    defaultMessage:
      "The records you’ve selected are not all related to each other. We recommend relating the records so your data is more accurate.",
    description: "Relate records description",
  },
  notRightNow: {
    id: "showRelateEntitiesAlert.notRightNow",
    defaultMessage: `Not right now`,
    description: "Cancel button",
  },
  relatedRecords: {
    id: "showRelateEntitiesAlert.relateRecords",
    defaultMessage: "Relate Records",
    description: "Relate Records button",
  },
  title: {
    id: "showRelateEntitiesAlert.title",
    defaultMessage: "Relate {records}?",
    description: "Relate records title",
  },
});

const showRelateEntitiesAlert = (
  intl?: IntlShape,
  associatedCompany?: Company,
  associatedPerson?: Person,
  associatedDeal?: Deal,
  relateEntities?: (payload: {
    associatedCompany?: Company;
    associatedDeal?: Deal;
    associatedPerson?: Person;
    failureCallback?: () => void;
    isDealCorrectlyRelatedToCompany: boolean;
    isDealCorrectlyRelatedToPerson: boolean;
    isPersonCorrectlyRelatedToCompany: boolean;
    successCallback?: () => void;
  }) => void
) => {
  const isPersonCorrectlyRelatedToCompany =
    !associatedCompany ||
    !associatedPerson ||
    (associatedPerson?.accounts ?? []).some(({ id }) => associatedCompany?.id === id);
  const isDealCorrectlyRelatedToCompany =
    !associatedDeal || !associatedCompany || associatedCompany?.id === associatedDeal?.account?.id;
  const isDealCorrectlyRelatedToPerson =
    !associatedDeal || !associatedPerson || associatedPerson?.id === associatedDeal?.contact?.id;

  if (
    (isPersonCorrectlyRelatedToCompany &&
      isDealCorrectlyRelatedToCompany &&
      isDealCorrectlyRelatedToPerson) ||
    !relateEntities
  ) {
    return;
  }

  const handleOk = () => {
    relateEntities?.({
      associatedCompany,
      associatedDeal,
      associatedPerson,
      isDealCorrectlyRelatedToCompany,
      isDealCorrectlyRelatedToPerson,
      isPersonCorrectlyRelatedToCompany,
    });
  };

  const entities = [];

  if (
    !isPersonCorrectlyRelatedToCompany &&
    !(isDealCorrectlyRelatedToCompany && isDealCorrectlyRelatedToPerson)
  ) {
    entities.push(...[associatedCompany, associatedPerson, associatedDeal]);
  } else if (!isPersonCorrectlyRelatedToCompany) {
    entities.push(...[associatedCompany, associatedPerson]);
  } else if (!isDealCorrectlyRelatedToCompany || !isDealCorrectlyRelatedToPerson) {
    entities.push(
      ...[isDealCorrectlyRelatedToCompany ? associatedPerson : associatedCompany, associatedDeal]
    );
  }

  if (entities.length < 2) {
    return;
  }

  Modal.confirm({
    cancelText: intl?.formatMessage(messages.notRightNow),
    className: styles.alert,
    content: intl?.formatMessage(messages.description),
    okText: intl?.formatMessage(messages.relatedRecords),
    onOk: handleOk,
    title: intl?.formatMessage(messages.title, {
      records: intl.formatList(
        entities.filter(isDefined).map(({ name }) => name),
        { type: "conjunction" }
      ),
    }),
  });
};

export default showRelateEntitiesAlert;
