import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import CumulAuth from "@mapmycustomers/shared/types/cumul/CumulAuth";
import CumulDashboard from "@mapmycustomers/shared/types/cumul/CumulDashboard";

export const fetchDashboardList = createAsyncAction(
  "@cumul/fetchDashboardList/request",
  "@cumul/fetchDashboardList/success",
  "@cumul/fetchDashboardList/failure"
)<void, Array<CumulDashboard>, unknown>();

export const initializeCumul = createAsyncAction(
  "@cumul/initializeCumul/request",
  "@cumul/initializeCumul/success",
  "@cumul/initializeCumul/failure"
)<void, CumulAuth, unknown>();

export const deleteDashboard = createAsyncAction(
  "@cumul/deleteDashboard/request",
  "@cumul/deleteDashboard/success",
  "@cumul/deleteDashboard/failure"
)<CumulDashboard["id"], void, unknown>();

export const showDashboard = createAction("@cumul/showDashboard")<CumulDashboard["id"]>();

export const hideDashboard = createAction("@cumul/hideDashboard")<void>();

export const duplicateDashboard = createAsyncAction(
  "@cumul/duplicateDashboard/request",
  "@cumul/duplicateDashboard/success",
  "@cumul/duplicateDashboard/failure"
)<CumulDashboard["id"], CumulDashboard, unknown>();

export const cumulDemoRequest = createAsyncAction(
  "reports/cumulDemoRequest/request",
  "reports/cumulDemoRequest/success",
  "reports/cumulDemoRequest/failure"
)<{ message: string }, void, void>();

export const createDashboard = createAsyncAction(
  "@cumul/createDashboard/request",
  "@cumul/createDashboard/success",
  "@cumul/createDashboard/failure"
)<void, CumulDashboard, unknown>();

export type Actions = ActionType<
  | typeof createDashboard
  | typeof cumulDemoRequest
  | typeof deleteDashboard
  | typeof duplicateDashboard
  | typeof fetchDashboardList
  | typeof hideDashboard
  | typeof initializeCumul
  | typeof showDashboard
>;
