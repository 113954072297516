import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import FieldModel from "@app/util/fieldModel/impl/FieldModel";

const getDefaultDrillDownColumns = (columnNames: IField["name"][], fieldModel: FieldModel) => {
  return [
    ...columnNames
      .map((name) => fieldModel.getByName(name))
      .filter(isDefined)
      .map((field) => ({
        field,
        pinned: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? ("left" as const) : undefined,
        visible: true,
        width: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? 380 : undefined,
      })),
    ...fieldModel.sortedFields
      .filter((field) => !field.hasFeature(FieldFeature.NON_LIST_VIEW))
      .filter((field) => !columnNames.includes(field.name))
      .map((field) => ({
        field,
        pinned: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? ("left" as const) : undefined,
        visible: false,
        width: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? 380 : undefined,
      })),
  ];
};

export default getDefaultDrillDownColumns;
