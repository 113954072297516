import { defineMessages } from "react-intl";

const messages = defineMessages({
  firstName: {
    id: "formFields.personName.firstName",
    defaultMessage: "First Name",
    description: "First name field on the Create Person modal",
  },
  lastName: {
    id: "formFields.personName.lastName",
    defaultMessage: "Last Name",
    description: "Last name field on the Create Person modal",
  },
});

export default messages;
