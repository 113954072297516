import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const sandboxState = (state: RootState) => state.scene.settings.sandbox;

export const getCurrentSandbox = createSelector(sandboxState, ({ sandbox }) => sandbox);

export const getCurrentSandboxConfig = createSelector(
  getCurrentSandbox,
  (sandbox) => sandbox?.config
);

export const doesIntegrationExist = createSelector(
  sandboxState,
  ({ hasIntegration }) => hasIntegration
);

export const isSandboxLoading = createSelector(sandboxState, ({ loading }) => loading);
