import User from "@mapmycustomers/shared/types/User";

import { userDisplayName } from "../formatters";

const userDisplayNameComparator = (
  a: Pick<User, "fullName" | "username">,
  b: Pick<User, "fullName" | "username">
) => userDisplayName(a).localeCompare(userDisplayName(b));

export default userDisplayNameComparator;
