import React, { ReactNode } from "react";

import { bem } from "@react-md/utils";
import cn from "classnames";
import invariant from "tiny-invariant";

const block = bem("mmc-loading-spinner");

interface Props {
  children?: ReactNode;
  className?: string;
  global?: boolean;
  inline?: boolean;
  micro?: boolean;
  mini?: boolean;
}

const LoadingSpinner: React.FC<Props> = ({ children, className, global, inline, micro, mini }) => {
  invariant(
    !inline || !global,
    "Can't use both inline and global props, they're mutually exclusive"
  );

  return (
    <div className={cn(block({ global, inline }), className)}>
      <div className={block("spinner", { micro, mini })} />
      {children && <div className={block("message")}>{children}</div>}
    </div>
  );
};

export default LoadingSpinner;
