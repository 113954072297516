import { defineMessages, IntlShape } from "react-intl";

import { ClientType, RecordSource } from "@mapmycustomers/shared/enum/RecordSourceType";
import { EntitySupportingSourceTracking } from "@mapmycustomers/shared/types/entity";

export const getRecordSourceType = (entity: EntitySupportingSourceTracking) => {
  const source = entity.sourceCreated;
  if (!source) {
    return undefined;
  }
  if (source.recordSource === RecordSource.LEAD_GEN) {
    return RecordSource.LEAD_GEN;
  }
  return source.clientType;
};

const messages = defineMessages({
  [ClientType.IMPORTS]: {
    id: "recordSourceType.import",
    defaultMessage: "Import",
    description: "import record source type",
  },
  [ClientType.INTEGRATIONS]: {
    id: "recordSourceType.integration",
    defaultMessage: "Integration",
    description: "integration record source type",
  },
  [ClientType.MOBILE]: {
    id: "recordSourceType.mobile",
    defaultMessage: "Mobile",
    description: "mobile record source type",
  },
  [ClientType.OTHERS]: {
    id: "recordSourceType.others",
    defaultMessage: "Others",
    description: "Others record source type",
  },
  [ClientType.PUBLIC_API]: {
    id: "recordSourceType.publicapi",
    defaultMessage: "Public API",
    description: "direct api record source type",
  },
  [ClientType.WEB]: {
    id: "recordSourceType.web",
    defaultMessage: "Web",
    description: "web record source type",
  },
  [ClientType.WORKFLOWS]: {
    id: "recordSourceType.workflows",
    defaultMessage: "Workflow",
    description: "Workflow record source type",
  },
});

export const getRecordSourceTypeDisplayName = (
  intl: IntlShape,
  recordSource: ClientType | null | RecordSource
) => {
  if (!recordSource) {
    return intl.formatMessage({
      id: "recordSourceType.undefined",
      defaultMessage: "Unknown",
      description: "Undefined record source type",
    });
  }
  if (recordSource === RecordSource.LEAD_GEN) {
    return intl.formatMessage({
      id: "recordSourceType.leadfinder",
      defaultMessage: "Lead Finder",
      description: "lead finder record source type",
    });
  }
  return messages[recordSource as ClientType]
    ? intl.formatMessage(messages[recordSource as ClientType])
    : recordSource;
};
