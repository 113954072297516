import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faPaperclip } from "@fortawesome/pro-solid-svg-icons/faPaperclip";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Popconfirm from "antd/es/popconfirm";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import { RawFile } from "@mapmycustomers/shared/types/File";
import { LoadingSpinner } from "@mapmycustomers/ui";

import styles from "./FileUploader.module.scss";
import messages from "./messages";

interface Props {
  className?: string;
  disabled?: boolean;
  errored?: boolean;
  file?: RawFile;
  fileName?: string;
  onClick?: (file: RawFile) => void;
  onRemove?: () => void;
  removeTooltip?: string;
  uploading?: boolean;
}

const FileItem: React.FC<Props> = ({
  className,
  disabled,
  errored,
  file,
  fileName,
  onClick,
  onRemove,
  removeTooltip,
  uploading,
}) => {
  const intl = useIntl();

  return (
    <Row className={cn(styles.fileRow, className)}>
      <Col span={2}>
        <FontAwesomeIcon icon={errored ? faExclamationCircle : faPaperclip} />
      </Col>
      <Col span={20}>
        <div className={styles.fileRowLabel} onClick={() => file && onClick?.(file)}>
          {fileName ?? intl.formatMessage(messages.emptyFileName)}
        </div>
      </Col>
      {onRemove && (
        <Col span={2}>
          {uploading ? (
            <LoadingSpinner mini />
          ) : (
            !errored &&
            (!disabled || removeTooltip) && (
              <Popconfirm
                cancelText={intl.formatMessage(messages.deletePopConfirmCancelText)}
                disabled={disabled}
                okText={intl.formatMessage(messages.deletePopConfirmOkText)}
                onConfirm={onRemove}
                title={intl.formatMessage(messages.deletePopConfirmTitle)}
              >
                <Tooltip title={removeTooltip ?? null}>
                  <Button
                    danger
                    disabled={disabled}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    type="link"
                  />
                </Tooltip>
              </Popconfirm>
            )
          )}
        </Col>
      )}
    </Row>
  );
};

export default FileItem;
