@use "../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        color: colors.$mmc-white;
        background: colors.$mmc-soft-slate;
      }

      .audio {
        background: colors.$mmc-yellow-100;
      }

      .video {
        background: colors.$mmc-violet-100;
      }

      .image {
        background: colors.$mmc-green-050;
      }

      .pdf {
        background: colors.$mmc-red-100;
      }

      .spreadsheet {
        background: colors.$mmc-green-100;
      }

      .document {
        background: colors.$mmc-primary-100;
      }
    }
  }
}
