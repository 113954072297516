import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

import styles from "../FieldStyle.module.scss";

import Field, { FieldProperties } from "./Field";

class NameField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customGridProperties: {
        cellClass: [styles.nameField],
        cellRenderer: "nameCellRenderer",
      },
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.ALWAYS_VISIBLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.RECORD_PREVIEW_VISIBLE_BY_DEFAULT,
        FieldFeature.MAP_PINNED_FIELD,
        FieldFeature.WITH_CHECKBOX_SELECTION,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.REQUIRED,
      ],
      formProperties: {
        fullWidth: true,
      },
      type: FieldType.STRING,
      ...data,
    });
  }

  get isReadable() {
    // system-required fields are always readable, except Name which can be masked
    return this._schemaDetails?.accessStatus.read !== false;
  }

  get doesSupportPhi() {
    return true;
  }
}

export default NameField;
