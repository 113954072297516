import React from "react";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { Activity } from "@mapmycustomers/shared/types/entity";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { Avatar } from "@mapmycustomers/ui";

import UserPreviewCard from "@app/component/UserPreviewCard";

import styles from "./ActivityAssignee.module.scss";

interface Props {
  activity: Activity;
  avatarClassName?: string;
  className?: string;
}

const ActivityAssignee: React.FC<Props> = ({ activity, avatarClassName, className }) => {
  return (
    <div className={cn(styles.container, className)} onClick={stopEvents}>
      {activity.assignee ? (
        activity.assignee.id === activity.user.id ? (
          <UserPreviewCard userId={activity.user.id}>
            <Avatar
              actionOnHover
              className={cn(styles.avatar, avatarClassName)}
              user={activity.user}
            />
          </UserPreviewCard>
        ) : (
          <div className={styles.differentAssigneeContainer}>
            <UserPreviewCard userId={activity.user.id}>
              <Avatar
                actionOnHover
                className={cn(styles.avatar, avatarClassName)}
                user={activity.user}
              />
            </UserPreviewCard>
            <FontAwesomeIcon className={styles.chevron} icon={faChevronRight} />
            <UserPreviewCard userId={activity.assignee.id}>
              <Avatar
                actionOnHover
                className={cn(styles.avatar, avatarClassName)}
                user={activity.assignee}
              />
            </UserPreviewCard>
          </div>
        )
      ) : (
        <Avatar className={styles.avatar} user={activity.user} />
      )}
    </div>
  );
};

export default ActivityAssignee;
