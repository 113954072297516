import React from "react";

import { IntlShape } from "react-intl";

import { LoadingSpinner } from "@mapmycustomers/ui";

import styles from "./CommonNotificationStyles.module.scss";

const getYourDownloadWillStartShortlyNode = (intl: IntlShape | undefined, name: string) => (
  <div className={styles.container}>
    <span>
      {intl?.formatMessage(
        {
          id: "previewPane.notification.downloadingStarted",
          defaultMessage: 'Downloading "{name}"...',
          description: "A notification about file downloading in preview panes",
        },
        { name }
      ) ?? `Downloading "${name}"...`}
    </span>
    <LoadingSpinner inline mini />
  </div>
);

export default getYourDownloadWillStartShortlyNode;
