import React, { FC, useCallback, useMemo, useState } from "react";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popconfirm } from "antd";
import cn from "classnames";

import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { useConfigProvider } from "../ConfigProvider";
import ListWithOverflowItemWithShrinking from "../ListWithOverflowItem/ListWithOverflowItemWithShrinking";

import * as styles from "./RecipientList.module.scss";
import EmailRecipientMissedFields from "./type/EmailRecipientMissedFields";

export interface RecipientListProps {
  action?: React.ReactNode;
  entities: EntitiesSupportedByEmailFeature[];
  label: string;
  missingFields?: EmailRecipientMissedFields;
  moreText: string;
  onDeleteEntity?: (entity: EntitiesSupportedByEmailFeature) => void;
}

const RecipientList: FC<RecipientListProps> = ({
  action,
  entities,
  label,
  missingFields,
  moreText,
  onDeleteEntity,
}) => {
  const [extended, setExtended] = useState<boolean>();
  const [somePopconfirmVisible, setSomePopconfirmVisible] = useState<boolean>();
  const configProvider = useConfigProvider();

  const dupeIndexes = useMemo(() => {
    const emails = entities.map((entity) => entity.email);
    return [...emails]
      .map((email, i) => (emails.filter((e) => e === email).length > 1 ? i : -1))
      .filter((i) => i !== -1);
  }, [entities]);

  const getEntityWarningText = useCallback(
    (entity: EntitiesSupportedByEmailFeature, indexInList: number) => {
      const duplicateText = dupeIndexes.includes(indexInList)
        ? configProvider.formatMessage("ui.recipientList.waringText.tooltip.duplicate")
        : undefined;
      const hasMissingFieldsText = missingFields?.[entity.id]
        ? configProvider.formatMessage("ui.recipientList.waringText.tooltip.missingFields")
        : undefined;
      if (duplicateText || hasMissingFieldsText) {
        return (
          <div>
            <b>
              {duplicateText && hasMissingFieldsText
                ? configProvider.formatMessage("ui.recipientList.waringText.tooltip.title")
                : duplicateText ?? hasMissingFieldsText}
            </b>
            <br />
            {duplicateText && hasMissingFieldsText ? (
              <ol className={styles.warningPoints}>
                <li>{duplicateText}</li>
                <li>{hasMissingFieldsText}</li>
              </ol>
            ) : (
              <span>
                {duplicateText
                  ? configProvider.formatMessage(
                      "ui.recipientList.waringText.tooltip.duplicate.description"
                    )
                  : configProvider.formatMessage(
                      "ui.recipientList.waringText.tooltip.missingFields.description"
                    )}
              </span>
            )}
          </div>
        );
      }
      return undefined;
    },
    [configProvider, dupeIndexes, missingFields]
  );

  const getEntityDisplay = useCallback((entity: EntitiesSupportedByEmailFeature) => {
    return `${entity.name} (${entity.email})`;
  }, []);

  const handleOverflowRender = useCallback(
    (count: number) => {
      const hasHiddenHighlightedItem = entities.some(
        (entity, i) => i >= entities.length - count && !!getEntityWarningText(entity, i)
      );
      return (
        <div
          className={cn(styles.more, {
            [styles.moreWithDuplicates]: hasHiddenHighlightedItem,
          })}
        >
          {hasHiddenHighlightedItem && (
            <FontAwesomeIcon className={styles.warningIcon} icon={faExclamationTriangle} />
          )}
          <span>{count}</span> <span>{moreText}</span>
        </div>
      );
    },
    [entities, getEntityWarningText, moreText]
  );

  return (
    <div
      className={styles.container}
      onBlur={() => setExtended(false)}
      onFocus={() => setExtended(true)}
      tabIndex={-1}
    >
      <div>{label}:</div>
      {extended || somePopconfirmVisible ? (
        <div className={styles.extendedContainer}>
          {entities.map((entity, i) => {
            const warningText = getEntityWarningText(entity, i);
            return (
              <Popconfirm
                key={entity.id}
                okButtonProps={{ danger: true, disabled: entities.length <= 1 }}
                okText={configProvider.formatMessage("ui.recipientList.waringText.tooltip.ok")}
                onConfirm={() => {
                  onDeleteEntity?.(entity);
                }}
                onOpenChange={setSomePopconfirmVisible}
                overlayClassName={styles.issuePopconfirm}
                title={warningText}
                trigger={warningText ? ["click"] : []}
              >
                <span
                  className={cn(styles.email, styles.normalEmail, {
                    [styles.normalEmailDuplicate]: !!warningText,
                  })}
                >
                  {!!warningText && (
                    <FontAwesomeIcon className={styles.warningIcon} icon={faExclamationTriangle} />
                  )}
                  <span className={styles.recipientName}>{getEntityDisplay(entity)}</span>
                  {entities.length > 1 && (
                    <span
                      className={styles.deleteIcon}
                      onClick={(e) => {
                        stopEvents(e);
                        onDeleteEntity?.(entity);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  )}
                </span>
              </Popconfirm>
            );
          })}
          {action}
        </div>
      ) : (
        <ListWithOverflowItemWithShrinking
          className={styles.recipients}
          overflowRender={handleOverflowRender}
        >
          {entities.map((entity, i) => {
            const warning = !!getEntityWarningText(entity, i);
            return (
              <span
                className={cn(styles.email, styles.inlineEmail, {
                  [styles.inlineEmailDuplicate]: warning,
                })}
                key={entity.email}
              >
                {warning && (
                  <FontAwesomeIcon className={styles.warningIcon} icon={faExclamationTriangle} />
                )}
                {getEntityDisplay(entity)}
              </span>
            );
          })}
        </ListWithOverflowItemWithShrinking>
      )}
    </div>
  );
};

export default RecipientList;
