import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";

import PerformanceData from "@app/scene/dashboard/types/PerformanceData";
import { isOwner } from "@app/store/iam/util";
import getLeaderboardFieldValue from "@app/util/leaderboard/getLeaderboardFieldValue";

const upsertPerformanceData = (
  performanceData: PerformanceData,
  metric: LeaderboardMetricFieldName,
  items: LeaderboardItem[],
  users: Team["users"],
  teamId?: "noTeam" | Team["id"]
): void => {
  const userToItem = items.reduce(
    (result, item) => ({ ...result, [item.userId!]: item }),
    {} as Record<User["id"], LeaderboardItem>
  );

  let orgTotalValue = 0;

  // process team users
  if (teamId) {
    let teamTotalValue = 0;
    for (const user of users) {
      const item = userToItem[user.id];
      if (!item) {
        // no data for this user, nothing to process
        continue;
      }

      const value = getLeaderboardFieldValue(metric, item);
      teamTotalValue += value;

      performanceData[`${teamId}_${user.id}`] = {
        rank: isOwner(user) ? undefined : item.rank,
        rankDiff: isOwner(user) ? undefined : item.rankDiff,
        value,
      };
    }

    if (teamTotalValue) {
      performanceData[`${teamId}_undefined`] = { value: teamTotalValue };
    }
    orgTotalValue = teamTotalValue;
  } else {
    // process owners
    for (const user of users) {
      const item = userToItem[user.id];
      if (!item || !isOwner(user)) {
        // no data for this user or this user is not an owner, which should be the only
        // possible case when teamId is not defined
        continue;
      }
      const value = getLeaderboardFieldValue(metric, item);
      orgTotalValue += value;
      performanceData[`undefined_${user.id}`] = { rank: item.rank, rankDiff: item.rankDiff, value };
    }
  }

  if (orgTotalValue) {
    performanceData["undefined_undefined"] = {
      value: (performanceData["undefined_undefined"]?.value ?? 0) + orgTotalValue,
    };
  }
};

export default upsertPerformanceData;
