enum AgGridFilterOperator {
  EQUALS = "equals",
  NOT_EQUAL = "notEqual",
  LESS_THAN = "lessThan",
  GREATER_THAN = "greaterThan",
  IN_RANGE = "inRange",
  LESS_THAN_OR_EQUAL = "lessThanOrEqual",
  GREATER_THAN_OR_EQUAL = "greaterThanOrEqual",
  CONTAINS = "contains",
  NOT_CONTAINS = "notContains",
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith",
}

export default AgGridFilterOperator;
