import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { faLocationArrow } from "@fortawesome/free-solid-svg-icons/faLocationArrow";
import { defineMessages, useIntl } from "react-intl";

import geoService, {
  convertCoordinatesToLatLngLiteral,
} from "@mapmycustomers/shared/util/geo/GeoService";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";

import { getPosition } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import useAnalytics from "@app/util/contexts/useAnalytics";
import useMapEventHandler from "@app/util/hook/map/useMapEventHandler";

import useMap from "../../utils/useMap";

import styles from "./FindMeButton.module.scss";
import ToolButton from "./ToolButton";

const messages = defineMessages({
  findMe: {
    id: "map.controls.findMe",
    defaultMessage: "Find Me",
    description: "Find Me button tooltip",
  },
});

// wait for max 500ms before reacting
const boundsChangeOptions = { maxWait: 500 };

interface Props {
  position?: GeolocationPosition;
}

const FindMeButton: React.FC<Props> = ({ position }) => {
  const analytics = useAnalytics();
  const { map } = useMap();
  const intl = useIntl();

  const handleFindMe = useCallback(async () => {
    const position: GeolocationPosition = await geoService.getCurrentPosition();
    if (position) {
      map.panTo(convertCoordinatesToLatLngLiteral(position.coords));
      // can also try zooming smoothly using this: https://stackoverflow.com/a/4752617/5346779
      // but it looks like pan will take user immediately anyway, unless current and
      // target locations are close
      map.setZoom(18);
      analytics.clicked(["Find Me"]);
    }
  }, [analytics, map]);

  const [matchesUserPosition, setMatchesUserPosition] = useState<boolean>(false);

  const recalculatePosition = useCallback(
    (center: google.maps.LatLng | undefined) => {
      if (center && position?.coords) {
        const centerPoint = {
          lat: center.lat(),
          lng: center.lng(),
        };
        const currentPosition = convertCoordinatesToLatLngLiteral(position.coords);

        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          centerPoint,
          currentPosition
        );
        setMatchesUserPosition(distance <= (position.coords.accuracy ?? 10));
      }
    },
    [position, setMatchesUserPosition]
  );

  useMapEventHandler(
    map,
    // We don't use `idle` because it is not triggered if you stopped moving but keep mouse
    // button pressed. Hence, we use debounce to avoid calling backend too frequently.
    // However, we still use maxWait to guarantee that at least one request is sent every
    // maxWait milliseconds. This is needed to load data if user is panning continuously.
    "center_changed",
    useDebouncedCallback([
      function (this: google.maps.Map) {
        recalculatePosition(this.getCenter());
      },
      100,
      boundsChangeOptions,
    ])
  );

  return (
    <ToolButton
      active={matchesUserPosition}
      icon={faLocationArrow}
      iconClassName={styles.icon}
      onClick={handleFindMe}
      title={intl.formatMessage(messages.findMe)}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  position: getPosition(state),
});

export default connect(mapStateToProps)(FindMeButton);
