import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { IHumanReadableFilterConfig } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";

import TextFilter, { TEXT_FILTER_OPERATORS } from "@app/util/filters/TextFilter";

import { BOOLEAN_FILTER_OPERATORS } from "./BooleanFilter";
import DateFilter, { DATE_FILTER_OPERATORS } from "./DateFilter";
import DateRangeFilter from "./DateRangeFilter";
import EmptyFilter from "./EmptyFilter";
import IntervalFilter from "./IntervalFilter";
import NumberFilter, { NUMBER_FILTER_OPERATORS } from "./NumberFilter";
import NumberRangeFilter from "./NumberRangeFilter";

// assumes that "empty" instance is included
export const DEFAULT_EMPTINESS_OPERATOR = [
  { instance: "empty", operator: FilterOperator.EMPTY },
  { instance: "empty", operator: FilterOperator.NOT_EMPTY },
];

const defaultFilters: Partial<Record<FieldType, IHumanReadableFilterConfig>> = {
  [FieldType.BOOLEAN]: {
    defaultInstance: "boolean",
    filterInstances: {
      boolean: EmptyFilter,
    },
    operators: BOOLEAN_FILTER_OPERATORS,
  },
  [FieldType.DATE]: {
    defaultInstance: "date",
    filterInstances: {
      date: DateFilter,
      dateRange: DateRangeFilter,
      empty: EmptyFilter,
      interval: IntervalFilter,
    },
    operators: [
      ...DATE_FILTER_OPERATORS,
      { instance: "dateRange", operator: FilterOperator.IN_RANGE },
      { instance: "interval", operator: FilterOperator.INTERVAL_BEFORE },
      { instance: "interval", operator: FilterOperator.INTERVAL_AFTER },
      ...DEFAULT_EMPTINESS_OPERATOR,
    ],
  },
  [FieldType.DATE_TIME]: {
    defaultInstance: "date",
    filterInstances: {
      date: DateFilter,
      dateRange: DateRangeFilter,
      empty: EmptyFilter,
      interval: IntervalFilter,
    },
    operators: [
      ...DATE_FILTER_OPERATORS,
      { instance: "dateRange", operator: FilterOperator.IN_RANGE },
      { instance: "interval", operator: FilterOperator.INTERVAL_BEFORE },
      { instance: "interval", operator: FilterOperator.INTERVAL_AFTER },
      ...DEFAULT_EMPTINESS_OPERATOR,
    ],
  },
  [FieldType.NUMBER]: {
    defaultInstance: "number",
    filterInstances: {
      empty: EmptyFilter,
      number: NumberFilter,
      numberRange: NumberRangeFilter,
    },
    operators: [
      ...NUMBER_FILTER_OPERATORS,
      { instance: "numberRange", operator: FilterOperator.IN_RANGE },
      ...DEFAULT_EMPTINESS_OPERATOR,
    ],
  },
  [FieldType.STRING]: {
    defaultInstance: "text",
    filterInstances: {
      empty: EmptyFilter,
      text: TextFilter,
    },
    operators: [...TEXT_FILTER_OPERATORS, ...DEFAULT_EMPTINESS_OPERATOR],
  },
};

export default defaultFilters;
