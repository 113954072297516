enum DuplicateReason {
  ADDRESS = "address",
  DOMAIN = "domain",
  EMAIL = "email",
  NAME = "name",
  NAME_IN_RADIUS = "nameInRadius",
  PHONE = "phone",
  WEBSITE = "website",
}

export default DuplicateReason;
