import React, { useMemo } from "react";

import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";

import ActivityAssignee from "@app/component/activity/ActivityAssignee";
import GlobalSearchItem, { GlobalSearchActivity } from "@app/types/globalSearch/GlobalSearchItem";

import styles from "./SubTitle.module.scss";

interface Props {
  item: GlobalSearchItem;
}

const SubTitle: React.FC<Props> = ({ item }) =>
  useMemo(() => {
    if (item.entityType !== EntityType.ACTIVITY) {
      return null;
    }
    const text = (item.entity as GlobalSearchActivity).note;
    return (
      <div className={styles.container}>
        <ActivityAssignee activity={item.entity as Activity} avatarClassName={styles.avatar} />
        {text ? <span className={styles.activityText}> • {text}</span> : null}
      </div>
    );
  }, [item]);

export default SubTitle;
