import { useMemo } from "react";

import { FormInstance } from "antd/es/form/hooks/useForm";
import { IntlShape } from "react-intl";

import floorToFixed from "@app/util/number/floorToFixed";
import isFloat from "@app/util/number/isFloat";

export const useCoordinatesValidation = (intl: IntlShape, form: FormInstance) =>
  useMemo(
    () => [
      {
        validator: () => {
          const { addressDetails, latitude, longitude } = form.getFieldsValue();
          const hasAddress =
            addressDetails &&
            (addressDetails.address?.trim() ||
              addressDetails.city?.trim() ||
              addressDetails.country?.trim() ||
              addressDetails.countryCode?.trim() ||
              addressDetails.region?.trim() ||
              addressDetails.regionCode?.trim() ||
              addressDetails.postalCode?.trim());

          if (hasAddress) {
            return Promise.resolve();
          }

          let error = false;
          const latitudeParsed = isFloat(latitude) ? floorToFixed(Number(latitude), 6) : NaN;
          const longitudeParsed = isFloat(longitude) ? floorToFixed(Number(longitude), 6) : NaN;

          if (latitude === undefined || longitude === undefined) {
            return Promise.resolve();
          } else if (isNaN(latitudeParsed) || isNaN(longitudeParsed)) {
            error = true;
          } else {
            const latitude = Math.abs(latitudeParsed);
            const longitude = Math.abs(longitudeParsed);
            if (latitude <= 90 && longitude <= 180) {
              return Promise.resolve();
            } else if (!latitude && !longitude) {
              return Promise.resolve();
            } else {
              error = true;
            }
          }

          if (error) {
            return Promise.reject(
              intl.formatMessage({
                id: "validation.coordinates.message",
                defaultMessage: "Please enter valid coordinates",
                description: "Validation message for coordinates fields - latitude and longitude",
              })
            );
          }

          return Promise.resolve();
        },
      },
    ],
    [intl, form]
  );
