import { useMemo } from "react";

import memoize from "fast-memoize";

import Address from "@mapmycustomers/shared/types/Address";
import { convertPlaceToAddress } from "@mapmycustomers/shared/util/geo/GeoService";

import { useConfigProvider } from "../../../ConfigProvider";

// Use LeadFinder from the Platform, see ApiService.fetchLeads
// @deprecated
const usePlaceDetailsGetter = (): [(placeId: string) => Promise<Address | undefined>] => {
  const configProvider = useConfigProvider();
  // Get address by place
  const placesService = useMemo(
    () => new google.maps.places.PlacesService(document.createElement("div")),
    []
  );
  const selectPlace = useMemo(
    () =>
      memoize(
        (placeId: string) =>
          new Promise<Address | undefined>((resolve) => {
            placesService.getDetails({ placeId }, (result, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && result) {
                const address = convertPlaceToAddress(configProvider.formatCountryName, result);
                resolve(address);
              } else {
                resolve(undefined);
              }
            });
          })
      ),
    [configProvider, placesService]
  );
  return [selectPlace];
};

export default usePlaceDetailsGetter;
