import React from "react";

import { faUndoAlt } from "@fortawesome/free-solid-svg-icons/faUndoAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import { defineMessages, useIntl } from "react-intl";

import ActivityRecurringUnit from "@mapmycustomers/shared/enum/activity/ActivityRecurringUnit";
import { Activity } from "@mapmycustomers/shared/types/entity";

import { parseActivityInterval } from "../createEditEntity/Activity/utils/activityInterval";

import styles from "./RecurringTooltip.module.scss";

const messages = defineMessages<ActivityRecurringUnit>({
  [ActivityRecurringUnit.DAY]: {
    id: "activityDateTag.recurringTooltipTitle.day",
    defaultMessage: "Repeats {count, plural, one {daily} other {every {count} days}}",
    description: "Activity date tag recurring tooltip title whit day interval",
  },
  [ActivityRecurringUnit.MONTH]: {
    id: "activityDateTag.recurringTooltipTitle.month",
    defaultMessage: "Repeats {count, plural, one {monthly} other {every {count} months}}",
    description: "Activity date tag recurring tooltip title whit month interval",
  },
  [ActivityRecurringUnit.WEEK]: {
    id: "activityDateTag.recurringTooltipTitle.week",
    defaultMessage: "Repeats {count, plural, one {weekly} other {every {count} weeks}}",
    description: "Activity date tag recurring tooltip title whit week interval",
  },
});

export const RecurringTooltip: React.FC<{ recurInterval: Activity["recurInterval"] }> = ({
  recurInterval,
}) => {
  const intl = useIntl();

  const parsedInterval = parseActivityInterval(recurInterval);

  if (!parsedInterval) {
    return null;
  }

  return (
    <Tooltip
      align={{
        offset: [15],
      }}
      placement="topRight"
      title={intl.formatMessage(messages[parsedInterval.unit], {
        count: parsedInterval.value,
      })}
    >
      <span className={styles.container}>
        <FontAwesomeIcon icon={faUndoAlt} size="sm" />
      </span>
    </Tooltip>
  );
};
