import { SyntheticEvent, useCallback, useState } from "react";

type AnyEvent = Event | SyntheticEvent;

const useBoolean = (
  initialValue: boolean = false, // initial value
  stopPropagation: boolean = false // for convenient usage of setToTrue/False in some callbacks
): [boolean, (e?: any) => void, (e?: any) => void, (e?: any) => void] => {
  const [value, setValue] = useState(initialValue);
  const setToTrue = useCallback(
    (e?: any) => {
      if (stopPropagation && (e as AnyEvent)?.stopPropagation) {
        e.stopPropagation();
      }
      setValue(true);
    },
    [setValue, stopPropagation]
  );
  const setToFalse = useCallback(
    (e?: any) => {
      if (stopPropagation && (e as AnyEvent)?.stopPropagation) {
        e.stopPropagation();
      }
      setValue(false);
    },
    [setValue, stopPropagation]
  );
  const toggle = useCallback(
    (e?: any) => {
      if (stopPropagation && (e as AnyEvent)?.stopPropagation) {
        e.stopPropagation();
      }
      setValue((value) => !value);
    },
    [setValue, stopPropagation]
  );
  return [value, setToTrue, setToFalse, toggle];
};

export default useBoolean;
