import invariant from "tiny-invariant";

import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import User from "@mapmycustomers/shared/types/User";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import CombineOperator from "@mapmycustomers/shared/types/viewModel/internalModel/CombineOperator";
import FilterModel, {
  SimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import PlatformCombineOperator from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformCombineOperator";
import PlatformFilterModel, {
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import { AreaFilterCondition } from "@app/types/viewModel/agGridModel/AgGridFilterModel";
import { getLocalTimeZoneFormattedOffset } from "@app/util/dates";
import { isCustomField } from "@app/util/fieldModel/impl/assert";
import CustomField from "@app/util/fieldModel/impl/CustomField";
import { isEntityValue } from "@app/util/filters/Location/assert";
import { formatRawDate } from "@app/util/formatters";
import { isMetaField } from "@app/util/viewModel/util";

import IntervalUnit from "../../enum/IntervalUnit";

import createRegionCondition from "./converters/createRegionCondition";
import { SET_FILTER_OPERATOR } from "./convertFromAgGridFilterModel";

export const filterOperatorToPlatformFilterOperatorMap: Partial<{
  [key in FilterOperator]: PlatformFilterOperator;
}> = {
  [FilterOperator.AFTER]: PlatformFilterOperator.GREATER_THAN,
  [FilterOperator.BEFORE]: PlatformFilterOperator.LESS_THAN,
  [FilterOperator.CONTAINS]: PlatformFilterOperator.CONTAINS,
  [FilterOperator.ENDS_WITH]: PlatformFilterOperator.ENDS_WITH,
  [FilterOperator.EQUALS]: PlatformFilterOperator.EQUALS,
  [FilterOperator.GREATER_THAN]: PlatformFilterOperator.GREATER_THAN,
  [FilterOperator.GREATER_THAN_OR_EQUAL]: PlatformFilterOperator.GREATER_THAN_OR_EQUAL,
  [FilterOperator.LESS_THAN]: PlatformFilterOperator.LESS_THAN,
  [FilterOperator.LESS_THAN_OR_EQUAL]: PlatformFilterOperator.LESS_THAN_OR_EQUAL,
  [FilterOperator.NONE_OF]: PlatformFilterOperator.NOT_CONTAINS,
  [FilterOperator.NOT_CONTAINS]: PlatformFilterOperator.NOT_CONTAINS,
  [FilterOperator.NOT_EQUAL]: PlatformFilterOperator.NOT_EQUAL,
  [FilterOperator.NOT_IN]: PlatformFilterOperator.NOT_CONTAINS,
  [FilterOperator.NOT_ON]: PlatformFilterOperator.NOT_EQUAL,
  [FilterOperator.ON]: PlatformFilterOperator.EQUALS,
  [FilterOperator.ON_OR_AFTER]: PlatformFilterOperator.GREATER_THAN_OR_EQUAL,
  [FilterOperator.ON_OR_BEFORE]: PlatformFilterOperator.LESS_THAN_OR_EQUAL,
  [FilterOperator.STARTS_WITH]: PlatformFilterOperator.STARTS_WITH,
} as const;

export const intervalUnitToRound: Record<IntervalUnit, string> = {
  [IntervalUnit.DAY]: "days",
  [IntervalUnit.HOUR]: "hours",
  [IntervalUnit.MINUTE]: "minutes",
  [IntervalUnit.MONTH]: "months",
  [IntervalUnit.WEEK]: "weeks",
  [IntervalUnit.YEAR]: "years",
} as const;

export const filterCombineOperatorToPlatformCombineOperator: {
  [key in CombineOperator]: PlatformCombineOperator;
} = {
  AND: "$and",
  OR: "$or",
} as const;

const getIntervalCondition = (condition: SimpleCondition): PlatformFilterCondition => {
  const unit = intervalUnitToRound[condition.value!.unit as IntervalUnit];
  return {
    [PlatformFilterOperator.INTERVAL]: {
      $round: unit,
      [condition.operator === FilterOperator.INTERVAL_BEFORE
        ? PlatformFilterOperator.GREATER_THAN
        : PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: `${condition.value!.from} ${unit}`,
      [condition.operator === FilterOperator.INTERVAL_BEFORE
        ? PlatformFilterOperator.LESS_THAN_OR_EQUAL
        : PlatformFilterOperator.LESS_THAN]: `${condition.value!.to} ${unit}`,
    },
  };
};

export const getRegularFieldPlatformConditionValue = (
  field: IField,
  condition: SimpleCondition
) => {
  switch (condition.operator) {
    case FilterOperator.IN_RANGE:
      return {
        [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: condition.value[0],
        [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: condition.value[1],
      };
    case FilterOperator.IN_ALL:
      return { [PlatformFilterOperator.GROUP_IN_ALL]: condition.value };
    case SET_FILTER_OPERATOR:
      if (Array.isArray(condition.value) && !condition.value.length) {
        return undefined; // do no filtering if array is empty
      }
      if (
        field.hasAnyFeature(
          FieldFeature.GROUP_FIELD,
          FieldFeature.ROUTE_FIELD,
          FieldFeature.TERRITORY_FIELD
        )
      ) {
        return { [PlatformFilterOperator.GROUP_IN_ANY]: condition.value };
      } else {
        return { [PlatformFilterOperator.CONTAINS]: condition.value };
      }
    case FilterOperator.EQUALS:
    case FilterOperator.ON:
      if (field.type === FieldType.DATE || field.type === FieldType.DATE_TIME) {
        // only compare date part and ignore time part, but first, convert it to the correct date
        // We use formatRawDate instead of substring(0, 10) because in some cases it leaded to
        // incorrect result. E.g. it is 2023-10-16T21:07:00-04:00 locally, which is
        // 2023-10-17T01:07:00Z. And if user expected Oct 16, in reality they'll get Oct 17,
        // which is not correct. Hence, we format the date to get a day for local TZ.
        return {
          $offset: getLocalTimeZoneFormattedOffset(),
          [PlatformFilterOperator.EQUALS]: formatRawDate(condition.value, "yyyy-MM-dd"),
        };
      }
      return condition.value;
    case FilterOperator.NOT_EQUAL:
    case FilterOperator.NOT_ON:
      if (field.type === FieldType.DATE || field.type === FieldType.DATE_TIME) {
        // only compare date part and ignore time part, but first, convert it to the correct date
        // We use formatRawDate instead of substring(0, 10) because in some cases it leaded to
        // incorrect result. E.g. it is 2023-10-16T21:07:00-04:00 locally, which is
        // 2023-10-17T01:07:00Z. And if user expected Oct 16, in reality they'll get Oct 17,
        // which is not correct. Hence, we format the date to get a day for local TZ.
        return {
          $offset: getLocalTimeZoneFormattedOffset(),
          [PlatformFilterOperator.NOT_EQUAL]: formatRawDate(condition.value, "yyyy-MM-dd"),
        };
      }
      return { [PlatformFilterOperator.NOT_EQUAL]: condition.value };
    case FilterOperator.IN_AREA: {
      const areaFilter = condition as AreaFilterCondition;
      if (isEntityValue(areaFilter.value)) {
        return {
          entity: areaFilter.value.entity,
          radius: areaFilter.value.distanceInMeters,
        };
      } else {
        return {
          location: areaFilter.value.location,
          point: [areaFilter.value.longitude, areaFilter.value.latitude],
          radius: areaFilter.value.distanceInMeters,
          uom: "meter", // radius is in meters
          ...(field.platformAreaFieldName ? { fieldName: field.platformAreaFieldName } : {}),
        };
      }
    }
    case FilterOperator.EMPTY:
      return {
        [PlatformFilterOperator.EQUALS]: null,
      };
    case FilterOperator.NOT_EMPTY:
      return {
        [PlatformFilterOperator.NOT_EQUAL]: null,
      };
    case FilterOperator.IS_OVERDUE:
      return { [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: 0 };
    case FilterOperator.IS_UPCOMING:
      return { [PlatformFilterOperator.LESS_THAN]: 0 };
    case FilterOperator.IS_OVERDUE_BY_MORE_THAN:
      return { [PlatformFilterOperator.GREATER_THAN]: condition.value };
    case FilterOperator.IS_OVERDUE_BY_LESS_THAN:
      return {
        [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: 0,
        [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: condition.value - 1,
      };
    case FilterOperator.IS_OVERDUE_IN_MORE_THAN:
      return { [PlatformFilterOperator.LESS_THAN]: -condition.value };
    case FilterOperator.IS_OVERDUE_IN_LESS_THAN:
      return {
        [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: -condition.value + 1,
        [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: 0,
      };
    case FilterOperator.IS_TRUE:
      return true;
    case FilterOperator.IS_FALSE:
      return false;
    case FilterOperator.INTERVAL_BEFORE:
    case FilterOperator.INTERVAL_AFTER:
      return getIntervalCondition(condition);
    // could be under `default` case, but let's make it explicit
    case FilterOperator.LESS_THAN:
    case FilterOperator.BEFORE:
    case FilterOperator.GREATER_THAN:
    case FilterOperator.AFTER:
    case FilterOperator.LESS_THAN_OR_EQUAL:
    case FilterOperator.ON_OR_BEFORE:
    case FilterOperator.GREATER_THAN_OR_EQUAL:
    case FilterOperator.ON_OR_AFTER:
    case FilterOperator.CONTAINS:
    case FilterOperator.NOT_CONTAINS:
    case FilterOperator.STARTS_WITH:
    case FilterOperator.ENDS_WITH:
    case FilterOperator.NOT_IN:
    case FilterOperator.NONE_OF: {
      const platformOperator = filterOperatorToPlatformFilterOperatorMap[condition.operator];
      invariant(
        platformOperator,
        `Failed to convert "${condition.operator}" into platform operator`
      );
      return { [platformOperator]: condition.value };
    }
  }
};

export const getCustomFieldPlatformConditionValue = (
  field: CustomField,
  condition: SimpleCondition
) => {
  const isTimeCustomField =
    isCustomField(field) && field.customFieldData.dataType === CustomFieldDataType.TIME;
  switch (condition.operator) {
    case FilterOperator.IN_RANGE: {
      let valueFrom = condition.value[0];
      let valueTo = condition.value[1];
      if (
        (field.type === FieldType.DATE || field.type === FieldType.DATE_TIME) &&
        // don't need to format value for time fields
        !isTimeCustomField
      ) {
        // only compare date part and ignore time part, but first, convert it to the correct date
        // Read more about this conversion next in the case for FilterOperator.ON
        valueFrom = formatRawDate(valueFrom, "yyyy-MM-dd");
        valueTo = formatRawDate(valueTo, "yyyy-MM-dd");
      }
      return {
        [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: valueFrom,
        [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: valueTo,
      };
    }
    case SET_FILTER_OPERATOR:
      return { [PlatformFilterOperator.CONTAINS]: condition.value };
    case FilterOperator.EMPTY:
      return {
        [PlatformFilterOperator.EQUALS]: null,
      };
    case FilterOperator.NOT_EMPTY:
      return {
        [PlatformFilterOperator.NOT_EQUAL]: null,
      };
    case FilterOperator.EQUALS:
    case FilterOperator.ON:
      if (
        (field.type === FieldType.DATE || field.type === FieldType.DATE_TIME) &&
        // don't need to format value for time fields
        !isTimeCustomField
      ) {
        // only compare date part and ignore time part, but first, convert it to the correct date
        // We use formatRawDate instead of substring(0, 10) because in some cases it leaded to
        // incorrect result. E.g. it is 2023-10-16T21:07:00-04:00 locally, which is
        // 2023-10-17T01:07:00Z. And if user expected Oct 16, in reality they'll get Oct 17,
        // which is not correct. Hence, we format the date to get a day for local TZ.
        return {
          [PlatformFilterOperator.EQUALS]: formatRawDate(condition.value, "yyyy-MM-dd"),
        };
      }
      return { [PlatformFilterOperator.EQUALS]: condition.value };
    case FilterOperator.NOT_EQUAL:
    case FilterOperator.NOT_ON:
      if (
        (field.type === FieldType.DATE || field.type === FieldType.DATE_TIME) &&
        // don't need to format value for time fields
        !isTimeCustomField
      ) {
        // only compare date part and ignore time part, but first, convert it to the correct date
        // We use formatRawDate instead of substring(0, 10) because in some cases it leaded to
        // incorrect result. E.g. it is 2023-10-16T21:07:00-04:00 locally, which is
        // 2023-10-17T01:07:00Z. And if user expected Oct 16, in reality they'll get Oct 17,
        // which is not correct. Hence, we format the date to get a day for local TZ.
        return {
          [PlatformFilterOperator.NOT_EQUAL]: formatRawDate(condition.value, "yyyy-MM-dd"),
        };
      }
      return { [PlatformFilterOperator.NOT_EQUAL]: condition.value };
    case FilterOperator.INTERVAL_BEFORE:
    case FilterOperator.INTERVAL_AFTER:
      return getIntervalCondition(condition);
    // could be under `default` case, but let's make it explicit
    case FilterOperator.CONTAINS:
    case FilterOperator.NOT_CONTAINS:
    case FilterOperator.GREATER_THAN:
    case FilterOperator.GREATER_THAN_OR_EQUAL:
    case FilterOperator.LESS_THAN:
    case FilterOperator.LESS_THAN_OR_EQUAL:
    case FilterOperator.BEFORE:
    case FilterOperator.AFTER:
    case FilterOperator.ON_OR_BEFORE:
    case FilterOperator.ON_OR_AFTER:
    case FilterOperator.STARTS_WITH:
    case FilterOperator.ENDS_WITH: {
      const platformOperator = filterOperatorToPlatformFilterOperatorMap[condition.operator];
      invariant(
        platformOperator,
        `Failed to convert "${condition.operator}" into platform operator`
      );
      return { [platformOperator]: condition.value };
    }
  }
};

export const convertToPlatformFilterModel = (
  filterModel: FilterModel,
  columnModel: ColumnModel,
  fieldModel: IFieldModel,
  fetchAllColumns: boolean = true,
  viewAs?: User["id"]
): PlatformFilterModel => {
  // First, include any special column-specific filters
  // Like `includeCustomFields`, `includeGroups`, `includeNotes`, etc.
  // Here we're not filtering for visible fields which means we always
  // fetch all available columns even if some are hidden.
  const platformFilterModel: PlatformFilterModel = columnModel.reduce<PlatformFilterModel>(
    (result, column) =>
      Object.assign(
        result,
        fetchAllColumns || column.visible ? column.field.extraPlatformFilters : {}
      ),
    {}
  );

  platformFilterModel.$and = [];

  Object.keys(filterModel).forEach((fieldFilterName) => {
    const field = fieldModel.getByFilterName(fieldFilterName);
    invariant(field, `Invalid field name in filter model: ${fieldFilterName}`);

    const rawPlatformCondition = field.convertToPlatformCondition(filterModel[fieldFilterName]);
    const platformCondition = field.hasFeature(FieldFeature.REGION_FIELD)
      ? createRegionCondition(field, rawPlatformCondition)
      : rawPlatformCondition;

    if (isMetaField(field)) {
      // meta fields put their values right into the root of the platformFilterModel
      Object.assign(platformFilterModel, platformCondition);
    } else {
      platformFilterModel.$and!.push(platformCondition);
    }
  });

  if (viewAs !== undefined) {
    Object.assign(platformFilterModel, { viewAs });
  }

  return platformFilterModel;
};
