import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const componentState = (state: RootState) => state.component.userPreviewCard;

export const getBoards = createSelector(componentState, ({ boards }) => boards);
export const getCardIds = createSelector(componentState, ({ cards }) => cards);
export const areCardsLoading = createSelector(componentState, ({ cardsLoading }) => cardsLoading);
export const getCardsData = createSelector(componentState, ({ cardsData }) => cardsData);
