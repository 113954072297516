import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import { isCustomField } from "@app/util/fieldModel/impl/assert";

const isGroupedField = (field: IField): boolean =>
  field.hasFeature(FieldFeature.TEAM_FIELD) ||
  field.hasFeature(FieldFeature.SOURCE_FIELD) ||
  field.hasFeature(FieldFeature.GROUP_FIELD) ||
  field.hasFeature(FieldFeature.ROUTE_FIELD) ||
  field.hasFeature(FieldFeature.FUNNEL_FIELD) ||
  field.hasFeature(FieldFeature.OWNER_FIELD) ||
  (isCustomField(field) && field.type === FieldType.LIST);

export default isGroupedField;
