import { createReducer } from "typesafe-actions";

import { TerritoryLevel } from "@mapmycustomers/shared";
import BoundaryType from "@mapmycustomers/shared/enum/BoundaryType";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

import {
  BoundariesActions,
  calculateBuckets,
  ensureDemographicDataLoaded,
  initializeTerritories,
  setBoundariesSettings,
  setBoundaryType,
  setHighlightedTerritoryId,
  setVisibleRegions,
  setZoomLevel,
  toggleBucketVisibility,
  toggleVisualizeDemography,
  updateDemographicDataConfiguration,
} from "./actions";
import BoundariesLayerData from "./types/BoundariesLayerData";
import ClassificationMethod from "./types/ClassificationMethod";
import DataSource from "./types/DataSource";
import colorPalette from "./util/colorPalette";

export const boundariesLayerInitialState: BoundariesLayerData = {
  buckets: [],
  bucketsLoading: false,
  demographicDataConfiguration: {
    bucketCount: 5,
    classificationMethod: ClassificationMethod.NATURAL_BREAKS,
    countryCode: CountryCode["UNITED STATES OF AMERICA"],
    dataSource: DataSource.MEDIAN_INCOME,
    onlyVisibleRegions: true,
    theme: colorPalette[0],
  },
  demographicDataLoading: false,
  demographyDataCountryCode: CountryCode["UNITED STATES OF AMERICA"],
  territoriesLoading: false,
  type: BoundaryType.TERRITORY,
  viewportRegions: [],
  visible: false,
  visualizeDemography: false,
  zoomLevel: TerritoryLevel.ADM2,
};

const boundariesLayer = createReducer<BoundariesLayerData, BoundariesActions>(
  boundariesLayerInitialState
)
  .handleAction(initializeTerritories.request, (state) => ({
    ...state,
    territoriesLoading: true,
  }))
  .handleAction(ensureDemographicDataLoaded.request, (state) => ({
    ...state,
    demographicDataLoading: true,
  }))
  .handleAction(ensureDemographicDataLoaded.success, (state, { payload }) => ({
    ...state,
    demographicData: payload.demographicData ?? state.demographicData,
    demographicDataLoading: false,
    demographyDataCountryCode: payload.demographyDataCountryCode ?? state.demographyDataCountryCode,
  }))
  .handleAction(ensureDemographicDataLoaded.failure, (state) => ({
    ...state,
    demographicDataLoading: false,
  }))
  .handleAction([initializeTerritories.success, initializeTerritories.failure], (state) => ({
    ...state,
    territoriesLoading: false,
  }))
  .handleAction(setHighlightedTerritoryId, (state, { payload }) => ({
    ...state,
    highlightedTerritoryId: payload,
  }))
  .handleAction(setBoundariesSettings, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setBoundaryType, (state, { payload }) => ({
    ...state,
    type: payload,
  }))
  .handleAction(toggleVisualizeDemography, (state) => ({
    ...state,
    visualizeDemography: !state.visualizeDemography,
  }))
  .handleAction(calculateBuckets.request, (state) => ({
    ...state,
    bucketsLoading: true,
  }))
  .handleAction(calculateBuckets.success, (state, { payload }) => ({
    ...state,
    buckets: payload,
    bucketsLoading: false,
  }))
  .handleAction(setZoomLevel, (state, { payload }) => ({
    ...state,
    bucketsLoading: true,
    zoomLevel: payload,
  }))
  .handleAction(setVisibleRegions, (state, { payload }) => ({
    ...state,
    viewportRegions: payload,
  }))
  .handleAction(updateDemographicDataConfiguration, (state, { payload }) => ({
    ...state,
    demographicDataConfiguration: { ...state.demographicDataConfiguration, ...payload },
  }))
  .handleAction(toggleBucketVisibility, (state, { payload }) => {
    const buckets = state.buckets.map((bucket) => ({
      ...bucket,
      visible: bucket.id === payload.id ? !bucket.visible : bucket.visible,
    }));
    return {
      ...state,
      buckets,
    };
  });

export default boundariesLayer;
