import { useCallback, useRef } from "react";

const useErroredFieldsScroller = () => {
  const formFieldsRef = useRef<any>(null);
  const handleFinishFail = useCallback(() => {
    if (formFieldsRef.current) {
      // we need timeout because antd doesn't put .ant-form-item-has-error class immediately
      setTimeout(() => {
        const fieldWithError = formFieldsRef.current.querySelector(".ant-form-item-has-error");
        if (fieldWithError) {
          fieldWithError.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, []);
  return [formFieldsRef, handleFinishFail] as const;
};

export default useErroredFieldsScroller;
