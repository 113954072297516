import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const importState = (state: RootState) => state.scene.settings.imports;

export const getEntityType = createSelector(importState, ({ newImport }) => newImport.entityType);

export const getFilePreview = createSelector(importState, ({ newImport }) => newImport.filePreview);

export const getLayoutId = createSelector(importState, ({ newImport }) => newImport.layoutId);

export const getTimezone = createSelector(importState, ({ newImport }) => newImport.timezone);

export const isFilePreviewLoading = createSelector(
  importState,
  ({ filePreviewLoading }) => filePreviewLoading
);

export const getStartedImport = createSelector(importState, ({ startedImport }) => startedImport);

export const getMappingOptions = createSelector(
  importState,
  ({ newImport }) => newImport.mappingOptions
);

export const isLoading = createSelector(importState, ({ loading }) => loading);

export const isAddPageLoading = createSelector(
  importState,
  ({ initializeAddPageLoading }) => initializeAddPageLoading
);

export const isCreatingConfig = createSelector(
  importState,
  ({ configCreationLoading }) => configCreationLoading
);

export const isUpdatingConfig = createSelector(
  importState,
  ({ configUpdateLoading }) => configUpdateLoading
);

export const isConfigDeletionInProgress = createSelector(
  importState,
  ({ configDeletionLoading }) => configDeletionLoading
);

export const getImportHistory = createSelector(importState, ({ importHistory }) => importHistory);

export const getImportConfigs = createSelector(importState, ({ configs }) => configs);

export const getErrorCodeWhileUploading = createSelector(
  importState,
  ({ newImport }) => newImport.errorCode
);

export const getSelectedConfig = createSelector(
  importState,
  ({ selectedConfig }) => selectedConfig
);

export const isConfigModified = createSelector(importState, ({ configModified }) => configModified);

export const getMappedColumns = createSelector(
  importState,
  ({ newImport }) => newImport.mappedColumns
);

export const getMissedFileColumns = createSelector(
  importState,
  ({ missedFileColumns }) => missedFileColumns
);

export const areThereMissedFileColumns = createSelector(
  importState,
  ({ missedFileColumns }) => missedFileColumns.length > 0
);

export const getStep = createSelector(importState, ({ step }) => step);
