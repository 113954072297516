import { isToday, isYesterday } from "date-fns/esm";
import { IntlShape } from "react-intl";

import MmcNotification from "@app/types/MmcNotification";
import { parseApiDate } from "@app/util/parsers";

const getGroupHeaderTitle = (intl: IntlShape, updatedAt: MmcNotification["updatedAt"]) => {
  let headerTitle = intl.formatMessage({
    id: "navbar.notifications.list.groupHeader.earlier",
    defaultMessage: "Earlier",
    description: "Navbar Notifications - list - group header - earlier",
  });
  if (isToday(parseApiDate(updatedAt))) {
    headerTitle = intl.formatMessage({
      id: "navbar.notifications.list.groupHeader.today",
      defaultMessage: "Today",
      description: "Navbar Notifications - list - group header - today",
    });
  } else if (isYesterday(parseApiDate(updatedAt))) {
    headerTitle = intl.formatMessage({
      id: "navbar.notifications.list.groupHeader.yesterday",
      defaultMessage: "Yesterday",
      description: "Navbar Notifications - list - group header - yesterday",
    });
  }
  return headerTitle;
};

export default getGroupHeaderTitle;
