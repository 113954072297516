import invariant from "tiny-invariant";

import ReportType from "@app/enum/ReportType";

import MapFilter from "../../enums/MapFilter";

// might be different than the EntityType, that's why EntityType was not used instead
enum ExportType {
  ACTIVITIES,
  COMPANIES,
  PEOPLE,
  DEALS,
  ROUTES,
}

export const getExportTypeByReportTypeAndMapFilter = (
  reportType: ReportType,
  filter?: MapFilter
): ExportType => {
  if (reportType === ReportType.DEALS) {
    return ExportType.DEALS;
  } else if (
    reportType === ReportType.ACTIVITY &&
    filter &&
    [
      MapFilter.COMPANIES_30_DAYS_NO_CONTACT,
      MapFilter.COMPANIES_60_DAYS_NO_CONTACT,
      MapFilter.COMPANIES_OVER_CADENCE,
    ].includes(filter)
  ) {
    return ExportType.COMPANIES;
  } else if (
    reportType === ReportType.ACTIVITY &&
    filter &&
    [
      MapFilter.PEOPLE_30_DAYS_NO_CONTACT,
      MapFilter.PEOPLE_60_DAYS_NO_CONTACT,
      MapFilter.PEOPLE_OVER_CADENCE,
    ].includes(filter)
  ) {
    return ExportType.PEOPLE;
  } else if (reportType === ReportType.ACTIVITY) {
    return ExportType.ACTIVITIES;
  }

  invariant(false, `Unsupported report type: ${reportType}`);
};

export default ExportType;
