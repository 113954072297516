import React from "react";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faCircle } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import styles from "./ValidationItem.module.scss";

interface Props {
  isValid: boolean;
  name: string;
}

const ValidationItem: React.FC<Props> = ({ isValid, name }) => {
  return (
    <div className={cn(styles.container, { [styles.valid]: isValid })}>
      <FontAwesomeIcon icon={isValid ? faCheckCircle : faCircle} />
      {name}
    </div>
  );
};

export default ValidationItem;
