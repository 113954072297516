import React, { useMemo } from "react";

import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import AntdAlert, { AlertProps } from "antd/es/alert";
import cn from "classnames";

const block = bem("mmc-alert");

const Alert: React.FC<AlertProps> = ({ className, type, ...restProps }) => {
  const icon = useMemo(() => {
    switch (type) {
      case "error":
        return faCircleXmark;
      case "success":
        return faCircleCheck;
      case "warning":
        return faCircleExclamation;
      default:
        return faCircleInfo;
    }
  }, [type]);

  return (
    <AntdAlert
      className={cn(block(), className)}
      icon={<FontAwesomeIcon className={block("icon")} icon={icon} />}
      type={type}
      {...restProps}
    />
  );
};

export default Alert;
