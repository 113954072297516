import React, { FC, useCallback } from "react";

import cn from "classnames";
import { useIntl } from "react-intl";

import { NumberField, NumberFieldComponent } from "@mapmycustomers/ui";

import styles from "./DaysField.module.scss";

export interface DaysFieldProps {
  className?: string;
  focus?: boolean;
  onChange?: (value: number | undefined) => void;
  value?: number;
}

const DaysField: FC<DaysFieldProps> = ({ className, focus, onChange, value }) => {
  const intl = useIntl();

  const setRef = useCallback(
    (ref: null | NumberFieldComponent) => {
      if (focus && ref) {
        ref.focus();
      }
    },
    [focus]
  );

  return (
    <div className={cn(styles.container, className)}>
      <NumberField onChange={onChange} ref={setRef} size="middle" value={value} />
      <span className={styles.suffix}>
        {intl.formatMessage({
          id: "filters.cadenceStatus.suffix",
          defaultMessage: "days",
          description: "Days suffix in cadence status field filter",
        })}
      </span>
    </div>
  );
};

export default DaysField;
