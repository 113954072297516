import { useMemo } from "react";

import { LabeledValue } from "antd/es/select";
import { useIntl } from "react-intl";

import IntervalUnit, { getIntervalUnitDisplayName } from "@app/enum/IntervalUnit";

const useIntervalUnitOptions = (count: number): LabeledValue[] => {
  const intl = useIntl();

  return useMemo<LabeledValue[]>(() => {
    return Object.values<IntervalUnit>(IntervalUnit)
      .filter((unit) => unit !== IntervalUnit.HOUR && unit !== IntervalUnit.MINUTE)
      .map<LabeledValue>((value) => ({
        key: value,
        label: getIntervalUnitDisplayName(intl, value, count),
        value,
      }));
  }, [intl, count]);
};

export default useIntervalUnitOptions;
