import EntityType from "../../enum/EntityType";

// kept this line for better backwards compatibility
export { default as EntityType } from "../../enum/EntityType";

export type * from "../integrations/types";
export type * from "../map/types";
export type { default as Activity } from "./Activity";
export type { default as Company } from "./Company";
export type { default as Deal } from "./Deal";
export type { default as Group } from "./Group";
export type { default as Lead } from "./Lead";
export type { default as Person } from "./Person";

export type { default as Route } from "./Route";
export type { default as Territory } from "./Territory";
export type * from "./types";

export const entityTypesSupportingFiles = [
  EntityType.COMPANY,
  EntityType.DEAL,
  EntityType.PERSON,
  EntityType.ACTIVITY,
] as const;

export const entityTypesSupportingGroups = [
  EntityType.COMPANY,
  EntityType.DEAL,
  EntityType.PERSON,
] as const;

export const entityTypesSupportingRoutes = [EntityType.COMPANY, EntityType.PERSON] as const;

export const entityTypesSupportingFieldCustomization = [
  EntityType.COMPANY,
  EntityType.PERSON,
  EntityType.DEAL,
  EntityType.ACTIVITY,
] as const;

export const entityTypesSupportingChildLayouts = [EntityType.DEAL, EntityType.ACTIVITY] as const;
