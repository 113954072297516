import React, { useMemo } from "react";

import Button from "antd/es/button";
import { useIntl } from "react-intl";

import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";

import { isOwner } from "@app/store/iam/util";
import Dashboard from "@app/types/dashboard/Dashboard";
import DashboardCard, { UserPreviewCard } from "@app/types/dashboard/DashboardCard";

import DataCard from "../components/DataCard";
import styles from "../UserPreviewCard.module.scss";

import { SUPPORTED_PREVIEW_CHART_TYPES, UserPreviewCardType } from "./const";

const usePreviewCards = (
  user: undefined | User,
  cardIds: Record<number, DashboardCard["id"] | undefined>,
  orgDashboard: Dashboard | undefined,
  selectedTeamId: Team["id"] | undefined,
  teamDashboard: Dashboard | undefined,
  userDashboard: Dashboard | undefined,
  viewOnlyNoAccess: boolean,
  viewOnly: boolean,
  setIndex: (index: number) => void,
  setModalOpen: (open: boolean) => void
) => {
  const intl = useIntl();

  const previewCards = useMemo(() => {
    const elements = [];
    const dashboard =
      user && isOwner(user) ? orgDashboard : selectedTeamId ? teamDashboard : userDashboard;

    if (!viewOnlyNoAccess) {
      for (let i = 0; i < 2; i++) {
        const cardId = cardIds[i];
        const card = dashboard?.settings.cards.find(({ id }) => id === cardId);
        if (card && SUPPORTED_PREVIEW_CHART_TYPES.includes(card.type as UserPreviewCardType)) {
          elements.push(
            <DataCard
              allowRemove={!viewOnly}
              card={card as UserPreviewCard}
              index={i}
              key={i}
              scope={{ teamId: selectedTeamId, userId: user?.id }}
            />
          );
        } else if (!viewOnly) {
          elements.push(
            <Button
              className={styles.addMetric}
              key={i}
              onClick={() => {
                setIndex(i);
                setModalOpen(true);
              }}
            >
              {intl.formatMessage({
                id: "userPreviewCard.addMetric",
                defaultMessage: "+ Add Metric",
                description: "Add metric button title",
              })}
            </Button>
          );
        }
      }
    }
    return elements;
  }, [
    user,
    orgDashboard,
    selectedTeamId,
    teamDashboard,
    userDashboard,
    viewOnlyNoAccess,
    cardIds,
    viewOnly,
    intl,
    setIndex,
    setModalOpen,
  ]);

  return previewCards;
};

export default usePreviewCards;
