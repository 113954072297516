import React from "react";

import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";

import { Alert } from "@mapmycustomers/ui";

import styles from "./FormNoLayoutAlert.module.scss";

interface Props {}

const FormNoLayoutAlert: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <Alert
      className={styles.alert}
      message={
        <>
          <div className={styles.title}>
            <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />
            <span>
              {intl.formatMessage({
                id: "formNoLayout.alert.title",
                defaultMessage: "No Layout Assigned",
                description: "No Layout Alert - title",
              })}
            </span>
          </div>

          <div className={styles.content}>
            {intl.formatMessage({
              id: "formNoLayout.alert.message",
              defaultMessage:
                "Please contact your administrator to assign you a layout. Records cannot be created without an assigned layout.",
              description: "No Layout Alert - message",
            })}
          </div>
        </>
      }
      type="error"
    />
  );
};

export default FormNoLayoutAlert;
