import { createReducer } from "typesafe-actions";

import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";

import FrequencyPreviewConfig from "../../component/frequency/type/FrequencyPreviewConfig";
import LoggedActivityMap from "../../component/frequency/type/LoggedActivityMap";

import {
  Actions,
  clearLoggedActivities,
  clearPreviousActivities,
  donePendingActivity,
  fetchLoggedActivities,
  fetchPreviousActivities,
  hideFrequencyModal,
  initFrequencyModal,
  initFrequencyPanel,
  setFrequencyPanelConfig,
  updateEntityFrequency,
} from "./actions";

const DEFAULT_CONFIG = {
  actualFrequencyCriteria: { dueDate: new Date() },
  entity: { id: 0, entity: EntityType.COMPANY, name: "" },
  isEntityFrequencied: false,
  isGroup: false,
  isSomeGroupFrequencied: false,
  minimumCadenceInterval: 0,
};

export interface FrequencyState {
  entityFrequencyUpdating: boolean;
  frequencyModalAnalyticsPath?: string[];
  frequencyModalConfig: FrequencyPreviewConfig;
  frequencyModalLoading: boolean;
  frequencyModalVisible: boolean;
  frequencyPanelConfig: FrequencyPreviewConfig;
  frequencyPanelLoading: boolean;
  lastFrequencyActivity?: Activity;
  loggedActivities: LoggedActivityMap;
  loggedActivitiesLoading: boolean;
  pendingActivity?: Activity;
  pendingActivityUpdate: boolean;
  previousActivities: Activity[];
  previousActivitiesLoading: boolean;
  previousActivitiesTotal: number;
}

const initialState: FrequencyState = {
  entityFrequencyUpdating: false,
  frequencyModalConfig: DEFAULT_CONFIG,
  frequencyModalLoading: false,
  frequencyModalVisible: false,
  frequencyPanelConfig: DEFAULT_CONFIG,
  frequencyPanelLoading: false,
  loggedActivities: {},
  loggedActivitiesLoading: false,
  pendingActivityUpdate: false,
  previousActivities: [],
  previousActivitiesLoading: false,
  previousActivitiesTotal: 0,
};

const frequency = createReducer<FrequencyState, Actions>(initialState)
  .handleAction(fetchLoggedActivities.request, (state) => ({
    ...state,
    loggedActivitiesLoading: true,
  }))
  .handleAction(fetchLoggedActivities.success, (state, { payload: { loggedActivities } }) => ({
    ...state,
    loggedActivities: {
      ...state.loggedActivities,
      ...Object.keys(loggedActivities).reduce(
        (result, key) => ({
          ...result,
          [key]: new Set([
            ...loggedActivities[key],
            ...new Array(state.loggedActivities[key] ?? []),
          ]),
        }),
        {}
      ),
    },
    loggedActivitiesLoading: false,
  }))
  .handleAction(fetchLoggedActivities.failure, (state) => ({
    ...state,
    loggedActivitiesLoading: false,
  }))
  .handleAction(fetchPreviousActivities.request, (state) => ({
    ...state,
    previousActivitiesLoading: false,
  }))
  .handleAction(
    fetchPreviousActivities.success,
    (state, { payload: { previousActivities, previousActivitiesTotal } }) => ({
      ...state,
      previousActivities: [...state.previousActivities, ...previousActivities],
      previousActivitiesLoading: false,
      previousActivitiesTotal,
    })
  )
  .handleAction(fetchPreviousActivities.failure, (state) => ({
    ...state,
    previousActivitiesLoading: false,
  }))
  .handleAction(initFrequencyPanel.request, (state) => ({
    ...state,
    frequencyPanelLoading: true,
  }))
  .handleAction(initFrequencyPanel.success, (state, { payload: { pendingActivity } }) => ({
    ...state,
    frequencyPanelLoading: false,
    pendingActivity,
  }))
  .handleAction(initFrequencyPanel.failure, (state) => ({
    ...state,
    frequencyPanelLoading: false,
  }))
  .handleAction(donePendingActivity.request, (state) => ({
    ...state,
    pendingActivityUpdate: true,
  }))
  .handleAction(donePendingActivity.success, (state) => ({
    ...state,
    pendingActivityUpdate: false,
  }))
  .handleAction(donePendingActivity.failure, (state) => ({
    ...state,
    pendingActivityUpdate: false,
  }))
  .handleAction(initFrequencyModal.request, (state, { payload: { analyticsPath } }) => ({
    ...state,
    frequencyModalAnalyticsPath: analyticsPath ?? state.frequencyModalAnalyticsPath,
    frequencyModalLoading: true,
    loggedActivities: {},
    previousActivities: [],
  }))
  .handleAction(
    initFrequencyModal.success,
    (state, { payload: { config, lastFrequencyActivity } }) => ({
      ...state,
      frequencyModalConfig: config,
      frequencyModalLoading: false,
      frequencyModalVisible: true,
      lastFrequencyActivity,
    })
  )
  .handleAction(initFrequencyModal.failure, (state) => ({
    ...state,
    frequencyModalLoading: false,
  }))
  .handleAction(updateEntityFrequency.request, (state) => ({
    ...state,
    entityFrequencyUpdating: true,
    frequencyModalLoading: true,
  }))
  .handleAction([updateEntityFrequency.success, updateEntityFrequency.failure], (state) => ({
    ...state,
    entityFrequencyUpdating: false,
    frequencyModalLoading: false,
  }))
  .handleAction(hideFrequencyModal, (state) => ({
    ...state,
    frequencyModalVisible: false,
  }))
  .handleAction(setFrequencyPanelConfig, (state, { payload }) => ({
    ...state,
    frequencyPanelConfig: payload,
  }))
  .handleAction(clearPreviousActivities, (state) => ({
    ...state,
    previousActivities: [],
  }))
  .handleAction(clearLoggedActivities, (state) => ({
    ...state,
    loggedActivities: {},
  }));

export * from "./selectors";
export type FrequencyActions = Actions;
export default frequency;
