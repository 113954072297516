import { isBefore } from "date-fns/esm";

import { Activity } from "@mapmycustomers/shared/types/entity";

import { parseApiDate } from "@app/util/parsers";

const getOverdueStatus = (activity: Activity) =>
  !activity.completed && activity.startAt && isBefore(parseApiDate(activity.startAt), Date.now());

export default getOverdueStatus;
