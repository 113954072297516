import { defineMessages } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import i18nService from "@app/config/I18nService";
import { DEFAULT_EMPTINESS_OPERATOR } from "@app/util/filters/defaultFilters";
import EmptyFilter from "@app/util/filters/EmptyFilter";
import RottingDaysOutFilter, {
  ROTTING_DAYS_OUT_FILTER_OPERATORS,
} from "@app/util/filters/RottingDaysOutFilter";
import { isCombinedCondition } from "@app/util/viewModel/assert";
import {
  filterOperatorToPlatformFilterOperatorMap,
  getRegularFieldPlatformConditionValue,
} from "@app/util/viewModel/convertToPlatformFilterModel";

import Field, { FieldProperties } from "./Field";

export const messages = defineMessages({
  daysRotting: {
    id: "rottingDaysOutField.daysRotting",
    defaultMessage: "{days} {days, plural, one {day} other {days}}",
    description: "Rotting days message for the given entity",
  },
  daysUntilRotting: {
    id: "rottingDaysOutField.daysUntilRotting",
    defaultMessage: "{days} {days, plural, one {day} other {days}} until rotting",
    description: "Days until rotting message for the given entity",
  },
});

class RottingDaysOutField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "rotting",
        filterInstances: { empty: EmptyFilter, rotting: RottingDaysOutFilter },
        operators: [...ROTTING_DAYS_OUT_FILTER_OPERATORS, ...DEFAULT_EMPTINESS_OPERATOR],
      },
      customGridProperties: {
        cellRenderer: "rottingCellRenderer",
      },
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.ENGAGEMENT,
        FieldFeature.ROTTING_DAYS_OUT_FIELD,
        FieldFeature.DETAILS,
      ],
      type: FieldType.NUMBER,
      valueFormatter: (entity: unknown, value: unknown) => {
        const intl = i18nService.getIntl();
        if (!intl) {
          return String(value); // returning value as string is better than returning nothing
        }
        return (value as number) > 0
          ? intl.formatMessage(messages.daysRotting, { days: value as number })
          : intl.formatMessage(messages.daysUntilRotting, { days: -(value as number) });
      },
      ...data,
    });
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      return {}; // combined condition is not supported
    } else {
      // special handling for the $lt / $lte operators because we need to additionally limit
      // the min value by 0
      const { operator, value } = filterCondition;
      if (operator === FilterOperator.LESS_THAN || operator === FilterOperator.LESS_THAN_OR_EQUAL) {
        return {
          [this.platformFilterName]: {
            [filterOperatorToPlatformFilterOperatorMap[operator]!]: value,
            [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: 0,
          },
        };
      }
    }

    return {
      [this.platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
    };
  }
}

export default RottingDaysOutField;
