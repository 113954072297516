import localSettings from "@app/config/LocalSettings";
import Path from "@app/enum/Path";

class AuthService {
  // some methods here don't do anything specific compared to localSettings' methods, so just borrow them

  getToken = localSettings.getAuthToken;
  setToken = localSettings.setAuthToken;
  hasToken = localSettings.hasAuthToken;
  resetToken = localSettings.resetAuthToken;
  // wasSkeletonKeyUsed = localSettings.wasSkeletonKeyUsed;
  // setSkeletonKeyUsed = localSettings.setSkeletonKeyUsed;

  resetSession = () => {
    this.resetToken();
    localSettings.setSkeletonKeyUsed(false);
    localSettings.setLocationReminderAllowed(true);
    localSettings.setRecapChartRange();
    localSettings.resetDontShowDeleteEntityConfirmation();
    localSettings.resetActivityCreationNotifyPreferences();
    localSettings.removeAnonymousFlag();
  };

  saveLocation = () => {
    // Dummy now, but can be used to persist current location to open it again after signing in.
    // Should only save valid locations, i.e. no service urls like /login or /auth or especially
    // external URIs.
    // TODO: implement saving location using localSettings.setLocation
  };
  getSavedLocation = localSettings.getLocation;
  resetSavedLocation = localSettings.resetLocation;

  signOut = (location?: string) => {
    window.location.href = location ?? `${window.location.origin}${Path.ROOT}`;
  };
}

const authService = new AuthService();
export default authService;
