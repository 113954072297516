import cn from "classnames";
import { eventChannel } from "redux-saga";
import { cancelled } from "redux-saga-test-plan/matchers";
import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

import localSettings from "../../config/LocalSettings";
import Theme from "../../enum/Theme";

import { changeTheme } from "./actions";

// create channel which will listen to system color scheme changes
// and emit current system theme
const createSystemThemeChangeChannel = () =>
  eventChannel<Theme>(() => {
    // more about this media-query: https://developer.mozilla.org/ru/docs/Web/CSS/@media/prefers-color-scheme
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

    // if not supported (old Safari and IE), do nothing
    if (!matchMedia.addEventListener) {
      return () => {};
    }

    const eventListener = () => {
      // Temporarily disable the line below because our dark theme is not optimized yet.
      // And if user uses dark theme, our app will not look good for them.
      // https://mapmycustomers.slack.com/archives/CMRDJAMV2/p1648053265209839
      // emitter(e.matches ? Theme.DARK : Theme.LIGHT);
    };
    matchMedia.addEventListener("change", eventListener);
    return () => {
      matchMedia.removeEventListener("change", eventListener);
    };
  });

export const applyTheme = async (theme: Theme) => {
  await import(/* webpackPrefetch: true */ `../../styles/theme/${theme}.theme.scss`);
  document.body.className = cn("theme", `theme-${theme}`);
};

export function* onChangeTheme(action: ReturnType<typeof changeTheme.request>): Generator {
  const targetTheme = action.payload;

  yield all([yield call(applyTheme, targetTheme)]);

  // And finally set the current theme in the store and in local settings
  yield call(localSettings.setTheme, targetTheme);
  yield put(changeTheme.success(targetTheme));
}

export function* onSystemThemeChange(): Generator {
  const systemThemeChangeChannel = createSystemThemeChangeChannel();
  try {
    while (true) {
      const theme = yield take(systemThemeChangeChannel);
      yield put(changeTheme.request(theme as Theme));
    }
  } finally {
    if (yield cancelled()) {
      systemThemeChangeChannel.close();
    }
  }
}

export function* themeSaga() {
  yield fork(onSystemThemeChange);
  yield takeEvery(changeTheme.request, onChangeTheme);
}
