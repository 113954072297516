import React from "react";

import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useIntl } from "react-intl";

import { RawFile } from "@mapmycustomers/shared/types/File";
import { Labeled } from "@mapmycustomers/ui";

import FileItem from "@app/component/FileUploader/FileItem";

import styles from "./LockedFiles.module.scss";
import messages from "./messages";

interface Props {
  files: RawFile[];
}

const LockedFiles: React.FC<Props> = ({ files }) => {
  const intl = useIntl();

  return (
    <Labeled label={intl.formatMessage(messages.files)}>
      <div className={styles.restrictedUpload}>
        <span>{intl.formatMessage(messages.fileUploadRestricted)}</span>
        <span>
          <FontAwesomeIcon icon={faLock} />
        </span>
      </div>
      <div className={styles.attachments}>
        {files.map((file) => (
          <FileItem
            className={cn(styles.fileRow, styles.clickableFileRow)}
            file={file}
            fileName={file.name}
            key={file.id}
          />
        ))}
      </div>
    </Labeled>
  );
};

export default LockedFiles;
