import { put, select, takeLatest } from "redux-saga/effects";

import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization } from "@app/store/iam";
import TextSearchResult from "@app/types/TextSearchResult";

import { doGlobalSearch } from "./actions";

export function* onGlobalSearch({
  payload: searchText,
}: ReturnType<typeof doGlobalSearch.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const searchResults: ListResponse<TextSearchResult> = yield callApi(
      "search",
      org.id,
      searchText
    );
    yield put(doGlobalSearch.success(searchResults.data));
  } catch (error) {
    yield put(doGlobalSearch.failure());
    yield put(handleError({ error }));
  }
}

export function* navigationBarSaga() {
  yield takeLatest(doGlobalSearch.request, onGlobalSearch);
}
