import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { faLocationArrow, faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import AutoComplete from "antd/es/auto-complete";
import Input, { InputRef } from "antd/es/input";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import Address from "@mapmycustomers/shared/types/Address";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { getFormattedAddressForUi } from "@mapmycustomers/shared/util/formatters";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useStateWithDebouncedListener from "@mapmycustomers/shared/util/hook/useStateWithDebouncedListener";

import poweredByGoogle from "../../../../assets/google/poweredByGoogle.png";
import { useConfigProvider } from "../../../../ConfigProvider";
import LoadingSpinner from "../../../../LoadingSpinner";
import Footer from "../../../inline/Footer";
import Labeled, { LabeledFieldProps } from "../../../Labeled";
import useGoogleAutoCompleteOptions, {
  MANUAL_ADDRESS_VALUE,
  NO_RESULT_VALUE,
} from "../../utils/useGoogleAutoCompleteOptions";
import usePlacePredictionsGetter from "../../utils/usePlacePredictionsGetter";
import usePlaceDetailsGetter from "../../utils/usePlacesService";

const block = bem("mmc-inline-auto-complete-address");

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

interface InlineAutoCompleteAddressProps extends Omit<LabeledFieldProps, "children"> {
  allowClear?: boolean;
  caption?: string;
  className?: string;
  disabled?: boolean;
  disableFindMe?: boolean;
  onChange?: (address?: Address) => void;
  onEnterManually?: (value: string) => void;
  onPressEnter?: () => void;
  onReverseGeocodeAddress: (payload: {
    callback: (result: GeocodeResult) => void;
    coordinates: LongLat;
    failureCallback?: () => void;
  }) => void;
  placeholder?: string;
  placePredictionTypes?: string[];
  ref?: React.Ref<InputRef>;
  value?: Address;
}

// eslint-disable-next-line react/display-name
const AutoCompleteAddress = forwardRef<InputRef, InlineAutoCompleteAddressProps>(
  (
    {
      allowClear,
      caption,
      className,
      disabled,
      disableFindMe,
      label,
      labelClassName,
      labelPosition = "side",
      onChange,
      onEnterManually,
      onPressEnter,
      onReverseGeocodeAddress,
      placeholder,
      placePredictionTypes,
      required,
      value,
    },
    ref
  ) => {
    const { formatMessage, useFindMyLocation } = useConfigProvider();
    const [placeList, setPlacesList] = useState<AutocompletePrediction[]>([]);
    const [placeListLoading, startLoading, stopLoading] = useBoolean();
    const [editing, startEditing, cancelEditing] = useBoolean();

    // Text field state + search listener to find suggestions
    const [placePredictionsGetter] = usePlacePredictionsGetter(placePredictionTypes);
    const [fieldValue, setFieldValue, setFieldValueWithoutSearch] = useStateWithDebouncedListener(
      [
        useCallback(
          async (value: string) => {
            try {
              startLoading();
              const places = await placePredictionsGetter(value);
              setPlacesList(places);
            } finally {
              stopLoading();
            }
          },
          [placePredictionsGetter, startLoading, stopLoading]
        ),
        100,
      ],
      ""
    );
    useEffect(() => {
      setFieldValueWithoutSearch(getFormattedAddressForUi(value));
    }, [setFieldValueWithoutSearch, value]);

    // Get address by place
    const [placeDetailsGetter] = usePlaceDetailsGetter();
    const selectPlace = useCallback(
      async (placeId: string) => {
        const address = await placeDetailsGetter(placeId);
        setFieldValueWithoutSearch(getFormattedAddressForUi(address));
        onChange?.(address);
      },
      [onChange, placeDetailsGetter, setFieldValueWithoutSearch]
    );

    // Handle clicking on any option in a dropdown
    const handleSelect = useCallback(
      (placeId: string) => {
        if ([MANUAL_ADDRESS_VALUE, NO_RESULT_VALUE].includes(placeId)) {
          if (placeId === MANUAL_ADDRESS_VALUE) {
            onEnterManually?.(fieldValue);
          }
          setFieldValue("");
          return;
        }
        const place = placeList.find(({ place_id }) => place_id === placeId);
        if (place) {
          setFieldValue(place.description);
        }
        selectPlace(placeId);
        cancelEditing();
      },
      [cancelEditing, fieldValue, onEnterManually, placeList, selectPlace, setFieldValue]
    );

    // Find Me functionality:
    const [, , findMeLoading, findMeError, handleFindMyLocation, geocodingResult] =
      useFindMyLocation(onReverseGeocodeAddress);
    const lastGeocodingResult = useRef<GeocodeResult>();
    useEffect(() => {
      if (lastGeocodingResult.current !== geocodingResult) {
        lastGeocodingResult.current = geocodingResult;
        setPlacesList([]);
        onChange?.(geocodingResult?.address);
      }
    }, [geocodingResult, onChange]);

    const options = useGoogleAutoCompleteOptions(placeList, !!onEnterManually);

    const checkTextAndFindPlaceIfNeeded = useCallback(async () => {
      if (getFormattedAddressForUi(value) !== fieldValue) {
        const places = await placePredictionsGetter(fieldValue);
        if (places.length > 0) {
          selectPlace(places[0].place_id);
        } else if (allowClear) {
          onChange?.(undefined);
        }
      }
      cancelEditing();
    }, [
      value,
      fieldValue,
      placePredictionsGetter,
      allowClear,
      cancelEditing,
      selectPlace,
      onChange,
    ]);

    const handleClear = useCallback(() => {
      onChange?.(undefined);
      setFieldValueWithoutSearch("");
    }, [onChange, setFieldValueWithoutSearch]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === "Escape") {
          setFieldValueWithoutSearch(getFormattedAddressForUi(value));
          cancelEditing();
        } else if (event.key === "Enter") {
          checkTextAndFindPlaceIfNeeded();
        }
      },
      [cancelEditing, checkTextAndFindPlaceIfNeeded, setFieldValueWithoutSearch, value]
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const handleStartEditing = useCallback(() => {
      if (disabled) {
        return;
      }
      startEditing();
      // can't focus hidden field, need to wait until it will be displayed
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }, [disabled, startEditing]);

    return (
      <div className={cn(block({ disabled }), className)}>
        <Labeled
          extra={disabled ? <FontAwesomeIcon className={block("lock")} icon={faLock} /> : undefined}
          label={
            <div className={block("label-container")}>
              {label}
              <img alt="powered by Google" src={poweredByGoogle} />
            </div>
          }
          labelClassName={cn(block("label"), labelClassName)}
          labelPosition={labelPosition}
          required={required}
        >
          {editing && !disabled ? (
            <AutoComplete
              autoFocus
              className={cn(block("input-container"), className)}
              disabled={disabled}
              dropdownMatchSelectWidth={false}
              onBlur={disabled ? undefined : checkTextAndFindPlaceIfNeeded}
              onChange={disabled ? undefined : setFieldValue}
              onClear={disabled ? undefined : handleClear}
              onKeyDown={disabled ? undefined : handleKeyDown}
              onSelect={disabled ? undefined : handleSelect}
              options={options}
              showSearch={!disabled}
              value={fieldValue}
            >
              <Input
                allowClear={allowClear && !disabled}
                className={block("input")}
                disabled={disabled}
                onPressEnter={onPressEnter}
                placeholder={
                  disabled
                    ? undefined
                    : placeholder ?? formatMessage("ui.address.autoCompleteAddress.placeholder")
                }
                ref={ref}
                size="large"
                suffix={
                  findMeLoading || placeListLoading ? (
                    <LoadingSpinner mini />
                  ) : disableFindMe || disabled ? undefined : (
                    <Tooltip title={formatMessage("ui.address.autoCompleteAddress.tooltip")}>
                      <FontAwesomeIcon
                        className={block("icon")}
                        icon={faLocationArrow}
                        onClick={handleFindMyLocation}
                        size="lg"
                      />
                    </Tooltip>
                  )
                }
              />
            </AutoComplete>
          ) : (
            <div className={cn(block("value"))} onClick={handleStartEditing}>
              {value ? (
                getFormattedAddressForUi(value)
              ) : (
                <span className={block("unknown")}>{formatMessage("ui.inlineInput.unknown")}</span>
              )}
            </div>
          )}
        </Labeled>
        <Footer caption={findMeError ?? caption} disabled={true} editing={false} />
      </div>
    );
  }
);

export default AutoCompleteAddress;
