import { EventInput } from "@fullcalendar/common";
import { createSelector } from "reselect";

import { Activity } from "@mapmycustomers/shared/types/entity";
import ActivitySuggestion from "@mapmycustomers/shared/types/entity/ActivitySuggestion";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import CalendarViewState from "@app/component/view/CalendarView/types/CalendarViewState";
import { RootState } from "@app/store/rootReducer";

import activityToEvent from "../utils/activityToEvent";
import suggestionToEvent from "../utils/suggestionToEvent";

const calendarState = (state: RootState) => state.scene.activityCalendar;

export const getCalendarViewState = createSelector(
  calendarState,
  ({ viewState }): CalendarViewState => viewState
);
export const getCalendarActivities = createSelector(calendarState, ({ activities }) => activities);
export const getCalendarMapEntities = createSelector(
  calendarState,
  ({ mapEntities }) => mapEntities
);
export const getCalendarSuggestions = createSelector(
  calendarState,
  ({ suggestions }) => suggestions
);

export const isCalendarViewLoading = createSelector(calendarState, ({ loading }) => loading);
export const getCalendarViewTotalFilteredRecordsCount = createSelector(
  calendarState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getCalendarViewTotalRecordsCount = createSelector(
  calendarState,
  ({ totalRecords }) => totalRecords
);

export const getCalendarEvents = createSelector(
  getCalendarActivities,
  getCalendarSuggestions,
  getCalendarViewState,
  (activities: Activity[], suggestions: ActivitySuggestion[], viewState: CalendarViewState) => {
    const nearbySuggestionsMap = new Map(
      suggestions
        .filter(({ crmActivityId, property }) => property === "nearby" && crmActivityId)
        .map((suggestion) => [suggestion.crmActivityId!, suggestion])
    );

    let events: EventInput[] = activities.map((activity) =>
      activityToEvent(activity, nearbySuggestionsMap)
    );

    // also, let's add some suggestions
    events = events.concat(
      suggestions
        .map((suggestion) => suggestionToEvent(suggestion, viewState.viewMode))
        .filter(isDefined)
    );

    return events;
  }
);

export const isToggleCompleteLoading = createSelector(
  calendarState,
  ({ toggleCompleteLoading }) => toggleCompleteLoading
);

export const isPostponeLoading = createSelector(
  calendarState,
  ({ postponeLoading }) => postponeLoading
);

export const areNearbyEntitiesLoading = createSelector(
  calendarState,
  ({ nearbyEntitiesLoading }) => nearbyEntitiesLoading
);
export const getNearbyEntities = createSelector(
  calendarState,
  ({ nearbyEntities }) => nearbyEntities
);

export const areOutOfCadenceEntitiesLoading = createSelector(
  calendarState,
  ({ outOfCadenceEntitiesLoading }) => outOfCadenceEntitiesLoading
);
export const getOutOfCadenceEntities = createSelector(
  calendarState,
  ({ outOfCadenceEntities }) => outOfCadenceEntities
);

export const areSearchResultsLoading = createSelector(
  calendarState,
  ({ searchResultsLoading }) => searchResultsLoading
);
export const getSearchResults = createSelector(calendarState, ({ searchResults }) => searchResults);
