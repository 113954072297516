import { Nullable } from "../../util/ts";
import GeoAddress from "../GeoAddress";
import GeoPoint from "../shapes/GeoPoint";

// FIXME: refactor this file. Move enums outside, ensure Located interface is correct

export enum GeoStatus {
  ZERO_RESULTS = "ZERO_RESULTS",
  OK = "OK",
  ADDRESS_AND_CITY_MISSING = "ADDRESS_AND_CITY_MISSING",
  MAX_GEOCODES_REACHED = "MAX_GEOCODES_REACHED",
  ORG_GEOCODE_LIMIT_REACHED = "ORG_GEOCODE_LIMIT_REACHED",
  NO_PROVIDER = "NO_PROVIDER",
  GEOCODING_FUNCTIONS_NOT_CALLED = "Geocoding functions not called",
  GEOCODING_ERROR = "GEOCODING_ERROR",
  GEOCODING_TIMEOUT = "GEOCODING_TIMEOUT",
}

export enum GeoSource {
  APPLE = "apple",
  GOOGLE = "google",
}

export enum GeoCodeType {
  GEOCODE = "geocode",
  REVERSE_GEOCODE = "reverseGeocode",
}

export enum GeoManagementState {
  AUTOMATIC = "automatic",
  MANUAL = "manual",
  AUTOMATIC_PRESERVE_ADDRESS = "automaticPreserveAddress",
}

export interface GeocodeLimits {
  MMC: {
    limit: number;
    used: number;
  };
  organization: {
    limit: number;
    used: number;
  };
}

interface Located extends Nullable<GeoAddress> {
  geoAddress?: GeoAddress;
  geoCodedAt: null | string;
  geoCodeType: GeoCodeType | null;
  geoManagementState: GeoManagementState;
  geoPoint: GeoPoint | null;
  geoSource: GeoSource | null;
  geoStatus: GeoStatus | null;
}

export interface GeocodeResult extends Pick<Located, "geoPoint"> {
  address: GeoAddress;
  status: string;
}

export default Located;
