import numeral from "numeral";
import { call, put, takeEvery } from "redux-saga/effects";

import dateFnsLocaleService, { dateFnsLocales } from "@app/config/DateFnsLocaleService";
import i18nService from "@app/config/I18nService";

import "numeral/locales/es-es";
import "numeral/locales/ru";
import "numeral/locales/pt-br";
import "numeral/locales/en-au";
import "numeral/locales/en-gb";

import localSettings from "@app/config/LocalSettings";
import findLibraryLocale from "@app/util/i18n/findLibraryLocale";

import { changeLocale } from "./actions";

const localeToNumeralLocale: Record<string, string> = {
  "en-AU": "en-au",
  "en-GB": "en-gb",
  "en-NZ": "en-au",
  es: "es-es",
  pt: "pt-br",
};

const availableNumeralLocaleList = ["en", "en-au", "en-gb", "es", "ru", "pt"]; // "hi" locale is not provided :(

// available date-fns locales (converting enUS to en_US)
const availableDateFnsLocaleList = Object.keys(dateFnsLocales).map((key) =>
  (/([a-z]+)([A-Z][A-Za-z]+)?/g.exec(key) || [])
    .splice(1)
    .filter((x) => !!x)
    .join("_")
);

const dateFnsLocaleFormatter = ({
  language,
  region,
}: {
  language: string;
  region: string | undefined;
}): string => `${language}${region ? region.toUpperCase() : ""}`;

// FIXME: it would be much better to load locales dynamically, instead of bundling them within the app

export function* onChangeLocale(action: ReturnType<typeof changeLocale.request>): Generator {
  const targetLocale = action.payload;

  // Update locale for all libraries (except AntD, it's handled in the App.tsx)

  const dateFnsLocale = findLibraryLocale(
    targetLocale,
    availableDateFnsLocaleList,
    "enUS",
    "en_US",
    dateFnsLocaleFormatter // format locale with no separator between language and region
  );
  dateFnsLocaleService.locale = dateFnsLocales[dateFnsLocale.locale as keyof typeof dateFnsLocales];

  const numeralLocale = findLibraryLocale(
    targetLocale,
    availableNumeralLocaleList,
    process.env.REACT_APP_DEFAULT_LOCALE!
  );

  numeral.locale(localeToNumeralLocale[numeralLocale.locale] ?? numeralLocale.locale);

  // Change app's locale
  yield call(i18nService.changeLocale, targetLocale);

  // And finally set the current locale in the store and in local settings
  yield call(localSettings.setLocale, targetLocale);
  yield put(changeLocale.success(targetLocale));
}

export function* localeSaga() {
  yield takeEvery(changeLocale.request, onChangeLocale);
}
