import { createReducer } from "typesafe-actions";

import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";

import displayOrderComparator from "@app/util/comparator/displayOrderComparator";

import {
  Actions,
  createDealLossReason,
  deleteDealLossReason,
  fetchDealLossReasons,
  updateDealLossReason,
} from "./actions";

export interface DealLossReasonsState {
  createLoading: boolean;
  deleteLoading: boolean;
  loading: boolean;
  lossReasons: DealLossReason[];
}

const initialState: DealLossReasonsState = {
  createLoading: false,
  deleteLoading: false,
  loading: false,
  lossReasons: [],
};

const dealLossReasons = createReducer<DealLossReasonsState, Actions>(initialState)
  .handleAction(fetchDealLossReasons.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchDealLossReasons.success, (state, action) => ({
    ...state,
    loading: false,
    lossReasons: action.payload.sort(displayOrderComparator),
  }))
  .handleAction(fetchDealLossReasons.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(createDealLossReason.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction(createDealLossReason.success, (state, action) => ({
    ...state,
    createLoading: false,
    lossReasons: [...state.lossReasons, action.payload].sort(displayOrderComparator),
  }))
  .handleAction(createDealLossReason.failure, (state) => ({
    ...state,
    createLoading: false,
  }))
  .handleAction(updateDealLossReason.request, (state) => ({
    ...state,
  }))
  .handleAction(updateDealLossReason.success, (state, { payload }) => ({
    ...state,
    lossReasons: state.lossReasons
      .map((reason) => (reason.id === payload.id ? payload : reason))
      .sort(displayOrderComparator),
  }))
  .handleAction(updateDealLossReason.failure, (state) => ({
    ...state,
  }))
  .handleAction(deleteDealLossReason.request, (state) => ({
    ...state,
    deleteLoading: true,
  }))
  .handleAction(deleteDealLossReason.success, (state, action) => ({
    ...state,
    deleteLoading: false,
    lossReasons: state.lossReasons.filter(({ id }) => id !== action.payload),
  }))
  .handleAction(deleteDealLossReason.failure, (state) => ({
    ...state,
    deleteLoading: false,
  }));

export type DealLossReasonsActions = Actions;
export default dealLossReasons;
