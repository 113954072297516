enum SignupField {
  FULL_NAME = "fullName",
  EMAIL = "email",
  PHONE = "phone",
  PASSWORD = "password",
  PASSWORD_CONFIRMATION = "confirm",
  ORGANIZATION_NAME = "organizationName",
  NUM_FIELDS_REPS = "numFieldReps",
  CRM = "crm",
  AGREE_WITH_TNC = "tnc",
  PROMO_EMAILS = "promoEmails",
  INVITATION_CODE = "invitationCode",
  REFERRAL_SOURCE = "referralSource",
}

export default SignupField;
