import React, { FC, useMemo } from "react";

import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { SelectField } from "@mapmycustomers/ui";

import DaysField from "@app/util/filters/FrequencyStatusFilter/DaysField";

import styles from "./CommonValue.module.scss";
import FrequencyStatusFilterValue from "./FrequencyStatusFilterValue";

const SUPPORTED_UPCOMING_SUB_OPERATORS = [
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_THAN_OR_EQUAL,
  FilterOperator.LESS_THAN,
  FilterOperator.LESS_THAN_OR_EQUAL,
] as const;

const subOperatorMessages = defineMessages<(typeof SUPPORTED_UPCOMING_SUB_OPERATORS)[number]>({
  [FilterOperator.GREATER_THAN]: {
    id: "filters.cadenceStatus.subOperator.upcoming.greaterThan",
    defaultMessage: "in greater than",
    description: "Greater than in frequency status filter",
  },
  [FilterOperator.GREATER_THAN_OR_EQUAL]: {
    id: "filters.cadenceStatus.subOperator.upcoming.greaterThanOrEqual",
    defaultMessage: "in greater than or equal to",
    description: "Greater than or equal operator in frequency status filter",
  },
  [FilterOperator.LESS_THAN]: {
    id: "filters.cadenceStatus.subOperator.upcoming.lessThan",
    defaultMessage: "in less than",
    description: "Less than operator in frequency status filter",
  },
  [FilterOperator.LESS_THAN_OR_EQUAL]: {
    id: "filters.cadenceStatus.subOperator.upcoming.lessThanOrEqual",
    defaultMessage: "in less than or equal to",
    description: "Less than or equal operator in frequency status filter",
  },
});

export interface UpcomingValueProps {
  className?: string;
  onChange?: (value: SimpleCondition) => void;
  value: SimpleCondition;
}

const UpcomingValue: FC<UpcomingValueProps> = ({ className, onChange, value }) => {
  const intl = useIntl();

  const options = useMemo(() => {
    return SUPPORTED_UPCOMING_SUB_OPERATORS.map((operator) => ({
      label: intl.formatMessage(subOperatorMessages[operator]),
      value: operator,
    }));
  }, [intl]);

  const subValue = value.value as FrequencyStatusFilterValue | undefined;

  return (
    <div className={cn(styles.container, className)}>
      <SelectField<FilterOperator>
        className={styles.selectField}
        dropdownMatchSelectWidth={false}
        onChange={(operator) =>
          onChange?.({ ...value, value: { ...subValue, operator } as SimpleCondition })
        }
        options={options}
        showSearch
        size="middle"
        value={subValue?.operator}
      />
      <DaysField
        className={styles.daysField}
        onChange={(days) => {
          onChange?.({ ...value, value: { ...subValue, value: days } as SimpleCondition });
        }}
        value={subValue?.value}
      />
    </div>
  );
};

export default UpcomingValue;
