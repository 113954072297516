import { defineMessages, IntlShape } from "react-intl";

import { EntityType } from "@mapmycustomers/shared/types/entity";

enum GlobalSearchFilter {
  ALL,
  COMPANIES,
  PEOPLE,
  DEALS,
  GROUPS,
  ACTIVITIES,
  ROUTES,
  TERRITORIES,
}

const messages = defineMessages({
  [GlobalSearchFilter.ACTIVITIES]: {
    id: "@component.globalSearch.filter.activities",
    defaultMessage: "Activities",
    description: "Global search filter - Activities",
  },
  [GlobalSearchFilter.ALL]: {
    id: "@component.globalSearch.filter.all",
    defaultMessage: "All",
    description: "Global search filter - All",
  },
  [GlobalSearchFilter.COMPANIES]: {
    id: "@component.globalSearch.filter.companies",
    defaultMessage: "Companies",
    description: "Global search filter - Companies",
  },
  [GlobalSearchFilter.DEALS]: {
    id: "@component.globalSearch.filter.deals",
    defaultMessage: "Deals",
    description: "Global search filter - Deals",
  },
  [GlobalSearchFilter.GROUPS]: {
    id: "@component.globalSearch.filter.groups",
    defaultMessage: "Groups",
    description: "Global search filter - Groups",
  },
  [GlobalSearchFilter.PEOPLE]: {
    id: "@component.globalSearch.filter.people",
    defaultMessage: "People",
    description: "Global search filter - People",
  },
  [GlobalSearchFilter.ROUTES]: {
    id: "@component.globalSearch.filter.routes",
    defaultMessage: "Routes",
    description: "Global search filter - Routes",
  },
  [GlobalSearchFilter.TERRITORIES]: {
    id: "@component.globalSearch.filter.territories",
    defaultMessage: "Territories",
    description: "Global search filter - Territories",
  },
});

export const getGlobalSearchFilterValue = (
  filter: GlobalSearchFilter
): EntityType[] | undefined => {
  return {
    [GlobalSearchFilter.ACTIVITIES]: [EntityType.ACTIVITY],
    [GlobalSearchFilter.ALL]: undefined,
    [GlobalSearchFilter.COMPANIES]: [EntityType.COMPANY],
    [GlobalSearchFilter.DEALS]: [EntityType.DEAL],
    [GlobalSearchFilter.GROUPS]: [
      EntityType.COMPANY_GROUP,
      EntityType.PEOPLE_GROUP,
      EntityType.DEAL_GROUP,
    ],
    [GlobalSearchFilter.PEOPLE]: [EntityType.PERSON],
    [GlobalSearchFilter.ROUTES]: [EntityType.COMPANY_ROUTE, EntityType.PEOPLE_ROUTE],
    [GlobalSearchFilter.TERRITORIES]: [EntityType.TERRITORY],
  }[filter];
};

export const getGlobalSearchFilterDisplayName = (intl: IntlShape, filter: GlobalSearchFilter) =>
  intl.formatMessage(messages[filter]);

export default GlobalSearchFilter;
