import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import { listFormatter } from "@app/util/fieldModel/impl/fieldUtil";
import TerritoriesFilter, { TERRITORIES_FILTER_OPERATORS } from "@app/util/filters/Territories";
import { getRegularFieldConditionValue } from "@app/util/viewModel/convertFromPlatformFilterModel";

import Field, { FieldProperties } from "./Field";

class TerritoryField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "territories",
        filterInstances: {
          territories: TerritoriesFilter,
        },
        operators: [...TERRITORIES_FILTER_OPERATORS],
      },
      customGridProperties: {
        cellRenderer: "territoriesCellRenderer",
      },
      extraPlatformFiltersGetter: () => ({ includeTerritories: true }),
      features: [
        FieldFeature.TERRITORY_FIELD,
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_IMPORT,
        FieldFeature.RELATIONSHIPS,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterType: FieldType.ID,
      platformFilterName: "territories",
      type: FieldType.LIST,
      valueFormatter: listFormatter("name"),
      ...data,
    });
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    // territory field filter is applied as a meta-filter, thus it doesn't have a name
    // so we're adding a field name back for it.
    return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
  }
}

export default TerritoryField;
