import { LabeledValue } from "antd/lib/select";
import { startOfDay, startOfWeek } from "date-fns/esm";

import {
  dayGranularityDateFormatter,
  minusDays,
  plusDays,
  SimpleRangeType,
  weekGranularityDateFormatter,
} from "./dateUtils";

export const ACTIVITY_LAST_DATE_RANGE: SimpleRangeType[] = [
  {
    dateFormatter: dayGranularityDateFormatter,
    dateRounder: startOfDay,
    getStartDate: minusDays(7),
    granularity: "day",
    label: "Last 7 Days",
  },
  {
    dateFormatter: weekGranularityDateFormatter,
    dateRounder: startOfWeek,
    getStartDate: minusDays(14),
    granularity: "week",
    label: "Last 14 Days",
  },
  {
    dateFormatter: weekGranularityDateFormatter,
    dateRounder: startOfWeek,
    getStartDate: minusDays(30),
    granularity: "week",
    label: "Last 30 Days",
  },
];

export const ACTIVITY_NEXT_DATE_RANGE: SimpleRangeType[] = [
  {
    dateFormatter: dayGranularityDateFormatter,
    dateRounder: startOfDay,
    getStartDate: plusDays(7),
    granularity: "day",
    label: "Next 7 Days",
  },
  {
    dateFormatter: weekGranularityDateFormatter,
    dateRounder: startOfWeek,
    getStartDate: plusDays(14),
    granularity: "week",
    label: "Next 14 Days",
  },
  {
    dateFormatter: weekGranularityDateFormatter,
    dateRounder: startOfWeek,
    getStartDate: plusDays(30),
    granularity: "week",
    label: "Next 30 Days",
  },
];

export const ACTIVITY_LAST_DATE_RANGE_LABELS: LabeledValue[] = ACTIVITY_LAST_DATE_RANGE.map(
  ({ label }) => ({
    label,
    value: label,
  })
);

export const ACTIVITY_NEXT_DATE_RANGE_LABELS: LabeledValue[] = ACTIVITY_NEXT_DATE_RANGE.map(
  ({ label }) => ({
    label,
    value: label,
  })
);

export const getActivityRangeStartDate = (rangeLabel: string) =>
  [...ACTIVITY_LAST_DATE_RANGE, ...ACTIVITY_NEXT_DATE_RANGE]
    .find(({ label }) => label === rangeLabel)
    ?.getStartDate();
