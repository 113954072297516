import React, { useCallback, useMemo } from "react";

import Select, { RefSelectProps } from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";

import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import defaultFilterOption from "@app/component/input/utils/defaultFilterOption";

import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "./getHumanReadableDescriptionForOptionsFilter";
import styles from "./TeamsFilter.module.scss";

export const TEAMS_FILTER_OPERATORS = [FilterOperator.IN_ANY];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  TEAMS_FILTER_OPERATORS.includes(operator);

interface TeamsFilterProps extends IFilterComponentProps {}

const TeamsFilter: IFilterInstance = {
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_TEAMS,
  doesSupportValue,
  getComponent:
    (): React.FC<TeamsFilterProps> =>
    ({ className, focus, onChange, options, value }) => {
      const intl = useIntl();

      const teamOptions = useMemo(
        () =>
          [...((options?.availableTeams as Team[]) ?? [])]
            .sort(nameComparator)
            .map(({ id, name }) => ({
              label: name,
              value: id,
            })),
        [options?.availableTeams]
      );

      const setRef = useCallback(
        (ref: null | RefSelectProps) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <Select<Array<Team["id"]>>
          className={cn(styles.container, className)}
          filterOption={defaultFilterOption}
          mode="multiple"
          onChange={(teamIds: Team["id"][]) => onChange?.({ ...value, value: teamIds })}
          options={teamOptions}
          placeholder={intl.formatMessage({
            id: "filters.team.select.placeholder",
            defaultMessage: "Click or type to select teams",
            description: "Placeholder displayed in a TeamFilter's select field",
          })}
          ref={setRef}
          value={Array.isArray(value.value) ? value.value : []}
        />
      );
    },
  getHumanReadableDescription: (value: SimpleCondition, field: IField, options) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const teamsMap = new Map(
      ((options?.availableTeams ?? []) as Team[]).map(({ id, name }) => [id, name])
    );
    const selectedValues = value.value as User["id"][];
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      selectedValues.map((teamId) => teamsMap.get(teamId) ?? String(teamId))
    );
  },
};

export default TeamsFilter;
