import { AnyEntity, EntityType } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import getFieldModelByEntityType from "../fieldModel/getByEntityType";

import downloadFile from "./downloadFile";

const downloadEntitiesAsCsv = (
  fileNameWithoutExtension: string,
  entityType: EntityType.ACTIVITY | EntityType.COMPANY | EntityType.DEAL | EntityType.PERSON,
  entities: AnyEntity[],
  fieldList?: IField[]
) => {
  const fieldModel = getFieldModelByEntityType(entityType);
  const fields: IField[] = fieldList ?? fieldModel.fields;
  const csvText = [
    fields.map((field) => field?.displayName).join(","),
    ...entities.map((entity) =>
      [
        ...fields.map(
          (field: IField) => `"${field.getFormattedCsvValueFor(entity).replaceAll(`"`, '""')}"`
        ),
      ].join(",")
    ),
  ].join("\n");

  const blob = new Blob([csvText], { type: "text/plain" });
  downloadFile(`${fileNameWithoutExtension}.csv`, blob);
};

export default downloadEntitiesAsCsv;
