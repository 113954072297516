import React, { useCallback } from "react";

import { bem } from "@react-md/utils";
import Slider from "antd/es/slider";
import cn from "classnames";

import Labeled from "../input/Labeled";
import NumberField from "../input/NumberField";

const block = bem("mmc-slider-with-number-field");

interface Props {
  className?: string;
  label?: string;
  max: number;
  min: number;
  onChange?: (value: number) => void;
  step: number;
  value?: number;
  valueFormatter?: (value?: number | string) => string;
}

const SliderWithNumberField: React.FC<Props> = ({
  className,
  label,
  max,
  min,
  onChange,
  step,
  value,
  valueFormatter,
}) => {
  const handleNumberFieldChange = useCallback(
    (value?: number) => value !== undefined && onChange?.(value),
    [onChange]
  );

  return (
    <Labeled className={cn(block("label"), className)} label={label}>
      <div className={block()}>
        <Slider
          className={block("slider")}
          max={max}
          min={min}
          onChange={onChange}
          step={step}
          tipFormatter={valueFormatter}
          value={value}
        />
        <NumberField
          className={block("numberField")}
          formatter={valueFormatter}
          max={max}
          min={min}
          onChange={handleNumberFieldChange}
          step={step}
          value={value}
        />
      </div>
    </Labeled>
  );
};

export default SliderWithNumberField;
