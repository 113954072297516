import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import { EntityWithLocation } from "@mapmycustomers/shared/types/entity";
import { Modal } from "@mapmycustomers/ui";

import { RootState } from "@app/store/rootReducer";
import { TOPMOST_MODAL_ZINDEX } from "@app/util/consts";

import Associations from "./components/Associations";
import styles from "./LocatedEntityAssociation.module.scss";
import { getLocatedEntities, isLoading } from "./store";
import { filterEntitiesWithLocation } from "./store/actions";
import useEntitySorter from "./utils/useEntitySorter";

interface Props {
  entities?: EntityWithLocation[];
  loading?: boolean;
  onHide: (updated?: boolean) => void;
  onSearch: typeof filterEntitiesWithLocation.request;
  onSelect?: (selectedEntity: EntityWithLocation) => void;
  selectedEntityId?: EntityWithLocation["id"];
  visible: boolean;
}

const LocatedEntityAssociation: React.FC<Props> = ({
  entities,
  loading,
  onHide,
  onSearch,
  onSelect,
  selectedEntityId,
  visible,
}) => {
  const intl = useIntl();

  const [query, setQuery] = useState<string>("");
  const [selectedEntitiesId, setSelectedEntitiesId] = useState<EntityWithLocation["id"][]>(
    selectedEntityId ? [selectedEntityId] : []
  );

  useEffect(() => {
    onSearch({ query: "" });
  }, [onSearch]);

  const sortedEntities = useEntitySorter(entities ?? [], [], query.trim().length > 0);

  const handleOk = useCallback(() => {
    const selectedId = selectedEntitiesId?.[0];
    const selected = entities?.find((entity) => entity.id === selectedId);
    if (selected) {
      onSelect?.(selected);
      onHide();
    }
  }, [entities, onHide, onSelect, selectedEntitiesId]);

  const handleCancel = useCallback(() => {
    onHide(false);
  }, [onHide]);

  const handleSearch = useCallback(
    (query: string) => {
      onSearch({ query });
    },
    [onSearch]
  );

  return (
    <Modal
      okButtonProps={{ disabled: !selectedEntitiesId.length }}
      okText={intl.formatMessage({
        id: "associations.locatedEntities.footer.okButton",
        defaultMessage: "Select Record",
        description: "Add button title on Company/Person Associations modal",
      })}
      onCancel={handleCancel}
      onOk={handleOk}
      title={intl.formatMessage({
        id: "associations.locatedEntities.header",
        defaultMessage: "Select a Company or Person",
        description: "Header of Company/Person Associations modal",
      })}
      visible={visible}
      width="clamp(500px, 50vw, 750px)"
      zIndex={TOPMOST_MODAL_ZINDEX}
    >
      <Associations<EntityWithLocation>
        alreadyAssociatedMessage={null}
        cantAssociateWithSelfMessage={null}
        className={styles.associations}
        entities={sortedEntities}
        loading={loading}
        multiselect={false}
        onChange={setSelectedEntitiesId}
        onChangeQuery={setQuery}
        onSearch={handleSearch}
        query={query}
        textFieldProps={{
          placeholder: intl.formatMessage({
            id: "associations.locatedEntities.search.placeholder",
            defaultMessage: "Search for a Company or Person",
            description: "Search bar placeholder for company/person association modal",
          }),
        }}
        value={selectedEntitiesId}
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  entities: getLocatedEntities(state),
  loading: isLoading(state),
});

const mapDispatchToProps = {
  onSearch: filterEntitiesWithLocation.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocatedEntityAssociation);
